import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import Paragraph from '../../../PDF/Paragraph.component';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import LetterHead from '../../../PDF/Letterhead.component';
import moment from 'moment';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const PoorPerformanceNoticeOfAppealHearingPDF: React.FC<DocumentProps> = props => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Confidential</Text>
        <Table>
          <TableHeader title="attention" />
          <TableRow>
            <TableCell>Name of Employee: </TableCell>
            <TableCell>{props.content.employeeFullName.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Employee Number: </TableCell>
            <TableCell>{props.content.employeeNumber.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Department/Business Unit</TableCell>
            <TableCell>{props.content.employeeDepartment.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{props.content.employeeAddress.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>{props.content.date.value}</TableCell>
          </TableRow>
        </Table>
        <Paragraph> Dear {props.content.employeeFullName.value}, </Paragraph>
        <Text style={{ ...styles.subject }}>NOTICE OF APPEAL HEARING – INCAPACITY POOR PERFORMANCE </Text>
        <Paragraph>
          <Text>You are hereby called upon to appear at an appeal hearing as set out hereunder:</Text>
        </Paragraph>
        <Paragraph>
          <Text>
            DATE:{' '}
            {moment.isMoment(props.content.appeal_hearing_hearingDate.value)
              ? ' ' + props.content.appeal_hearing_hearingDate.value.format('DD-MM-YYYY')
              : props.content.appeal_hearing_hearingDate.value}
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            TIME:{' '}
            {moment.isMoment(props.content.appeal_hearing_hearingTime.value)
              ? ' ' + props.content.appeal_hearing_hearingTime.value.format('DD-MM-YYYY')
              : props.content.appeal_hearing_hearingTime.value}
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>PLACE: {props.content.appeal_hearing_hearingLocation.value}</Text>
        </Paragraph>
        <Paragraph>
          <Text style={{ ...styles.text, ...styles.bold, marginTop: 2, paddingLeft: 3 }}>
            The appeal hearing will be chaired by{' '}
            {props.content.appeal_hearing_chairpersonFirstName.value +
              ' ' +
              props.content.appeal_hearing_chairpersonLastName.value}
            . Documentation relating to the original Incapacity hearing as well as your Appeal Form have been made
            available to the appeal chairperson in advance.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            The purpose of the hearing is not to rehear the matter in its entirety, but to deal with the grounds for and
            merits of your appeal and/or to hear new evidence only.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            You have the right to be represented/assisted by a fellow employee at the appeal hearing and/or shop steward
            of a recognised union, if any in the workplace.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            You have the right to an interpreter. The hearing will be conducted in English. If an interpreter is
            required this should be requested when the notification is issued, to allow the employer time to arrange
            this. Hearings may not be delayed due to late notification that an interpreter is required.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            You may call witnesses with due regard for the purpose as stated above (i.e. new evidence only), but it is
            your own responsibility to secure their presence at the hearing, subject to you not intimidating or putting
            any undue pressure on such witness. Please inform the employer at least 24 hours in advance of the hearing
            if you have arranged with any of its employees to appear as your witness in order for it to confirm the
            person’s absence from the workplace with his/her line manager.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>You are entitled to cross-examine witnesses called by the employer, if any.</Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Should you fail or refuse to attend the appeal hearing and fail to timeously provide the employer with an
            acceptable reason for your failure / refusal, the hearing may be held in your absence without any further
            notice to yourself and action taken accordingly.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            You and/or your representative must come fully prepared to this hearing as no requests for postponements
            will be entertained. You are to argue the grounds for appeal as set out in your Appeal form.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Should you fail to understand the contents of this notice, you are welcome to approach
            {props.content.authorName.value} who will be prepared to explain the matter to you.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a
            copy to writer hereof in acknowledgement of receipt hereof, alternatively, acknowledge by way of return
            e-mail.
          </Text>
        </Paragraph>
        <Paragraph>Yours sincerely,</Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
    </Document>
  );
};
