import React, { ChangeEvent, ReactElement, useContext, useState } from 'react';
import { Field, FieldAttributes, FormikValues, useFormikContext } from 'formik';
import moment from 'moment';
import { Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import DateTime from 'react-datetime';
import { CalenderIcon, ClockIcon } from '../../components/icon/Icon.component';
import { autoExpand, setDateTime } from './FormField.component';
import { listActiveEmployeesByOrganisationId } from '../../utils/graphql-utils';
import { UserContext, UserContextProps } from '../../App';
import AsyncSelect from 'react-select/async-creatable';
import { tableFieldSelectStyles, ValueContainer } from '../../components/reactSelect/ReactSelectComponents.component';
import { ValueType } from 'react-select';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../components/taskContainer/TaskContainer';
import UploaderContainer from '../../components/Uploader/UploaderContainer';
import { Employee } from '../../models';
import ApiDataContext from '../../contexts';

//"redefine" react datetime to the renderInput prop that's missing in the typescript version
// lets us render a custom input field in React datetime
export const TypedDateTime = DateTime as React.ComponentType<
  DateTime.DatetimepickerProps & {
    renderInput?: (props: any, openCalendar: () => void, closeCalendar: () => void) => void;
    closeOnTab?: boolean;
  }
>;

interface SelectType {
  [key: string]: string | null | undefined;
}

interface SelectOptionWithLabel {
  value: string;
  label: string;
}

interface FormFieldProps {
  type: string;
  placeholder?: string;
  name: string;
  selectOptions?: SelectOptionWithLabel[];
  className?: string;
  checkedString?: string;
  disabled?: boolean;
  processInstanceId?: string;
  currentEmployeeId?: string;
  isUserAdmin?: boolean;
}

const TableFormFieldWithReadOnlyOption = (props: FormFieldProps) => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  // const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const [textAreaHeight, setTextAreaHeight] = useState(1);
  const { type, placeholder, name, selectOptions, className, processInstanceId, isUserAdmin } = props;
  const { setFieldValue } = useFormikContext();

  const employeeListApiData = useContext(ApiDataContext);

  const readOnly = isUserAdmin;

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  const handleChange = (value: { value: string; label: string }, fieldName: string) => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data.map((employee: Employee) => {
      return {
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
      };
    });
    return preparedData;
  };

  // const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
  //   if (currentUser?.organisationId) {
  //     return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
  //       const preparedData = prepareData(data);
  //       return !inputValue ? preparedData : filterItems(preparedData, inputValue);
  //     });
  //   }
  // };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId && typeof employeeListApiData.initialEmployeesPage === 'undefined') {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        employeeListApiData.employees = !inputValue ? preparedData : filterItems(preparedData, inputValue);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    } else {
      return !inputValue
        ? employeeListApiData.initialEmployeesPage
        : filterItems(employeeListApiData.initialEmployeesPage, inputValue);
    }
  };

  return (
    <>
      {(type === 'text' || type === 'email' || type === 'number' || type === 'password') && (
        <Field name={name} type={type}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <Input
              {...field}
              placeholder={placeholder}
              type={type}
              className={`${className} px-0`}
              style={{ fontSize: '0.875rem' }}
              disabled={readOnly}
              autoComplete="off"
            />
          )}
        </Field>
      )}

      {type === 'textarea' && (
        <Field name={name} type={type} style={{ display: 'flex', justifyContent: 'center' }}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <Input
              {...field}
              placeholder={placeholder}
              type={type}
              className={className + ' h-100 border-0'}
              height={textAreaHeight}
              autoComplete="off"
              style={{ fontSize: '0.875rem', overflowY: 'auto', height: '20px' }}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                event.preventDefault();
                setFieldValue(name, event.target.value);
                autoExpand(event, setTextAreaHeight);
              }}
              disabled={readOnly}
            />
          )}
        </Field>
      )}

      {(type === 'date' || type === 'time') && (
        <Field name={name}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <>
              <TypedDateTime
                onChange={(dateTime: string | moment.Moment): void => setDateTime(dateTime, setFieldValue, name, type)}
                dateFormat={type === 'date'}
                timeFormat={type === 'time'}
                inputProps={{
                  onKeyDown: e => e.preventDefault(),
                }}
                closeOnTab
                //@ts-ignore
                renderInput={(
                  props: any,
                  openCalender: () => void,
                  closeCalender: () => void,
                  closeOnTab: boolean,
                ): JSX.Element => {
                  return (
                    <div>
                      <InputGroup className="border-white mb-0">
                        <Input
                          {...props}
                          {...field}
                          placeholder={placeholder}
                          className="form-control h-25 border-0 pl-0 pr-0"
                          style={{ fontSize: '0.875rem', display: 'block' }}
                          disabled={readOnly}
                          autoComplete="off"
                        />
                        <InputGroupAddon addonType="append" className="border-0 p-0 m-0">
                          <InputGroup className="text-dark border-0 pt-1 pl-2">
                            {type === 'time' && <ClockIcon width="20" height="20" />}
                            {type === 'date' && <CalenderIcon width="20" height="20" />}
                          </InputGroup>
                        </InputGroupAddon>
                      </InputGroup>
                      <span onClick={openCalender} style={{ display: 'none' }} />
                      <span onClick={closeCalender} style={{ display: 'none' }} />
                    </div>
                  );
                }}
              />
            </>
          )}
        </Field>
      )}

      {type === 'select' && (
        <Field name={name} placeholder={placeholder}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <Input
              {...field}
              type="select"
              className={className ? className : 'square-radius border-0 h-25 px-0'}
              style={{ fontSize: '0.9em' }}
              disabled={props.disabled || readOnly}
              autoComplete="off"
            >
              <option className="text-muted" value="" label={placeholder} />
              {selectOptions &&
                selectOptions.length &&
                selectOptions.map((item: SelectOptionWithLabel, key: number) => (
                  <option key={key} value={item.value} style={{ fontSize: '1em' }} label={item.label} />
                ))}
            </Input>
          )}
        </Field>
      )}
      {type === 'asyncSelect' && (
        <Field name={name}>
          {({ field }: FieldAttributes<FormikValues>) => (
            <AsyncSelect
              {...field}
              placeholder="Select Employee"
              cacheOptions
              defaultOptions
              loadOptions={loadOptions}
              closeMenuOnSelect={false}
              isMulti
              styles={tableFieldSelectStyles}
              onChange={(value: ValueType<any>): void => handleChange(value, name)}
              components={{ ValueContainer }}
              isDisabled={readOnly}
            />
          )}
        </Field>
      )}
      {type === 'upload' && (
        <Field name={name}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <Row>
              <Col>
                <UploaderContainer fieldName={name} path={`cases/${processInstanceId}`} hasIcon={true} />
              </Col>
            </Row>
          )}
        </Field>
      )}
      {type === 'checkbox-yes-and-no' && (
        <Field name={name}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <>
              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        disabled={props.disabled || readOnly}
                        type="checkbox"
                        checked={props.checkedString === 'YES'}
                        onChange={(): void => {
                          setFieldValue(name, 'YES');
                        }}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">Yes</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        disabled={props.disabled || readOnly}
                        type="checkbox"
                        checked={props.checkedString === 'NO'}
                        onChange={(): void => {
                          setFieldValue(name, 'NO');
                        }}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-uppercase">No</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </>
          )}
        </Field>
      )}
    </>
  );
};

export default TableFormFieldWithReadOnlyOption;
