import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import Paragraph from '../../../PDF/Paragraph.component';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const PoorPerformanceHearingFindingsFormPDF: React.FC<DocumentProps> = () => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        <Paragraph>
          <Text style={{ ...styles.subject }}>FINDINGS OF INCAPACITY POOR PERFORMANCE HEARING</Text>
        </Paragraph>
        <Paragraph>
          <Text style={{ ...styles.subject }}>COMPLETED BY CHAIRPERSON OF HEARING</Text>
        </Paragraph>
        <Paragraph>
          <Text>1. State briefly the background of what led to the Incapacity Poor Performance hearing process:</Text>
        </Paragraph>
        <Paragraph>{/*<Text>{freeText.value}</Text>*/}</Paragraph>
        <Paragraph>
          <Text>2. Record the employee’s rights exercised in the hearing:</Text>
        </Paragraph>
        <Paragraph>{/*<Text>{freeText.value}</Text>*/}</Paragraph>
        <Paragraph>
          <Text>3. Summarise evidence submitted by the employer representative:</Text>
        </Paragraph>
        <Paragraph>{/*<Text>{freeText.value}</Text>*/}</Paragraph>
        <Paragraph>
          <Text>4. Summarise evidence submitted by the employee and/or his/her representative:</Text>
        </Paragraph>
        <Paragraph>{/*<Text>{freeText.value}</Text>*/}</Paragraph>
        <Paragraph>
          <Text>5. Summarise legal principles applied, if any:</Text>
        </Paragraph>
        <Paragraph>{/*<Text>{freeText.value}</Text>*/}</Paragraph>
        <Paragraph>
          <Text>
            6. Conclusions reached on sub-standard performance/shortfalls or otherwise based on factual evidence and
            legal principles:
          </Text>
        </Paragraph>
        <Paragraph>{/*<Text>{freeText.value}</Text>*/}</Paragraph>
        <Paragraph>
          <Text>7. Findings On Incapacity. Tick as appropriate ☑:</Text>
        </Paragraph>
        <TableRow>
          <TableCell width={'16%'}>Guilty</TableCell>
          <TableCell checkbox width={'8%'} />
          <TableCell width={'16%'}>Not Guilty</TableCell>
          <TableCell checkbox width={'8%'} />
        </TableRow>
        <Paragraph>
          <Text>8. Summarise aggravating factors considered to determine way forward:</Text>
        </Paragraph>
        <Paragraph>{/*<Text>{freeText.value}</Text>*/}</Paragraph>
        <Paragraph>
          <Text>9. Summarise employee comments and mitigating factors considered to determine way forward:</Text>
        </Paragraph>
        <Paragraph>{/*<Text>{freeText.value}</Text>*/}</Paragraph>
        <Paragraph>
          <Text>10. Conclusions reached on way forward:</Text>
        </Paragraph>
        <Paragraph>{/*<Text>{freeText.value}</Text>*/}</Paragraph>
        <Paragraph>
          <Text>11. Findings. Tick as appropriate ☑:</Text>
        </Paragraph>
        <TableRow>
          <TableCell width={'92%'}>Extension of PIP with final review period </TableCell>
          <TableCell checkbox width={'8%'} />
        </TableRow>
        <TableRow>
          <TableCell width={'92%'}>Summary termination</TableCell>
          <TableCell checkbox width={'8%'} />
        </TableRow>
        <TableRow>
          <TableCell width={'92%'}>Termination with notice pay</TableCell>
          <TableCell checkbox width={'8%'} />
        </TableRow>
        <TableRow>
          <TableCell width={'100%'}>Other, Explain: </TableCell>
        </TableRow>
      </Page>
    </Document>
  );
};
