import React, { useContext, useEffect } from 'react';
import { Field, FieldAttributes } from 'formik';
import { ErrorMessage, FormikValues, useFormikContext } from 'formik';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Tooltip } from '../../../../../components/tooltips/Tooltip.component';
import Tabs from '../../../../../components/HearingAndAppealTabs/HearingAndAppealTabs.component';
import { HEARING_TABS } from '../../../../../utils/case-utils';
import FormField from '../../../../../forms/fields/FormField.component';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select';
import { selectStyles, ValueContainer } from '../../../../../components/reactSelect/ReactSelectComponents.component';
import { CaseType } from '../../../../../API';
import { UserContext, UserContextProps } from '../../../../../App';
import { FormProps } from '../../../../WorkflowContainer/workflow-utils';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../../../components/taskContainer/TaskContainer';
import { appointChairperson } from '../../../../../utils/user-utils';
import { EmailParamsV2, EmailRecipient, EmailType } from '../../../../../utils/email-utils';
import { toTitleCase } from '../../../../../utils/string-utils';
import { EmailPreviewModalv3 } from '../../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { AxiosResponse } from 'axios';
import { loadOptionsChairperson } from '../../../../../utils/employee-user-selector';

const InitialHearingAppointChairpersonForm: React.FC<FormProps> = props => {
  const { caseData } = props.data;
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const handleChange = (item: {
    value: string;
    label: string;
    firstName: string;
    lastName: string;
    employeeId: string;
    userId: string;
    emailAddress: string;
  }): void => {
    setFieldValue('initial_hearing_chairpersonFirstName', item.firstName);
    setFieldValue('initial_hearing_chairpersonLastName', item.lastName);
    setFieldValue('initial_hearing_chairpersonEmailAddress', item.emailAddress);
    setFieldValue('initial_hearing_chairpersonUserId', item.userId);
    setFieldValue('initial_hearing_chairpersonEmployeeId', item.employeeId);
    setFieldValue('initial_hearing_chairpersonId', item.value);
  };

  const getRecipients = (): EmailRecipient[] => {
    return [
      {
        emailAddress: values.initial_hearing_chairpersonEmailAddress,
        name: toTitleCase(values.initial_hearing_chairpersonFirstName, ' '),
      },
    ];
  };

  const sendEmail = (): Promise<AxiosResponse> => {
    if (!caseData?.organisationId) {
      throw new Error('No current user with organisation id');
    }
    if (!currentUser?.id) {
      throw new Error('No current user id');
    }
    const params: EmailParamsV2 = {
      attachmentBucketKeys: ['planners/LabourteqChairpersonchecklistandminutes.doc'],
      currentUserId: currentUser?.id,
      emailType:
        props.data.caseType === CaseType.POOR_PERFORMANCE
          ? EmailType.APPOINT_CHAIRPERSON_POOR_PERFORMANCE
          : EmailType.APPOINT_CHAIRPERSON_MISCONDUCT,
      formValues: values,
      masterProcessInstanceId: props.data.masterProcessInstanceId,
      processInstanceId: props.data.processInstanceId,
      recipients: getRecipients(),
    };
    return appointChairperson(
      params,
      props.getFormValuesForSubmission(values),
      values.initial_hearing_chairpersonEmailAddress,
      values.initial_hearing_chairpersonFirstName,
      values.initial_hearing_chairpersonLastName,
      values.initial_hearing_chairpersonEmployeeId,
      values.initial_hearing_chairpersonType === 'internal' ? caseData?.organisationId : undefined,
    );
  };

  useEffect(() => {
    if (!values.initial_hearing_chairpersonType) setFieldValue('initial_hearing_chairpersonType', 'internal');
  }, [values.initial_hearing_chairpersonType]);

  return (
    <Form>
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Hearing preparations</h4>
      <Row>
        <Col md={12}>
          <Tabs page={1} tabs={HEARING_TABS} />
        </Col>
      </Row>
      <h5 className="text-h5 text-blue text-capitalize font-weight-500 mt-3">Appoint Chairperson</h5>
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            Appoint Chairperson
            <span className="ml-3">
              <Tooltip
                id="chairperson"
                title="Chairperson"
                text={
                  props.data.caseType === CaseType.POOR_PERFORMANCE
                    ? 'This is the person who presides over the proceedings. This person must be impartial, unbiased and not have any interest in the outcome. An internal resource  may  be  used,  or  the  services  of  an  outside  independent  and  skilled chair sourced. Refer to your Incapacity Poor Performance Policy for any specific prescripts.'
                    : `This is the person who presides over the proceedings. This person must be impartial, unbiased and not have any interest in the outcome. An internal resource  may  be  used,  or  the  services  of  an  outside  independent  and  skilled chair sourced. Refer to your Disciplinary Code for any specific prescripts.`
                }
              />
            </span>
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.initial_hearing_chairpersonType === 'internal'}
                    onChange={(): void => {
                      setFieldValue('initial_hearing_chairpersonType', 'internal');
                      setFieldValue('initial_hearing_chairpersonEmailAddress', '');
                      setFieldValue('initial_hearing_chairpersonFirstName', '');
                      setFieldValue('initial_hearing_chairpersonLastName', '');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted">Internal</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.initial_hearing_chairpersonType === 'external'}
                    onChange={(): void => {
                      setFieldValue('initial_hearing_chairpersonType', 'external');
                      setFieldValue('initial_hearing_chairpersonEmailAddress', '');
                      setFieldValue('initial_hearing_chairpersonFirstName', '');
                      setFieldValue('initial_hearing_chairpersonLastName', '');
                      setFieldValue('initial_hearing_chairpersonId', undefined);
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted">External</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      {values.initial_hearing_chairpersonType === 'external' && (
        <Row className="mt-4 mb-4">
          <Col md={3}>
            <FormGroup>
              <Label for="ChairpersonFirstName" className="text-default text-capitalize">
                Chairperson First Name*
              </Label>
              <FormField type={'text'} placeholder="Name & Last Name" name="initial_hearing_chairpersonFirstName" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'initial_hearing_chairpersonFirstName'} />
              </span>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="ChairpersonLastName" className="text-default text-capitalize">
                Chairperson Last Name*
              </Label>
              <FormField type={'text'} placeholder="Last Name" name="initial_hearing_chairpersonLastName" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'initial_hearing_chairpersonLastName'} />
              </span>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="Chairperson Email Address" className="text-default text-capitalize">
                Chairperson Email Address*
              </Label>
              <FormField type={'text'} placeholder="Email Address" name="initial_hearing_chairpersonEmailAddress" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'initial_hearing_chairpersonEmailAddress'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
      )}

      {values.initial_hearing_chairpersonType === 'internal' && (
        <Row className="mt-4 mb-4">
          <Col md={3}>
            <FormGroup>
              <Label for="employee" className="text-default text-capitalize">
                Select Approved Chairperson*
              </Label>
              <Field name={'initial_hearing_chairpersonId'}>
                {({ field }: FieldAttributes<FormikValues>) => (
                  <AsyncSelect
                    placeholder="Select Employee"
                    cacheOptions={false}
                    defaultOptions
                    value={{
                      label:
                        values.initial_hearing_chairpersonFirstName && values.initial_hearing_chairpersonLastName
                          ? values.initial_hearing_chairpersonFirstName +
                            ' ' +
                            values.initial_hearing_chairpersonLastName
                          : '',
                      value: values.initial_hearing_chairpersonId,
                    }}
                    loadOptions={(inputValue: string | null) => {
                      return loadOptionsChairperson(
                        inputValue,
                        props.data.caseData.organisationId,
                        props.data.caseData.employeeId,
                      );
                    }}
                    closeMenuOnSelect={false}
                    styles={selectStyles}
                    onChange={(value: ValueType<any>): void => handleChange(value)}
                    components={{ ValueContainer }}
                    isDisabled={readOnly}
                  />
                )}
              </Field>
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'initial_hearing_chairpersonId'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <EmailPreviewModalv3
            buttonText={'Email Chairperson'}
            getRecipients={getRecipients}
            disabled={
              !values.initial_hearing_chairpersonFirstName ||
              !values.initial_hearing_chairpersonLastName ||
              !values.initial_hearing_chairpersonEmailAddress ||
              readOnly
            }
            formValues={values}
            emailType={
              props.data.caseType === CaseType.POOR_PERFORMANCE
                ? EmailType.APPOINT_CHAIRPERSON_POOR_PERFORMANCE
                : EmailType.APPOINT_CHAIRPERSON_MISCONDUCT
            }
            masterProcessInstanceId={props.data.masterProcessInstanceId}
            processInstanceId={props.data.processInstanceId}
            currentUserId={currentUser?.id}
            sendFunction={sendEmail}
            getFlowableVariables={() => []}
            getAttachmentBucketKeys={() => ['/planners/LabourteqChairpersonchecklistandminutes.doc']}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default InitialHearingAppointChairpersonForm;
