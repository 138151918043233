import { ObjectSchema } from 'yup';
import { misconductFieldsMap } from '../../../utils/fields/misconductFieldsSetupMap';
import { poorPerformanceFieldsMap } from '../../../utils/fields/poorPerformanceFieldsSetupMap';
import { CaseType, TemplateType } from '../../../API';

export interface FieldsAndValidationSchema {
  fields: { [key: string]: string };
  validationSchema: ObjectSchema<object>;
}

const getMisconductFieldsByFormKey = (formKey: string): FieldsAndValidationSchema => {
  return misconductFieldsMap[formKey];
};

const getPoorPerformanceFieldsByFormKey = (formKey: string): FieldsAndValidationSchema => {
  return poorPerformanceFieldsMap[formKey];
};

export const getFieldsAndValidationSchema = (
  caseType: CaseType | null | undefined,
  formKey: string,
): FieldsAndValidationSchema => {
  const caseTypeFunctionMap: {
    [key: string]: (formKey: string) => FieldsAndValidationSchema;
  } = {
    [CaseType.MISCONDUCT]: getMisconductFieldsByFormKey,
    [CaseType.POOR_PERFORMANCE]: getPoorPerformanceFieldsByFormKey,
  };

  if (!caseType) {
    throw new Error('No case type');
  }
  const func = caseTypeFunctionMap[caseType];
  return func(formKey);
};

const letterTemplateMap: { [key: string]: TemplateType } = {
  'compile-notice-of-hearing': TemplateType.NOTICE_OF_HEARING_MISCONDUCT,
  'compile-notice-of-hearing-appeal': TemplateType.NOTICE_OF_HEARING_MISCONDUCT_APPEAL,
  'compile-notice-of-hearing-incapacity': TemplateType.NOTICE_OF_HEARING_POOR_PERFORMANCE,
  'compile-notice-of-hearing-incapacity-appeal': TemplateType.NOTICE_OF_HEARING_POOR_PERFORMANCE_APPEAL,
};

export const getDocumentTemplateType = (itemKey: string | undefined | null): TemplateType | null => {
  if (!itemKey) return null;
  return letterTemplateMap[itemKey];
};
