import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
  FlowableHistoricProcessInstance,
  FlowableHistoricProcessInstanceQuery,
  FlowableHistoricTask,
  FlowableHistoricTaskQuery,
  FlowableProcessInstance,
  FlowableProcessInstanceQuery,
  FlowableQueryResponse,
  FlowableTask,
  FlowableTaskQuery,
  FlowableVariable,
  FlowableVariableType,
} from './flowable-types';
import { FormikValues } from 'formik';

export enum Environments {
  DEV = 'DEV',
  QA = 'QA',
  UAT = 'UAT',
}

const tenantId = 'labourteq-dev';
const authToken = 'Basic bGFib3VydGVxLWRldjpMYWIxMjM=';

const getFlowableURLByCurrentEnvironment = (): string => {
  const url = window.location.href;
  if (url.includes('uat.labourteq')) return 'https://uat.flowable.labourteq.com/flowable-task/process-api/';
  else return 'https://qa.flowable.labourteq.com/flowable-task/process-api/';
};

const axiosFlowableInstance: AxiosInstance = axios.create({
  baseURL: getFlowableURLByCurrentEnvironment(),
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: authToken,
    'Access-Control-Allow-Origin': '*',
  },
});

const post = async <T>(url: string, requestBody: any): Promise<T> => {
  requestBody.tenantId = tenantId;
  const res: AxiosResponse = await axiosFlowableInstance.post(url, requestBody);
  return res.data;
};

const put = (url: string, requestBody: any): Promise<AxiosResponse> => {
  return axiosFlowableInstance.put(url, requestBody);
};

export const getCurrentEnvironment = (): Environments | null => {
  const url = window.location.href;

  //TODO add check for prod
  if (url.includes('uat.labourteq')) return Environments.UAT;
  else if (url.includes('qa.labourteq')) return Environments.DEV;
  else if (url.includes('localhost') || url.includes('3000')) return Environments.DEV;
  // return 'https://jwx31pby3h.execute-api.eu-central-1.amazonaws.com/notificati/items';
  else return null;
};


const queryFlowable = async <T>(url: string, query: FlowableTaskQuery): Promise<T> => {
  query.size = 10000;
  query.tenantId = tenantId;
  const res = await post<FlowableQueryResponse<T>>(url, query);
  return res.data;
};

const queryTasks = async (query: FlowableTaskQuery): Promise<FlowableTask[]> => {
  return queryFlowable<FlowableTask[]>(`query/tasks`, query);
};

const queryHistoricTasks = async (query: FlowableHistoricTaskQuery): Promise<FlowableHistoricTask[]> => {
  return queryFlowable<FlowableHistoricTask[]>(`query/historic-task-instances`, query);
};

const queryHistoricProcessInstances = async (
  query: FlowableHistoricProcessInstanceQuery,
): Promise<FlowableHistoricProcessInstance[]> => {
  return queryFlowable<FlowableHistoricProcessInstance[]>(`query/historic-process-instances`, query);
};

export const queryProcessInstances = async (
  query: FlowableProcessInstanceQuery,
): Promise<FlowableProcessInstance[]> => {
  return queryFlowable<FlowableProcessInstance[]>(`query/process-instances`, query);
};

export const queryHistoricProcessInstancesByMasterProcessInstanceIdAndEmailType = (
  masterProcessInstanceId: string,
  emailType: string,
): Promise<FlowableHistoricProcessInstance[]> => {
  const requestBody: FlowableHistoricProcessInstanceQuery = {
    size: 1000,
    includeProcessVariables: true,
    variables: [
      {
        name: 'masterProcessInstanceId',
        value: masterProcessInstanceId,
        operation: 'equals',
        type: 'string',
      },
      {
        name: 'emailType',
        value: emailType,
        operation: 'equals',
        type: 'string',
      },
    ],
  };
  return queryHistoricProcessInstances(requestBody);
};

export const queryMasterProcessInstancesByOrganisationId = (
  organisationId: string,
): Promise<FlowableProcessInstance[]> => {
  const requestBody: FlowableProcessInstanceQuery = {
    includeProcessVariables: true,
    variables: [
      {
        name: 'organisationId',
        value: organisationId,
        operation: 'equals',
        type: 'string',
      },
      {
        name: 'isMasterProcessInstance',
        value: true,
        operation: 'equals',
        type: 'boolean',
      },
    ],
  };
  return queryProcessInstances(requestBody);
};

export const updateProcessInstanceVariables = async (
  processInstanceId: string,
  variables: FlowableVariable[],
): Promise<AxiosResponse> => {
  return put(`runtime/process-instances/${processInstanceId}/variables`, variables);
};

export const convertToFlowableVariables = (item: FormikValues): FlowableVariable[] => {
  return Object.keys(item).map(key => {
    const name: string = key;
    const value: any = item[key];

    if (!['string', 'number', 'bigint', 'boolean', 'symbol'].includes(typeof value)) {
      throw new Error('Flowable variable values must be primitives! ' + name + ' is not primitive.');
    }

    let type: FlowableVariableType;
    if (typeof value === 'boolean') {
      type = 'boolean';
    } else if (typeof value === 'number') {
      type = 'long';
    } else {
      type = 'string';
    }

    return { name: name, value: value, type: type };
  });
};

export const queryHistoricTasksByMasterProcessInstanceIdAndFinished = (
  masterProcessInstanceId: string,
): Promise<FlowableHistoricTask[]> => {
  const requestBody: FlowableHistoricTaskQuery = {
    includeTaskLocalVariables: true,
    includeProcessVariables: true,
    finished: true,
    processVariables: [
      {
        name: 'masterProcessInstanceId',
        value: masterProcessInstanceId,
        operation: 'equals',
        type: 'string',
      },
    ],
  };
  return queryHistoricTasks(requestBody);
};

export const queryHistoricTaskInstancesByLambdaReferenceId = (
  lambdaReferenceId: string,
): Promise<FlowableHistoricTask[]> => {
  const requestBody: FlowableHistoricTaskQuery = {
    includeTaskLocalVariables: true,
    includeProcessVariables: false,
    taskVariables: [
      {
        name: 'lambdaReferenceId',
        value: lambdaReferenceId,
        operation: 'equals',
        type: 'string',
      },
    ],
  };
  return queryHistoricTasks(requestBody);
};

export const queryHistoricTaskInstancesByDocumentIdAndProcessInstanceId = (
  documentId: string,
  processInstanceId: string,
): Promise<FlowableHistoricTask[]> => {
  const requestBody: FlowableHistoricTaskQuery = {
    includeTaskLocalVariables: true,
    includeProcessVariables: false,
    processInstanceId: processInstanceId,
    taskVariables: [
      {
        name: 'documentId',
        value: documentId,
        operation: 'equals',
        type: 'string',
      },
    ],
  };
  return queryHistoricTasks(requestBody);
};

export const queryTasksByProcessInstanceId = (processInstanceId: string): Promise<FlowableTask[]> => {
  const query: FlowableTaskQuery = {
    includeTaskLocalVariables: true,
    includeProcessVariables: true,
    processInstanceId: processInstanceId,
  };
  return queryTasks(query);
};

export const queryTasksByProcessInstanceIdAndTaskDefinitionKey = (
  processInstanceId: string,
  taskDefinitionKey: string,
): Promise<FlowableTask[]> => {
  const query: FlowableTaskQuery = {
    includeTaskLocalVariables: true,
    includeProcessVariables: true,
    processInstanceId: processInstanceId,
    taskDefinitionKey: taskDefinitionKey,
  };
  return queryTasks(query);
};

export const queryHistoricTaskInstances = async (processInstanceId: string): Promise<FlowableHistoricTask[]> => {
  const query: FlowableHistoricTaskQuery = {
    includeTaskLocalVariables: true,
    includeProcessVariables: true,
    processInstanceId: processInstanceId,
  };
  return queryHistoricTasks(query);
};

export const queryHistoricProcessInstancesForDocumentFlows = (
  parentProcessInstanceId: string,
  documentType: string,
): Promise<FlowableHistoricProcessInstance[]> => {
  const requestBody: FlowableHistoricProcessInstanceQuery = {
    size: 1000,
    includeProcessVariables: true,
    superProcessInstanceId: parentProcessInstanceId,
    variables: [
      {
        name: 'documentType',
        value: documentType,
        operation: 'equals',
        type: 'string',
      },
    ],
  };
  return queryHistoricProcessInstances(requestBody);
};

export const queryHistoricProcessInstancesForDocumentFlowsonDocumentCreationTaskKey = (
  parentProcessInstanceId: string,
  documentCreationTaskKey: string,
): Promise<FlowableHistoricProcessInstance[]> => {
  const requestBody: FlowableHistoricProcessInstanceQuery = {
    size: 1000,
    includeProcessVariables: true,
    superProcessInstanceId: parentProcessInstanceId,
    variables: [
      {
        name: 'documentCreationTaskKey',
        value: documentCreationTaskKey,
        operation: 'equals',
        type: 'string',
      },
    ],
  };
  return queryHistoricProcessInstances(requestBody);
};

export const queryHistoricProcessInstancesByMasterProcessInstanceId = (
  masterProcessInstanceId: string,
): Promise<FlowableHistoricProcessInstance[]> => {
  const requestBody: FlowableHistoricProcessInstanceQuery = {
    size: 1000,
    includeProcessVariables: true,
    variables: [
      {
        name: 'masterProcessInstanceId',
        value: masterProcessInstanceId,
        operation: 'equals',
        type: 'string',
      },
    ],
  };
  return queryHistoricProcessInstances(requestBody);
};

export const queryHistoricMasterProcessInstancesByOrganisationId = (
  organisationId: string,
): Promise<FlowableHistoricProcessInstance[]> => {
  const requestBody: FlowableProcessInstanceQuery = {
    includeProcessVariables: false,
    variables: [
      {
        name: 'organisationId',
        value: organisationId,
        operation: 'equals',
        type: 'string',
      },
      {
        name: 'isMasterProcessInstance',
        value: true,
        operation: 'equals',
        type: 'boolean',
      },
      {
        name: 'caseType',
        value: 'NONE',
        operation: 'notEquals',
        type: 'string',
      },
    ],
  };
  return queryHistoricProcessInstances(requestBody);
};

export const queryHistoricMasterProcessInstancesByEmployeeIdAndCaseType = (
  employeeId: string,
  caseType: string,
): Promise<FlowableHistoricProcessInstance[]> => {
  const requestBody: FlowableHistoricProcessInstanceQuery = {
    size: 1000,
    includeProcessVariables: false,
    variables: [
      {
        name: 'isMasterProcessInstance',
        value: true,
        operation: 'equals',
        type: 'boolean',
      },
      {
        name: 'employeeId',
        value: employeeId,
        operation: 'equals',
        type: 'string',
      },
      {
        name: 'caseType',
        value: caseType,
        operation: 'equals',
        type: 'string',
      },
      {
        name: 'caseType',
        value: 'NONE',
        operation: 'notEquals',
        type: 'string',
      },
    ],
  };
  return queryHistoricProcessInstances(requestBody);
};

export const queryHistoricMasterProcessInstancesByEmployeeId = (
  employeeId: string,
): Promise<FlowableHistoricProcessInstance[]> => {
  const requestBody: FlowableHistoricProcessInstanceQuery = {
    size: 1000,
    includeProcessVariables: true,
    variables: [
      {
        name: 'isMasterProcessInstance',
        value: true,
        operation: 'equals',
        type: 'boolean',
      },
      {
        name: 'employeeId',
        value: employeeId,
        operation: 'equals',
        type: 'string',
      },
      {
        name: 'caseType',
        value: 'NONE',
        operation: 'notEquals',
        type: 'string',
      },
    ],
  };
  return queryHistoricProcessInstances(requestBody);
};

export const getTasksByAssignee = async (assignee: string): Promise<FlowableTask[]> => {
  const query: FlowableTaskQuery = {
    includeProcessVariables: true,
    assignee: assignee,
  };
  return queryTasks(query);
};

export const checkforCompletedTaskOnProcessInstance = async (
  processInstanceId: string,
  taskDefinitionKeys: string[],
): Promise<boolean> => {
  const promises = taskDefinitionKeys.map(t => {
    const query: FlowableHistoricTaskQuery = {
      includeTaskLocalVariables: true,
      includeProcessVariables: false,
      processInstanceId: processInstanceId,
      taskDefinitionKey: t,
    };
    return queryHistoricTasks(query);
  });

  const taskArrays = await Promise.all(promises);
  for (const taskArray of taskArrays) {
    for (const task of taskArray) {
      const directionVar = task.variables.find(v => v.name === 'direction');
      if (!!directionVar && directionVar.value !== 'back') {
        return true;
      }
    }
  }
  return false;
};

export const queryDocumentCreationTasks = (
  masterProcessInstanceId: string,
  documentType: string,
): Promise<FlowableHistoricTask[]> => {
  const query: FlowableHistoricTaskQuery = {
    includeTaskLocalVariables: true,
    includeProcessVariables: true,
    finished: true,
    deleteReason: null,
    taskDefinitionKey: 'generic-document-creation-task',
    processVariables: [
      {
        name: 'masterProcessInstanceId',
        value: masterProcessInstanceId,
        operation: 'equals',
      },
    ],
    taskVariables: [
      {
        name: 'documentType',
        value: documentType,
        operation: 'equals',
      },
    ],
  };
  return queryHistoricTasks(query);
};
