import React, { useContext, useEffect, useState } from 'react';
import { DropdownToggle, DropdownItem, DropdownMenu, Dropdown } from 'reactstrap';
import moment from 'moment';
import { CreateNotificationInput } from '../../API';
import { UserContext, UserContextProps } from '../../App';
import { updateNotification } from '../../graphql/mutations';
import { mutate } from '../../utils/graphql-utils';

const Notification: React.FC = () => {
  const [dropdownIsOpen, setDropdownOpen] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(4);
  const [expanded, setExpanded] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState<number | undefined>();
  const notifications: CreateNotificationInput[] | undefined = useContext<Partial<UserContextProps>>(UserContext)
    .userNotifications;

  const toggleDropdown = (): void => setDropdownOpen(prevState => !prevState);

  const markAsDeleted = async (notification: CreateNotificationInput): Promise<void> => {
    const item: CreateNotificationInput = { ...notification };
    const notificationItem = {
      id: item.id,
      masterProcessInstanceId: item.masterProcessInstanceId,
      notificationType: item.notificationType,
      organisationId: item.organisationId,
      lastModifiedDate: item.lastModifiedDate,
      read: item.read,
      deleted: true,
      message: item.message,
      userID: item.userID,
    };
    await mutate(updateNotification, notificationItem);
  };

  const showMore = (): void => {
    setExpanded(prevState => !prevState);
  };

  useEffect(() => {
    setItemsToShow(() => (expanded ? notifications!.length : 4));
  }, [expanded]);

  useEffect(() => {
    const length = notifications!.filter(notification => /*!notification.read && */ !notification.deleted).length;
    setUnreadNotifications(length);
  }, [JSON.stringify(notifications)]);

  return (
    <div>
      <Dropdown isOpen={dropdownIsOpen} toggle={toggleDropdown} size="lg">
        <DropdownToggle
          className="btn-primary btn-notification-icon"
          caret
          style={{ background: 'transparent', paddingLeft: '1em', paddingRight: '1.5em' }}
        >
          <div className="position-relative d-flex">
            {notifications && unreadNotifications && unreadNotifications > 0 && (
              <span
                className="d-none d-lg-block d-xl-block"
                style={{
                  position: 'absolute',
                  top: '-0.5em',
                  right: '0px',
                  left: '2.3em',
                  textAlign: 'center',
                  fontSize: '11px',
                  fontWeight: 600,
                  border: '1px solid white',
                  borderRadius: '50%',
                  background: 'white',
                  color: 'black',
                  minWidth: '1.5em',
                }}
              >
                {unreadNotifications < 10 ? unreadNotifications : 9 + '+'}
              </span>
            )}
            <i className="tim-icons icon-bell-55" style={{ fontSize: '2em', borderColor: 'white' }} />
          </div>
        </DropdownToggle>
        <DropdownMenu
          right
          style={{
            maxHeight: '600px',
            overflowY: 'auto',
            padding: 0,
            marginTop: '1.5em',
            borderRadius: '6px',
          }}
        >
          <div className="bg-gradient-primary dropdown-header py-2 px-3 d-flex justify-content-center">
            <h6 className="text-white">Notifications</h6>
          </div>
          {notifications &&
            notifications
              .filter(notification => !notification.deleted)
              .slice(0, itemsToShow)
              .map((notification: CreateNotificationInput, index: number) => (
                <div key={index}>
                  <DropdownItem
                    toggle={false}
                    // onClick={(e: any): Promise<void> => {
                    //   return markAsRead(notification);
                    // }}
                  >
                    <div>
                      <span className="d-flex">
                        <span className="text-muted pr-1 ml-0">
                          <i className="fas fa-circle text-success" style={{ fontSize: '0.8em' }} />
                        </span>
                        <span className="d-flex flex-column flex-wrap">
                          <span className="text-dark">
                            <span className="font-weight-bold">{notification.notificationType}: </span>
                            <span style={{ whiteSpace: 'nowrap' }}> {notification.message} </span>
                          </span>
                          <span className="text-muted pt-1">{moment(notification.lastModifiedDate).fromNow()}</span>
                        </span>
                        <span
                          className="text-danger font-weight-400 pl-3 ml-0 justify-content-end"
                          style={{ cursor: 'pointer' }}
                          onClick={(): Promise<void> => markAsDeleted(notification)}
                        >
                          <i className="tim-icons icon-simple-remove" style={{ fontSize: '1.1em' }} />
                        </span>
                      </span>
                      <hr style={{ border: '0.03em solid #adb5bd' }} />
                    </div>
                  </DropdownItem>
                </div>
              ))}
          {unreadNotifications && unreadNotifications > 4 && (
            <>
              {expanded ? (
                <span
                  className="d-flex justify-content-center text-muted pb-2 pt-1 font-weight-bold"
                  style={{ cursor: 'pointer' }}
                  onClick={showMore}
                >
                  See less
                </span>
              ) : (
                <span
                  className="d-flex justify-content-center text-muted pb-2 pt-1 font-weight-bold"
                  style={{ cursor: 'pointer' }}
                  onClick={showMore}
                >
                  See all recent activity
                </span>
              )}
            </>
          )}
          {unreadNotifications === 0 && (
            <span className="d-flex justify-content-center text-muted px-3 pb-2 pt-1 font-weight-bold">
              You have no recent notifications
            </span>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default Notification;
