/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCase = /* GraphQL */ `
  mutation CreateCase($input: CreateCaseInput!) {
    createCase(input: $input) {
      id
      caseNumber
      employeeId
      organizationId
      issueDescription
      sanction
      expiryDate
      transgression
      offence
      caseType
      incidents {
        summaryOfFacts
        date
        time
        location
        complainants
        witnesses
      }
      investigators {
        fullName
        emailAddress
        cognitoId
        employeeId
        investigatorTimeline
      }
      processInstanceId
      createdAt
      updatedAt
    }
  }
`;
export const updateCase = /* GraphQL */ `
  mutation UpdateCase($input: UpdateCaseInput!) {
    updateCase(input: $input) {
      id
      caseNumber
      employeeId
      organizationId
      issueDescription
      sanction
      expiryDate
      transgression
      offence
      caseType
      incidents {
        summaryOfFacts
        date
        time
        location
        complainants
        witnesses
      }
      investigators {
        fullName
        emailAddress
        cognitoId
        employeeId
        investigatorTimeline
      }
      processInstanceId
      createdAt
      updatedAt
    }
  }
`;
export const deleteCase = /* GraphQL */ `
  mutation DeleteCase($input: DeleteCaseInput!) {
    deleteCase(input: $input) {
      id
      caseNumber
      employeeId
      organizationId
      issueDescription
      sanction
      expiryDate
      transgression
      offence
      caseType
      incidents {
        summaryOfFacts
        date
        time
        location
        complainants
        witnesses
      }
      investigators {
        fullName
        emailAddress
        cognitoId
        employeeId
        investigatorTimeline
      }
      processInstanceId
      createdAt
      updatedAt
    }
  }
`;
export const createPastCase = /* GraphQL */ `
  mutation CreatePastCase($input: CreatePastCaseInput!) {
    createPastCase(input: $input) {
      id
      caseNumber
      employeeId
      organisationId
      sanction
      expiryDate
      transgression
      shortfallDescription
      frequency
      caseType
      caseStatus
      caseFiles {
        fileName
        key
        url
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePastCase = /* GraphQL */ `
  mutation UpdatePastCase($input: UpdatePastCaseInput!) {
    updatePastCase(input: $input) {
      id
      caseNumber
      employeeId
      organisationId
      sanction
      expiryDate
      transgression
      shortfallDescription
      frequency
      caseType
      caseStatus
      caseFiles {
        fileName
        key
        url
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePastCase = /* GraphQL */ `
  mutation DeletePastCase($input: DeletePastCaseInput!) {
    deletePastCase(input: $input) {
      id
      caseNumber
      employeeId
      organisationId
      sanction
      expiryDate
      transgression
      shortfallDescription
      frequency
      caseType
      caseStatus
      caseFiles {
        fileName
        key
        url
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDisciplinaryMatrix = /* GraphQL */ `
  mutation CreateDisciplinaryMatrix($input: CreateDisciplinaryMatrixInput!) {
    createDisciplinaryMatrix(input: $input) {
      id
      organisationId
      transgressions {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDisciplinaryMatrix = /* GraphQL */ `
  mutation UpdateDisciplinaryMatrix($input: UpdateDisciplinaryMatrixInput!) {
    updateDisciplinaryMatrix(input: $input) {
      id
      organisationId
      transgressions {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDisciplinaryMatrix = /* GraphQL */ `
  mutation DeleteDisciplinaryMatrix($input: DeleteDisciplinaryMatrixInput!) {
    deleteDisciplinaryMatrix(input: $input) {
      id
      organisationId
      transgressions {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee($input: CreateEmployeeInput!) {
    createEmployee(input: $input) {
      id
      organisationId
      employeeNumber
      idNumber
      passportNumber
      firstName
      lastName
      departmentID
      jobTitleID
      jobGradeID
      jobLevelID
      directManagerID
      allocatedManagerID
      hireDate
      race
      phoneNumbers {
        number
        phoneNumberType
      }
      physicalAddresses {
        physicalAddressType
        lineOne
        lineTwo
        city
        province
        postalCode
      }
      emails {
        emailAddressType
        address
      }
      hasDisability
      locationID
      placeOfWork
      workHours
      startTime
      endTime
      status
      disability
      disabilityDescription
      gender
      deleted
      suspensionDate
      createdAt
      updatedAt
      directManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      allocatedManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      location {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      department {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobTitle {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobGrade {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobLevel {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      id
      organisationId
      approvedChairPerson
      approvedHcApprover
      employeeNumber
      idNumber
      passportNumber
      firstName
      lastName
      departmentID
      jobTitleID
      jobGradeID
      jobLevelID
      directManagerID
      allocatedManagerID
      hireDate
      race
      phoneNumbers {
        number
        phoneNumberType
      }
      physicalAddresses {
        physicalAddressType
        lineOne
        lineTwo
        city
        province
        postalCode
      }
      emails {
        emailAddressType
        address
      }
      hasDisability
      locationID
      placeOfWork
      workHours
      startTime
      endTime
      status
      disability
      disabilityDescription
      gender
      deleted
      suspensionDate
      createdAt
      updatedAt
      directManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      allocatedManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      location {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      department {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobTitle {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobGrade {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobLevel {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee($input: DeleteEmployeeInput!) {
    deleteEmployee(input: $input) {
      id
      organisationId
      employeeNumber
      idNumber
      passportNumber
      firstName
      lastName
      departmentID
      jobTitleID
      jobGradeID
      jobLevelID
      directManagerID
      allocatedManagerID
      hireDate
      race
      phoneNumbers {
        number
        phoneNumberType
      }
      physicalAddresses {
        physicalAddressType
        lineOne
        lineTwo
        city
        province
        postalCode
      }
      emails {
        emailAddressType
        address
      }
      hasDisability
      locationID
      placeOfWork
      workHours
      startTime
      endTime
      status
      disability
      disabilityDescription
      gender
      deleted
      suspensionDate
      createdAt
      updatedAt
      directManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      allocatedManager {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
      location {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      department {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobTitle {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobGrade {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
      jobLevel {
        id
        name
        deleted
        organisationID
        createdAt
        updatedAt
      }
    }
  }
`;
export const createOrganisation = /* GraphQL */ `
  mutation CreateOrganisation($input: CreateOrganisationInput!) {
    createOrganisation(input: $input) {
      id
      organisationName
      vatNumber
      webUrl
      registrationNumber
      mainContactLastName
      mainContactFirstName
      mainContactEmail
      mainContactNumber
      ccmaContactName
      ccmaContactEmail
      ccmaContactPhysicalAddress
      facilitiesEmail
      payrollEmail
      itEmail
      switchboardTelephoneNumber
      companyHeadcount
      maxEmployees
      termsAndConditions {
        fileName
        key
        url
      }
      logo {
        fileName
        key
        url
      }
      poorPerformancePolicy {
        fileName
        key
        url
      }
      disciplinaryPolicy {
        fileName
        key
        url
      }
      logoPosition
      type
      disciplinaryCode {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      useCustomDatesForReportingPeriod
      useHCApprover
      defaultToHearingOnFinalWarning
      defaultReportingPeriod
      monthForEndOfReportingYear
      customReportingPeriodStartDate
      customReportingPeriodEndDate
      hrContactUserID
      createdAt
      updatedAt
      hrContactUser {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      locations {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
      departments {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOrganisation = /* GraphQL */ `
  mutation UpdateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      id
      organisationName
      vatNumber
      webUrl
      registrationNumber
      mainContactLastName
      mainContactFirstName
      mainContactEmail
      mainContactNumber
      ccmaContactName
      ccmaContactEmail
      ccmaContactPhysicalAddress
      facilitiesEmail
      payrollEmail
      itEmail
      switchboardTelephoneNumber
      companyHeadcount
      maxEmployees
      termsAndConditions {
        fileName
        key
        url
      }
      logo {
        fileName
        key
        url
      }
      poorPerformancePolicy {
        fileName
        key
        url
      }
      disciplinaryPolicy {
        fileName
        key
        url
      }
      logoPosition
      type
      disciplinaryCode {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      useCustomDatesForReportingPeriod
      useHCApprover
      defaultToHearingOnFinalWarning
      defaultReportingPeriod
      monthForEndOfReportingYear
      customReportingPeriodStartDate
      customReportingPeriodEndDate
      hrContactUserID
      createdAt
      updatedAt
      hrContactUser {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      locations {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
      departments {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOrganisationSettings = /* GraphQL */ `
  mutation UpdateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      id
      organisationName
      vatNumber
      webUrl
      registrationNumber
      mainContactLastName
      mainContactFirstName
      mainContactEmail
      mainContactNumber
      ccmaContactName
      ccmaContactEmail
      ccmaContactPhysicalAddress
      facilitiesEmail
      payrollEmail
      itEmail
      switchboardTelephoneNumber
      companyHeadcount
      maxEmployees
      termsAndConditions {
        fileName
        key
        url
      }
      logo {
        fileName
        key
        url
      }
      poorPerformancePolicy {
        fileName
        key
        url
      }
      disciplinaryPolicy {
        fileName
        key
        url
      }
      logoPosition
      type
      disciplinaryCode {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      useCustomDatesForReportingPeriod
      useHCApprover
      defaultToHearingOnFinalWarning
      defaultReportingPeriod
      monthForEndOfReportingYear
      customReportingPeriodStartDate
      customReportingPeriodEndDate
      hrContactUserID
      createdAt
      updatedAt
      hrContactUser {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      locations {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
      departments {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOrganisation = /* GraphQL */ `
  mutation DeleteOrganisation($input: DeleteOrganisationInput!) {
    deleteOrganisation(input: $input) {
      id
      organisationName
      vatNumber
      webUrl
      registrationNumber
      mainContactLastName
      mainContactFirstName
      mainContactEmail
      mainContactNumber
      ccmaContactName
      ccmaContactEmail
      ccmaContactPhysicalAddress
      facilitiesEmail
      payrollEmail
      itEmail
      switchboardTelephoneNumber
      companyHeadcount
      maxEmployees
      termsAndConditions {
        fileName
        key
        url
      }
      logo {
        fileName
        key
        url
      }
      poorPerformancePolicy {
        fileName
        key
        url
      }
      disciplinaryPolicy {
        fileName
        key
        url
      }
      logoPosition
      type
      disciplinaryCode {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      useCustomDatesForReportingPeriod
      useHCApprover
      defaultToHearingOnFinalWarning
      defaultReportingPeriod
      monthForEndOfReportingYear
      customReportingPeriodStartDate
      customReportingPeriodEndDate
      hrContactUserID
      createdAt
      updatedAt
      hrContactUser {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      locations {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
      departments {
        items {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      cognitoSub
      emailAddress
      firstName
      lastName
      active
      fontPreference
      roles
      organisationId
      loginAttempts
      createdAt
      updatedAt
      employee {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      cognitoSub
      emailAddress
      firstName
      lastName
      active
      fontPreference
      roles
      organisationId
      loginAttempts
      createdAt
      updatedAt
      employee {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      cognitoSub
      emailAddress
      firstName
      lastName
      active
      fontPreference
      roles
      organisationId
      loginAttempts
      createdAt
      updatedAt
      employee {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        hasDisability
        locationID
        placeOfWork
        workHours
        startTime
        endTime
        status
        disability
        disabilityDescription
        gender
        deleted
        suspensionDate
        createdAt
        updatedAt
        directManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        allocatedManager {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
        location {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        department {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobTitle {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobGrade {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
        jobLevel {
          id
          name
          deleted
          organisationID
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      id
      masterProcessInstanceId
      notificationType
      organisationId
      lastModifiedDate
      read
      deleted
      message
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
      masterProcessInstanceId
      notificationType
      organisationId
      lastModifiedDate
      read
      deleted
      message
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input) {
      id
      masterProcessInstanceId
      notificationType
      organisationId
      lastModifiedDate
      read
      deleted
      message
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
     id
      processInstanceId
      userId
      type
      createdDate
      lastModifiedDate
      note
      deleted
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      id
      processInstanceId
      userId
      type
      createdDate
      lastModifiedDate
      note
      deleted
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      id
      processInstanceId
      userId
      type
      createdDate
      lastModifiedDate
      note
      deleted
      createdAt
      updatedAt
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation($input: DeleteLocationInput!) {
    deleteLocation(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment($input: CreateDepartmentInput!) {
    createDepartment(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const updateDepartment = /* GraphQL */ `
  mutation UpdateDepartment($input: UpdateDepartmentInput!) {
    updateDepartment(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment($input: DeleteDepartmentInput!) {
    deleteDepartment(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const createJobTitle = /* GraphQL */ `
  mutation CreateJobTitle($input: CreateJobTitleInput!) {
    createJobTitle(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const updateJobTitle = /* GraphQL */ `
  mutation UpdateJobTitle($input: UpdateJobTitleInput!) {
    updateJobTitle(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const deleteJobTitle = /* GraphQL */ `
  mutation DeleteJobTitle($input: DeleteJobTitleInput!) {
    deleteJobTitle(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const createJobGrade = /* GraphQL */ `
  mutation CreateJobGrade($input: CreateJobGradeInput!) {
    createJobGrade(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const updateJobGrade = /* GraphQL */ `
  mutation UpdateJobGrade($input: UpdateJobGradeInput!) {
    updateJobGrade(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const deleteJobGrade = /* GraphQL */ `
  mutation DeleteJobGrade($input: DeleteJobGradeInput!) {
    deleteJobGrade(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const createJobLevel = /* GraphQL */ `
  mutation CreateJobLevel($input: CreateJobLevelInput!) {
    createJobLevel(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const updateJobLevel = /* GraphQL */ `
  mutation UpdateJobLevel($input: UpdateJobLevelInput!) {
    updateJobLevel(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const deleteJobLevel = /* GraphQL */ `
  mutation DeleteJobLevel($input: DeleteJobLevelInput!) {
    deleteJobLevel(input: $input) {
      id
      name
      deleted
      organisationID
      createdAt
      updatedAt
    }
  }
`;
export const createTemplateDocumentVersion = /* GraphQL */ `
  mutation CreateTemplateDocumentVersion(
    $input: CreateTemplateDocumentVersionInput!
  ) {
    createTemplateDocumentVersion(input: $input) {
      id
      processInstanceId
      bucketPath
      templateType
      version
      stringifiedContent
      parentID
      organisationID
      uploadedFileBucketPath
      signedCopy
      createdAt
      updatedAt
      auditLogs {
        items {
          id
          userID
          type
          timeStamp
          description
          content
          organisationID
          taskId
          processInstanceId
          userRole
          userRoles
          caseRole
          caseRoles
          relevantEmployeeId
          dummyVar
          emailType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateTemplateDocumentVersion = /* GraphQL */ `
  mutation UpdateTemplateDocumentVersion(
    $input: UpdateTemplateDocumentVersionInput!
  ) {
    updateTemplateDocumentVersion(input: $input) {
      id
      processInstanceId
      bucketPath
      templateType
      version
      stringifiedContent
      parentID
      organisationID
      uploadedFileBucketPath
      signedCopy
      createdAt
      updatedAt
      auditLogs {
        items {
          id
          userID
          type
          timeStamp
          description
          content
          organisationID
          taskId
          processInstanceId
          userRole
          userRoles
          caseRole
          caseRoles
          relevantEmployeeId
          dummyVar
          emailType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteTemplateDocumentVersion = /* GraphQL */ `
  mutation DeleteTemplateDocumentVersion(
    $input: DeleteTemplateDocumentVersionInput!
  ) {
    deleteTemplateDocumentVersion(input: $input) {
      id
      processInstanceId
      bucketPath
      templateType
      version
      stringifiedContent
      parentID
      organisationID
      uploadedFileBucketPath
      signedCopy
      createdAt
      updatedAt
      auditLogs {
        items {
          id
          userID
          type
          timeStamp
          description
          content
          organisationID
          taskId
          processInstanceId
          userRole
          userRoles
          caseRole
          caseRoles
          relevantEmployeeId
          dummyVar
          emailType
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAuditLog = /* GraphQL */ `
  mutation CreateAuditLog($input: CreateAuditLogInput!) {
    createAuditLog(input: $input) {
      id
      userID
      type
      timeStamp
      description
      content
      organisationID
      taskId
      processInstanceId
      userRole
      userRoles
      caseRole
      caseRoles
      relevantEmployeeId
      dummyVar
      emailType
      createdAt
      updatedAt
      organisation {
        id
        organisationName
        vatNumber
        webUrl
        registrationNumber
        mainContactLastName
        mainContactFirstName
        mainContactEmail
        mainContactNumber
        ccmaContactName
        ccmaContactEmail
        ccmaContactPhysicalAddress
        facilitiesEmail
        payrollEmail
        itEmail
        switchboardTelephoneNumber
        companyHeadcount
        maxEmployees
        termsAndConditions {
          fileName
          key
          url
        }
        logo {
          fileName
          key
          url
        }
        poorPerformancePolicy {
          fileName
          key
          url
        }
        disciplinaryPolicy {
          fileName
          key
          url
        }
        logoPosition
        type
        disciplinaryCode {
          transgressionCategory
          transgression
          default
          active
        }
        useCustomDatesForReportingPeriod
        useHCApprover
        defaultToHearingOnFinalWarning
        defaultReportingPeriod
        monthForEndOfReportingYear
        customReportingPeriodStartDate
        customReportingPeriodEndDate
        hrContactUserID
        createdAt
        updatedAt
        hrContactUser {
          id
          cognitoSub
          emailAddress
          firstName
          lastName
          active
          fontPreference
          roles
          organisationId
          loginAttempts
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        departments {
          nextToken
        }
      }
      user {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      templateDocumentVersion {
        id
        processInstanceId
        bucketPath
        templateType
        version
        stringifiedContent
        parentID
        organisationID
        uploadedFileBucketPath
        signedCopy
        createdAt
        updatedAt
        auditLogs {
          nextToken
        }
      }
    }
  }
`;
export const updateAuditLog = /* GraphQL */ `
  mutation UpdateAuditLog($input: UpdateAuditLogInput!) {
    updateAuditLog(input: $input) {
      id
      userID
      type
      timeStamp
      description
      content
      organisationID
      taskId
      processInstanceId
      userRole
      userRoles
      caseRole
      caseRoles
      relevantEmployeeId
      dummyVar
      emailType
      createdAt
      updatedAt
      organisation {
        id
        organisationName
        vatNumber
        webUrl
        registrationNumber
        mainContactLastName
        mainContactFirstName
        mainContactEmail
        mainContactNumber
        ccmaContactName
        ccmaContactEmail
        ccmaContactPhysicalAddress
        facilitiesEmail
        payrollEmail
        itEmail
        switchboardTelephoneNumber
        companyHeadcount
        maxEmployees
        termsAndConditions {
          fileName
          key
          url
        }
        logo {
          fileName
          key
          url
        }
        poorPerformancePolicy {
          fileName
          key
          url
        }
        disciplinaryPolicy {
          fileName
          key
          url
        }
        logoPosition
        type
        disciplinaryCode {
          transgressionCategory
          transgression
          default
          active
        }
        useCustomDatesForReportingPeriod
        useHCApprover
        defaultToHearingOnFinalWarning
        defaultReportingPeriod
        monthForEndOfReportingYear
        customReportingPeriodStartDate
        customReportingPeriodEndDate
        hrContactUserID
        createdAt
        updatedAt
        hrContactUser {
          id
          cognitoSub
          emailAddress
          firstName
          lastName
          active
          fontPreference
          roles
          organisationId
          loginAttempts
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        departments {
          nextToken
        }
      }
      user {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      templateDocumentVersion {
        id
        processInstanceId
        bucketPath
        templateType
        version
        stringifiedContent
        parentID
        organisationID
        uploadedFileBucketPath
        signedCopy
        createdAt
        updatedAt
        auditLogs {
          nextToken
        }
      }
    }
  }
`;
export const deleteAuditLog = /* GraphQL */ `
  mutation DeleteAuditLog($input: DeleteAuditLogInput!) {
    deleteAuditLog(input: $input) {
      id
      userID
      type
      timeStamp
      description
      content
      organisationID
      taskId
      processInstanceId
      userRole
      userRoles
      caseRole
      caseRoles
      relevantEmployeeId
      dummyVar
      emailType
      createdAt
      updatedAt
      organisation {
        id
        organisationName
        vatNumber
        webUrl
        registrationNumber
        mainContactLastName
        mainContactFirstName
        mainContactEmail
        mainContactNumber
        ccmaContactName
        ccmaContactEmail
        ccmaContactPhysicalAddress
        facilitiesEmail
        payrollEmail
        itEmail
        switchboardTelephoneNumber
        companyHeadcount
        maxEmployees
        termsAndConditions {
          fileName
          key
          url
        }
        logo {
          fileName
          key
          url
        }
        poorPerformancePolicy {
          fileName
          key
          url
        }
        disciplinaryPolicy {
          fileName
          key
          url
        }
        logoPosition
        type
        disciplinaryCode {
          transgressionCategory
          transgression
          default
          active
        }
        useCustomDatesForReportingPeriod
        useHCApprover
        defaultToHearingOnFinalWarning
        defaultReportingPeriod
        monthForEndOfReportingYear
        customReportingPeriodStartDate
        customReportingPeriodEndDate
        hrContactUserID
        createdAt
        updatedAt
        hrContactUser {
          id
          cognitoSub
          emailAddress
          firstName
          lastName
          active
          fontPreference
          roles
          organisationId
          loginAttempts
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        departments {
          nextToken
        }
      }
      user {
        id
        cognitoSub
        emailAddress
        firstName
        lastName
        active
        fontPreference
        roles
        organisationId
        loginAttempts
        createdAt
        updatedAt
        employee {
          id
          organisationId
          employeeNumber
          idNumber
          passportNumber
          firstName
          lastName
          departmentID
          jobTitleID
          jobGradeID
          jobLevelID
          directManagerID
          allocatedManagerID
          hireDate
          race
          hasDisability
          locationID
          placeOfWork
          workHours
          startTime
          endTime
          status
          disability
          disabilityDescription
          gender
          deleted
          suspensionDate
          createdAt
          updatedAt
        }
      }
      templateDocumentVersion {
        id
        processInstanceId
        bucketPath
        templateType
        version
        stringifiedContent
        parentID
        organisationID
        uploadedFileBucketPath
        signedCopy
        createdAt
        updatedAt
        auditLogs {
          nextToken
        }
      }
    }
  }
`;
