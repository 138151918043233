import React from 'react';
import TopBarComponent from '../../components/topBar/TopBar.component';

export const DisputesInfoScreen: React.FC = () => {
  return (
    <>
      <TopBarComponent
        title={'Disputes'}
        subTitle={'View Dispute Module Information'}
        hideSearch={true}
      ></TopBarComponent>
      <div className="content">
        <div className="text-muted px-4 pt-4" style={{ fontSize: '16px' }}>
          The schematic below provides a roadmap of the process you will follow in LabourTeq as you capture instances of
          employees referring matters to a dispute phase. This may include the CCMA, Bargaining Council dispute
          resolution forums, or the Labour Court.
          <br />
          <div>
            <img src={require('../../assets/img/Dispute.png')} alt={'Dispute Flow'} />
          </div>
        </div>
      </div>
    </>
  );
};
