import React, { useContext } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Tooltip } from '../../../components/tooltips/Tooltip.component';
import FormField from '../../../forms/fields/FormField.component';
import { ErrorMessage, Field, FieldAttributes, FormikProps, FormikValues, useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';
import {
  selectStyles,
  SelectType,
  ValueContainer,
} from '../../../components/reactSelect/ReactSelectComponents.component';
import { ValueType } from 'react-select';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../components/taskContainer/TaskContainer';
import { UserContext, UserContextProps } from '../../../App';
import { listActiveEmployeesByOrganisationId } from '../../../utils/graphql-utils';
import { EmailRecipient, EmailType } from '../../../utils/email-utils';
import { FormProps } from '../../WorkflowContainer/workflow-utils';
import { Employee } from '../../../models';
import { EmailPreviewModalv3 } from '../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { convertToFlowableVariables } from '../../../utils/flowable/flowable-utils';
import { notEmpty } from '../../../utils/typescript-utils';
import ApiDataContext from '../../../contexts';

const PreparePerformanceCounsellingForm: React.FC<FormProps> = props => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const employeeListApiData = useContext(ApiDataContext);

  const handleChange = (value: { value: string; label: string }, fieldName: string): void => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data.map((employee: Employee) => {
      const emailAddresses = employee.emails.filter(notEmpty).map(item => item.address);
      return {
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
        attendeeId: employee.id,
        attendeeEmailAddress: emailAddresses[0] ? emailAddresses[0] : 'noEmail@labourteq.co.za',
        attendeeName: employee.firstName + ' ' + employee.lastName,
      };
    });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option.label ? option!.label.toLowerCase().includes(inputValue.toLowerCase()) : '';
    });
    return filteredData;
  };

  // const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
  //   if (currentUser?.organisationId) {
  //     return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
  //       const preparedData = prepareData(data);
  //       return !inputValue ? preparedData : filterItems(preparedData, inputValue);
  //     });
  //   }
  // };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId && typeof employeeListApiData.initialEmployeesPage === 'undefined') {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        employeeListApiData.employees = !inputValue ? preparedData : filterItems(preparedData, inputValue);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    } else {
      return !inputValue
        ? employeeListApiData.initialEmployeesPage
        : filterItems(employeeListApiData.initialEmployeesPage, inputValue);
    }
  };

  const getRecipients = (): EmailRecipient[] => {
    const recipients: EmailRecipient[] = [];
    values.counsellingSession.counsellingAttendees.forEach((item: any) => {
      if (item.attendeeEmailAddress && item.attendeeName)
        recipients.push({ emailAddress: item.attendeeEmailAddress, name: item.attendeeName });
    });
    return recipients;
  };

  return (
    <>
      <Row>
        <Col>
          <Label className="text-default d-block">Compile invite for discussion?</Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.compileInvite}
                    onChange={(): void => {
                      setFieldValue('compileInvite', true);
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="no"
                    checked={!values.compileInvite}
                    onChange={(): void => setFieldValue('compileInvite', false)}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>

      {values.compileInvite && (
        <>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="employeeDiscussionDate" className="text-default text-capitalize">
                  Counselling Date*
                </Label>
                <FormField name={`counsellingSession.counsellingDate`} placeholder="Counselling Date" type="date" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={`counsellingSession.counsellingDate`} />
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="counsellingTime" className="text-default text-capitalize">
                  Counselling Time*
                </Label>
                <FormField type={'time'} placeholder="Counselling Time" name={`counsellingSession.counsellingTime`} />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={`counsellingSession.counsellingTime`} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="employeeDiscussionLocation" className="text-default text-capitalize">
                  Location*
                </Label>
                <FormField name={`counsellingSession.counsellingLocation`} placeholder="Location" type="text" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={`counsellingSession.counsellingLocation`} />
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="counsellingAttendees" className="text-default">
                  Attendees (other than employee)*
                </Label>
                <Field name={`counsellingSession.counsellingAttendees`}>
                  {({ field }: FieldAttributes<FormikValues>) => (
                    <AsyncSelect
                      {...field}
                      isMulti
                      placeholder="Select Attendees"
                      cacheOptions
                      defaultOptions
                      isClearable={false}
                      loadOptions={loadOptions}
                      closeMenuOnSelect={false}
                      styles={selectStyles}
                      onChange={(value: ValueType<any>) =>
                        handleChange(value, `counsellingSession.counsellingAttendees`)
                      }
                      components={{ ValueContainer }}
                    />
                  )}
                </Field>
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={`counsellingSession.counsellingAttendees`} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="d-flex">
                <EmailPreviewModalv3
                  buttonText={'Send Email/Invite to employee'}
                  disabled={
                    !(
                      props.data.employeeWorkEmailAddresses.length &&
                      values.counsellingSession?.counsellingDate &&
                      values.counsellingSession?.counsellingTime
                    )
                  }
                  formValues={values.counsellingSession}
                  emailType={EmailType.POOR_PERFORMANCE_COUNSELLING_EMPLOYEE}
                  masterProcessInstanceId={props.data.masterProcessInstanceId}
                  processInstanceId={props.data.processInstanceId}
                  currentUserId={currentUser?.id}
                  getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
                />
                {!props.data.employeeWorkEmailAddresses.length && (
                  <div className="mt-2">
                    <Tooltip
                      id="noWorkEmailAddress"
                      title={`No work email address`}
                      text={`We can only send an email to an employee's designated work email address. You will need to update this employee's information with a work email address.`}
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={4}>
              <EmailPreviewModalv3
                buttonText={'Send Email/Invite to Attendees'}
                disabled={
                  !(
                    values.counsellingSession?.counsellingDate &&
                    values.counsellingSession?.counsellingTime &&
                    values.counsellingSession?.counsellingAttendees &&
                    values.counsellingSession?.counsellingAttendees.length
                  )
                }
                formValues={values.counsellingSession}
                emailType={EmailType.POOR_PERFORMANCE_COUNSELLING_ATTENDEES}
                masterProcessInstanceId={props.data.masterProcessInstanceId}
                processInstanceId={props.data.processInstanceId}
                currentUserId={currentUser?.id}
                getRecipients={getRecipients}
                getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default PreparePerformanceCounsellingForm;
