import { CreateEmployeeInput } from '../../../API';

export const blankEmployee: CreateEmployeeInput = {
  id: null,
  phoneNumbers: [],
  physicalAddresses: [],
  emails: [],
  hasDisability: null,
  placeOfWork: null,
  locationID: '',
  workHours: null,
  organisationId: '',
  employeeNumber: '',
  idNumber: '',
  firstName: '',
  lastName: '',
  departmentID: '',
  jobTitleID: 'none',
  jobGradeID: 'none',
  jobLevelID: 'none',
  directManagerID: 'none',
  allocatedManagerID: 'none',
  hireDate: '',
  status: null,
  race: null,
  startTime: '',
  endTime: '',
  disability: null,
  gender: null,
  disabilityDescription: null,
  passportNumber: '',
  deleted: null,
  suspensionDate: null,
  // otherStatus: null,
};
