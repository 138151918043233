import React, { useState } from 'react';
import { toTitleCase } from '../../utils/string-utils';

export interface ProgressUpdateHistory {
  description: string;
  updates: {
    progressRemarks: string;
    progressStatus: string;
    reviewPeriod: string;
  }[];
}
interface ShortfallProgressUpdatesAccordionProps {
  data: ProgressUpdateHistory;
  index: number;
}

interface ShortfallProgressUpdatesAccordionState {
  open: boolean;
}

const ShortfallProgressUpdatesAccordion: React.FC<ShortfallProgressUpdatesAccordionProps> = (
  props: ShortfallProgressUpdatesAccordionProps,
) => {
  const [state, setState] = useState<ShortfallProgressUpdatesAccordionState>({ open: false });
  const { data } = props;
  const { updates, description } = data;

  return (
    <>
      <div
        className="d-flex justify-content-between tab-background pointer px-3 py-3 my-1"
        onClick={(): void => setState(oldState => ({ ...oldState, open: !state.open }))}
      >
        <div>
          <span className="text-default px-1">{props.index + 1}.</span>
          <span className="h-75 px-1 font-weight-light text-muted">{description}</span>
        </div>
        <span>
          {state.open ? (
            <i className="tim-icons icon-minimal-up text-default font-weight-bold" />
          ) : (
            <i className="tim-icons icon-minimal-down text-default font-weight-bold" />
          )}
        </span>
      </div>
      {state.open && (
        <div className="d-flex flex-column">
          {updates?.map((update, index) => {
            return (
              <div
                key={index}
                className="py-2 d-flex justify-content-between"
                style={{ border: '#f6f9fc 0.05em solid' }}
              >
                <span className="px-2 text-dark">{update.progressRemarks}</span>
                <span className="px-2 text-dark">{update.reviewPeriod}</span>
                <span className="px-2 text-dark">{toTitleCase(update.progressStatus, '_')}</span>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ShortfallProgressUpdatesAccordion;
