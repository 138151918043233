/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
// reactstrap components
import { Alert, Button, CardFooter, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import './Login.styles.scss';
import { UserContext } from '../../../App';
import { UserContextProps } from '../../../App';
import { useErrorHandler } from '../../../utils/notification-utils';
import aws_config from '../../../aws-exports';

// import AdhocButton from "../../../components/AdhocButton/AdhocButton.component";

import pkg from '../../../../package.json';
// import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

const INITIAL_LOGIN_DETAILS = {
  username: '',
  password: '',
  error: '',
  loading: false,
  acceptedTerms: false,
  showPassword: false,
};
interface LoginViewProps {
  onForgotPassword: () => void;
  onAuthenticated: (user?: CognitoUser, firstLogin?: boolean, mfa?: boolean) => void;
  user?: CognitoUser;
}

interface LoginViewState {
  username: string;
  password: string;
  loading: boolean;
  error: string;
  user?: CognitoUser;
  acceptedTerms: boolean;
  showPassword: boolean;
}

const LoginView: React.FC<LoginViewProps> = (props: LoginViewProps) => {
  const [isTermsAndConsOpen, setIsTermsAndConsOpen] = useState<boolean>(false);
  const [state, setState] = useState<LoginViewState>(INITIAL_LOGIN_DETAILS);
  const { onForgotPassword, onAuthenticated } = props;
  const { username, password, loading, user = props.user, error, acceptedTerms, showPassword } = state;
  const handleError = useErrorHandler();
  const contextValue = useContext<Partial<UserContextProps>>(UserContext);

  const toggleIsTermsAndCons = (e: React.MouseEvent<HTMLSpanElement, MouseEvent> | null): void => {
    if (e) {
      e.preventDefault();
    }
    setIsTermsAndConsOpen(!isTermsAndConsOpen);
  };

  const handleLogin = (): void => {
    if (!state.password || !state.username) {
      setState(oldState => ({ ...oldState, error: 'Username and Password required' }));
      return;
    }

    setState(oldState => ({ ...oldState, error: '', loading: true }));
    Auth.signIn(username.toLowerCase(), password)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          onAuthenticated(user, true);
          if (contextValue.loginCallBack) contextValue.loginCallBack(user);
        } else if (user.challengeName === 'CUSTOM_CHALLENGE') {
          onAuthenticated(user, true, true);
          if (contextValue.loginCallBack) contextValue.loginCallBack(user);
        } else {
          // Auth.completeNewPassword(user, password, null);
          onAuthenticated(user);
          setState(oldState => ({ ...oldState, user }));
          if (contextValue.loginCallBack) {
            contextValue.loginCallBack(user);
          } else {
            setState(oldState => ({ ...oldState, loading: false }));
          }
        }
      })
      .catch(error => {
        handleError(error);
        setState(oldState => ({
          ...oldState,
          error: error.message.replace('PreAuthentication failed with error', ''),
          loading: false,
        }));
      });
  };
  const handleLogout = (): void => {
    Auth.signOut().then(() => {
      onAuthenticated();
      setState(() => ({ ...INITIAL_LOGIN_DETAILS, loading: false }));
      if (contextValue.logoutCallBack) contextValue.logoutCallBack();
    });
  };

  const handleToggleTerms = (): void => {
    setState(oldState => ({ ...oldState, acceptedTerms: !acceptedTerms }));
  };

  const handleToggleShowPassword = (): void => {
    setState(oldState => ({ ...oldState, showPassword: !showPassword }));
  };

  const handleChangeUsername = (event: ChangeEvent<HTMLInputElement>): void => {
    const username = event.target.value.trim();
    setState(oldState => ({ ...oldState, error: '', username }));
  };
  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>): void => {
    const password = event.target.value.trim();
    setState(oldState => ({ ...oldState, error: '', password }));
  };

  function getButtonDetails(): [string, () => void] {
    if (loading) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return ['Signing In', (): void => {}];
    }
    if (user) {
      return ['Loading', handleLogout];
    } else {
      return ['Login', handleLogin];
    }
  }
  const [buttonText, buttonAction] = getButtonDetails();
  const togglePasswordIcon = showPassword ? 'fas fa-eye-slash' : 'fas fa-eye';

  useEffect(() => {
    const config = aws_config;
    config.aws_appsync_authenticationType = 'AMAZON_COGNITO_USER_POOLS';
    Amplify.configure(config);
  }, []);

  return (
    <>
      {!!error && (
        <Alert aria-label="error-message" className=" d-flex justify-content-center" color="danger" fade={false}>
          <span>{error}</span>
        </Alert>
      )}
      <FormGroup className="p-1">
        <Input
          aria-label="login"
          placeholder="Username"
          type="text"
          value={username}
          disabled={loading}
          onChange={handleChangeUsername}
        />
      </FormGroup>
      <FormGroup className="p-1 d-flex ">
        <span
          aria-label="show-password"
          onClick={handleToggleShowPassword}
          className="position-absolute"
          style={{ color: '#979797', fontSize: '1.2em', right: '2%' }}
        >
          <i className={`${togglePasswordIcon} d-flex align-self-center  pt-3 pr-2 input-icon`} />
        </span>
        <Input
          aria-label="password"
          placeholder="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handleChangePassword}
          disabled={loading}
        />
      </FormGroup>

      <TermsAndConditions isOpen={isTermsAndConsOpen} toggleIsOpen={() => toggleIsTermsAndCons(null)} />
      <FormGroup check className="pl-1">
        <Label check>
          <Input
            aria-label="accept-terms"
            type="checkbox"
            onChange={handleToggleTerms}
            defaultChecked={acceptedTerms}
          />
          <span className="form-check-sign " />
          <span className="text-muted">I agree to the </span>

          <span className="text-muted" onClick={e => toggleIsTermsAndCons(e)}>
            <u>terms and conditions</u>.
          </span>
        </Label>
      </FormGroup>

      <CardFooter className="d-flex flex-column align-items-center">
        <Button
          block
          aria-label="submit"
          className="btn-round mb-2 text-uppercase font-weight-light"
          color="primary"
          onClick={buttonAction}
          size="lg"
          disabled={loading || (buttonText === 'Login' ? !acceptedTerms : false)}
        >
          {buttonText}
        </Button>
        <div className="p-2">
          <h4 aria-label="forgot-password" className="text-muted" onClick={onForgotPassword}>
            <small>Forgot Password?</small>
          </h4>
        </div>
        <div className="p-1">
          <h5 className="text-muted text-lg-center">
            <small>
              Disclaimer: The labourteq software solution does not constitute legal advice.
              <br />
              Labourteq is strictly copyright protected and any unauthorised use/copying/redistribution is prohibited.
              Non-compliance will lead to prosecution.
              <br />© Labourteq Solutions (Pty) Ltd 2019 (Registration number 2019/588031/07)
            </small>
          </h5>
          <h6>v{pkg.version}</h6>
        </div>
      </CardFooter>
    </>
  );
};

interface TermsAndConditionsState {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

const TermsAndConditions: React.FC<TermsAndConditionsState> = (props: TermsAndConditionsState) => {
  const doc = [{ uri: 'https://labourteq-public-files.s3.eu-central-1.amazonaws.com/terms-and-conditions.pdf' }];

  return (
    <>
      <Modal
        className="terms-and-conditions"
        isOpen={props.isOpen}
        toggle={props.toggleIsOpen}
        style={{
          maxWidth: 1200,
          padding: 0,
        }}
      >
        {/* <ModalHeader toggle={props.toggleIsOpen} >Terms and Conditions</ModalHeader> */}

        <ModalBody
          style={{
            padding: 0,
          }}
        >
          {/* This is where the terms and conditions will appear */}
          {/* <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={doc}
          config={{
            header: {
              disableFileName: true,
            }
          }}
        /> */}
        </ModalBody>
      </Modal>
    </>
  );
};

export default LoginView;
