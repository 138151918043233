import { Formik, FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useContext, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import Tabs from '../../../../../components/HearingAndAppealTabs/HearingAndAppealTabs.component';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../../../components/taskContainer/TaskContainer';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { HEARING_TABS } from '../../../../../utils/case-utils';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { CaseType } from '../../../../../API';
import { AppealHearingPoorPerformanceChairpersonRulingsForm } from './AppealHearingPoorPerformanceChairpersonRulingsForm';
import { AppealHearingChairpersonsRulingForm } from './AppealHearingChairpersonsRulingForm';
import { getFieldsAndValidationSchema } from '../../hearing-utils';

export const AppealHearingChairpersonsRuling: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const enableFieldsCallback = useContext<ReadOnlyContextProps>(ReadOnlyContext).enableFieldsCallback!;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const setupValues = getFieldsAndValidationSchema(props.data.caseType, props.data.flowableFunctions.formKey);
  const { fields, validationSchema } = setupValues;
  const initialValues = getFormDataFromCaseData(fields, caseData);
  console.log(caseData);

  const renderForm = (): JSX.Element => {
    switch (props.data.caseType) {
      case CaseType.MISCONDUCT:
        return (
          <AppealHearingChairpersonsRulingForm
            data={props.data}
            getFormValuesForSubmission={getFormValuesForSubmission}
          />
        );
      case CaseType.POOR_PERFORMANCE:
        return (
          <AppealHearingPoorPerformanceChairpersonRulingsForm
            data={props.data}
            getFormValuesForSubmission={getFormValuesForSubmission}
          />
        );
    }
    return <> </>;
  };

  const getFormValuesForSubmission = (values: FormikValues): Record<string, any> => {
    const formValues = { ...values };
    delete formValues.isUpload;
    formValues.appeal_hearing_chairpersonsRulingDocuments = JSON.stringify(
      values.appeal_hearing_chairpersonsRulingDocuments,
    );
    if (props.data.caseType === CaseType.MISCONDUCT) {
      formValues.incidents = JSON.stringify(values.incidents);
    } else if (props.data.caseType === CaseType.POOR_PERFORMANCE) {
      formValues.performanceShortfalls = JSON.stringify(values.performanceShortfalls);
    }
    return filterFormData(formValues, fields);
  };

  const submitValues = async (values: FormikValues): Promise<void> => {
    flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  useEffect(() => {
    if (readOnly) enableFieldsCallback();
  }, []);
  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Appeal Hearing Concluded</h4>
      <Row>
        <Col md={12}>
          <Tabs page={7} tabs={HEARING_TABS} />
        </Col>
      </Row>
      <h5 className="text-h5 text-blue font-weight-500 mt-3">Chairperson's Ruling*</h5>
      <Formik
        initialValues={{ ...initialValues, isUpload: true }}
        enableReinitialize
        onSubmit={submitValues}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, errors }: FormikProps<FormikValues>): ReactElement => (
          <>
            {renderForm()}
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
