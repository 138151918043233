export const Sanctions: Record<string, string> = {
  VERBAL_WARNING: 'Verbal Warning',
  WRITTEN_WARNING: 'Written Warning',
  FINAL_WRITTEN_WARNING: 'Final Written Warning',
  DISMISSAL: 'Dismissal',
  NOT_APPLICABLE: 'Not Applicable',
};

export const OffenceFrequency: Record<string, string> = {
  firstOffence: 'First Offence',
  secondOffence: 'Second Offence',
  thirdOffence: 'Third Offence',
  fourthOffence: 'Fourth Offence',
  fifthOffence: 'Fifth Offence',
  sixthOffence: 'Sixth Offence',
};
