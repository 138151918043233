import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import { PlusIcon } from '../icon/Icon.component';
import ButtonWithIcons from '../buttons/ButtonWIthIcons.component';
import { UserRole } from '../../models';
import { UserContext } from '../../Router';
import { UserContextProps } from '../../App';


const BulkUploadButton: React.FC = () => {

    const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
    const allowedRoles = [
        UserRole.SUPER_ADMIN,
        UserRole.COMPANY_ADMIN,
    ]

    //@ts-ignore
    if (currentUser && !allowedRoles.filter(role => currentUser?.userRoles.includes(role)).length) {

        return null;
    }
    return (
        <>
            <Link to="/upload-employees">
                <ButtonWithIcons title={'bulk upload'} leftIcon={<PlusIcon fill="#B3B4B4" />} buttonType={'btn-bd-purple'} />
            </Link>
        </>
    );
};

export default BulkUploadButton;
