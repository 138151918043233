import React, { ReactElement, useState } from 'react';
import GuidanceModal from '../../../../../components/guidanceModals/GuidanceModal.component';
import { Formik, FormikProps, FormikValues } from 'formik';
import {
  CaseData,
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { getFieldsAndValidationSchema } from '../../hearing-utils';
import { ObjectSchema } from 'yup';
import { CaseType } from '../../../../../API';
import { AppealHearingAppointEmployerRepresentativeForm } from './AppealHearingAppointEmployerRepresentativeForm';

export const AppealHearingAppointEmployerRepresentative: React.FC<WorkflowComponentProps> = (
  props: WorkflowComponentProps,
) => {
  const { flowableFunctions } = props.data;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const setUpValues: {
    fields: { [key: string]: string };
    validationSchema: ObjectSchema<object>;
  } = getFieldsAndValidationSchema(props.data.caseType, flowableFunctions.formKey);

  const { fields, validationSchema } = setUpValues;

  const initialValues: FormikValues = getFormDataFromCaseData(setUpValues.fields, props.data.caseData);

  const getFormValuesForSubmission = (values: FormikValues): { [key: string]: any } => {
    const formValues: Partial<CaseData> = values;
    return filterFormData(formValues, fields);
  };

  const submitValues = (values: FormikValues): void => {
    props.data.flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Prepare for Appeal Hearing</h4>
      {props.data.caseType === CaseType.POOR_PERFORMANCE ? (
        <GuidanceModal
          isOpen={modalIsOpen}
          setModal={setModalIsOpen}
          buttonTitle={'Appeal Hearing'}
          title="GUIDANCE: NOTICE OF INCAPACITY POOR PERFORMANCE HEARING"
        >
          <>
            <p className="text-muted font-weight-bold">
              What is the purpose of the Notice of an Incapacity Poor Performance Hearing?
            </p>
            <p className="text-muted">
              The notice sets out the logistical details of the hearing (such as the date, time and venue), the
              employee’s rights, and the allegations of continued and/or serious poor performance levelled against the
              employee. It is a critical document in the hearing process
            </p>
            <p className="text-muted">
              A key element of fairness is that the employee is timeously informed of the hearing and the allegations
              against him/her. A notification of a hearing must be in writing and not given verbally. This is to ensure
              that the employee understands the nature of the allegations as well as his/her rights, and that proof of
              this exists
            </p>
            <p className="text-muted">
              The purpose of this type of enquiry is to establish whether the employee is capable of achieving and
              sustaining an acceptable standard of work for the role he/she was employed for. Although the employee is
              not “charged” in the same way as a disciplinary hearing, he/she is still entitled to know the standard of
              performance required and how he/she allegedly failed in this regard. It is therefore still advisable that
              the alleged performance shortcomings are stipulated in the notice. As it is assumed that counselling, a
              review period(s) and/or a Performance Improvement Plan preceded the hearing, he/she should have a good
              understanding of the allegations against him/her. The employee is also entitled to the same procedural
              rights as in the case of a disciplinary hearing
            </p>
            <p className="text-muted font-weight-bold">
              What is the minimum period of notice of hearing that must be given to an employee?
            </p>
            <p className="text-muted">
              At least 2 working days’ notice of the incapacity hearing should be given to the employee to allow him/her
              time to prepare his/her case. Depending on how complex the case is, a longer period may be required to put
              the employee in a fair position to prepare. Should there be a reasonable request by the employee for
              additional time in or der to prepare, this should not be unreasonably withheld. However, continuous
              requests for extensions of time, or delays that are unreasonable and obstructive, should not be granted
            </p>
            <p className="text-muted font-weight-bold">What are the rights of the employee prior to the hearing?</p>
            <p className="text-muted">
              The employee must be timeously informed of the allegations against his/her, the right to have a translator
              in the hearing, and the right to be represented in the hearing. He/she must be granted sufficient time to
              prepare for the hearing. The employee must also be warned that if he/she does not attend the hearing
              without providing a reasonable explanation, the employer will be entitled to proceed with the hearing in
              their absence.
            </p>
            <p className="text-muted font-weight-bold">
              What is the role of the Incapacity Poor Performance Policy in the drafting of charges?
            </p>
            <p className="text-muted">
              The Incapacity Poor Performance Policy on LabourTeq (either provided as part of the system or reflecting
              the employer’s own policy) provides mainly for the procedure to be followed when poor performance problems
              are experienced
            </p>
            <p className="text-muted font-weight-bold">
              Should the employee acknowledge receipt of the Notice of Hearing?
            </p>
            <p className="text-muted">
              It is important that the employee confirms receipt of the Notice and that he/she is made aware that this
              does not constitute an admission to the allegations made against him/her. It may happen that an employee
              refuses to sign (manually or digitally ). The Notice can then be handed to the employee in the presence of
              a witness. The witness will sign that the Notice was handed and read to the employee, which will suffice
              as proof of the employee having received it. In th e hearing, the chairperson will confirm at the outset
              whether or not the employee did indeed receive the Notice and whether they received it timeously.
            </p>
          </>
        </GuidanceModal>
      ) : (
        <GuidanceModal
          isOpen={modalIsOpen}
          setModal={setModalIsOpen}
          buttonTitle={'Appeal Hearing'}
          title="Guidance: Appeal hearing process"
        >
          <>
            <p>
              <div className="font-weight-bold font-italic text-dark">
                Can an employee appeal the outcome of a disciplinary and/or incapacity poor performance process and what
                steps should be followed?
              </div>
              <span className="text-muted">
                Should an employee feel aggrieved by the outcome of a disciplinary or incapacity poor performance
                process, he/she may lodge a formal appeal setting out the reasons for appealing, but only if the
                employer’s Disciplinary Code/Incapacity Policy provides for this. There is no automatic right to appeal
                in law. LabourTeq is customised to reflect the employer’s Disciplinary Code/Incapacity Policy provisions
                in this regard.
                <br />
                <p className="px-3 text-muted">The appeal can be either:</p>
                <ul className="px-3 text-muted">
                  <li className="px-3 text-muted">
                    • Against the actual finding(s) of guilt in case of misconduct, or a finding of incapacity in case
                    of poor performance; and/or
                  </li>
                  <li className="px-3 text-muted">
                    • Against the actual finding(s) of guilt in case of misconduct, or a finding of incapacity in case
                    of poor performance; and/or
                  </li>
                </ul>
                <br />
                <p className="px-3 text-muted"> The appeal process involves the following:</p>
                <ul className="px-3 text-muted">
                  <li className="px-3 text-muted">
                    • The employee submits his/her completed appeal form, provided on LabourTeq, within the prescribed
                    number of working days from the receipt of the written findings of the hearing/discussion;
                  </li>
                  <li className="px-3 text-muted">
                    • The employee must set out the grounds for appeal and the proposed outcome required. The appeal
                    grounds can involve issues such as alleged procedural irregularities, bias on the side of the
                    chairperson, a denial of guilt or incapacity, inappropriateness of sanction in case of
                    misconduct/outcome in case of incapacity, and/or that new evidence has arisen impacting on the
                    findings.
                  </li>
                  <li className="px-3 text-muted">
                    • An appeal chairperson is appointed. A different person must chair the appeal than the person who
                    acted as chairperson in the initial hearing.
                  </li>
                  <li className="px-3 text-muted">
                    • The same rules and employee rights as applicable in the first hearing process apply in the appeal
                    hearing.
                  </li>
                  <li className="px-3 text-muted">
                    • The aim is not to re-hear all evidence but to better understand the reasons for the appeal and/or
                    to examine new evidence that may have arisen.
                  </li>
                  <li className="px-3 text-muted">
                    • The appeal chairperson must put his/her findings in writing. He/she can either upload their own
                    ruling report on LabourTeq or use the online form available for this purpose.
                  </li>
                  <li className="px-3 text-muted">
                    • The appeal chairperson can either:
                    <ul className="px-3 text-muted">
                      <li className="px-3 text-muted"> - Concur with the original findings;</li>
                      <li className="px-3 text-muted">
                        {' '}
                        - Come to a different decision on guilt/incapacity on perusal of all evidence;
                      </li>
                      <li className="px-3 text-muted">
                        {' '}
                        - Come to a different decision on sanction/outcome only and show leniency. The chairperson
                        cannot impose a harsher sanction.{' '}
                      </li>
                    </ul>
                  </li>
                </ul>
              </span>
            </p>
            <br />
          </>
        </GuidanceModal>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={submitValues}
      >
        {({ values, resetForm, setFieldValue, handleSubmit, errors }: FormikProps<FormikValues>): ReactElement => (
          <>
            <AppealHearingAppointEmployerRepresentativeForm
              data={props.data}
              getFormValuesForSubmission={getFormValuesForSubmission}
            />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
