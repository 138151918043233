import React, { useEffect, useState } from 'react';
import { ErrorMessage, FormikProps, FormikValues, useFormikContext } from 'formik';
import DocumentUploader from './DocumentUploader';
import FileDisplay, { isBucketFile } from './FileDisplay';
import { BucketFile } from '../../screens/WorkflowContainer/workflow-utils';
import { get } from 'lodash';
import { getAWSCache } from '../../utils/storage-utils';

interface UploaderContainerProps {
  fieldName: string;
  path?: string;
  isMulti?: boolean;
  hasIcon?: boolean;
  onTable?: boolean;
  index?: number;
  withAutoUpload?: boolean;
  uploadButtonValue?: string;
}

const Multi: React.FC<UploaderContainerProps> = (props: UploaderContainerProps) => {
  const { fieldName, path, hasIcon = false, withAutoUpload = true } = props;
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const [documents, setDocuments] = useState<(BucketFile | File)[]>();

  const setDocument = (document: BucketFile | File): void => {
    let result = fieldName.split('.').reduce((previous, current) => previous[current], values);
    result = result ? result : [];
    setFieldValue(fieldName, result.concat(document));
  };

  const removeDocument = (documentKey: string): void => {
    const files = documents?.filter((file: BucketFile | File) => {
      if (isBucketFile(file)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return file.key !== documentKey;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return file.name !== documentKey;
      }
    });
    setFieldValue(fieldName, files && files.length ? files : []);
  };

  useEffect(() => {
    if (get(values, fieldName)) {
      setDocuments(get(values, fieldName));
    }
  }, [JSON.stringify(get(values, fieldName))]);

  useEffect(() => {
    const fetchData = async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const pathInstanceId = path?.split('/')[1];
      const data = {
        action: 'hcApproverGet',
        currentTaskId: `${pathInstanceId}-FileUploadUrl`,
      };
      const getCache = await getAWSCache(data);
      const fileUrl = getCache?.body[0]?.value;
      if (fileUrl) {
        setDocuments(JSON.parse(fileUrl));

        setFieldValue('adhocCaseFiles', JSON.parse(fileUrl));
      }
    };
    fetchData();
  }, []);
  return (
    <div className="d-flex flex-column">
      {documents &&
        documents.map((file: BucketFile | File, index: number) => (
          <div key={index}>
            <FileDisplay file={file} removeDocumentCallback={removeDocument} />
          </div>
        ))}
      <DocumentUploader
        alreadyDocument={!!documents?.length}
        bucketPath={path}
        setDocumentCallback={setDocument}
        isMulti={true}
        hasIcon={hasIcon}
        withAutoUpload={withAutoUpload}
      />
    </div>
  );
};

const TableUploadContainer: React.FC<UploaderContainerProps> = (props: UploaderContainerProps) => {
  const { fieldName, path, hasIcon, index = 0 } = props;
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();

  const setDocument = (document: BucketFile | File): void => {
    if (values[fieldName][index].documents.length === 1 && !values[fieldName][index].documents[0].url)
      setFieldValue(`${fieldName}.${index}.documents[0]`, document);
    else setFieldValue(`${fieldName}.${index}.documents`, values[fieldName][index].documents.concat(document));
  };

  const removeDocument = (documentKey: string): void => {
    const files = values[fieldName][index].documents.filter((file: BucketFile) => file.key !== documentKey);
    setFieldValue(`${fieldName}.${index}.documents`, files.length ? files : [{ fileName: '', url: '', key: '' }]);
  };

  return (
    <div className="d-flex flex-column">
      {values[fieldName][index].documents &&
        values[fieldName][index].documents[0].key &&
        values[fieldName][index].documents.map((file: BucketFile, index: number) => (
          <div key={index}>
            <FileDisplay file={file} removeDocumentCallback={removeDocument} />
          </div>
        ))}
      <DocumentUploader
        alreadyDocument={true}
        bucketPath={path}
        setDocumentCallback={setDocument}
        isMulti={true}
        hasIcon={hasIcon}
      />
    </div>
  );
};

const Single: React.FC<UploaderContainerProps> = (props: UploaderContainerProps) => {
  const { fieldName, path, hasIcon = false, uploadButtonValue } = props;
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();

  const setDocument = (document: BucketFile | File): void => {
    setFieldValue(fieldName, document);
  };

  const removeDocument = (): void => {
    setFieldValue(fieldName, null);
  };

  return (
    <div className="d-flex flex-column">
      {values[fieldName] && <FileDisplay file={values[fieldName]} removeDocumentCallback={removeDocument} />}
      <DocumentUploader
        alreadyDocument={values[`${fieldName}`] && values[`${fieldName}`].length}
        bucketPath={path}
        setDocumentCallback={setDocument}
        isMulti={false}
        hasIcon={hasIcon}
        value={uploadButtonValue}
      />
    </div>
  );
};

const UploaderContainerAdhoc: React.FC<UploaderContainerProps> = (props: UploaderContainerProps) => {
  const {
    fieldName,
    path,
    isMulti = true,
    hasIcon = false,
    onTable = false,
    index = 0,
    withAutoUpload = true,
    uploadButtonValue = '',
  } = props;

  return (
    <div className="d-flex flex-column">
      {onTable && (
        <>
          <TableUploadContainer fieldName={fieldName} isMulti={true} hasIcon={true} index={index} path={path} />
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={`${fieldName}.${index}.documents`} />
          </span>
        </>
      )}
      {isMulti && !onTable && (
        <Multi fieldName={fieldName} path={path} hasIcon={hasIcon} withAutoUpload={withAutoUpload} />
      )}
      {!isMulti && !onTable && (
        <Single fieldName={fieldName} path={path} hasIcon={hasIcon} uploadButtonValue={uploadButtonValue} />
      )}

      {!onTable && (
        <span className="text-danger">
          <ErrorMessage className="text-danger" name={fieldName} />
        </span>
      )}
    </div>
  );
};

export default UploaderContainerAdhoc;
