import React, { ReactElement } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import AppealProcessForm from './AppealProcessForm';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { getFieldsAndValidationSchema } from '../../hearing-utils';
import { CaseType } from '../../../../../API';

export const AppealProcess: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData } = props.data;

  const setupValues = getFieldsAndValidationSchema(props.data.caseType, props.data.flowableFunctions.formKey);
  const { fields, validationSchema } = setupValues;
  const initialValues = getFormDataFromCaseData(fields, caseData);

  const submitValues = (values: FormikValues): void => {
    const closeCase: boolean =
      !values.appealAllowed || !values.proceedWithAppeal || !values.employeeHasIndicatedAppealIntent;
    props.data.flowableFunctions.onNext(getFormValuesForSubmission(values), closeCase);
  };

  const getFormValuesForSubmission = (values: FormikValues): Record<string, any> => {
    const formValues = { ...values };
    formValues.employeeAppealResponseDocuments = JSON.stringify(formValues.employeeAppealResponseDocuments);
    if (props.data.caseType === CaseType.MISCONDUCT) {
      formValues.incidents = JSON.stringify(formValues.incidents);
    } else if (props.data.caseType === CaseType.POOR_PERFORMANCE) {
      formValues.performanceShortfalls = JSON.stringify(formValues.performanceShortfalls);
    }
    return filterFormData(formValues, fields);
  };

  return (
    <div className="content">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={submitValues}
      >
        {({ handleSubmit, values }: FormikProps<FormikValues>): ReactElement => (
          <>
            <AppealProcessForm data={props.data} getFormValuesForSubmission={getFormValuesForSubmission} />
            <WorkFlowFooter
              data={props.data}
              finalPage={!values.appealAllowed || !values.proceedWithAppeal || !values.employeeHasIndicatedAppealIntent}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
