import React, { ReactElement } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { Col, Row } from 'reactstrap';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import CCMADetailsBForm from './CCMADetailsBForm';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../WorkflowContainer/workflow-utils';
import { CCMADetailsBSchema } from '../../../forms/ValidationSchema/GeneralCaseSchema';
import { useHistory } from 'react-router-dom';

const CCMADetailsB: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const fields = {
    disputeReferredToCCMA: 'boolean',
    arbitrationRulingInFavourOf: 'string',
    arbitrationRuling: 'string',
    furtherDetailsArbitration: 'string',
    professionalFees: 'string',
    arbitrationAward: 'string',
    labourCourtRulingValue: 'string',
    CCMAArbitrationRulingDocuments: 'array',
    internalEmployerRepresentativeArbitration: 'array',
    externalEmployerRepresentativeNameArbitration: 'string',
    arbitrationDate: 'string',
    extraArbitrationRulingDocuments: 'array',
    disputeReferredToLabourCourt: 'boolean',
    labourCourtRulingInFavourOf: 'string',
    labourCourtRuling: 'string',
    furtherDetailsLabourCourt: 'string',
    labourCourtJudgementDocuments: 'array',
    extraAdjudicationRulingDocuments: 'array',
    internalEmployerRepresentativeAdjudication: 'array',
    externalEmployerRepresentativeNameAdjudication: 'string',
    adjudicationDate: 'string',
  };

  const history = useHistory();
  const initialValues: FormikValues = getFormDataFromCaseData(fields, props.data.caseData);
  const submitValues = (values: FormikValues): void => {
    const variables = { ...values };
    variables.CCMAArbitrationRulingDocuments = JSON.stringify(variables.CCMAArbitrationRulingDocuments);
    variables.extraArbitrationRulingDocuments = JSON.stringify(variables.extraArbitrationRulingDocuments);
    variables.labourCourtJudgementDocuments = JSON.stringify(variables.labourCourtJudgementDocuments);
    variables.extraAdjudicationRulingDocuments = JSON.stringify(variables.extraAdjudicationRulingDocuments);
    variables.internalEmployerRepresentativeAdjudication = JSON.stringify(
      variables.internalEmployerRepresentativeAdjudication,
    );
    variables.internalEmployerRepresentativeArbitration = JSON.stringify(
      variables.internalEmployerRepresentativeArbitration,
    );

    props.data.flowableFunctions.onNext(filterFormData(variables, fields));
    history.push(`/case/${props.data.masterProcessInstanceId}/history`);
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-blue font-weight-500">Dispute Referral (Continue)</h4>
      <Formik
        initialValues={initialValues}
        validationSchema={CCMADetailsBSchema}
        enableReinitialize
        onSubmit={submitValues}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <CCMADetailsBForm data={props.data} />
            <div className="mt-2 w-auto">
              <hr style={{ border: '0.06em solid #adb5bd' }} />
              <Row className="d-flex justify-content-between">
                <Col className="align-items-start">
                  <ButtonWithIcons
                    title="Back"
                    handleClick={(): void => {
                      props.data.flowableFunctions.goBack(values);
                    }}
                  />
                  <ButtonWithIcons
                    title="Cancel"
                    buttonType="btn-simple"
                    handleClick={(): void => console.log('Cancel')}
                  />
                </Col>
                <Col className="text-right">
                  <ButtonWithIcons
                    title="Save & Close"
                    handleClick={(): void => props.data.flowableFunctions.onSaveAndClose(values)}
                    buttonType="btn-simple"
                  />
                  <ButtonWithIcons title="Close" handleClick={handleSubmit} />
                </Col>
              </Row>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CCMADetailsB;
