import React, { useState } from 'react';
import { taskConfigs } from '../../../configs/task-configs/taskConfigs';
import Bugsnag from '@bugsnag/js';

interface CaseHistoryAccordionProps {
  index: number;
  task: any;
}
const CaseHistoryAccordionAdhoc: React.FC<CaseHistoryAccordionProps> = ({ task, children }) => {
  const [state, setState] = useState({ open: false });
  const taskConfig = taskConfigs[task.taskDefinitionKey];

  if (!taskConfig) {
    Bugsnag.notify(`No taskconfig found on ${task}`);
  }

  return (
    <>
      <div
        className="d-flex justify-content-between tab-background pointer px-3 py-3 my-1"
        onClick={(): void => setState(oldState => ({ ...oldState, open: !state.open }))}
      >
        <span className="text-default">{0}. &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;Adhoc Files</span>
        {/* <span className="h-75 font-weight-light text-muted text-capitalize w-25 text-left">Adhoc Files</span> */}
        <span>
          {state.open ? (
            <i className="tim-icons icon-minimal-up text-default font-weight-bold" />
          ) : (
            <i className="tim-icons icon-minimal-down text-default font-weight-bold" />
          )}
        </span>
      </div>
      {state.open && children}
    </>
  );
};

export default CaseHistoryAccordionAdhoc;
