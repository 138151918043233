import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import DetermineNatureOfPerformanceShortfallsForm from './DetermineNatureOfPerformanceShortfallsForm';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import { Button } from 'reactstrap';
import { DetermineNatureOfPerformanceShortfallsSchema } from '../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../WorkflowContainer/workflow-utils';
import { Storage } from 'aws-amplify';
import { UserContext, UserContextProps } from '../../../App';
import { ChatIcon } from '../../../components/icon/Icon.component';
import { getOrganisationForDisciplinaryMatrix } from '../../../utils/company-setup-utils';
import { getOrganisation } from '../../../graphql/queries';
import { UpdateOrganisationInput } from '../../../API';

const DetermineNatureOfPerformanceShortfalls: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const { caseData, flowableFunctions } = props.data;
  const [organisation, setOrganisation] = useState<UpdateOrganisationInput>();
  const fields = {
    furtherActionRequired: 'boolean',
    reasonForDecision: 'string',
    wayForward: 'string',
  };
  const initialValues: FormikValues = getFormDataFromCaseData(fields, caseData);
  const submitValues = async (values: FormikValues): Promise<void> => {
    flowableFunctions.onNext(filterFormData(values, fields));
  };

  const getSignedUrl = async (key: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      Storage.get(key, { level: 'public' })
        .then(item => {
          if (item) {
            resolve(item as string);
            console.log('resolve', resolve(item as string));
          } else reject(new Error('could not get key'));
        })
        .catch(error => reject(error));
    });
  };

  useEffect(() => {
    if (currentUser && currentUser.organisationId)
      getOrganisationForDisciplinaryMatrix(getOrganisation, currentUser.organisationId).then(organisationObject => {
        setOrganisation(organisationObject);
      });
  }, []);

  return (
    <div className="content">
      <h4 className="text-default text-capitalize font-weight-bold">
        Determine nature of performance shortfalls and way forward
      </h4>
      {organisation && (
        <Button
          className="text-capitalize rounded-0 px-4 py-2 mr-auto guidance-modal-button"
          onClick={(e: any): void => {
            if (organisation.poorPerformancePolicy) {
              getSignedUrl(`${organisation.poorPerformancePolicy.key}`).then((url: string) => {
                e.preventDefault();
                window.open(url);
              });
            } else {
              //default policy
              getSignedUrl(`defaultPolicies/LTQ_Poor_Performance.pdf`).then((url: string) => {
                e.preventDefault();
                window.open(url);
              });
            }
          }}
        >
          <div className="d-flex">
            <span>
              <ChatIcon />
            </span>
            <span className="text-uppercase font-weight-light pl-2 py-2">Guidance: </span>
            <span className="text-capitalize font-weight-light pl-1 py-2">
              Consult Incapacity Poor performance policy
            </span>
          </div>
        </Button>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={DetermineNatureOfPerformanceShortfallsSchema}
        onSubmit={submitValues}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <DetermineNatureOfPerformanceShortfallsForm data={props.data} />
            <WorkFlowFooter
              data={props.data}
              onCancel={(): void => console.log('cancel')}
              onNext={handleSubmit}
              onSaveAndClose={(): void => flowableFunctions.onSaveAndClose(values)}
              finalPage={!values.furtherActionRequired || values.wayForward === 'OTHER'}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default DetermineNatureOfPerformanceShortfalls;
