import { WrittenWarningWithPipHearingSchema } from '../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import {
  initial_hearing_confirmOverallSanctionSchema,
  initial_hearing_poor_performance_compileNoticeOfHearingSchema,
  initial_hearing_poorPerformanceChairpersonsRulingSchema,
  startAppealSchema,
} from '../../forms/ValidationSchema/InitialHearingValidationSchema';
import {
  appeal_hearing_poorPerformanceCompileNoticeOfHearingSchema,
  appeal_hearing_pp_chairpersonsRulingSchema,
  appeal_hearing_pp_confirmOverallSanction,
} from '../../forms/ValidationSchema/AppealHearingValidationSchema';

export const appeal_hearing_pp_chairpersonsRuling = {
  fields: {
    appeal_hearing_chairpersonsRulingDocuments: 'array',
    appeal_hearing_backgroundLeadingToHearing: 'string',
    appeal_hearing_summaryOfAppellantsArguments: 'string',
    appeal_hearing_summaryOfEmployerRepresentativesArguments: 'string',
    appeal_hearing_summaryOfLegalPrinciples: 'string',
    appeal_hearing_conclusionsReached: 'string',
    appeal_hearing_newEvidenceConsidered: 'string',
    performanceShortfalls: 'array',
  },
  validationSchema: appeal_hearing_pp_chairpersonsRulingSchema,
};

export const initial_hearing_pp_chairpersonsRuling = {
  fields: {
    initial_hearing_chairpersonsRulingDocuments: 'array',
    initial_hearing_backgroundLeadingToHearing: 'string',
    initial_hearing_employeeRightsExercisedInHearing: 'string',
    initial_hearing_summaryOfEmployerRepresentativeEvidence: 'string',
    initial_hearing_summaryOfEmployeesEvidence: 'string',
    initial_hearing_summaryOfLegalPrinciples: 'string',
    initial_hearing_conclusionsReachedOnSubStandardPerformance: 'string',
    initial_hearing_summaryOfAggravatingFactors: 'string',
    initial_hearing_conclusionsReachedOnWayForward: 'string',
    initial_hearing_findingsOnWayForward: 'string',
    initial_hearing_employeeCommentsAndMitigatingFactors: 'string',
    initial_hearing_reasonForSelectingOther: 'string',
    performanceShortfalls: 'array',
  },
  validationSchema: initial_hearing_poorPerformanceChairpersonsRulingSchema,
};

export const appeal_hearing_pp_confirmSanction = {
  fields: {
    performanceShortfalls: 'array',
    overallSanction: 'string',
    revisedOverallSanction: 'string',
  },
  validationSchema: appeal_hearing_pp_confirmOverallSanction,
};

// todo: make validation schema for pp
export const initial_hearing_pp_confirmSanction = {
  fields: {
    performanceShortfalls: 'array',
    overallSanction: 'string',
  },
  validationSchema: initial_hearing_confirmOverallSanctionSchema,
};

// todo: replace schema with pp
// export const WrittenWarningWithPip = (isAppeal: boolean): FieldsAndValidationSchema => {
//   return {
//     fields: {
//       performanceShortfalls: 'array',
//       hearingDate: 'string',
//       warningValidityPeriod: 'string',
//       warningExpiryDate: 'string',
//     },
//     validationSchema: WrittenWarningWithPipHearingSchema,
//   };
// };

//todo: replace schema with pp
export const pp_executeOutcomeWrittenWarning = {
  fields: {
    performanceShortfalls: 'array',
    hearingDate: 'string',
    warningValidityPeriod: 'string',
    warningExpiryDate: 'string',
  },
  validationSchema: WrittenWarningWithPipHearingSchema,
};

export const initial_hearing_pp_compileNoticeOfHearing = {
  fields: {
    performanceShortfalls: 'array',
  },
  validationSchema: initial_hearing_poor_performance_compileNoticeOfHearingSchema,
};

export const appeal_hearing_pp_compileNoticeOfHearing = {
  fields: {
    performanceShortfalls: 'array',
  },
  validationSchema: appeal_hearing_poorPerformanceCompileNoticeOfHearingSchema,
};

export const pp_startAppeal = {
  fields: {
    performanceShortfalls: 'array',
    appealAllowed: 'boolean',
    employeeHasIndicatedAppealIntent: 'boolean',
    employeeAppealResponseDocuments: 'array',
    appealReceivedWithinPrescribedTimeFrame: 'boolean',
    proceedWithAppeal: 'boolean',
    appealDeadline: 'string',
  },
  validationSchema: startAppealSchema,
};
