import React from 'react';
import { DropdownItem, NavLink } from 'reactstrap';

interface DyslexiaToggleProps {
  dyslexiaToolEnabled?: boolean;
  toggleDyslexiaTool?: () => void;
}

const DyslexiaToggle: React.FC<DyslexiaToggleProps> = (props: DyslexiaToggleProps) => {
  const { dyslexiaToolEnabled, toggleDyslexiaTool } = props;
  return (
    <div className="toggle-wrapper">
      <NavLink tag="li" disabled>
        <span className="text-default d-flex justify-content-center">Dyslexia Toggle</span>
      </NavLink>
      <DropdownItem className="nav-item d-flex justify-content-between" onClick={toggleDyslexiaTool}>
        <span>Off</span>
        {dyslexiaToolEnabled ? (
          <span>
            <i className="fas fa-toggle-on success" />
          </span>
        ) : (
          <span>
            <i className="fas fa-toggle-off text-danger" />
          </span>
        )}
        <span>On</span>
      </DropdownItem>
    </div>
  );
};

export default DyslexiaToggle;
