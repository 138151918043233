import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import TopBarComponent from '../../components/topBar/TopBar.component';
import { CaseStatusBar } from '../../components/caseStatusBar/caseStatusBar';
import { queryHistoricTasksByMasterProcessInstanceIdAndFinished } from '../../utils/flowable/flowable-utils';
import { FlowableHistoricTask, FlowableVariable } from '../../utils/flowable/flowable-types';
import CaseHistoryAccordion from './caseHistoryAccordion/CaseHistoryAccordion';
import CaseHistoryData from './CaseHistoryData';
import moment from 'moment';
import { getAWSCache } from '../../utils/storage-utils';
import CaseHistoryAccordionAdhoc from './caseHistoryAccordion/CaseHistoryAccordionAdhoc';
import CaseHistoryDataAdhoc from './CaseHistoryDataAdhoc';

interface Person {
  firstName: string;
  lastName: string;
}

interface Incident {
  summaryOfFacts: string;
  date: string;
  time: string;
  location: string;
  complainants?: Person[];
  witnesses?: Person[];
}

interface PerformanceShortfall {
  shortfallDescription: string;
  date: string;
  complainants?: Person[];
  witnesses?: Person[];
}

const CaseHistoryContainer: React.FC = () => {
  const { masterProcessInstanceId } = useParams<{ masterProcessInstanceId: string }>();
  const [tasksWithFormKeys, setTaskWithFormKeys] = useState<FlowableHistoricTask[]>([]);
  const [hcColumns, setHcColumns] = useState(true);
  const [adhocFiles, setAdhocFiles] = useState([]);
  const [adhocText, setAdhocText] = useState([]);

  const duplicateFormKey = (withFormKeys: FlowableHistoricTask[], formKey: string): boolean => {
    return withFormKeys.some(element => {
      return element.formKey === formKey;
    });
  };

  const isABackTask = (flowableVars: FlowableVariable[]): boolean => {
    const pathVarNames = [
      'path',
      'investigatorPath',
      'chairpersonPath',
      'employerRepresentativePath',
      'HCApproverPath',
    ];
    for (const varName of pathVarNames) {
      const pathVar = flowableVars.find(v => v.name === varName && v.scope === 'local');
      if (pathVar?.value === 'back') {
        return true;
      }
    }
    return false;
  };

  const shouldShowTask = useCallback((task: FlowableHistoricTask): boolean => {
    return task.formKey !== 'case-history' && task.deleteReason === null && !isABackTask(task.variables);
  }, []);

  const loadCase = useCallback(
      async (masterProcessInstanceId: string): Promise<void> => {
        const historicCaseTasks =
            await queryHistoricTasksByMasterProcessInstanceIdAndFinished(
                masterProcessInstanceId
            );
        const withFormKeys: FlowableHistoricTask[] = [];
        historicCaseTasks.forEach((task) => {
          if (!!task.formKey && shouldShowTask(task)) {
            const duplicateKeys = duplicateFormKey(withFormKeys, task.formKey);
            if (duplicateKeys) {
              const index = withFormKeys.findIndex(
                  (element) => element.formKey === task.formKey
              );
              if (
                  index >= 0 &&
                  withFormKeys[index].endTime &&
                  task.endTime &&
                  moment(withFormKeys[index].endTime).isBefore(task.endTime)
              ) {
                withFormKeys[index] = task;
              }
            } else {
              withFormKeys.push(task);
            }
          }
        });

        const sortedTasks = withFormKeys.sort((a, b) => {
          return moment(a.endTime).diff(moment(b.endTime));
        });

        setTaskWithFormKeys(sortedTasks);
      },
      [setTaskWithFormKeys, shouldShowTask]
  );

  useEffect(() => {
    if (masterProcessInstanceId) {
      loadCase(masterProcessInstanceId).catch();
    }
  }, [masterProcessInstanceId, loadCase]);

  useEffect(() => {
    const fetchCacheData = async () => {
      try {
        const data = {
          action: 'hcApproverGet',
          currentTaskId: `${masterProcessInstanceId}-FileUploadUrl`,
        };
        const getCache = await getAWSCache(data);
        const fileUrl = getCache?.body[0]?.value;
        const addTexts = getCache?.body[1]?.value;
        console.log('Fetched AWS Cache Data:', getCache); // Debugging line
        if (fileUrl) {
          setAdhocFiles(JSON.parse(fileUrl));
          setAdhocText(JSON.parse(addTexts));
        }
      } catch (error) {
        console.error('Error fetching cache data:', error);
      }
    };
    fetchCacheData();
  }, [masterProcessInstanceId]);

  // Function to extract labels for "Case Overview"
  const extractLabelsForCaseOverview = (task: FlowableHistoricTask) => {
    // Initialize updated variables array
    let updatedVariables = [...task.variables];
    // Process incidents if present
    const incidentsVariable = task.variables.find((v) => v.name === 'incidents');

    if (incidentsVariable && incidentsVariable.value) {
      try {
        const incidents: Incident[] =
            JSON.parse(incidentsVariable.value as string) || [];

        incidents.forEach((incident) => {
          // Handle missing complainants and witnesses with fallback empty arrays
          incident.complainants =
              incident.complainants?.map((c) => ({
                firstName: c.firstName || 'N/A',
                lastName: c.lastName || 'N/A',
              })) || [];

          incident.witnesses =
              incident.witnesses?.map((w) => ({
                firstName: w.firstName || 'N/A',
                lastName: w.lastName || 'N/A',
              })) || [];
        });

        // Update the incidents variable in the variables array
        updatedVariables = updatedVariables.map((v) =>
            v.name === 'incidents'
                ? { ...v, value: JSON.stringify(incidents) }
                : v
        );
      } catch (error) {
        console.error('Error parsing incidents variable:', error);
      }
    } else {
      console.warn('No incidents variable found or it has invalid data');
    }

    // Process performanceShortfalls if present
    const performanceShortfallsVariable = task.variables.find(
        (v) => v.name === 'performanceShortfalls'
    );

    if (performanceShortfallsVariable && performanceShortfallsVariable.value) {
      try {
        const performanceShortfalls: PerformanceShortfall[] =
            JSON.parse(performanceShortfallsVariable.value as string) || [];

        performanceShortfalls.forEach((shortfall) => {
          // Handle missing fields with fallback values
          shortfall.shortfallDescription =
              shortfall.shortfallDescription || 'No description provided';
          shortfall.date = shortfall.date || 'N/A';

          // Handle missing complainants and witnesses with fallback empty arrays
          shortfall.complainants =
              shortfall.complainants?.map((c) => ({
                firstName: c.firstName || 'N/A',
                lastName: c.lastName || 'N/A',
              })) || [];

          shortfall.witnesses =
              shortfall.witnesses?.map((w) => ({
                firstName: w.firstName || 'N/A',
                lastName: w.lastName || 'N/A',
              })) || [];
        });

        // Update the performanceShortfalls variable in the variables array
        updatedVariables = updatedVariables.map((v) =>
            v.name === 'performanceShortfalls'
                ? { ...v, value: JSON.stringify(performanceShortfalls) }
                : v
        );
      } catch (error) {
        console.error('Error parsing performanceShortfalls variable:', error);
      }
    } else {
      console.warn(
          'No performanceShortfalls variable found or it has invalid data'
      );
    }

    return {
      ...task,
      variables: updatedVariables,
    };
  };

  return (
      <>
        <TopBarComponent title={'case history'} hideSearch={true} />
        <CaseStatusBar status="Case History" />
        <div className="workflow-content">
          <div className="task-container mt-5">
            {adhocFiles?.length > 0 && (
                <CaseHistoryAccordionAdhoc task={''} index={11} key={11}>
                  <CaseHistoryDataAdhoc
                      task={adhocFiles}
                      adhocText={adhocText}
                  />{' '}
                </CaseHistoryAccordionAdhoc>
            )}
            {tasksWithFormKeys.map((task, index) => {
              const taskData =
                  task.formKey === 'case-overview'
                      ? extractLabelsForCaseOverview(task)
                      : task;
              return (
                  <CaseHistoryAccordion task={taskData} index={index} key={index}>
                    <CaseHistoryData
                        task={taskData}
                        hcColumns={hcColumns}
                        setHcColumns={setHcColumns}
                    />
                  </CaseHistoryAccordion>
              );
            })}
          </div>
        </div>
      </>
  );
};

export default CaseHistoryContainer;
