import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { CaseClosedIcon } from '../../components/icon/Icon.component';

export interface PleaseWaitModalProps {
  closeModal: () => void;
  isOpen: boolean;
  titleText?: string;
  subtitleText?: string;
}

export const PleaseWaitModal: React.FC<PleaseWaitModalProps> = props => {
  const title = props.titleText || 'Please wait';

  return (
    <Modal className="p-0" size="md" isOpen={props.isOpen} centered>
      <ModalBody className="p-0 rounded-0">
        <div className="d-flex justify-content-between px-3 py-2 guidance-modal-header">
          <div className="text-capitalize ml-auto mr-auto font-weight-bold">{title}</div>
        </div>
        <div className="d-flex align-items-center flex-column px-3 py-3 mb-3">
          <div className="text-muted mb-2">
            Please access Chairperson guidance resources, if needed. You will be able to upload your ruling after the
            hearing has been concluded.
          </div>
          <div className="pl-2">
            <CaseClosedIcon />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center my-2">
          <>
            <Button
              className="btn-simple text-uppercase rounded-0 ml-2 font-weight-light"
              title={'OK'}
              onClick={props.closeModal}
            >
              OK
            </Button>
          </>
        </div>
      </ModalBody>
    </Modal>
  );
};
