import React, { ReactElement } from 'react';
import { Col, Row } from 'reactstrap';
import { Formik, FormikProps, FormikValues } from 'formik';
import { GuidanceModalSummaryDismissal } from './guidance-modals/GuidanceModalSummaryDismissal';
import { filterFormData, getFormDataFromCaseData } from '../../../../../WorkflowContainer/workflow-utils';
import { HEARING_TABS } from '../../../../../../utils/case-utils';
import Tabs from '../../../../../../components/tabs/Tabs.component';
import { DocumentVersionControlTable } from '../../../../../../components/documentVersionControlTable/DocumentVersionControlTable';
import { WorkFlowFooter } from '../../../../../../components/workflowFooter/WorkFlowFooter';
import { CaseType } from '../../../../../../API';
import {
  initial_hearing_misconduct_executeOutcomeSummaryDismissal,
  initial_hearing_pp_executeOutcomeSummaryDismissal,
} from './config';
import { InitialHearingExecuteOutcomeSummaryDismissalForm } from './InitialHearingExecuteOutcomeSummaryDismissalForm';
import { ExecuteOutcomeProps } from '../InitialHearingExecuteOutcome';

export const InitialHearingExecuteOutcomeSummaryDismissal: React.FC<ExecuteOutcomeProps> = (
  props: ExecuteOutcomeProps,
) => {
  const { caseData, flowableFunctions } = props.data;
  if (!props.data.caseType) {
    throw new Error('Missing caseType');
  }
  const caseType = props.data.caseType;
  const config =
    props.data.caseType === CaseType.POOR_PERFORMANCE
      ? initial_hearing_pp_executeOutcomeSummaryDismissal
      : initial_hearing_misconduct_executeOutcomeSummaryDismissal;

  const { fields, validationSchema, templateType } = config;
  if (!templateType) {
    throw new Error('Missing templateType');
  }
  const initialValues = getFormDataFromCaseData(fields, caseData);

  // if the form has not been submitted before, autopopulate the company's ccma details if they exist.
  // we use dismissalDate as the check because it is a compulsory field.
  if (!initialValues.dismissalDate) {
    initialValues.contactPersonInCaseOfCCMA = props.data.organisation?.ccmaContactName
      ? props.data.organisation?.ccmaContactName
      : '';
    initialValues.physicalAddress = props.data.organisation?.ccmaContactPhysicalAddress
      ? props.data.organisation?.ccmaContactPhysicalAddress
      : '';
    initialValues.emailAddress = props.data.organisation?.ccmaContactEmail
      ? props.data.organisation?.ccmaContactEmail
      : '';
  }

  const validateRequiredFields = (values: FormikValues): boolean => {
    let isValid = true;

    const dismissalDateError = document.querySelector("span[ErrorMessage='dismissalDate']") as HTMLElement;
    const lastRemunerationDateError = document.querySelector("span[ErrorMessage='lastRemunerationDate']") as HTMLElement;
    const dismissalDateInput = document.querySelector("input[name='dismissalDate']") as HTMLElement;
    const lastRemunerationDateInput = document.querySelector("input[name='lastRemunerationDate']") as HTMLElement;

    if (!values.dismissalDate) {
      // Display error message or style
      if (dismissalDateError) {
        dismissalDateError.style.display = 'block';
      }
      if (dismissalDateInput) {
        dismissalDateInput.focus();
      }
      isValid = false;
    } else {
      if (dismissalDateError) {
        dismissalDateError.style.display = 'none';
      }
    }

    if (!values.lastRemunerationDate) {
      // Display error message or style
      if (lastRemunerationDateError) {
        lastRemunerationDateError.style.display = 'block';
      }
      if (lastRemunerationDateInput) {
        lastRemunerationDateInput.focus();
      }
      isValid = false;
    } else {
      if (lastRemunerationDateError) {
        lastRemunerationDateError.style.display = 'none';
      }
    }

    return isValid;
  };

  const submitValues = (values: FormikValues): void => {
    const formValues = { ...values };
    if (props.data.caseType === CaseType.MISCONDUCT) {
      formValues.incidents = JSON.stringify(values.incidents);
    } else if (props.data.caseType === CaseType.POOR_PERFORMANCE) {
      formValues.performanceShortfalls = JSON.stringify(values.performanceShortfalls);
    }
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  const renderModal = (): JSX.Element => {
    return <GuidanceModalSummaryDismissal />;
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Conclude Hearing</h4>
      <Row>
        <Col md={12}>
          <Tabs page={9} tabs={HEARING_TABS} />
        </Col>
      </Row>
      <h5 className="text-h5 text-blue text-capitalize font-weight-500 mt-3"> Execute Sanction outcomes</h5>
      {renderModal()}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submitValues}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <InitialHearingExecuteOutcomeSummaryDismissalForm caseType={caseType} />
            <button
              className="btn prepare-letter-button mb-3"
              onClick={(): void => {
                if (validateRequiredFields(values)) {
                  props.data.flowableFunctions.onOpenNewDocument(values, fields, templateType);
                }
              }}
            >
              PREPARE SUMMARY DISMISSAL LETTER DRAFT
            </button>
            {props.data.processInstanceId ? (
              <DocumentVersionControlTable
                data={props.data}
                processInstanceId={props.data.processInstanceId}
                templateType={templateType}
                completeTask={props.data.flowableFunctions.completeTask}
                additionalAttachmentFormKeys={props.additionalBucketKeys}
              />
            ) : null}
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
            {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
          </>
        )}
      </Formik>
    </div>
  );
};
