import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import * as queryString from 'querystring';
import LoginView from './LoginView/LoginView';
import ForgotPasswordView from './ForgotPasswordView/ForgotPasswordView';
import ChangePasswordView from './ChangePasswordView/ChangePasswordView';
import ForgotPasswordSubmissionView from './ForgotPasswordSubmissionView/ForgotPasswordSubmissionView';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import { verifyUser } from '../../utils/cognito-utils';
import MFAView from './MFAView/MFAView';

/**
 * Query Parameters supported:
 * continue: String <url> - Redirects to url after successful auth or if already authorised
 * redirect: string - Redirects internally
 * logout: Boolean - automatically logs out if set to true
 */

interface LoginScreenState {
  username: string;
  step: 0 | 1 | 2 | 3 | 4; // 0: LoginView, 1: ForgotPasswordView, 2: ForgotPasswordViewSubmission
  acceptedTerms: boolean;
  user?: CognitoUser;
}

export type LoginScreenProps = Omit<RouteComponentProps, 'match'>;

const LoginScreen: React.FC<LoginScreenProps> = (props: LoginScreenProps) => {
  const { location } = props;

  const [state, setState] = useState<LoginScreenState>({ username: '', step: 0, acceptedTerms: false });
  const { username, step, user } = state;

  const query = queryString.parse(location.search.slice(1));

  useEffect(() => {
    if (user && query.redirect && !Array.isArray(query.redirect)) {
      props.history.push(query.redirect);
    }
    if (user && query.continue && !Array.isArray(query.continue)) {
      window.location.replace(query.continue);
    }
  }, [user, query.continue, query.redirect, props.history]);

  const handleForgotPassword = (): void => setState(oldState => ({ ...oldState, step: 2 }));
  const handleForgotPasswordContinue = (username: string): void =>
    setState(oldState => ({ ...oldState, username, step: 3 }));
  const handleCancelForgotPassword = (): void => setState(oldState => ({ ...oldState, step: 0 }));
  const handleOnAuthenticated = async (user?: CognitoUser, firstLogin?: boolean, mfa?: boolean): Promise<void> => {
    if (user && firstLogin) {
      verifyUser(user).catch(error => console.log(error));
    }
    let stepCount = firstLogin ? 1 : 0;

    if (mfa) {
      stepCount = 4;
    }
    // @ts-ignore
    setState(oldState => ({ ...oldState, user, step: stepCount }));
  };

  const changePasswordView = step === 1;
  const forgotPasswordView = step === 2;
  const forgotPasswordSubmitView = step === 3;
  const mfaView = step === 4;
  return (
    <div className="hero">
      <Container className="vh-100">
        <Row className="h-100  d-flex align-items-end justify-content-end">
          <Col className="h-100 py-5 col-5 d-flex align-items-center">
            <Form>
              <Card className="bg-white card-white my-2">
                <CardHeader className="p-5 align-self-center">
                  <img
                    className="logo"
                    style={{ height: '80px' }}
                    src={require('../../assets/img/LabourTeq_Logo_Secondary.svg')}
                    alt="LabourTeq_Logo"
                  />
                </CardHeader>
                <CardBody className="card-body d-flex flex-column px-3">
                  {step === 0 && (
                    <LoginView
                      user={user}
                      onForgotPassword={handleForgotPassword}
                      onAuthenticated={handleOnAuthenticated}
                    />
                  )}
                  {changePasswordView && <ChangePasswordView user={user} onComplete={handleCancelForgotPassword} />}
                  {forgotPasswordView && (
                    <ForgotPasswordView
                      handleCancelForgotPassword={handleCancelForgotPassword}
                      handleMoveForward={handleForgotPasswordContinue}
                    />
                  )}
                  {forgotPasswordSubmitView && (
                    <ForgotPasswordSubmissionView
                      handleCancelForgotPassword={handleCancelForgotPassword}
                      onComplete={handleCancelForgotPassword}
                      username={username}
                    />
                  )}
                  {mfaView && (
                    <MFAView
                      onAuthenticated={handleOnAuthenticated}
                      // @ts-ignore
                      user={user}
                    />
                  )}
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginScreen;
