import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import { CaseTableData } from '../../utils/case-utils';
import './CaseTable.styles.scss';
import { UncontrolledTooltip } from 'reactstrap';
import { toStartCase } from '../../screens/SetupAndConfigurationScreens/DisciplinaryMatrix/DisciplinaryMatrixData';
import { toTitleCase } from '../../utils/string-utils';
import { CaseClosedIcon } from '../icon/Icon.component';
import { startCase } from 'lodash';

export interface CaseTableProps {
  caseData: CaseTableData[];
  caseNumberPath?: string;
  auditTrailPath?: string;
  showTabs?: boolean;
  showActionButtons: boolean;
  showEmployeeName?: boolean;
}

const tabs = ['ALL', 'MISCONDUCT', 'POOR_PERFORMANCE', 'ILL_HEALTH', 'RETRENCHMENTS', 'GRIEVANCES', 'DISPUTES'];

const CaseTable: React.FC<CaseTableProps> = (props: CaseTableProps) => {
  const { showTabs = true } = props;
  const [currentTab, setCurrentTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [pageSize] = useState(5);
  const [currentPageSize, setCurrentPageSize] = useState(
    props.caseData.length < pageSize ? props.caseData.length : pageSize,
  );

  const handleFirstPage = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();
    setCurrentPage(0);
    setCurrentPageSize(pageSize);
  };

  const handleNextPage = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();
    if (currentPage >= 0) {
      setCurrentPage(currentPage + 1);
      if (currentPageSize + pageSize > props.caseData.length) setCurrentPageSize(props.caseData.length);
      else setCurrentPageSize(currentPageSize + pageSize);
    }
  };

  const handlePreviousPage = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();
    setCurrentPage(currentPage - 1);
    if (currentPageSize - pageSize < pageSize) setCurrentPageSize(pageSize);
    else setCurrentPageSize(currentPageSize - pageSize);
  };

  const handleLastPage = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();
    setCurrentPage(lastPage - 1);
    setCurrentPageSize(props.caseData.length);
  };

  const handleGoToPage = (e: React.MouseEvent<HTMLElement, MouseEvent>, page: number): void => {
    e.preventDefault();
    setCurrentPage(page);
    if (currentPageSize + pageSize > props.caseData.length) setCurrentPageSize(props.caseData.length);
    if (currentPageSize - pageSize < pageSize) setCurrentPageSize(pageSize);
  };

  const changeTab = (tab: number): void => {
    setCurrentTab(tab);
  };

  const headerText = classnames({
    'text-info': true,
  });

  const rowText = classnames({
    'text-default': true,
  });

  const getPaginationLinks = (totalCount: number, currentPage: number, windowSize: number): number[] => {
    const noOfPages = Math.ceil(totalCount / 5);

    const standardCurrentPage = currentPage + 1;

    const maxWindow = standardCurrentPage + windowSize >= noOfPages ? noOfPages : standardCurrentPage + windowSize;
    const minWindow = standardCurrentPage - windowSize < 1 ? 1 : standardCurrentPage - windowSize;

    const pageLinks: number[] = [];
    for (let i = minWindow; i <= maxWindow; i++) {
      pageLinks.push(i);
    }

    return pageLinks;
  };

  const getPath = (caseItem: CaseTableData): string | undefined => {
    if (props.caseNumberPath && props.caseNumberPath.includes('past-case')) return props.caseNumberPath + caseItem.id;
    return props.caseNumberPath + caseItem.id;
  };

  useEffect(() => {
    if (props.caseData) {
      const pages = Math.ceil(props.caseData.length / pageSize);
      setLastPage(pages);
    }
  }, [props.caseData, pageSize]);

  return (
    <>
      <div>
        {showTabs && (
          <Nav className="nav-pills-info flex-nowrap" pills>
            {tabs.map((tabName, index) => {
              if (['ILL_HEALTH', 'RETRENCHMENTS', 'GRIEVANCES', 'DISPUTES'].includes(tabName)) {
                return (
                  <span key={index} style={{ cursor: 'pointer', minWidth: '9vw', height: 'auto' }}>
                    <NavItem>
                      <NavLink
                        disabled
                        data-toggle="tab"
                        className={tabs[currentTab] === tabName ? 'active' : ''}
                        style={{
                          width: '100%',
                          padding: '0.8vw 2vw',
                          fontSize: '13px',
                          lineHeight: '18px',
                          border: 'none',
                        }}
                      >
                        {toTitleCase(tabName, '_')}
                      </NavLink>
                    </NavItem>
                  </span>
                );
              } else {
                return (
                  <span
                    key={index}
                    style={{ cursor: 'pointer', minWidth: '10vw', height: 'auto' }}
                    onClick={e => changeTab(index)}
                  >
                    <NavItem>
                      <NavLink
                        data-toggle="tab"
                        className={tabs[currentTab] === tabName ? 'active' : ''}
                        style={{
                          width: '100%',
                          padding: '0.8vw 0.5vw',
                          fontSize: '13px',
                          lineHeight: '18px',
                          border: 'none',
                          wordWrap: 'normal',
                        }}
                      >
                        {toTitleCase(tabName, '_')}
                      </NavLink>
                    </NavItem>
                  </span>
                );
              }
            })}
          </Nav>
        )}
      </div>
      {!props.caseData?.length ||
      !props.caseData.filter(
        (caseItem: CaseTableData) => caseItem.caseType === tabs[currentTab] || tabs[currentTab] === 'ALL',
      ).length ? (
        <div className="d-flex mt-4 justify-content-center">
          <span className=" text-muted"> There are no cases to view </span>
        </div>
      ) : (
        <>
          <Table borderless striped hover>
            <thead>
              <tr>
                {props.showEmployeeName && <th className={`${headerText}`}>Employee</th>}
                <th className={`${headerText}`}>Case Number</th>
                <th className={`${headerText}`}>Sanction</th>
                <th className={`${headerText}`}>Case Status</th>
                <th className={`${headerText}`}>Expiry Date</th>
                <th className={`${headerText}`}>Description</th>
                <th className={`${headerText}`}>Frequency</th>
                <th className={`${headerText}`}>Case Type</th>
                {props.showActionButtons && <th className={`${headerText}`}>Action</th>}
              </tr>
            </thead>
            <tbody>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {// @ts-ignore
              props.caseData
                .filter(
                  (caseItem: CaseTableData) => caseItem.caseType === tabs[currentTab] || tabs[currentTab] === 'ALL',
                )
                .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                .map((item: CaseTableData, index: number) => {
                  return (
                    <tr key={index}>
                      {props.showEmployeeName && (
                        <td>
                          <span style={{ cursor: 'pointer' }} className={`${rowText}`}>
                            {item.employeeName}
                          </span>
                        </td>
                      )}
                      <td>
                        <Link to={{ pathname: getPath(item), state: item }}>
                          <span style={{ cursor: 'pointer' }} className="text-default text-underline">
                            {item.caseNumber}
                          </span>
                        </Link>
                      </td>
                      <td>
                        <span className={`${rowText}`}>{item.sanction}</span>
                      </td>
                      <td>
                        <span className={`${rowText}`}>{item.caseStatus}</span>
                      </td>
                      <td>
                        <span className={`${rowText}`}>{item.expiryDate}</span>
                      </td>
                      <td style={{ width: '15%' }}>
                        <span className={`${rowText} flex-wrap`}>{item.description}</span>
                      </td>
                      <td>
                        <span className={`${rowText}`}>{startCase(item.frequency)}</span>
                      </td>
                      <td>
                        <span className={`${rowText}`}>{toStartCase(item.caseType)}</span>
                      </td>
                      {props.showActionButtons && (
                        <>
                          <td>
                            <Link
                              className="btn view-case-button pl-1 py-1"
                              to={{ pathname: `${props.auditTrailPath}` + item.id }}
                              id={'auditTrail'}
                            >
                              <span>
                                <i className="fas fa-history display-5 mr-1" />
                              </span>
                              <UncontrolledTooltip target={'auditTrail'} delay={0} placement={'right'}>
                                Case Audit trail
                              </UncontrolledTooltip>
                            </Link>
                            {/** Unsure about functionality hiding until request is solid */}
                            {/* <Button className={'case-upload-button pl-1'} onClick={() => console.log('upload')}>
                        <i className="fas fa-file-upload display-4 mr-1" />
                      </Button> */}
                          </td>
                          <td>
                            <Link
                              className="btn view-case-button pl-1 py-1"
                              to={{ pathname: '/case/' + item.id + '/history' }}
                              id={'history'}
                            >
                              <span>
                                <i className="fas fa-book display-5 mr-1" />
                              </span>
                              <UncontrolledTooltip target={'history'} delay={0} placement={'right'}>
                                History
                              </UncontrolledTooltip>
                            </Link>
                          </td>
                          <td>
                            {item.showDisputeButton && (
                              <Link
                                className="btn view-case-button pl-1 py-1"
                                to={{ pathname: '/case/' + item.id }}
                                id={'dispute'}
                              >
                                <div style={{ width: 25 }}>
                                  <CaseClosedIcon color="white" secondColor="white" width={20} height={20} />
                                </div>
                                <UncontrolledTooltip target={'dispute'} delay={0} placement={'right'}>
                                  Dispute referral
                                </UncontrolledTooltip>
                              </Link>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {props.caseData!.length > 5 ? (
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ borderTop: '1px solid lightgrey', paddingTop: '8px' }}
            >
              <span style={{ color: '#6D7278' }}>
                Showing {currentPageSize} of {props.caseData.length} entries
              </span>
              <Pagination aria-label="Page navigation" className="d-flex justify-content-end" style={{ color: 'blue' }}>
                <PaginationItem disabled={currentPage <= 0}>
                  <PaginationLink first onClick={e => handleFirstPage(e)} className="pagination-item">
                    FIRST
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={currentPage <= 0}>
                  <PaginationLink previous onClick={e => handlePreviousPage(e)} className="pagination-item">
                    PREVIOUS
                  </PaginationLink>
                </PaginationItem>
                {getPaginationLinks(props.caseData!.length, currentPage, 2).map(pageNumber => (
                  <PaginationItem key={pageNumber}>
                    <PaginationLink
                      style={{
                        color: 'white',
                        borderRadius: '50%',
                        width: '30px',
                        background:
                          currentPage + 1 === pageNumber
                            ? '#50E3C2'
                            : 'linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%)',
                        boxShadow: '0px 1px 15px rgba(39, 39, 39, 0.1)',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={e => handleGoToPage(e, pageNumber - 1)}
                    >
                      {pageNumber}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem disabled={lastPage <= 0 || currentPage >= lastPage - 1}>
                  <PaginationLink next onClick={e => handleNextPage(e)} className="pagination-item">
                    NEXT
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={currentPage >= lastPage - 1}>
                  <PaginationLink last onClick={e => handleLastPage(e)} className="pagination-item">
                    LAST
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default CaseTable;
