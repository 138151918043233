import React, { ReactElement, useContext, useEffect, useState } from 'react';
import TopBarComponent from '../../components/topBar/TopBar.component';
import GuidanceModal from '../../components/guidanceModals/GuidanceModal.component';
import { bucketPath } from '../../utils/storage-utils';
import TransgressionsForm from '../SetupAndConfigurationScreens/DisciplinaryMatrix/TransgressionsForm';
import { Formik, FormikProps, FormikValues } from 'formik';
import Loader from '../../components/loader/Loader';
import { getOrganisationForDisciplinaryMatrix } from '../../utils/company-setup-utils';
import { UserContext, UserContextProps } from '../../App';
import { getOrganisation } from '../../graphql/queries';
import { TransgressionInput, UpdateOrganisationInput } from '../../API';
import { useErrorHandler } from '../../utils/notification-utils';
import { defaultDisciplinaryCodeValues } from '../SetupAndConfigurationScreens/DisciplinaryMatrix/DisciplinaryMatrixData';
import CustomTransgressionsSchema from '../SetupAndConfigurationScreens/DisciplinaryMatrix/DisciplinaryMatrixValidationSchema';
import { updateDisciplinaryMatrixMutation } from '../../graphql-custom/custom-mutations';
import { mutate } from '../../utils/graphql-utils';
import { useHistory } from 'react-router-dom';
import {
  NotificationAlertContext,
  NotificationAlertContextProps,
  NotificationAlertOptions,
} from '../../layouts/AdminLayout';
import TransgressionsFormFAQPage from '../SetupAndConfigurationScreens/DisciplinaryMatrix/TransgressionsFormFAQPage';

export const HelpAndFAQScreen: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modalIsOpen3, setModalIsOpen3] = useState(false);
  const [modalIsOpen4, setModalIsOpen4] = useState(false);
  const [modalIsOpen5, setModalIsOpen5] = useState(false);
  const [modalIsOpen6, setModalIsOpen6] = useState(false);

  const [modalIsOpen7, setModalIsOpen7] = useState(false);
  const [modalIsOpen8, setModalIsOpen8] = useState(false);
  const [modalIsOpen9, setModalIsOpen9] = useState(false);
  const [modalIsOpen10, setModalIsOpen10] = useState(false);
  const [modalIsOpen11, setModalIsOpen11] = useState(false);
  const [modalIsOpen12, setModalIsOpen12] = useState(false);
  const [modalIsOpen13, setModalIsOpen13] = useState(false);
  const [modalIsOpen14, setModalIsOpen14] = useState(false);

  const [loading, setLoading] = useState(true);
  const notificationContext = useContext<Partial<NotificationAlertContextProps>>(NotificationAlertContext);
  const [organisation, setOrganisation] = useState<UpdateOrganisationInput>();
  const [defaultTransgressions, setDefaultTransgressions] = useState<(TransgressionInput | null)[]>([]);
  const [customTransgressions, setCustomTransgressions] = useState<(TransgressionInput | null)[]>([]);

  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const handleError = useErrorHandler();
  const history = useHistory();

  const prepareData = (organisationObject: UpdateOrganisationInput): void => {
    if (organisationObject && organisationObject.id) {
      if (organisationObject.disciplinaryCode) {
        console.log('HERE', organisationObject.disciplinaryCode);
        const defaultTrans = organisationObject.disciplinaryCode.filter(transgression => transgression!.default);
        setDefaultTransgressions(defaultTrans);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const customTrans = organisationObject.disciplinaryCode.filter(transgression => !transgression.default);
        setCustomTransgressions(customTrans);
      }
    }
  };

  const showMatrixUpdateNotification = (): void => {
    if (notificationContext.showNotificationCallback) {
      const options: NotificationAlertOptions = {
        place: 'tr',
        message: 'Disciplinary Code Updated!',
        type: 'primary',
        icon: 'tim-icons icon-bell-55',
        autoDismiss: 7,
      };
      notificationContext.showNotificationCallback(options);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (currentUser && currentUser.organisationId) {
      getOrganisationForDisciplinaryMatrix(getOrganisation, currentUser.organisationId)
        .then(organisation => {
          setOrganisation(organisation);
          if (organisation.disciplinaryCode) {
            prepareData(organisation);
            setLoading(false);
          } else {
            setLoading(false);
            handleError(new Error('No disciplinary code found for your organisation'));
          }
        })
        .catch(error => {
          setLoading(false);
          handleError(error);
        });
    } else {
      handleError(new Error('No current user'));
      setLoading(false);
    }
  }, [currentUser, handleError]);

  const concatArrays = (values: FormikValues): [] => {
    const arrays = [values.defaultTransgressions, values.customTransgressions];
    // concat only arrays, as custom transgressions may be null or undefined
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return [].concat(...arrays.filter(Array.isArray));
  };

  const submitValues = async (values: FormikValues): Promise<void> => {
    if (organisation) {
      const vals: TransgressionInput[] = concatArrays(values);
      organisation.disciplinaryCode = vals;
      const org: UpdateOrganisationInput = {
        id: organisation.id,
        disciplinaryCode: vals,
      };
      await mutate(updateDisciplinaryMatrixMutation, org)
        .then(() => {
          showMatrixUpdateNotification();
          history.push('/dashboard');
        })
        .catch(error => handleError(error));
    } else handleError(new Error('No organisation'));
  };

  const generateSrc = (fileName: any) => {
    return `${bucketPath}/public/planners/${fileName}.pdf`;
  };

  const generateTitle = (action: any) => {
    return `Guidance: How to ${action}`;
  };

  return (
    <>
      <TopBarComponent title={'Help & FAQ'} subTitle={'View Help & FAQ'} hideSearch={true}></TopBarComponent>

      <div className="content">
        <div style={{ display: 'block', marginLeft: '27px', width: '100%' }}>
          <h4 className="text-h4 text-capitalize font-weight-500">1. Disciplinary Code: </h4>
          <div style={{ width: '50%', marginTop: '25px', marginBottom: '25px' }}>
            <GuidanceModal
              isOpen={modalIsOpen}
              setModal={setModalIsOpen}
              buttonTitle={'Disciplinary Code'}
              title="Guidance: Disciplinary Code"
            >
              <>
                <Formik
                  initialValues={{
                    defaultTransgressions: defaultTransgressions.length
                      ? defaultTransgressions
                      : defaultDisciplinaryCodeValues,
                    customTransgressions: customTransgressions.length ? customTransgressions : undefined,
                  }}
                  validationSchema={CustomTransgressionsSchema}
                  enableReinitialize
                  onSubmit={submitValues}
                >
                  {({ values, setFieldValue, handleSubmit, errors }: FormikProps<FormikValues>): ReactElement => (
                    <>
                      {loading ? (
                        <div className="d-flex justify-content-center mt-5">
                          <Loader />
                        </div>
                      ) : (
                        <>
                          <TransgressionsFormFAQPage />
                          <hr style={{ border: '0.06em solid #adb5bd' }} />
                        </>
                      )}
                    </>
                  )}
                </Formik>
              </>
            </GuidanceModal>
          </div>

          <div className="section" style={{ width: '50%', marginTop: '20px' }}>
            <h4 className="text-h4 text-capitalize font-weight-500">2. General System Usage:</h4>
            <GuidanceModal
              isOpen={modalIsOpen2}
              setModal={setModalIsOpen2}
              buttonTitle="How to become a Labourteq user"
              title={generateTitle('become a Labourteq user')}
            >
              <iframe src={generateSrc('How-to-become-a-labourteq-user')} width="100%" height="1000" />
            </GuidanceModal>

            <GuidanceModal
              isOpen={modalIsOpen3}
              setModal={setModalIsOpen3}
              buttonTitle="How to reset your own password"
              title={generateTitle('reset your own password')}
            >
              <iframe src={generateSrc('How-to-reset-your-own-password')} width="100%" height="1000" />
            </GuidanceModal>

            <GuidanceModal
              isOpen={modalIsOpen4}
              setModal={setModalIsOpen4}
              buttonTitle="How to start a new case"
              title={generateTitle('start a new case')}
            >
              <iframe src={generateSrc('How-to-start-a-new-case')} width="100%" height="1000" />
            </GuidanceModal>

            <GuidanceModal
                isOpen={modalIsOpen14}
                setModal={setModalIsOpen14}
                buttonTitle="How to send a document for review"
                title={generateTitle('send a document for review')}
            >
              <iframe src={generateSrc('How-to-send-a-document-for-review')} width="100%" height="1000" />
            </GuidanceModal>
          </div>

          <div className="section" style={{ width: '50%', marginTop: '20px' }}>
            <h4 className="text-h4 text-capitalize font-weight-500">3. Misconduct:</h4>
            <GuidanceModal
              isOpen={modalIsOpen5}
              setModal={setModalIsOpen5}
              buttonTitle="How to execute a Warning"
              title={generateTitle('execute a warning')}
            >
              <iframe src={generateSrc('How-to-execute-a-Warning')} width="100%" height="1000" />
            </GuidanceModal>

            <GuidanceModal
              isOpen={modalIsOpen7}
              setModal={setModalIsOpen7}
              buttonTitle="How to execute a Misconduct Hearing (guidance coming soon) "
              title={generateTitle('Execute a Misconduct Hearing (guidance coming soon) ')}
            >
              {/* <iframe src={generateSrc('')} width="100%" height="1000" /> */}
            </GuidanceModal>

            <GuidanceModal
              isOpen={modalIsOpen8}
              setModal={setModalIsOpen8}
              buttonTitle="For Chairpersons: How to access resources and upload a ruling"
              title={generateTitle('For Chairpersons: How to access resources and upload a ruling')}
            >
              <iframe
                src={generateSrc('Chairperson-Guide-How-to-access-resources-and-upload-a-ruling')}
                width="100%"
                height="1000"
              />
            </GuidanceModal>

            <GuidanceModal
              isOpen={modalIsOpen9}
              setModal={setModalIsOpen9}
              buttonTitle="How to execute the approval process for hearing outcomes"
              title={generateTitle('execute the approval process for hearing outcomes')}
            >
              <iframe
                src={generateSrc('How-to-execute-the-approval-process-for-hearing-outcomes')}
                width="100%"
                height="1000"
              />
            </GuidanceModal>
          </div>

          <div className="section" style={{ width: '50%', marginTop: '20px' }}>
            <h4 className="text-h4 text-capitalize font-weight-500">4. Company Administrators:</h4>
            <GuidanceModal
              isOpen={modalIsOpen10}
              setModal={setModalIsOpen10}
              buttonTitle="How to invite a new user"
              title={generateTitle('invite a new user')}
            >
              <iframe src={generateSrc('How-to-invite-a-new-user')} width="100%" height="1000" />
            </GuidanceModal>

            <GuidanceModal
              isOpen={modalIsOpen11}
              setModal={setModalIsOpen11}
              buttonTitle="How to resend a user invite"
              title={generateTitle('resend a user invite')}
            >
              <iframe src={generateSrc('How-to-resend-a-user-invite')} width="100%" height="1000" />
            </GuidanceModal>

            <GuidanceModal
              isOpen={modalIsOpen12}
              setModal={setModalIsOpen12}
              buttonTitle="How to reset a user's password when locked out"
              title={generateTitle("reset a user's password when locked out")}
            >
              <iframe src={generateSrc('How-to-reset-a-users-password-when-locked-out')} width="100%" height="1000" />
            </GuidanceModal>

            <GuidanceModal
              isOpen={modalIsOpen13}
              setModal={setModalIsOpen13}
              buttonTitle="How to deactivate a user"
              title={generateTitle('deactivate a userv')}
            >
              <iframe src={generateSrc('How-to-deactivate-a-user')} width="100%" height="1000" />
            </GuidanceModal>
          </div>

          <div className="section" style={{ width: '50%', marginTop: '20px' }}>
            <h4 className="text-h4 text-capitalize font-weight-500">5. Raise A Support Ticket:</h4>
            <GuidanceModal
              isOpen={modalIsOpen6}
              setModal={setModalIsOpen6}
              buttonTitle="How to Raise a Support Ticket"
              title={generateTitle('Raise a Support Ticket')}
            >
              <iframe src={generateSrc('How-to-Raise-a-Support-Ticket-final')} width="100%" height="1000" />
            </GuidanceModal>
          </div>
        </div>
      </div>
    </>
  );
};
