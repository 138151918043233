import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { ErrorMessage } from 'formik';
import FormField from '../fields/FormField.component';
import UploaderContainer from '../../components/Uploader/UploaderContainer';

const OrganisationForm: React.FC = () => {
  return (
    <>
      <h4 className="text-default mt-2 font-weight-bold">Company Information</h4>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="organisationName" className="text-default">
              Company name*
            </Label>
            <FormField type="text" placeholder="Name" name="organisationName" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="organisationName" />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="type" className="text-default">
              Company Type*
            </Label>
            <FormField
              className="text-capitalize"
              type="select"
              placeholder="Type"
              name="type"
              selectOptions={['SINGLE', 'HOLDING']}
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="type" />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="maxEmployees" className="text-default">
              Max Employees*
            </Label>
            <FormField type="number" placeholder="Number" name="maxEmployees" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="maxEmployees" />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="termsAndConditions" className="text-default">
              Terms and Conditions*
            </Label>
            <UploaderContainer fieldName={'termsAndConditions'} path={'termsAndConditions'} isMulti={false} />
          </FormGroup>
        </Col>
      </Row>
      <h4 className="text-default mt-2 font-weight-bold">Company Main Contact</h4>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="mainContactFirstName" className="text-default">
              First Name*
            </Label>
            <FormField type="text" placeholder="First Name" name="mainContactFirstName" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="mainContactFirstName" />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="mainContactLastName" className="text-default">
              Last Name*
            </Label>
            <FormField type="text" placeholder="Last Name" name="mainContactLastName" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="mainContactLastName" />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="mainContactEmail" className="text-default">
              Email Address*
            </Label>
            <FormField type="email" placeholder="Email Address" name="mainContactEmail" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="mainContactEmail" />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="mainContactNumber" className="text-default">
              Phone Number*
            </Label>
            <FormField type="text" placeholder="Number" name="mainContactNumber" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="mainContactNumber" />
            </span>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default OrganisationForm;
