//Validation schema with Yup
//first wizard page
import moment from 'moment';
import * as Yup from 'yup';
import { DATE_FORMAT } from '../../forms/fields/FormField.component';

export const PersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required field')
    .min(2, 'First name must have 2 or more characters'),
  lastName: Yup.string()
    .required('Required field')
    .min(2, 'Last Name must have 2 or more characters'),
  idNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(13, 'Must be exactly 13 digits')
    .max(13, 'Must be exactly 13 digits')
    .nullable(),
  passportNumber: Yup.string()
    .nullable()
    .when('idNumber', {
      is: undefined,
      then: Yup.string().required('Provide either ID number or passport number'),
    }),
  phoneNumbers: Yup.array().of(
    Yup.object().shape({
      number: Yup.string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(10, 'Must be exactly 10 digits')
        .max(10, 'Must be exactly 10 digits'),
    }),
  ),
  emails: Yup.array().of(
    Yup.object().shape({
      address: Yup.string()
        .email('This is not a valid email')
        .nullable(),
      emailAddressType: Yup.string().nullable(),
    }),
  ),
  physicalAddresses: Yup.array().of(
    Yup.object().shape({
      physicalAddressType: Yup.string().required('Required field'),
      lineOne: Yup.string()
        .required('Required field')
        .min(2, 'Address line must have 2 or more characters'),
      lineTwo: Yup.string()
        .required('Required field')
        .min(2, 'Address line must have 2 or more characters'),
      city: Yup.string().required('Required field'),
      province: Yup.string().required('Required field'),
      postalCode: Yup.string()
        .required('Required field')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(4, 'Must be exactly 4 digits')
        .max(4, 'Must be exactly 4 digits'),
    }),
  ),
});

//second wizard page
export const EmploymentSchema = Yup.object().shape({
  employeeNumber: Yup.string()
    .required('Required field')
    .nullable(),
  departmentID: Yup.string().required('Required field'),
  placeOfWork: Yup.string()
    .nullable()
    .required('Required Field'),
  locationID: Yup.string().required('Required Field'),
  race: Yup.string()
    .required('Required field')
    .nullable(),
  gender: Yup.string()
    .required('Required field')
    .nullable(),
  hireDate: Yup.string().test('date', 'Required Field', val => moment(val, DATE_FORMAT).isValid()),
  directManager: Yup.string()
    .nullable()
    .required('Required Field'),
  jobTitle: Yup.string()
    .required('Required field')
    .min(2, 'Name is too short')
    .nullable(),
  jobLevel: Yup.string()
    .required('Required field')
    .min(2, 'Name is too short')
    .nullable(),
  status: Yup.string()
    .required('Required Field')
    .nullable(),
});

//Third wizard page
const UserSchema = Yup.object().shape({
  systemUserEmail: Yup.string()
    .nullable()
    .when('isSystemUser', {
      is: true,
      then: Yup.string().required('Required'),
    })
    .email('Enter valid email'),
});

export const schemaArray = [PersonalInfoSchema, EmploymentSchema, UserSchema];
