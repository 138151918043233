import React, { useContext } from 'react';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import UploaderContainer from '../../../../../components/Uploader/UploaderContainer';
import { toTitleCase } from '../../../../../utils/string-utils';
import { FormikValues, useFormikContext } from 'formik';
import { FormProps } from '../../../../WorkflowContainer/workflow-utils';
import { UserContext, UserContextProps } from '../../../../../App';
import { EmailRecipient, EmailType } from '../../../../../utils/email-utils';
import { EmailPreviewModalv3 } from '../../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { convertToFlowableVariables } from '../../../../../utils/flowable/flowable-utils';

export const AppealHearingExternalRepresentationSubmissionsForm: React.FC<FormProps> = props => {
  const { values } = useFormikContext<FormikValues>();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const getRecipients = (): EmailRecipient[] => {
    return [
      {
        emailAddress: props.data.caseData.appeal_hearing_chairpersonEmailAddress,
        name: toTitleCase(props.data.caseData.appeal_hearing_chairpersonFirstName, ' '),
      },
    ];
  };

  return (
    <Form>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="exampleFile" className="text-default text-capitalize">
              Upload Employee submission
            </Label>
            <UploaderContainer
              fieldName={'appeal_hearing_externalRepresentationApplicationEmployeeSubmissionDocuments'}
              path={`cases/${props.data.masterProcessInstanceId}`}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="exampleFile" className="text-default text-capitalize">
              Upload Employer Response
            </Label>
            <UploaderContainer
              fieldName={'appeal_hearing_externalRepresentationApplicationEmployerResponseDocuments'}
              path={`cases/${props.data.masterProcessInstanceId}`}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <EmailPreviewModalv3
            buttonText={'Send Email to Chairperson'}
            disabled={
              !values.appeal_hearing_externalRepresentationApplicationEmployeeSubmissionDocuments &&
              !values.appeal_hearing_externalRepresentationApplicationEmployerResponseDocuments
            }
            formValues={values}
            emailType={EmailType.NOTIFY_CHAIRPERSON_OF_SUBMISSIONS_FOR_EXTERNAL_REPRESENTATION}
            masterProcessInstanceId={props.data.masterProcessInstanceId}
            processInstanceId={props.data.processInstanceId}
            currentUserId={currentUser?.id}
            getRecipients={getRecipients}
            getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
          />
        </Col>
      </Row>
    </Form>
  );
};
