import React, { ReactElement } from 'react';
import { Col, Row } from 'reactstrap';
import { Formik, FormikProps, FormikValues } from 'formik';
import { CaseType } from '../../../../../../API';
import { filterFormData, getFormDataFromCaseData } from '../../../../../WorkflowContainer/workflow-utils';
import { HEARING_TABS } from '../../../../../../utils/case-utils';
import Tabs from '../../../../../../components/tabs/Tabs.component';
import { InitialHearingExecuteOutcomeDemotionForm } from './InitialHearingExecuteOutcomeDemotionForm';
import { DocumentVersionControlTable } from '../../../../../../components/documentVersionControlTable/DocumentVersionControlTable';
import { WorkFlowFooter } from '../../../../../../components/workflowFooter/WorkFlowFooter';
import { initial_hearing_misconduct_executeOutcomeDemotion, initial_hearing_pp_executeOutcomeDemotion } from './config';
import { ExecuteOutcomeProps } from '../InitialHearingExecuteOutcome';

export const InitialHearingExecuteOutcomeDemotion: React.FC<ExecuteOutcomeProps> = props => {
  const { caseData, flowableFunctions } = props.data;
  if (!props.data.caseType) {
    throw new Error('Missing caseType');
  }
  const caseType = props.data.caseType;
  const config =
    caseType === CaseType.POOR_PERFORMANCE
      ? initial_hearing_pp_executeOutcomeDemotion
      : initial_hearing_misconduct_executeOutcomeDemotion;

  const { fields, validationSchema, templateType } = config;
  if (!templateType) {
    throw new Error('Missing templateType');
  }
  const initialValues = getFormDataFromCaseData(fields, caseData);

  // if the form has not been submitted before, autopopulate the company's ccma details if they exist.
  // we use dismissalDate as the check because it is a compulsory field.
  if (!initialValues.dismissalDate) {
    initialValues.contactPersonInCaseOfCCMA = props.data.organisation?.ccmaContactName
      ? props.data.organisation?.ccmaContactName
      : '';
    initialValues.physicalAddress = props.data.organisation?.ccmaContactPhysicalAddress
      ? props.data.organisation?.ccmaContactPhysicalAddress
      : '';
    initialValues.emailAddress = props.data.organisation?.ccmaContactEmail
      ? props.data.organisation?.ccmaContactEmail
      : '';
  }

  const submitValues = (values: FormikValues): void => {
    const formValues = { ...values };
    if (caseType === CaseType.MISCONDUCT) {
      formValues.incidents = JSON.stringify(values.incidents);
    } else if (caseType === CaseType.POOR_PERFORMANCE) {
      formValues.performanceShortfalls = JSON.stringify(values.performanceShortfalls);
    }
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Conclude Hearing</h4>
      <Row>
        <Col md={12}>
          <Tabs page={9} tabs={HEARING_TABS} />
        </Col>
      </Row>
      <h5 className="text-h5 text-blue text-capitalize font-weight-500 mt-3"> Execute Sanction outcomes</h5>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submitValues}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <InitialHearingExecuteOutcomeDemotionForm caseType={caseType} />
            <button
              className="btn prepare-letter-button mb-3"
              onClick={(): void => {
                props.data.flowableFunctions.onOpenNewDocument(values, fields, templateType);
              }}
            >
              PREPARE DEMOTION LETTER
            </button>
            {props.data.processInstanceId ? (
              <DocumentVersionControlTable
                data={props.data}
                processInstanceId={props.data.processInstanceId}
                templateType={templateType}
                completeTask={props.data.flowableFunctions.completeTask}
                additionalAttachmentFormKeys={props.additionalBucketKeys}
              />
            ) : null}
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
