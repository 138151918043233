import React, { useContext, useEffect, useState } from 'react';
import Select, { ValueType } from 'react-select';

import {
  CATEGORY_OPTIONS,
  EMPLOYEE_OPTIONS,
  FREQUENCY_OPTIONS,
  OptionType,
  GENDER_OPTIONS,
  RACE_OPTIONS,
  STATUS_OPTIONS,
  YES_NO_OPTIONS,
  DISABILITY_OPTIONS,
  getDisciplinaryCode,
  getTransgressionCategories,
  getTransgressionsByCategory,
  getSanctionOptions,
  CASE_TYPE_OPTIONS,
  PROCESS_TYPE_OPTIONS,
  IS_CASE_CLOSED_OPTIONS,
  getCaseStatusOptions,
  convertChartDropdownToCaseAttributeQuery,
} from './chart-options-utils';
import { UserContext, UserContextProps } from '../../../../App';
import { useErrorHandler } from '../../../../utils/notification-utils';
import {
  tableFieldSelectStyles,
  ValueContainer,
} from '../../../../components/reactSelect/ReactSelectComponents.component';
import { list, listActiveEmployeesByOrganisationId } from '../../../../utils/graphql-utils';
import {
  listDepartments,
  listJobGrades,
  listJobLevels,
  listJobTitles,
  listLocations,
} from '../../../../graphql/queries';
import { Department, Employee, JobGrade, JobLevel, JobTitle, Location, Transgression } from '../../../../models';
import { CaseDataSetQuery } from './case-reports-types';

interface ChartFilterProps {
  heading: string;
  onChange: (queries: CaseDataSetQuery[]) => void;
}

export interface ChartDropdownsMultiSelect {
  firstSelect: OptionType | null;
  secondSelect: OptionType | OptionType[] | null;
  thirdSelect: OptionType[] | null;
}

export const ChartDropdowns: React.FC<ChartFilterProps> = props => {
  const TRANSGRESSION_OPTIONS = getTransgressionCategories();
  const SANCTION_OPTIONS = getSanctionOptions();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const [disciplinaryCode, setDisciplinaryCode] = useState<Transgression[] | null>([]);
  const [firstSelect, setFirstSelect] = useState<OptionType | null>(null);
  const [secondSelect, setSecondSelect] = useState<OptionType | OptionType[] | null>([]);
  const [thirdSelect, setThirdSelect] = useState<OptionType[] | null>([]);

  const [firstSelectOptions] = useState<OptionType[]>(CATEGORY_OPTIONS);
  const [secondSelectOptions, setSecondSelectOptions] = useState<OptionType[]>([]);
  const [thirdSelectOptions, setThirdSelectOptions] = useState<OptionType[]>([]);

  const handleError = useErrorHandler();

  useEffect(() => {
    try {
      const queries = convertChartDropdownToCaseAttributeQuery({ firstSelect, secondSelect, thirdSelect });
      props.onChange(queries);
    } catch (error) {}
  }, [firstSelect, secondSelect, thirdSelect]);

  const renderFirstSelect = (): JSX.Element => {
    return (
      <Select
        placeholder="Select Category"
        cacheOptions
        options={firstSelectOptions}
        closeMenuOnSelect={true}
        styles={tableFieldSelectStyles}
        onChange={(value: ValueType<any>): void => {
          if (value && firstSelect && value.value !== firstSelect.value) {
            setSecondSelect([]);
            setThirdSelect([]);
            setSecondSelectOptions([]);
            setThirdSelectOptions([]);
          }

          if (!secondSelect) {
            setSecondSelect([]);
          }

          setFirstSelect(value);
        }}
        components={{ ValueContainer }}
        value={firstSelect}
      />
    );
  };

  const renderSecondSelect = (): JSX.Element => {
    const isMulti = !!(firstSelect && firstSelect.value !== 'employeeAttributes');
    return (
      <Select
        placeholder="Select"
        cacheOptions
        options={secondSelectOptions}
        closeMenuOnSelect={true}
        styles={tableFieldSelectStyles}
        onChange={(value: ValueType<any>): void => {
          setThirdSelect([]);
          setSecondSelect(value || []);
        }}
        isMulti={isMulti}
        components={{ ValueContainer }}
        value={secondSelect}
      />
    );
  };

  const renderThirdSelect = (): JSX.Element => {
    if (thirdSelectOptions.length) {
      return (
        <Select
          placeholder="Select Third"
          cacheOptions
          options={thirdSelectOptions}
          closeMenuOnSelect={true}
          styles={tableFieldSelectStyles}
          onChange={(value: ValueType<any>): void => {
            setThirdSelect(value || []);
          }}
          isMulti={true}
          components={{ ValueContainer }}
          value={thirdSelect}
        />
      );
    }
    return <></>;
  };

  const populateThirdSelectOptions = () => {
    const firstSelectValue = firstSelect && firstSelect.value;
    if (currentUser && currentUser.organisationId) {
      if (firstSelectValue === 'transgressionCategory' && disciplinaryCode) {
        const secondSelectValue: OptionType[] = secondSelect && Array.isArray(secondSelect) ? secondSelect : [];
        const transgressionCategories = secondSelectValue.map(item => `${item.value}`);
        const transgressionOptions = getTransgressionsByCategory(disciplinaryCode, transgressionCategories);
        setThirdSelectOptions(transgressionOptions);
      }
      if (firstSelectValue === 'employeeAttributes') {
        const secondSelectValue = secondSelect && !Array.isArray(secondSelect) ? secondSelect.value : '';
        if (secondSelectValue === 'departmentID') {
          list(listDepartments, { filter: { organisationID: { eq: currentUser.organisationId } } })
            .then(res => {
              if (res && res.data) {
                const departments = (res.data as any).listDepartments.items;
                const departmentOptions = departments.map((d: Department) => ({
                  label: d.name,
                  value: d.id,
                }));
                setThirdSelectOptions(departmentOptions);
              }
            })
            .catch(error => handleError(error));
        }
        if (secondSelectValue === 'locationID') {
          list(listLocations, { filter: { organisationID: { eq: currentUser.organisationId } } })
            .then(res => {
              if (res && res.data) {
                const locations = (res.data as any).listLocations.items;
                const locationOptions = locations.map((d: Location) => ({
                  label: d.name,
                  value: d.id,
                }));
                setThirdSelectOptions(locationOptions);
              }
            })
            .catch(error => handleError(error));
        }
        if (secondSelectValue === 'gender') {
          setThirdSelectOptions(GENDER_OPTIONS);
        }
        if (secondSelectValue === 'race') {
          setThirdSelectOptions(RACE_OPTIONS);
        }
        if (secondSelectValue === 'status') {
          setThirdSelectOptions(STATUS_OPTIONS);
        }
        if (secondSelectValue === 'hasDisability') {
          setThirdSelectOptions(YES_NO_OPTIONS);
        }
        if (secondSelectValue === 'disability') {
          setThirdSelectOptions(DISABILITY_OPTIONS);
        }
        if (secondSelectValue === 'jobTitleID') {
          list(listJobTitles, { filter: { organisationID: { eq: currentUser.organisationId } } })
            .then(res => {
              if (res && res.data) {
                const titles = (res.data as any).listJobTitles.items;
                const titleOptions = titles.map((d: JobTitle) => ({
                  label: d.name,
                  value: d.id,
                }));
                setThirdSelectOptions(titleOptions);
              }
            })
            .catch(error => handleError(error));
        }
        if (secondSelectValue === 'jobLevelID') {
          list(listJobLevels, { filter: { organisationID: { eq: currentUser.organisationId } } })
            .then(res => {
              if (res && res.data) {
                const levels = (res.data as any).listJobLevels.items;
                const levelOptions = levels.map((d: JobLevel) => ({
                  label: d.name,
                  value: d.id,
                }));
                setThirdSelectOptions(levelOptions);
              }
            })
            .catch(error => handleError(error));
        }
        if (secondSelectValue === 'jobGradeID') {
          list(listJobGrades, { filter: { organisationID: { eq: currentUser.organisationId } } })
            .then(res => {
              if (res && res.data) {
                const grades = (res.data as any).listJobGrades.items;
                const gradeOptions = grades.map((d: JobGrade) => ({
                  label: d.name,
                  value: d.id,
                }));
                setThirdSelectOptions(gradeOptions);
              }
            })
            .catch(error => handleError(error));
        }
        if (secondSelectValue === 'directManagerID' || secondSelectValue === 'indirectManagerID') {
          listActiveEmployeesByOrganisationId(currentUser.organisationId).then(employees => {
            const employeeOptions = employees.map((e: Employee) => ({
              label: `${e.firstName} ${e.lastName}`,
              value: e.id,
            }));
            setThirdSelectOptions(employeeOptions);
          });
        }
      }
    }
  };

  const populateSecondSelectOptions = () => {
    const optionMapping: { [key: string]: OptionType[] } = {
      employeeAttributes: EMPLOYEE_OPTIONS,
      transgressionCategory: TRANSGRESSION_OPTIONS,
      frequency: FREQUENCY_OPTIONS,
      potentialSanction: SANCTION_OPTIONS,
      processType: PROCESS_TYPE_OPTIONS,
      caseType: CASE_TYPE_OPTIONS,
      isCaseClosed: IS_CASE_CLOSED_OPTIONS,
      caseStatus: getCaseStatusOptions(),
    };
    const firstSelectValue = firstSelect && firstSelect.value;
    let options: OptionType[] = [];
    if (firstSelectValue && typeof firstSelectValue === 'string') {
      options = (firstSelectValue && optionMapping[firstSelectValue]) || [];
    }
    setSecondSelectOptions(options);
  };

  useEffect(() => {
    if (currentUser?.organisationId) {
      getDisciplinaryCode(currentUser.organisationId)
        .then(res => {
          setDisciplinaryCode(res);
        })
        .catch(error => handleError(error));
    } else {
      handleError('No organisationId');
    }
  }, []);

  useEffect(() => {
    populateThirdSelectOptions();
  }, [firstSelect, secondSelect]);

  useEffect(() => {
    populateSecondSelectOptions();
  }, [firstSelect]);

  return (
    <>
      {renderFirstSelect()}
      {renderSecondSelect()}
      {renderThirdSelect()}
    </>
  );
};

export default ChartDropdowns;
