import React from 'react';

import './AdhocButton.styles.scss';
import { Button } from 'reactstrap';

interface AdhocButtonProps {
  onClick?: () => void;
  children: any;
  className: string;
  id: string;
}

const AdhocButton: React.FC<AdhocButtonProps> = (props: AdhocButtonProps) => {
  const { children, className, onClick, id } = props;
  return (
    <>
      <Button id={id} onClick={onClick} className={`${className}`}>
        {children}
      </Button>
    </>
  );
};

export default AdhocButton;
