import React, { useContext, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import FormField from '../../../../../forms/fields/FormField.component';
import ButtonWithIcons from '../../../../../components/buttons/ButtonWIthIcons.component';
import UploaderContainer from '../../../../../components/Uploader/UploaderContainer';
import { CaseIncident, FormProps } from '../../../../WorkflowContainer/workflow-utils';
import { UserContextProps } from '../../../../../App';
import { UserContext } from '../../../../../Router';
import { pdf } from '@react-pdf/renderer';
import { Storage } from 'aws-amplify';
import { EmailPreviewModalv3 } from '../../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { EmailType } from '../../../../../utils/email-utils';
import { AppealHearingChairpersonsRulingPDF } from './AppealHearingChairpersonsRulingPDF';
import { convertToFlowableVariables } from '../../../../../utils/flowable/flowable-utils';

export const AppealHearingChairpersonsRulingForm: React.FC<FormProps> = props => {
  const [toggleView, setToggleView] = useState(false);
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const generateFindingsPDF = async (): Promise<string> => {
    const pdfDocumentJSX = <AppealHearingChairpersonsRulingPDF data={props.data} values={values} />;
    return new Promise<any>((resolve, reject) => {
      if (pdfDocumentJSX) {
        pdf(pdfDocumentJSX)
          .toBlob()
          .then(async blob => {
            const file = new File([blob], 'appeal_hearing_chairperson_findings.pdf');
            await Storage.put(
              `cases/${props.data.masterProcessInstanceId}/appeal_hearing_chairperson_findings.pdf`,
              file,
              {
                level: 'public',
              },
            )
              .then((response: Record<string, any>) => {
                resolve(response.key);
                setFieldValue('appeal_hearing_chairpersonsRulingDocuments', [
                  { key: response.key, url: 'generatedPDF', fileName: 'generatedPDF' },
                ]);
              })
              .catch(error => reject(error));
          });
      } else {
        reject(new Error('Could not generate document'));
      }
    });
  };

  const getAttachmentPaths = (): Promise<string[]> => {
    return new Promise<string[]>((resolve, reject) => {
      if (!toggleView) {
        if (values.appeal_hearing_chairpersonsRulingDocuments) {
          const keys = values.appeal_hearing_chairpersonsRulingDocuments.map((item: any) => item.key);
          resolve(keys);
        } else {
          reject(new Error('No fields'));
        }
      } else {
        generateFindingsPDF()
          .then((res: string) => {
            resolve([res]);
          })
          .catch(error => reject(error));
      }
    });
  };

  return (
    <Form>
      {toggleView && (
        <>
          <Row>
            <Col>
              <ButtonWithIcons
                title={'Upload Chairperson Ruling'}
                handleClick={(): void => {
                  setToggleView(!toggleView);
                  setFieldValue('isUpload', true);
                }}
              />
            </Col>
          </Row>
        </>
      )}
      {!toggleView && (
        <>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="exampleFile" className="text-default text-capitalize">
                  Upload Chairperson Ruling
                </Label>
                <UploaderContainer
                  fieldName={'appeal_hearing_chairpersonsRulingDocuments'}
                  path={`cases/${props.data.masterProcessInstanceId}`}
                />
              </FormGroup>
            </Col>
          </Row>
          {values.appeal_hearing_chairpersonsRulingDocuments && (
            <Row>
              <Col>
                <EmailPreviewModalv3
                  buttonText={'Submit to case creator'}
                  disabled={!toggleView && !values.appeal_hearing_chairpersonsRulingDocuments}
                  formValues={values}
                  emailType={EmailType.NOTIFY_CASE_OWNER_OF_CHAIRPERSON_FINDINGS}
                  masterProcessInstanceId={props.data.masterProcessInstanceId}
                  processInstanceId={props.data.processInstanceId}
                  currentUserId={currentUser?.id}
                  getAttachmentBucketKeysAsync={getAttachmentPaths}
                  getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
                />
              </Col>
              <div>
                <Col>
                  <ButtonWithIcons
                    title={'Rather Complete Ruling details'}
                    handleClick={(): void => {
                      setToggleView(!toggleView);
                      setFieldValue('isUpload', false);
                    }}
                    buttonClass="btn btn-simple"
                    style={{
                      width: '26.5vw',
                      borderRadius: 0,
                    }}
                  />
                </Col>
              </div>
            </Row>
          )}
        </>
      )}

      {toggleView && (
        <>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="appeal_hearing_backgroundLeadingToHearing" className="text-default">
                  State briefly the background of what led to the appeal hearing process:
                </Label>
                <FormField
                  type={'textarea'}
                  placeholder="Background"
                  name="appeal_hearing_backgroundLeadingToHearing"
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'appeal_hearing_backgroundLeadingToHearing'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="appeal_hearing_summaryOfAppellantsArguments" className="text-default">
                  Summarise arguments of the appellant:
                </Label>
                <FormField
                  type={'textarea'}
                  placeholder="Summary of Arguments: Appellant"
                  name="appeal_hearing_summaryOfAppellantsArguments"
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'appeal_hearing_summaryOfAppellantsArguments'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="Employer Representative Arguments" className="text-default text-capitalize">
                  Summarise arguments of the employer representative:
                </Label>
                <FormField
                  type={'textarea'}
                  placeholder="Summary of Arguments: Employer Representative"
                  name="appeal_hearing_summaryOfEmployerRepresentativesArguments"
                />
                <span className="text-danger">
                  <ErrorMessage
                    className="text-danger"
                    name={'appeal_hearing_summaryOfEmployerRepresentativesArguments'}
                  />
                </span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="appeal_hearing_newEvidenceConsidered" className="text-default">
                  Summarise any NEW evidence considered, if any:
                </Label>
                <FormField
                  type={'textarea'}
                  placeholder="Summary of new evidence"
                  name="appeal_hearing_newEvidenceConsidered"
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'appeal_hearing_newEvidenceConsidered'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="Legal principles summary" className="text-default text-capitalize">
                  Summarise legal principles applied, if any:
                </Label>
                <FormField
                  type={'text'}
                  placeholder="Summary of Legal Principles"
                  name="appeal_hearing_summaryOfLegalPrinciples"
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'appeal_hearing_summaryOfLegalPrinciples'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="conclusions reached" className="text-default text-capitalize">
                  Conclusions reached:
                </Label>
                <FormField type={'text'} placeholder="Conclusions" name="appeal_hearing_conclusionsReached" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'appeal_hearing_conclusionsReached'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-0">
            <Col md={6}>
              <Label for="disciplinary findings on guilt" className="text-default">
                Appeal outcomes in relation to original incapacity findings. Tick as appropriate:
              </Label>
            </Col>
          </Row>
          <FieldArray name={'incidents'}>
            {(arrayHelpers: FieldArrayRenderProps) => (
              <>
                {!values.incidents && arrayHelpers.push({})}
                {values.incidents &&
                  values.incidents.length &&
                  values.incidents
                    .filter((item: CaseIncident) => item.didEmployeeAppealRuling === 'YES')
                    .map((item: { [key: string]: any }, index: number) => (
                      <>
                        <Row key={index}>
                          <Col md={3}>
                            <h4 className="text-muted text-capitalize mb-0 mt-0">
                              <span className="font-weight-bold">Findings on Guilt:</span> {item.transgression}
                            </h4>
                            <div className="d-flex ml-1 mb-2">
                              <div className="mr-4">
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      value="yes"
                                      checked={values.incidents[index].appeal_hearing_findingsOnGuiltConfirmed}
                                      onChange={(): void => {
                                        setFieldValue(
                                          `incidents.${index}.appeal_hearing_findingsOnGuiltConfirmed`,
                                          true,
                                        );
                                        setFieldValue(
                                          `incidents.${index}.reasonForOverturningGuiltyFinding`,
                                          undefined,
                                        );
                                      }}
                                    />
                                    <span className="form-check-sign">
                                      <span className="check text-muted text-uppercase">Confirmed</span>
                                    </span>
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="ml-4">
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      checked={
                                        values.incidents[index].appeal_hearing_findingsOnGuiltConfirmed === false
                                      }
                                      onChange={(): void => {
                                        setFieldValue(
                                          `incidents.${index}.appeal_hearing_findingsOnGuiltConfirmed`,
                                          false,
                                        );
                                      }}
                                    />
                                    <span className="form-check-sign">
                                      <span className="check text-default text-muted text-uppercase">Overturned</span>
                                    </span>
                                  </Label>
                                </FormGroup>
                              </div>
                              <span className="text-danger">
                                <ErrorMessage
                                  className="text-danger"
                                  name={`incidents.${index}.appeal_hearing_findingsOnGuiltConfirmed`}
                                />
                              </span>
                            </div>
                          </Col>
                          {values.incidents[index].appeal_hearing_findingsOnGuiltConfirmed === false && (
                            <Col md={4}>
                              <FormGroup>
                                <Label for="Reason for overturning guilty finding" className="text-muted">
                                  State reasons for overturning guilty finding
                                </Label>
                                <FormField
                                  type={'text'}
                                  placeholder="Reason for overturning guilty finding"
                                  name={`incidents.${index}.reasonForOverturningGuiltyFinding`}
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name={`incidents.${index}.reasonForOverturningGuiltyFinding`}
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                      </>
                    ))}
              </>
            )}
          </FieldArray>
          <FieldArray name={'incidents'}>
            {(arrayHelpers: FieldArrayRenderProps) => (
              <>
                {!values.incidents && arrayHelpers.push({})}
                {values.incidents &&
                  values.incidents.length &&
                  values.incidents
                    .filter((item: CaseIncident) => item.didEmployeeAppealSanction === 'YES')
                    .map((item: { [key: string]: any }, index: number) => (
                      <>
                        <Row key={index}>
                          <Col md={3}>
                            <h4 className="text-muted text-capitalize mb-0 mt-0">
                              <span className="font-weight-bold">Findings on Sanction:</span> {item.transgression}
                            </h4>
                            <div className="d-flex ml-1 mb-2">
                              <div className="mr-4">
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      value="yes"
                                      checked={values.incidents[index].sanctionConfirmed}
                                      onChange={(): void => {
                                        setFieldValue(`incidents.${index}.sanctionConfirmed`, true);
                                        setFieldValue(`incidents.${index}.reasonForOverturningSanction`, undefined);
                                      }}
                                    />
                                    <span className="form-check-sign">
                                      <span className="check text-muted text-uppercase">Confirmed</span>
                                    </span>
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="ml-4">
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      checked={values.incidents[index].sanctionConfirmed === false}
                                      onChange={(): void => {
                                        setFieldValue(`incidents.${index}.sanctionConfirmed`, false);
                                      }}
                                    />
                                    <span className="form-check-sign">
                                      <span className="check text-default text-muted text-uppercase">Overturned</span>
                                    </span>
                                  </Label>
                                </FormGroup>
                              </div>
                            </div>
                          </Col>
                          {values.incidents[index].sanctionConfirmed === false && (
                            <Col md={4}>
                              <FormGroup>
                                <Label for="Reason for overturning sanction" className="text-muted">
                                  State reasons for overturning sanction
                                </Label>
                                <FormField
                                  type={'text'}
                                  placeholder="Reason for overturning sanction"
                                  name={`incidents.${index}.reasonForOverturningSanction`}
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name={`incidents.${index}.reasonForOverturningSanction`}
                                  />
                                </span>
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                      </>
                    ))}
              </>
            )}
          </FieldArray>
          <Row>
            <Col>
              <EmailPreviewModalv3
                buttonText={'Submit to case creator'}
                disabled={!toggleView && !values.appeal_hearing_chairpersonsRulingDocuments}
                formValues={values}
                emailType={EmailType.NOTIFY_CASE_OWNER_OF_CHAIRPERSON_FINDINGS}
                masterProcessInstanceId={props.data.masterProcessInstanceId}
                processInstanceId={props.data.processInstanceId}
                currentUserId={currentUser?.id}
                getAttachmentBucketKeysAsync={getAttachmentPaths}
                getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'appeal_hearing_chairpersonsRulingDocuments'} />
              </span>
            </Col>
            <Col>
              <ButtonWithIcons
                title={'Rather Upload Ruling'}
                handleClick={(): void => {
                  setToggleView(!toggleView);
                  setFieldValue('isUpload', true);
                }}
                buttonClass="btn btn-simple"
                style={{
                  width: '26.5vw',
                  borderRadius: 0,
                }}
              />
            </Col>
          </Row>
        </>
      )}
      {!toggleView && (
        <>
          <Row>
            <Col>
              <label className="text-default text-uppercase">or</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="exampleFile" className="text-default text-capitalize">
                  Add appeal hearing findings
                </Label>
                <div>
                  <ButtonWithIcons
                    title={'Add / View Appeal Ruling'}
                    handleClick={(): void => {
                      setToggleView(!toggleView);
                      setFieldValue('isUpload', false);
                    }}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};
