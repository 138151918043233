import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, FormikErrors, FormikProps, FormikValues, getIn } from 'formik';
import RecordCounselingForm from './RecordCounsellingForm';
import { BucketFile } from '../../../models';
import { RecordCounsellingSchema } from '../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../WorkflowContainer/workflow-utils';

export interface CounsellingSession {
  sessionId: string;
  counsellingDate: string;
  counsellingTime: string;
  counsellingAttendees: any[] | null;
  counsellingSummary?: string;
  counsellingDocuments?: BucketFile[] | null;
  counsellingLocation: string;
  sessionCompleted: boolean;
}

const RecordCounseling: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const fields = {
    performanceShortfalls: 'array',
    performanceCounsellingSessions: 'array',
  };

  const [state, setState] = useState(getFormDataFromCaseData(fields, caseData));
  console.log('state', state);

  useEffect(() => {
    const completedSessions = state.performanceCounsellingSessions.filter(
      (session: CounsellingSession) => session.sessionCompleted,
    );
    const onGoingSessions = state.performanceCounsellingSessions.filter(
      (session: CounsellingSession) => !session.sessionCompleted,
    );

    // get the last session if there are any completed sessions
    if (onGoingSessions.length) {
      setState(oldState => ({
        ...oldState,
        // performanceCounsellingSessions: onGoingSessions,
        counsellingSession: onGoingSessions[0],
      }));
    } else {
      setState(oldState => ({
        ...oldState,
        // performanceCounsellingSessions: completedSessions,
        counsellingSession: completedSessions[completedSessions.length - 1],
      }));
    }
  }, []);

  const setErrors = (errors: FormikErrors<FormikValues>, setFieldTouched: (field: string, touched: boolean) => any) => {
    const errorKeys = Object.keys(errors);
    errorKeys.forEach(field => {
      //if fieldArray
      if (Array.isArray(errors[field])) {
        // @ts-ignore
        errors[field].forEach((item, index) => {
          const keys = Object.keys(item);
          keys.forEach(name => {
            //if inner array
            if (Array.isArray(item[name])) {
              item[name].forEach((obj: { [key: string]: any }, idx: number) => {
                const itemNames = Object.keys(obj);
                itemNames.forEach(fieldName => {
                  setFieldTouched(`${field}.${index}.${name}.${idx}.${fieldName}`, true);
                });
              });
            }
          });
        });
      } else if (errors[field] === Object(errors[field])) {
        // if object
        const err = getIn(errors, field);
        const innerObjKeys = Object.keys(err);
        innerObjKeys.forEach(item => {
          setFieldTouched(`${field}.${item}`, true);
        });
      } else {
        setFieldTouched(field, true);
      }
    });
  };

  const submitValues = async (formValues: FormikValues): Promise<void> => {
    const values = { ...formValues };
    values.performanceShortfalls = JSON.stringify(formValues.performanceShortfalls);

    if (!values.counsellingSession.sessionCompleted) {
      values.counsellingSession.sessionCompleted = true;
      values.performanceCounsellingSessions = JSON.stringify([values.counsellingSession]);
    } else {
      values.performanceCounsellingSessions = JSON.stringify(values.performanceCounsellingSessions);
    }

    delete values.counsellingSession;
    flowableFunctions.onNext(filterFormData(values, fields));
  };
  return (
    <div className="content">
      <h4 className="text-default text-capitalize font-weight-bold">Record Counselling</h4>
      <Formik
        enableReinitialize
        initialValues={state}
        validationSchema={RecordCounsellingSchema}
        onSubmit={submitValues}
      >
        {({ values, errors, handleSubmit, setFieldTouched }: FormikProps<FormikValues>): ReactElement => (
          <>
            {values.counsellingSession && (
              <RecordCounselingForm
                employeeFullName={'someone'}
                employeeEmail={'email@email.com'}
                processInstanceId={props.data.processInstanceId}
              />
            )}

            <WorkFlowFooter
              hideSaveAndCloseButton={true}
              data={props.data}
              onNext={() => {
                setErrors(errors, setFieldTouched);
                if (!Object.keys(errors).length) handleSubmit();
              }}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => flowableFunctions.onSaveAndClose(values)}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default RecordCounseling;
