import React, { ReactElement } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import {
  CaseData,
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { ObjectSchema } from 'yup';
import { getFieldsAndValidationSchema } from '../../hearing-utils';
import InitialHearingAppointChairpersonForm from './InitialHearingAppointChairpersonForm';

const InitialHearingAppointChairperson: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;

  const setUpValues: {
    fields: { [key: string]: string };
    validationSchema: ObjectSchema<object>;
  } = getFieldsAndValidationSchema(props.data.caseType, flowableFunctions.formKey);

  const initialValues: FormikValues = getFormDataFromCaseData(setUpValues.fields, caseData);

  const getFormValuesForSubmission = (values: FormikValues): { [key: string]: any } => {
    const formValues: Partial<CaseData> = values;
    return filterFormData(formValues, setUpValues.fields);
  };

  const submitValues = async (values: FormikValues): Promise<void> => {
    flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  return (
    <div className="content">
      <Formik
        initialValues={initialValues}
        validationSchema={setUpValues.validationSchema}
        enableReinitialize
        onSubmit={submitValues}
      >
        {({ values, handleSubmit, errors }: FormikProps<FormikValues>): ReactElement => (
          <>
            <InitialHearingAppointChairpersonForm
              getFormValuesForSubmission={getFormValuesForSubmission}
              data={props.data}
            />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default InitialHearingAppointChairperson;
