import React, { useState, useContext } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldAttributes,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import FormField from '../../../../../forms/fields/FormField.component';
import ButtonWithIcons from '../../../../../components/buttons/ButtonWIthIcons.component';
import UploaderContainer from '../../../../../components/Uploader/UploaderContainer';
import { Storage } from 'aws-amplify';
import { EmailType } from '../../../../../utils/email-utils';
import { FormProps } from '../../../../WorkflowContainer/workflow-utils';
import Select, { ValueType } from 'react-select';
import { selectStyles, SingleValue } from '../../../../../components/reactSelect/ReactSelectComponents.component';
import { EmailPreviewModalv3 } from '../../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { UserContext, UserContextProps, UserDetails } from '../../../../../App';
import { pdf } from '@react-pdf/renderer';
import InitialHearingPoorPerformanceChairpersonsRulingPDF from './InitialHearingPoorPerformanceChairpersonsRulingPDF';
import { convertToFlowableVariables } from '../../../../../utils/flowable/flowable-utils';

export const InitialHearingPoorPerformanceChairpersonsRulingForm: React.FC<FormProps> = props => {
  const { processInstanceId } = props.data;
  const [toggleView, setToggleView] = useState(false);
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const currentUser: UserDetails | null | undefined = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const generateFindingsPDF = async (): Promise<string> => {
    const pdfDocumentJSX = <InitialHearingPoorPerformanceChairpersonsRulingPDF values={values} />;
    return new Promise<any>((resolve, reject) => {
      if (pdfDocumentJSX) {
        pdf(pdfDocumentJSX)
          .toBlob()
          .then(async blob => {
            const file = new File([blob], 'initial_hearing_chairperson_findings.pdf');
            await Storage.put(
              `cases/${props.data.masterProcessInstanceId}/initial_hearing_chairperson_findings.pdf`,
              file,
              {
                level: 'public',
              },
            )
              .then((response: Record<string, any>) => {
                resolve(response.key);
                setFieldValue('initial_hearing_chairpersonsRulingDocuments', [
                  { key: response.key, url: 'generatedPDF', fileName: 'generatedPDF' },
                ]);
              })
              .catch(error => reject(error));
          });
      } else {
        reject(new Error('Could not generate document'));
      }
    });
  };

  const getAttachmentPaths = (): Promise<string[]> => {
    return new Promise<string[]>((resolve, reject) => {
      if (!toggleView) {
        if (values.initial_hearing_chairpersonsRulingDocuments) {
          const keys = values.initial_hearing_chairpersonsRulingDocuments.map((item: any) => item.key);
          resolve(keys);
        } else {
          reject(new Error('No fields'));
        }
      } else {
        generateFindingsPDF()
          .then((res: string) => {
            resolve([res]);
          })
          .catch(error => reject(error));
      }
    });
  };

  return (
    <Form>
      {!toggleView && (
        <>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="exampleFile" className="text-default text-capitalize">
                  Upload Chairperson Ruling
                </Label>
                <UploaderContainer
                  fieldName={'initial_hearing_chairpersonsRulingDocuments'}
                  path={`cases/${processInstanceId}`}
                />
              </FormGroup>
            </Col>
          </Row>
          {values.initial_hearing_chairpersonsRulingDocuments && (
            <Row>
              <Col>
                <EmailPreviewModalv3
                  buttonText={'Submit to case creator'}
                  disabled={!toggleView && !values.initial_hearing_chairpersonsRulingDocuments}
                  formValues={values}
                  emailType={EmailType.NOTIFY_CASE_OWNER_OF_CHAIRPERSON_FINDINGS}
                  masterProcessInstanceId={props.data.masterProcessInstanceId}
                  processInstanceId={props.data.processInstanceId}
                  currentUserId={currentUser?.id}
                  getAttachmentBucketKeysAsync={getAttachmentPaths}
                  getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
                />
              </Col>
              <div>
                <Col>
                  <ButtonWithIcons
                    title={'Rather Complete Ruling details'}
                    handleClick={(): void => {
                      setToggleView(!toggleView);
                      setFieldValue('isUpload', false);
                    }}
                    buttonClass="btn btn-simple"
                    style={{
                      width: '26.5vw',
                      borderRadius: 0,
                    }}
                  />
                </Col>
              </div>
            </Row>
          )}
        </>
      )}

      {toggleView && (
        <>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="background" className="text-default">
                  State briefly the background of what led to the Incapacity Poor Performance hearing process:*
                </Label>
                <FormField type={'text'} placeholder="Background" name="initial_hearing_backgroundLeadingToHearing" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'initial_hearing_backgroundLeadingToHearing'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="Recorded Employee Rights" className="text-default">
                  Record the employee’s rights exercised in the hearing:*
                </Label>
                <FormField
                  type={'text'}
                  placeholder="Record Employee Rights"
                  name="initial_hearing_employeeRightsExercisedInHearing"
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'initial_hearing_employeeRightsExercisedInHearing'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="Employer Representative Evidence" className="text-default text-capitalize">
                  Summarise evidence submitted by the employer representative:*
                </Label>
                <FormField
                  type={'text'}
                  placeholder="Summary of Evidence: Employer Representative"
                  name="initial_hearing_summaryOfEmployerRepresentativeEvidence"
                />
                <span className="text-danger">
                  <ErrorMessage
                    className="text-danger"
                    name={'initial_hearing_summaryOfEmployerRepresentativeEvidence'}
                  />
                </span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="Employee Representative Evidence" className="text-default text-capitalize">
                  Summarise evidence submitted by the employee and/or his/her representative:*
                </Label>
                <FormField
                  type={'text'}
                  placeholder="Summary of Evidence: Employee Representative"
                  name="initial_hearing_summaryOfEmployeesEvidence"
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'initial_hearing_summaryOfEmployeesEvidence'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="Legal principles summary" className="text-default text-capitalize mb-2">
                  Summarise legal principles applied, if any:*
                </Label>
                <FormField
                  type={'text'}
                  placeholder="Summary of Legal Principles"
                  name="initial_hearing_summaryOfLegalPrinciples"
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'initial_hearing_summaryOfLegalPrinciples'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label
                  for="initial_hearing_conclusionsReachedOnSubStandardPerformance"
                  className="text-default text-capitalize"
                >
                  Conclusions reached on sub-standard performance/shortfalls or otherwise based on factual evidence and
                  legal principles:*
                </Label>
                <FormField
                  type={'text'}
                  placeholder="Conclusions Reached"
                  name="initial_hearing_conclusionsReachedOnSubStandardPerformance"
                />
                <span className="text-danger">
                  <ErrorMessage
                    className="text-danger"
                    name={'initial_hearing_conclusionsReachedOnSubStandardPerformance'}
                  />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-0">
            <Col md={6}>
              <Label for="disciplinary findings on guilt" className="text-default text-capitalize">
                Findings On Incapacity. Tick as appropriate per shortfall:*
              </Label>
            </Col>
          </Row>
          <FieldArray name={'performanceShortfalls'}>
            {(arrayHelpers: FieldArrayRenderProps) => (
              <>
                {!values.performanceShortfalls && arrayHelpers.push({})}
                {values.performanceShortfalls &&
                  values.performanceShortfalls.length &&
                  values.performanceShortfalls.map((item: any, index: number) => (
                    <Row key={index}>
                      <Col md={6}>
                        <h4 className="text-muted text-capitalize mb-0 mt-0">
                          <span className="font-weight-bold">Shortfall:</span> {item.shortfallDescription}
                        </h4>
                        <div className="d-flex ml-1 mb-2">
                          <div className="mr-4">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  value="yes"
                                  checked={
                                    !!values.performanceShortfalls[index].incapacitatedBasedOnDisciplinaryFindings
                                  }
                                  onChange={(): void => {
                                    setFieldValue(
                                      `performanceShortfalls.${index}.incapacitatedBasedOnDisciplinaryFindings`,
                                      true,
                                    );
                                  }}
                                />
                                <span className="form-check-sign">
                                  <span className="check text-muted text-uppercase">Incapacitated</span>
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                          <div className="ml-4">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={
                                    !values.performanceShortfalls[index].incapacitatedBasedOnDisciplinaryFindings
                                  }
                                  onChange={(): void => {
                                    setFieldValue(
                                      `performanceShortfalls.${index}.incapacitatedBasedOnDisciplinaryFindings`,
                                      false,
                                    );
                                  }}
                                />
                                <span className="form-check-sign">
                                  <span className="check text-default text-muted text-uppercase">
                                    Not Incapacitated
                                  </span>
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))}
              </>
            )}
          </FieldArray>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="initial_hearing_summaryOfAggravatingFactors" className="text-default text-capitalize">
                  Summarise aggravating factors considered to determine way forward:*
                </Label>
                <FormField
                  type={'text'}
                  placeholder="Summary of Aggravating Factors"
                  name={`initial_hearing_summaryOfAggravatingFactors`}
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'initial_hearing_summaryOfAggravatingFactors'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="initial_hearing_conclusionsReachedOnWayForward" className="text-default text-capitalize">
                  Conclusions reached on way forward*
                </Label>
                <FormField
                  type={'text'}
                  placeholder="Conclusions"
                  name="initial_hearing_conclusionsReachedOnWayForward"
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'initial_hearing_conclusionsReachedOnWayForward'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="employee comments" className="text-default text-capitalize">
                  Summarise employee comments and mitigating factors considered to determine way forward*:
                </Label>
                <FormField
                  type={'text'}
                  placeholder="Summary of employee comments and mitigation factors"
                  name="initial_hearing_employeeCommentsAndMitigatingFactors"
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'initial_hearing_employeeCommentsAndMitigatingFactors'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="employee comments" className="text-default text-capitalize">
                  Select finding*
                </Label>
                <Field name={`initial_hearing_findingsOnWayForward`}>
                  {({ field }: FieldAttributes<FormikValues>) => (
                    <Select
                      {...field}
                      placeholder="Select Process"
                      cacheOptions
                      options={[
                        {
                          label: 'Extension of PIP with final Review Period',
                          value: 'EXTENSION_OF_PIP_WITH_FINAL_REVIEW_PERIOD',
                        },
                        { label: 'Summary Termination', value: 'SUMMARY_TERMINATION' },
                        { label: 'Termination with Notice Pay', value: 'TERMINATION_WITH_NOTICE_PAY' },
                        { label: 'Other', value: 'OTHER' },
                      ]}
                      closeMenuOnSelect={true}
                      styles={selectStyles}
                      onChange={(value: ValueType<any>): void => {
                        setFieldValue(`initial_hearing_findingsOnWayForward`, value.value);
                      }}
                      value={{
                        label: values.initial_hearing_findingsOnWayForward,
                        value: values.initial_hearing_findingsOnWayForward,
                      }}
                      components={{ SingleValue }}
                    />
                  )}
                </Field>
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'initial_hearing_findingsOnWayForward'} />
                </span>
              </FormGroup>
            </Col>
            {values.initial_hearing_findingsOnWayForward === 'OTHER' && (
              <Col md={6}>
                <FormGroup>
                  <Label for="employee comments" className="text-default text-capitalize">
                    Reason for selecting other*
                  </Label>
                  <FormField type={'text'} placeholder="Enter Reason" name="initial_hearing_reasonForSelectingOther" />
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'initial_hearing_reasonForSelectingOther'} />
                  </span>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <EmailPreviewModalv3
                buttonText={'Submit to case creator'}
                disabled={!toggleView && !values.initial_hearing_chairpersonsRulingDocuments}
                formValues={values}
                emailType={EmailType.NOTIFY_CASE_OWNER_OF_CHAIRPERSON_FINDINGS}
                masterProcessInstanceId={props.data.masterProcessInstanceId}
                processInstanceId={props.data.processInstanceId}
                currentUserId={currentUser?.id}
                getAttachmentBucketKeysAsync={getAttachmentPaths}
                getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
              />
            </Col>
            <Col>
              <ButtonWithIcons
                title={'Rather Upload Ruling'}
                handleClick={(): void => {
                  setToggleView(!toggleView);
                  setFieldValue('isUpload', true);
                }}
                buttonClass="btn btn-simple"
                style={{
                  width: '26.5vw',
                  borderRadius: 0,
                }}
              />
            </Col>
          </Row>
        </>
      )}
      {!toggleView && !values.initial_hearing_chairpersonsRulingDocuments && (
        <>
          <Row>
            <Col>
              <label className="text-default text-uppercase">or</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="exampleFile" className="text-default text-capitalize">
                  Capture Chairperson's Ruling
                </Label>
                <div>
                  <ButtonWithIcons
                    title={'Add / View Ruling'}
                    handleClick={(): void => {
                      setToggleView(!toggleView);
                      setFieldValue('isUpload', false);
                    }}
                    buttonClass="btn btn-simple"
                    style={{
                      width: '26.5vw',
                      borderRadius: 0,
                    }}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};
