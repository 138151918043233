import { useCallback, useContext } from 'react';
import { NotificationAlertContext, NotificationAlertContextProps } from '../layouts/AdminLayout';

export const useErrorHandler = (): ((errorObject: any) => void) => {
  const notificationContext = useContext<Partial<NotificationAlertContextProps>>(NotificationAlertContext);
  const errorFunction = useCallback((errorObject: any) => {
    let errorMessage = 'Error';
    if (errorObject && !errorObject.message && errorObject['errors']?.length && errorObject['errors'][0].message) {
      errorMessage = errorObject['errors'][0].message;
    } else if (errorObject?.message && typeof errorObject.message === 'string') {
      errorMessage = errorObject.message;
    } else if (errorObject && typeof errorObject === 'string') {
      errorMessage = errorObject;
    }

    if (notificationContext.showErrorNotificationAlert) {
      notificationContext.showErrorNotificationAlert(errorMessage);
    }
  }, []);
  return errorFunction;
};

export const useEmailSentHandler = (): (() => void) => {
  const notificationContext = useContext<Partial<NotificationAlertContextProps>>(NotificationAlertContext);
  return (): void => {
    if (notificationContext.showEmailSentAlert) {
      notificationContext.showEmailSentAlert();
    }
  };
};
