import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../../../components/PDF/PDFStyles.style';
import Table from '../../../../../components/PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../../../components/PDF/PDFTable/TableHeader.component';
import TableRow from '../../../../../components/PDF/PDFTable/TableRow.component';
import TableCell from '../../../../../components/PDF/PDFTable/TableCell.component';
// import List from '../../../PDF/List.component';
// import ListItem from '../../../PDF/ListItem.component';
import Paragraph from '../../../../../components/PDF/Paragraph.component';
import { LogoPosition } from '../../../../../API';
import LetterHead from '../../../../../components/PDF/Letterhead.component';
import { FormikValues } from 'formik';
import { toTitleCase } from '../../../../../utils/string-utils';

interface Transgression {
  complainants: Array<string>;
  date: string;
  frequency: string;
  guiltyBasedOnDisciplinaryFindings: boolean;
  location: string;
  potentialSanction: string;
  process: string;
  summaryOfFacts: string;
  time: string;
  transgression: string;
  transgressionCategory: string;
  witnesses: Array<string>;
}

interface ChairpersonRulingPDFProps {
  //content: DocumentJSONContent;
  values: FormikValues;
  logo?: { imageUrl: string; position: LogoPosition };
}

const InitialHearingChairpersonsRulingPDF = (props: ChairpersonRulingPDFProps): JSX.Element => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Chairperson’s Ruling</Text>
        <TableHeader title="Background of what led to the disciplinary hearing process:" />
        <Paragraph>{props.values.initial_hearing_backgroundLeadingToHearing}</Paragraph>
        <TableHeader title="Employee’s rights exercised in the hearing:" />
        <Paragraph>{props.values.initial_hearing_employeeRightsExercisedInHearing}</Paragraph>
        <TableHeader title="Evidence submitted by the employer representative:" />
        <Paragraph>{props.values.initial_hearing_summaryOfEmployerRepresentativeEvidence}</Paragraph>
        <TableHeader title="Evidence submitted by the employee and/or his/her representative:" />
        <Paragraph>{props.values.initial_hearing_summaryOfEmployeesEvidence}</Paragraph>
        <TableHeader title="Legal principles applied, if any:" />
        <Paragraph>{props.values.initial_hearing_summaryOfLegalPrinciples}</Paragraph>
        <TableHeader title="Conclusions reached on guilt or otherwise based on factual evidence and legal principles" />
        <Paragraph>{props.values.initial_hearing_conclusionsReachedOnGuilt}</Paragraph>
        <TableHeader title="Disciplinary findings on guilt. Tick as appropriate per charge :" />
        <Table>
          {props.values.incidents.map((transgression: Transgression) => (
            <TableRow key={transgression.transgression}>
              <TableCell>{transgression.transgression}</TableCell>
              <TableCell>{transgression.guiltyBasedOnDisciplinaryFindings ? 'Guilty' : 'Not Guilty'}</TableCell>
            </TableRow>
          ))}
        </Table>
        <TableHeader title="Aggravating factors considered for sanction:" />
        <Paragraph>{props.values.initial_hearing_summaryOfAggravatingFactors}</Paragraph>
        <TableHeader title="Employee comments and mitigating factors considered for sanction:" />
        <Paragraph>{props.values.initial_hearing_employeeCommentsAndMitigatingFactors}</Paragraph>
        <TableHeader title="Conclusions reached on Sanction:" />
        <Paragraph>{props.values.initial_hearing_conclusionsReachedOnSanction}</Paragraph>
        <TableHeader title="Findings on sanction:" />
        <Paragraph>{toTitleCase(props.values.initial_hearing_findingsOnSanction, '_')}</Paragraph>
      </Page>
    </Document>
  );
};

export default InitialHearingChairpersonsRulingPDF;
