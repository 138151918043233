import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from '../PDFStyles.style';

interface TableHeaderComponentProps {
  title: string;
  noUpperCase?: boolean;
}
const TableHeader: React.FC<TableHeaderComponentProps> = (props: TableHeaderComponentProps) => {
  const { title } = props;
  return (
    <View style={styles.tableHeader}>
      <Text>{props.noUpperCase ? title : title.toUpperCase()}</Text>
    </View>
  );
};

export default TableHeader;
