import React, { ReactElement } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import RecordDisciplinaryDiscussionForm from './RecordDisciplinaryDiscussionForm';

import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../components/workflowFooter/WorkFlowFooter';
import { RecordDisciplinaryDiscussionSchema } from '../../../../forms/ValidationSchema/DiscussionValidationSchema';

const RecordDisciplinaryDiscussion: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { flowableFunctions, processInstanceId, caseData } = props.data;

  const fields = {
    disciplinaryDiscussionDate: 'string',
    disciplinaryDiscussionTime: 'string',
    disciplinaryDiscussionAttendees: 'array',
    disciplinaryDiscussionLocation: 'string',
    disciplinaryDiscussionSummary: 'string',
    disciplinaryDiscussionDocuments: 'map',
  };

  const initialValues: FormikValues = getFormDataFromCaseData(fields, caseData);

  const submitValues = (values: FormikValues): void => {
    const formValues = { ...values };
    formValues.disciplinaryDiscussionAttendees = values.disciplinaryDiscussionAttendees.map(
      (attendee: { [key: string]: string }) => {
        return {
          attendeeName: attendee.attendeeName,
          attendeeEmailAddress: attendee.attendeeEmailAddress
            ? attendee.attendeeEmailAddress
            : 'noEmail@labourteq.co.za',
          attendeeId: attendee.attendeeId,
        };
      },
    );
    formValues.disciplinaryDiscussionAttendees = JSON.stringify(formValues.disciplinaryDiscussionAttendees);
    formValues.disciplinaryDiscussionDocuments = JSON.stringify(formValues.disciplinaryDiscussionDocuments);
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Record Disciplinary Discussion</h4>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={RecordDisciplinaryDiscussionSchema}
        onSubmit={submitValues}
      >
        {({ values, setFieldValue, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <RecordDisciplinaryDiscussionForm processInstanceId={processInstanceId} />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={() => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default RecordDisciplinaryDiscussion;
