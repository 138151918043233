import React from 'react';
import TopBarComponent from '../../components/topBar/TopBar.component';

export const IllHealthInfoScreen: React.FC = () => {
  return (
    <>
      <TopBarComponent
        title={'Ill Health'}
        subTitle={'View Ill Health Module Information'}
        hideSearch={true}
      ></TopBarComponent>
      <div className="content"></div>
    </>
  );
};
