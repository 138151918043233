import React, { useState } from 'react';
import { ButtonProps, Input, Table as BootStrapTable, TableProps as BootStrapTableProps } from 'reactstrap';
import { AttemptedEmployee } from './upload-validation-utils';
import Pagination from 'reactstrap/lib/Pagination';
import PaginationItem from 'reactstrap/lib/PaginationItem';
import PaginationLink from 'reactstrap/lib/PaginationLink';

export type Data = { [key: string]: string | number | boolean | null | undefined | object | [] };
export interface TableProps extends BootStrapTableProps {
  data?: AttemptedEmployee[];
  columns: Column[];
  toggle?: (event: any, rowIndex: number) => void;
}
export type Column = ColumnData | ColumnAction;
interface ColumnData {
  label: string;
  key: string;
  type?: 'data';
}
interface ColumnAction {
  label: string;
  type: 'action';
  actions: Action[];
}
interface Action extends ButtonProps {
  label: string;
  func: (data: Data) => void;
}
export const DynamicEmployeeUploadTableForInvalidEntries: React.FC<TableProps> = (props: TableProps) => {
  const { data = [], columns, toggle, ...bootstrapProps } = props;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const pageSize = 10;
  const lastPage = data ? Math.ceil(data.length / pageSize) : 0;

  const handleFirstPage = (e: any) => {
    e.preventDefault();
    setCurrentPage(0);
  };
  const handleNextPage = (e: any) => {
    e.preventDefault();
    if (currentPage >= 0) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = (e: any) => {
    e.preventDefault();
    setCurrentPage(currentPage - 1);
  };
  const handleLastPage = (e: any) => {
    e.preventDefault();
    setCurrentPage(lastPage - 1);
  };

  function renderHeadings(): JSX.Element {
    const renderHeading = (column: Column, headingIndex: number): JSX.Element => (
      <th key={`heading_${headingIndex}`}>{column.label}</th>
    );
    return (
      <thead>
        <tr>{columns.map(renderHeading)}</tr>
      </thead>
    );
  }
  const renderRow = (row: AttemptedEmployee, rowIndex: number): JSX.Element => {
    const renderItem = (column: Column, itemIndex: number): JSX.Element => {
      if (column.label === 'Validation Errors') {
        // @ts-ignore
        return (
          <td key={`row_${rowIndex}_item_${itemIndex}`}>
            {/* @ts-ignore*/}
            {row.validationErrors.map((item: string) => (
              <div className={'text-danger'}>{' - ' + item + '.'}</div>
            ))}
          </td>
        );
      }
      if (column.label === 'Included' && toggle) {
        return (
          <td key={`row_${rowIndex}_item_${itemIndex}`}>
            <Input
              name={row.employeeData.employeeNumber ? row.employeeData.employeeNumber : 'error: no name'}
              type="checkbox"
              checked={row.included}
              onChange={(event: any): void => toggle(event.nativeEvent.target.checked, rowIndex)}
            />
          </td>
        );
      }
      if (!itemIndex && itemIndex !== 0) {
        return (
          <th key={`row_${rowIndex}_item_${itemIndex}`} scope="row">
            {(row.employeeData as any)[(column as any).key]}
          </th>
        );
      }
      return <td key={`row_${rowIndex}_item_${itemIndex}`}>{(row.employeeData as any)[(column as any).key]}</td>;
    };
    return <tr key={`row_${rowIndex}`}>{columns.map(renderItem)}</tr>;
  };

  const renderTableBody = (): JSX.Element => {
    let renderData = data.slice(currentPage * pageSize, pageSize);
    if (renderData.length === 0 && currentPage > 0) {
      renderData = data.slice(currentPage * pageSize);
    }
    return <tbody>{renderData.map(renderRow)}</tbody>;
  };

  return (
    <>
      <BootStrapTable {...bootstrapProps}>
        {renderHeadings()}
        {renderTableBody()}
      </BootStrapTable>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ borderTop: '1px solid lightgrey', paddingTop: '8px' }}
      >
        <span style={{ color: '#6D7278' }}>
          Showing {data.length > 0 ? pageSize * currentPage + 1 : 0}
          {' - '}
          {pageSize * (currentPage + 1) > data.length ? data.length : pageSize * (currentPage + 1)} of {data.length}{' '}
          entries
        </span>
        <Pagination aria-label="Page navigation" className="d-flex justify-content-end" style={{ color: 'blue' }}>
          <PaginationItem disabled={currentPage <= 0}>
            <PaginationLink first onClick={e => handleFirstPage(e)} className="pagination-item">
              FIRST
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={currentPage <= 0}>
            <PaginationLink previous onClick={e => handlePreviousPage(e)} className="pagination-item">
              PREVIOUS
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink
              style={{
                color: 'white',
                borderRadius: '50%',
                width: '30px',
                background: 'linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%)',
                boxShadow: '0px 1px 15px rgba(39, 39, 39, 0.1)',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={e => e.preventDefault()}
            >
              {currentPage + 1}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={lastPage <= 0 || currentPage >= lastPage - 1}>
            <PaginationLink next onClick={e => handleNextPage(e)} className="pagination-item">
              NEXT
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={currentPage >= lastPage - 1}>
            <PaginationLink last onClick={e => handleLastPage(e)} className="pagination-item">
              LAST
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    </>
  );
};
export default DynamicEmployeeUploadTableForInvalidEntries;
