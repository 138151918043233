import React, { ReactElement, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { WorkFlowFooter } from '../../../../components/workflowFooter/WorkFlowFooter';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../WorkflowContainer/workflow-utils';
import { ExtendedPipAssessmentSchema } from '../../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import { TemplateType } from '../../../../API';
import { DocumentVersionControlTable } from '../../../../components/documentVersionControlTable/DocumentVersionControlTable';
import ExtendedPipAssessmentForm from './ExtendedPipAssessmentForm';

const ExtendedPipAssessment: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const fields = {
    extendedPipHavePerformanceConcernsBeenAddressed: 'boolean',
    performanceShortfalls: 'array',
    furtherActionAfterExtendedPip: 'string',
    reasonForFurtherActionAfterExtendedPip: 'string',
  };

  const [data] = useState(() => getFormDataFromCaseData(fields, caseData, caseData.isAppeal));

  const submitValues = async (values: FormikValues): Promise<void> => {
    const formValues = { ...values };
    formValues.performanceShortfalls = JSON.stringify(formValues.performanceShortfalls);
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Extended PIP Assessment</h4>
      <Formik
        initialValues={data}
        onSubmit={submitValues}
        validationSchema={ExtendedPipAssessmentSchema}
        enableReinitialize
      >
        {({ values, errors, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
          <>
            <ExtendedPipAssessmentForm data={props.data} />
            <br />
            <br />
            {values.extendedPipHavePerformanceConcernsBeenAddressed && (
              <>
                <button
                  className="btn prepare-letter-button mb-3"
                  onClick={(): void => {
                    props.data.flowableFunctions.onOpenNewDocument(
                      values,
                      fields,
                      TemplateType.SUCCESSFUL_CONCLUSION_PERFORMANCE_PROCESS_LETTER,
                    );
                  }}
                >
                  Compile PIP Successfully Concluded Letter
                </button>
                {props.data.processInstanceId ? (
                  <DocumentVersionControlTable
                    data={props.data}
                    processInstanceId={props.data.processInstanceId}
                    templateType={TemplateType.SUCCESSFUL_CONCLUSION_PERFORMANCE_PROCESS_LETTER}
                    completeTask={props.data.flowableFunctions.completeTask}
                  />
                ) : null}
              </>
            )}
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => flowableFunctions.onSaveAndClose(values)}
              finalPage={
                values.extendedPipHavePerformanceConcernsBeenAddressed ||
                values.furtherActionAfterExtendedPip === 'OTHER'
              }
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default ExtendedPipAssessment;
