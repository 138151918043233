import React from 'react';
import { Col, Form, Row, Table } from 'reactstrap';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import { startCase } from 'lodash';
import { CaseIncident, CasePerformanceShortfall } from '../../../../../WorkflowContainer/workflow-utils';
import TableFormField from '../../../../../../forms/fields/TableFormField';
import { CaseType } from '../../../../../../API';

const incidentColumns = [
  'Date of Hearing*',
  'Transgressions found guilty of per hearing notice*',
  // 'Period Of Unpaid Suspension*',
  // 'Date of Return To Work*',
  'Suspension Start Date*',
  'Suspension End Date*',
  'Dismissal Date*',
  'Corrective Actions On Return To Work*',
];

const shortfallColumns = [
  'Date of Hearing',
  'Performance shortfalls found guilty of per hearing notice*',
  // 'Period Of Unpaid Suspension',
  // 'Date of Return To Work',
  'Corrective Actions On Return To Work',
];

export const InitialHearingExecuteOutcomeSuspensionWithoutPayForm: React.FC<{ caseType: CaseType }> = props => {
  const { values }: FormikProps<FormikValues> = useFormikContext();

  return (
    <Form>
      <Row>
        <Col>
          <div>
            <h5 className="text-primary text-capitalize font-weight-normal mt-3">
              Compile notice for Suspension Without Pay Letter
            </h5>
            <hr style={{ border: '0.06em solid #adb5bd' }} />
            {props.caseType === CaseType.MISCONDUCT && (
              <Row>
                <Col>
                  <Table className="table-responsive-lg" bordered>
                    <thead>
                      <tr>
                        {incidentColumns.map(column => (
                          <th key={column}>
                            <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name={'incidents'}>
                        {(arrayHelpers: FieldArrayRenderProps) => (
                          <>
                            {!values.incidents && arrayHelpers.push({})}
                            {values.incidents &&
                              values.incidents.length &&
                              values.incidents
                                .filter((incidents: CaseIncident) =>
                                  incidents.initial_hearing_hcApproversFindingOnGuilt
                                    ? incidents.initial_hearing_hcApproversFindingOnGuilt === 'GUILTY'
                                    : incidents.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
                                )
                                .map((item: any, index: number) => (
                                  <tr key={index}>
                                    <td style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'Date'}
                                        name={`initial_hearing_hearingDate`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'initial_hearing_hearingDate'} />
                                      </span>
                                    </td>
                                    <td className="position-relative text-capitalize" style={{ minWidth: '100px' }}>
                                      <span>{startCase(item.transgression.toLowerCase())}</span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'date'}
                                        name={`suspensionStartDate`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'suspensionStartDate'} />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'date'}
                                        name={`suspensionEndDate`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'suspensionEndDate'} />
                                      </span>
                                    </td>

                                    {/*<td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>*/}
                                    {/*  <TableFormField*/}
                                    {/*    type={'number'}*/}
                                    {/*    placeholder={''}*/}
                                    {/*    name={`periodOfUnpaidSuspension`}*/}
                                    {/*    className="square-radius border-0 h-25"*/}
                                    {/*  />*/}
                                    {/*  <span className="text-danger">*/}
                                    {/*    <ErrorMessage className="text-danger" name={'periodOfUnpaidSuspension'} />*/}
                                    {/*  </span>*/}
                                    {/*</td>*/}
                                    {/*<td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>*/}
                                    {/*  <TableFormField*/}
                                    {/*    type={'date'}*/}
                                    {/*    placeholder={'date'}*/}
                                    {/*    name={`returnToWorkDate`}*/}
                                    {/*    className="square-radius border-0 h-25"*/}
                                    {/*  />*/}
                                    {/*  <span className="text-danger">*/}
                                    {/*    <ErrorMessage className="text-danger" name={'returnToWorkDate'} />*/}
                                    {/*  </span>*/}
                                    {/*</td>*/}
                                    <td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'date'}
                                        name={`dismissalDate`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'dismissalDate'} />
                                        {!values.incidents.dismissalDate && <div>&nbsp; required field </div>}
                                      </span>
                                    </td>
                                    <td style={{ minWidth: '100px' }}>
                                      <TableFormField
                                        type={'text'}
                                        placeholder={'Corrective action required'}
                                        name={`incidents.${index}.correctiveAction`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage
                                          className="text-danger"
                                          name={`incidents.${index}.correctiveAction`}
                                        />
                                        {!values.incidents[index].correctiveAction && <div>&nbsp; required field </div>}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                          </>
                        )}
                      </FieldArray>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
            {props.caseType === CaseType.POOR_PERFORMANCE && (
              <Row>
                <Col>
                  <Table className="table-responsive-lg" bordered>
                    <thead>
                      <tr>
                        {shortfallColumns.map(column => (
                          <th key={column}>
                            <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name={'performanceShortfalls'}>
                        {(arrayHelpers: FieldArrayRenderProps) => (
                          <>
                            {!values.performanceShortfalls && arrayHelpers.push({})}
                            {values.performanceShortfalls &&
                              values.performanceShortfalls.length &&
                              values.performanceShortfalls
                                .filter(
                                  (shortfall: CasePerformanceShortfall) =>
                                    shortfall.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
                                )
                                .map((item: CasePerformanceShortfall, index: number) => (
                                  <tr key={index}>
                                    <td style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'Date'}
                                        name={`initial_hearing_hearingDate`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'initial_hearing_hearingDate'} />
                                      </span>
                                    </td>
                                    <td className="position-relative text-capitalize" style={{ minWidth: '100px' }}>
                                      <span>{startCase(item.shortfallDescription.toLowerCase())}</span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'text'}
                                        placeholder={''}
                                        name={`periodOfUnpaidSuspension`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'periodOfUnpaidSuspension'} />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'date'}
                                        name={`returnToWorkDate`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'returnToWorkDate'} />
                                      </span>
                                    </td>
                                    <td style={{ minWidth: '100px' }}>
                                      <TableFormField
                                        type={'text'}
                                        placeholder={'Corrective action required'}
                                        name={`incidents.${index}.correctiveAction`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage
                                          className="text-danger"
                                          name={`incidents.${index}.correctiveAction`}
                                        />
                                        {!values.incidents[index].correctiveAction && <div>&nbsp; required field </div>}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                          </>
                        )}
                      </FieldArray>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
};
