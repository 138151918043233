import React, { ReactElement } from 'react';
import { AddIcon, RemoveIcon } from '../icon/Icon.component';
import { Col, Row } from 'reactstrap';

interface InputRowProps {
  index: number;
  defaultValues: { [key: string]: string | boolean } | string;
  addCallback: (val: string | { [key: string]: any }) => void;
  removeCallback: (val: number) => void;
  children: ReactElement;
  rowClassName?: string;
  iconWidth?: number;
  disabled?: boolean;
}

const InputRow: React.FC<InputRowProps> = (props: InputRowProps) => {
  const {
    index,
    defaultValues,
    addCallback,
    removeCallback,
    children,
    rowClassName,
    disabled = false,
  } = props;

  return (
    <Row className={rowClassName}>
      {children}
      <Col md={1} className="d-flex justify-content-center align-items-center p-0">
        {index === 0 && (
          <div className="mt-md-0">
            <span
              className="pointer"
              onClick={(): void => {
                if (disabled) return;
                addCallback(defaultValues);
              }}
            >
              <AddIcon />
            </span>
          </div>
        )}
        {!disabled && (
          <>
            {index > 0 && (
              <span
                className="pointer"
                onClick={(): void => {
                  removeCallback(index);
                }}
              >
                <RemoveIcon />
              </span>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default InputRow;
