import {
  ErrorMessage,
  Field,
  FieldArrayRenderProps,
  FieldAttributes,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import React, { ChangeEvent, ReactElement, useContext, useState } from 'react';
import { Input, InputGroup } from 'reactstrap';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../components/taskContainer/TaskContainer';
import { autoExpand } from './FormField.component';

interface TableFormFieldWithControlsProps {
  name: string;
  type: string;
  placeholder: string;
  className: string;
  disabled?: boolean;
  arrayHelpers: FieldArrayRenderProps;
  defaultValues: { [key: string]: string };
  index: number;
}

const TableFormFieldWithControls: React.FC<TableFormFieldWithControlsProps> = (
  props: TableFormFieldWithControlsProps,
) => {
  const { placeholder, type, name, index, disabled = false } = props;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const [, setTextAreaHeight] = useState(1);
  const { setFieldValue }: FormikProps<FormikValues> = useFormikContext();

  return (
    <>
      <Field name={name}>
        {({ field }: FieldAttributes<FormikValues>): ReactElement => (
          <>
            <InputGroup
              className="border-white mb-0 h-25"
              style={{ border: '2px solid red', display: 'flex', justifyContent: 'center' }}
            >
              <Input
                {...field}
                type={type}
                placeholder={placeholder}
                className={`form-control h-25 rounded-0 border-bottom-0 border-left-0 border-right-0 ${
                  index === 0 ? 'border-top-0' : 'border-top-1'
                } pr-0 pl-0 py-4`}
                style={{ fontSize: '0.9em', display: 'block' }}
                disabled={readOnly || disabled}
                autoComplete="off"
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  event.preventDefault();
                  setFieldValue(name, event.target.value);
                  autoExpand(event, setTextAreaHeight);
                }}
              />
            </InputGroup>
          </>
        )}
      </Field>
      <span className="text-danger">
        <ErrorMessage className="text-danger" name={name} />
      </span>
    </>
  );
};

export default TableFormFieldWithControls;
