// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const RaceType = {
  "BLACK": "BLACK",
  "AFRICAN": "AFRICAN",
  "COLOURED": "COLOURED",
  "WHITE": "WHITE",
  "INDIAN": "INDIAN",
  "OTHER": "OTHER"
};

const PhoneNumberType = {
  "WORK": "WORK",
  "HOME": "HOME",
  "MOBILE": "MOBILE"
};

const PhysicalAddressType = {
  "WORK": "WORK",
  "RESIDENCE": "RESIDENCE"
};

const ApprovedChairPersonType = {
  "Y": "Y",
  "N": "N"
}

const ApprovedHcApproverType = {
  "Y": "Y",
  "N": "N"
}

const Province = {
  "GAUTENG": "GAUTENG",
  "FREE_STATE": "FREE_STATE",
  "EASTERN_CAPE": "EASTERN_CAPE",
  "KWAZULU_NATAL": "KWAZULU_NATAL",
  "LIMPOPO": "LIMPOPO",
  "MPUMALANGA": "MPUMALANGA",
  "NORTHERN_CAPE": "NORTHERN_CAPE",
  "NORTH_WEST": "NORTH_WEST",
  "WESTERN_CAPE": "WESTERN_CAPE"
};

const EmailAddressType = {
  "WORK": "WORK",
  "PERSONAL": "PERSONAL"
};

const PlaceOfWorkType = {
  "ON_SITE": "ON_SITE",
  "REMOTE": "REMOTE",
  "HYBRID": "HYBRID"
};

const WorkHoursType = {
  "FULL_TIME": "FULL_TIME",
  "PART_TIME": "PART_TIME",
  "FLEXITIME": "FLEXITIME"
};

const StatusType = {
  "PERMANENT": "PERMANENT",
  "FIXED_TERM": "FIXED_TERM",
  "PROBATION": "PROBATION"
};

const DisabilityType = {
  "PHYSICAL": "PHYSICAL",
  "SENSORY": "SENSORY",
  "COGNITIVE_AND_LEARNING": "COGNITIVE_AND_LEARNING",
  "MENTAL_HEALTH": "MENTAL_HEALTH",
  "OTHER": "OTHER"
};

const GenderType = {
  "MALE": "MALE",
  "FEMALE": "FEMALE",
  "OTHER": "OTHER"
};

const CaseType = {
  "NONE": "NONE",
  "MISCONDUCT": "MISCONDUCT",
  "POOR_PERFORMANCE": "POOR_PERFORMANCE",
  "ILL_HEALTH": "ILL_HEALTH",
  "RETRENCHMENTS": "RETRENCHMENTS",
  "GRIEVANCES": "GRIEVANCES",
  "DISPUTES": "DISPUTES"
};

const ReportingPeriod = {
  "YEAR": "YEAR",
  "QUARTER": "QUARTER",
  "MONTH": "MONTH"
};

const LogoPosition = {
  "LEFT": "LEFT",
  "RIGHT": "RIGHT",
  "CENTER": "CENTER"
};

const OrganisationType = {
  "SINGLE": "SINGLE",
  "HOLDING": "HOLDING"
};

const TransgressionCategory = {
  "TIME_KEEPING_AND_ATTENDANCE": "TIME_KEEPING_AND_ATTENDANCE",
  "WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES": "WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES",
  "ALCOHOL_AND_SUBSTANCES": "ALCOHOL_AND_SUBSTANCES",
  "VIOLENCE_AND_AGGRESSIVE_BEHAVIOR": "VIOLENCE_AND_AGGRESSIVE_BEHAVIOR",
  "EMPLOYER_REPUTATION": "EMPLOYER_REPUTATION",
  "HEALTH_SAFETY_AND_SECURITY": "HEALTH_SAFETY_AND_SECURITY",
  "UNPROFESSIONAL_CONDUCT": "UNPROFESSIONAL_CONDUCT",
  "CORPORATE_GOVERNANCE": "CORPORATE_GOVERNANCE",
  "REMOTE_WORK": "REMOTE_WORK",
  "DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT": "DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT",
  "PROPERTY_AND_EQUIPMEMT": "PROPERTY_AND_EQUIPMEMT",
  "INDUSTRIAL_ACTION": "INDUSTRIAL_ACTION",
  "VEHICLES": "VEHICLES",
  "CRIMINAL_OFFENCES": "CRIMINAL_OFFENCES",
  "CONFIDENTIAL_INFORMATION_AND_DATA_BREACH": "CONFIDENTIAL_INFORMATION_AND_DATA_BREACH",
  "DISHONESTY": "DISHONESTY",
  "IT_RESOURCES_AND_SOCIAL_MEDIA": "IT_RESOURCES_AND_SOCIAL_MEDIA",
  "GENERAL": "GENERAL",
  "COVID_19": "COVID_19"
};

const MatrixSanctionNames = {
  "VERBAL_WARNING": "VERBAL_WARNING",
  "WRITTEN_WARNING": "WRITTEN_WARNING",
  "FINAL_WRITTEN_WARNING": "FINAL_WRITTEN_WARNING",
  "DISMISSAL": "DISMISSAL",
  "NOT_APPLICABLE": "NOT_APPLICABLE"
};

const Month = {
  "JANUARY": "JANUARY",
  "FEBRUARY": "FEBRUARY",
  "MARCH": "MARCH",
  "APRIL": "APRIL",
  "MAY": "MAY",
  "JUNE": "JUNE",
  "JULY": "JULY",
  "AUGUST": "AUGUST",
  "SEPTEMBER": "SEPTEMBER",
  "OCTOBER": "OCTOBER",
  "NOVEMBER": "NOVEMBER",
  "DECEMBER": "DECEMBER"
};

const FontPreference = {
  "DYSLEXIC": "DYSLEXIC",
  "DEFAULT": "DEFAULT"
};

const UserRole = {
  "SUPER_ADMIN": "SUPER_ADMIN",
  "COMPANY_ADMIN": "COMPANY_ADMIN",
  "HR_MANAGER": "HR_MANAGER",
  "LINE_EXECUTIVE": "LINE_EXECUTIVE",
  "LINE_MANAGER": "LINE_MANAGER",
  "CASE_USER": "CASE_USER"
};

const NoteType = {
  "PERSONAL": "PERSONAL",
  "CASE": "CASE"
};

const DiscussionSanctionNames = {
  "VERBAL_WARNING": "VERBAL_WARNING",
  "WRITTEN_WARNING": "WRITTEN_WARNING",
  "FINAL_WRITTEN_WARNING": "FINAL_WRITTEN_WARNING"
};

const HearingSanctionNames = {
  "WRITTEN_WARNING": "WRITTEN_WARNING",
  "FINAL_WRITTEN_WARNING": "FINAL_WRITTEN_WARNING",
  "SUMMARY_DISMISSAL": "SUMMARY_DISMISSAL",
  "DEMOTION": "DEMOTION",
  "DISMISSAL_WITH_NOTICE": "DISMISSAL_WITH_NOTICE",
  "SUSPENSION_WITHOUT_PAY": "SUSPENSION_WITHOUT_PAY"
};

const CaseRole = {
  "INVESTIGATOR": "INVESTIGATOR",
  "EMPLOYER_REPRESENTATIVE": "EMPLOYER_REPRESENTATIVE",
  "CHAIRPERSON": "CHAIRPERSON",
  "CASE_MANAGER": "CASE_MANAGER",
  "IT": "IT",
  "PAYROLL": "PAYROLL",
  "FACILITIES": "FACILITIES",
  "OTHER": "OTHER",
  "CCMA": "CCMA"
};

const Gender = {
  "MALE": "MALE",
  "FEMALE": "FEMALE",
  "OTHER": "OTHER"
};

const TemplateType = {
  "AGREED_DEMOTION_LETTER": "AGREED_DEMOTION_LETTER",
  "SUSPENSION_PENDING_INVESTIGATION_LETTER": "SUSPENSION_PENDING_INVESTIGATION_LETTER",
  "SUSPENSION_PENDING_HEARING_LETTER": "SUSPENSION_PENDING_HEARING_LETTER",
  "SUSPENSION_WITHOUT_PAY_LETTER": "SUSPENSION_WITHOUT_PAY_LETTER",
  "WRITTEN_WARNING_DISCUSSION": "WRITTEN_WARNING_DISCUSSION",
  "WRITTEN_WARNING_HEARING": "WRITTEN_WARNING_HEARING",
  "VERBAL_WARNING_DISCUSSION": "VERBAL_WARNING_DISCUSSION",
  "FINAL_WRITTEN_WARNING_DISCUSSION": "FINAL_WRITTEN_WARNING_DISCUSSION",
  "FINAL_WRITTEN_WARNING_HEARING": "FINAL_WRITTEN_WARNING_HEARING",
  "DISMISSAL_WITH_NOTICE_LETTER": "DISMISSAL_WITH_NOTICE_LETTER",
  "DEMOTION_LETTER_MISCONDUCT": "DEMOTION_LETTER_MISCONDUCT",
  "DEMOTION_LETTER_POOR_PERFORMANCE": "DEMOTION_LETTER_POOR_PERFORMANCE",
  "SUCCESSFUL_CONCLUSION_PERFORMANCE_PROCESS_LETTER": "SUCCESSFUL_CONCLUSION_PERFORMANCE_PROCESS_LETTER",
  "PERFORMANCE_IMPROVEMENT_PLAN": "PERFORMANCE_IMPROVEMENT_PLAN",
  "PERFORMANCE_IMPROVEMENT_PLAN_REVISED": "PERFORMANCE_IMPROVEMENT_PLAN_REVISED",
  "EXTENSION_OF_PIP": "EXTENSION_OF_PIP",
  "EXTENSION_OF_PIP_REVISED": "EXTENSION_OF_PIP_REVISED",
  "EXTENSION_OF_PIP_FOLLOWING_HEARING": "EXTENSION_OF_PIP_FOLLOWING_HEARING",
  "EXTENSION_OF_PIP_FOLLOWING_HEARING_REVISED": "EXTENSION_OF_PIP_FOLLOWING_HEARING_REVISED",
  "NOTICE_OF_HEARING_MISCONDUCT": "NOTICE_OF_HEARING_MISCONDUCT",
  "NOTICE_OF_HEARING_POOR_PERFORMANCE": "NOTICE_OF_HEARING_POOR_PERFORMANCE",
  "HEARING_FINDINGS_FORM_POOR_PERFORMANCE": "HEARING_FINDINGS_FORM_POOR_PERFORMANCE",
  "HEARING_FINDINGS_FORM_POOR_PERFORMANCE_APPEAL": "HEARING_FINDINGS_FORM_POOR_PERFORMANCE_APPEAL",
  "SUMMARY_DISMISSAL_LETTER_POOR_PERFORMANCE": "SUMMARY_DISMISSAL_LETTER_POOR_PERFORMANCE",
  "SUMMARY_DISMISSAL_LETTER_MISCONDUCT": "SUMMARY_DISMISSAL_LETTER_MISCONDUCT",
  "DISMISSAL_WITH_NOTICE_PAY_LETTER_POOR_PERFORMANCE": "DISMISSAL_WITH_NOTICE_PAY_LETTER_POOR_PERFORMANCE",
  "NOTICE_OF_HEARING_MISCONDUCT_APPEAL": "NOTICE_OF_HEARING_MISCONDUCT_APPEAL",
  "NOTICE_OF_HEARING_POOR_PERFORMANCE_APPEAL": "NOTICE_OF_HEARING_POOR_PERFORMANCE_APPEAL",
  "APPEAL_FORM_POOR_PERFORMANCE": "APPEAL_FORM_POOR_PERFORMANCE",
  "HEARING_OUTCOME_MISCONDUCT_APPEAL": "HEARING_OUTCOME_MISCONDUCT_APPEAL",
  "HEARING_OUTCOME_POOR_PERFORMANCE_APPEAL": "HEARING_OUTCOME_POOR_PERFORMANCE_APPEAL"
};

const AuditLogEventType = {
  "EMPLOYEE_CREATED": "EMPLOYEE_CREATED",
  "EMPLOYEE_EDITED": "EMPLOYEE_EDITED",
  "USER_CREATED": "USER_CREATED",
  "USER_EDITED": "USER_EDITED",
  "BULK_EMPLOYEE_UPLOAD": "BULK_EMPLOYEE_UPLOAD",
  "CASE_STARTED": "CASE_STARTED",
  "CASE_FILE_UPLOADED": "CASE_FILE_UPLOADED",
  "WORKFLOW_PROGRESSED": "WORKFLOW_PROGRESSED",
  "DOCUMENT_CREATED": "DOCUMENT_CREATED",
  "DOCUMENT_NEW_VERSION": "DOCUMENT_NEW_VERSION",
  "DOCUMENT_APPROVAL_REQUESTED": "DOCUMENT_APPROVAL_REQUESTED",
  "DOCUMENT_APPROVED": "DOCUMENT_APPROVED",
  "DOCUMENT_EMAILED_TO_EMPLOYEE": "DOCUMENT_EMAILED_TO_EMPLOYEE",
  "DOCUMENT_RECEIVED_BY_EMPLOYEE": "DOCUMENT_RECEIVED_BY_EMPLOYEE",
  "DOCUMENT_EMAILED_TO_NOMINEE": "DOCUMENT_EMAILED_TO_NOMINEE",
  "DOCUMENT_RECEIVED_BY_NOMINEE": "DOCUMENT_RECEIVED_BY_NOMINEE",
  "DOCUMENT_DOWNLOADED": "DOCUMENT_DOWNLOADED",
  "DOCUMENT_UPLOADED": "DOCUMENT_UPLOADED",
  "DOCUMENT_SIGNED": "DOCUMENT_SIGNED",
  "EMAIL_RECEIPT_CONFIRMED": "EMAIL_RECEIPT_CONFIRMED",
  "EMAIL_SENT": "EMAIL_SENT"
};

const { Employee, Department, JobTitle, JobGrade, JobLevel, Location, Case, PastCase, Organisation, User, Notification, Note, DisciplinaryMatrix, TemplateDocumentVersion, AuditLog, PhoneNumber, PhysicalAddress, EmailAddress, Incident, Investigator, BucketFile, Transgression, SanctionType, Discussion } = initSchema(schema);

export {
  Employee,
  Department,
  JobTitle,
  JobGrade,
  JobLevel,
  Location,
  Case,
  PastCase,
  Organisation,
  User,
  Notification,
  Note,
  DisciplinaryMatrix,
  TemplateDocumentVersion,
  AuditLog,
  RaceType,
  PhoneNumberType,
  PhysicalAddressType,
  Province,
  EmailAddressType,
  PlaceOfWorkType,
  WorkHoursType,
  StatusType,
  DisabilityType,
  GenderType,
  CaseType,
  ReportingPeriod,
  LogoPosition,
  OrganisationType,
  TransgressionCategory,
  MatrixSanctionNames,
  Month,
  FontPreference,
  UserRole,
  NoteType,
  DiscussionSanctionNames,
  HearingSanctionNames,
  CaseRole,
  Gender,
  TemplateType,
  AuditLogEventType,
  PhoneNumber,
  PhysicalAddress,
  EmailAddress,
  Incident,
  Investigator,
  BucketFile,
  Transgression,
  SanctionType,
  Discussion,
  ApprovedChairPersonType,
  ApprovedHcApproverType
};