import React, { ReactElement } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { filterFormData, getFormDataFromCaseData } from '../../../../../WorkflowContainer/workflow-utils';
import { DocumentVersionControlTable } from '../../../../../../components/documentVersionControlTable/DocumentVersionControlTable';
import { WorkFlowFooter } from '../../../../../../components/workflowFooter/WorkFlowFooter';
import { documentConfigs } from '../../../../../../configs/document-configs/document-configs';
import { CaseType, TemplateType } from '../../../../../../API';
import { ExecuteOutcomeProps } from '../InitialHearingExecuteOutcome';
import { InitialHearingExecuteOutcomeWrittenWarningForm } from './InitialHearingExecuteOutcomeWrittenWarningForm';

export const InitialHearingExecuteOutcomeWrittenWarning: React.FC<ExecuteOutcomeProps> = props => {
  const { caseData } = props.data;
  if (!props.data.caseType) {
    throw new Error('Missing caseType');
  }
  const caseType = props.data.caseType;

  const { fields, validationSchema } = props.formConfig;
  const initialValues = getFormDataFromCaseData(fields, caseData);

  // TODO are these the correct template types for the PP sanctions?
  const templateTypesBySanctionName: Record<string, Record<string, TemplateType>> = {
    MISCONDUCT: {
      WRITTEN_WARNING: TemplateType.WRITTEN_WARNING_HEARING,
      FINAL_WRITTEN_WARNING: TemplateType.FINAL_WRITTEN_WARNING_HEARING,
    },
    POOR_PERFORMANCE: {
      WRITTEN_WARNING_WITH_PIP: TemplateType.WRITTEN_WARNING_HEARING,
      FINAL_WRITTEN_WARNING_WITH_PIP: TemplateType.EXTENSION_OF_PIP_FOLLOWING_HEARING,
    },
  };

  const templateType = templateTypesBySanctionName[caseType][props.sanction];
  console.log('templateType', templateType);
  if (!templateType) {
    throw new Error('No matching template type');
  }

  const getDocumentTitle = (templateType: TemplateType): string | null => {
    const title = documentConfigs[templateType]?.name;
    return title || 'Document';
  };

  const text = getDocumentTitle(templateType);
  if (!text) throw new Error('no text!');

  const prepareValuesForSubmission = (values: FormikValues): { [key: string]: any } => {
    const formValues = { ...values };
    if (props.data.caseType === CaseType.MISCONDUCT) {
      formValues.incidents = JSON.stringify(formValues.incidents);
    } else if (props.data.caseType === CaseType.POOR_PERFORMANCE) {
      formValues.performanceShortfalls = JSON.stringify(formValues.performanceShortfalls);
    }
    return filterFormData(formValues, fields);
  };

  const submitValues = (values: FormikValues): void => {
    props.data.flowableFunctions.onNext(prepareValuesForSubmission(values));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">
        Conclude {props.data.caseData.isAppeal && 'Appeal'} Hearing
      </h4>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={submitValues}
      >
        {({ values, handleSubmit, errors }: FormikProps<FormikValues>): ReactElement => (
          <>
            <InitialHearingExecuteOutcomeWrittenWarningForm templateType={templateType} caseType={caseType} />
            <button
              className="btn prepare-letter-button mb-3"
              onClick={(): void => {
                props.data.flowableFunctions.onOpenNewDocument(values, fields, templateType);
              }}
            >
              PREPARE {text!.toUpperCase()} LETTER
            </button>
            {props.data.processInstanceId && templateType ? (
              <DocumentVersionControlTable
                data={props.data}
                processInstanceId={props.data.processInstanceId}
                templateType={templateType}
                completeTask={props.data.flowableFunctions.completeTask}
                additionalAttachmentFormKeys={props.additionalBucketKeys}
              />
            ) : null}
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
