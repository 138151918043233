import React from 'react';
import { WorkflowComponentProps } from '../../../../WorkflowContainer/workflow-utils';
import { DisciplinaryHearingGuidancePage } from './DisciplinaryHearingGuidancePage';
import { IncapacityHearingGuidancePage } from './IncapacityHearingGuidancePage';

export const InitialHearingGuidance: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  return props.data.caseType === 'POOR_PERFORMANCE' ? (
    <IncapacityHearingGuidancePage data={props.data} />
  ) : (
    <DisciplinaryHearingGuidancePage data={props.data} />
  );
};
