import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import List from '../../../PDF/List.component';
import ListItem from '../../../PDF/ListItem.component';
import Paragraph from '../../../PDF/Paragraph.component';
import LetterHead from '../../../PDF/Letterhead.component';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const AppealOutcomeLetterPDF: React.FC<DocumentProps> = (props): JSX.Element => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Confidential</Text>
        <Table>
          <TableHeader title="attention" />
          <TableRow>
            <TableCell>Name of Employee: </TableCell>
            <TableCell>{props.content.employeeFullName.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Employee Number: </TableCell>
            <TableCell>{props.content.employeeNumber.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Department/Business Unit</TableCell>
            <TableCell>{props.content.employeeDepartment.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{props.content.employeeAddress.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>{props.content.date.value}</TableCell>
          </TableRow>
        </Table>
        <Paragraph> Dear {props.content.employeeFirstName.value}, </Paragraph>
        <Text style={{ ...styles.subject }}>OUTCOME OF APPEAL - MISCONDUCT </Text>
        <Paragraph />
        <Paragraph>
          An Appeal Hearing held {props.content.appeal_hearing_hearingDate.value} refers. You appealed against the{' '}
          {props.content.appealReason.value}. The appeal chairperson came to the following conclusions:
        </Paragraph>

        <Text style={{ ...styles.textBold }}>Findings on guilt: </Text>
        <List>
          {Array.isArray(props.content.appealFindingOutcomes.value) &&
            props.content.appealFindingOutcomes.value.map((value: any, index: number) => (
              <ListItem key={index}>{value}</ListItem>
            ))}
        </List>
        <Text style={{ ...styles.textBold }}>Findings on sanction: </Text>
        <List>
          {Array.isArray(props.content.appealSanctionOutcomes.value) &&
            props.content.appealSanctionOutcomes.value.map((value: any, index: number) => (
              <ListItem key={index}>{value}</ListItem>
            ))}
        </List>
        <Paragraph>{props.content.other.value}</Paragraph>
        <Paragraph>See attached Appeal Ruling.</Paragraph>
        <Paragraph>In conclusion, the following outcome is reached on appeal:</Paragraph>
        <List>
          <ListItem>{props.content.appealOutcomeText.value}</ListItem>
        </List>
        <Paragraph>Please liaise with {props.content.authorName.value} in case of queries.</Paragraph>
        <Paragraph>
          This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a copy
          to writer hereof in acknowledgement of receipt hereof, alternatively, acknowledge by way of return e-mail.
        </Paragraph>
        <Paragraph>Yours sincerely,</Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
    </Document>
  );
};
