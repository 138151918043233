import React, { ReactElement, useContext, useState, useEffect } from 'react';
import { Col, Form, FormGroup, Label, Row, Table, Modal, ModalBody, Button } from 'reactstrap';
import FormField, { DATE_FORMAT } from '../../../forms/fields/FormField.component';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select';
import { listActiveEmployeesByOrganisationId } from '../../../utils/graphql-utils';
import { UserContext, UserContextProps } from '../../../App';
import { AddIcon, CloseIcon, RemoveIcon } from '../../../components/icon/Icon.component';
import UploaderContainer from '../../../components/Uploader/UploaderContainer';
import TableFormFieldWithControls from '../../../forms/fields/TableFormFieldWithControls';
import TableDateTimeFieldWithControls from '../../../forms/fields/TableDateTimeFieldWithControls';
import * as Yup from 'yup';
import moment from 'moment';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import { CounsellingSession } from './RecordCounselling';
import PerformanceCounsellingHistoryAccordion, {
  SessionHistory,
} from '../../../components/PeformanceCounsellingHistory/PerformanceCounsellingHistoryAccordion';
import {
  selectStyles,
  SelectType,
  ValueContainer,
} from '../../../components/reactSelect/ReactSelectComponents.component';
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldAttributes,
  Formik,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { Employee } from '../../../models';
import { CasePerformanceShortfall, CounsellingProgressUpdate } from '../../WorkflowContainer/workflow-utils';
import { notEmpty } from '../../../utils/typescript-utils';
import ApiDataContext from '../../../contexts';

const performanceShortfallTableColumns = [
  'Performance Shortfall',
  'Date/Period',
  'Progress Update',
  'Upload Documents',
];

const NewShortfallValidationSchema = Yup.object().shape({
  shortfallDescription: Yup.string().required('Required'),
  date: Yup.string()
    .required('Required')
    .test('date', 'Required', val => moment(val, DATE_FORMAT).isValid())
    .required('Required'),
  documents: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string(),
        key: Yup.string(),
        url: Yup.string(),
      }),
    )
    .nullable(),
});

interface RecordCounsellingFormProps {
  employeeEmail: string;
  employeeFullName: string;
  processInstanceId: string;
}

interface RecordCounsellingFormState {
  newPerformanceShortfallModalIsOpen: boolean;
  counsellingHistoryModalIsOpen: boolean;
  counsellingSessionHistory: SessionHistory[];
}

const initialStateValues: RecordCounsellingFormState = {
  newPerformanceShortfallModalIsOpen: false,
  counsellingHistoryModalIsOpen: false,
  counsellingSessionHistory: [],
};

const RecordCounselingForm: React.FC<RecordCounsellingFormProps> = (props: RecordCounsellingFormProps) => {
  const { processInstanceId } = props;
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();

  const [state, setState] = useState<RecordCounsellingFormState>(initialStateValues);
  const employeeListApiData = useContext(ApiDataContext);

  const handleChange = (value: { value: string; label: string; emails: string[] }, fieldName: string): void => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data
      // .filter(employee => employee.id !== caseData.employeeId)
      .map((employee: Employee) => {
        const emailAddresses = employee.emails.filter(notEmpty).map(item => item.address);
        return {
          label: employee.firstName + ' ' + employee.lastName,
          value: employee.id,
          attendeeId: employee.id,
          attendeeEmailAddress: emailAddresses[0] ? emailAddresses[0] : 'noEmail@labourteq.co.za',
          attendeeName: employee.firstName + ' ' + employee.lastName,
        };
      });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  // const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
  //   if (currentUser?.organisationId) {
  //     return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
  //       const preparedData = prepareData(data);
  //       return !inputValue ? preparedData : filterItems(preparedData, inputValue);
  //     });
  //   }
  // };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId && typeof employeeListApiData?.employees === 'undefined') {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        employeeListApiData.employees = !inputValue ? preparedData : filterItems(preparedData, inputValue);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    } else {
      return !inputValue ? employeeListApiData.employees : filterItems(employeeListApiData.employees, inputValue);
    }
  };

  useEffect(() => {
    if (values.performanceCounsellingSessions && values.performanceCounsellingSessions.length) {
      const sessions = values.performanceCounsellingSessions.map((session: CounsellingSession) => {
        return {
          sessionId: session.sessionId,
          date: session.counsellingDate,
          time: session.counsellingTime,
          location: session.counsellingLocation,
          attendees: session.counsellingAttendees,
          documents: session.counsellingDocuments,
          summary: session.counsellingSummary,
          sessionCompleted: session.sessionCompleted,
        };
      });
      setState(oldState => ({ ...oldState, counsellingSessionHistory: sessions }));
    }
  }, []);

  useEffect(() => {
    const pendingSessions = state.counsellingSessionHistory.filter(session => session.sessionCompleted).length;
    if (pendingSessions) {
      values.performanceShortfalls.forEach((item: CasePerformanceShortfall) => {
        if (!item.counsellingProgressUpdates.filter(i => i.sessionId === values.counsellingSession.sessionId).length) {
          item.counsellingProgressUpdates.push({
            sessionId: values.counsellingSession.sessionId,
            date: '',
            update: '',
          });
        }
      });
    }
  }, [state.counsellingSessionHistory]);

  useEffect(() => {
    if (values.counsellingSession && values.performanceShortfalls.length) {
      values.performanceShortfalls.forEach((shortfall: CasePerformanceShortfall, index: number) => {
        if (
          shortfall.counsellingProgressUpdates &&
          shortfall.counsellingProgressUpdates.length &&
          state.counsellingSessionHistory.length
        ) {
          if (shortfall.counsellingProgressUpdates.length) {
            shortfall.counsellingProgressUpdates.forEach((update, i) => {
              if (!update.date || i === shortfall.counsellingProgressUpdates.length - 1) {
                setFieldValue(
                  `performanceShortfalls.${index}.counsellingProgressUpdates.${i}.date`,
                  values.counsellingSession.counsellingDate,
                );
              }
            });
          }
        }
      });
    }
  }, [
    values.counsellingSession.counsellingDate,
    JSON.stringify(values.performanceShortfalls),
    state.counsellingSessionHistory,
  ]);

  return (
    <>
      <Form>
        <>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="counsellingDate" className="text-default text-capitalize">
                  Date of Discussion
                </Label>
                <FormField type={'date'} placeholder="Select Date" name={`counsellingSession.counsellingDate`} />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={`counsellingSession.counsellingDate`} />
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="counsellingTime" className="text-default text-capitalize">
                  Time of discussion
                </Label>
                <FormField type={'time'} placeholder="Select Time" name={`counsellingSession.counsellingTime`} />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={`counsellingSession.counsellingTime`} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="counselingLocation" className="text-default text-capitalize">
                  Location
                </Label>
                <FormField type={'text'} placeholder="Location" name={`counsellingSession.counsellingLocation`} />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={`counsellingSession.counsellingLocation`} />
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="Attendees" className="text-default">
                  Attendees*
                </Label>
                <Field name={`counsellingSession.counsellingAttendees`}>
                  {({ field }: FieldAttributes<FormikValues>) => (
                    <AsyncSelect
                      {...field}
                      isMulti
                      placeholder="Select Attendees"
                      cacheOptions
                      defaultOptions
                      isClearable={false}
                      loadOptions={loadOptions}
                      closeMenuOnSelect={false}
                      styles={selectStyles}
                      onChange={(value: ValueType<any>) =>
                        handleChange(value, `counsellingSession.counsellingAttendees`)
                      }
                      components={{ ValueContainer }}
                    />
                  )}
                </Field>
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={`counsellingSession.counsellingAttendees`} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label for="exampleFile" className="text-default text-capitalize">
                  Upload record of counseling discussion
                </Label>
                <UploaderContainer
                  fieldName={`counsellingSession.counsellingDocuments`}
                  isMulti={true}
                  path={`cases/${processInstanceId}`}
                />
              </FormGroup>
            </Col>
            <Col md={1} className="text-default py-5">
              <FormGroup>OR</FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="counselingSummary" className="text-default text-capitalize">
                  Type the discussion summary*
                </Label>
                <FormField
                  type={'textarea'}
                  placeholder="Please add summary of the discussion or upload document"
                  name={`counsellingSession.counsellingSummary`}
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={`counsellingSession.counsellingSummary`} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <ButtonWithIcons
                title={'View Performance Counselling History'}
                buttonType={'btn-bd-purple'}
                handleClick={(): void => setState(oldState => ({ ...oldState, counsellingHistoryModalIsOpen: true }))}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <div>
                <h6 className="text-default text-capitalize mt-4 font-weight-bold">Performance shortfalls overview</h6>
                {/*<hr style={{ border: '0.06em solid #adb5bd' }} />*/}
              </div>
            </Col>
          </Row>

          <FieldArray name="performanceShortfalls">
            {(arrayHelpers: FieldArrayRenderProps) => (
              <>
                <Table bordered className="table-responsive-sm">
                  <thead>
                    <tr>
                      {performanceShortfallTableColumns.map((col: string, index: number) => (
                        <th key={index} className="text-default">
                          {col}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {values.performanceShortfalls &&
                      values.performanceShortfalls.length &&
                      values.performanceShortfalls.map((item: { [key: string]: any }, index: number) => (
                        <tr key={index}>
                          <td className="position-relative" style={{ maxWidth: '150px', width: '30%' }}>
                            {item.shortfallDescription}
                          </td>
                          <td className="position-relative" style={{ maxWidth: '140px', width: '10%' }}>
                            <FieldArray name={`performanceShortfalls.${index}.counsellingProgressUpdates`}>
                              {(arrayHelpers: FieldArrayRenderProps) => (
                                <>
                                  {!values.performanceShortfalls[index].counsellingProgressUpdates &&
                                    arrayHelpers.push({
                                      date: '',
                                      update: '',
                                      sessionId: values.counsellingSession.sessionId,
                                    })}
                                  {values.performanceShortfalls[index].counsellingProgressUpdates &&
                                    values.performanceShortfalls[index].counsellingProgressUpdates.length &&
                                    values.performanceShortfalls[index].counsellingProgressUpdates.map(
                                      (item: any, idx: number) => (
                                        <span key={idx}>
                                          <TableDateTimeFieldWithControls
                                            type={'date'}
                                            placeholder={'Date'}
                                            name={`performanceShortfalls.${index}.counsellingProgressUpdates.${idx}.date`}
                                            index={idx}
                                            disabled={
                                              idx !==
                                              values.performanceShortfalls[index].counsellingProgressUpdates.length - 1
                                            }
                                            className="mt-4"
                                          />
                                        </span>
                                      ),
                                    )}
                                </>
                              )}
                            </FieldArray>
                          </td>
                          <td className="position-relative" style={{ maxWidth: '140px', width: '40%' }}>
                            <FieldArray name={`performanceShortfalls.${index}.counsellingProgressUpdates`}>
                              {(helpers: FieldArrayRenderProps) => (
                                <>
                                  {values.performanceShortfalls[index].counsellingProgressUpdates &&
                                    values.performanceShortfalls[index].counsellingProgressUpdates.length &&
                                    values.performanceShortfalls[index].counsellingProgressUpdates.map(
                                      (item: CounsellingProgressUpdate, idx: number) => (
                                        <span key={idx}>
                                          <TableFormFieldWithControls
                                            name={`performanceShortfalls.${index}.counsellingProgressUpdates.${idx}.update`}
                                            type={'textarea'}
                                            placeholder={'Progress Update'}
                                            className={''}
                                            defaultValues={{
                                              date: '',
                                              update: ' ',
                                            }}
                                            index={idx}
                                            disabled={
                                              idx !==
                                              values.performanceShortfalls[index].counsellingProgressUpdates.length - 1
                                            }
                                            arrayHelpers={helpers}
                                          />
                                        </span>
                                      ),
                                    )}
                                </>
                              )}
                            </FieldArray>
                          </td>
                          <td className="position-relative" style={{ maxWidth: '120px', width: '20%' }}>
                            <UploaderContainer
                              fieldName={'performanceShortfalls'}
                              path={`cases/${processInstanceId}`}
                              hasIcon={true}
                              onTable={true}
                              index={index}
                            />
                          </td>
                          <td className="border-white">
                            {index !== 0 && (
                              <span
                                className="d-flex justify-content-center align-items-center"
                                onClick={(): void => arrayHelpers.remove(index)}
                              >
                                <RemoveIcon />
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </>
            )}
          </FieldArray>
        </>
        <Row>
          <Col
            md={3}
            className="d-flex"
            style={{ cursor: 'pointer' }}
            onClick={(): void => setState(oldState => ({ ...oldState, newPerformanceShortfallModalIsOpen: true }))}
          >
            <span className="px-1">
              <AddIcon height={'20'} width={'20'} />
            </span>
            <span className="text-default px-1">Add a new Performance Shortfall</span>
          </Col>
        </Row>
      </Form>
      <Modal isOpen={state.newPerformanceShortfallModalIsOpen} size={'lg'} centered>
        <ModalBody>
          <div
            onClick={(): void => setState(oldState => ({ ...oldState, newPerformanceShortfallModalIsOpen: false }))}
            className="d-flex justify-content-between"
          >
            <div className="text-default h4">New Performance Shortfall</div>
            <div>
              <CloseIcon />
            </div>
          </div>
          <Formik
            initialValues={{
              shortfallDescription: '',
              date: '',
              documents: null,
              counsellingProgressUpdates: [
                {
                  date: '',
                  update: '',
                },
              ],
            }}
            validationSchema={NewShortfallValidationSchema}
            onSubmit={(currentValues: FormikValues): void => {
              setFieldValue('performanceShortfalls', values.performanceShortfalls.concat(currentValues));
              setState(oldState => ({ ...oldState, newPerformanceShortfallModalIsOpen: false }));
            }}
          >
            {({ values, errors, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
              <>
                <Form>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="shortfallDescription" className="text-default text-capitalize">
                          Shortfall Description
                        </Label>
                        <FormField type={'text'} placeholder="Enter Description" name="shortfallDescription" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'shortfallDescription'} />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="dateOrPeriod" className="text-default text-capitalize">
                          Date / Period
                        </Label>
                        <FormField type={'date'} placeholder="Select Date" name="date" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'date'} />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="text-default text-capitalize">Upload Documents</Label>
                        <UploaderContainer fieldName={'documents'} path={`cases/${processInstanceId}`} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between">
                    <Button
                      className="btn btn-bd-purple rounded-0 text-uppercase font-weight-normal"
                      onClick={(): void =>
                        setState(oldState => ({ ...oldState, newPerformanceShortfallModalIsOpen: false }))
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn btn-bd-purple rounded-0 text-uppercase font-weight-normal"
                      onClick={(): void => handleSubmit()}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </ModalBody>
      </Modal>

      <Modal isOpen={state.counsellingHistoryModalIsOpen} size={'lg'} centered>
        <ModalBody>
          <div
            onClick={(): void =>
              setState(oldState => ({
                ...oldState,
                counsellingHistoryModalIsOpen: !state.counsellingHistoryModalIsOpen,
              }))
            }
            className="d-flex justify-content-between"
          >
            <div className="text-default h4">Performance Counselling Sessions</div>
            <div>
              <CloseIcon height={'15'} width={'15'} fillColour="#8461C9" />
            </div>
          </div>
          {state.counsellingSessionHistory &&
            state.counsellingSessionHistory.map((session: SessionHistory, index: number) => (
              <PerformanceCounsellingHistoryAccordion key={index} index={index} data={session} />
            ))}
        </ModalBody>
      </Modal>
    </>
  );
};

export default RecordCounselingForm;
