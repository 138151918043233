import React from 'react';
import { InitialHearingExecuteOutcomeWrittenWarning } from './WrittenWarning/InitialHearingExecuteOutcomeWrittenWarning';
import { InitialHearingExecuteOutcomeSummaryDismissal } from './SummaryDismissal/InitialHearingExecuteOutcomeSummaryDismissal';
import { InitialHearingExecuteOutcomeDemotion } from './Demotion/InitialHearingExecuteOutcomeDemotion';
import { InitialHearingExecuteOutcomeDismissalWithNotice } from './DismissalWithNotice/InitialHearingExecuteOutcomeDismissalWithNotice';
import { InitialHearingExecuteOutcomeSuspensionWithoutPay } from './SuspensionWithoutPay/InitialHearingExecuteOutcomeSuspensionWithoutPay';
import { ExecuteOutcomeProps } from './InitialHearingExecuteOutcome';
import {
  initial_hearing_misconduct_executeOutcomeWrittenWarning,
  initial_hearing_pp_executeOutcomeWrittenWarning,
} from './WrittenWarning/config';
import {
  initial_hearing_misconduct_executeOutcomeSummaryDismissal,
  initial_hearing_pp_executeOutcomeSummaryDismissal,
} from './SummaryDismissal/config';
import {
  initial_hearing_misconduct_executeOutcomeDemotion,
  initial_hearing_pp_executeOutcomeDemotion,
} from './Demotion/config';
import { initial_hearing_misconduct_executeOutcomeDismissalWithNotice } from './DismissalWithNotice/config';
import { initial_hearing_misconduct_executeOutcomeSuspensionWithoutPay } from './SuspensionWithoutPay/config';
import { FormConfigV2 } from '../../../../../configs/form-configs/form-config-types';
import { CaseType } from '../../../../../API';

export const componentsAndFormsBySanction: Record<
  string,
  Record<string, { component: React.FC<ExecuteOutcomeProps>; formConfig: FormConfigV2 }>
> = {
  [CaseType.MISCONDUCT]: {
    WRITTEN_WARNING: {
      component: InitialHearingExecuteOutcomeWrittenWarning,
      formConfig: initial_hearing_misconduct_executeOutcomeWrittenWarning,
    },
    FINAL_WRITTEN_WARNING: {
      component: InitialHearingExecuteOutcomeWrittenWarning,
      formConfig: initial_hearing_misconduct_executeOutcomeWrittenWarning,
    },
    SUMMARY_DISMISSAL: {
      component: InitialHearingExecuteOutcomeSummaryDismissal,
      formConfig: initial_hearing_misconduct_executeOutcomeSummaryDismissal,
    },
    DEMOTION: {
      component: InitialHearingExecuteOutcomeDemotion,
      formConfig: initial_hearing_misconduct_executeOutcomeDemotion,
    },
    DISMISSAL_WITH_NOTICE: {
      component: InitialHearingExecuteOutcomeDismissalWithNotice,
      formConfig: initial_hearing_misconduct_executeOutcomeDismissalWithNotice,
    },
    SUSPENSION_WITHOUT_PAY: {
      component: InitialHearingExecuteOutcomeSuspensionWithoutPay,
      formConfig: initial_hearing_misconduct_executeOutcomeSuspensionWithoutPay,
    },
  },
  [CaseType.POOR_PERFORMANCE]: {
    FINAL_WRITTEN_WARNING_WITH_PIP: {
      component: InitialHearingExecuteOutcomeWrittenWarning,
      formConfig: initial_hearing_pp_executeOutcomeWrittenWarning,
    },
    SUMMARY_DISMISSAL: {
      component: InitialHearingExecuteOutcomeSummaryDismissal,
      formConfig: initial_hearing_pp_executeOutcomeSummaryDismissal,
    },
    DEMOTION: {
      component: InitialHearingExecuteOutcomeDemotion,
      formConfig: initial_hearing_pp_executeOutcomeDemotion,
    },
    DISMISSAL_WITH_NOTICE_PAY: {
      component: InitialHearingExecuteOutcomeDismissalWithNotice,
      formConfig: initial_hearing_misconduct_executeOutcomeDismissalWithNotice,
    },
  },
};
