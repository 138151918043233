import React, { ReactElement, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { WorkFlowFooter } from '../../../../components/workflowFooter/WorkFlowFooter';
import PipAssessmentForm from './PipAssessmentForm';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../WorkflowContainer/workflow-utils';
import { PipAssessmentSchema } from '../../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import { TemplateType } from '../../../../API';
import { DocumentVersionControlTable } from '../../../../components/documentVersionControlTable/DocumentVersionControlTable';

const PipAssessment: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const fields = {
    pipHavePerformanceConcernsBeenAddressed: 'boolean',
    performanceShortfalls: 'array',
    furtherActionAfterPip: 'string',
    reasonForFurtherActionAfterPip: 'string',
  };

  const pipText =
    props.data.taskData.currentTask.taskDefinitionKey === 'draft-new-success-letter-2'
      ? 'Extended PIP Assessment'
      : 'PIP Assessment';

  const [data] = useState(() => getFormDataFromCaseData(fields, caseData, caseData.isAppeal));

  const submitValues = async (values: FormikValues): Promise<void> => {
    const formValues = { ...values };
    formValues.performanceShortfalls = JSON.stringify(formValues.performanceShortfalls);
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">{pipText}</h4>
      <Formik initialValues={data} onSubmit={submitValues} validationSchema={PipAssessmentSchema} enableReinitialize>
        {({ values, errors, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
          <>
            <PipAssessmentForm data={props.data} />
            <br />
            <br />
            {values.pipHavePerformanceConcernsBeenAddressed && (
              <>
                <button
                  className="btn prepare-letter-button mb-3"
                  onClick={(): void => {
                    props.data.flowableFunctions.onOpenNewDocument(
                      values,
                      fields,
                      TemplateType.SUCCESSFUL_CONCLUSION_PERFORMANCE_PROCESS_LETTER,
                    );
                  }}
                >
                  Compile PIP Successfully Concluded Letter
                </button>
                {props.data.processInstanceId ? (
                  <DocumentVersionControlTable
                    data={props.data}
                    processInstanceId={props.data.processInstanceId}
                    templateType={TemplateType.SUCCESSFUL_CONCLUSION_PERFORMANCE_PROCESS_LETTER}
                    completeTask={props.data.flowableFunctions.completeTask}
                  />
                ) : null}
              </>
            )}
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => flowableFunctions.onSaveAndClose(values)}
              finalPage={values.pipHavePerformanceConcernsBeenAddressed || values.furtherActionAfterPip === 'OTHER'}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default PipAssessment;
