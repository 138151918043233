import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import List from '../../../PDF/List.component';
import ListItem from '../../../PDF/ListItem.component';
import Paragraph from '../../../PDF/Paragraph.component';
import LetterHead from '../../../PDF/Letterhead.component';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const PoorPerformanceDismissalWithNoticePayLetterPDF: React.FC<DocumentProps> = props => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Confidential</Text>
        <Table>
          <TableHeader title="attention" />
          <TableRow>
            <TableCell>Name of Employee: </TableCell>
            <TableCell>{props.content.employeeFullName.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Employee Number: </TableCell>
            <TableCell>{props.content.employeeNumber.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Department/Business Unit</TableCell>
            <TableCell>{props.content.employeeDepartment.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{props.content.employeeAddress.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>{props.content.date.value}</TableCell>
          </TableRow>
        </Table>
        <Paragraph> Dear {props.content.employeeFirstName.value}, </Paragraph>
        <Text style={{ ...styles.subject }}>
          CONFIRMATION OF DISMISSAL WITH NOTICE PAY DUE TO INCAPACITY POOR PERFORMANCE
        </Text>
        <Paragraph>
          <Text>
            An Incapacity Poor Performance hearing held on {props.content.initial_hearing_hearingDate.value} refers.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            You were found guilty, alternatively pleaded guilty, on the following incidents of poor performance and/or
            failure to meet deliverables:
          </Text>
        </Paragraph>
        <Table>
          {Array.isArray(props.content.performanceShortfalls_guilty.value) &&
            props.content.performanceShortfalls_guilty.value.length &&
            props.content.performanceShortfalls_guilty.value.map((value: string, index: number) => (
              <TableRow key={index}>
                <TableCell width={'100%'}>{value} </TableCell>
              </TableRow>
            ))}
        </Table>
        <Paragraph>
          <Text>
            Dismissal with notice pay was found to be the applicable sanction in the circumstances by the chairperson of
            the hearing having considered aggravating and mitigating factors, as applicable. Please find attached a copy
            of the chairperson’s ruling.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>This serves as confirmation that your services are terminated on the following terms:</Text>
        </Paragraph>
        <List>
          <ListItem>
            Your last day of employment with the company will be {props.content.dismissalDate.value} (“termination
            date”);
          </ListItem>
          <ListItem>
            A notice period is paid in lieu of you working. Final remuneration is thus payable up to{' '}
            {props.content.finalRemunerationDate.value};
          </ListItem>
          <ListItem>You will not be required to render any further services with immediate effect.</ListItem>
          <ListItem>Any accrued leave as at the termination date will be paid;</ListItem>
          <ListItem>
            Membership of all employer schemes/funds, as applicable, will also terminate on this date as will the
            employer’s obligations in this regard;
          </ListItem>
          <ListItem>A Certificate of Service will be issued to you;</ListItem>
          <ListItem>Appropriate UIF documentation will be completed and issued, as required;</ListItem>
          <ListItem>
            You are to return all company property on receipt hereof, should you still have any such in your possession;
          </ListItem>
          <ListItem>Confidentiality undertakings continue to apply.</ListItem>
        </List>
        <Paragraph>
          <Text>{props.content.other.value}</Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Information on appeal procedures, if any are stipulated in the employer’s HR policy and procedures, will be provided on receipt hereof.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            You have the right to refer the matter to the Commission for Conciliation, Mediation and Arbitration (CCMA)
            or the relevant Bargaining Council, as appropriate should you so wish{' '}
            <Text style={styles.bold}>within 30 days of termination date</Text>{' '}
            in which case all documents must be served at the following address:
          </Text>

          {/*<Text>{props.content.authorEmailAddress.value}</Text>*/}
        </Paragraph>
        <Text style={styles.bold}>Contact Name: {props.content.contactName.value}</Text>
        <Text style={styles.bold}>Physical Address: {props.content.contactAddress.value}</Text>
        <Text style={styles.bold}>Email Address: {props.content.contactEmail.value}</Text>
        <Paragraph>
          <Text>Please liaise with {props.content.authorName.value} in case of queries.</Text>
        </Paragraph>
        <Paragraph>
          <Text>
            This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a
            copy to writer hereof in acknowledgement of receipt hereof, alternatively, acknowledge by way of return
            e-mail.
          </Text>
        </Paragraph>
        <Paragraph>Yours sincerely,</Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
    </Document>
  );
};
