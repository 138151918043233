import React, { useState } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import ButtonWithIcons, { default as CustomButton } from '../buttons/ButtonWIthIcons.component';
import { ClipIcon, CloseIcon } from '../icon/Icon.component';
import {
  EmailParamsV2,
  EmailPreviewResponse,
  EmailRecipient,
  EmailType,
  getEmailPreview,
} from '../../utils/email-utils';
import { useEmailSentHandler, useErrorHandler } from '../../utils/notification-utils';
import { sendGenericEmailFromFlowable } from '../../utils/email-utils';
import { FlowableVariable } from '../../utils/flowable/flowable-types';
import Loader from '../loader/Loader';
import { AxiosResponse } from 'axios';

interface EmailPreviewProps {
  buttonText: string;
  sendFunction?: () => Promise<unknown>;
  disabled: boolean;
  formValues: Record<string, unknown>;
  emailType: EmailType | null;
  masterProcessInstanceId: string;
  processInstanceId: string;
  currentUserId: string | undefined;
  getRecipients?: () => EmailRecipient[];
  getAttachmentBucketKeys?: () => string[];
  getAttachmentBucketKeysAsync?: () => Promise<string[]>;
  callback?: () => void;
  getFlowableVariables: () => FlowableVariable[];
}

export const EmailPreviewModalv3: React.FC<EmailPreviewProps> = (props: EmailPreviewProps) => {
  const [emailPreviewResponse, setemailPreviewResponse] = useState<EmailPreviewResponse | null>(null);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const showEmailSentAlert = useEmailSentHandler();
  const handleError = useErrorHandler();

  const getParams = async (): Promise<EmailParamsV2> => {
    if (!props.emailType) {
      throw new Error('no emailType');
    }
    if (!props.currentUserId) {
      throw new Error('no user');
    } else {
      let attachmentBucketKeys: string[] = [];
      if (props.getAttachmentBucketKeys) {
        attachmentBucketKeys = props.getAttachmentBucketKeys();
      } else if (props.getAttachmentBucketKeysAsync) {
        attachmentBucketKeys = await props.getAttachmentBucketKeysAsync();
      }
      return {
        currentUserId: props.currentUserId,
        emailType: props.emailType,
        formValues: props.formValues,
        processInstanceId: props.processInstanceId,
        masterProcessInstanceId: props.masterProcessInstanceId,
        recipients: props.getRecipients ? props.getRecipients() : [],
        attachmentBucketKeys: attachmentBucketKeys,
      };
    }
  };

  const onOpenModal = async (): Promise<void> => {
    if (!props.currentUserId) {
      throw new Error('no user');
    } else {
      setIsLoading(true);
      setIsModalOpen(true);
      const params = await getParams();
      getEmailPreview(params)
        .then(emailPreviewResponse => {
          console.log('test', emailPreviewResponse);
          setemailPreviewResponse(emailPreviewResponse.data);
          setIsLoading(false);
        })
        .catch(error => {
          handleError(error);
          setIsLoading(false);
        });
    }
  };

  const toggleModal = (): void => {
    if (!isModalOpen) {
      onOpenModal();
    } else {
      setemailPreviewResponse(null);
      setIsModalOpen(false);
    }
  };

  const defaultSendFunction = async (): Promise<AxiosResponse<any>> => {
    const params = await getParams();
    let flowableVariables: FlowableVariable[];
    if (props.getFlowableVariables) {
      flowableVariables = props.getFlowableVariables();
      flowableVariables.push({ name: 'emailParams', value: JSON.stringify(params) });
      flowableVariables.push({ name: 'createExternalUser', value: false });
    } else {
      flowableVariables = [
        { name: 'emailParams', value: JSON.stringify(params) },
        { name: 'createExternalUser', value: false },
      ];
    }
    if (!props.currentUserId) {
      throw new Error('missing user Id');
    }
    return sendGenericEmailFromFlowable({
      processInstanceId: props.processInstanceId,
      variables: flowableVariables,
      userId: props.currentUserId,
    });
  };

  const onSend = async (): Promise<void> => {
    setIsSending(true);
    const sendFunction = props.sendFunction ?? defaultSendFunction;
    await sendFunction()
      .then(() => {
        setIsSending(false);
        showEmailSentAlert();
        setIsModalOpen(false);
        if (props.callback) {
          props.callback();
        }
      })
      .catch(error => {
        setIsSending(false);
        handleError(error);
      });
  };

  const renderModalBody = (): JSX.Element => {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center mt-5 mb-5">
          <Loader />
        </div>
      );
    } else if (emailPreviewResponse?.html) {
      return <div dangerouslySetInnerHTML={{ __html: emailPreviewResponse.html }} className="p-0 m-0 " />;
    } else return <div />;
  };

  return (
    <>
      <ButtonWithIcons disabled={props.disabled} title={props.buttonText} handleClick={toggleModal} />
      <Modal className="p-0" size="lg" isOpen={isModalOpen} toggle={toggleModal} backdrop="static" fade={true} centered>
        {isSending ? (
          <div className="py-4 px-4">
            <Spinner type="grow" color="primary" />
            <p>Sending</p>
          </div>
        ) : (
          <div style={{ width: '100vw !important' }}>
            <div className="d-flex justify-content-between py-2 px-5">
              <CustomButton
                title={isSending ? 'Sending...' : 'Send'}
                buttonType="btn-bd-purple py-2 px-1 mx-0"
                handleClick={async (): Promise<void> => await onSend()}
                style={{ height: '50px' }}
                disabled={props.disabled || isSending}
              />
              <span className="text-dark align-self-center d-block font-weight-bold">Compose Message</span>
              <span onClick={toggleModal} className="d-block align-self-center pointer p-2 ml-1">
                <CloseIcon />
              </span>
            </div>
            {emailPreviewResponse?.attachmentFileNames.map((item: string) => {
              return (
                <div
                  className="d-flex px-5 border-top-4 border-dark "
                  style={{
                    backgroundColor: '#ECEBEB',
                  }}
                >
                  <span className="text-muted mr-2">
                    <ClipIcon />
                  </span>
                  <span className="text-muted">{item}</span>
                </div>
              );
            })}
            <div className="px-5 mw-100">
              <span
                className="d-flex d-inline-block"
                style={{
                  borderBottom: '0.1em solid rgb(206, 206, 206)',
                  paddingBottom: '1em',
                  paddingTop: '1em',
                }}
              >
                <span className="text-muted">To: </span>
                <span className="text-dark pl-2">
                  {emailPreviewResponse?.recipients.map((recipient, index) => (
                    <span key={index}>
                      {recipient.emailAddress}
                      {index !== emailPreviewResponse?.recipients.length - 1 && ', '}
                    </span>
                  ))}
                </span>
              </span>
            </div>
            <div className="px-5">
              <span
                className="d-flex d-inline-block"
                style={{
                  borderBottom: '0.1em solid rgb(206, 206, 206)',
                  paddingBottom: '1em',
                  paddingTop: '1em',
                }}
              >
                <span className="text-muted">From: </span>
                <span className="text-dark pl-2">{emailPreviewResponse?.fromEmailAddress}</span>
              </span>
            </div>
            <div className="px-5">
              <span
                className="d-flex d-inline-block"
                style={{
                  borderBottom: '0.1em solid rgb(206, 206, 206)',
                  paddingBottom: '1em',
                  paddingTop: '1em',
                }}
              >
                <span className="text-muted">Subject: </span>
                <span className="text-dark pl-2">{emailPreviewResponse?.subject}</span>
              </span>
            </div>
          </div>
        )}
        <ModalBody className="p-0 rounded-0">{renderModalBody()}</ModalBody>
      </Modal>
    </>
  );
};
