import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';

const apiName = 'middlewareREST';

export const get = <T>(path: string): Promise<AxiosResponse<T>> => {
  const init = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    // queryStringParameters: {
    //   // OPTIONAL
    //   name: 'param',
    // },
  };
  return API.get(apiName, path, init);
};

export const post = <T>(path: string, requestBody: unknown): Promise<AxiosResponse<T>> => {
  const init = {
    body: requestBody, // replace this with attributes you need
    headers: {}, // OPTIONAL
    response: true,
  };
  return API.post(apiName, path, init);
};
