import React, { useContext, useEffect, useState } from 'react';
import {
  ArrayHelpers,
  ErrorMessage,
  Field,
  FieldAttributes,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import TableFormField from '../../../forms/fields/TableFormField';
import Select, { ValueType } from 'react-select';
import { Organisation, TransgressionCategory } from '../../../API';
import { SingleValue, tableFieldSelectStyles } from '../../../components/reactSelect/ReactSelectComponents.component';
import { startCase } from 'lodash';
import { get } from '../../../utils/graphql-utils';
import { useErrorHandler } from '../../../utils/notification-utils';
import { getOrganisation } from '../../../graphql/queries';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../components/taskContainer/TaskContainer';
import { CaseTableData } from '../../../utils/case-utils';
import { MatrixSanctionNames, Transgression } from '../../../models';
import { AddIcon, RemoveIcon } from '../../../components/icon/Icon.component';
import { getOrganisationSettings } from '../../../graphql-custom/custom-queries';

interface TransgressionInputsProps {
  organisationId: string;
  cases: CaseTableData[];
  index: number;
  setWarningsCallback: (index: number, message?: string) => void;
  arrayHelpers: ArrayHelpers;
}

const defaultProcessOptions = [
  { label: 'Hearing', value: 'HEARING' },
  { label: 'Discussion', value: 'DISCUSSION' },
];

const TransgressionInputs: React.FC<TransgressionInputsProps> = (props: TransgressionInputsProps) => {
  const { index, cases, setWarningsCallback, arrayHelpers } = props;
  const handleError = useErrorHandler();
  const [disciplinaryCode, setDisciplinaryCode] = useState<Transgression[]>();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [organisationSettings, setOrganisationSettings] = useState<Partial<Organisation | null>>(null);
  const [processOptions, setProcessOptions] = useState(defaultProcessOptions);
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();

  //this works
  const mapFrequencyToPotentialSanction = (transgressionName: string): void => {
    const frequency = values.incidents[index].frequency;

    if (disciplinaryCode) {
      const matchingTransgression: Transgression | undefined = disciplinaryCode.find((item: Transgression) => {
        return item.transgression === transgressionName;
      });

      if (matchingTransgression) {
        const matchingFrequency: string | undefined = Object.keys(matchingTransgression.sanction).find(
          (item: string) => {
            return item.toLowerCase() === frequency.toLowerCase();
          },
        );

        if (matchingFrequency) {
          // @ts-ignore
          let potentialSanction: MatrixSanctionNames | undefined = matchingTransgression.sanction[matchingFrequency];
          if (potentialSanction === MatrixSanctionNames.NOT_APPLICABLE) {
            potentialSanction = MatrixSanctionNames.DISMISSAL;
          }
          if (potentialSanction) {
            setFieldValue(`incidents.${index}.potentialSanction`, potentialSanction);
          }
        }
      }
    }
  };

  const mapFrequencyToNumber = (frequency: string): number => {
    let value = 0;
    switch (frequency) {
      case 'firstOffence':
        value = 1;
        break;
      case 'secondOffence':
        value = 2;
        break;
      case 'thirdOffence':
        value = 3;
        break;
      case 'forthOffence':
        value = 4;
        break;
      case 'fifthOffence':
        value = 5;
        break;
      case 'sixthOffence':
        value = 6;
        break;
    }
    return value;
  };

  const mapNumberToFrequency = (frequency: number): string => {
    let value = '';
    switch (frequency) {
      case 1:
        value = 'firstOffence';
        break;
      case 2:
        value = 'secondOffence';
        break;
      case 3:
        value = 'thirdOffence';
        break;
      case 4:
        value = 'forthOffence';
        break;
      case 5:
        value = 'fifthOffence';
        break;
      case 6:
        value = 'sixthOffence';
        break;
    }
    return value;
  };

  const determineNextFrequency = (transgression: string): string => {
    const frequencies = cases
      .filter(caseItem => {
        return caseItem.history && caseItem.history[transgression] && !!caseItem.isCaseClosed;
      })
      .map(item => {
        // @ts-ignore
        return mapFrequencyToNumber(item.history[transgression]);
      })
      .sort((a, b) => a - b);
    if (frequencies.length && frequencies[frequencies.length - 1] === 6) return 'sixthOffence';
    return mapNumberToFrequency(frequencies[frequencies.length - 1] + 1);
  };

  const getFrequencyForTransgression = (transgression: string): void => {
    const matches = cases
      .filter(caseItem => caseItem.history && caseItem.history[transgression] && !!caseItem.isCaseClosed)
      .map(caseItem => {
        setFieldValue(`incidents.${index}.frequency`, determineNextFrequency(transgression));
        return caseItem;
      });
    //set to first offence if this is the first case for this transgression
    matches.length < 1 && setFieldValue(`incidents.${index}.frequency`, 'firstOffence');
  };

  const setWarnings = (transgression: string): void => {
    cases.forEach(caseItem => {
      const nextFrequency = determineNextFrequency(transgression);
      if (caseItem.history && caseItem.history[transgression] && nextFrequency) {
        if (values.incidents[index].frequency && values.incidents[index].frequency !== nextFrequency) {
          setWarningsCallback(
            index,
            `row ${index + 1}: This is not the employee's ${startCase(
              values.incidents[index].frequency,
            )} for this transgression. Consider selecting ${startCase(nextFrequency)}`,
          );
        } else {
          setWarningsCallback(index);
        }
      } else if (
        nextFrequency &&
        values.incidents[index].frequency &&
        values.incidents[index].frequency !== nextFrequency
      ) {
        setWarningsCallback(
          index,
          `row ${index + 1}: This is not the employee's ${startCase(
            values.incidents[index].frequency,
          )} for this transgression. Consider selecting ${startCase(nextFrequency)}`,
        );
      } else if (
        !nextFrequency &&
        values.incidents[index].frequency &&
        values.incidents[index].frequency !== 'firstOffence'
      ) {
        setWarningsCallback(
          index,
          `row ${index + 1}: This is not the employee's ${startCase(
            values.incidents[index].frequency,
          )} for this transgression. Consider selecting 'First Offence'`,
        );
      } else {
        setWarningsCallback(index);
      }
    });
  };

  const getTransgressionsFromCategory = async (category: string): Promise<void> => {
    return await get(getOrganisation, props.organisationId)
      .then(response => {
        if (response.data && (response.data as { [key: string]: any }).getOrganisation.disciplinaryCode) {
          const data: [] = (response.data as { [key: string]: any }).getOrganisation.disciplinaryCode
            .filter(
              (item: Transgression) =>
                item.active &&
                startCase(item.transgressionCategory.toLowerCase()) === startCase(category.toLowerCase()),
            )
            .map((item: { [key: string]: { [key: string]: any } }) => {
              return {
                label: item.transgression,
                value: item.transgression,
              };
            });
          setDisciplinaryCode((response.data as { [key: string]: any }).getOrganisation.disciplinaryCode);
          setCategoryOptions(data);
        }
      })
      .catch(error => handleError(error));
  };

  useEffect(() => {
    if (props.organisationId && !organisationSettings) {
      get(getOrganisationSettings, props.organisationId)
        .then(res => {
          setOrganisationSettings((res.data as any).getOrganisation);
        })
        .catch(error => console.error(error));
    }
  }, [organisationSettings, props.organisationId]);

  // multiple useEffect calls here as there would be different actions for each state variable change.
  // set appropriate sanction if frequency or transgression changes
  useEffect(() => {
    if (values.incidents[index].transgressionCategory && values.incidents[index].transgression) {
      mapFrequencyToPotentialSanction(values.incidents[index].transgression);
      setWarnings(values.incidents[index].transgression);
    }
  }, [values.incidents[index].frequency, values.incidents[index].transgression]);

  // set process to hearing if potential sanction is dismissal
  useEffect(() => {
    if (
      values.incidents[index].potentialSanction &&
      values.incidents[index].potentialSanction.toLowerCase() === 'dismissal'
    ) {
      setFieldValue(`incidents.${index}.process`, 'HEARING');
    } else if (
      values.incidents[index].potentialSanction === 'WRITTEN_WARNING' ||
      values.incidents[index].potentialSanction === 'VERBAL_WARNING'
    ) {
      setFieldValue(`incidents.${index}.process`, 'DISCUSSION');
      setProcessOptions([{ label: 'Discussion', value: 'DISCUSSION' }]);
      return;
    } else if (
      (values.incidents[index].potentialSanction === 'FINAL_WRITTEN_WARNING' ||
        values.incidents[index].potentialSanction === 'DISMISSAL') &&
      organisationSettings?.defaultToHearingOnFinalWarning
    ) {
      setFieldValue(`incidents.${index}.process`, 'HEARING');
      setProcessOptions([{ label: 'Hearing', value: 'HEARING' }]);
      return;
    } else {
      setFieldValue(`incidents.${index}.process`, values.incidents[index].process || 'DISCUSSION');
    }
    setProcessOptions(defaultProcessOptions);
  }, [values.incidents[index].potentialSanction]);

  // Get transgressions for category
  useEffect(() => {
    if (values.incidents[index].transgressionCategory) {
      getTransgressionsFromCategory(values.incidents[index].transgressionCategory).catch(error => handleError(error));
    }
  }, [values.incidents[index].transgressionCategory]);

  return (
    <>
      <tr key={index}>
        <td style={{ width: '20%' }}>
          <TableFormField
            type={'textarea'}
            placeholder={'Summary of Allegations'}
            name={`incidents.${index}.summaryOfFacts`}
            className="square-radius border-0 h-25"
          />
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={`incidents.${index}.summaryOfFacts`} />
          </span>
        </td>
        <td className="position-relative" style={{ maxWidth: '120px', width: 'auto' }}>
          <TableFormField
            type={'date'}
            placeholder={'Date'}
            name={`incidents.${index}.date`}
            className="square-radius border-0 h-25"
          />
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={`incidents.${index}.date`} />
          </span>
        </td>
        <td className="position-relative" style={{ maxWidth: '120px', width: 'auto' }}>
          <TableFormField
            type={'time'}
            placeholder={'Time'}
            name={`incidents.${index}.time`}
            className="square-radius border-0"
          />
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={`incidents.${index}.time`} />
          </span>
        </td>
        <td className="position-relative" style={{ width: 'auto', maxWidth: '150px' }}>
          <Field name={`incidents.${index}.transgressionCategory`}>
            {({ field }: FieldAttributes<FormikValues>) => (
              <Select
                {...field}
                placeholder="Select Category"
                cacheOptions
                options={Object.keys(TransgressionCategory).map(category => {
                  return {
                    label: startCase(category.toLowerCase()),
                    value: category,
                  };
                })}
                closeMenuOnSelect={true}
                styles={tableFieldSelectStyles}
                onChange={(value: ValueType<any>): void => {
                  setFieldValue(`incidents.${index}.transgressionCategory`, value.label);
                  setFieldValue(`incidents.${index}.frequency`, '');
                  setFieldValue(`incidents.${index}.transgression`, '');
                  setWarningsCallback(index);
                }}
                components={{ SingleValue }}
                value={{
                  label: values.incidents[index].transgressionCategory
                    ? values.incidents[index].transgressionCategory
                    : 'Select Category',
                  value: values.incidents[index].transgressionCategory
                    ? values.incidents[index].transgressionCategory
                    : '',
                }}
                isDisabled={readOnly}
              />
            )}
          </Field>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={`incidents.${index}.transgressionCategory`} />
          </span>
        </td>
        <td className="position-relative" style={{ width: 'auto', maxWidth: '150px' }}>
          <Field name={`incidents.${index}.transgression`}>
            {({ field }: FieldAttributes<FormikValues>) => (
              <Select
                {...field}
                placeholder="Select Transgression"
                cacheOptions
                options={categoryOptions}
                closeMenuOnSelect={true}
                styles={tableFieldSelectStyles}
                onChange={(value: ValueType<any>): void => {
                  setFieldValue(`incidents.${index}.transgression`, value.value);
                  getFrequencyForTransgression(value.value);
                  setWarningsCallback(index);
                }}
                components={{ SingleValue }}
                defaultValue={{ label: 'Select Transgression', value: '' }}
                value={{
                  label: values.incidents[index].transgression,
                  value: values.incidents[index].transgression,
                }}
                isDisabled={readOnly}
              />
            )}
          </Field>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={`incidents.${index}.transgression`} />
          </span>
        </td>
        <td className="position-relative" style={{ width: 'auto', maxWidth: '130px' }}>
          <Field name={`incidents.${index}.frequency`}>
            {({ field }: FieldAttributes<FormikValues>) => (
              <Select
                {...field}
                placeholder="Select Frequency"
                cacheOptions
                options={[
                  { label: 'First Offence', value: 'firstOffence' },
                  { label: 'Second Offence', value: 'secondOffence' },
                  { label: 'Third Offence', value: 'thirdOffence' },
                  { label: 'Fourth Offence', value: 'fourthOffence' },
                  { label: 'Fifth Offence', value: 'fifthOffence' },
                  { label: 'Sixth Offence', value: 'sixthOffence' },
                ]}
                closeMenuOnSelect={true}
                styles={tableFieldSelectStyles}
                onChange={(value: ValueType<any>): void => {
                  setFieldValue(`incidents.${index}.frequency`, value.value);
                }}
                components={{ SingleValue }}
                value={{
                  label: values.incidents[index].frequency,
                  value: values.incidents[index].frequency,
                }}
                isDisabled={readOnly}
              />
            )}
          </Field>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={`incidents.${index}.frequency`} />
          </span>
        </td>
        <td className="position-relative" style={{ width: 'auto', maxWidth: '130px' }}>
          <Field name={`incidents.${index}.potentialSanction`}>
            {({ field }: FieldAttributes<FormikValues>) => (
              <Select
                {...field}
                placeholder="Select Potential Sanction"
                cacheOptions
                options={[
                  { label: 'Verbal Warning', value: 'VERBAL_WARNING' },
                  { label: 'Written Warning', value: 'WRITTEN_WARNING' },
                  { label: 'Final Written Warning', value: 'FINAL_WRITTEN_WARNING' },
                  { label: 'Dismissal', value: 'DISMISSAL' },
                ]}
                closeMenuOnSelect={true}
                styles={tableFieldSelectStyles}
                onChange={(value: ValueType<any>): void => {
                  setFieldValue(`incidents.${index}.potentialSanction`, value.value);
                }}
                components={{ SingleValue }}
                value={{
                  label: values.incidents[index].potentialSanction,
                  value: values.incidents[index].potentialSanction,
                }}
                isDisabled={readOnly}
              />
            )}
          </Field>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={`incidents.${index}.potentialSanction`} />
          </span>
        </td>
        <td className="position-relative" style={{ width: 'auto', maxWidth: '130px' }}>
          <Field name={`incidents.${index}.process`}>
            {({ field }: FieldAttributes<FormikValues>) => (
              <Select
                {...field}
                placeholder="Select Process"
                cacheOptions
                options={processOptions}
                closeMenuOnSelect={true}
                styles={tableFieldSelectStyles}
                onChange={(value: ValueType<any>): void => {
                  setFieldValue(`incidents.${index}.process`, value.value);
                }}
                value={{
                  label: values.incidents[index].process,
                  value: values.incidents[index].process,
                }}
                components={{ SingleValue }}
                isDisabled={readOnly}
              />
            )}
          </Field>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={`incidents.${index}.process`} />
          </span>
        </td>
        <td className="border-white">
          {index === 0 ? (
            <span
              className="d-flex justify-content-center align-items-center"
              onClick={(): void => {
                if (!readOnly) {
                  arrayHelpers.push({
                    summaryOfFacts: '',
                    date: '',
                    time: '',
                    witnesses: null,
                    complainants: null,
                    transgressionCategory: '',
                    transgression: '',
                    frequency: '',
                    potentialSanction: '',
                    process: '',
                  });
                } else {
                  handleError(new Error('You cannot add new incidents'));
                }
              }}
            >
              <AddIcon />
            </span>
          ) : (
            <span
              className="d-flex justify-content-center align-items-center"
              onClick={(): void => {
                if (!readOnly) {
                  arrayHelpers.remove(index);
                } else {
                  handleError(new Error('You cannot remove incidents'));
                }
              }}
            >
              <RemoveIcon />
            </span>
          )}
        </td>
      </tr>
    </>
  );
};

export default TransgressionInputs;
