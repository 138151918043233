import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import Paragraph from '../../../PDF/Paragraph.component';
import moment from 'moment';
import LetterHead from '../../../PDF/Letterhead.component';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const NoticeOfAppealHearingPDF: React.FC<DocumentProps> = (props): JSX.Element => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Confidential</Text>
        <Table>
          <TableHeader title="attention" />
          <TableRow>
            <TableCell>Name of Employee: </TableCell>
            <TableCell>{props.content.employeeFullName.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Employee Number: </TableCell>
            <TableCell>{props.content.employeeNumber.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Department/Business Unit</TableCell>
            <TableCell>{props.content.employeeDepartment.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{props.content.employeeAddress.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>{props.content.date.value}</TableCell>
          </TableRow>
        </Table>
        <Paragraph>Dear {props.content.employeeFirstName.value},</Paragraph>
        <Text style={{ ...styles.subject }}>Notice of Appeal Hearing - Misconduct</Text>
        <Paragraph>You are hereby called upon to appear at an appeal hearing as set out hereunder: </Paragraph>
        <Text style={{ ...styles.text, ...styles.bold }}>
          Date:{' '}
          {moment.isMoment(props.content.appeal_hearing_hearingDate.value)
            ? ' ' + props.content.appeal_hearing_hearingDate.value.format('DD-MM-YYYY')
            : props.content.appeal_hearing_hearingDate.value}{' '}
        </Text>
        <Text style={{ ...styles.text, ...styles.bold }}>
          Time:{' '}
          {moment.isMoment(props.content.appeal_hearing_hearingTime.value)
            ? ' ' + props.content.appeal_hearing_hearingTime.value.format('DD-MM-YYYY')
            : props.content.appeal_hearing_hearingTime.value}{' '}
        </Text>
        <Text style={{ ...styles.text, ...styles.bold }}>
          Place: {props.content.appeal_hearing_hearingLocation.value}
        </Text>
        <Paragraph>
          The appeal hearing will be chaired by{' '}
          {props.content.appeal_hearing_chairpersonFirstName.value +
            ' ' +
            props.content.appeal_hearing_chairpersonLastName.value}
          . Documentation relating to the original hearing as well as your Appeal Form have been made available to the
          appeal chairperson in advance.
        </Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>
          The purpose of the hearing is not to rehear the matter in its entirety, but to deal with the grounds for and
          merits of your appeal and/or to hear new evidence{' '}
          <Text debug style={{ ...styles.text, ...styles.bold }}>
            only.
          </Text>
        </Text>
        <Paragraph>
          You have the right to be represented/assisted by a fellow employee at the appeal hearing and/or shop steward
          of a recognised union, if any in the workplace.
        </Paragraph>
        <Paragraph>
          You have the right to an interpreter. The hearing will be conducted in English. If an interpreter is required
          this should be requested when the notification is issued, to allow the employer time to arrange this. Hearings
          may not be delayed due to late notification that an interpreter is required.
        </Paragraph>
        <Paragraph>
          You may call witnesses with due regard for the purpose as stated above (i.e. new evidence only), but it is
          your own responsibility to secure their presence at the hearing, subject to you not intimidating or putting
          any undue pressure on such witness. Please inform the employer at least 24 hours in advance of the hearing if
          you have arranged with any of its employees to appear as your witness in order for it to confirm the person’s
          absence from the workplace with his/her line manager.
        </Paragraph>
        <Paragraph>You are entitled to cross-examine witnesses called by the employer, if any.</Paragraph>
        <Paragraph>
          Should you fail or refuse to attend the appeal hearing and fail to timeously provide the employer with an
          acceptable reason for your failure / refusal, the hearing may be held in your absence without any further
          notice to yourself and action taken accordingly.
        </Paragraph>
        <Paragraph>
          You and/or your representative must come fully prepared to this hearing as no requests for postponements will
          be entertained. You are to argue the grounds for appeal as set out in your Appeal form.
        </Paragraph>
        <Paragraph>
          Should you fail to understand the contents of this notice, you are welcome to approach{' '}
          {props.content.authorName.value} who will be prepared to explain the matter to you.
        </Paragraph>
        <Paragraph>
          This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a copy
          to writer hereof in acknowledgement of receipt hereof, alternatively, acknowledge by way of return e-mail.
        </Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>Regards</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
      <Page style={styles.body} size="A4" wrap>
        <View>
          <Text style={{ ...styles.text, ...styles.bold }}>Employee responses:</Text>
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ ...styles.text, ...styles.fonts }}>
              A translator is required / not. If so, state language
            </Text>
            <Text
              style={{
                ...styles.line,
                ...styles.text,
                marginTop: -20,
                marginLeft: 4,
              }}
            >
              {'                               '}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
