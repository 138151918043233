import React, { ReactElement, useState } from 'react';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../WorkflowContainer/workflow-utils';
import { Formik, FormikProps, FormikValues } from 'formik';
import { RecordDemotionDiscussionSchema } from '../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import RecordDemotionDiscussionForm from './RecordDemotionDiscussionForm';

const RecordDemotionDiscussion: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const fields = {
    demotionDiscussionDate: 'string',
    demotionDiscussionTime: 'string',
    demotionDiscussionSummary: 'string',
    demotionDiscussionAttendees: 'array',
    demotionDiscussionDocuments: 'array',
    demotionDiscussionLocation: 'string',
  };
  const [data] = useState(getFormDataFromCaseData(fields, caseData, caseData.isAppeal));

  const submitValues = async (values: FormikValues): Promise<void> => {
    const formValues = { ...values };
    formValues.demotionDiscussionAttendees = JSON.stringify(formValues.demotionDiscussionAttendees);
    formValues.demotionDiscussionDocuments = JSON.stringify(formValues.demotionDiscussionDocuments);
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  return (
    <div className="content">
      <h4 className="text-default font-weight-bold">Record Demotion Discussion</h4>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={RecordDemotionDiscussionSchema}
        onSubmit={submitValues}
      >
        {({ values, errors, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
          <>
            <RecordDemotionDiscussionForm processInstanceId={props.data.processInstanceId} />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => flowableFunctions.onSaveAndClose(values)}
            />
            {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
          </>
        )}
      </Formik>
    </div>
  );
};

export default RecordDemotionDiscussion;
