import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './PDFStyles.style';

interface ListItemProps {
  index?: number;
}

const ListItem: React.FC<ListItemProps> = ({ index, children }) => {
  return (
    <View wrap={false} style={{ display: 'flex', flexDirection: 'row', width: '95%' }}>
      {index ? (
        <Text style={{ ...styles.text, ...styles.fonts, fontSize: 11, margin: 3, marginTop: 3 }}>{index}.</Text>
      ) : (
        <View style={{ ...styles.bulletPoint, margin: 6, marginTop: 8 }} />
      )}
      <Text style={{ ...styles.text, ...styles.fonts, fontSize: 11, fontWeight: 'thin', paddingTop: '3' }}>
        {children}
      </Text>
    </View>
  );
};

export default ListItem;
