import React, { ReactElement } from 'react';
import { Button } from 'reactstrap';

interface ButtonWithIconsProps {
  title: string;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  disabled?: boolean;
  buttonType?: string;
  style?: Record<string, unknown>;
  handleClick?: () => void;
  buttonClass?: string;
}
const ButtonWithIcons: React.FC<ButtonWithIconsProps> = (props: ButtonWithIconsProps) => {
  const { style, title, leftIcon, rightIcon, handleClick, disabled, buttonType, buttonClass } = props;

  return (
    <Button
      className={`${buttonType} text-uppercase px-4 mr-3 shadow rounded-0.1 ${buttonClass}`}
      onClick={handleClick}
      disabled={disabled}
      style={{ ...style, borderRadius: '2px', height: '40px', cursor: 'pointer' }}
      {...props}
    >
      <div className="d-flex justify-content-center align-items-center">
        {!!leftIcon && <span className="pr-3">{leftIcon}</span>}
        <span className="font-weight-light">{title}</span>
        {!!rightIcon && <span className="pl-3">{rightIcon}</span>}
      </div>
    </Button>
  );
};

export default ButtonWithIcons;
