import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import List from '../../../PDF/List.component';
import ListItem from '../../../PDF/ListItem.component';
import Paragraph from '../../../PDF/Paragraph.component';
import { DocumentHeader } from '../../common/Header';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const AgreedDemotionPDF: React.FC<DocumentProps> = (props: DocumentProps) => {
  return (
    <Document>
      {console.log(props.content)}
      <Page style={styles.body} size="A4" wrap>
        <DocumentHeader content={props.content} logo={props.logo} />
        <Paragraph> Dear {props.content.employeeFullName.value}, </Paragraph>
        <Text style={{ ...styles.subject }}>CONFIRMATION OF AGREED DEMOTION</Text>
        <List>
          <ListItem>The performance improvement process embarked upon by the parties refers.</ListItem>
          <ListItem>
            We discussed our concerns as employer with you regarding your work performance and possible ways to deal
            with the matters arising.
          </ListItem>
          <ListItem>
            We herewith confirm that the parties have agreed on your demotion as corrective measure on the terms as set
            out below:
          </ListItem>
        </List>
        <Table>
          <TableRow>
            <TableCell>New job title </TableCell>
            <TableCell>{props.content.demotionNewJobTitle.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>New job level/grade </TableCell>
            <TableCell>{props.content.demotionNewJobLevel.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Department</TableCell>
            <TableCell>{props.content.demotionNewDepartment.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>New remuneration package per annum </TableCell>
            <TableCell>{props.content.demotionNewRemunerationPackage.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Any other changed terms and conditions related to the new level/grade</TableCell>
            <TableCell>{props.content.demotionNewTerms.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Effective date of demotion </TableCell>
            <TableCell>{props.content.demotionDate.value}</TableCell>
          </TableRow>
        </Table>
        <List>
          <ListItem>
            Details of the role and performance expectations will be discussed with you by your reporting manager.
          </ListItem>
          <ListItem>
            At this time the parties will not proceed with a formal incapacity – poor performance process at this time.
            Your performance rather will be monitored against the standards and expectations set for your new level and
            feedback given to ensure acceptable future performance.
          </ListItem>
          <ListItem>
            You should continue to engage with us on any assistance you may require so that pro-active steps can be
            introduced to assist you to perform, if required.
          </ListItem>
          <ListItem>We confirm you have read this letter and understand the content.</ListItem>
          <ListItem>
            Please sign a copy of this document to show your agreement with the content hereof and return to writer
            hereof at your soonest convenience, alternatively, confirm acceptance by way of return e-mail.
          </ListItem>
          <ListItem>We wish you success on your new level.</ListItem>
        </List>
        <Paragraph>Yours sincerely,</Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
    </Document>
  );
};
