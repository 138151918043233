import React, { useContext, useState } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { UserContext, UserContextProps } from '../../../App';
import { Card, Col, Row } from 'reactstrap';
import {
  getTimePeriodFromYear,
  OptionType,
  TIME_PERIOD_OPTIONS,
  YEAR_OPTIONS,
} from './chart-options/chart-options-utils';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import CardBody from 'reactstrap/lib/CardBody';
import {
  tableFieldSelectStyles,
  ValueContainer,
} from '../../../components/reactSelect/ReactSelectComponents.component';
import Select, { ValueType } from 'react-select';
import { post } from '../../../utils/api-utils';
import { CaseDataSetQuery, ChartsQuery, MultiDataSet } from './chart-options/case-reports-types';
import ChartDropdownsSingleSelect from './chart-options/ChartDropdownsSingleSelect';
import ChartDropdownsMultiSelects from './chart-options/ChartDropdownsMultiSelects';

import Loader from '../../../components/loader/Loader';
import { toTitleCase } from '../../../utils/string-utils';
import { BarGraph } from './graphs/BarGraph';
import { DATE_FORMAT_DASHES } from '../../../utils/date-formats';

//@ts-ignore
const moment = extendMoment(Moment);

export interface QueryTimePeriod {
  startDate: string;
  endDate: string;
}

const MultiVarChart: React.FC = () => {
  const [gradients] = useState(true);
  const [differentColours] = useState(false);
  const [queries, setQueries] = useState<CaseDataSetQuery[]>([]);
  const [queryFilter, setQueryFilter] = useState<CaseDataSetQuery | null>(null);
  const [groupingQueries, setGroupingQueries] = useState<CaseDataSetQuery[]>([]);
  const [currentDataSet, setCurrentDataSet] = useState<MultiDataSet[]>([]);
  const [loadingGraph, setLoadingGraph] = useState<boolean>(false);

  // Time-related options:
  const [yearPeriod, setYearPeriod] = useState<OptionType | null>(YEAR_OPTIONS[1]);
  const [timePeriodType, setTimePeriodType] = useState<OptionType | null>(TIME_PERIOD_OPTIONS[0]);
  const [endDate, setEndDate] = useState<Date | null>(moment().toDate());
  const [startDate, setStartDate] = useState<Date | null>(
    moment()
      .startOf('year')
      .toDate(),
  );

  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const getCurrentTimePeriod = (): { startDate: string; endDate: string } => {
    if (timePeriodType?.value === 'year' && !!yearPeriod?.value && typeof yearPeriod.value === 'string') {
      return getTimePeriodFromYear(yearPeriod.value);
    } else {
      return {
        startDate: startDate ? moment(startDate).format(DATE_FORMAT_DASHES) : '2019-01-01',
        endDate: endDate ? moment(endDate).format(DATE_FORMAT_DASHES) : '2099-01-01',
      };
    }
  };

  const onClickUpdate = () => {
    if (currentUser?.organisationId) {
      const chartsQuery: ChartsQuery = {
        dataSetQueries: queries,
        organisationId: currentUser?.organisationId,
        timePeriod: getCurrentTimePeriod(),
      };
      if (queryFilter) {
        chartsQuery.filterDataSetQuery = queryFilter;
      }
      if (groupingQueries) {
        chartsQuery.groupingDataSetQueries = groupingQueries;
      }
      setLoadingGraph(true);
      post<MultiDataSet[]>('/case-reporting', chartsQuery)
        .then(res => {
          setCurrentDataSet(res.data);
          setLoadingGraph(false);
        })
        .catch(() => {
          setLoadingGraph(false);
        });
    }
  };

  const getChartHeading = () => {
    const timePeriodText =
      timePeriodType?.value === 'year'
        ? yearPeriod?.label
        : moment(startDate).format(DATE_FORMAT_DASHES) + ' - ' + moment(endDate).format(DATE_FORMAT_DASHES);
    // e.g. quarter + ly
    return toTitleCase(timePeriodText ?? '', ' ');
  };

  return (
    <div className="px-3 mt-4">
      <Card className="shadow bg-white" style={{ alignItems: 'left' }}>
        <CardBody>
          <Row className="d-flex justify-content-between align-items-start py-0 px-3" style={{ height: '20px' }}>
            <h4 className="text-default">MULTI-VARIABLE CHART</h4>
          </Row>
          <hr />
          <Row>
            <Col md={4}>
              <h5 className="text-default">Choose variables for x-axis:</h5>
              <ChartDropdownsMultiSelects
                heading="Filter"
                onChange={(queries: CaseDataSetQuery[]) => setQueries(queries)}
              />
            </Col>
            <Col md={4}>
              <h5 className="text-default">Choose variables for y-axis:</h5>
              <ChartDropdownsMultiSelects
                heading="Filter"
                onChange={(queries: CaseDataSetQuery[]) => setGroupingQueries(queries)}
              />
            </Col>
            <Col md={4}>
              <h5 className="text-default">Choose time period:</h5>
              <Select
                placeholder="Select Time Period"
                cacheOptions
                options={TIME_PERIOD_OPTIONS}
                closeMenuOnSelect={true}
                styles={tableFieldSelectStyles}
                value={timePeriodType}
                onChange={(value: ValueType<any>): void => setTimePeriodType(value)}
                components={{ ValueContainer }}
              />

              {timePeriodType?.value === 'custom' && (
                <div>
                  <label style={{ color: '#ababab', marginRight: 10 }}>
                    Select start date:
                    <DatePicker
                      dateFormat={'yyyy-MM-dd'}
                      shouldCloseOnSelect={true}
                      selected={startDate}
                      onChange={(date: Date | null): void => {
                        setStartDate(date);
                      }}
                    />
                  </label>
                  <br />
                  <label style={{ color: '#ababab', marginRight: 10 }}>
                    Select end date:
                    <DatePicker
                      dateFormat={'yyyy-MM-dd'}
                      shouldCloseOnSelect={true}
                      selected={endDate}
                      onChange={(date: Date | null): void => {
                        setEndDate(date);
                      }}
                    />
                  </label>
                </div>
              )}
              {timePeriodType?.value === 'year' && (
                <div>
                  <Select
                    placeholder="Select Year"
                    cacheOptions
                    value={yearPeriod}
                    options={YEAR_OPTIONS}
                    closeMenuOnSelect={true}
                    styles={tableFieldSelectStyles}
                    onChange={(value: ValueType<any>): void => {
                      setYearPeriod(value || []);
                    }}
                    components={{ ValueContainer }}
                  />
                </div>
              )}
            </Col>
            <Col md={4}>
              <h5 className="text-default">Choose filter:</h5>
              <ChartDropdownsSingleSelect heading="Filter" onChange={filter => setQueryFilter(filter)} />
            </Col>
            <Col md={4} />
            <Col md={4}>
              {' '}
              <button
                className="btn btn-primary w-100"
                onClick={() => onClickUpdate()}
                disabled={!queries.length || !groupingQueries.length}
              >
                UPDATE CHART
              </button>
            </Col>
          </Row>
          {loadingGraph ? (
            <Row className="d-flex flex-row justify-content-center">
              {' '}
              <Loader></Loader>{' '}
            </Row>
          ) : (
            <></>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <BarGraph
              multiDataSets={currentDataSet}
              heading={getChartHeading()}
              gradients={gradients}
              colourStart={differentColours ? 60 : 270}
            />
            )
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default MultiVarChart;
