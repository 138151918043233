import React, { useContext, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import { Tooltip } from '../../../components/tooltips/Tooltip.component';
import { ErrorMessage, FormikProps, FormikValues, useFormikContext } from 'formik';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../components/taskContainer/TaskContainer';
import FormField from '../../../forms/fields/FormField.component';
import UploaderContainer from '../../../components/Uploader/UploaderContainer';
import { CloseIcon } from '../../../components/icon/Icon.component';
import { useCaseDataForHistoryTable } from '../../../utils/case-utils';
import CaseTable from '../../../components/tables/CaseTable.component';
import { CaseData } from '../../WorkflowContainer/workflow-utils';
import { CaseType } from '../../../API';

interface CaseAssessmentFormProps {
  caseData: CaseData;
}
const CaseAssessmentForm: React.FC<CaseAssessmentFormProps> = (props: CaseAssessmentFormProps) => {
  const { processInstanceId, employeeId, organisationId } = props.caseData;
  const { setFieldValue, values }: FormikProps<FormikValues> = useFormikContext();
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { data } = useCaseDataForHistoryTable({
    employeeId,
    organisationId,
    caseType: CaseType.POOR_PERFORMANCE,
  });

  return (
    <>
      <Row className="my-1">
        <Col>
          <Label className="text-default d-block">
            Have any remedial actions been instituted related to this employee's performance shortfalls
            <span className="ml-3">
              <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                <Tooltip
                  id="remedialActionsInstituted"
                  title="Remedial actions instituted?"
                  text={
                    'These can include any previous measures, informal and formal, taken to assist the employee in attaining  the  required  performance  standards.  Describe  what  feedback  was  provided  and/or what   measures   were   taken   to   develop   the   employee’s   knowledge,   skills,   abilities   and competencies.  Be  brief.  Where  available  upload  relevant  supporting  documentation,  such  as performance review notes, minutes of 1:1 meetings or training records. The purpose is to assess what corrective measures have already been taken and documented, which can form the basis to progress to the necessary steps in the incapacity poor performance process.'
                  }
                />
              </span>
            </span>
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.remedialActionsInstituted}
                    onChange={(): void => {
                      setFieldValue('remedialActionsInstituted', true);
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="no"
                    checked={!values.remedialActionsInstituted}
                    onChange={(): void => setFieldValue('remedialActionsInstituted', false)}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      {values.remedialActionsInstituted && (
        <>
          <Row>
            <Col md={4} className="mt-3 pt-1">
              <FormGroup>
                <Button
                  className="rounded-0 btn-simple"
                  onClick={(): void => {
                    setModalIsOpen(true);
                  }}
                >
                  View PP History Issues
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Label className="text-default d-block">Performance Review process feedback</Label>
              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="yes"
                        checked={values.performanceReviewFeedbackGiven}
                        onChange={(): void => {
                          setFieldValue('performanceReviewFeedbackGiven', true);
                        }}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">yes</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="no"
                        checked={!values.performanceReviewFeedbackGiven}
                        onChange={(): void => setFieldValue('performanceReviewFeedbackGiven', false)}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-uppercase">no</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </Col>
          </Row>
          {values.performanceReviewFeedbackGiven && (
            <Row className="mt-3">
              <Col md={10}>
                <FormGroup>
                  <Label for="performanceReviewFeedbackSummary" className="text-default text-capitalize">
                    Provide details of support provided*
                  </Label>
                  <FormField name="performanceReviewFeedbackSummary" placeholder="Enter details" type="textarea" />
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'performanceReviewFeedbackSummary'} />
                  </span>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col>
              <Label className="text-default d-block">Informal 1:1's </Label>
              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="yes"
                        checked={values.haveMetInformally}
                        onChange={(): void => {
                          setFieldValue('haveMetInformally', true);
                        }}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">yes</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="no"
                        checked={!values.haveMetInformally}
                        onChange={(): void => setFieldValue('haveMetInformally', false)}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-uppercase">no</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </Col>
          </Row>
          {values.haveMetInformally && (
            <Row className="mt-3">
              <Col md={10}>
                <FormGroup>
                  <Label for="informalMeetingSummary" className="text-default text-capitalize">
                    Provide details of support provided*
                  </Label>
                  <FormField name="informalMeetingSummary" placeholder="Enter details" type="textarea" />
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'informalMeetingSummary'} />
                  </span>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col>
              <Label className="text-default d-block">Coaching/Mentoring </Label>
              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="yes"
                        checked={values.hasReceivedCoaching}
                        onChange={(): void => {
                          setFieldValue('hasReceivedCoaching', true);
                        }}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">yes</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="no"
                        checked={!values.hasReceivedCoaching}
                        onChange={(): void => setFieldValue('hasReceivedCoaching', false)}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-uppercase">no</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </Col>
          </Row>
          {values.hasReceivedCoaching && (
            <Row className="mt-3">
              <Col md={10}>
                <FormGroup>
                  <Label for="coachingSummary" className="text-default text-capitalize">
                    Provide details of support provided*
                  </Label>
                  <FormField name="coachingSummary" placeholder="Enter details" type="textarea" />
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'coachingSummary'} />
                  </span>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col>
              <Label className="text-default d-block">On the Job training</Label>
              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="yes"
                        checked={values.hasReceivedOnTheJobTraining}
                        onChange={(): void => {
                          setFieldValue('hasReceivedOnTheJobTraining', true);
                        }}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">yes</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="no"
                        checked={!values.hasReceivedOnTheJobTraining}
                        onChange={(): void => setFieldValue('hasReceivedOnTheJobTraining', false)}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-uppercase">no</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </Col>
          </Row>
          {values.hasReceivedOnTheJobTraining && (
            <Row className="mt-3">
              <Col md={10}>
                <FormGroup>
                  <Label for="onTheJobTrainingSummary" className="text-default text-capitalize">
                    Provide details of support provided*
                  </Label>
                  <FormField name="onTheJobTrainingSummary" placeholder="Enter details" type="textarea" />
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'onTheJobTrainingSummary'} />
                  </span>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col>
              <Label className="text-default d-block">Classroom-based support</Label>
              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="yes"
                        checked={values.hasReceivedClassroomBasedSupport}
                        onChange={(): void => {
                          setFieldValue('hasReceivedClassroomBasedSupport', true);
                        }}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">yes</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="no"
                        checked={!values.hasReceivedClassroomBasedSupport}
                        onChange={(): void => setFieldValue('hasReceivedClassroomBasedSupport', false)}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-uppercase">no</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </Col>
          </Row>
          {values.hasReceivedClassroomBasedSupport && (
            <Row className="mt-3">
              <Col md={10}>
                <FormGroup>
                  <Label for="classroomBasedSupportSummary" className="text-default text-capitalize">
                    Provide details of support provided*
                  </Label>
                  <FormField name="classroomBasedSupportSummary" placeholder="Enter details" type="textarea" />
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'classroomBasedSupportSummary'} />
                  </span>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col>
              <Label className="text-default d-block">Other</Label>
              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="yes"
                        checked={values.extraInformation}
                        onChange={(): void => {
                          setFieldValue('extraInformation', true);
                        }}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">yes</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="no"
                        checked={!values.extraInformation}
                        onChange={(): void => setFieldValue('extraInformation', false)}
                        disabled={readOnly}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-uppercase">no</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </Col>
          </Row>
          {values.extraInformation && (
            <Row className="mt-3">
              <Col md={10}>
                <FormGroup>
                  <Label for="extraInformationSummary" className="text-default text-capitalize">
                    Provide details of support provided*
                  </Label>
                  <FormField name="extraInformationSummary" placeholder="Enter details" type="textarea" />
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'extraInformationSummary'} />
                  </span>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row className="d-flex justify-content-between mt-3">
            <Col md={3} className="align-items-start">
              <FormGroup>
                <Label for="extraInformationSummary" className="text-default text-capitalize">
                  Upload Supporting Documents*
                </Label>
                <UploaderContainer
                  fieldName={'caseAssessmentSupportingDocuments'}
                  isMulti={true}
                  path={`cases/${processInstanceId}`}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}

      <Modal isOpen={modalIsOpen} toggle={(): void => setModalIsOpen(!modalIsOpen)} className="top-5" size="lg">
        <ModalBody className="px-3 py-3">
          <div className="d-flex justify-content-between">
            <span className="font-weight-bold pt-1 text-dark h4 text-uppercase align-self-start">
              History of Poor Performance Issues
            </span>
            <span className="text-default h4 text-uppercase align-self-end" onClick={(): void => setModalIsOpen(false)}>
              <CloseIcon />
            </span>
          </div>
          <span className="text-dark my-3 h5"> Current Issues </span>
          <CaseTable
            caseData={data.systemCases}
            showTabs={false}
            auditTrailPath={'/audit-trail/case/'}
            caseNumberPath={'/case/'}
            showActionButtons={true}
          />
          <span className="text-dark my-3 h5"> Past Issues </span>
          <CaseTable
            caseData={data.pastCases}
            showTabs={false}
            auditTrailPath={'/audit-trail/case/'}
            caseNumberPath={'/case/'}
            showActionButtons={false}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default CaseAssessmentForm;
