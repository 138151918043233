import React, { useContext, useState } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { Button, Input } from 'reactstrap';
import { EmailAddressType, TemplateType } from '../../API';
import { EmailParamsV2, EmailRecipient, EmailType } from '../../utils/email-utils';
import Select, { ValueType } from 'react-select';
import { CloseIcon } from '../icon/Icon.component';
import { listActiveEmployeesByOrganisationId } from '../../utils/graphql-utils';
import { UserContext, UserContextProps } from '../../App';
import { SelectType, ValueContainer } from '../reactSelect/ReactSelectComponents.component';
import AsyncSelect from 'react-select/async';
import './document-modal.styles.scss';
import { useErrorHandler } from '../../utils/notification-utils';
import { BucketFile } from '../../screens/WorkflowContainer/workflow-utils';
import { Employee, TemplateDocumentVersion } from '../../models';
import { toTitleCase } from '../../utils/string-utils';
import SignDocumentModal from './SignDocumentModal';
import { EmailPreviewModalv3 } from '../EmailPreviewModal/EmailPreviewModalv3';
import { completeDocumentTask } from '../../utils/email-utils';
import { DocumentTask } from './DocumentVersionControlTable';
import { documentConfigs } from '../../configs/document-configs/document-configs';
import ButtonWithIcons from '../buttons/ButtonWIthIcons.component';

interface DocumentModalProps {
  documentType: TemplateType;
  additionalAttachmentFormKeys?: string[];
  documentTask: DocumentTask;
  employee: Employee;
  employees: Employee[];
  closeModal: () => void;
  issueDocument: (
    document: TemplateDocumentVersion,
    emailParams: EmailParamsV2,
    documentDeliveryMethod: DocumentDeliveryMethod,
    documentProcessInstanceId: string,
  ) => Promise<void>;
  processInstanceId: string;
  appeal_hearingChairpersonRulingDocumentURL?: string;
  downloadDocument: (document: TemplateDocumentVersion) => Promise<void>;
  masterProcessInstanceId: string;
  documentProcessInstanceId: string;
}

interface DocumentModalState {
  selectedDeliveryMethod: OptionType;
  employeeDropDownOpen: boolean;
  additionalEmployeeEmail: string;
  nominatedEmployeeEmail: string | null;
  nominatedEmployeeFirstName: string | null;
  uploadProgress: number;
  documentUrl: any;
  uploading: boolean;
  file: BucketFile | null;
}

export enum DocumentDeliveryMethod {
  DIRECT_EMAIL = 'DIRECT_EMAIL',
  PRINT_AND_DELIVER = 'PRINT_AND_DELIVER',
  NOMINATE_EMPLOYEE_TO_DELIVER = 'NOMINATE_EMPLOYEE_TO_DELIVER',
  SIGN_DIGITALLY = 'SIGN_DIGITALLY',
}

interface OptionType {
  value: DocumentDeliveryMethod;
  label: string;
}

export const IssueLetterModal: React.FC<DocumentModalProps> = (props: DocumentModalProps) => {
  const options: OptionType[] = [
    { value: DocumentDeliveryMethod.DIRECT_EMAIL, label: 'Email' },
    { value: DocumentDeliveryMethod.PRINT_AND_DELIVER, label: 'Print & Deliver' },
    { value: DocumentDeliveryMethod.NOMINATE_EMPLOYEE_TO_DELIVER, label: 'Nominate an Employee to Deliver' },
    { value: DocumentDeliveryMethod.SIGN_DIGITALLY, label: 'Sign on tablet' },
  ];

  const [state, setState] = useState<DocumentModalState>({
    employeeDropDownOpen: false,
    additionalEmployeeEmail: '',
    nominatedEmployeeEmail: null,
    nominatedEmployeeFirstName: null,
    selectedDeliveryMethod: options[0],
    uploadProgress: 0,
    documentUrl: null,
    uploading: false,
    file: null,
  });

  const [isDownloadingOrSending, setIsDownloadingOrSending] = useState<boolean>(false);
  const [hasSigned, setHasSigned] = useState<boolean>(false);
  const attachmentName = props.documentTask.doc.templateType
    ? toTitleCase(props.documentTask.doc.templateType, ' ') + '_v' + props.documentTask.doc.version?.toString() + '.pdf'
    : '';

  const handleError = useErrorHandler();

  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const updateAdditionalEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const text: string = event.target.value.trim();
    setState((oldState: DocumentModalState) => ({ ...oldState, additionalEmployeeEmail: text }));
  };

  const getEmployeeEmail = (employee: Employee): string | null => {
    let emailAddress: string | null = null;
    if (employee?.emails) {
      employee.emails.forEach(item => {
        if (item?.emailAddressType === EmailAddressType.WORK) {
          emailAddress = item.address;
        } else if (item) {
          emailAddress = item.address;
        }
      });
    }
    return emailAddress;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId) {
      return listActiveEmployeesByOrganisationId(currentUser.organisationId).then(response => {
        const preparedData = response.map((employee: Employee) => {
          const email = getEmployeeEmail(employee);
          return {
            label: toTitleCase(employee.firstName + ' ' + employee.lastName, ' '),
            value: employee.id,
            emailAddress: email,
            name: employee.firstName + ' ' + employee.lastName,
          };
        });
        return filterItems(preparedData, inputValue);
      });
    }
  };

  const getEmployeeEmails = (employee: Employee): string[] => {
    const myEmails: string[] = [];
    if (employee.emails?.length) {
      for (let i = 0; i < employee.emails.length; i++) {
        const email = employee.emails[i];
        if (email && email.address) myEmails.push(email.address);
      }
    }
    return myEmails;
  };

  const onSelectNominatedEmployee = (value: {
    value: string;
    label: string;
    emailAddress: string | null;
    name: string;
  }) => {
    if (value.emailAddress) {
      setState((oldState: DocumentModalState) => ({
        ...oldState,
        nominatedEmployeeEmail: value.emailAddress,
        nominatedEmployeeFirstName: value.name,
      }));
    } else handleError(new Error('Selected employee does not have an email address'));
  };

  const renderPageForDirectEmail = (): JSX.Element => {
    return (
      <>
        <div>
          <p className="ml-4 mt-3">System Email Addresses</p>
          {getEmployeeEmails(props.employee).map((item: string, index: number) => (
            <Input key={index} className="modal-text-input" value={item} disabled={true} />
          ))}
        </div>
        <div>
          <p className="ml-4 mt-3">Additional Email Address</p>
          <Input
            className="modal-text-input"
            placeholder={'Add Employee Personal Email'}
            onChange={updateAdditionalEmail}
          />
        </div>
      </>
    );
  };

  const renderPageForNominateEmployee = (): JSX.Element => {
    return (
      <div>
        <p className="ml-4 mt-3">Email the document to a nominated employee for hand delivery to recipient</p>
        <div>
          <AsyncSelect
            className="document-modal-select"
            placeholder="Select Employee"
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            closeMenuOnSelect={true}
            components={{ ValueContainer }}
            onChange={(value: ValueType<any>) => onSelectNominatedEmployee(value)}
          />
        </div>
      </div>
    );
  };

  const printAndDeliver = async (documentTask: DocumentTask): Promise<void> => {
    if (currentUser) {
      completeDocumentTask({
        documentId: documentTask.doc.id,
        taskDefinitionKey: 'issue-document',
        variables: [{ name: 'documentDeliveryMethod', value: DocumentDeliveryMethod.PRINT_AND_DELIVER }],
        userId: currentUser.id,
        processInstanceId: documentTask.processInstanceId,
      })
        .then(() => {
          props.closeModal();
        })
        .catch(handleError);
    } else {
      handleError(new Error('No current user'));
    }
  };

  const prepareDigitalSign = async (documentTask: DocumentTask): Promise<void> => {
    //documentTask
    console.log({ documentTask });
    const windowReference = window.open();
    if (currentUser) {
      completeDocumentTask({
        documentId: documentTask.doc.id,
        taskDefinitionKey: 'issue-document',
        variables: [{ name: 'documentDeliveryMethod', value: DocumentDeliveryMethod.SIGN_DIGITALLY }],
        userId: currentUser.id,
        processInstanceId: documentTask.processInstanceId,
      })
        .then(() => {
          props.closeModal();
          if (windowReference) {
            //@ts-ignore
            windowReference.location = `https://uat.labourteq.com/sign-document/${documentTask.processInstanceId}`;
          }
        })
        .catch(handleError);
    } else {
      handleError(new Error('No current user'));
    }
  };

  const renderPageForPrintAndDeliver = (): JSX.Element => {
    return <></>;
  };
  const renderPageDigitalSign = (): JSX.Element => {
    return (
      <span style={{ width: 250, display: 'inline-block', marginLeft: 32 }}>
        Important: Please remember to hand the employee a copy of the document once signed by both parties.{' '}
      </span>
    );
  };

  const renderCurrentPageOnModal = (documentDeliveryMethod: DocumentDeliveryMethod): JSX.Element => {
    switch (documentDeliveryMethod) {
      case DocumentDeliveryMethod.DIRECT_EMAIL:
        return renderPageForDirectEmail();
      case DocumentDeliveryMethod.NOMINATE_EMPLOYEE_TO_DELIVER:
        return renderPageForNominateEmployee();
      case DocumentDeliveryMethod.PRINT_AND_DELIVER:
        return renderPageForPrintAndDeliver();
      case DocumentDeliveryMethod.SIGN_DIGITALLY:
        return renderPageDigitalSign();
      default:
        return <div />;
    }
  };

  const onSelectDeliveryMethod = (option: ValueType<OptionType>): void => {
    setState(oldState => ({ ...oldState, selectedDeliveryMethod: option as OptionType }));
  };

  const getBucketKeys = (): string[] => {
    let keys = [];
    if (props.documentTask.doc.bucketPath) {
      keys.push(props.documentTask.doc.bucketPath);
    }
    if (props.additionalAttachmentFormKeys) {
      keys = keys.concat(props.additionalAttachmentFormKeys);
    }
    return keys;
  };

  const getParamsForNominateEmployee = (): EmailParamsV2 => {
    if (!currentUser) {
      throw new Error('no user');
    }
    const bucketKeys = getBucketKeys();
    const recipients = getRecipientForNominatedEmployee();
    return {
      attachmentBucketKeys: bucketKeys,
      currentUserId: currentUser.id,
      emailType: EmailType.DELIVER_DOCUMENT,
      formValues: {
        // @ts-ignore
        documentDescription: documentConfigs[props.documentTask.doc.templateType]?.name ?? '',
      },
      masterProcessInstanceId: props.masterProcessInstanceId,
      processInstanceId: props.processInstanceId,
      recipients: recipients,
    };
  };

  const nominateEmployee = async () => {
    const emailParams = getParamsForNominateEmployee();
    await props
      .issueDocument(
        props.documentTask.doc,
        emailParams,
        DocumentDeliveryMethod.NOMINATE_EMPLOYEE_TO_DELIVER,
        props.documentProcessInstanceId,
      )
      .catch(error => handleError(error));
    props.closeModal();
  };

  const getRecipientForNominatedEmployee = (): EmailRecipient[] => {
    if (!state.nominatedEmployeeEmail || !state.nominatedEmployeeFirstName) {
      throw new Error('No nominated employee');
    }
    return [
      {
        emailAddress: state.nominatedEmployeeEmail,
        name: toTitleCase(state.nominatedEmployeeFirstName, ' '),
      },
    ];
  };

  const getParamsForIssueByDirectMail = (): EmailParamsV2 => {
    if (!currentUser) {
      throw new Error('no user');
    }
    const bucketKeys = getBucketKeys();
    const recipients = getRecipientForIssueByEmail();
    return {
      attachmentBucketKeys: bucketKeys,
      currentUserId: currentUser.id,
      emailType: documentConfigs[props.documentType]?.emailType ?? EmailType.ISSUE_DOCUMENT_BY_EMAIL,
      formValues: {
        // @ts-ignore
        documentDescription: documentConfigs[props.documentTask.doc.templateType]?.name ?? '',
      },
      masterProcessInstanceId: props.masterProcessInstanceId,
      processInstanceId: props.processInstanceId,
      recipients: recipients,
    };
  };

  const getRecipientForIssueByEmail = (): EmailRecipient[] => {
    const emailAddresses = getEmployeeEmails(props.employee);
    if (state.additionalEmployeeEmail) {
      emailAddresses.push(state.additionalEmployeeEmail);
    }
    return emailAddresses.map(e => ({ emailAddress: e, name: props.employee.firstName }));
  };

  const issueByDirectEmail = async () => {
    const emailParams = getParamsForIssueByDirectMail();
    await props
      .issueDocument(
        props.documentTask.doc,
        emailParams,
        DocumentDeliveryMethod.DIRECT_EMAIL,
        props.documentProcessInstanceId,
      )
      .catch(error => handleError(error));
    props.closeModal();
  };

  const showEmailPreviewButton = (): JSX.Element => {
    if (state.selectedDeliveryMethod.value === DocumentDeliveryMethod.NOMINATE_EMPLOYEE_TO_DELIVER) {
      return (
        <EmailPreviewModalv3
          buttonText={'Preview'}
          disabled={!state.nominatedEmployeeEmail}
          formValues={{
            // @ts-ignore
            documentDescription: documentConfigs[props.documentTask.doc.templateType]?.name ?? '',
          }}
          emailType={EmailType.DELIVER_DOCUMENT}
          masterProcessInstanceId={props.masterProcessInstanceId}
          processInstanceId={props.processInstanceId}
          currentUserId={currentUser?.id}
          getRecipients={getRecipientForNominatedEmployee}
          getAttachmentBucketKeys={getBucketKeys}
          sendFunction={nominateEmployee}
          getFlowableVariables={() => []}
        />
      );
    } else if (state.selectedDeliveryMethod.value === DocumentDeliveryMethod.DIRECT_EMAIL) {
      return (
        <EmailPreviewModalv3
          buttonText={'Preview'}
          disabled={false}
          formValues={{
            // @ts-ignore
            documentDescription: documentConfigs[props.documentTask.doc.templateType]?.name ?? '',
          }}
          emailType={documentConfigs[props.documentType]?.emailType ?? EmailType.ISSUE_DOCUMENT_BY_EMAIL}
          masterProcessInstanceId={props.masterProcessInstanceId}
          processInstanceId={props.processInstanceId}
          currentUserId={currentUser?.id}
          getAttachmentBucketKeys={getBucketKeys}
          getRecipients={getRecipientForIssueByEmail}
          sendFunction={issueByDirectEmail}
          getFlowableVariables={() => []}
        />
      );
    } else {
      throw new Error('No delivery method selected');
    }
  };

  const renderButtonForEmail = (method: DocumentDeliveryMethod): JSX.Element => {
    if (
      (method === DocumentDeliveryMethod.DIRECT_EMAIL || DocumentDeliveryMethod.SIGN_DIGITALLY) &&
      !(hasSigned || props.documentTask.doc.signedCopy)
    ) {
      return <SignDocumentModal document={props.documentTask.doc} onSign={() => setHasSigned(true)} />;
    } else if (method === DocumentDeliveryMethod.SIGN_DIGITALLY) {
      return (
        <ButtonWithIcons
          title={'Employee to Sign Next'}
          handleClick={() => {
            prepareDigitalSign(props.documentTask);
          }}
        />
      );
    } else {
      return showEmailPreviewButton();
    }
  };

  return (
    <>
      <Modal className="p-0" size="lg" isOpen={true} centered>
        <ModalBody className="document-modal-body p-0 rounded-0">
          <div className="document-modal-header d-flex justify-content-between px-3 py-2 bg-default">
            <div className="text-capitalize ml-auto mr-auto font-weight-bold">
              {' '}
              {'Issue ' + toTitleCase(props.documentTask.doc!.templateType!, '_') + ' to Employee'}
            </div>
            <div className="align-self-center" onClick={(): void => props.closeModal()}>
              <CloseIcon fillColour={'white'} />
            </div>
          </div>
          <div className="modal-file-attachment">
            <i className="fas fa-paperclip pr-2" />
            {attachmentName}
          </div>
          <div className="px-3 py-3 text-default">
            <p className="ml-3 p-2">How would you like to issue the letter to the employee?</p>
            <Select
              className="document-modal-select"
              value={state.selectedDeliveryMethod}
              onChange={onSelectDeliveryMethod}
              options={options}
            />
            {[renderCurrentPageOnModal(state.selectedDeliveryMethod.value)]}
          </div>
        </ModalBody>
        <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            className="btn btn-simple text-uppercase font-weight-light rounded-0"
            onClick={(): void => props.closeModal()}
          >
            CLOSE
          </Button>
          {state.selectedDeliveryMethod.value !== DocumentDeliveryMethod.PRINT_AND_DELIVER ? (
            <>{renderButtonForEmail(state.selectedDeliveryMethod.value)}</>
          ) : (
            <Button
              className="btn btn-bd-purple rounded-0 text-uppercase font-weight-normal"
              onClick={async (e: React.MouseEvent) => {
                setIsDownloadingOrSending(true);
                e.preventDefault();
                await props
                  .downloadDocument(props.documentTask.doc)
                  .then(async () => {
                    await printAndDeliver(props.documentTask)
                      .then(() => {
                        setIsDownloadingOrSending(false);
                        props.closeModal();
                      })
                      .catch(error => handleError(error));
                  })
                  .catch(() => {
                    setIsDownloadingOrSending(false);
                  });
              }}
            >
              {isDownloadingOrSending ? <i className="spinner-border spinner-border-sm" /> : 'Download Document'}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};
