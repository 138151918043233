import React, { useContext, useEffect, useState } from 'react';
import './organisationHierarchy.scss';
import { list, listActiveEmployeesByOrganisationId } from '../../utils/graphql-utils';
import { listUsers } from '../../graphql/queries';
import { Employee, User } from '../../models';
import { UserContext, UserContextProps } from '../../App';
import { useErrorHandler } from '../../utils/notification-utils';
//@ts-ignore
import Loader from '../../components/loader/Loader';

// export interface HBProps {}
//@ts-ignore
import { OrganisationalChart } from 'react-hierarchy-org-chart';
import { startCase } from 'lodash';
import './test.css';
import Input from 'reactstrap/lib/Input';
import hierarchyNodeConfig from './hierarchyConfig.json';
import InputGroup from 'reactstrap/lib/InputGroup';
import EmployeeHierarchyBar from '../../components/EmployeeHierarchyBar/EmployeeHierarchyBar.component';
import { toTitleCase } from '../../utils/string-utils';
import HierarchyErrorBoundary from '../../components/hierarchyErrorBoundary';

export interface CombinedEmployee extends Employee {
  user?: User;
}
export interface HBState {
  tree: Employee[] | null;
  error?: string;
  downloadingChart: boolean;
  config: {};
  highlightPostNumbers: number[];
}

export interface HBTree {
  id: number;
  person: {
    id: number;
    avatar?: any;
    department: string;
    name: string;
    title: string;
    totalReports: number;
    link?: string;
  };
  hasChild: boolean;
  hasParent: boolean;
  children?: HBTree[];
}

export const OrganisationHierarchy: React.FC = () => {
  const [state, setState] = useState<HBState>({
    tree: null,
    downloadingChart: false,
    config: {},
    highlightPostNumbers: [1],
  });

  const [selectedEmployee, setSelectedEmployee] = useState<CombinedEmployee | null>(null);
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const handleError = useErrorHandler();
  const [searchValue, setSearcValue] = useState('');
  const [dimensions] = useState({ height: window.innerHeight - 67, width: window.innerWidth - 260 });
  const [chart, setChart] = useState(null);
  const [loading, setloading] = useState(true);
  const [employees, setEmployees] = useState<CombinedEmployee[]>([]);
  const [search, toggleSearch] = useState(false);
  const [highligthIds, setHighligthIds] = useState<CombinedEmployee[]>([]);

  const [results, setResults] = useState<CombinedEmployee[]>([]);

  if (chart && searchValue === 'Make it explode') {
    //@ts-ignore
    chart.explodeOrContract(true);
  }

  const loadEmployees = async (): Promise<any[]> => {
    if (!currentUser?.organisationId) return [];
    else {
      const orgId = currentUser.organisationId;
      return new Promise<any[]>((resolve, reject) => {
        const variables = { filter: { organisationId: { eq: currentUser.organisationId } } };
        // gets all users
        list(listUsers, variables).then(res => {
          if (res.data && (res.data as any).listUsers) {
            const employeeUsers: User[] = (res.data as any).listUsers.items.filter(
              (user: User) => user.employee && user.active,
            );

            const employeeUserIds: string[] = employeeUsers
              .filter(el => el.employee)
              //@ts-ignore
              .map((user: User) => user.employee!.id);

            listActiveEmployeesByOrganisationId(orgId)
              .then(data => {
                const combinedData = data.map((el: Employee) => {
                  if (employeeUserIds.includes(el.id)) {
                    return {
                      ...el,
                      ...{ user: employeeUsers.filter((user: User) => el.id === user.employee?.id)[0] },
                    };
                  }
                  return el;
                });
                setEmployees(combinedData);
                resolve(
                  combinedData
                    .map((item: CombinedEmployee) => {
                      if (item.id !== item.directManagerID) {
                        return {
                          ...hierarchyNodeConfig,
                          nodeId: item.id,
                          parentNodeId: item.directManagerID === 'topOfReportingLine' ? null : item.directManagerID,
                          template: `<div> <div class="node_text_title" > ${
                            item.user ? '<i class="fas fa-user-cog"></i>' : ''
                          }  ${startCase(item.firstName)} ${startCase(item.lastName)}</div>
                            ${
                              item.user
                                ? `\n<div class="node_text">${(item.user.roles as string[])
                                    .map(item => toTitleCase(item, '_'))
                                    .join(', ')}</div>`
                                : ''
                            }\n<div class="node_text">${item.employeeNumber} - ${toTitleCase(
                            item.department?.name || 'No Department',
                            ' ',
                          )}</div></div>`,
                        };
                      }
                      return null;
                    })
                    .filter(e => e),
                );
              })
              .catch((error: Error) => {
                reject(error);
              });
          }
          //gets all employees
        });
      });
    }
  };

  const fetchTreeData = (): void => {
    setloading(true);

    loadEmployees()
      .then((employees: Employee[]) => {
        if (employees.length > 0) {
          //ErrorHandling
          //@ts-ignore
          if (employees.filter(el => !el.parentNodeId).length <= 0) {
            setState(oldState => ({ ...oldState, tree: [], error: 'No top of reporting line selected' }));
          } else {
            setState(oldState => ({ ...oldState, tree: employees }));
          }
        } else {
          setState(oldState => ({ ...oldState, tree: [], error: 'No employee' }));
        }

        setloading(false);
      })
      .catch(error => {
        handleError(error);
        setloading(false);
      });
  };

  useEffect(() => {
    if (!state.tree) {
      fetchTreeData();
    }
  }, []);

  const onNodeClick = (nodeId: string): void => {
    let childrenIds: CombinedEmployee[] = [];
    setSelectedEmployee(null);
    setloading(true);
    const employee = employees.filter(el => el.id === nodeId)[0];
    const locateChildren = (id: string): void => {
      const employeesChildren = employees.filter((el: Employee) => el.directManagerID === id);
      if (employeesChildren.length > 0) {
        employeesChildren.forEach(element => locateChildren(element.id));
      }
      childrenIds = [...childrenIds, ...employeesChildren];
    };

    if (employee) {
      const canAccess = employees.filter((el: Employee) => el.allocatedManagerID === nodeId);
      locateChildren(employee.id);
      setHighligthIds([...canAccess, ...childrenIds]);
      setSelectedEmployee(employee);
      setloading(false);
    }
  };

  const searchEmployee = (name: string): void => {
    let results: CombinedEmployee[] = [];
    if (employees) {
      const cleanedName = name.toLowerCase();
      results = employees.filter(employee => {
        return (
          employee.firstName.includes(cleanedName) ||
          employee.lastName.includes(cleanedName) ||
          employee.employeeNumber.includes(cleanedName)
        );
      });
    }
    setResults(results);
  };

  if (highligthIds.length && chart) {
    //@ts-ignore
    chart.explodeOrContract(highligthIds.length);
  }

  if (!loading && !state.error && state.tree && state.tree.length <= 1) {
    return (
      <div className="hierarchy-container">
        <span className="h2 text-justify text-default font-weight-normal" style={{ fontSize: '18px' }}>
          You do not have enough employees loaded, please proceed to the employees section and upload your organisation.
        </span>
      </div>
    );
  }

  return (
    <div className="hierarchy-container">
      {selectedEmployee && (
        //@ts-ignore
        <EmployeeHierarchyBar employee={selectedEmployee} refreshHierarchy={(): void => fetchTreeData()} />
      )}

      {state.tree && !state.error ? (
        <>
          <HierarchyErrorBoundary>
            <>
              <section className="toolbar_small">
                <div className="search-contianer">
                  <i onClick={() => toggleSearch(!search)} className="fa fa-search fa-2x search" aria-hidden="true"></i>
                  <InputGroup style={{ width: search ? 370 : 0 }} className="shadow no-border mb-2">
                    {search && (
                      <Input
                        autoComplete="off"
                        className="bg-grey h-25 w-100"
                        name="search"
                        value={searchValue}
                        onChange={e => {
                          searchEmployee(e.target.value);
                          setSearcValue(e.target.value);
                        }}
                        placeholder={'Search on name, last name, employee number'}
                        type="text"
                      />
                    )}
                  </InputGroup>
                </div>
                {search && results.length > 0 && (
                  <div style={{ width: '100%' }} className="result-container">
                    {results.map((el: Employee) => (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={(): void => {
                          //@ts-ignore
                          chart.locate(el.id);
                          searchEmployee('null');
                          setSearcValue(`${el.employeeNumber}`);
                        }}
                        key={el.id}
                      >
                        <p
                          style={{
                            color: '#BD7AE3',
                            fontWeight: 300,
                            textAlign: 'left',
                          }}
                          className="result_text"
                        >
                          {startCase(el.firstName)} {startCase(el.lastName)} {el.employeeNumber}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </section>
              <section className="legend_small">
                <p>Legend</p>
                <div className="legend_row">
                  <i className="fas fa-user-cog user_legend_icon"></i>
                  <p> - System User</p>
                </div>
                <div className="legend_row">
                  <i className="fas fa-square access_legend_icon"></i>
                  <p> - Selected user can access</p>
                </div>
                <div></div>
              </section>
            </>
            {!loading && (
              <OrganisationalChart
                width={dimensions.width}
                height={dimensions.height}
                highligthIds={highligthIds.map((el: Employee) => el.id)}
                higligthClassName="can-access"
                zoom={0.4}
                ref={(e: any): void => setChart(e)}
                onNodeClick={onNodeClick}
                data={state.tree}
              />
            )}
          </HierarchyErrorBoundary>
        </>
      ) : (
        <div className="hierarchy-container">
          <Loader />
          <div>
            {state.error && (
              <>
                <p>Your company`s employee data is not in the correct structure</p>
                {//@ts-ignore
                state.error && <p>{state.error}</p>}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
