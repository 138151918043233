import React from 'react';
import { WorkflowComponentProps } from '../../../../WorkflowContainer/workflow-utils';
import { FormConfigV2 } from '../../../../../configs/form-configs/form-config-types';
import { componentsAndFormsBySanction } from './execute-outcome-form-configs';
import { useErrorHandler } from '../../../../../utils/notification-utils';

export interface ExecuteOutcomeProps extends WorkflowComponentProps {
  sanction: string;
  additionalBucketKeys: string[];
  formConfig: FormConfigV2;
}

const InitialHearingExecuteOutcome: React.FC<WorkflowComponentProps> = props => {
  const sanction = props.data.caseData.overallSanction;
  const handleError = useErrorHandler();
  const getRelevantChairpersonsRuling = (): string[] => {
    const docs = props.data.caseData.initial_hearing_chairpersonsRulingDocuments;
    console.log(props.data.caseData)
    if (!docs) {
      handleError('No ruling found, please go back and check that ruling is uploaded')
      return []
    }else{
      return docs.map(d => d.key);
    }
  
  };

  // const reactComponentsBySanction: Record<string, React.FC<ExecuteOutcomeProps>> = {
  //   WRITTEN_WARNING: InitialHearingExecuteOutcomeWrittenWarning,
  //   FINAL_WRITTEN_WARNING: InitialHearingExecuteOutcomeWrittenWarning,
  //   WRITTEN_WARNING_WITH_PIP: InitialHearingExecuteOutcomeWrittenWarning,
  //   FINAL_WRITTEN_WARNING_WITH_PIP: InitialHearingExecuteOutcomeWrittenWarning,
  //   SUMMARY_DISMISSAL: InitialHearingExecuteOutcomeSummaryDismissal,
  //   DEMOTION: InitialHearingExecuteOutcomeDemotion,
  //   DISMISSAL_WITH_NOTICE: InitialHearingExecuteOutcomeDismissalWithNotice,
  //   DISMISSAL_WITH_NOTICE_PAY: InitialHearingExecuteOutcomeDismissalWithNotice,
  //   SUSPENSION_WITHOUT_PAY: InitialHearingExecuteOutcomeSuspensionWithoutPay,
  // };

  const getSanctionComponent = (sanction: string): JSX.Element | null => {
    if (!props.data.caseType) {
      throw new Error('Missing caseType');
    }
    const caseType = props.data.caseType;
    const config = componentsAndFormsBySanction[caseType][sanction];
    const component: React.FC<ExecuteOutcomeProps> = config.component;
    if (component) {
      return component({
        data: props.data,
        sanction: sanction,
        additionalBucketKeys: getRelevantChairpersonsRuling(),
        formConfig: config.formConfig,
      });
    } else {
      throw new Error('No matching component for sanction: ' + sanction);
    }
  };
  return getSanctionComponent(sanction);
};

export default InitialHearingExecuteOutcome;
