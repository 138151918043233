import React, { useContext, useState, useEffect } from 'react';
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import { Tooltip } from '../../../components/tooltips/Tooltip.component';
import FormField from '../../../forms/fields/FormField.component';
import {
  ErrorMessage,
  Field,
  FieldArrayRenderProps,
  FieldAttributes,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import AsyncSelect from 'react-select/async';
import {
  selectStyles,
  SelectType,
  ValueContainer,
} from '../../../components/reactSelect/ReactSelectComponents.component';
import { ValueType } from 'react-select';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import { UserContext, UserContextProps } from '../../../App';
import { listActiveEmployeesByOrganisationId } from '../../../utils/graphql-utils';
import { CloseIcon } from '../../../components/icon/Icon.component';
import PerformanceCounsellingHistoryAccordion, {
  SessionHistory,
} from '../../../components/PeformanceCounsellingHistory/PerformanceCounsellingHistoryAccordion';
import { CounsellingSession } from '../RecordCounselling/RecordCounselling';
import { EmailRecipient, EmailType } from '../../../utils/email-utils';
import { FormProps } from '../../WorkflowContainer/workflow-utils';
import { Employee } from '../../../models';
import { EmailPreviewModalv3 } from '../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { convertToFlowableVariables } from '../../../utils/flowable/flowable-utils';
import ApiDataContext from '../../../contexts';

const POOR_PERFORMANCE_PROCESSES = ['PERFORMANCE_IMPROVEMENT_PLAN', 'INCAPACITY_HEARING', 'OTHER'];

interface RecordAdditionalCounsellingFormState {
  newPerformanceShortfallModalIsOpen: boolean;
  counsellingHistoryModalIsOpen: boolean;
  counsellingSessionHistory: SessionHistory[];
  counsellingSessionsWarningModalIsOpen: boolean;
}

const initialStateValues: RecordAdditionalCounsellingFormState = {
  newPerformanceShortfallModalIsOpen: false,
  counsellingHistoryModalIsOpen: false,
  counsellingSessionHistory: [],
  counsellingSessionsWarningModalIsOpen: false,
};

let boundArrayHelpers: FieldArrayRenderProps;

const ScheduleAdditionalCounsellingSessionForm: React.FC<FormProps> = props => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const [state, setState] = useState(initialStateValues);
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const employeeListApiData = useContext(ApiDataContext);

  const handleChange = (value: { value: string; label: string }, fieldName: string): void => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data.map((employee: Employee) => {
      const emailAddresses = employee.emails!.map(item => item!.address);
      return {
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
        attendeeId: employee.id,
        attendeeEmailAddress: emailAddresses[0] ? emailAddresses[0] : 'noEmail@labourteq.co.za',
        attendeeName: employee.firstName + ' ' + employee.lastName,
      };
    });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  // const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
  //   if (currentUser?.organisationId) {
  //     return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
  //       const preparedData = prepareData(data);
  //       return !inputValue ? preparedData : filterItems(preparedData, inputValue);
  //     });
  //   }
  // };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId && typeof employeeListApiData.initialEmployeesPage === 'undefined') {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        employeeListApiData.employees = !inputValue ? preparedData : filterItems(preparedData, inputValue);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    } else {
      return !inputValue
        ? employeeListApiData.initialEmployeesPage
        : filterItems(employeeListApiData.initialEmployeesPage, inputValue);
    }
  };

  const getRecipients = (): EmailRecipient[] => {
    const recipients: EmailRecipient[] = [];
    values.counsellingSession.counsellingAttendees.forEach((item: any) => {
      if (item.attendeeEmailAddress && item.attendeeName)
        recipients.push({ emailAddress: item.attendeeEmailAddress, name: item.attendeeName });
    });
    return recipients;
  };

  useEffect(() => {
    if (boundArrayHelpers && values.counsellingSession && !values.counsellingSession.sessionCompleted) {
      boundArrayHelpers.push({ update: '', date: '' });
    }
  }, []);

  useEffect(() => {
    if (values.performanceCounsellingSessions && values.performanceCounsellingSessions.length) {
      const sessions = values.performanceCounsellingSessions.map((session: CounsellingSession) => {
        return {
          sessionId: session.sessionId,
          date: session.counsellingDate,
          time: session.counsellingTime,
          location: session.counsellingLocation,
          attendees: session.counsellingAttendees,
          documents: session.counsellingDocuments,
          summary: session.counsellingSummary,
          sessionCompleted: session.sessionCompleted,
        };
      });
      setState(oldState => ({ ...oldState, counsellingSessionHistory: sessions }));
    }
  }, []);

  useEffect(() => {
    if (
      values.additionalSessions &&
      state.counsellingSessionHistory.length >= 3 &&
      !state.counsellingSessionsWarningModalIsOpen
    ) {
      setState(oldState => ({
        ...oldState,
        counsellingSessionsWarningModalIsOpen: true,
      }));
    }
  }, [values.additionalSessions, state.counsellingSessionHistory]);

  return (
    <>
      <Form>
        <Modal className="p-0" size="md" isOpen={state.counsellingSessionsWarningModalIsOpen} centered>
          <ModalBody className="p-0 rounded-0">
            <div className="d-flex justify-content-between px-3 py-2 document-modal-header">
              <div className="text-capitalize ml-auto mr-auto font-weight-light">Additional Counselling Sessions</div>
              <div
                onClick={() => {
                  setState(oldState => ({ ...oldState, counsellingSessionsWarningModalIsOpen: false }));
                }}
                className="align-self-center"
              >
                <CloseIcon fillColour={'white'} />
              </div>
            </div>
            <div className="px-3 py-3">
              <span className="text-dark">
                You have already held a number of additional counselling sessions with this employee in an effort to
                assist and improve his/her performance. If no or insufficient progress has been made in addressing the
                performance shortfalls, it is advisable that you formalise the process. Consider either drafting a
                formal Performance Improvement Plan (PIP) or even initiating an incapacity poor performance hearing, if
                the shortfalls are serious in nature , and/or habitual in nature, and/or pose a material risk to the
                employer. There is no hard or fast rule in law as to the number of counselling sessions required or the
                time periods allowed for improvement. You do not need to accept continued or habitual poor performance
                for an undue period of time. Consider progressing the process and liaise with HR or your Executive
                management for guidance, if required.
              </span>
            </div>
            <div className="d-flex ml-auto mr-auto justify-content-center">
              <ButtonWithIcons
                title={'Close'}
                handleClick={(): void =>
                  setState(oldState => ({
                    ...oldState,
                    counsellingSessionsWarningModalIsOpen: false,
                  }))
                }
              />
            </div>
          </ModalBody>
        </Modal>
        <Row className="mb-3">
          <Col>
            <Label className="text-default d-block mt-2">
              Are additional Sessions required?
              <span className="align-self-center pl-2 pointer">
                <Tooltip
                  id="additionalSessionsRequired"
                  title="Are additional Sessions required?"
                  jsx={
                    <>
                      {state.counsellingSessionHistory.length >= 3 ? (
                        <>
                          You have already held a number of additional counselling sessions with this employee in an
                          effort to assist and improve his/her performance. If no or insufficient progress has been made
                          in addressing the performance shortfalls, it is advisable that you formalise the process.
                          Consider either drafting a formal Performance Improvement Plan (PIP) or even initiating an
                          incapacity poor performance hearing, if the shortfalls are serious in nature , and/or habitual
                          in nature, and/or pose a material risk to the employer. There is no hard or fast rule in law
                          as to the number of counselling sessions required or the time periods allowed for improvement.
                          You do not need to accept continued or habitual poor performance for an undue period of time.
                          Consider progressing the process and liaise with HR or your Executive management for guidance,
                          if required.
                        </>
                      ) : (
                        <>
                          You need to provide an appropriate opportunity for the employee to improve and a reasonable
                          time period should be allowed between performance counselling discussions, dependent on
                          deliverables to be measured. Ensure that you follow up and provide regular feedback. If the
                          employee improves, acknowledge the progress and provide further encouragement. If he/she is
                          still struggling, continue to counsel. During the additional counselling, discuss instances of
                          ongoing performance shortfalls. If applicable, new performance shortfalls can be identified
                          and must then be shared. You must provide the employee with an opportunity to respond in these
                          sessions.
                          <br />
                          <br />
                          However, you do not need to tolerate continued or habitual poor performance for an undue
                          period of time. If there is no or insufficient improvement, you need to begin a formal process
                          which may include drafting a Performance Improvement Plan (PIP) or even initiating an
                          Incapacity poor performance hearing. It is your responsibility as a manager to progress the
                          incapacity performance process so that the employer and colleagues are not unduly burdened
                          during this process.
                          <br />
                          <br />
                          LabourTeq allows you to properly document all additional counselling sessions so that proof of
                          actions taken is available should the matter need to be escalated to a formal process.
                          Diligently document all sessions held. Share your discussion points and list of corrective
                          actions required with the employee after each session.
                        </>
                      )}
                    </>
                  }
                />
              </span>
            </Label>
            <div className="d-flex">
              <div className="mr-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={values.additionalSessions}
                      onChange={(): void => {
                        setFieldValue('additionalSessions', true);
                        if (state.counsellingSessionHistory.length >= 3)
                          setState(oldState => ({
                            ...oldState,
                            counsellingSessionsWarningModalIsOpen: true,
                          }));
                        // setFieldValue('counsellingHavePerformanceConcernsBeenAddressed', false);
                      }}
                    />
                    <span className="form-check-sign">
                      <span className="check text-muted text-uppercase">yes</span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
              <div className="ml-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={!values.additionalSessions}
                      onChange={(): void => {
                        setFieldValue('additionalSessions', false);
                      }}
                    />
                    <span className="form-check-sign">
                      <span className="check text-default text-muted text-uppercase">no</span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <ButtonWithIcons
              title={'View Performance Counselling History'}
              buttonType={'btn-bd-purple'}
              handleClick={(): void => setState(oldState => ({ ...oldState, counsellingHistoryModalIsOpen: true }))}
            />
          </Col>
        </Row>
        {values.additionalSessions && (
          <>
            <h4 className="text-default text-capitalize font-weight-bold mt-2">
              Invite Employee to additional discussion?
            </h4>
            <Row className="mb-3">
              <Col>
                <Label className="text-default d-block mt-2">
                  Compile invite to discussion?
                  <span className="align-self-center pl-2 pointer">
                    <Tooltip
                      id="compileInviteToDiscussion"
                      title="ADDITIONAL PERFORMANCE COUNSELLING "
                      jsx={
                        <>
                          Are additional counselling sessions required?
                          <br />
                          You need to provide an appropriate opportunity for the employee to improve and a reasonable
                          time period should be allowed between performance counselling discussions, dependent on
                          deliverables to be measured. Ensure that you follow up and provide regular feedback. If the
                          employee improves, acknowledge the progress and provide further encouragement. If he/she is
                          still struggling, continue to counsel. During the additional counselling, discuss instances of
                          ongoing performance shortfalls. If applicable, new performance shortfalls can be identified
                          and must then be shared. You must provide the employee with an opportunity to respond in these
                          sessions. However, you do not need to tolerate continued or habitual poor performance for an
                          undue period of time. If there is no or insufficient improvement, you need to begin a formal
                          process which may include drafting a Performance Improvement Plan (PIP) or even initiating an
                          Incapacity poor performance hearing. It is your responsibility as a manager to progress the
                          incapacity performance process so that the employer and colleagues are not unduly burdened
                          during this process. LabourTeq allows you to properly document all additional counselling
                          sessions so that proof of actions taken is available should the matter need to be escalated to
                          a formal process. Diligently document all sessions held. Share your discussion points and list
                          of corrective actions required with the employee after each session.
                          <br />
                          <br />
                          Compile invite for discussion
                          <br />
                          Use this functionality to set up additional counselling meetings with the employee ahead of
                          time, allowing him/her time to prepare feedback on progress made, and gather supporting
                          information, if relevant.
                        </>
                      }
                    />
                  </span>
                </Label>
                <div className="d-flex">
                  <div className="mr-4">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={values.compileInvite}
                          onChange={(): void => setFieldValue('compileInvite', true)}
                        />
                        <span className="form-check-sign">
                          <span className="check text-muted text-uppercase">yes</span>
                        </span>
                      </Label>
                    </FormGroup>
                  </div>
                  <div className="ml-4">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={!values.compileInvite}
                          onChange={(): void => setFieldValue('compileInvite', false)}
                        />
                        <span className="form-check-sign">
                          <span className="check text-default text-muted text-uppercase">no</span>
                        </span>
                      </Label>
                    </FormGroup>
                  </div>
                </div>
              </Col>
            </Row>
            {values.compileInvite && (
              <>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label for="counsellingDate" className="text-default text-capitalize">
                        Counselling Date
                      </Label>
                      <FormField
                        name={`counsellingSession.counsellingDate`}
                        placeholder="Counselling Date"
                        type="date"
                      />
                      <span className="text-danger">
                        <ErrorMessage className="text-danger" name={`counsellingSession.counsellingDate`} />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="`counsellingSession.counsellingTime`" className="text-default text-capitalize">
                        Counselling Time*
                      </Label>
                      <FormField
                        type={'time'}
                        placeholder="Counselling Time"
                        name={`counsellingSession.counsellingTime`}
                      />
                      <span className="text-danger">
                        <ErrorMessage className="text-danger" name={`counsellingSession.counsellingTime`} />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="counsellingSession.counsellingLocation" className="text-default text-capitalize">
                        Location
                      </Label>
                      <FormField name={`counsellingSession.counsellingLocation`} placeholder="Location" type="text" />
                      <span className="text-danger">
                        <ErrorMessage className="text-danger" name={`counsellingSession.counsellingLocation`} />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="counsellingSession.counsellingAttendees" className="text-default">
                        Attendees (other than employee)
                      </Label>
                      <Field name={`counsellingSession.counsellingAttendees`}>
                        {({ field }: FieldAttributes<FormikValues>) => (
                          <AsyncSelect
                            {...field}
                            placeholder="Select Attendees"
                            cacheOptions
                            defaultOptions
                            loadOptions={loadOptions}
                            closeMenuOnSelect={false}
                            isClearable={false}
                            value={
                              values.employeeDiscussionAttendees &&
                              values.employeeDiscussionAttendees.length &&
                              values.employeeDiscussionAttendees.map((attendee: { [key: string]: string }) => ({
                                label: attendee.attendeeName,
                                value: attendee.attendeeId,
                                attendeeId: attendee.attendeeId,
                                attendeeEmailAddress: attendee.attendeeEmailAddress
                                  ? attendee.attendeeEmailAddress
                                  : 'noEmail@labourteq.co.za',
                                attendeeName: attendee.attendeeName,
                              }))
                            }
                            isMulti
                            styles={selectStyles}
                            onChange={(value: ValueType<any>) =>
                              handleChange(value, `counsellingSession.counsellingAttendees`)
                            }
                            components={{ ValueContainer }}
                          />
                        )}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage className="text-danger" name={`counsellingSession.counsellingAttendees`} />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <EmailPreviewModalv3
                      buttonText={'Send Email/Invite to employee '}
                      disabled={
                        !(
                          props.data.employeeWorkEmailAddresses.length &&
                          values.counsellingSession?.counsellingDate &&
                          values.counsellingSession?.counsellingTime &&
                          values.counsellingSession?.counsellingAttendees
                        )
                      }
                      formValues={values.counsellingSession}
                      emailType={EmailType.POOR_PERFORMANCE_COUNSELLING_EMPLOYEE}
                      masterProcessInstanceId={props.data.masterProcessInstanceId}
                      processInstanceId={props.data.processInstanceId}
                      currentUserId={currentUser?.id}
                      getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
                    />
                  </Col>
                  <Col md={4}>
                    <EmailPreviewModalv3
                      buttonText={'Send Email/Invite to Attendees'}
                      disabled={
                        !(
                          values.counsellingSession?.counsellingDate &&
                          values.counsellingSession?.counsellingTime &&
                          values.counsellingSession?.counsellingAttendees
                        )
                      }
                      formValues={values.counsellingSession}
                      emailType={EmailType.POOR_PERFORMANCE_COUNSELLING_ATTENDEES}
                      masterProcessInstanceId={props.data.masterProcessInstanceId}
                      processInstanceId={props.data.processInstanceId}
                      currentUserId={currentUser?.id}
                      getRecipients={getRecipients}
                      getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
        {!values.additionalSessions && (
          <>
            <Row className="mb-3">
              <Col>
                <Label className="text-default d-block mt-2">Have performance concerns been addressed?</Label>
                <div className="d-flex">
                  <div className="mr-4">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={values.counsellingHavePerformanceConcernsBeenAddressed}
                          onChange={(): void => setFieldValue('counsellingHavePerformanceConcernsBeenAddressed', true)}
                        />
                        <span className="form-check-sign">
                          <span className="check text-muted text-uppercase">yes</span>
                        </span>
                      </Label>
                    </FormGroup>
                  </div>
                  <div className="ml-4">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={!values.counsellingHavePerformanceConcernsBeenAddressed}
                          onChange={(): void => setFieldValue('counsellingHavePerformanceConcernsBeenAddressed', false)}
                        />
                        <span className="form-check-sign">
                          <span className="check text-default text-muted text-uppercase">no</span>
                        </span>
                      </Label>
                    </FormGroup>
                  </div>
                </div>
              </Col>
            </Row>
            {!values.counsellingHavePerformanceConcernsBeenAddressed && (
              <>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="wayForward" className="text-default text-capitalize">
                        Select the way forward
                      </Label>
                      <FormField
                        type={'select'}
                        placeholder={'Please select'}
                        name={'furtherActionAfterCounselling'}
                        selectOptions={POOR_PERFORMANCE_PROCESSES}
                      />
                      <span className="text-danger">
                        <ErrorMessage className="text-danger" name={'furtherActionAfterCounselling'} />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <FormGroup>
                      <Label for="wayForward" className="text-default">
                        Provide reason/s for decision*
                      </Label>
                      <FormField
                        type={'textarea'}
                        placeholder={'Motivate for action taken'}
                        name={'reasonForFurtherActionAfterCounselling'}
                        selectOptions={POOR_PERFORMANCE_PROCESSES}
                      />
                      <span className="text-danger">
                        <ErrorMessage className="text-danger" name={'reasonForFurtherActionAfterCounselling'} />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Form>
      <Modal isOpen={state.counsellingHistoryModalIsOpen} size={'lg'} centered>
        <ModalBody>
          <div
            onClick={(): void => setState(oldState => ({ ...oldState, counsellingHistoryModalIsOpen: false }))}
            className="d-flex justify-content-between"
          >
            <div className="text-default h4">Performance Counselling Sessions</div>
            <div>
              <CloseIcon height={'15'} width={'15'} fillColour="#8461C9" />
            </div>
          </div>
          {state.counsellingSessionHistory.length &&
            state.counsellingSessionHistory.map((session: SessionHistory, index: number) => (
              <PerformanceCounsellingHistoryAccordion key={index} index={index} data={session} />
            ))}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ScheduleAdditionalCounsellingSessionForm;
