import { CaseData } from '../../WorkflowContainer/workflow-utils';
import { Employee } from '../../../models';
import { UserDetails } from '../../../App';
import { DocumentJSONContent } from '../DocumentEditor';
import { getCommonFieldValuesFromCaseData } from '../common-document-utils';
import {
  prepareFieldValuesMisconduct,
  summaryDismissalLetterFields,
  demotionLetterFields,
  appealOutcomeLetterFields,
  noticeOfHearingFields,
  dismissalWithNoticeLetterFields,
  noticeOfAppealHearingFields,
  suspensionLetterFields,
  suspensionWithoutPayLetterFields,
  warningLetterDiscussionFields,
  warningLetterHearingFields,
} from './prepareFieldValues';
import { TemplateType } from '../../../API';

const getFieldValuesFromCaseData = (
  caseData: CaseData,
  employee: Employee,
  author: UserDetails,
  fieldNames: string[],
): DocumentJSONContent => {
  const values = prepareFieldValuesMisconduct(caseData);
  const documentContent: DocumentJSONContent = {};
  fieldNames.forEach((field: string) => {
    documentContent[field] = values[field];
  });

  const commonValues = getCommonFieldValuesFromCaseData(caseData, employee, author);
  return { ...commonValues, ...documentContent };
};

const getRequiredFieldsByTemplateType = (
  templateType: TemplateType,
  caseData: CaseData,
  employee: Employee,
  author: UserDetails,
): DocumentJSONContent | null => {
  const templateTypeMap: { [key: string]: string[] | null } = {
    [TemplateType.SUSPENSION_PENDING_INVESTIGATION_LETTER]: suspensionLetterFields,
    // [TemplateType.SUSPENSION_PENDING_DISCUSSION_LETTER]: suspensionLetterFields,
    [TemplateType.SUSPENSION_PENDING_HEARING_LETTER]: suspensionLetterFields,
    [TemplateType.SUSPENSION_WITHOUT_PAY_LETTER]: suspensionWithoutPayLetterFields,
    [TemplateType.WRITTEN_WARNING_DISCUSSION]: warningLetterDiscussionFields,
    [TemplateType.VERBAL_WARNING_DISCUSSION]: warningLetterDiscussionFields,
    [TemplateType.FINAL_WRITTEN_WARNING_DISCUSSION]: warningLetterDiscussionFields,
    [TemplateType.WRITTEN_WARNING_HEARING]: warningLetterHearingFields,
    [TemplateType.FINAL_WRITTEN_WARNING_HEARING]: warningLetterHearingFields,
    [TemplateType.NOTICE_OF_HEARING_MISCONDUCT]: noticeOfHearingFields,
    [TemplateType.NOTICE_OF_HEARING_MISCONDUCT_APPEAL]: noticeOfAppealHearingFields,
    [TemplateType.SUMMARY_DISMISSAL_LETTER_MISCONDUCT]: summaryDismissalLetterFields,
    [TemplateType.DISMISSAL_WITH_NOTICE_LETTER]: dismissalWithNoticeLetterFields,
    [TemplateType.DEMOTION_LETTER_MISCONDUCT]: demotionLetterFields,
    [TemplateType.HEARING_OUTCOME_MISCONDUCT_APPEAL]: appealOutcomeLetterFields,
    default: null,
  };

  const fieldNames = templateTypeMap[templateType] || templateTypeMap['default'];
  if (fieldNames) {
    return getFieldValuesFromCaseData(caseData, employee, author, fieldNames);
  } else {
    return null;
  }
};

export default class MisconductDocumentUtils {
  public static getRequiredFields(
    templateType: TemplateType,
    caseData: CaseData,
    employee: Employee,
    author: UserDetails,
  ): DocumentJSONContent | null {
    return getRequiredFieldsByTemplateType(templateType, caseData, employee, author);
  }
}
