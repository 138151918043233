import { CaseType, TemplateType } from '../../API';
import { UserDetails } from '../../App';
import { Employee, TemplateDocumentVersion } from '../../models';
import { CaseData } from '../WorkflowContainer/workflow-utils';
import { DocumentJSONContent, DocumentWithContent } from './DocumentEditor';
import PoorPerformanceDocumentUtils from './poorPerformanceDocumentUtils/poor-performance-document-utils';
import MisconductDocumentUtils from './misconductDocumentUtils/misconduct-document-utils';
import { FlowableVariable } from '../../utils/flowable/flowable-types';
import { AxiosResponse } from 'axios';
import { post } from '../../utils/api-utils';

export const startNewDocumentFlow = async (config: {
  processInstanceId: string;
  variables: FlowableVariable[];
  userId: string;
}): Promise<AxiosResponse> => {
  return post('/start-generic-document-flow', config);
};

const getRequiredFields = (
  templateType: TemplateType,
  caseData: CaseData,
  employee: Employee,
  author: UserDetails,
  caseType: CaseType,
): DocumentJSONContent | null => {
  const fieldsFunctionMap: {
    [key: string]: (
      templateType: TemplateType,
      caseData: CaseData,
      employee: Employee,
      author: UserDetails,
    ) => DocumentJSONContent | null;
  } = {
    [CaseType.MISCONDUCT]: MisconductDocumentUtils.getRequiredFields,
    [CaseType.POOR_PERFORMANCE]: PoorPerformanceDocumentUtils.getRequiredFields,
    default: () => null,
  };
  const fieldsFunction = fieldsFunctionMap[caseType] || fieldsFunctionMap['default'];
  return fieldsFunction(templateType, caseData, employee, author);
};

export const getInputFields = (
  templateType: TemplateType,
  caseData: CaseData,
  employee: Employee,
  author: UserDetails,
  caseType: CaseType,
): DocumentJSONContent | null => {
  return getRequiredFields(templateType, caseData, employee, author, caseType);
};

export const generateNewDocument = (
  templateType: TemplateType,
  masterProcessInstanceId: string,
  inputFields: {},
  organisationID: string,
): DocumentWithContent => {
  const document = new TemplateDocumentVersion({
    processInstanceId: masterProcessInstanceId,
    templateType: templateType,
    version: 0,
    organisationID: organisationID,
  });

  return {
    document: document,
    jsonContent: inputFields,
  };
};
