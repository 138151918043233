import React, { useState, useEffect } from 'react';
//@ts-ignore
const IdleTimer = props => {
  //@ts-ignore
  const { idleTime, onIdleReached } = props;
  const [interval, setIntervalObject] = useState(null);
  const [active, setActive] = useState(true);

  const updateExpiredTime = () => {
    localStorage.setItem('LBTQ_expiredTime', Date.now() + idleTime);
  };

  const onUnmounted = () => {
    console.log('clear');
    window.removeEventListener('mousemove', () => updateInterval());
    window.removeEventListener('scroll', () => updateInterval());
    window.removeEventListener('keydown', () => updateInterval());
    //@ts-ignore
    clearInterval(interval);
  };

  const updateInterval = () => {
    updateExpiredTime();
    if (interval) {
      //@ts-ignore
      clearInterval(interval);
    }
    const newInterval = setInterval(() => {
      //@ts-ignore
      const expiredTime = parseInt(localStorage.getItem('LBTQ_expiredTime'), 10);
      if (expiredTime < Date.now()) {
        if (onIdleReached && active) {
          onIdleReached();
          onUnmounted();
          setActive(false);
        }
      }
      //@ts-ignore
    }, 1000);
    //@ts-ignore
    setIntervalObject(newInterval);
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateInterval);
    window.addEventListener('scroll', updateInterval);
    window.addEventListener('keydown', updateInterval);

    return () => {
      onUnmounted();
    };
  }, []);

  return <div />;
};

export default IdleTimer;
