import { CaseType } from '../API';
import { BucketFile } from '../models';
import { useEffect, useState } from 'react';
import { getFlowableCasesForEmployeeCaseTable, getPastCasesFromDynamoDB } from '../components/tables/case-table-utils';

export interface CaseTableData {
  id: string;
  employeeName: string;
  employeeId: string;
  caseNumber: string;
  caseStatus: string;
  sanction: string;
  expiryDate: string;
  description: string;
  frequency: string;
  caseType: string;
  caseFiles?: BucketFile[];
  showDisputeButton?: boolean;
  history?: { [key: string]: string };
  isCaseClosed?: boolean;
  isDisputeReferred?: boolean;
  isSuspensionActive?: boolean;
}

export const HEARING_TABS = [
  'Step 1',
  'Step 2',
  'Step 3',
  'Step 4',
  'Step 5',
  'Step 6',
  'Step 7',
  'Step 8',
  'Step 9',
  'Step 10',
];

export const useCaseDataForHistoryTable = (props: {
  employeeId: string;
  organisationId: string;
  caseType?: CaseType;
}): { data: { systemCases: CaseTableData[]; pastCases: CaseTableData[] }; loading: boolean } => {
  const [caseData, setCaseData] = useState<CaseTableData[]>([]);
  const [pastCaseData, setPastCaseData] = useState<CaseTableData[]>([]);
  const [loading, setLoading] = useState(false);
  const { organisationId, employeeId, caseType } = props;

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    const caseArrays = await Promise.all([
      getFlowableCasesForEmployeeCaseTable(employeeId, caseType),
      getPastCasesFromDynamoDB(employeeId, caseType),
    ]);

    const systemCases = caseArrays[0].sort((a, b) => {
      const textA = a.caseNumber.toUpperCase();
      const textB = b.caseNumber.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    setCaseData(systemCases);
    setPastCaseData(caseArrays[1]);
  };

  useEffect(() => {
    if (employeeId && organisationId) {
      fetchData().finally(() => setLoading(false));
    } else {
      setCaseData([]);
      setPastCaseData([]);
      setLoading(false);
    }
  }, []);

  return { data: { systemCases: caseData, pastCases: pastCaseData }, loading };
};
