import React from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { default as CustomButton } from '../buttons/ButtonWIthIcons.component';
import { ClipIcon, CloseIcon } from '../icon/Icon.component';
import { EmailRecipient } from '../../utils/email-utils';

interface EmailPreviewProps {
  sendStatus?: boolean;
  modal?: boolean;
  subject?: string;
  toAddress?: EmailRecipient[];
  fromAdress?: string;
  previewContent?: string;
  sendFunction?: () => void;
  toggleModal?: (item?: any) => void;
  attachmentName?: string;
}

const EmailPreviewModal: React.FC<EmailPreviewProps> = (props: EmailPreviewProps) => {
  const {
    sendStatus,
    sendFunction,
    modal,
    toggleModal,
    attachmentName,
    toAddress,
    previewContent,
    fromAdress,
    subject,
  } = props;

  const preparePreviewEmailAddresses = (): EmailRecipient[] | undefined => {
    return toAddress
      ?.filter(item => item)
      .filter(function({ emailAddress }) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return !this.has(emailAddress) && this.add(emailAddress);
      }, new Set());
  };

  return (
    <Modal
      className="p-0 email-modal"
      size="lg"
      isOpen={modal}
      toggle={toggleModal}
      backdrop="static"
      fade={true}
      centered
    >
      {sendStatus ? (
        <div className="py-4 px-4">
          <Spinner type="grow" color="primary" />
          <p>Sending</p>
        </div>
      ) : (
        <div style={{ width: '100vw !important' }}>
          <div className="d-flex justify-content-between py-2 px-5">
            <CustomButton
              title={sendStatus ? 'Sending...' : 'Send'}
              buttonType="btn-bd-purple py-2 px-0 mx-0"
              handleClick={(): void => sendFunction && sendFunction()}
              style={{ height: '50px' }}
              disabled={sendStatus}
            />
            <span className="text-dark align-self-center d-block font-weight-bold">Compose Message</span>
            <span onClick={toggleModal} className="d-block align-self-center pointer p-2 ml-1">
              <CloseIcon />
            </span>
          </div>
          {attachmentName && (
            <div
              className="d-flex px-5 border-top-4 border-dark "
              style={{
                backgroundColor: '#ECEBEB',
              }}
            >
              <span className="text-muted mr-2">
                <ClipIcon />
              </span>
              <span className="text-muted">{attachmentName}</span>
            </div>
          )}
          <div className="px-5 mw-100">
            <span
              className="d-flex d-inline-block"
              style={{
                borderBottom: '0.1em solid rgb(206, 206, 206)',
                paddingBottom: '1em',
                paddingTop: '1em',
              }}
            >
              <span className="text-muted">To: </span>
              <span className="text-dark pl-2">
                {preparePreviewEmailAddresses()!.map((address, index) => (
                  <span key={index}>
                    {address.emailAddress}
                    {index !== toAddress!.length - 1 && ', '}
                  </span>
                ))}
              </span>
            </span>
          </div>
          <div className="px-5">
            <span
              className="d-flex d-inline-block"
              style={{
                borderBottom: '0.1em solid rgb(206, 206, 206)',
                paddingBottom: '1em',
                paddingTop: '1em',
              }}
            >
              <span className="text-muted">From: </span>
              <span className="text-dark pl-2">{fromAdress}</span>
            </span>
          </div>
          <div className="px-5">
            <span
              className="d-flex d-inline-block"
              style={{
                borderBottom: '0.1em solid rgb(206, 206, 206)',
                paddingBottom: '1em',
                paddingTop: '1em',
              }}
            >
              <span className="text-muted">Subject: </span>
              <span className="text-dark pl-2">Confidential: {subject}</span>
            </span>
          </div>
        </div>
      )}
      <ModalBody className="p-0 rounded-0" style={{ height: '50vh', overflowY: 'auto' }}>
        {previewContent && (
          <div dangerouslySetInnerHTML={{ __html: previewContent }} className="p-0 m-0" style={{ height: '100%' }} />
        )}
      </ModalBody>
    </Modal>
  );
};

export default EmailPreviewModal;
