import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { ErrorMessage, Field, FieldAttributes, FormikProps, FormikValues, useFormikContext } from 'formik';
import FormField from '../../../forms/fields/FormField.component';
import { EmailAddressType } from '../../../API';
import AsyncSelect from 'react-select/async';
import Select, { ValueType } from 'react-select';
import {
  selectStyles,
  SelectType,
  ValueContainer,
} from '../../../components/reactSelect/ReactSelectComponents.component';
import { UserContext, UserContextProps } from '../../../App';
import { Employee, UserRole } from '../../../models';
import { toTitleCase } from '../../../utils/string-utils';
import { startCase } from 'lodash';
import { allowUserToPerformOperation } from '../../../utils/permissions-utils';
import { listActiveEmployeesByOrganisationId } from '../../../utils/graphql-utils';
import ApiDataContext from '../../../contexts';

const CreateUserForm: React.FC = () => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const [roleOptions, setRoleOptions] = useState<{ label: string; value: string }[]>();

  const employeeListApiData = useContext(ApiDataContext);

  const filterItems = (data: SelectType[] | undefined, inputValue: string | null): SelectType[] => {
    const filteredData = data!.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  const getEmployeeEmail = (employee: Employee): string | undefined => {
    const emailArray = employee.emails!.filter(email => {
      return email!.emailAddressType === EmailAddressType.WORK;
    });
    if (!emailArray.length) {
      return undefined;
    }
    //@ts-ignore
    return emailArray[0].address;
  };

  const prepareData = (data: Employee[]): SelectType[] | undefined => {
    if (!data.length) return;

    return data
      .filter((employee: Employee) => {
        return employee.emails.length !== 0;
      })
      .map((employee: Employee) => {
        return {
          label: employee.firstName + ' ' + employee.lastName,
          value: employee.id,
          id: employee.id,
          firstName: employee.firstName,
          lastName: employee.lastName,
          emailAddress: getEmployeeEmail(employee),
        };
      })
      .filter(el => el.emailAddress);
  };

  const handleChangeEmployee = (value: { [key: string]: string }): void => {
    setFieldValue(`employee`, value);
    if (!values.emailAddress && value && value.emailAddress) {
      setFieldValue('emailAddress', value && value.emailAddress);
    }
  };

  // const loadEmployees = async (inputValue: string | null): Promise<SelectType[] | void> => {
  //   if (currentUser && currentUser.organisationId) {
  //     return await listActiveEmployeesByOrganisationId(currentUser.organisationId)
  //       .then((data: Employee[]) => {
  //         const preparedData = prepareData(data);

  //         return !inputValue ? preparedData : filterItems(preparedData, inputValue);
  //       })
  //       .catch(error => console.error(error));
  //   }
  // };

  const loadEmployees = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId && typeof employeeListApiData.initialEmployeesPage === 'undefined') {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        employeeListApiData.employees = !inputValue ? preparedData : filterItems(preparedData, inputValue);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    } else {
      return !inputValue
        ? employeeListApiData.initialEmployeesPage
        : filterItems(employeeListApiData.initialEmployeesPage, inputValue);
    }
  };

  const getRoles = (): { label: string; value: string }[] => {
    return Object.keys(UserRole)
      .filter(item => item !== UserRole.CASE_USER)
      .map((item: string) => {
        return {
          label: startCase(item),
          value: item,
        };
      });
  };

  useEffect(() => {
    if (values.employee) {
      setFieldValue('firstName', values.employee.firstName);
      setFieldValue('lastName', values.employee.lastName);
      setFieldValue('emailAddress', values?.employee?.emailAddress);
    }
    if (!roleOptions) {
      const roles: { label: string; value: string }[] = getRoles();
      setRoleOptions(roles);
      if (allowUserToPerformOperation(currentUser, [UserRole.SUPER_ADMIN])) {
        setRoleOptions(roles);
      } else if (allowUserToPerformOperation(currentUser, [UserRole.COMPANY_ADMIN])) {
        setRoleOptions(roles.filter(role => role.value !== UserRole.SUPER_ADMIN));
      }
    }
  }, [values.employee, roleOptions]);

  return (
    <>
      <h4 className="text-default mt-2 font-weight-bold">User Information</h4>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="employee" className="text-default">
              Employee
            </Label>
            <AsyncSelect
              placeholder="Select Employee"
              cacheOptions
              defaultOptions
              defaultValue={null}
              value={{
                label: values.employee ? values.employee.firstName + ' ' + values.employee.lastName : '',
                value: values.employee ? values.employee.id : '',
              }}
              loadOptions={loadEmployees}
              styles={selectStyles}
              onChange={(value: ValueType<any>): void => handleChangeEmployee(value)}
              components={{ ValueContainer }}
              isClearable
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="placeOfWork" />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="firstName" className="text-default">
              First Name*
            </Label>
            <FormField type="text" className="text-capitalize" placeholder="First Name" name="firstName" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="firstName" />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="lastName" className="text-default">
              Last Name*
            </Label>
            <FormField type="text" className="text-capitalize" placeholder="Last Name" name="lastName" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="lastName" />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="emailAddress" className="text-default">
              Email Address*
            </Label>
            <FormField type="text" placeholder="Email Address" name="emailAddress" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="emailAddress" />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="roles" className="text-default">
              Roles*
            </Label>
            <Field name="roles">
              {({ field }: FieldAttributes<FormikValues>) => (
                <Select
                  placeholder="Select Category"
                  options={roleOptions ? roleOptions : []}
                  closeMenuOnSelect={false}
                  isMulti
                  styles={selectStyles}
                  onChange={(value: ValueType<any>): void => {
                    setFieldValue(
                      'roles',
                      value ? value.map((item: { label: string; value: string }) => item.value) : [],
                    );
                  }}
                  components={{ ValueContainer }}
                  isDisabled={false}
                  value={
                    values?.roles
                      ? values.roles.map((item: string) => {
                          return { label: toTitleCase(item, '_'), value: item };
                        })
                      : []
                  }
                />
              )}
            </Field>
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="role" />
            </span>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default CreateUserForm;
