import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ButtonGroup,
  ButtonProps,
  ButtonToolbar,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table as BootStrapTable,
  TableProps as BootStrapTableProps,
} from 'reactstrap';

export type RowData = { [key: string]: string | number | boolean | null | undefined | object };
export interface TableProps extends BootStrapTableProps {
  data?: RowData[];
  columns: ColumnHeading[];
  /**
   * Where to a row clicked should navigate. This will be prepended with the id
   * for example if `linkPrefix='employee'` the url it will navigate to is `/employee/{EMPLOYEE_ID_OF_ROW}`
   */
  linkPrefix: string;
}
export type ColumnHeading = ColumnHeadingData | ColumnHeadingAction;
interface ColumnHeadingData {
  label: string;
  key: string;
  type?: 'data';
}
export interface ColumnHeadingAction {
  label: string;
  type: 'action';
  actions: Action[];
}
interface Action extends ButtonProps {
  label: string;
  func: (data: RowData) => void;
}

const DynamicTableLinkable: React.FC<TableProps> = (props: TableProps) => {
  const { data = [], columns, linkPrefix, ...bootstrapProps } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [pageSize] = useState(50);
  const [currentPageSize, setCurrentPageSize] = useState(data.length < pageSize ? data.length : pageSize);

  const handleFirstPage = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    setCurrentPage(0);
    setCurrentPageSize(pageSize);
  };

  const handleNextPage = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (currentPage >= 0) {
      setCurrentPage(currentPage + 1);
      if (currentPageSize + pageSize > data.length) setCurrentPageSize(data.length);
      else setCurrentPageSize(currentPageSize + pageSize);
    }
  };

  const handlePreviousPage = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    setCurrentPage(currentPage - 1);
    if (currentPageSize - pageSize < pageSize) setCurrentPageSize(pageSize);
    else setCurrentPageSize(currentPageSize - pageSize);
  };

  const handleLastPage = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    setCurrentPage(lastPage - 1);
    setCurrentPageSize(data.length);
  };

  function renderHeadings(): JSX.Element {
    const renderHeading = (column: ColumnHeading, headingIndex: number): JSX.Element => (
      <th key={`heading_${headingIndex}`}>{column.label}</th>
    );
    return (
      <thead>
        <tr>{columns.map(renderHeading)}</tr>
      </thead>
    );
  }
  const renderRow = (row: RowData, rowIndex: number): JSX.Element => {
    const renderButton = (action: Action, buttonIndex: number): JSX.Element => {
      const { func, label, ...buttonProps } = action;

      return (
        <span key={`row_${rowIndex}_button_${buttonIndex}`} {...buttonProps} onClick={(): void => func(row)}>
          {label === 'delete' && (
            <span className="col-sm btn-danger mx-2 pb-2 pt-1" style={{ borderRadius: '10%', maxWidth: '0.5em' }}>
              <i className="tim-icons icon-simple-remove" />
            </span>
          )}
          {label === 'edit' && (
            <span
              className="col-sm btn-default mx-2 pb-2 pt-1 border-3"
              style={{ borderRadius: '10%', maxWidth: '0.5em', backgroundColor: '#50E3C2' }}
            >
              <i className="tim-icons icon-pencil" />
            </span>
          )}
          {label === 'reset' && (
            <span className="col-sm btn-default mx-2 pb-2 pt-1" style={{ borderRadius: '10%', maxWidth: '0.5em' }}>
              <i className="tim-icons icon-lock-circle" />
            </span>
          )}
          {label === 'impersonate' && (
            <span className="col-sm btn-warning mx-2 pb-2 pt-1" style={{ borderRadius: '10%', maxWidth: '0.5em' }}>
              <i className="tim-icons icon-key-25" />
            </span>
          )}
        </span>
      );
    };
    const renderItem = (column: ColumnHeading, itemIndex: number): JSX.Element => {
      if (column.type === 'action') {
        return (
          <td key={`row_${rowIndex}_item_${itemIndex}`}>
            <ButtonToolbar>
              <ButtonGroup>{column.actions.map(renderButton)}</ButtonGroup>
            </ButtonToolbar>
          </td>
        );
      }
      if (!itemIndex && itemIndex !== 0) {
        return (
          <th key={`row_${rowIndex}_item_${itemIndex}`} scope="row">
            <Link to={`/${linkPrefix}/${row.id}`}>{row[column.key]}</Link>
          </th>
        );
      }
      return (
        <td key={`row_${rowIndex}_item_${itemIndex}`}>
          <Link to={`/${linkPrefix}/${row.id}`}>
            <span style={{ color: '#6236FF', textTransform: 'capitalize' }}>{row[column.key]}</span>
          </Link>
        </td>
      );
    };
    return <tr key={`row_${rowIndex}`}>{columns.map(renderItem)}</tr>;
  };

  useEffect(() => {
    if (data) {
      const pages = Math.ceil(data.length / pageSize);
      setLastPage(pages);
    }
  });

  return (
    <>
      <BootStrapTable {...bootstrapProps}>
        {renderHeadings()}
        {data.length && (
          <tbody>{data.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map(renderRow)}</tbody>
        )}
        {!data.length && (
          <tbody>
            <tr>
              <td colSpan={columns.length}>
                <div className="w-100 d-flex flex-column py-2 align-items-center justify-content-center">
                  <div className="mb-2 position-relative">
                    <span className="far fa-file fa-4x text-light" />
                    <span className="fa fa-times fa-2x text-light position-absolute floating-times" />
                  </div>
                  <h3 className="text-light my-1">No data to display</h3>
                  <h4 className="text-light my-1">Press the + button to add some!</h4>
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </BootStrapTable>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ borderTop: '1px solid lightgrey', paddingTop: '8px' }}
      >
        <span style={{ color: '#6D7278' }}>
          Showing {currentPageSize} of {data.length} entries
        </span>
        <Pagination aria-label="Page navigation" className="d-flex justify-content-end" style={{ color: 'blue' }}>
          <PaginationItem disabled={currentPage <= 0}>
            <PaginationLink first onClick={e => handleFirstPage(e)} className="pagination-item">
              FIRST
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={currentPage <= 0}>
            <PaginationLink previous onClick={e => handlePreviousPage(e)} className="pagination-item">
              PREVIOUS
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink
              style={{
                color: 'white',
                borderRadius: '50%',
                width: '30px',
                background: 'linear-gradient(225deg, #BD7AE3 0%, #8461C9 100%)',
                boxShadow: '0px 1px 15px rgba(39, 39, 39, 0.1)',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={e => e.preventDefault()}
            >
              {currentPage + 1}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={lastPage <= 0 || currentPage >= lastPage - 1}>
            <PaginationLink next onClick={e => handleNextPage(e)} className="pagination-item">
              NEXT
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={currentPage >= lastPage - 1}>
            <PaginationLink last onClick={e => handleLastPage(e)} className="pagination-item">
              LAST
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    </>
  );
};

export default DynamicTableLinkable;
