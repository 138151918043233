import React, { ReactElement } from 'react';
import { Col, Row } from 'reactstrap';
import Tabs from '../../../../../components/HearingAndAppealTabs/HearingAndAppealTabs.component';
import { HEARING_TABS } from '../../../../../utils/case-utils';
import { Formik, FormikProps, FormikValues } from 'formik';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { AppealHearingExternalRepresentationOutcomeForm } from './AppealHearingExternalRepresentationOutcomeForm';
import { appeal_hearing_externalRepresentationOutcomeSchema } from '../../../../../forms/ValidationSchema/AppealHearingValidationSchema';

export const AppealHearingExternalRepresentationOutcome: React.FC<WorkflowComponentProps> = (
  props: WorkflowComponentProps,
) => {
  const fields = {
    appeal_hearing_allowExternalRepresentation: 'boolean',
    appeal_hearing_externalRepresentationApplicationRulingDocuments: 'array',
  };

  const initialValues = getFormDataFromCaseData(fields, props.data.caseData);

  const submitValues = (values: FormikValues): void => {
    const formValues = { ...values };
    formValues.appeal_hearing_externalRepresentationApplicationRulingDocuments = JSON.stringify(
      values.appeal_hearing_externalRepresentationApplicationRulingDocuments,
    );
    props.data.flowableFunctions.onNext(filterFormData(formValues, fields));
  };
  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Appeal Hearing preparations</h4>
      <Row>
        <Col md={12}>
          <Tabs page={5} tabs={HEARING_TABS} />
        </Col>
      </Row>
      {/*<h5 className="text-h5 text-capitalize font-weight-500">*/}
      {/*  /!* eslint-disable-next-line react/no-unescaped-entities *!/*/}
      {/*  Decide on Employee's application for external representation*/}
      {/*</h5>*/}
      <h5 className="text-h5 text-blue text-capitalize font-weight-500 mt-3">Record the Application outcome</h5>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={appeal_hearing_externalRepresentationOutcomeSchema}
        onSubmit={submitValues}
      >
        {({ values, handleSubmit, errors }: FormikProps<FormikValues>): ReactElement => (
          <>
            <AppealHearingExternalRepresentationOutcomeForm data={props.data} />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={() => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
