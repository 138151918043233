import React, { ReactElement } from 'react';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../WorkflowContainer/workflow-utils';
import { Formik, FormikProps, FormikValues } from 'formik';
import CompleteInvestigationForm from './CompleteInvestigationForm';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import CompleteInvestigationSchema from './CompleteInvestigationSchema';

const CompleteInvestigation: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const fields = {
    investigationReportDocuments: 'array',
  };

  const initialValues: FormikValues = getFormDataFromCaseData(fields, caseData);
  const submitValues = (values: FormikValues): void => {
    const formValues = { ...values };
    formValues.investigationReportDocuments = JSON.stringify(formValues.investigationReportDocuments);
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };
  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Complete Investigation</h4>
      <Formik initialValues={initialValues} validationSchema={CompleteInvestigationSchema} onSubmit={submitValues}>
        {({ values, setFieldValue, handleSubmit, errors }: FormikProps<FormikValues>): ReactElement => (
          <>
            <div style={{ marginBottom: '12em' }}>
              <CompleteInvestigationForm data={props.data} />
            </div>
            {/*{currentUser && currentUser.userRoles === userRoles.LINE_MANAGER && (*/}
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
              onCancel={() => console.log('cancel')}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default CompleteInvestigation;
