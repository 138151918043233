import React, { useCallback, useContext, useEffect, useState } from 'react';
import { list } from '../../utils/graphql-utils';
import { auditLogsByDummyVar } from '../../graphql/queries';
import { AuditLogEventType } from '../../API';
import DynamicTable, { Column, Data } from '../Employee/ViewEmployees/DynamicTable';
import { useErrorHandler } from '../../utils/notification-utils';
import { Link } from 'react-router-dom';
import TopBarComponent from '../../components/topBar/TopBar.component';
import Loader from '../../components/loader/Loader';
import { Card, CardBody, Col, Row } from 'reactstrap';
import moment from 'moment';
import { AuditLog } from '../../models';
import { toTitleCase } from '../../utils/string-utils';
import { UserContext, UserContextProps } from '../../App';
import { filterLogsByRole } from '../../utils/permissions-utils';
import AddEmployeeButton from '../../components/AddEmployeeButton/AddEmployeeButton';

interface AuditLogsScreenState {
  data?: Data[];
  loading: boolean;
}

const getDescription = (auditLog: AuditLog): string => {
  switch (auditLog.type) {
    case AuditLogEventType.EMPLOYEE_CREATED:
      return 'Added employee';
    case AuditLogEventType.EMPLOYEE_EDITED:
      return 'Updated employee';
    case AuditLogEventType.CASE_STARTED:
      return 'Started a new case';
    case AuditLogEventType.WORKFLOW_PROGRESSED:
      return auditLog.description
        ? 'Completed step: ' + toTitleCase(auditLog.description, '-')
        : 'Completed a workflow step';
    case AuditLogEventType.DOCUMENT_NEW_VERSION:
      return 'New Document Version';
    case AuditLogEventType.LOCATION_MODIFIED:
    case AuditLogEventType.DEPARTMENT_MODIFIED:
    case AuditLogEventType.JOB_GRADE_MODIFIED:
    case AuditLogEventType.JOB_LEVELS_MODIFIED:
    case AuditLogEventType.JOB_TITLE_MODIFIED:
      return auditLog.description ? auditLog.description : toTitleCase(auditLog.type, '_');
    default:
      return toTitleCase(auditLog.type, '_');
  }
};

const formatActionedByName = (log: AuditLog): string => {
  if (log.user && log.user.firstName && log.user.lastName) {
    return toTitleCase(log.user.firstName, ' ') + ' ' + toTitleCase(log.user.lastName, ' ');
  } else if (log.userID.includes('@')) return 'External user: ' + log.userID;
  else return '';
};

export const OrganisationAuditTrailScreen: React.FC = () => {
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState<any[] | []>([]);
  const [queryLimit, setQueryLimit] = useState(10);
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const prepLog = useCallback((auditLog: AuditLog): any => {
    return {
      description: getDescription(auditLog),
      userFullName: formatActionedByName(auditLog),
      timeStamp: moment(auditLog.timeStamp).format('DD/MM/YYYY HH:mm'),
      roles: auditLog.userRoles ? toTitleCase(auditLog.userRoles.toString(), '_') : '',
    };
  }, []);

  const [state, setState] = useState<AuditLogsScreenState>({ data: [], loading: true });
  const handleError = useErrorHandler();
  const getData = useCallback(() => {
    list(auditLogsByDummyVar, {
      dummyVar: 1,
      sortDirection: 'DESC',
      nextToken,
      limit: queryLimit,
      filter: { organisationID: { eq: currentUser?.organisationId } },
    })
      .then(async res => {
        if (res.data && (res.data as any).auditLogsByDummyVar) {
          const rawData: AuditLog[] = await filterLogsByRole((res.data as any).auditLogsByDummyVar.items, currentUser!);
          const data: any[] = rawData.map((log: any) => prepLog(log));
          setState(oldState => ({ ...oldState, data: data }));
          setNextNextToken((res.data as any).auditLogsByDummyVar.nextToken);
        }
      })
      .then(() => setState(oldState => ({ ...oldState, loading: false })))
      .catch(error => handleError(error));
  }, [nextToken, currentUser, handleError, prepLog, queryLimit]);

  useEffect(() => {
    if (currentUser && currentUser.organisationId) {
      getData();
    }
  }, [getData, currentUser]);

  const columns: Column[] = [
    { key: 'description', label: 'Action' },
    { key: 'userFullName', label: 'Actioned By' },
    { key: 'roles', label: 'Roles' },
    { key: 'timeStamp', label: 'Date' },
  ];

  const changeLimit = (limit: any) => {
    list(auditLogsByDummyVar, { nextToken, limit: limit, dummyVar: 1, sortDirection: 'DESC' })
      .then(async res => {
        if (res.data && (res.data as any).auditLogsByDummyVar) {
          const rawData: AuditLog[] = await filterLogsByRole((res.data as any).auditLogsByDummyVar.items, currentUser!);
          const data: any[] = rawData.map((log: any) => prepLog(log));
          setState(oldState => ({ ...oldState, data: data }));
          setNextNextToken((res.data as any).auditLogsByDummyVar.nextToken);
          setQueryLimit(limit);
        }
      })
      .then(() => setState(oldState => ({ ...oldState, loading: false })))
      .catch(error => handleError(error));
  };

  const pageForward = () => {
    const historyPages = [...previousTokens, { page: previousTokens.length + 1, token: nextToken }];
    setPreviousTokens(historyPages);
    setNextToken(nextNextToken);
    list(auditLogsByDummyVar, { nextNextToken, limit: queryLimit, dummyVar: 1, sortDirection: 'DESC' })
      .then(async res => {
        if (res.data && (res.data as any).auditLogsByDummyVar) {
          const rawData: AuditLog[] = await filterLogsByRole((res.data as any).auditLogsByDummyVar.items, currentUser!);
          const data: any[] = rawData.map((log: any) => prepLog(log));
          setState(oldState => ({ ...oldState, data: data }));
          setNextNextToken((res.data as any).auditLogsByDummyVar.nextToken);
        }
      })
      .then(() => setState(oldState => ({ ...oldState, loading: false })))
      .catch(error => handleError(error));
  };

  const pageBackward = () => {
    if (previousTokens.length > 0) {
      const clonePrevious = previousTokens;
      const token = clonePrevious.pop().token;
      setPreviousTokens(clonePrevious);
      setNextToken(token);

      list(auditLogsByDummyVar, { token, limit: queryLimit, dummyVar: 1, sortDirection: 'DESC' })
        .then(async res => {
          if (res.data && (res.data as any).auditLogsByDummyVar) {
            const rawData: AuditLog[] = await filterLogsByRole(
              (res.data as any).auditLogsByDummyVar.items,
              currentUser!,
            );
            const data: any[] = rawData.map((log: any) => prepLog(log));
            setState(oldState => ({ ...oldState, data: data }));
            setNextNextToken((res.data as any).auditLogsByDummyVar.nextToken);
          }
        })
        .then(() => setState(oldState => ({ ...oldState, loading: false })))
        .catch(error => handleError(error));
    }
  };

  return (
    <>
      <TopBarComponent title={'Audit Trail'} subTitle={' '}>
        <AddEmployeeButton />
        <Link to="/new-case"></Link>
      </TopBarComponent>
      <div className="content">
        {state.loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <Row>
            <Col className="mb-5" md="12">
              <Card>
                <CardBody>
                  <DynamicTable
                    currentPage={previousTokens.length + 1}
                    pageBackward={() => pageBackward()}
                    pageForward={() => pageForward()}
                    onSetLimit={limit => changeLimit(limit)}
                    data={state.data?.sort((a, b) => {
                      return moment(b.timeStamp, 'DD/MM/YYYY').diff(moment(a.timeStamp, 'DD/MM/YYYY'));
                    })}
                    columns={columns}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
