import React from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { FormikValues, useFormikContext } from 'formik';
import { AllSanctionOptions } from '../../InitialHearingScreens/ConfirmOverallSanction/sanction-options';
import { WorkflowComponentProps } from '../../../../WorkflowContainer/workflow-utils';

export const AppealHearingConcludeHearingAdministrationForm: React.FC<WorkflowComponentProps> = props => {
  const { values } = useFormikContext<FormikValues>();
  const { setFieldValue } = useFormikContext();
  return (
    <Form>
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            Sanction outcome letter was delivered to/served on Employee?
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.appeal_hearing_deliveredSanctionOutcomeLetter}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_deliveredSanctionOutcomeLetter', true);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.appeal_hearing_deliveredSanctionOutcomeLetter}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_deliveredSanctionOutcomeLetter', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            If applicable, did employee accept Demotion as alternative to dismissal?
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    disabled={props.data.caseData.revisedOverallSanction !== AllSanctionOptions.DEMOTION}
                    type="checkbox"
                    value="yes"
                    checked={values.appeal_hearing_acceptedDemotion === 'YES'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_acceptedDemotion', 'YES');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    disabled={props.data.caseData.revisedOverallSanction !== AllSanctionOptions.DEMOTION}
                    type="checkbox"
                    checked={values.appeal_hearing_acceptedDemotion === 'NO'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_acceptedDemotion', 'NO');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    disabled={props.data.caseData.revisedOverallSanction !== AllSanctionOptions.DEMOTION}
                    type="checkbox"
                    checked={values.appeal_hearing_acceptedDemotion === 'NOT APPLICABLE'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_acceptedDemotion', 'NOT APPLICABLE');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">not applicable</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            If applicable, did employee accept suspension without pay as alternative to dismissal?
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    disabled={props.data.caseData.revisedOverallSanction !== AllSanctionOptions.SUSPENSION_WITHOUT_PAY}
                    value="yes"
                    checked={values.appeal_hearing_acceptedSuspensionWithoutPay === 'YES'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_acceptedSuspensionWithoutPay', 'YES');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    disabled={props.data.caseData.revisedOverallSanction !== AllSanctionOptions.SUSPENSION_WITHOUT_PAY}
                    checked={values.appeal_hearing_acceptedSuspensionWithoutPay === 'NO'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_acceptedSuspensionWithoutPay', 'NO');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    disabled={props.data.caseData.revisedOverallSanction !== AllSanctionOptions.SUSPENSION_WITHOUT_PAY}
                    checked={values.appeal_hearing_acceptedSuspensionWithoutPay === 'NOT APPLICABLE'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_acceptedSuspensionWithoutPay', 'NOT APPLICABLE');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">not applicable</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
