import React, { useContext } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ErrorMessage, FormikProps, FormikValues, useFormikContext } from 'formik';
import { Tooltip } from '../../../../../components/tooltips/Tooltip.component';
import FormField from '../../../../../forms/fields/FormField.component';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../../../components/taskContainer/TaskContainer';
import { WorkflowComponentProps } from '../../../../WorkflowContainer/workflow-utils';

export const AppealHearingRecordEmployeeRightsAndResponsesForm: React.FC<WorkflowComponentProps> = (
  props: WorkflowComponentProps,
) => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;

  return (
    <Form>
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            Has the Employee requested a translator?*
            <span className="ml-3">
              <Tooltip
                title={'Translator'}
                id="translator"
                text="An  employee  may  request  an  interpreter  in  a  language  of  his  choice  to  avoid  any prejudice from arising during the hearing process, even if normally in the day to day business he/she engages in the standard workplace language. The employer should provide such."
              />
            </span>
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.appeal_hearing_requestedTranslator === 'YES'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_requestedTranslator', 'YES');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.appeal_hearing_requestedTranslator === 'NO'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_requestedTranslator', 'NO');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.appeal_hearing_requestedTranslator === 'NO RESPONSE'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_requestedTranslator', 'NO RESPONSE');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no response</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={'appeal_hearing_requestedTranslator'} />
          </span>
        </Col>
      </Row>
      {values.appeal_hearing_requestedTranslator === 'YES' && (
        <Row className="mt-4">
          <Col md={6}>
            <FormGroup>
              <Label for="language" className="text-default text-capitalize">
                Language
              </Label>
              <FormField
                type={'select'}
                placeholder="Select Language"
                name="appeal_hearing_language"
                selectOptions={[
                  'English',
                  'Zulu',
                  'Afrikaans',
                  'Xhosa',
                  'Venda',
                  'Ndebele',
                  'Northern Sotho',
                  'Sotho',
                  'Swazi',
                  'Tswana',
                  'Tsonga',
                  'Other',
                ]}
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'appeal_hearing_language'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            Has the Employee requested external representation?*
            <span className="ml-3">{/* <InfoIcon /> */}</span>
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.appeal_hearing_requestedExternalRepresentation === 'YES'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_requestedExternalRepresentation', 'YES');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.appeal_hearing_requestedExternalRepresentation === 'NO'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_requestedExternalRepresentation', 'NO');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.appeal_hearing_requestedExternalRepresentation === 'NO RESPONSE'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_requestedExternalRepresentation', 'NO RESPONSE');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no response</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={'appeal_hearing_requestedExternalRepresentation'} />
          </span>
        </Col>
      </Row>
      {values.appeal_hearing_requestedExternalRepresentation === 'YES' && (
        <Row className="mt-4">
          <Col md={6}>
            <FormGroup>
              <Label
                for="appeal_hearing_reasonForRequestingExternalRepresentation"
                className="text-default text-capitalize"
              >
                Reason for requesting external representation
              </Label>
              <FormField
                type={'text'}
                placeholder="Enter Reason"
                name="appeal_hearing_reasonForRequestingExternalRepresentation"
              />
              <span className="text-danger">
                <ErrorMessage
                  className="text-danger"
                  name={'appeal_hearing_reasonForRequestingExternalRepresentation'}
                />
              </span>
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            Will an EE representative be present?*
            <span className="ml-3">
              <Tooltip
                title={'Employee Representative'}
                id="eeRep"
                text={
                  props.data.caseType === 'POOR_PERFORMANCE'
                    ? 'This  is  optional  in  special  circumstances  at  the  choice  of  or  with  the  consent  of  the employee.  The  employee  may,  for  example,  feel  that  he/she  is  unfairly  being discriminated against in the poor performance process. The EE representative will only have observer status.'
                    : 'This  is  optional  in  special  circumstances  at  the  choice  of  or  with  the  consent  of  the employee.  Examples  include  alleged  cases  of  harassment  or  unfair  discrimination. They will only have observer status.'
                }
              />
            </span>
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.appeal_hearing_eERepresentativePresent === 'YES'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_eERepresentativePresent', 'YES');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.appeal_hearing_eERepresentativePresent === 'NO'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_eERepresentativePresent', 'NO');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.appeal_hearing_eERepresentativePresent === 'NO RESPONSE'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_eERepresentativePresent', 'NO RESPONSE');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no response</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={'appeal_hearing_eERepresentativePresent'} />
          </span>
        </Col>
      </Row>
      {values.appeal_hearing_eERepresentativePresent === 'YES' && (
        <Row className="mt-4">
          <Col md={6}>
            <FormGroup>
              <Label for="appeal_hearing_reasonForEeRepresentativePresence" className="text-default text-capitalize">
                Reason for presence of EE Representative
              </Label>
              <FormField
                type={'text'}
                placeholder="Enter Reason"
                name="appeal_hearing_reasonForEeRepresentativePresence"
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'appeal_hearing_reasonForEeRepresentativePresence'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            Details of reasonable accommodation required*
            <span className="ml-3">
              <Tooltip
                title={'Reasonable Accommodation'}
                id="disabledEmployee"
                text="The person’s status may already be known, or they may disclose it at this time. They may require reasonably accommodations meaning assistance to put them in a position to fairly participate in the hearing and state their case. For example, a hearing impaired person  may  require  a  sign  language  interpreter,  even  if  in  the  day  to  day  business he/she does not. Such must be provided by the employer within reason."
              />
            </span>
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.appeal_hearing_disabledAndRequiresAccommodation === 'YES'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_disabledAndRequiresAccommodation', 'YES');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.appeal_hearing_disabledAndRequiresAccommodation === 'NO'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_disabledAndRequiresAccommodation', 'NO');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.appeal_hearing_disabledAndRequiresAccommodation === 'NO RESPONSE'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_disabledAndRequiresAccommodation', 'NO RESPONSE');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no response</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={'appeal_hearing_disabledAndRequiresAccommodation'} />
          </span>
        </Col>
      </Row>
      {values.appeal_hearing_disabledAndRequiresAccommodation === 'YES' && (
        <Row className="mt-4">
          <Col md={6}>
            <FormGroup>
              <Label for="appeal_hearing_reasonForRequiringAccommodation" className="text-default text-capitalize">
                Enter Details
              </Label>
              <FormField
                type={'text'}
                placeholder="Enter Reason"
                name="appeal_hearing_reasonForRequiringAccommodation"
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'appeal_hearing_reasonForRequiringAccommodation'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
      )}
    </Form>
  );
};
