import React from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ErrorMessage, FormikValues, useFormikContext } from 'formik';
import { WorkflowComponentProps } from '../../../../WorkflowContainer/workflow-utils';
import { AllSanctionOptions } from '../ConfirmOverallSanction/sanction-options';

export const InitialHearingConcludeHearingAdministrationForm: React.FC<WorkflowComponentProps> = props => {
  const { values } = useFormikContext<FormikValues>();
  const { setFieldValue } = useFormikContext();
  return (
    <Form>
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            Sanction outcome letter was delivered to/served on Employee?
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.initial_hearing_deliveredSanctionOutcomeLetter}
                    onChange={(): void => {
                      setFieldValue('initial_hearing_deliveredSanctionOutcomeLetter', true);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.initial_hearing_deliveredSanctionOutcomeLetter}
                    onChange={(): void => {
                      setFieldValue('initial_hearing_deliveredSanctionOutcomeLetter', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'initial_hearing_deliveredSanctionOutcomeLetter'} />
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            If applicable, did employee accept Demotion as alternative to dismissal?
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    disabled={props.data.caseData.overallSanction !== AllSanctionOptions.DEMOTION}
                    type="checkbox"
                    value="yes"
                    checked={values.initial_hearing_acceptedDemotion === 'YES'}
                    onChange={(): void => {
                      setFieldValue('initial_hearing_acceptedDemotion', 'YES');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    disabled={props.data.caseData.overallSanction !== AllSanctionOptions.DEMOTION}
                    type="checkbox"
                    checked={values.initial_hearing_acceptedDemotion === 'NO'}
                    onChange={(): void => {
                      setFieldValue('initial_hearing_acceptedDemotion', 'NO');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    disabled={props.data.caseData.overallSanction !== AllSanctionOptions.DEMOTION}
                    type="checkbox"
                    checked={values.initial_hearing_acceptedDemotion === 'NOT APPLICABLE'}
                    onChange={(): void => {
                      setFieldValue('initial_hearing_acceptedDemotion', 'NOT APPLICABLE');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">not applicable</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={'initial_hearing_acceptedDemotion'} />
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            If applicable, did employee accept suspension without pay as alternative to dismissal?
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    disabled={props.data.caseData.overallSanction !== AllSanctionOptions.SUSPENSION_WITHOUT_PAY}
                    type="checkbox"
                    value="yes"
                    checked={values.initial_hearing_acceptedSuspensionWithoutPay === 'YES'}
                    onChange={(): void => {
                      setFieldValue('initial_hearing_acceptedSuspensionWithoutPay', 'YES');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    disabled={props.data.caseData.overallSanction !== AllSanctionOptions.SUSPENSION_WITHOUT_PAY}
                    type="checkbox"
                    checked={values.initial_hearing_acceptedSuspensionWithoutPay === 'NO'}
                    onChange={(): void => {
                      setFieldValue('initial_hearing_acceptedSuspensionWithoutPay', 'NO');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    disabled={props.data.caseData.overallSanction !== AllSanctionOptions.SUSPENSION_WITHOUT_PAY}
                    type="checkbox"
                    checked={values.initial_hearing_acceptedSuspensionWithoutPay === 'NOT APPLICABLE'}
                    onChange={(): void => {
                      setFieldValue('initial_hearing_acceptedSuspensionWithoutPay', 'NOT APPLICABLE');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">not applicable</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={'initial_hearing_acceptedSuspensionWithoutPay'} />
          </span>
        </Col>
        {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
      </Row>
    </Form>
  );
};
