import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/bootstrap.min.css';
import './assets/css/nucleo-icons.css';
import './assets/scss/black-dashboard-pro-react.scss?v=1.0.0';
import './assets/demo/demo.css';
import 'react-notification-alert/dist/animate.css';
import App from './App';
import aws_config from './aws-exports';
import Amplify from 'aws-amplify';
import './index.styles.scss';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

declare global {
  interface ModuleConfig {
    INITIAL_MEMORY?: number;
    ALLOW_MEMORY_GROWTH?: boolean;
  }

  interface Window {
    Module?: ModuleConfig;
  }
}

Bugsnag.start({
  apiKey: '86e1a2dc65acd7fa478c3a9ac38ffb8b',
  plugins: [new BugsnagPluginReact()],
});
// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const config = aws_config;
if (
  window.location.href.includes('/external') ||
  window.location.href.includes('/confirm-receipt') ||
  window.location.href.includes('/sign-document')
) {
  console.log('setting amplify config to API key...');
  config.aws_appsync_authenticationType = 'API_KEY';
} else {
  console.log('setting amplify config to Cognito...');
  config.aws_appsync_authenticationType = 'AMAZON_COGNITO_USER_POOLS';
}
Amplify.configure(config);

window.Module = {
  INITIAL_MEMORY: 268435456,
  ALLOW_MEMORY_GROWTH: true,
};

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
