import React from 'react';
import './caseProgressBar.styles.scss';

interface CaseProgressBarProps {
  steps: string[];
  stepIndex: number;
}

export const CaseProgressBar: React.FC<CaseProgressBarProps> = (props: CaseProgressBarProps) => {
  return (
    <div className="case-progress-bar-container">
      {props.steps.map((step: string, index: number) => {
        let className: string;
        let style: object;
        style = {
          border: '1px solid #ECEBED',
          padding: '5px',
        };

        if (props.stepIndex === index) {
          className = 'case-step-active';
        } else if (props.stepIndex > index) {
          className = 'case-step-completed';
        } else {
          className = 'case-step-incomplete';
        }
        return (
          <div key={index} className={'case-progress-step text-default' + ' ' + className}>
            <span className={'case-progress-step-circle text-center' + ' ' + className} style={style}>
              {props.stepIndex > index ? (
                <i className="fa fa-check w-100" />
              ) : (
                <p className="w-100" style={{ color: className === 'case-step-active' ? 'white' : 'grey' }}>
                  {index + 1}
                </p>
              )}
            </span>
            {index !== props.steps.length - 1 ? <span className={'case-progress-bar' + ' ' + className} /> : null}
            {step}
          </div>
        );
      })}
    </div>
  );
};
