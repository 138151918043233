import { Formik, FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { EmailAddressType, UpdateEmployeeInput } from '../../../../API';
import GuidanceModal from '../../../../components/guidanceModals/GuidanceModal.component';
import { Tooltip } from '../../../../components/tooltips/Tooltip.component';
import { WorkFlowFooter } from '../../../../components/workflowFooter/WorkFlowFooter';
import { ConductDisciplinaryDiscussionSchema } from '../../../../forms/ValidationSchema/DiscussionValidationSchema';
import { getEmployee } from '../../../../graphql/queries';
import { get } from '../../../../utils/graphql-utils';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../WorkflowContainer/workflow-utils';
import ConductDisciplinaryDiscussionForm from './ConductDisciplinaryDiscussionForm';

const ConductDisciplinaryDiscussion: React.FC<WorkflowComponentProps> = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [, setEmployeeEmail] = useState('');

  const fields = {
    disciplinaryDiscussionDate: 'string',
    disciplinaryDiscussionTime: 'string',
    disciplinaryDiscussionAttendees: 'array',
  };

  const initialValues: FormikValues = getFormDataFromCaseData(fields, props.data.caseData);

  const submitValues = (formValues: FormikValues): void => {
    props.data.flowableFunctions.onNext(getFormValuesForSubmission(formValues));
  };

  const getEmployeeEmail = (employee: UpdateEmployeeInput): string | null => {
    let emailAddress: string | undefined | null = null;
    if (employee.emails) {
      employee.emails.forEach(item => {
        if (item?.emailAddressType === EmailAddressType.WORK) {
          emailAddress = item.address;
        }
      });
    }
    return emailAddress;
  };

  const getFormValuesForSubmission = (values: FormikValues): Record<string, any> => {
    const formValues = { ...values };
    formValues.disciplinaryDiscussionAttendees = values.disciplinaryDiscussionAttendees.map(
      (attendee: Record<string, string>) => {
        return {
          attendeeName: attendee.attendeeName,
          attendeeEmailAddress: attendee.attendeeEmailAddress
            ? attendee.attendeeEmailAddress
            : 'noEmail@labourteq.co.za',
          attendeeId: attendee.attendeeId,
        };
      },
    );
    formValues.disciplinaryDiscussionAttendees = JSON.stringify(formValues.disciplinaryDiscussionAttendees);
    return filterFormData(formValues, fields);
  };

  useEffect(() => {
    get(getEmployee, props.data.caseData.employeeId).then(res => {
      if (res.data && (res.data as any).getEmployee) {
        const employee: UpdateEmployeeInput = (res.data as any).getEmployee;
        const email = getEmployeeEmail(employee);
        if (email) setEmployeeEmail(email);
      }
    });
  }, []);

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">
        Conduct Disciplinary Discussion
        <Tooltip
          id="disciplinaryDiscussion"
          title="disciplinary discussion"
          text="A disciplinary discussion is an informal process during which alleged minor misconduct is discussed with the employee. It typically results in a verbal or written warning, if the employee has no reasonable explanation for his/her conduct. Depending on your Disciplinary Code it could also result in a Final Written Warning. The purpose is to give the employee an opportunity to state his/her case prior to any disciplinary sanction being issued."
          className="ml-2"
        />
      </h4>
      <GuidanceModal
        buttonTitle="Disciplinary Discussion"
        title="Guidance: Disciplinary Discussion"
        isOpen={modalIsOpen}
        setModal={setModalIsOpen}
      >
        <p>
          <div className="font-weight-bold font-italic text-dark">
            What is the purpose of a disciplinary discussion?
          </div>
          <span className="text-muted">
            The discussion gives line management the opportunity to address minor transgressions pro-actively and avoid
            habitual conduct from arising. You must be consistent in your application and must not be seen to condone
            same or similar wrongdoing in other employees. In the discussion the employee should be given an opportunity
            to respond to alleged minor wrongdoings, prior to you deciding on an appropriate way to deal with the
            behaviour. It is supposed to be corrective in nature.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">
            What process should be followed in the disciplinary discussion?
          </div>
          <span className="text-muted">
            The following serves as guideline with due reference to any particulars contained in your Disciplinary Code:
          </span>
          <br />
          <ul className="px-3">
            <li className="text-muted">
              • Invite the employee to a meeting in your office, away from other employees.
            </li>
            <li className="text-muted">
              • A formal Notice is not required, as it constitutes an informal process.o A formal Notice is not
              required, as it constitutes an informal process.
            </li>
            <li className="text-muted">
              • Tell the employee why it is important that rules are obeyed and standards of behaviour are met on a
              daily basis.
            </li>
            <li className="text-muted">
              • Explain to him/her that he/she has seemingly broken a rule. Back it up with facts you have gathered in
              support of the allegation.
            </li>
            <li className="text-muted">• Ask him/her for an explanation and provide a fair opportunity to respond.</li>
            <li className="text-muted">• Question him/her to clarify your understanding of his/her response.</li>
            <li className="text-muted">• Consider whether the explanation is plausible, reasonable and acceptable.</li>
            <li className="text-muted">
              • Your Disciplinary Code may allow the person to have a representative present during such discussion, in
              which case you should allow this.
            </li>
            <li className="text-muted">
              • Tell him/her your decision on the matter. You may adjourn to verify facts prior to coming to a
              conclusion.
            </li>
            <li className="text-muted">
              • If applicable, complete the necessary disciplinary form and ask him/her to sign it. Advise that it will
              be placed on his/her employee record for a period of time.
            </li>
            <li className="text-muted">
              • Stress that obeying of rules is his/her responsibility and same or similar future transgressions will
              lead to more serious penalties being instituted.
            </li>
            <li className="text-muted">
              • Consider whether any other corrective actions or assistance is required from the employer. For example,
              if a person is continuously late because of family responsibilities consider amending his/her working
              times by agreement, if operationally possible.
            </li>
          </ul>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">
            What type of sanction can be issued following a disciplinary discussion?
          </div>
          <span className="text-muted">
            The discussion can serve as a counselling only, or can typically result in a Verbal or Written Warning. A
            Verbal warning is merely a note placed on record that a discussion was held with the person concerned. A
            Written Warning is a formal outcome wherein the transgression and required remedial action are documented.
            Both serve as the basis for progressive disciplinary action, should the conduct be repeated in future. It
            depends on your own Disciplinary Code’s prescripts, whether a Final Written Warning can be issued or not.
            Some Codes require a formal disciplinary hearing if this is the potential outcome of a disciplinary process
            embarked upon. You should never dismiss a person as an outcome of a disciplinary discussion. If this becomes
            apparent as a potential sanction, rather embark upon a formal disciplinary hearing process to ensure
            procedural fairness and protect both the employer and employee’s interests. Avoid making decisions on the
            spur of the moment, or when angry.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">What is progressive discipline?</div>
          <span className="text-muted">
            Progressive discipline is a disciplinary system that provides a graduated range of responses or sanctions to
            employees' misconduct problems. Disciplinary measures range from mild to severe, depending on the nature (ie
            minor vs. serious) and/or frequency of the misconduct. The severity of sanctions increase upon repeat
            occurrences of same or similar misconduct. For example, theft and dishonesty are serious transgressions that
            in the first instance can lead to dismissal. Late coming on the other hand, warrants a verbal warning in the
            the first instance. However, i f the late coming becomes habitual notwithstanding corrective actions , it
            ultimately lead to a final written warning being issued and even dismissal
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">
            What should I do if the employee refuses to sign a warning document?
          </div>
          <span className="text-muted">
            You are merely requesting the employee to sign in acknowledgement of receipt. This does not mean that he/she
            necessarily agrees with the outcome of the disciplinary discussion. Explain this to him/her. However, it is
            within his/her right to refuse to sign. In this instance hand the sanction outcome to the employee in the
            presence of a witness. The latter then signs the document indicating that it was handed to him/her in their
            presence. Place the document on the employee’s record. The employee’s signature is not required for the
            sanction to be valid.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">For how long does a warning remain valid?</div>
          <span className="text-muted">
            That depends on the provisions of your Disciplinary Code. Typically, verbal warnings are valid for 3 months,
            written warnings for 6 months and final written warnings for 12 months from date of it being issued. Check
            your Code. The LabourTeq system would have been customised in accordance with the latter.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold font-italic text-dark">
            Does the employee have a right of appeal in these circumstances?
          </div>
          <span className="text-muted">
            Only if your own Disciplinary Code provides for an appeal process, does the employee have a right to such.
            In principle he/she can then appeal against any disciplinary sanction, including against a verbal or written
            warning. Try and avoid drawn-out processes about minor transgressions. If no internal appeal process is
            available he/she has the right to refer to the matter to a dispute resolution body (i.e. the CCMA or
            Bargaining Council, as applicable) as an unfair labour practice dispute.
          </span>
        </p>
      </GuidanceModal>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={ConductDisciplinaryDiscussionSchema}
        onSubmit={submitValues}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <ConductDisciplinaryDiscussionForm
              data={props.data}
              getFormValuesForSubmission={getFormValuesForSubmission}
            />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={() => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default ConductDisciplinaryDiscussion;
