import React, { FC, useState } from 'react';
import Select from 'react-select';
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Popover,
  PopoverBody,
  PopoverHeader,
} from 'reactstrap';
import { SearchIcon } from '../../components/icon/Icon.component';

interface SearchCasesProps {
  handleTextChange: any;
  selectedCaseStatus: any;
  caseStatuses: any;
  onHandleCaseStatus: any;
  onClearSearch?: any;
  clearSearchResults?: any;
}

const Search: FC<SearchCasesProps> = (props: SearchCasesProps) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <>
      <InputGroup className="no-border mt-sm-0 mb-0 shadow-sm rounded">
        <InputGroupAddon addonType="prepend">
          <InputGroupText className="bg-white">
            <SearchIcon />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          autoComplete="off"
          className="bg-white h-auto"
          name="search"
          placeholder={'Search Cases'}
          type="text"
          onChange={props?.handleTextChange}
        />
        <InputGroupAddon
          addonType="append"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'white', padding: '2vh' }}
        >
          <i
            className="fa fa-filter pointer"
            id="Popover1"
            style={{ color: props.selectedCaseStatus ? '#131042' : '#8461C9' }}
          />
          <Popover placement="bottom" isOpen={popoverOpen} target="Popover1" toggle={toggle}>
            <PopoverHeader
              style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}
              className="w-100 d-flex justify-content-between"
            >
              <span className="text-default">Filter By</span>
              <span className="text-grey pointer" onClick={props.onClearSearch} style={{ color: '#6c757d' }}>
                <i
                  className="fa fa-times-circle mr-1"
                  id="Popover1"
                  style={{ borderRadius: '30px', width: '15px', paddingTop: '0.25rem', paddingBottom: '0.25rem' }}
                  onClick={() => toggle()}
                />
                <span onClick={() => toggle()}>Clear Filter</span>
              </span>
            </PopoverHeader>
            <PopoverBody style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
              <Select
                className="bg-white h-25 my-2 text-default"
                placeholder={'Case filter'}
                // @ts-ignore
                options={props.caseStatuses ? props.caseStatuses.sort((a, b) => a.value - b.value) : []}
                onChange={props.onHandleCaseStatus}
                value={
                  // @ts-ignore
                  props.caseStatuses ? props.caseStatuses.filter(el => props.selectedCaseStatus === el.value)[0] : null
                }
              />
            </PopoverBody>
          </Popover>
        </InputGroupAddon>
      </InputGroup>
    </>
  );
};

export default Search;
