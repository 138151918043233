import React, { useState } from 'react';
import { FlowableHistoricTask } from '../../../utils/flowable/flowable-types';
import moment from 'moment';
import { taskConfigs } from '../../../configs/task-configs/taskConfigs';
import Bugsnag from '@bugsnag/js';

interface CaseHistoryAccordionProps {
  index: number;
  task: FlowableHistoricTask;
}
const CaseHistoryAccordion: React.FC<CaseHistoryAccordionProps> = ({ children, task, index }) => {
  const [state, setState] = useState({ open: false });
  const taskConfig = taskConfigs[task.taskDefinitionKey];
  const formatDateTime = (dateTime: string | Date): string => {
    if (moment(dateTime).isValid())
      return moment(dateTime)
        .format('DD/MM/YYYY HH:mm')
        .toString();
    return 'N/A';
  };
  if (!taskConfig) {
    Bugsnag.notify(`No taskconfig found on ${task}`);
  }

  return (
    <>
      <div
        className="d-flex justify-content-between tab-background pointer px-3 py-3 my-1"
        onClick={(): void => setState(oldState => ({ ...oldState, open: !state.open }))}
      >
        <span className="text-default">{index + 1}.</span>
        <span className="h-75 font-weight-light text-muted text-capitalize w-25">
          {taskConfig && taskConfig.name ? taskConfig.name : 'Task name not found'}
        </span>
        <span className="h-75 font-weight-light text-muted d-flex flex-column w-25">
          <span className="font-weight-bold ">Start Date</span>
          <span>{task.startTime ? formatDateTime(task.startTime) : 'N/A'}</span>
        </span>
        <span className="h-75 font-weight-light text-muted d-flex flex-column w-25">
          <span className="font-weight-bold">End Date</span>
          <span>{task.endTime ? formatDateTime(task.endTime) : 'N/A'}</span>
        </span>
        <span>
          {state.open ? (
            <i className="tim-icons icon-minimal-up text-default font-weight-bold" />
          ) : (
            <i className="tim-icons icon-minimal-down text-default font-weight-bold" />
          )}
        </span>
      </div>
      {state.open && children}
    </>
  );
};

export default CaseHistoryAccordion;
