import { Document, Page, Text } from '@react-pdf/renderer';
import { FormikValues } from 'formik';
import React from 'react';
import { CaseType } from '../../../../../API';
import LetterHead from '../../../../../components/PDF/Letterhead.component';
import Paragraph from '../../../../../components/PDF/Paragraph.component';
import { styles } from '../../../../../components/PDF/PDFStyles.style';
import { default as Table, default as TableRow } from '../../../../../components/PDF/PDFTable/Table/Table.component';
import TableCell from '../../../../../components/PDF/PDFTable/TableCell.component';
import TableHeader from '../../../../../components/PDF/PDFTable/TableHeader.component';
import { LogoPosition } from '../../../../../models';
import { CaseIncident, CasePerformanceShortfall, Data } from '../../../../WorkflowContainer/workflow-utils';

interface ChairpersonsRulingPDFProps {
  values: FormikValues;
  data: Data;
  logo?: { imageUrl: string; position: LogoPosition };
}

export const AppealHearingChairpersonsRulingPDF = (props: ChairpersonsRulingPDFProps): JSX.Element => {
  console.log({ props });
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Chairperson’s Ruling</Text>
        <TableHeader title="State briefly the background of what led to the appeal hearing process:" />
        <Paragraph>{props.values.appeal_hearing_backgroundLeadingToHearing}</Paragraph>
        <TableHeader title="Summarise arguments of the appellant:" />
        <Paragraph>{props.values.appeal_hearing_summaryOfAppellantsArguments}</Paragraph>
        <TableHeader title="Summarise arguments of the employer representative:" />
        <Paragraph>{props.values.appeal_hearing_summaryOfEmployerRepresentativesArguments}</Paragraph>
        <TableHeader title="Summarise any NEW evidence considered, if any:" />
        <Paragraph>{props.values.appeal_hearing_newEvidenceConsidered}</Paragraph>
        <TableHeader title="Legal principles applied, if any:" />
        <Paragraph>{props.values.appeal_hearing_summaryOfLegalPrinciples}</Paragraph>
        <TableHeader title="Conclusions reached:" />
        <Paragraph>{props.values.appeal_hearing_conclusionsReached}</Paragraph>
        <TableHeader title="Charge, outcome on appeal and reason if overturned" />
        <Table>
          {props.data.caseType === CaseType.MISCONDUCT && props.values.incidents?.length && (
            <>
              {props.values.incidents.map((transgression: CaseIncident) => (
                <TableRow key={transgression.transgression}>
                  <TableCell width={'33%'}>{transgression.transgression}</TableCell>
                  <TableCell width={'33%'}>{transgression.sanctionConfirmed ? 'Confirmed' : 'Overturned'}</TableCell>
                  <TableCell width={'33%'}>
                    {transgression.reasonForOverturningSanction ? transgression.reasonForOverturningSanction : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
          {props.data.caseType === CaseType.POOR_PERFORMANCE && (
            <>
              {props.values.performanceShortfalls.map((shortfall: CasePerformanceShortfall, index: number) => (
                <TableRow key={index}>
                  <TableCell width={'33%'}>{shortfall.shortfallDescription}</TableCell>
                  <TableCell width={'33%'}>{shortfall.sanctionConfirmed ? 'Confirmed' : 'Overturned'}</TableCell>
                  <TableCell width={'33%'}>
                    {shortfall.reasonForOverturningSanction ? shortfall.reasonForOverturningSanction : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </Table>
      </Page>
    </Document>
  );
};
