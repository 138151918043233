import { Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useState } from 'react';
import { Row } from 'reactstrap';
import GuidanceModal from '../../components/guidanceModals/GuidanceModal.component';
import { WorkFlowFooter } from '../../components/workflowFooter/WorkFlowFooter';
import RecordSuspensionDiscussionForm from '../../forms/addCase/RecordSuspensionDiscussionForm';
import { RecordSuspensionDiscussionSchema } from '../../forms/ValidationSchema/GeneralCaseSchema';
import { getFormDataFromCaseData, WorkflowComponentProps } from '../WorkflowContainer/workflow-utils';
import './RecordSuspensionDiscussion.styles.scss';

const RecordSuspensionDiscussion: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const [modal, setModal] = useState(false);
  const { caseData, flowableFunctions } = props.data;
  const fields = {
    suspensionDiscussionDate: 'string',
    suspensionDiscussionTime: 'string',
    suspensionDiscussionSummary: 'string',
    suspensionDiscussionAttendees: 'array',
  };
  const initialValues: FormikValues = getFormDataFromCaseData(fields, caseData, caseData.isAppeal);

  const submitValues = async (values: FormikValues): Promise<void> => {
    const formValues = { ...values };
    formValues.suspensionDiscussionAttendees = JSON.stringify(values.suspensionDiscussionAttendees);
    flowableFunctions.onNext(formValues);
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Prepare for suspension discussion</h4>
      <GuidanceModal
        buttonTitle="Prepare for Suspension Discussion (if relevant)"
        title="Suspension Discussion"
        isOpen={modal}
        setModal={setModal}
      >
        <p>
          <div className="font-weight-bold text-dark">When should a person be suspended?</div>
          <span className="text-muted">
            There is no hard-and-fast rule to determine when an employee should be suspended, but this action could
            constitute an unfair labour practice if not justified. You should apply your mind whether a reasonable,
            justifiable cause exists to suspect that:
          </span>
        </p>
        <br />
        <p>
          <ul className="px-3">
            <li className="text-muted">
              • The nature of the alleged offence poses an operational risk or impacts on the trust relationship. For
              example, 5 days of unauthorised absence is a serious offence, but suspension is not typical in this case.
              However, a dishonesty-related offence, theft or conflicts of interests may warrant suspension.
            </li>
            <li className="text-muted">
              • The employee may interfere with the investigation or the operations of the employer, for example, by
              intimidating witnesses, tampering with evidence, sabotaging systems, or otherwise posing a threat to the
              investigation and employees.
            </li>
            <li className="text-muted">
              • The employee may interfere with or retaliate against the complainant, for example, in cases of alleged
              sexual harassment.
            </li>
            <li className="text-muted">
              • The alleged offence/s may be repeated if the employee continues in his/her role and retains access to
              the employer’s premises and equipment. For example, in cases of alleged theft or gross negligence that
              caused damage to employer property.
            </li>
            <li className="text-muted">• When the health and safety of fellow employees is at risk.</li>
          </ul>
        </p>
        <br />
        <p>
          <span className="text-muted">
            Only if such is reasonably foreseeable, should you suspend the employee pending an investigation or
            disciplinary hearing. It is reserved for serious cases of alleged misconduct.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold text-dark">
            What does the case law and your Disciplinary Code say about the process to be followed when suspending?
          </div>
          <span className="text-muted">
            According to latest case law, it remains the employer’s prerogative to suspend a person against whom serious
            allegations of misconduct have arisen. As the employer, you may thus apply your mind, decide to suspend and
            inform the employee accordingly by issuing him/her with a formal Letter of Suspension. Indicate that the
            process is confidential and should be treated as such.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            However, you <span className="text-underline">must</span> take note of the provisions contained in your own
            Disciplinary Code. Some Codes prescribe a process whereby the employee is first given an opportunity to
            submit reasons why he/she should not be suspended. Only after consideration of such submissions, can the
            employer take a decision on this matter. You need to comply with these prescripts to avoid the suspension
            from being procedurally unfair. Typically it takes the form of a pre-suspension discussion, which should be
            documented. LabourTeq provides for this discussion, <span className="text-underline">if applicable</span>.
            In very rare circumstances, it is acceptable to forego this discussion if very strong substantive reasons
            exist to do so, and where it is necessary to have the employee leave the employer’s premises immediately,
            for example if there’s a clear threat of harm to self or others.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold text-dark">
            If required, what should happen at a pre-suspension discussion?
          </div>
          <span className="text-muted">
            You should have an HR representative or line management present who can attest to the discussion, if
            required at a later point in time. Arrange to meet the employee in a meeting room or suitable space,
            preferably one as private as possible. Provide a summary of the allegations arising and why you think it may
            be necessary to suspend the employee at this time. This may include the seriousness and nature of the
            alleged offence, concerns about potential interference in the investigation process or damage to employer
            systems or property, and/or concerns about potential behaviour and its impact on fellow employees. Give the
            employee an opportunity to respond.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            If the person for whatever reason is not at the workplace, you can request written submissions on the issue
            to be handed in within a prescribed time period, as alternative. He/she should not attend the workplace
            until a decision has been made to suspend or not.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            You are <span className="text-underline">not</span> obligated to disclose specific witness names during such
            discussion.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            Once the employee has made his/her submissions as to why s/he should not be suspended, request that the
            employee remain in the meeting room whilst you exit to deliberate with appropriate role players and make a
            decision. Should you feel that the employee may present a security risk, arrange for a security officer to
            wait outside the room. Brief the security officer to behave in a low key way, including if it is necessary
            to escort the employee out of the workplace.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            When returning to the meeting room, indicate whether the person will be suspended or not. If suspended,
            communicate the decision, succinctly provide reasons for the decision, and issue the Letter of Suspension.
            In both cases, explain the way forward and how the investigation or hearing process will proceed. Indicate
            that the process is confidential and should be treated as such.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            The employee is required to hand in employer property. Then escort him/her off the premises, alternatively,
            arrange for him/her to be escorted off premises by the security officer. Inform your IT and Security
            Departments of the person’s suspension and restrict his/her access to your systems and premises.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold text-dark">How do I issue a Letter of Suspension?</div>
          <span className="text-muted">
            Prepare two copies of the Letter of Suspension. Hand deliver a copy of the Letter to the employee whilst on
            your premises and read the content to him/her. Ask him/her to sign a copy for your records. If he/she
            refuses to sign in acknowledgement, get a witness to sign that a copy of the letter was handed to the
            employee. Verify or record the employee’s contact telephone number and home address where he/she can be
            contacted whilst suspended.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            Ask the employee to return all employer property, including computers and access cards. Allow the employee
            time to collect his/her personal belongings. Typically, he/she would be accompanied whilst doing this.
            Alternatively, collect the employee’s personal belongings after hours and arrange for delivery to the
            employee’s home. Escort the employee out of the premises or request security assistance, if appropriate.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            In exceptional circumstances, the person may already be off premises when suspended. The Letter may be
            delivered to his/her last known address or sent via e-mail. Again, acknowledgement of receipt is required.
            If none is forthcoming, a messenger or courier can sign that a copy was delivered to the employee’s last
            known address.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold text-dark">Must the employee be remunerated whilst on suspension?</div>
          <span className="text-muted">
            Yes, suspension pending a disciplinary investigation or pending a disciplinary hearing is{' '}
            <span className="text-underline">always with full pay</span>.
          </span>
        </p>
        <br />
        <p>
          <div className="font-weight-bold text-dark">For how long may the employee be suspended?</div>
          <span className="text-muted">
            There is no maximum period prescribed in law, but principles of fairness dictate that it should not be for
            an unduly long period.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            The period may be influenced by the complexity of the matter and the need for an in-depth disciplinary
            investigation. Suspending an employee for an unreasonably long period may constitute an actionable unfair
            labour practice. It also does not make business sense, as the employee is suspended with pay. It is thus in
            both parties’ interest to act within a reasonable time period.
          </span>
        </p>
        <br />
        <p>
          <span className="text-muted">
            You need to check whether your own Disciplinary Code or collective instrument, if any, contains any
            prescripts in this regard.
          </span>
        </p>
      </GuidanceModal>
      <Formik
        initialValues={initialValues}
        validationSchema={RecordSuspensionDiscussionSchema}
        onSubmit={(values: FormikValues) => submitValues(values)}
      >
        {({ values, errors, handleSubmit, setFieldValue, validateForm }: FormikProps<FormikValues>): ReactElement => (
          <>
            <Row>
              <Form className="col-md-12">
                <RecordSuspensionDiscussionForm />
              </Form>
            </Row>
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
              onCancel={() => console.log('cancel')}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default RecordSuspensionDiscussion;
