import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from './PDFStyles.style';

const Paragraph: React.FC = ({ children }) => {
  return (
    <View style={{ ...styles.text, ...styles.fonts, ...styles.paragraph }}>
      <Text>{children}</Text>
    </View>
  );
};

export default Paragraph;
