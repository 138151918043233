export const toTitleCase = (str: string, separator: string = ' '): string => {
  if (!str) return '';
  const splitStr = str.toLowerCase().split(separator);
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const toEnumCase = (str: string, separator: string): string => {
  const splitStr = str.toUpperCase().split(separator);
  return splitStr.join('_');
};
