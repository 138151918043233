export const schema = {
    "models": {
        "Employee": {
            "name": "Employee",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "organisationId": {
                    "name": "organisationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "employeeNumber": {
                    "name": "employeeNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "idNumber": {
                    "name": "idNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "passportNumber": {
                    "name": "passportNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "firstName": {
                    "name": "firstName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "lastName": {
                    "name": "lastName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "department": {
                    "name": "department",
                    "isArray": false,
                    "type": {
                        "model": "Department"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id"
                    }
                },
                "departmentID": {
                    "name": "departmentID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "jobTitle": {
                    "name": "jobTitle",
                    "isArray": false,
                    "type": {
                        "model": "JobTitle"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id"
                    }
                },
                "jobTitleID": {
                    "name": "jobTitleID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "jobGrade": {
                    "name": "jobGrade",
                    "isArray": false,
                    "type": {
                        "model": "JobGrade"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id"
                    }
                },
                "jobGradeID": {
                    "name": "jobGradeID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "jobLevel": {
                    "name": "jobLevel",
                    "isArray": false,
                    "type": {
                        "model": "JobLevel"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id"
                    }
                },
                "jobLevelID": {
                    "name": "jobLevelID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "directManagerID": {
                    "name": "directManagerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "directManager": {
                    "name": "directManager",
                    "isArray": false,
                    "type": {
                        "model": "Employee"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id"
                    }
                },
                "allocatedManagerID": {
                    "name": "allocatedManagerID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "allocatedManager": {
                    "name": "allocatedManager",
                    "isArray": false,
                    "type": {
                        "model": "Employee"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id"
                    }
                },
                "hireDate": {
                    "name": "hireDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "race": {
                    "name": "race",
                    "isArray": false,
                    "type": {
                        "enum": "RaceType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "phoneNumbers": {
                    "name": "phoneNumbers",
                    "isArray": true,
                    "type": {
                        "nonModel": "PhoneNumber"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "physicalAddresses": {
                    "name": "physicalAddresses",
                    "isArray": true,
                    "type": {
                        "nonModel": "PhysicalAddress"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "emails": {
                    "name": "emails",
                    "isArray": true,
                    "type": {
                        "nonModel": "EmailAddress"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "hasDisability": {
                    "name": "hasDisability",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "location": {
                    "name": "location",
                    "isArray": false,
                    "type": {
                        "model": "Location"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id"
                    }
                },
                "locationID": {
                    "name": "locationID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "placeOfWork": {
                    "name": "placeOfWork",
                    "isArray": false,
                    "type": {
                        "enum": "PlaceOfWorkType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "workHours": {
                    "name": "workHours",
                    "isArray": false,
                    "type": {
                        "enum": "WorkHoursType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "startTime": {
                    "name": "startTime",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "endTime": {
                    "name": "endTime",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "StatusType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "disability": {
                    "name": "disability",
                    "isArray": false,
                    "type": {
                        "enum": "DisabilityType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "disabilityDescription": {
                    "name": "disabilityDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "gender": {
                    "name": "gender",
                    "isArray": false,
                    "type": {
                        "enum": "GenderType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "approvedChairperson": {
                    "name": "approvedChairperson",
                    "isArray": false,
                    "type": {
                        "enum": "ApprovedChairPersonType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "approvedHcApprover": {
                    "name": "approvedHcApprover",
                    "isArray": false,
                    "type": {
                        "enum": "approvedHcApprover"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "suspensionDate": {
                    "name": "suspensionDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Employees",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "aws_api_key",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byDirectManager",
                        "fields": [
                            "directManagerID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAllocatedManager",
                        "fields": [
                            "allocatedManagerID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byLocation",
                        "fields": [
                            "locationID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byDepartment",
                        "fields": [
                            "departmentID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byJobTitle",
                        "fields": [
                            "jobTitleID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byJobGrade",
                        "fields": [
                            "jobGradeID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byJobLevel",
                        "fields": [
                            "jobLevelID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "userPools",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Department": {
            "name": "Department",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "organisationID": {
                    "name": "organisationID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Departments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "aws_api_key",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganisation",
                        "fields": [
                            "organisationID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "userPools",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "JobTitle": {
            "name": "JobTitle",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "organisationID": {
                    "name": "organisationID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "JobTitles",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "aws_api_key",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganisation",
                        "fields": [
                            "organisationID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "userPools",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "JobGrade": {
            "name": "JobGrade",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "organisationID": {
                    "name": "organisationID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "JobGrades",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "aws_api_key",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganisation",
                        "fields": [
                            "organisationID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "userPools",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "JobLevel": {
            "name": "JobLevel",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "organisationID": {
                    "name": "organisationID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "JobLevels",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "aws_api_key",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganisation",
                        "fields": [
                            "organisationID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "userPools",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Location": {
            "name": "Location",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "organisationID": {
                    "name": "organisationID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Locations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "aws_api_key",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganisation",
                        "fields": [
                            "organisationID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "userPools",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Case": {
            "name": "Case",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "caseNumber": {
                    "name": "caseNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "employeeId": {
                    "name": "employeeId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "issueDescription": {
                    "name": "issueDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sanction": {
                    "name": "sanction",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "expiryDate": {
                    "name": "expiryDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "transgression": {
                    "name": "transgression",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "offence": {
                    "name": "offence",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "caseType": {
                    "name": "caseType",
                    "isArray": false,
                    "type": {
                        "enum": "CaseType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "incidents": {
                    "name": "incidents",
                    "isArray": true,
                    "type": {
                        "nonModel": "Incident"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "investigators": {
                    "name": "investigators",
                    "isArray": true,
                    "type": {
                        "nonModel": "Investigator"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "processInstanceId": {
                    "name": "processInstanceId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Cases",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                }
            ]
        },
        "PastCase": {
            "name": "PastCase",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "caseNumber": {
                    "name": "caseNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "employeeId": {
                    "name": "employeeId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "organisationId": {
                    "name": "organisationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sanction": {
                    "name": "sanction",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "expiryDate": {
                    "name": "expiryDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "transgression": {
                    "name": "transgression",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "shortfallDescription": {
                    "name": "shortfallDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "frequency": {
                    "name": "frequency",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "caseType": {
                    "name": "caseType",
                    "isArray": false,
                    "type": {
                        "enum": "CaseType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "caseStatus": {
                    "name": "caseStatus",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "caseFiles": {
                    "name": "caseFiles",
                    "isArray": true,
                    "type": {
                        "nonModel": "BucketFile"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            },
            "syncable": true,
            "pluralName": "PastCases",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                }
            ]
        },
        "Organisation": {
            "name": "Organisation",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "organisationName": {
                    "name": "organisationName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "vatNumber": {
                    "name": "vatNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "webUrl": {
                    "name": "webUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "registrationNumber": {
                    "name": "registrationNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "mainContactLastName": {
                    "name": "mainContactLastName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "mainContactFirstName": {
                    "name": "mainContactFirstName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "mainContactEmail": {
                    "name": "mainContactEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "mainContactNumber": {
                    "name": "mainContactNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ccmaContactName": {
                    "name": "ccmaContactName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ccmaContactEmail": {
                    "name": "ccmaContactEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ccmaContactPhysicalAddress": {
                    "name": "ccmaContactPhysicalAddress",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "facilitiesEmail": {
                    "name": "facilitiesEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "payrollEmail": {
                    "name": "payrollEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "itEmail": {
                    "name": "itEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "switchboardTelephoneNumber": {
                    "name": "switchboardTelephoneNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "companyHeadcount": {
                    "name": "companyHeadcount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "maxEmployees": {
                    "name": "maxEmployees",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "termsAndConditions": {
                    "name": "termsAndConditions",
                    "isArray": false,
                    "type": {
                        "nonModel": "BucketFile"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "logo": {
                    "name": "logo",
                    "isArray": false,
                    "type": {
                        "nonModel": "BucketFile"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "poorPerformancePolicy": {
                    "name": "poorPerformancePolicy",
                    "isArray": false,
                    "type": {
                        "nonModel": "BucketFile"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "disciplinaryPolicy": {
                    "name": "disciplinaryPolicy",
                    "isArray": false,
                    "type": {
                        "nonModel": "BucketFile"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "logoPosition": {
                    "name": "logoPosition",
                    "isArray": false,
                    "type": {
                        "enum": "LogoPosition"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "OrganisationType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "locations": {
                    "name": "locations",
                    "isArray": true,
                    "type": {
                        "model": "Location"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "organisationID"
                    }
                },
                "departments": {
                    "name": "departments",
                    "isArray": true,
                    "type": {
                        "model": "Department"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "organisationID"
                    }
                },
                "disciplinaryCode": {
                    "name": "disciplinaryCode",
                    "isArray": true,
                    "type": {
                        "nonModel": "Transgression"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "useCustomDatesForReportingPeriod": {
                    "name": "useCustomDatesForReportingPeriod",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "useHCApprover": {
                    "name": "useHCApprover",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "defaultReportingPeriod": {
                    "name": "defaultReportingPeriod",
                    "isArray": false,
                    "type": {
                        "enum": "ReportingPeriod"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "monthForEndOfReportingYear": {
                    "name": "monthForEndOfReportingYear",
                    "isArray": false,
                    "type": {
                        "enum": "Month"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "customReportingPeriodStartDate": {
                    "name": "customReportingPeriodStartDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customReportingPeriodEndDate": {
                    "name": "customReportingPeriodEndDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "hrContactUserID": {
                    "name": "hrContactUserID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "hrContactUser": {
                    "name": "hrContactUser",
                    "isArray": false,
                    "type": {
                        "model": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id"
                    }
                }
            },
            "syncable": true,
            "pluralName": "Organisations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "aws_api_key",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "userPools",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "User": {
            "name": "User",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cognitoSub": {
                    "name": "cognitoSub",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "emailAddress": {
                    "name": "emailAddress",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "firstName": {
                    "name": "firstName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "lastName": {
                    "name": "lastName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "active": {
                    "name": "active",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "fontPreference": {
                    "name": "fontPreference",
                    "isArray": false,
                    "type": {
                        "enum": "FontPreference"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "roles": {
                    "name": "roles",
                    "isArray": true,
                    "type": {
                        "enum": "UserRole"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "organisationId": {
                    "name": "organisationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "employee": {
                    "name": "employee",
                    "isArray": false,
                    "type": {
                        "model": "Employee"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "userEmployeeId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "Users",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "aws_api_key",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "userPools",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Notification": {
            "name": "Notification",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "masterProcessInstanceId": {
                    "name": "masterProcessInstanceId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "notificationType": {
                    "name": "notificationType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "organisationId": {
                    "name": "organisationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "lastModifiedDate": {
                    "name": "lastModifiedDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "read": {
                    "name": "read",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "userID": {
                    "name": "userID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Notifications",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "aws_api_key",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganisationIdAndLastModifiedDate",
                        "fields": [
                            "organisationId",
                            "lastModifiedDate"
                        ],
                        "queryField": "byOrganisationIdAndLastModifiedDate"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "userPools",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Note": {
            "name": "Note",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "processInstanceId": {
                    "name": "processInstanceId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "NoteType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "createdDate": {
                    "name": "createdDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "lastModifiedDate": {
                    "name": "lastModifiedDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "note": {
                    "name": "note",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "deleted": {
                    "name": "deleted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Notes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "aws_api_key",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byProcessInstanceId",
                        "fields": [
                            "processInstanceId"
                        ],
                        "queryField": "byProcessInstanceId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byProcessInstanceIdAndUserId",
                        "fields": [
                            "processInstanceId",
                            "userId"
                        ],
                        "queryField": "byProcessInstanceIdAndUserId"
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "id"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "userPools",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "DisciplinaryMatrix": {
            "name": "DisciplinaryMatrix",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "organisationId": {
                    "name": "organisationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "transgressions": {
                    "name": "transgressions",
                    "isArray": true,
                    "type": {
                        "nonModel": "Transgression"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                }
            },
            "syncable": true,
            "pluralName": "DisciplinaryMatrices",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                }
            ]
        },
        "TemplateDocumentVersion": {
            "name": "TemplateDocumentVersion",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "processInstanceId": {
                    "name": "processInstanceId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "bucketPath": {
                    "name": "bucketPath",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "templateType": {
                    "name": "templateType",
                    "isArray": false,
                    "type": {
                        "enum": "TemplateType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "stringifiedContent": {
                    "name": "stringifiedContent",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "parentID": {
                    "name": "parentID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "organisationID": {
                    "name": "organisationID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "auditLogs": {
                    "name": "auditLogs",
                    "isArray": true,
                    "type": {
                        "model": "AuditLog"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "templateDocumentVersion"
                    }
                },
                "uploadedFileBucketPath": {
                    "name": "uploadedFileBucketPath",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "signedCopy": {
                    "name": "signedCopy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "TemplateDocumentVersions",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "aws_api_key",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "userPools",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "AuditLog": {
            "name": "AuditLog",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userID": {
                    "name": "userID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "user": {
                    "name": "user",
                    "isArray": false,
                    "type": {
                        "model": "User"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id"
                    }
                },
                "organisation": {
                    "name": "organisation",
                    "isArray": false,
                    "type": {
                        "model": "Organisation"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "organisationID"
                    }
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "AuditLogEventType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "timeStamp": {
                    "name": "timeStamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "content": {
                    "name": "content",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "taskId": {
                    "name": "taskId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "processInstanceId": {
                    "name": "processInstanceId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "templateDocumentVersion": {
                    "name": "templateDocumentVersion",
                    "isArray": false,
                    "type": {
                        "model": "TemplateDocumentVersion"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "auditLogTemplateDocumentVersionId"
                    }
                },
                "userRole": {
                    "name": "userRole",
                    "isArray": false,
                    "type": {
                        "enum": "UserRole"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "userRoles": {
                    "name": "userRoles",
                    "isArray": true,
                    "type": {
                        "enum": "UserRole"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "caseRole": {
                    "name": "caseRole",
                    "isArray": false,
                    "type": {
                        "enum": "CaseRole"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "caseRoles": {
                    "name": "caseRoles",
                    "isArray": true,
                    "type": {
                        "enum": "CaseRole"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "relevantEmployeeId": {
                    "name": "relevantEmployeeId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "dummyVar": {
                    "name": "dummyVar",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "emailType": {
                    "name": "emailType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "AuditLogs",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "aws_cognito_user_pools",
                    "properties": {}
                },
                {
                    "type": "aws_iam",
                    "properties": {}
                },
                {
                    "type": "aws_api_key",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTimeStamp",
                        "fields": [
                            "timeStamp"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "ByDummyVar",
                        "fields": [
                            "dummyVar",
                            "timeStamp"
                        ],
                        "queryField": "auditLogsByDummyVar"
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "provider": "userPools",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "RaceType": {
            "name": "RaceType",
            "values": [
                "BLACK",
                "AFRICAN",
                "COLOURED",
                "WHITE",
                "INDIAN",
                "OTHER"
            ]
        },
        "PhoneNumberType": {
            "name": "PhoneNumberType",
            "values": [
                "WORK",
                "HOME",
                "MOBILE"
            ]
        },
        "PhysicalAddressType": {
            "name": "PhysicalAddressType",
            "values": [
                "WORK",
                "RESIDENCE"
            ]
        },
        "Province": {
            "name": "Province",
            "values": [
                "GAUTENG",
                "FREE_STATE",
                "EASTERN_CAPE",
                "KWAZULU_NATAL",
                "LIMPOPO",
                "MPUMALANGA",
                "NORTHERN_CAPE",
                "NORTH_WEST",
                "WESTERN_CAPE"
            ]
        },
        "EmailAddressType": {
            "name": "EmailAddressType",
            "values": [
                "WORK",
                "PERSONAL"
            ]
        },
        "PlaceOfWorkType": {
            "name": "PlaceOfWorkType",
            "values": [
                "ON_SITE",
                "REMOTE",
            ]
        },
        "WorkHoursType": {
            "name": "WorkHoursType",
            "values": [
                "FULL_TIME",
                "PART_TIME",
                "FLEXITIME"
            ]
        },
        "StatusType": {
            "name": "StatusType",
            "values": [
                "PERMANENT",
                "FIXED_TERM",
                "PROBATION"
            ]
        },
        "DisabilityType": {
            "name": "DisabilityType",
            "values": [
                "PHYSICAL",
                "SENSORY",
                "COGNITIVE_AND_LEARNING",
                "MENTAL_HEALTH",
                "OTHER"
            ]
        },
        "GenderType": {
            "name": "GenderType",
            "values": [
                "MALE",
                "FEMALE",
                "OTHER"
            ]
        },
        "CaseType": {
            "name": "CaseType",
            "values": [
                "NONE",
                "MISCONDUCT",
                "POOR_PERFORMANCE",
                "ILL_HEALTH",
                "RETRENCHMENTS",
                "GRIEVANCES",
                "DISPUTES"
            ]
        },
        "ReportingPeriod": {
            "name": "ReportingPeriod",
            "values": [
                "YEAR",
                "QUARTER",
                "MONTH"
            ]
        },
        "LogoPosition": {
            "name": "LogoPosition",
            "values": [
                "LEFT",
                "RIGHT",
                "CENTER"
            ]
        },
        "OrganisationType": {
            "name": "OrganisationType",
            "values": [
                "SINGLE",
                "HOLDING"
            ]
        },
        "TransgressionCategory": {
            "name": "TransgressionCategory",
            "values": [
                "TIME_KEEPING_AND_ATTENDANCE",
                "WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES",
                "ALCOHOL_AND_SUBSTANCES",
                "VIOLENCE_AND_AGGRESSIVE_BEHAVIOR",
                "EMPLOYER_REPUTATION",
                "HEALTH_SAFETY_AND_SECURITY",
                "UNPROFESSIONAL_CONDUCT",
                "CORPORATE_GOVERNANCE",
                "REMOTE_WORK",
                "DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT",
                "PROPERTY_AND_EQUIPMEMT",
                "INDUSTRIAL_ACTION",
                "VEHICLES",
                "CRIMINAL_OFFENCES",
                "CONFIDENTIAL_INFORMATION_AND_DATA_BREACH",
                "DISHONESTY",
                "IT_RESOURCES_AND_SOCIAL_MEDIA",
                "GENERAL",
                "COVID_19"
            ]
        },
        "MatrixSanctionNames": {
            "name": "MatrixSanctionNames",
            "values": [
                "VERBAL_WARNING",
                "WRITTEN_WARNING",
                "FINAL_WRITTEN_WARNING",
                "DISMISSAL",
                "NOT_APPLICABLE"
            ]
        },
        "Month": {
            "name": "Month",
            "values": [
                "JANUARY",
                "FEBRUARY",
                "MARCH",
                "APRIL",
                "MAY",
                "JUNE",
                "JULY",
                "AUGUST",
                "SEPTEMBER",
                "OCTOBER",
                "NOVEMBER",
                "DECEMBER"
            ]
        },
        "FontPreference": {
            "name": "FontPreference",
            "values": [
                "DYSLEXIC",
                "DEFAULT"
            ]
        },
        "UserRole": {
            "name": "UserRole",
            "values": [
                "SUPER_ADMIN",
                "COMPANY_ADMIN",
                "HR_MANAGER",
                "LINE_EXECUTIVE",
                "LINE_MANAGER",
                "CASE_USER"
            ]
        },
        "NoteType": {
            "name": "NoteType",
            "values": [
                "PERSONAL",
                "CASE"
            ]
        },
        "DiscussionSanctionNames": {
            "name": "DiscussionSanctionNames",
            "values": [
                "VERBAL_WARNING",
                "WRITTEN_WARNING",
                "FINAL_WRITTEN_WARNING"
            ]
        },
        "HearingSanctionNames": {
            "name": "HearingSanctionNames",
            "values": [
                "WRITTEN_WARNING",
                "FINAL_WRITTEN_WARNING",
                "SUMMARY_DISMISSAL",
                "DEMOTION",
                "DISMISSAL_WITH_NOTICE",
                "SUSPENSION_WITHOUT_PAY"
            ]
        },
        "CaseRole": {
            "name": "CaseRole",
            "values": [
                "INVESTIGATOR",
                "EMPLOYER_REPRESENTATIVE",
                "CHAIRPERSON",
                "CASE_MANAGER",
                "IT",
                "PAYROLL",
                "FACILITIES",
                "OTHER",
                "CCMA"
            ]
        },
        "Gender": {
            "name": "Gender",
            "values": [
                "MALE",
                "FEMALE",
                "OTHER"
            ]
        },
        "TemplateType": {
            "name": "TemplateType",
            "values": [
                "AGREED_DEMOTION_LETTER",
                "SUSPENSION_PENDING_INVESTIGATION_LETTER",
                "SUSPENSION_PENDING_HEARING_LETTER",
                "SUSPENSION_WITHOUT_PAY_LETTER",
                "WRITTEN_WARNING_DISCUSSION",
                "WRITTEN_WARNING_HEARING",
                "VERBAL_WARNING_DISCUSSION",
                "FINAL_WRITTEN_WARNING_DISCUSSION",
                "FINAL_WRITTEN_WARNING_HEARING",
                "DISMISSAL_WITH_NOTICE_LETTER",
                "DEMOTION_LETTER_MISCONDUCT",
                "DEMOTION_LETTER_POOR_PERFORMANCE",
                "SUCCESSFUL_CONCLUSION_PERFORMANCE_PROCESS_LETTER",
                "PERFORMANCE_IMPROVEMENT_PLAN",
                "PERFORMANCE_IMPROVEMENT_PLAN_REVISED",
                "EXTENSION_OF_PIP",
                "EXTENSION_OF_PIP_REVISED",
                "EXTENSION_OF_PIP_FOLLOWING_HEARING",
                "EXTENSION_OF_PIP_FOLLOWING_HEARING_REVISED",
                "NOTICE_OF_HEARING_MISCONDUCT",
                "NOTICE_OF_HEARING_POOR_PERFORMANCE",
                "HEARING_FINDINGS_FORM_POOR_PERFORMANCE",
                "HEARING_FINDINGS_FORM_POOR_PERFORMANCE_APPEAL",
                "SUMMARY_DISMISSAL_LETTER_POOR_PERFORMANCE",
                "SUMMARY_DISMISSAL_LETTER_MISCONDUCT",
                "DISMISSAL_WITH_NOTICE_PAY_LETTER_POOR_PERFORMANCE",
                "NOTICE_OF_HEARING_MISCONDUCT_APPEAL",
                "NOTICE_OF_HEARING_POOR_PERFORMANCE_APPEAL",
                "APPEAL_FORM_POOR_PERFORMANCE",
                "HEARING_OUTCOME_MISCONDUCT_APPEAL",
                "HEARING_OUTCOME_POOR_PERFORMANCE_APPEAL"
            ]
        },
        "AuditLogEventType": {
            "name": "AuditLogEventType",
            "values": [
                "EMPLOYEE_CREATED",
                "EMPLOYEE_EDITED",
                "USER_CREATED",
                "USER_EDITED",
                "BULK_EMPLOYEE_UPLOAD",
                "CASE_STARTED",
                "CASE_FILE_UPLOADED",
                "WORKFLOW_PROGRESSED",
                "DOCUMENT_CREATED",
                "DOCUMENT_NEW_VERSION",
                "DOCUMENT_APPROVAL_REQUESTED",
                "DOCUMENT_APPROVED",
                "DOCUMENT_EMAILED_TO_EMPLOYEE",
                "DOCUMENT_RECEIVED_BY_EMPLOYEE",
                "DOCUMENT_EMAILED_TO_NOMINEE",
                "DOCUMENT_RECEIVED_BY_NOMINEE",
                "DOCUMENT_DOWNLOADED",
                "DOCUMENT_UPLOADED",
                "DOCUMENT_SIGNED",
                "EMAIL_RECEIPT_CONFIRMED",
                "EMAIL_SENT"
            ]
        }
    },
    "nonModels": {
        "PhoneNumber": {
            "name": "PhoneNumber",
            "fields": {
                "number": {
                    "name": "number",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "phoneNumberType": {
                    "name": "phoneNumberType",
                    "isArray": false,
                    "type": {
                        "enum": "PhoneNumberType"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "PhysicalAddress": {
            "name": "PhysicalAddress",
            "fields": {
                "physicalAddressType": {
                    "name": "physicalAddressType",
                    "isArray": false,
                    "type": {
                        "enum": "PhysicalAddressType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "lineOne": {
                    "name": "lineOne",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lineTwo": {
                    "name": "lineTwo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "city": {
                    "name": "city",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "province": {
                    "name": "province",
                    "isArray": false,
                    "type": {
                        "enum": "Province"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "postalCode": {
                    "name": "postalCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "EmailAddress": {
            "name": "EmailAddress",
            "fields": {
                "emailAddressType": {
                    "name": "emailAddressType",
                    "isArray": false,
                    "type": {
                        "enum": "EmailAddressType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "address": {
                    "name": "address",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Incident": {
            "name": "Incident",
            "fields": {
                "summaryOfFacts": {
                    "name": "summaryOfFacts",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "date": {
                    "name": "date",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "time": {
                    "name": "time",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "location": {
                    "name": "location",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "complainants": {
                    "name": "complainants",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "witnesses": {
                    "name": "witnesses",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "Investigator": {
            "name": "Investigator",
            "fields": {
                "fullName": {
                    "name": "fullName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "emailAddress": {
                    "name": "emailAddress",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "cognitoId": {
                    "name": "cognitoId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "employeeId": {
                    "name": "employeeId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "investigatorTimeline": {
                    "name": "investigatorTimeline",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "BucketFile": {
            "name": "BucketFile",
            "fields": {
                "fileName": {
                    "name": "fileName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Transgression": {
            "name": "Transgression",
            "fields": {
                "transgressionCategory": {
                    "name": "transgressionCategory",
                    "isArray": false,
                    "type": {
                        "enum": "TransgressionCategory"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "transgression": {
                    "name": "transgression",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sanction": {
                    "name": "sanction",
                    "isArray": false,
                    "type": {
                        "nonModel": "SanctionType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "default": {
                    "name": "default",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "active": {
                    "name": "active",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "SanctionType": {
            "name": "SanctionType",
            "fields": {
                "firstOffence": {
                    "name": "firstOffence",
                    "isArray": false,
                    "type": {
                        "enum": "MatrixSanctionNames"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "secondOffence": {
                    "name": "secondOffence",
                    "isArray": false,
                    "type": {
                        "enum": "MatrixSanctionNames"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "thirdOffence": {
                    "name": "thirdOffence",
                    "isArray": false,
                    "type": {
                        "enum": "MatrixSanctionNames"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "fourthOffence": {
                    "name": "fourthOffence",
                    "isArray": false,
                    "type": {
                        "enum": "MatrixSanctionNames"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "fifthOffence": {
                    "name": "fifthOffence",
                    "isArray": false,
                    "type": {
                        "enum": "MatrixSanctionNames"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "sixthOffence": {
                    "name": "sixthOffence",
                    "isArray": false,
                    "type": {
                        "enum": "MatrixSanctionNames"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Discussion": {
            "name": "Discussion",
            "fields": {
                "discussionDate": {
                    "name": "discussionDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "discissionTime": {
                    "name": "discissionTime",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "discussionSummary": {
                    "name": "discussionSummary",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "attendees": {
                    "name": "attendees",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        }
    },
    "version": "ac01520c63bcf4149d63e328b8803670"
};