import { FormConfig } from './form-config-types';
import { ccmaDetailsA, ccmaDetailsB, selectWitnessAppeal } from './misconduct-forms';

export const caseOverview: FormConfig = {
  fields: {
    caseType: {
      type: 'string',
      label: 'Case Type',
    },
    caseNumber: {
      type: 'string',
      label: 'Case Number',
    },
    jobTitle: {
      type: 'string',
      label: 'Job Title',
    },
    jobDescriptionOrKPIDocuments: {
      type: 'documents',
      label: 'Job Description or KPI documents',
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: ['shortfallDescription', 'date','recurring', 'documents'],
    },
  },
};

export const caseAssessment: FormConfig = {
  fields: {
    performanceReviewFeedbackSummary: {
      type: 'string',
      label: 'Performance Review Feedback Summary',
    },
    informalMeetingSummary: {
      type: 'string',
      label: 'Informal Meeting Summary',
    },
    coachingSummary: {
      type: 'string',
      label: 'Coaching Summary',
    },
    onTheJobTrainingSummary: {
      type: 'string',
      label: 'On The Job training Summary',
    },
    extraInformationSummary: {
      type: 'string',
      label: 'Extra Information Summary',
    },
    classroomBasedSupportSummary: {
      type: 'string',
      label: 'Classroom-based support summary',
    },
    remedialActionsInstituted: {
      type: 'boolean',
      label: 'Remedial Actions Instituted',
    },
    performanceReviewFeedbackGiven: {
      type: 'boolean',
      label: 'Performance Review Feedback Given',
    },
    haveMetInformally: {
      type: 'boolean',
      label: 'Informal Meeting Held',
    },
    hasReceivedCoaching: {
      type: 'boolean',
      label: 'Has Received coaching',
    },
    hasReceivedOnTheJobTraining: {
      type: 'boolean',
      label: 'Has received on the job training',
    },
    hasReceivedClassroomBasedSupport: {
      type: 'boolean',
      label: 'Has received classroom-based support',
    },
    extraInformation: {
      type: 'boolean',
      label: 'Extra Information',
    },
    caseAssessmentSupportingDocuments: {
      type: 'documents',
      label: 'Case Assessment supporting documents',
    },
  },
};

export const prepareEmployeeDiscussion: FormConfig = {
  fields: {
    employeeDiscussionDate: {
      type: 'string',
      label: 'Discussion Date',
    },
    employeeDiscussionTime: {
      type: 'string',
      label: 'Discussion Time',
    },
    employeeDiscussionLocation: {
      type: 'string',
      label: 'Discussion Location',
    },
    compileInvite: {
      type: 'boolean',
      label: 'Compile Invitation',
    },
    employeeDiscussionAttendees: {
      type: 'table',
      label: 'Employee Discussion Attendees',
      tableColumns: ['attendeeName', 'attendeeEmailAddress'],
    },
  },
};

export const recordEmployeeDiscussion: FormConfig = {
  fields: {
    employeeDiscussionDate: {
      type: 'string',
      label: 'Discussion Date',
    },
    employeeDiscussionTime: {
      type: 'string',
      label: 'Discussion Time',
    },
    employeeDiscussionLocation: {
      type: 'string',
      label: 'Discussion Location',
    },
    employeeDiscussionSummary: {
      type: 'string',
      label: 'Employee Discussion Summary',
    },
    employeeDiscussionDocuments: {
      type: 'documents',
      label: 'Employee Discussion Documents',
    },
    employeeDiscussionAttendees: {
      type: 'table',
      label: 'Employee Discussion Attendees',
      tableColumns: ['attendeeName', 'attendeeEmailAddress'],
    },
  },
};

export const determineShortfalls: FormConfig = {
  fields: {
    reasonForDecision: {
      type: 'string',
      label: 'Reason For Decision',
    },
    wayForward: {
      type: 'string',
      label: 'Way Forward',
    },
    furtherActionRequired: {
      type: 'boolean',
      label: 'Further Action Required',
    },
  },
};

export const initiatePip: FormConfig = {
  fields: {
    pipDiscussionDate: {
      type: 'string',
      label: 'PIP Discussion Date',
    },
    pipDiscussionTime: {
      type: 'string',
      label: 'PIP Discussion Time',
    },
    pipDiscussionLocation: {
      type: 'string',
      label: 'PIP Discussion Location',
    },
    pipDuration: {
      type: 'string',
      label: 'PIP Duration',
    },
    compilePipDiscussionInvite: {
      type: 'boolean',
      label: 'Compile PIP Discussion Invite',
    },
    pipDiscussionAttendees: {
      type: 'table',
      label: 'PIP Discussion Attendees',
      tableColumns: ['attendeeName', 'attendeeEmailAddress'],
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: [
        'shortfallDescription',
        'performanceGoal',
        'qualityStandard',
        'deadline',
        'actionsToBeTakenByEmployee',
        'assistanceRequiredFromEmployer',
      ],
    },
    lastDocumentId: {
      type: 'string',
      label: 'Document ID',
      hideField: true,
    },
    lastDocumentType: {
      type: 'string',
      label: 'Document Type',
    },
    documentViewConfig: {
      type: 'documentViewer',
      label: 'View Document',
      templateDocumentFields: ['lastDocumentId', 'lastDocumentType'],
    },
  },
  hasDocument: true,
};

export const recordPipDiscussion: FormConfig = {
  fields: {
    pipDiscussionDocuments: {
      type: 'documents',
      label: 'PIP Discussion Documents',
    },
    pipDiscussionSummary: {
      type: 'string',
      label: 'PIP Discussion Summary',
    },
    pipDuration: {
      type: 'string',
      label: 'PIP Duration',
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: [
        'shortfallDescription',
        'performanceGoal',
        'qualityStandard',
        'deadline',
        'actionsToBeTakenByEmployee',
        'assistanceRequiredFromEmployer',
      ],
    },
    lastDocumentId: {
      type: 'string',
      label: 'Document ID',
      hideField: true,
    },
    lastDocumentType: {
      type: 'string',
      label: 'Document Type',
    },
    documentViewConfig: {
      type: 'documentViewer',
      label: 'View Document',
      templateDocumentFields: ['lastDocumentId', 'lastDocumentType'],
    },
  },
  hasDocument: true,
};

export const recordPipProgressMeeting: FormConfig = {
  fields: {
    pipProgressMeetings: {
      type: 'table',
      label: 'PIP Progress Meetings',
      tableColumns: [
        'pipProgressMeetingDate',
        'pipProgressMeetingTime',
        'pipProgressMeetingLocation',
        'pipProgressMeetingAttendees',
      ],
      ignoredFields: ['label', 'value', 'attendeeId'],
    },

    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: [
        'shortfallDescription',
        'performanceGoal',
        'qualityStandard',
        'deadline',
        'actionsToBeTakenByEmployee',
        'assistanceRequiredFromEmployer',
        'pipProgressUpdates',
      ],
    },
  },
};

export const completePipSession: FormConfig = {
  fields: {
    pipProgressMeetings: {
      type: 'table',
      label: 'PIP Progress Meetings',
      tableColumns: [
        'pipProgressMeetingDate',
        'pipProgressMeetingTime',
        'pipProgressMeetingLocation',
        'pipProgressMeetingAttendees',
      ],
      ignoredFields: ['label', 'value', 'attendeeId'],
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: [
        'shortfallDescription',
        'performanceGoal',
        'qualityStandard',
        'deadline',
        'actionsToBeTakenByEmployee',
        'assistanceRequiredFromEmployer',
        'pipProgressUpdates',
      ],
    },
  },
};

export const initiateExtendedPip: FormConfig = {
  fields: {
    pipDiscussionDate: {
      type: 'string',
      label: 'Extended PIP Discussion Date',
    },
    pipDiscussionTime: {
      type: 'string',
      label: 'Extended PIP Discussion Time',
    },
    pipDiscussionLocation: {
      type: 'string',
      label: 'Extended PIP Discussion Location',
    },
    pipDuration: {
      type: 'string',
      label: 'Extended PIP Duration',
    },
    compilePipDiscussionInvite: {
      type: 'boolean',
      label: 'Compile Extended PIP Discussion Invite',
    },
    pipDiscussionAttendees: {
      type: 'table',
      label: 'Extended PIP Discussion Attendees',
      tableColumns: ['attendeeName', 'attendeeEmailAddress'],
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: [
        'shortfallDescription',
        'performanceGoal',
        'qualityStandard',
        'deadline',
        'actionsToBeTakenByEmployee',
        'assistanceRequiredFromEmployer',
      ],
    },
    lastDocumentId: {
      type: 'string',
      label: 'Document ID',
      hideField: true,
    },
    lastDocumentType: {
      type: 'string',
      label: 'Document Type',
    },
    documentViewConfig: {
      type: 'documentViewer',
      label: 'View Document',
      templateDocumentFields: ['lastDocumentId', 'lastDocumentType'],
    },
  },
  hasDocument: true,
};

export const recordExtendedPipDiscussion: FormConfig = {
  fields: {
    pipDiscussionDocuments: {
      type: 'documents',
      label: 'Extended PIP Discussion Documents',
    },
    pipDiscussionSummary: {
      type: 'string',
      label: 'Extended PIP Discussion Summary',
    },
    pipDuration: {
      type: 'string',
      label: 'Extended PIP Duration',
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: [
        'shortfallDescription',
        'performanceGoal',
        'qualityStandard',
        'deadline',
        'actionsToBeTakenByEmployee',
        'assistanceRequiredFromEmployer',
      ],
    },
    lastDocumentId: {
      type: 'string',
      label: 'Document ID',
      hideField: true,
    },
    lastDocumentType: {
      type: 'string',
      label: 'Document Type',
    },
    documentViewConfig: {
      type: 'documentViewer',
      label: 'View Document',
      templateDocumentFields: ['lastDocumentId', 'lastDocumentType'],
    },
  },
  hasDocument: true,
};

export const recordExtendedPipProgressMeeting: FormConfig = {
  fields: {
    pipProgressMeetings: {
      type: 'table',
      label: 'Extended PIP Progress Meetings',
      tableColumns: [
        'pipProgressMeetingDate',
        'pipProgressMeetingTime',
        'pipProgressMeetingLocation',
        'pipProgressMeetingAttendees',
      ],
      ignoredFields: ['label', 'value', 'attendeeId'],
    },

    performanceShortfalls: {
      type: 'table',
      label: 'Extended Performance Shortfalls',
      tableColumns: [
        'shortfallDescription',
        'performanceGoal',
        'qualityStandard',
        'deadline',
        'actionsToBeTakenByEmployee',
        'assistanceRequiredFromEmployer',
        'pipProgressUpdates',
      ],
    },
  },
};

export const completeExtendedPipSession: FormConfig = {
  fields: {
    pipProgressMeetings: {
      type: 'table',
      label: 'Extended PIP Progress Meetings',
      tableColumns: [
        'pipProgressMeetingDate',
        'pipProgressMeetingTime',
        'pipProgressMeetingLocation',
        'pipProgressMeetingAttendees',
      ],
      ignoredFields: ['label', 'value', 'attendeeId'],
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: [
        'shortfallDescription',
        'performanceGoal',
        'qualityStandard',
        'deadline',
        'actionsToBeTakenByEmployee',
        'assistanceRequiredFromEmployer',
        'pipProgressUpdates',
      ],
    },
  },
};

export const preparePerformanceCounselling: FormConfig = {
  fields: {
    compileInvite: {
      type: 'string',
      label: 'Compile Invite',
    },
    // todo: add counselling attendees to array fields
    performanceCounsellingSessions: {
      type: 'table',
      label: 'Performance Counselling Sessions',
      tableColumns: ['counsellingDate', 'counsellingTime', 'counsellingLocation', 'counsellingAttendees'],
      ignoredFields: ['label', 'value', 'attendeeId'],
    },
  },
};

export const recordCounsellingDiscussion: FormConfig = {
  fields: {
    performanceCounsellingSessions: {
      type: 'table',
      label: 'Performance Counselling Sessions',
      tableColumns: [
        'counsellingDate',
        'counsellingTime',
        'counsellingLocation',
        'counsellingSummary',
        'counsellingAttendees',
      ],
      ignoredFields: ['label', 'value', 'attendeeId'],
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: ['shortfallDescription', 'counsellingProgressUpdates', 'documents'],
    },
  },
};

export const prepareAdditionalCounsellingSession: FormConfig = {
  fields: {
    reasonForFurtherActionAfterCounselling: {
      type: 'string',
      label: 'Reason for Further Counselling',
    },
    furtherActionAfterCounselling: {
      type: 'string',
      label: 'Further Action After Counselling',
    },
    additionalSessions: {
      type: 'boolean',
      label: 'Conduct additional sessions',
    },
    counsellingHavePerformanceConcernsBeenAddressed: {
      type: 'boolean',
      label: 'Performance concerns have been Addressed',
    },
    performanceCounsellingSessions: {
      type: 'table',
      label: 'Performance Counselling Sessions',
      tableColumns: [
        'counsellingDate',
        'counsellingTime',
        'counsellingLocation',
        'counsellingSummary',
        'counsellingAttendees',
      ],
      ignoredFields: ['label', 'value', 'attendeeId'],
    },
  },
};

export const pipAssessment: FormConfig = {
  fields: {
    furtherActionAfterPip: {
      type: 'string',
      label: 'Further Action after PIP',
    },
    reasonForFurtherActionAfterPip: {
      type: 'string',
      label: 'Reason for further action after PIP',
    },
    pipHavePerformanceConcernsBeenAddressed: {
      type: 'boolean',
      label: 'Have Performance concerns been addressed',
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: [
        'shortfallDescription',
        'performanceGoal',
        'qualityStandard',
        'deadline',
        'actionsToBeTakenByEmployee',
        'assistanceRequiredFromEmployer',
        'pipProgressUpdates',
        'howShortfallsWereAddressed',
        'finalStatus',
      ],
    },
  },
};

export const extendedPipAssessment: FormConfig = {
  fields: {
    furtherActionAfterPip: {
      type: 'string',
      label: 'Further Action after Extended PIP',
    },
    reasonForFurtherActionAfterPip: {
      type: 'string',
      label: 'Reason for further action after Extended PIP',
    },
    pipHavePerformanceConcernsBeenAddressed: {
      type: 'boolean',
      label: 'Have Performance concerns been addressed',
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: [
        'shortfallDescription',
        'performanceGoal',
        'qualityStandard',
        'deadline',
        'actionsToBeTakenByEmployee',
        'assistanceRequiredFromEmployer',
        'pipProgressUpdates',
        'howShortfallsWereAddressed',
        'finalStatus',
      ],
    },
  },
};

export const prepareAgreedDemotion: FormConfig = {
  fields: {
    demotionNewJobTitle: {
      type: 'string',
      label: 'New Job Title',
    },
    demotionNewJobLevel: {
      type: 'string',
      label: 'New Job Level',
    },
    demotionNewDepartment: {
      type: 'string',
      label: 'New Department',
    },
    demotionNewRemunerationPackage: {
      type: 'string',
      label: 'New Remuneration Package',
    },
    demotionNewTerms: {
      type: 'string',
      label: 'New Terms',
    },
    demotionDate: {
      type: 'string',
      label: 'Demotion Date',
    },
    lastDocumentId: {
      type: 'string',
      label: 'Document ID',
      hideField: true,
    },
    lastDocumentType: {
      type: 'string',
      label: 'Document Type',
    },
    documentViewConfig: {
      type: 'documentViewer',
      label: 'View Document',
      templateDocumentFields: ['lastDocumentId', 'lastDocumentType'],
    },
  },
  hasDocument: true,
};

export const notifyPayrollOfDemotion: FormConfig = {
  fields: {
    didEmployeeSignedDemotionLetterInAcceptance: {
      type: 'boolean',
      label: 'Employee Signed Demotion Letter in Acceptance',
    },
    demotionDiscussionDate: {
      type: 'string',
      label: 'Demotion Discussion Date',
    },
    demotionDiscussionTime: {
      type: 'string',
      label: 'Demotion Discussion Time',
    },
    demotionDiscussionLocation: {
      type: 'string',
      label: 'Demotion Discussion Location',
    },
    demotionDiscussionAttendees: {
      type: 'table',
      label: 'Demotion Discussion Attendees',
      tableColumns: ['attendeeName', 'attendeeEmailAddress'],
    },
  },
};

export const recordDemotionDiscussion: FormConfig = {
  fields: {
    demotionDiscussionDate: {
      type: 'string',
      label: 'Demotion Discussion Date',
    },
    demotionDiscussionTime: {
      type: 'string',
      label: 'Demotion Discussion Time',
    },
    demotionDiscussionLocation: {
      type: 'string',
      label: 'Demotion Discussion Location',
    },
    demotionDiscussionDocuments: {
      type: 'documents',
      label: 'Demotion Discussion Documents',
    },
    demotionDiscussionSummary: {
      type: 'string',
      label: 'Demotion Discussion Summary',
    },
    demotionDiscussionAttendees: {
      type: 'table',
      label: 'Demotion Discussion Attendees',
      tableColumns: ['attendeeName', 'attendeeEmailAddress'],
    },
  },
};

export const compileNoticeOfHearing: FormConfig = {
  fields: {
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: ['shortfallDescription', 'date'],
    },
    lastDocumentId: {
      type: 'string',
      label: 'Document ID',
      hideField: true,
    },
    lastDocumentType: {
      type: 'string',
      label: 'Document Type',
    },
    documentViewConfig: {
      type: 'documentViewer',
      label: 'View Document',
      templateDocumentFields: ['lastDocumentId', 'lastDocumentType'],
    },
  },
  hasDocument: true,
};

export const chairpersonsRuling: FormConfig = {
  fields: {
    initial_hearing_backgroundLeadingToHearing: {
      type: 'string',
      label: 'Background Leading to Hearing',
    },
    initial_hearing_employeeRightsExercisedInHearing: {
      type: 'string',
      label: 'Employee Rights Exercised in Hearing',
    },
    initial_hearing_summaryOfEmployerRepresentativeEvidence: {
      type: 'string',
      label: 'Employer Representative Evidence Summary',
    },
    initial_hearing_summaryOfEmployeesEvidence: {
      type: 'string',
      label: 'Employee Representative Evidence Summary',
    },
    initial_hearing_conclusionsReachedOnSubStandardPerformance: {
      type: 'string',
      label: 'Conclusions Reached on Substandard Performance',
    },
    initial_hearing_summaryOfAggravatingFactors: {
      type: 'string',
      label: 'Aggravating Factors summary',
    },
    initial_hearing_conclusionsReachedOnWayForward: {
      type: 'string',
      label: 'Conclusions Reached on way forward',
    },
    initial_hearing_summaryOfLegalPrinciples: {
      type: 'string',
      label: 'Legal Principles Summary',
    },
    initial_hearing_findingsOnWayForward: {
      type: 'string',
      label: 'Findings on way forward',
    },
    initial_hearing_employeeCommentsAndMitigatingFactors: {
      type: 'string',
      label: 'Employee Comments and mitigating factors',
    },
    initial_hearing_reasonForSelectingOther: {
      type: 'string',
      label: 'Reason for Selecting other',
    },
    initial_hearing_chairpersonsRulingDocuments: {
      type: 'documents',
      label: 'Chairpersons ruling documents',
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: ['shortfallDescription', 'date', 'incapacitatedBasedOnDisciplinaryFindings'],
    },
  },
};

export const confirmSanction: FormConfig = {
  fields: {
    overallSanction: {
      type: 'string',
      label: 'Overall Sanction',
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: [
        'shortfallDescription',
        'date',
        'initial_hearing_chairpersonsFindingOnGuilt',
        'initial_hearing_chairpersonsSanction',
      ],
    },
  },
};

export const executeOutcome: FormConfig = {
  fields: {
    hearingDate: {
      type: 'string',
      label: 'Date of Hearing',
    },
    warningValidityPeriod: {
      type: 'string',
      label: 'Warning Validity Period',
    },
    warningExpiryDate: {
      type: 'string',
      label: 'Warning Expiry Date',
    },
    dismissalDate: {
      type: 'string',
      label: 'Dismissal Date',
    },
    lastRemunerationDate: {
      type: 'string',
      label: 'Last remuneration date',
    },
    contactPersonInCaseOfCCMA: {
      type: 'string',
      label: 'Contact Person in Case of CCMA',
    },
    physicalAddress: {
      type: 'string',
      label: 'Physical Address',
    },
    emailAddress: {
      type: 'string',
      label: 'Email Address',
    },
    physicalExitDate: {
      type: 'string',
      label: 'Physical Exit Date',
    },
    periodOfUnpaidSuspension: {
      type: 'string',
      label: 'Period of unpaid suspension',
    },
    returnToWorkDate: {
      type: 'string',
      label: 'Return to work Date',
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: ['shortfallDescription', 'correctiveAction'],
    },
    lastDocumentId: {
      type: 'string',
      label: 'Document ID',
      hideField: true,
    },
    lastDocumentType: {
      type: 'string',
      label: 'Document Type',
    },
    documentViewConfig: {
      type: 'documentViewer',
      label: 'View Document',
      templateDocumentFields: ['lastDocumentId', 'lastDocumentType'],
    },
  },
  hasDocument: true,
};

export const startAppeal: FormConfig = {
  fields: {
    appealDeadline: {
      type: 'string',
      label: 'Appeal Deadline',
    },
    appealAllowed: {
      type: 'boolean',
      label: 'Appeal Allowed',
    },
    employeeHasIndicatedAppealIntent: {
      type: 'boolean',
      label: 'Employee has indicated intent to appeal',
    },
    appealReceivedWithinPrescribedTimeFrame: {
      type: 'boolean',
      label: 'Appeal Received within prescribed time frame',
    },
    proceedWithAppeal: {
      type: 'boolean',
      label: 'Proceed with appeal',
    },
    employeeAppealResponseDocuments: {
      type: 'documents',
      label: 'Employee Appeal Response Documents',
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Appeal Against',
      tableColumns: [
        'shortfallDescription',
        'initial_hearing_chairpersonsFindingOnGuilt',
        'didEmployeeAppealRuling',
        'initial_hearing_chairpersonsSanction',
        'didEmployeeAppealSanction',
      ],
    },
  },
};

export const chairpersonsRulingAppeal: FormConfig = {
  fields: {
    appeal_hearing_chairpersonsRulingDocuments: {
      type: 'documents',
      label: 'Chairperson Ruling Documents',
    },
    appeal_hearing_backgroundLeadingToHearing: {
      type: 'string',
      label: 'Background leading to Hearing',
    },
    appeal_hearing_summaryOfEmployerRepresentativesArguments: {
      type: 'string',
      label: `Summary of Employer Representative's arguments`,
    },
    appeal_hearing_summaryOfLegalPrinciples: {
      type: 'string',
      label: 'Summary of Legal Principles',
    },
    appeal_hearing_conclusionsReached: {
      type: 'string',
      label: 'Conclusions Reached',
    },
    appeal_hearing_newEvidenceConsidered: {
      type: 'string',
      label: 'New Evidence Considered',
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: ['shortfallDescription', 'guiltyFindingsConfirmed', 'sanctionConfirmed'],
    },
  },
};

export const confirmSanctionAppeal: FormConfig = {
  fields: {
    overallSanction: {
      type: 'string',
      label: 'Overall Sanction',
    },
    revisedOverallSanction: {
      type: 'string',
      label: 'Revised overall Sanction',
    },
    performanceShortfalls: {
      type: 'table',
      label: 'Charges',
      tableColumns: [
        'shortfallDescription',
        'initial_hearing_chairpersonsFinding',
        'didEmployeeAppealRuling',
        'appeal_hearing_chairpersonsFinding',
        'initial_hearing_chairpersonsSanction',
        'didEmployeeAppealSanction',
        'appeal_hearing_chairpersonsSanction',
        'appeal_hearing_chairpersonConfirmedSanction',
      ],
    },
  },
};

// hearing fields.
// Todo: move to common file
export const initial_hearing_AppointEmployerRepresentative: FormConfig = {
  fields: {
    initial_hearing_suspendPendingHearing: {
      type: 'boolean',
      label: 'Suspend Employee Pending Hearing',
    },
    initial_hearing_employerRepresentativeType: {
      type: 'string',
      label: 'Employer Representative Type',
    },
    initial_hearing_employerRepresentativeEmailAddress: {
      type: 'string',
      label: 'Employer Representative Email Address',
    },
    initial_hearing_employerRepresentativeFirstName: {
      type: 'string',
      label: 'Employer Representative First Name',
    },
    initial_hearing_employerRepresentativeLastName: {
      type: 'string',
      label: 'Employer Representative Last Name',
    },
  },
};

export const appointChairperson: FormConfig = {
  fields: {
    initial_hearing_chairpersonType: {
      type: 'string',
      label: 'Chairperson Type',
    },
    initial_hearing_chairpersonFirstName: {
      type: 'string',
      label: 'Chairperson First Name',
    },
    initial_hearing_chairpersonLastName: {
      type: 'string',
      label: 'Chairperson Last Name',
    },
    initial_hearing_chairpersonEmailAddress: {
      type: 'string',
      label: 'Chairperson Email Address',
    },
  },
};

export const selectWitness: FormConfig = {
  fields: {
    initial_hearing_hasWitness: {
      type: 'boolean',
      label: 'Witnesses Present?',
    },
    initial_hearing_hearingWitnesses: {
      type: 'table',
      label: 'Hearing Witnesses',
      tableColumns: ['firstName', 'lastName', 'emailAddress'],
    },
  },
};

export const hearingLogistics: FormConfig = {
  fields: {
    initial_hearing_hearingDate: {
      type: 'string',
      label: 'Hearing Date',
    },
    initial_hearing_hearingTime: {
      type: 'string',
      label: 'HearingTime',
    },
    initial_hearing_hearingLocation: {
      type: 'string',
      label: 'Hearing Location',
    },
    initial_hearing_chairpersonFirstName: {
      type: 'string',
      label: 'Chairperson First Name',
    },
    initial_hearing_chairpersonLastName: {
      type: 'string',
      label: 'Chairperson Last Name',
    },
    initial_hearing_chairpersonEmailAddress: {
      type: 'string',
      label: 'Chairperson Email Address',
    },
    initial_hearing_employerRepresentativeFirstName: {
      type: 'string',
      label: 'Employer Representative First Name',
    },
    initial_hearing_employerRepresentativeLastName: {
      type: 'string',
      label: 'Employer Representative Last Name',
    },
    initial_hearing_employerRepresentativeEmailAddress: {
      type: 'string',
      label: 'Employer Representative Email Address',
    },
    initial_hearing_hearingWitnesses: {
      type: 'table',
      label: 'Hearing Witnesses',
      tableColumns: ['firstName', 'lastName', 'emailAddress'],
    },
  },
};

export const employeeRightsAndResponses: FormConfig = {
  fields: {
    initial_hearing_requestedTranslator: {
      type: 'string',
      label: 'Requested Translator',
    },
    initial_hearing_language: {
      type: 'string',
      label: 'Hearing Language',
    },
    initial_hearing_requestedExternalRepresentation: {
      type: 'string',
      label: 'Requested External Representation',
    },
    initial_hearing_reasonForRequestingExternalRepresentation: {
      type: 'string',
      label: 'Reason for Requesting External Representation',
    },
    initial_hearing_EERepresentativePresent: {
      type: 'string',
      label: 'EE representative Present',
    },
    initial_hearing_reasonForEERepresentativePresence: {
      type: 'string',
      label: 'Reason for EE Representative Presence/Absence',
    },
    initial_hearing_disabledAndRequiresAccommodation: {
      type: 'string',
      label: 'Disabled and requires Accommodation',
    },
    initial_hearing_reasonForRequiringAccommodation: {
      type: 'string',
      label: 'Reason for requiring Accommodation',
    },
  },
};

export const minutesAndRecording: FormConfig = {
  fields: {
    initial_hearing_employerRepresentativeFirstName: {
      type: 'string',
      label: 'Employer Representative First Name',
    },
    initial_hearing_employerRepresentativeLastName: {
      type: 'string',
      label: 'Employer Representative Last Name',
    },
    initial_hearing_chairpersonFirstName: {
      type: 'string',
      label: 'Chairperson First Name',
    },
    initial_hearing_chairpersonLastName: {
      type: 'string',
      label: 'Chairperson Last Name',
    },
    initial_hearing_hearingWitnesses: {
      type: 'table',
      label: 'Hearing Witnesses',
      tableColumns: ['firstName', 'lastName', 'emailAddress', 'presentAtHearing'],
    },
    initial_hearing_hearingDate: {
      type: 'string',
      label: 'Hearing Date',
    },
    initial_hearing_hearingTime: {
      type: 'string',
      label: 'Hearing Time',
    },
    initial_hearing_hearingLocation: {
      type: 'string',
      label: 'Hearing Location',
    },
    initial_hearing_minutesOrRecordingsFiles: {
      type: 'documents',
      label: 'Minutes and Recordings',
    },
    initial_hearing_documentaryEvidenceFiles: {
      type: 'documents',
      label: 'Documentary Evidence',
    },
    initial_hearing_isChairpersonPresent: {
      type: 'boolean',
      label: 'Chairperson Present',
    },
    initial_hearing_isEmployeePresent: {
      type: 'boolean',
      label: 'Employee Present',
    },
    initial_hearing_isEmployerRepresentativePresent: {
      type: 'boolean',
      label: 'Employer Representative Present',
    },
    initial_hearing_reasonForDeclining: {
      type: 'string',
      label: 'Reason for Declining',
    },
  },
};

export const concludeHearing: FormConfig = {
  fields: {
    initial_hearing_deliveredSanctionOutcomeLetter: {
      type: 'boolean',
      label: 'Delivered Sanction Outcome Letter',
    },
    initial_hearing_acceptedDemotion: {
      type: 'string',
      label: 'Accepted Demotion',
    },
    initial_hearing_acceptedSuspensionWithoutPay: {
      type: 'string',
      label: 'Accepted Suspension Without Pay',
    },
  },
};

export const appointEmployerRepresentativeAppeal: FormConfig = {
  fields: {
    appeal_hearing_employerRepresentativeType: {
      type: 'string',
      label: 'Appeal Employer Representative Type',
    },
    appeal_hearing_employerRepresentativeEmailAddress: {
      type: 'string',
      label: 'Appeal Employer Representative Email Address',
    },
    appeal_hearing_employerRepresentativeFirstName: {
      type: 'string',
      label: 'Appeal Employer Representative First Name',
    },
    appeal_hearing_employerRepresentativeLastName: {
      type: 'string',
      label: 'Appeal Employer Representative Last Name',
    },
    appeal_hearing_suspendPendingHearing: {
      type: 'boolean',
      label: 'Appeal Suspend Pending Hearing',
    },
  },
};

export const appointChairpersonAppeal: FormConfig = {
  fields: {
    appeal_hearing_chairpersonType: {
      type: 'string',
      label: 'Appeal Chairperson Type',
    },
    appeal_hearing_chairpersonFirstName: {
      type: 'string',
      label: 'Appeal Chairperson First Name',
    },
    appeal_hearing_chairpersonLastName: {
      type: 'string',
      label: 'Appeal Chairperson Last Name',
    },
    appeal_hearing_chairpersonEmailAddress: {
      type: 'string',
      label: 'Appeal Chairperson Email Address',
    },
  },
};

export const hearingLogisticsAppeal: FormConfig = {
  fields: {
    appeal_hearing_hearingDate: {
      type: 'string',
      label: 'Appeal Hearing Date',
    },
    appeal_hearing_hearingTime: {
      type: 'string',
      label: 'Appeal Hearing Time',
    },
    appeal_hearing_hearingLocation: {
      type: 'string',
      label: 'Appeal Hearing Location',
    },
    appeal_hearing_ChairpersonFirstName: {
      type: 'string',
      label: 'Appeal Chairperson First Name',
    },
    appeal_hearing_ChairpersonLastName: {
      type: 'string',
      label: 'Appeal Chairperson Last Name',
    },
    appeal_hearing_ChairpersonEmailAddress: {
      type: 'string',
      label: 'Appeal Chairperson Email Address',
    },
    appeal_hearing_employerRepresentativeFirstName: {
      type: 'string',
      label: 'Appeal Employer Representative First Name',
    },
    appeal_hearing_employerRepresentativeLastName: {
      type: 'string',
      label: 'Appeal Employer Representative Last Name',
    },
    appeal_hearing_employerRepresentativeEmailAddress: {
      type: 'string',
      label: 'Appeal Employer Representative Email Address',
    },
    appeal_hearing_hearingWitnesses: {
      type: 'table',
      label: 'Appeal Hearing Witnesses',
      tableColumns: ['firstName', 'lastName', 'emailAddress'],
    },
  },
};

export const employeeRightsAndResponsesAppeal: FormConfig = {
  fields: {
    appeal_hearing_requestedTranslator: {
      type: 'string',
      label: 'Requested Translator (Appeal)',
    },
    appeal_hearing_language: {
      type: 'string',
      label: 'Hearing Language (Appeal)',
    },
    appeal_hearing_requestedExternalRepresentation: {
      type: 'string',
      label: 'Requested External Representation (Appeal)',
    },
    appeal_hearing_reasonForRequestingExternalRepresentation: {
      type: 'string',
      label: 'Reason for Requesting external Representation (Appeal)',
    },
    appeal_hearing_eERepresentativePresent: {
      type: 'string',
      label: 'EE Representative Present (Appeal)',
    },
    appeal_hearing_reasonForEeRepresentativePresence: {
      type: 'string',
      label: 'Reason for EE Representative (Appeal)',
    },
    appeal_hearing_disabledAndRequiresAccommodation: {
      type: 'string',
      label: 'Disabled and Requires accommodation (Appeal)',
    },
    appeal_hearing_reasonForRequiringAccommodation: {
      type: 'string',
      label: 'Reason for Requiring Accommodation (Appeal)',
    },
  },
};

export const concludeHearingAppeal: FormConfig = {
  fields: {
    appeal_hearing_deliveredSanctionOutcomeLetter: {
      type: 'boolean',
      label: 'Delivered Sanction Outcome Letter',
    },
    appeal_hearing_acceptedDemotion: {
      type: 'string',
      label: 'Accepted Demotion',
    },
    appeal_hearing_acceptedSuspensionWithoutPay: {
      type: 'string',
      label: 'Accepted Suspension without pay',
    },
    appeal_hearing_chairpersonsRulingDocuments: {
      type: 'documents',
      label: 'Appeal Hearing Chairperson Ruling Documents',
    },
  },
};

export const poorPerformanceFormConfig: Record<string, FormConfig> = {
  'case-overview': caseOverview,
  'case-assessment': caseAssessment,
  'pp-prepare-employee-discussion': prepareEmployeeDiscussion,
  'pp-record-employee-discussion': recordEmployeeDiscussion,
  'determine-shortfalls': determineShortfalls,
  'prepare-agreed-demotion': prepareAgreedDemotion,
  'notify-payroll-of-demotion': notifyPayrollOfDemotion,
  'record-demotion-discussion': recordDemotionDiscussion,
  'prepare-performance-counselling': preparePerformanceCounselling,
  'record-counselling-discussion': recordCounsellingDiscussion,
  'prepare-additional-counselling-session': prepareAdditionalCounsellingSession,
  'record-additional-counselling-session': recordCounsellingDiscussion,

  // PIP
  'initiate-pip': initiatePip,
  'initiate-extended-pip': initiateExtendedPip,
  'record-pip': recordPipDiscussion,
  'record-extended-pip': recordExtendedPipDiscussion,
  'record-progress-meeting': recordPipProgressMeeting,
  'record-extended-pip-progress-meeting': recordExtendedPipProgressMeeting,
  'complete-pip-session': completePipSession,
  'complete-extended-pip-session': completeExtendedPipSession,
  'pip-assessment': pipAssessment,
  'extended-pip-assessment': extendedPipAssessment,

  //initial hearing
  'appoint-employer-representative': initial_hearing_AppointEmployerRepresentative,
  'appoint-chairperson': appointChairperson,
  'select-witness': selectWitness,
  'hearing-logistics': hearingLogistics,
  'compile-notice-of-hearing': compileNoticeOfHearing,
  'employee-rights': employeeRightsAndResponses,
  'minutes-and-recording': minutesAndRecording,
  'chairpersons-ruling': chairpersonsRuling,
  'confirm-sanction': confirmSanction,
  'execute-outcome': executeOutcome,
  'conclude-hearing-administration': concludeHearing,
  'appeal-process': startAppeal,

  // // appeal hearing
  'appoint-employer-representative-appeal': appointEmployerRepresentativeAppeal,
  'appoint-chairperson-appeal': appointChairpersonAppeal,
  'select-witness-appeal': selectWitnessAppeal,
  'hearing-logistics-appeal': hearingLogisticsAppeal,
  'compile-notice-of-hearing-appeal': compileNoticeOfHearing,
  'employee-rights-appeal': employeeRightsAndResponsesAppeal,
  'chairpersons-ruling-appeal': chairpersonsRulingAppeal,
  'confirm-sanction-appeal': confirmSanctionAppeal,
  'conclude-hearing-administration-appeal': concludeHearingAppeal,
  'execute-outcome-appeal': executeOutcome,

  // ccma
  'ccma-details-a': ccmaDetailsA,
  'ccma-details-b': ccmaDetailsB,
};
