import * as Yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '../../forms/fields/FormField.component';
import { CaseType } from '../../API';

export const PastCaseSchema = Yup.object().shape({
  caseNumber: Yup.string().required('Required'),
  caseType: Yup.string()
    .required('Required')
    .nullable(),
  transgression: Yup.string().when('caseType', {
    is: value => value && value === CaseType.MISCONDUCT,
    then: Yup.string().required('Required'),
  }),
  shortfallDescription: Yup.string().when('caseType', {
    is: value => value && value === CaseType.POOR_PERFORMANCE,
    then: Yup.string().required('Required'),
  }),
  frequency: Yup.string().required('Required'),
  sanction: Yup.string().required('Required'),
  expiryDate: Yup.string()
    .required('Required')
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid()),
  employeeId: Yup.string().required('Required'),
  organisationId: Yup.string().required('Required'),
  caseStatus: Yup.string().required('Required'),
  caseFiles: Yup.array().nullable(),
});

export const CaseSchema = Yup.object().shape({
  caseNumber: Yup.string().required('Please enter a case number'),
  caseType: Yup.string().required('Please select case type'),
  issueDescription: Yup.string().required('Please enter a description for this case'),
  incidents: Yup.array().of(
    Yup.object().shape({
      summaryOfFacts: Yup.string().required('Please enter a summary of this incident'),
      date: Yup.string()
        .required('Date is a required field')
        .test('date', 'Required Field', val => moment(val, DATE_FORMAT).isValid()),
      time: Yup.string()
        .required('Time is required')
        .test('time', 'Enter a valid time value', val => moment(val, TIME_FORMAT).isValid()),
      location: Yup.string().required('Please enter where incident occurred'),
    }),
  ),
});
