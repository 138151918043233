export const chairpersonsFindingsOptions = ['GUILTY', 'NOT_GUILTY'];

export enum AllSanctionOptions {
  SUMMARY_DISMISSAL = 'SUMMARY_DISMISSAL',
  FINAL_WRITTEN_WARNING = 'FINAL_WRITTEN_WARNING',
  WRITTEN_WARNING = 'WRITTEN_WARNING',
  DEMOTION = 'DEMOTION',
  DISMISSAL_WITH_NOTICE = 'DISMISSAL_WITH_NOTICE',
  SUSPENSION_WITHOUT_PAY = 'SUSPENSION_WITHOUT_PAY',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  FINAL_WRITTEN_WARNING_WITH_PIP = 'FINAL_WRITTEN_WARNING_WITH_PIP',
  DISMISSAL_WITH_NOTICE_PAY = 'DISMISSAL_WITH_NOTICE_PAY',
}

export enum MisconductSanctionOptions {
  SUMMARY_DISMISSAL = 'SUMMARY_DISMISSAL',
  FINAL_WRITTEN_WARNING = 'FINAL_WRITTEN_WARNING',
  WRITTEN_WARNING = 'WRITTEN_WARNING',
  DEMOTION = 'DEMOTION',
  DISMISSAL_WITH_NOTICE = 'DISMISSAL_WITH_NOTICE',
  SUSPENSION_WITHOUT_PAY = 'SUSPENSION_WITHOUT_PAY',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum PPSanctionOptionsFirstTime {
  FINAL_WRITTEN_WARNING_WITH_PIP = 'FINAL_WRITTEN_WARNING_WITH_PIP',
  SUMMARY_DISMISSAL = 'SUMMARY_DISMISSAL',
  DISMISSAL_WITH_NOTICE_PAY = 'DISMISSAL_WITH_NOTICE_PAY',
  DEMOTION = 'DEMOTION',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum PPSanctionOptionsSecondTime {
  SUMMARY_DISMISSAL = 'SUMMARY_DISMISSAL',
  DISMISSAL_WITH_NOTICE_PAY = 'DISMISSAL_WITH_NOTICE_PAY',
  DEMOTION = 'DEMOTION',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export const misconductSanctionOptions = [
  'SUMMARY_DISMISSAL',
  'FINAL_WRITTEN_WARNING',
  'WRITTEN_WARNING',
  'DEMOTION',
  'DISMISSAL_WITH_NOTICE',
  'SUSPENSION_WITHOUT_PAY',
  'NOT_APPLICABLE',
];

export const pp_sanctionOptionsFirstTime = [
  'FINAL_WRITTEN_WARNING_WITH_PIP',
  'SUMMARY_DISMISSAL',
  'DISMISSAL_WITH_NOTICE_PAY',
  'DEMOTION',
  'NOT_APPLICABLE',
];

export const pp_sanctionOptionsSecondTime = [
  'SUMMARY_DISMISSAL',
  'DISMISSAL_WITH_NOTICE_PAY',
  'DEMOTION',
  'NOT_APPLICABLE',
];
