export const listUsersBasic = `query ListUsers(
      $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoSub
        firstName
        lastName
        active
        roles
        organisationId
        fontPreference
        employee {
          id
          jobTitle {
            id
            name
          } 
          employeeNumber
          department {
            id
            name
          } 
          physicalAddresses{
            physicalAddressType
            lineOne
            lineTwo
            city
            province
            postalCode
          }
        } 
      }
      nextToken
    }
  }`;

// includes auditLogs associated with the documents
export const listTemplateDocumentsWithAuditLogs = `
  query ListTemplateDocumentVersions(
    $filter: ModelTemplateDocumentVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplateDocumentVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        processInstanceId
        signedCopy
        bucketPath
        uploadedFileBucketPath
        templateType
        version
        stringifiedContent
        parentID
        organisationID
        createdAt
        updatedAt
        auditLogs {
          items {
          id
          userID
           user {
            id
            cognitoSub
            firstName
            lastName
            active
            roles
            organisationId
            createdAt
            updatedAt
            }
          type
          timeStamp
          description
          content
          organisationID
          taskId
          processInstanceId
          createdAt
          updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getOrganisationSettings = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      ccmaContactName
      ccmaContactEmail
      ccmaContactPhysicalAddress
      defaultToHearingOnFinalWarning
      logo {
        fileName
        key
        url
      }
      logoPosition
    }
  }
`;

export const getOrganisationSimple = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      organisationName
      vatNumber
      webUrl
      registrationNumber
      mainContactLastName
      mainContactFirstName
      mainContactEmail
      mainContactNumber
      ccmaContactName
      ccmaContactEmail
      ccmaContactPhysicalAddress
      facilitiesEmail
      payrollEmail
      itEmail
      maxEmployees
      termsAndConditions {
        fileName
        key
        url
      }
      logo {
        fileName
        key
        url
      }
      logoPosition
      type
      useCustomDatesForReportingPeriod
      useHCApprover
      defaultReportingPeriod
      monthForEndOfReportingYear
      customReportingPeriodStartDate
      customReportingPeriodEndDate
    }
  }
`;

export const getUserBasic = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoSub
      firstName
      lastName
      emailAddress
      active
      roles
      organisationId
      fontPreference
      employee {
        id
        jobTitle {
          id
          name
        }
        employeeNumber
        department {
          id
          name
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
      }
    }
  }
`;

export const getUserDetails = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoSub
      firstName
      lastName
      emailAddress
      roles
      employee {
        id
      }
    }
  }
`;

export const getOrganisationWithDisciplinaryCode = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      disciplinaryCode {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      createdAt
      updatedAt
    }
  }
`;

export const listEmployeesForComparison = /* GraphQL */ `
  query ListEmployees($filter: ModelEmployeeFilterInput, $limit: Int, $nextToken: String) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisationId
        employeeNumber
        idNumber
        passportNumber
        firstName
        lastName
        departmentID
        locationID
        jobTitleID
        jobGradeID
        jobLevelID
        directManagerID
        allocatedManagerID
        hireDate
        race
        phoneNumbers {
          number
          phoneNumberType
        }
        physicalAddresses {
          physicalAddressType
          lineOne
          lineTwo
          city
          province
          postalCode
        }
        emails {
          emailAddressType
          address
        }
        placeOfWork
        workHours
        status
        disability
        disabilityDescription
        gender
        deleted
      }
      nextToken
    }
  }
`;

export const getEmployeeNameOnlyQuery = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      firstName
      lastName
    }
  }
`;
