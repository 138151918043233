import React from 'react';
import { Document, Page, PDFViewer, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import Paragraph from '../../../PDF/Paragraph.component';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const PoorPerformanceAppealFormPDF: React.FC<DocumentProps> = props => {
  return (
    <div className="content">
      <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <Document>
          <Page style={styles.body} size="A4" wrap>
            {/*{!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}*/}
            <Text style={{ ...styles.subject }}>APPEAL FORM</Text>
            <Text style={{ ...styles.subject }}>COMPLETED BY APPELLANT</Text>
            <Paragraph />
            <Table>
              <TableHeader title="Appellant details" />
              <TableRow>
                <TableCell>Name of employee appealing:</TableCell>
                <TableCell />
                {/*<TableCell>{props.content.employeeFullName.value}</TableCell>*/}
              </TableRow>
              <TableRow>
                <TableCell>Employee number: </TableCell>
                <TableCell />
                {/*<TableCell>{props.content.employeeNumber.value}</TableCell>*/}
              </TableRow>
              <TableRow>
                <TableCell>Department/Business unit</TableCell>
                <TableCell />
                {/*<TableCell>{props.content.employeeDepartment.value}</TableCell>*/}
              </TableRow>
              <TableRow>
                <TableCell>Job title</TableCell>
                <TableCell />
                {/*<TableCell>{props.content.employeeJobTitle.value}</TableCell>*/}
              </TableRow>
              <TableRow>
                <TableCell>Reporting manager</TableCell>
                {/*<TableCell>{props.content.reportingManager.value}</TableCell>*/}
                <TableCell />
              </TableRow>
            </Table>
            <Text style={{ ...styles.subject, textTransform: 'capitalize' }}>Appeal details</Text>
            <Paragraph />
            <Text style={{ ...styles.subject, textTransform: 'capitalize' }}>
              1. Appeal details: (Tick as appropriate)
            </Text>
            <Paragraph />
            <TableRow>
              <TableCell width={'92%'}>Finding(s) of guilt on performance shortfalls.</TableCell>
              <TableCell checkbox width={'8%'} />
            </TableRow>
            <Paragraph>
              <Text>OR</Text>
            </Paragraph>
            <TableRow>
              <TableCell width={'92%'}>Findings on way forward only</TableCell>
              <TableCell checkbox width={'8%'} />
            </TableRow>
            <Paragraph>
              <Text>If so, the appeal is against</Text>
            </Paragraph>
            <TableRow>
              <TableCell width={'92%'}>Extension of PIP with final review period</TableCell>
              <TableCell checkbox width={'8%'} />
            </TableRow>
            <TableRow>
              <TableCell width={'92%'}>Summary termination</TableCell>
              <TableCell checkbox width={'8%'} />
            </TableRow>
            <TableRow>
              <TableCell width={'92%'}>Termination with notice pay</TableCell>
              <TableCell checkbox width={'8%'} />
            </TableRow>
            <TableRow>
              <TableCell width={'92%'}>Other </TableCell>
              <TableCell checkbox width={'8%'} />
            </TableRow>
            <TableRow>
              <TableCell width={'100%'} />
            </TableRow>
            <Paragraph />
            <Text style={{ ...styles.subject, textTransform: 'capitalize' }}>2. Ground(s) for appeal:</Text>
            <Paragraph>
              <Text>
                Describe why you are dissatisfied with the incapacity hearing outcome. Attach separate sheet if
                necessary.
              </Text>
            </Paragraph>
            <Paragraph>{/*<Text>{props.content.groundsForAppeal.value}</Text>*/}</Paragraph>
            <Paragraph />
            <Text style={{ ...styles.subject, textTransform: 'capitalize' }}>3. Expected outcome:</Text>
            <Paragraph>
              <Text>
                Describe why you are dissatisfied with the incapacity hearing outcome. Attach separate sheet if
                necessary.
              </Text>
            </Paragraph>
            <Paragraph>{/*<Text>{props.content.expectedOutcome.value}</Text>*/}</Paragraph>
            <Table>
              <TableRow>
                <TableCell>Signature of employee:</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>Date: </TableCell>
                <TableCell />
              </TableRow>
            </Table>
            <Table>
              <TableHeader title="Employer acknowledgement" />
              <Paragraph />
              <Text style={{ ...styles.subject, textTransform: 'capitalize' }}>Received on behalf of employer:</Text>
              <Paragraph />
              <TableRow>
                <TableCell>Name:</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>Designation:</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>Signature:</TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell>Date:</TableCell>
                <TableCell />
              </TableRow>
            </Table>
            <Paragraph>Yours sincerely,</Paragraph>
            {/*<Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>*/}
            {/*<Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>*/}
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};
