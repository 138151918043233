import React from 'react';
import { Row, Col } from 'reactstrap';
import { startCase as _startCase } from 'lodash';
import { Storage } from 'aws-amplify';

interface CaseHistoryDataProps {
  task: any;
  adhocText: any;
}

const CaseHistoryDataAdhoc: React.FC<CaseHistoryDataProps> = (props: CaseHistoryDataProps) => {
  const { task, adhocText } = props;
  const getDocumentUrl = async (key: string): Promise<Record<string, any> | string> => {
    return new Promise((resolve, reject) => {
      Storage.get(key, { level: 'public' })
        .then(item => {
          if (item) {
            resolve(item);
          } else reject(new Error('could not get key'));
        })
        .catch(error => reject(error));
    });
  };

  return (
    <>
      <div className="flex justify-center items-center">
        <>
          <Row>
            {adhocText?.map((val: any, index: number) => {
              return (
                <Col md={4} key={index}>
                  <div key={index} className=" tab-background pointer px-2 py-3 my-1">
                    {/* <span key={index} className="text-muted" style={{ wordWrap: 'break-word' }}>
                      {val?.note}
                    </span> */}
                    <p key={index}>{val?.note}</p>
                  </div>
                </Col>
              );
            })}
            {task?.map((document: any, index: number) => {
              return (
                <Col md={4} key={index}>
                  <div key={index} className=" tab-background pointer px-2 py-3 my-1">
                    <a
                      onClick={(e: any): void => {
                        getDocumentUrl(document?.key).then((url: any) => {
                          e.preventDefault();
                          window.open(url, '_blank');
                        });
                      }}
                      className="text-success col-sm-md mx-2 d-flex justify-content-between"
                    >
                      <span key={index} className="text-muted" style={{ wordWrap: 'break-word' }}>
                        {document?.fileName}
                      </span>
                      <span>
                        <i className="tim-icons icon-cloud-download-93 text-default font-weight-bold" />
                      </span>
                    </a>
                  </div>
                </Col>
              );
            })}
          </Row>
        </>
      </div>
    </>
  );
};

export default CaseHistoryDataAdhoc;
