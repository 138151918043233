import React from 'react';

import { Nav, NavItem, NavLink } from 'reactstrap';

interface TabsProps {
  page: number;
  tabs: string[];
  changeTab?: (page: number, fn?: any, fn1?: any) => void;
  type?: 'modal' | 'info';
}

export const HearingAndAppealTabs: React.FC<TabsProps> = ({ page, tabs, changeTab, children, type = 'info' }) => {
  const active = tabs[page];
  const navigate = (page: number): void => {
    if (changeTab) changeTab(page);
  };

  return (
    <div>
      <Nav className={`nav-pills-${type} flex-nowrap`} pills>
        {tabs.map((tabName, index) => (
          <span
            key={index}
            style={{ cursor: 'pointer', minWidth: '5vw', height: 'auto' }}
            onClick={(): void => navigate(index)}
          >
            <NavItem>
              <NavLink
                data-toggle="tab"
                className={active === tabName ? 'active' : ''}
                style={{
                  width: '100%',
                  padding: '0.8vw 2vw',
                  fontSize: '13px',
                  lineHeight: '18px',
                  border: 'none',
                }}
              >
                {tabName}
              </NavLink>
            </NavItem>
          </span>
        ))}
      </Nav>
      <div className="py-1 px-0">{children}</div>
    </div>
  );
};

export default HearingAndAppealTabs;
