import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import RecordPIPDiscussionForm from './RecordPIPDiscussionForm';
import { WorkFlowFooter } from '../../../../components/workflowFooter/WorkFlowFooter';
import { RecordPIPDiscussionSchema } from '../../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../WorkflowContainer/workflow-utils';
import { DocumentVersionControlTable } from '../../../../components/documentVersionControlTable/DocumentVersionControlTable';
import { pipDocumentTypeConfig } from '../pip-config';

const RecordPIPDiscussion: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const { pipType } = caseData;
  const documentType = pipDocumentTypeConfig[pipType].revisedVersion;
  const fields = {
    pipDiscussionDocuments: 'array',
    pipDiscussionSummary: 'string',
    performanceShortfalls: 'array',
    pipStartDate: 'string',
    pipEndDate: 'string',
    pipProgressMeetings: 'array',
  };
  const initialValues = getFormDataFromCaseData(fields, caseData, caseData.isAppeal);
  const [data, setData] = useState(initialValues);
  useEffect(() => {
    if (!data.performanceShortfalls) {
      const shortfalls = [
        {
          shortfallDescription: '',
          performanceGoal: '',
          qualityStandard: '',
          deadline: '',
          assistanceRequiredFromEmployer: '',
          actionsToBeTakenByEmployee: '',
        },
      ];
      setData(data => ({ ...data, performanceShortfalls: shortfalls }));
    } else {
      const shortfalls = data.performanceShortfalls.map((shortfall: Record<string, any>) => {
        return {
          ...shortfall,
          performanceGoal: shortfall.performanceGoal ? shortfall.performanceGoal : '',
          qualityStandard: shortfall.qualityStandard ? shortfall.qualityStandard : '',
          deadline: shortfall.deadline ? shortfall.deadline : '',
          assistanceRequiredFromEmployer: shortfall.assistanceRequiredFromEmployer
            ? shortfall.assistanceRequiredFromEmployer
            : '',
          actionsToBeTakenByEmployee: shortfall.actionsToBeTakenByEmployee ? shortfall.actionsToBeTakenByEmployee : '',
        };
      });
      setData(data => ({ ...data, performanceShortfalls: shortfalls }));
    }
  }, [JSON.stringify(data)]);

  const submitValues = async (values: FormikValues): Promise<void> => {
    const formValues = { ...values };
    formValues.performanceShortfalls = JSON.stringify(formValues.performanceShortfalls);
    formValues.pipProgressMeetings = JSON.stringify(formValues.pipProgressMeetings);
    formValues.pipDiscussionDocuments = JSON.stringify(formValues.pipDiscussionDocuments);
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">
        <span>{pipType === 'EXTENDED_PIP' ? 'Record Extended PIP Discussion' : 'Record PIP Discussion'}</span>
      </h4>
      <Formik
        initialValues={data}
        onSubmit={submitValues}
        validationSchema={RecordPIPDiscussionSchema}
        enableReinitialize
      >
        {({ values, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
          <>
            <RecordPIPDiscussionForm
              data={props.data}
              processInstanceId={props.data.processInstanceId}
              pipType={pipType}
            />

            <button
              className="btn prepare-letter-button mb-3"
              onClick={(): void => {
                props.data.flowableFunctions.onOpenNewDocument(values, fields, documentType);
              }}
            >
              {pipType === 'EXTENDED_PIP' ? 'Prepare Final Extended PIP' : 'Prepare Final PIP'}
            </button>
            {props.data.processInstanceId ? (
              <DocumentVersionControlTable
                data={props.data}
                processInstanceId={props.data.processInstanceId}
                templateType={documentType}
                completeTask={props.data.flowableFunctions.completeTask}
              />
            ) : null}
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => flowableFunctions.onSaveAndClose(values)}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default RecordPIPDiscussion;
