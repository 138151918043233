import React from 'react';

import { Nav, NavItem, NavLink } from 'reactstrap';

interface TabsProps {
  page?: number;
  tabs?: string[];
  changeTab?: (page: number, fn?: any, fn1?: any) => void;
  type?: 'modal' | 'info';
  style?: Record<string, unknown>;
}

export const Tabs: React.FC<TabsProps> = ({ page, tabs, changeTab, children, type = 'info', style }) => {
  const active = tabs ? tabs[page || 0] : 0;
  const navigate = (page: number): void => {
    if (changeTab) changeTab(page);
  };

  return (
    <div>
      <Nav className={`nav-pills-${type} flex-wrap align-items-center justify-content-start`} pills>
        {tabs &&
          tabs.length &&
          tabs.map((tabName, index) => (
            <span
              key={index}
              style={{ cursor: 'pointer', minWidth: '10vw', height: 'auto', ...style }}
              onClick={(): void => navigate(index)}
            >
              <NavItem>
                <NavLink
                  data-toggle="tab"
                  className={active === tabName ? 'active' : ''}
                  style={{
                    width: '100%',
                    padding: '0.8vw 0.5vw',
                    fontSize: '13px',
                    lineHeight: '18px',
                    border: 'none',
                    wordWrap: 'normal',
                  }}
                >
                  {tabName}
                </NavLink>
              </NavItem>
            </span>
          ))}
      </Nav>
      <div className="py-1 px-0">{children}</div>
    </div>
  );
};

export default Tabs;
