import * as Yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../../../forms/fields/FormField.component';
import { TemplateType } from '../../../../../../API';
import { FormConfigV2 } from '../../../../../../configs/form-configs/form-config-types';

export const initial_hearing_misconduct_executeOutcomeDemotion: FormConfigV2 = {
  templateType: TemplateType.DEMOTION_LETTER_MISCONDUCT,
  fields: {
    incidents: 'array',
    initial_hearing_hearingDate: 'string',
    dismissalDate: 'string',
    physicalExitDate: 'string',
    contactPersonInCaseOfCCMA: 'string',
    physicalAddress: 'string',
    emailAddress: 'string',
  },
  caseHistoryFields: {
    incidents: {
      type: 'table',
      label: 'Incidents',
      tableColumns: ['transgression'],
    },
    initial_hearing_hearingDate: {
      type: 'string',
      label: 'Date of Hearing',
    },
    dismissalDate: {
      type: 'string',
      label: 'Dismissal Date',
    },
    lastRemunerationDate: {
      type: 'string',
      label: 'Last remuneration date',
    },
    contactPersonInCaseOfCCMA: {
      type: 'string',
      label: 'Contact Person in Case of CCMA',
    },
    physicalExitDate: {
      type: 'string',
      label: 'Physical Exit Date',
    },
    physicalAddress: {
      type: 'string',
      label: 'Physical Address',
    },
    emailAddress: {
      type: 'string',
      label: 'Email Address',
    },
  },
  validationSchema: Yup.object().shape({
    incidents: Yup.array()
      .nullable()
      .required('Incidents are required'),
    initial_hearing_hearingDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
    dismissalDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
    physicalExitDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
    contactPersonInCaseOfCCMA: Yup.string().required('Please enter contact name'),
    physicalAddress: Yup.string().required('Please enter address'),
    emailAddress: Yup.string()
      .email('Email is not valid')
      .required('Please enter representative email address'),
  }),
};

export const initial_hearing_pp_executeOutcomeDemotion: FormConfigV2 = {
  templateType: TemplateType.DEMOTION_LETTER_POOR_PERFORMANCE,
  fields: {
    performanceShortfalls: 'array',
    initial_hearing_hearingDate: 'string',
    dismissalDate: 'string',
    physicalExitDate: 'string',
    contactPersonInCaseOfCCMA: 'string',
    physicalAddress: 'string',
    emailAddress: 'string',
  },
  caseHistoryFields: {
    performanceShortfalls: {
      type: 'table',
      label: 'Performance Shortfalls',
      tableColumns: ['shortfallDescription'],
    },
    initial_hearing_hearingDate: {
      type: 'string',
      label: 'Date of Hearing',
    },
    dismissalDate: {
      type: 'string',
      label: 'Dismissal Date',
    },
    contactPersonInCaseOfCCMA: {
      type: 'string',
      label: 'Contact Person in Case of CCMA',
    },
    physicalExitDate: {
      type: 'string',
      label: 'Physical Exit Date',
    },
    physicalAddress: {
      type: 'string',
      label: 'Physical Address',
    },
    emailAddress: {
      type: 'string',
      label: 'Email Address',
    },
  },
  validationSchema: Yup.object().shape({
    performanceShortfalls: Yup.array()
      .nullable()
      .required('Performance shortfalls are required'),
    initial_hearing_hearingDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
    dismissalDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
    physicalExitDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
    contactPersonInCaseOfCCMA: Yup.string().required('Please enter contact name'),
    physicalAddress: Yup.string().required('Please enter address'),
    emailAddress: Yup.string()
      .email('Email is not valid')
      .required('Please enter representative email address'),
  }),
};
