import {
  appeal_hearing_confirmOverallSanctionSchema,
  appeal_hearing_misconductChairpersonsRulingSchema,
  appeal_hearing_misconductCompileNoticeOfHearingSchema,
} from '../../forms/ValidationSchema/AppealHearingValidationSchema';
import {
  initial_hearing_confirmOverallSanctionSchema,
  initial_hearing_misconductChairpersonsRulingSchema,
  initial_hearing_misconductCompileNoticeOfHearingSchema,
  startAppealSchema,
} from '../../forms/ValidationSchema/InitialHearingValidationSchema';

export const initial_hearing_chairpersonsRuling = {
  fields: {
    initial_hearing_chairpersonsRulingDocuments: 'array',
    initial_hearing_backgroundLeadingToHearing: 'string',
    initial_hearing_employeeRightsExercisedInHearing: 'string',
    initial_hearing_summaryOfEmployerRepresentativeEvidence: 'string',
    initial_hearing_summaryOfEmployeesEvidence: 'string',
    initial_hearing_conclusionsReachedOnGuilt: 'string',
    initial_hearing_conclusionsReachedOnSanction: 'string',
    initial_hearing_summaryOfAggravatingFactors: 'string',
    initial_hearing_summaryOfLegalPrinciples: 'string',
    initial_hearing_findingsOnSanction: 'string',
    initial_hearing_employeeCommentsAndMitigatingFactors: '',
    incidents: 'array',
  },
  validationSchema: initial_hearing_misconductChairpersonsRulingSchema,
};

export const appeal_hearing_chairpersonsRuling = {
  fields: {
    appeal_hearing_chairpersonsRulingDocuments: 'array',
    appeal_hearing_backgroundLeadingToHearing: 'string',
    appeal_hearing_summaryOfAppellantsArguments: 'string',
    appeal_hearing_summaryOfEmployerRepresentativesArguments: 'string',
    appeal_hearing_summaryOfLegalPrinciples: 'string',
    appeal_hearing_conclusionsReached: 'string',
    appeal_hearing_newEvidenceConsidered: 'string',
    incidents: 'array',
  },
  validationSchema: appeal_hearing_misconductChairpersonsRulingSchema,
};

export const initial_hearing_CompileNoticeOfHearing = {
  fields: {
    incidents: 'array',
  },
  validationSchema: initial_hearing_misconductCompileNoticeOfHearingSchema,
};

export const appeal_hearing_CompileNoticeOfHearing = {
  fields: {
    incidents: 'array',
  },
  validationSchema: appeal_hearing_misconductCompileNoticeOfHearingSchema,
};

export const initial_hearing_confirmOverallSanction = {
  fields: {
    incidents: 'array',
    overallSanction: 'string',
  },
  validationSchema: initial_hearing_confirmOverallSanctionSchema,
};

export const appeal_hearing_confirmOverallSanction = {
  fields: {
    incidents: 'array',
    overallSanction: 'string',
    revisedOverallSanction: 'string',
  },
  validationSchema: appeal_hearing_confirmOverallSanctionSchema,
};

export const initial_hearing_misconductCompileNoticeOfHearing = {
  fields: {
    incidents: 'array',
  },
  validationSchema: initial_hearing_misconductCompileNoticeOfHearingSchema,
};

export const appeal_hearing_misconductCompileNoticeOfHearing = {
  fields: {
    incidents: 'array',
  },
  validationSchema: appeal_hearing_misconductCompileNoticeOfHearingSchema,
};

export const startAppeal = {
  fields: {
    incidents: 'array',
    appealAllowed: 'boolean',
    employeeHasIndicatedAppealIntent: 'boolean',
    employeeAppealResponseDocuments: 'array',
    appealReceivedWithinPrescribedTimeFrame: 'boolean',
    proceedWithAppeal: 'boolean',
    appealDeadline: 'string',
  },
  validationSchema: startAppealSchema,
};
