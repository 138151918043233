import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useErrorHandler } from '../../utils/notification-utils';
import { Storage } from 'aws-amplify';
import { ReadOnlyContext, ReadOnlyContextProps } from '../taskContainer/TaskContainer';
import { DocumentUploadIcon } from '../icon/Icon.component';
import { BucketFile } from '../../models';
import { cleanFileName } from '../../utils/storage-utils';

interface DocumentUploaderProps {
  value?: string;
  bucketPath?: string;
  setDocumentCallback?: (document: BucketFile | File) => void;
  iconSize?: { height: string; width: string };
  hasIcon?: boolean;
  alreadyDocument: boolean;
  isMulti: boolean;
  withAutoUpload?: boolean;
}

const DocumentUploader: React.FC<DocumentUploaderProps> = (props: DocumentUploaderProps) => {
  const { alreadyDocument, bucketPath, setDocumentCallback, isMulti, hasIcon, withAutoUpload = true, value } = props;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const [file, setFile] = useState<File | null>();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const handleError = useErrorHandler();

  const getDocument = async (key: Record<string, any>): Promise<void> => {
    await Storage.get(key['key'], { level: 'public' })
      .then(item => {
        if (file) {
          const value = {
            fileName: file.name,
            key: key['key'] as string,
            url: item as string,
          };
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setDocumentCallback(value);
        }
      })
      .catch(error => handleError(error));
  };

  const uploadFile = async (): Promise<void> => {
    if (file) {
      setUploading(true);
      const cleanedFileName = cleanFileName(file.name);
      await Storage.put(`${bucketPath}/${cleanedFileName}`, file, {
        level: 'public',
        progressCallback(progress: ProgressEvent) {
          setUploadProgress((progress.loaded / progress.total) * 100);
        },
      })
        .then(response => {
          getDocument(response);
        })
        .catch(error => handleError(error))
        .finally(() => setUploading(false));
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileItem = event.target.files;
    if (fileItem) {
      setFile(fileItem[0]);
    }
  };

  useEffect(() => {
    if (withAutoUpload) {
      uploadFile().catch(error => handleError(error));
    } else {
      if (file && setDocumentCallback) setDocumentCallback(file);
    }
  }, [file]);

  return (
    <>
      <label className="vw-30">
        <span className="d-flex flex-column">
          {file && uploading && (
            <div className="mx-1" style={{ color: 'black' }}>
              <span className="px-4">
                <i className="fas fa-circle-notch fa-spin mr-3" />
                Uploading...{Math.round(uploadProgress)}%
              </span>
            </div>
          )}
          {hasIcon ? (
            <DocumentUploadIcon />
          ) : (
            <span className={`${readOnly && 'disabled'} btn btn-simple rounded-0`}>
              <span className="text-default">
                {alreadyDocument ? (isMulti ? 'Add more files' : 'Replace Current File') : value ? value : 'Add a file'}
              </span>
            </span>
          )}
        </span>
        <input
          style={{ display: 'none' }}
          type={'file'}
          onChange={handleFileChange}
          disabled={false}
          multiple={isMulti}
        />
      </label>
    </>
  );
};

export default DocumentUploader;
