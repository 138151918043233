import hexToRgba from 'hex-to-rgba';
import { DataSet, GraphData, MultiDataSet } from '../chart-options/case-reports-types';

const GRADIENT_PRESET_COLORS = [
  ['#50E3C2', '#4AC1FF'],
  ['#3023AE', '#C86DD7'],
  ['#FFB97A', '#FF69A4'],
  ['#55F0FC', '#7254F2'],
];

const SOLID_COLOURS = ['#50E3C2', '#4AC1FF', '#3023AE', '#C86DD7', '#FFB97A', '#FF69A4', '#7254F2'];

/**
 * Convert and HSL color to RGB
 * @param h - Hue
 * @param s - Satuaration
 * @param l - Lightness
 */
const convertHSLToRGB = (h: number, s: number, l: number) => {
  s /= 100;
  l /= 100;
  const c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;
  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);
  return `rgba(${r}, ${g}, ${b}, 1)`;
};

/**
 * Generates NON conflicting gradient colors
 * @param numOfColours - Number of colors needed.
 * @param colorOffset - Where the colors should begin generating, 270=purple.
 */
export const generateRandomGradients = (numOfColours: number, colorOffset = 270) => {
  const gradients = [];
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  for (let i = 0; i < 360; i += 360 / numOfColours) {
    const gradient = ctx.createLinearGradient(0, 300, 0, 0);
    let color = i + colorOffset;
    if (color > 360) {
      color = color % 360;
    }
    const rgb1 = convertHSLToRGB(color - 45, 100, 50);
    const rgb2 = convertHSLToRGB(color + 45, 100, 50);
    gradient.addColorStop(0, rgb1);
    gradient.addColorStop(1, rgb2);
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 300, 300);
    gradients.push(gradient);
  }
  return gradients;
};

const replicateArray = <T>(array: T[], times: number): T[] => {
  const arrays = new Array(times).fill(null).map(() => array);
  return arrays.flatMap(x => x);
};

/**
 * Generates gradient colors from the preset list.
 * @param numOfColours - Number of colors needed.
 */
export const generateGradients = (numOfColours: number): CanvasGradient[] => {
  const gradients: CanvasGradient[] = [];
  let gradientColors = GRADIENT_PRESET_COLORS;
  if (gradientColors.length < numOfColours) {
    gradientColors = replicateArray(gradientColors, Math.ceil(numOfColours / gradientColors.length));
  }
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  new Array(numOfColours).fill(null).forEach((_, index) => {
    const gradient = ctx.createLinearGradient(0, 300, 0, 0);
    const rgb1 = hexToRgba(gradientColors[index][0], 1);
    const rgb2 = hexToRgba(gradientColors[index][1], 1);
    gradient.addColorStop(0, rgb1);
    gradient.addColorStop(1, rgb2);
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 300, 300);
    gradients.push(gradient);
  });
  return gradients;
};

/**
 * Generates NON conflicting RGB colors
 * @param numOfColours - Number of colors needed.
 * @param colorOffset - Where the colors should begin generating, 270=purple.
 */
export const generateRandomColors = (numOfColours: number, colorOffset = 270) => {
  const colors = [];
  for (let i = 0; i < 360; i += 360 / numOfColours) {
    let color = i + colorOffset;
    if (color > 360) {
      color = color % 360;
    }
    const rgb = convertHSLToRGB(color, 100, 50);
    colors.push(rgb);
  }
  return colors;
};

export const generateColors = (numOfColours: number): string[] => {
  let colors = SOLID_COLOURS;
  if (colors.length < numOfColours) {
    colors = replicateArray(colors, Math.ceil(numOfColours / colors.length));
  }
  return colors.slice(0, numOfColours);
};

export const generateGraphData = (multiDataSets: MultiDataSet[], colorOffset = 270): GraphData => {
  {
    // const colors = gradientColors ? generateGradients(multiDataSets?.length) : generateColors(multiDataSets?.length);

    const colors = generateColors(multiDataSets?.length);
    const labels = multiDataSets.length ? multiDataSets[0].data.map(item => item.label) : [];
    const mappedDataSets: DataSet[] =
      multiDataSets &&
      multiDataSets.map((item: MultiDataSet, idx) => {
        const dataSet: DataSet = {
          label: item.label,
          fill: false,
          lineTension: 0.3,
          data: item.data.map(item => item.count),
          borderWidth: 10,
          backgroundColor: colors[idx],
          borderColor: colors[idx],
        };

        return dataSet;
      });
    const graphData: GraphData = {
      labels: labels,
      datasets: mappedDataSets,
      heading: 'this is a heading',
    };

    return graphData;
  }
};
