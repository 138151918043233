import React, { useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row, Table } from 'reactstrap';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import TableFormField from '../../../../forms/fields/TableFormField';
import { CloseIcon } from '../../../../components/icon/Icon.component';
import ButtonWithIcons from '../../../../components/buttons/ButtonWIthIcons.component';
import ShortfallProgressUpdatesAccordion, {
  ProgressUpdateHistory,
} from '../../../../components/ShortfallProgressUpdatesAccordion/ShortfallProgressUpdatesAccordion';
import FormField from '../../../../forms/fields/FormField.component';
import { WorkflowComponentProps } from '../../../WorkflowContainer/workflow-utils';

interface PipAssessmentFormState {
  shortfallProgressUpdatesModalIsOpen: boolean;
  pipProgressUpdates: ProgressUpdateHistory[];
}

const initialValues: PipAssessmentFormState = {
  shortfallProgressUpdatesModalIsOpen: false,
  pipProgressUpdates: [],
};

const ExtendedPipAssessmentForm: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const [state, setState] = useState<PipAssessmentFormState>(initialValues);
  const { setFieldValue, values }: FormikProps<FormikValues> = useFormikContext();

  const getOptionsForFurtherAction = (): string[] => {
    if (props.data.caseData.hasCompletedFinalWrittenWarningPIP || props.data.caseData.hasCompletedExtendedPIP) {
      return ['incapacity_hearing', 'agreed_demotion', 'other'];
    } else {
      return ['extended_performance_improvement_plan', 'incapacity_hearing', 'agreed_demotion', 'other'];
    }
  };

  useEffect(() => {
    if (values.performanceShortfalls) {
      const progressUpdates: ProgressUpdateHistory[] = values.performanceShortfalls.map(
        (shortfall: { [key: string]: any }) => {
          return {
            description: shortfall.shortfallDescription,
            updates: shortfall.pipProgressUpdates,
          };
        },
      );

      setState(oldState => ({
        ...oldState,
        pipProgressUpdates: progressUpdates,
      }));
    }
  }, []);

  return (
    <>
      <Form>
        <Row className="mb-3">
          <Col>
            <Label className="text-default d-block mt-3">
              The employee has reached the end of the Extended PIP period. Have the performance concerns been addressed?
            </Label>
            <div className="d-flex">
              <div className="mr-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={values.extendedPipHavePerformanceConcernsBeenAddressed}
                      onChange={(): void => setFieldValue('extendedPipHavePerformanceConcernsBeenAddressed', true)}
                    />
                    <span className="form-check-sign">
                      <span className="check text-muted text-uppercase">yes</span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
              <div className="ml-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={!values.extendedPipHavePerformanceConcernsBeenAddressed}
                      onChange={(): void => setFieldValue('extendedPipHavePerformanceConcernsBeenAddressed', false)}
                    />
                    <span className="form-check-sign">
                      <span className="check text-default text-muted text-uppercase">no</span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
        {values.extendedPipHavePerformanceConcernsBeenAddressed && (
          <>
            <Row>
              <Col>
                <div>
                  <h4 className="text-h4 text-capitalize font-weight-500">Compile Letter</h4>
                  <h5 className="text-primary text-capitalize mt-4 font-weight-light">Progress Report</h5>
                  <hr style={{ border: '0.06em solid #adb5bd' }} />
                </div>
              </Col>
            </Row>
            <Table bordered className="table-responsive-sm">
              <thead>
                <tr>
                  <th className="text-default" style={{ fontSize: '0.8em' }}>
                    Performance Shortfall
                  </th>
                  <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                    Performance or Competency Goal
                  </th>
                  <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                    Quality Deliverable or Standard
                  </th>
                  <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                    Deadline / Timeline
                  </th>
                  <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                    Assistance Required from Employer
                  </th>
                  <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                    Action to be taken by Employee
                  </th>
                  <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                    How shortfalls were addressed
                  </th>
                  <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                    Final Status
                  </th>
                </tr>
              </thead>
              <tbody>
                <FieldArray name="performanceShortfalls">
                  {(arrayHelpers: FieldArrayRenderProps) => (
                    <>
                      {values.performanceShortfalls &&
                        values.performanceShortfalls.length &&
                        values.performanceShortfalls.map((item: any, index: number) => (
                          <tr key={index}>
                            <td className="position-relative" style={{ width: '15%' }}>
                              {item.shortfallDescription}
                            </td>
                            <td className="position-relative" style={{ width: '5%' }}>
                              <span>{item.performanceGoal}</span>
                            </td>
                            <td className="position-relative" style={{ width: '5%' }}>
                              <span>{item.qualityStandard}</span>
                            </td>
                            <td className="position-relative" style={{ width: '5%' }}>
                              <span>{item.deadline}</span>
                            </td>
                            <td className="position-relative" style={{ width: '5%' }}>
                              <span>{item.assistanceRequiredFromEmployer}</span>
                            </td>
                            <td className="position-relative" style={{ width: '5%' }}>
                              <span>{item.actionsToBeTakenByEmployee}</span>
                            </td>
                            <td className="position-relative" style={{ width: '10%' }}>
                              <TableFormField
                                type={'textarea'}
                                placeholder={'shortfall'}
                                name={`performanceShortfalls.${index}.howShortfallsWereAddressed`}
                                className="square-radius text-dark font-weight-light border-0 h-25 mt-4"
                              />
                              <span className="text-danger">
                                <ErrorMessage
                                  className="text-danger"
                                  name={`performanceShortfalls.${index}.howShortfallsWereAddressed`}
                                />
                              </span>
                            </td>
                            <td className="position-relative" style={{ width: '10%' }}>
                              <TableFormField
                                type={'select'}
                                placeholder={'Select Status'}
                                name={`performanceShortfalls.${index}.finalStatus`}
                                className="square-radius text-dark font-weight-light border-0 h-25"
                                selectOptions={[
                                  { label: 'Missed', value: 'Missed' },
                                  { label: 'Trending Down', value: 'Trending_Down' },
                                  { label: 'Trending Up', value: 'Trending_Up' },
                                  { label: 'Achieved', value: 'Achieved' },
                                ]}
                              />
                              <span className="text-danger">
                                <ErrorMessage
                                  className="text-danger"
                                  name={`performanceShortfalls.${index}.finalStatus`}
                                />
                              </span>
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </FieldArray>
              </tbody>
            </Table>
            <Row>
              <Col md={3}>
                <ButtonWithIcons
                  title={'View Progress updates on shortfalls'}
                  buttonType={'btn-bd-purple'}
                  handleClick={(): void =>
                    setState(oldState => ({ ...oldState, shortfallProgressUpdatesModalIsOpen: true }))
                  }
                />
              </Col>
            </Row>
          </>
        )}
        {!values.extendedPipHavePerformanceConcernsBeenAddressed && (
          <>
            <Row className="my-2">
              <Col md={4} sm={4} lg={4}>
                <FormGroup>
                  <Label for="furtherActionAfterExtendedPip" className="text-default text-capitalize">
                    What further action is required?*
                  </Label>
                  <FormField
                    type={'select'}
                    placeholder="Please select"
                    name="furtherActionAfterExtendedPip"
                    selectOptions={getOptionsForFurtherAction()}
                  />
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'furtherActionAfterExtendedPip'} />
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={8} sm={8} lg={8}>
                <FormGroup>
                  <Label for="furtherActionRequiredAfterExtendedPip" className="text-default text-capitalize">
                    State reason*
                  </Label>
                  <FormField
                    type={'textarea'}
                    placeholder="Please select"
                    name="reasonForFurtherActionAfterExtendedPip"
                  />
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'reasonForFurtherActionAfterExtendedPip'} />
                  </span>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
      </Form>
      <Modal isOpen={state.shortfallProgressUpdatesModalIsOpen} size={'lg'} centered>
        <ModalBody>
          <div
            onClick={(): void => setState(oldState => ({ ...oldState, shortfallProgressUpdatesModalIsOpen: false }))}
            className="d-flex justify-content-between"
          >
            <div className="text-default h4">Updates on Performance Shortfalls</div>
            <div>
              <CloseIcon height={'15'} width={'15'} fillColour="#8461C9" />
            </div>
          </div>
          {state.pipProgressUpdates.map((shortfall: ProgressUpdateHistory, index: number) => {
            return <ShortfallProgressUpdatesAccordion key={index} index={index} data={shortfall} />;
          })}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ExtendedPipAssessmentForm;
