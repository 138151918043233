import React from 'react';
import { CreateNoteInput, NoteType } from '../../API';
import { Card, CardBody, CardHeader } from 'reactstrap';
import moment from 'moment';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { startCase } from 'lodash';

interface NoteProps {
  note: CreateNoteInput;
  handleChange: (event: ContentEditableEvent, note: CreateNoteInput) => void;
  // canDelete: boolean;
  addNew: (type: string) => void;
  deleteNote: (note: CreateNoteInput) => void;
}

const Note: React.FC<NoteProps> = (props: NoteProps) => {
  const { note, handleChange, addNew, deleteNote } = props;

  return (
    <Card key={`${note.id}`} className="shadow-lg mb-1">
      <CardHeader
        className="pb-0"
        style={note.type === NoteType.PERSONAL ? { background: '#4AC1FF' } : { background: '#F7B500' }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <span className="pb-1 pr-1 pl-1" onClick={(): void => addNew(note.type)}>
              <i className="fas fa-plus-circle font-weight-bold text-white display-4" />
            </span>
            <p className="pl-1 pr-1 pb-1 text-white font-weight-normal">{startCase(note.type.toLowerCase())} Note</p>
            <span className="pb-1 pl-1 pr-1">
              <i className="fas fa-info-circle font-weight-bold text-white display-4" />
            </span>
          </div>
          <div
            className="pb-1 pl-1 pr-1"
            onClick={(): void => {
              deleteNote(note);
            }}
          >
            <i className="fas fa-times text-white" />
          </div>
        </div>
      </CardHeader>
      <CardBody style={note.type === NoteType.PERSONAL ? { background: '#EDF9FF' } : { background: '#FFF8E5' }}>
        <div className="d-flex flex-column justify-content-between">
          <span className="align-self-end">
            <small className="text-dark">{moment(note.lastModifiedDate).format('DD/MM/YYYY HH:mm')}</small>
          </span>
          <ContentEditable
            html={note.note}
            onChange={(e: ContentEditableEvent): void => handleChange(e, note)}
            // onBlur={this.handleEditorBlur}
            className="text-muted align-self-center mr-auto ml-auto"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default Note;
