import React, { ReactElement } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import {
  CaseData,
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { ObjectSchema } from 'yup';
import { getFieldsAndValidationSchema } from '../../hearing-utils';
import { AppealHearingAppointChairpersonForm } from './AppealHearingAppointChairpersonForm';

export const AppealHearingAppointChairperson: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;

  const setUpValues: {
    fields: { [key: string]: string };
    validationSchema: ObjectSchema<object>;
  } = getFieldsAndValidationSchema(props.data.caseType, flowableFunctions.formKey);

  console.log({ caseData });
  console.log({ setUpValues });
  const initialValues: FormikValues = getFormDataFromCaseData(setUpValues.fields, caseData);
  console.log({ initialValues });

  const getFormValuesForSubmission = (values: FormikValues): { [key: string]: any } => {
    const formValues: Partial<CaseData> = values;
    return filterFormData(formValues, setUpValues.fields);
  };

  const submitValues = (values: FormikValues): void => {
    console.log({ values });
    props.data.flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  return (
    <div className="content">
      <Formik
        initialValues={initialValues}
        validationSchema={setUpValues.validationSchema}
        enableReinitialize
        onSubmit={submitValues}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <AppealHearingAppointChairpersonForm
              data={props.data}
              getFormValuesForSubmission={getFormValuesForSubmission}
            />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={() => props.data.flowableFunctions.onSaveAndClose(values)}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
