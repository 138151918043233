import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { Col, Row } from 'reactstrap';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { CaseType, UpdateOrganisationInput } from '../../../../../API';
import { get } from '../../../../../utils/graphql-utils';
import { getOrganisation } from '../../../../../graphql/queries';
import { useErrorHandler } from '../../../../../utils/notification-utils';
import { UserContext, UserContextProps } from '../../../../../App';
import { EmailType } from '../../../../../utils/email-utils';
import { AppealHearingConcludeHearingAdministrationForm } from './AppealHearingConcludeHearingAdministrationForm';
import { EmailPreviewModalv3 } from '../../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { AllSanctionOptions } from '../../InitialHearingScreens/ConfirmOverallSanction/sanction-options';
import { convertToFlowableVariables } from '../../../../../utils/flowable/flowable-utils';

export const AppealHearingConcludeHearingAdministration: React.FC<WorkflowComponentProps> = (
  props: WorkflowComponentProps,
) => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const { caseData } = props.data;
  const handleError = useErrorHandler();
  const [organisation, setOrganisation] = useState<UpdateOrganisationInput>();

  const fields = {
    appeal_hearing_deliveredSanctionOutcomeLetter: 'boolean',
    appeal_hearing_acceptedDemotion: 'string',
    appeal_hearing_acceptedSuspensionWithoutPay: 'string',
    appeal_hearing_chairpersonsRulingDocuments: 'array',
  };

  const initialValues = getFormDataFromCaseData(fields, caseData);

  const isAcceptDemotionApplicable = props.data.caseData.revisedOverallSanction === AllSanctionOptions.DEMOTION;
  const isAcceptSuspensionWithoutPayApplicable =
    props.data.caseData.revisedOverallSanction === AllSanctionOptions.SUSPENSION_WITHOUT_PAY;

  initialValues.appeal_hearing_acceptedSuspensionWithoutPay = initialValues.appeal_hearing_acceptedSuspensionWithoutPay
    ? initialValues.appeal_hearing_acceptedSuspensionWithoutPay
    : isAcceptSuspensionWithoutPayApplicable
    ? 'NO'
    : 'NOT APPLICABLE';

  initialValues.appeal_hearing_acceptedDemotion = initialValues.appeal_hearing_acceptedDemotion
    ? initialValues.appeal_hearing_acceptedDemotion
    : isAcceptDemotionApplicable
    ? 'NO'
    : 'NOT APPLICABLE';

  const submitValues = (values: FormikValues): void => {
    props.data.flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  const getFormValuesForSubmission = (values: FormikValues): Record<string, any> => {
    values.appeal_hearing_chairpersonsRulingDocuments = JSON.stringify(
      values.appeal_hearing_chairpersonsRulingDocuments,
    );
    return filterFormData(values, fields);
  };

  const loadOrganisation = () => {
    get(getOrganisation, props.data.caseData.organisationId)
      .then(res => {
        const org = (res.data as any).getOrganisation;
        setOrganisation(org);
      })
      .catch(error => handleError(error));
  };

  const isDismissal = (sanction: string): boolean => {
    return [
      AllSanctionOptions.DISMISSAL_WITH_NOTICE,
      AllSanctionOptions.DISMISSAL_WITH_NOTICE_PAY,
      AllSanctionOptions.SUMMARY_DISMISSAL,
    ].includes(sanction as AllSanctionOptions);
  };

  const getSanctionType = (
    values: any,
  ): 'dismissal' | 'demotion' | 'suspension_without_pay' | 'overturnDismissal' | 'noEmail' => {
    if (
      isDismissal(props.data.caseData.overallSanction) &&
      props.data.caseData.revisedOverallSanction === AllSanctionOptions.NOT_APPLICABLE
    ) {
      return 'overturnDismissal';
    } else {
      if (values.appeal_hearing_acceptedDemotion === 'YES') {
        return 'demotion';
      } else if (values.appeal_hearing_acceptedSuspensionWithoutPay === 'YES') {
        return 'suspension_without_pay';
      } else if (
        props.data.caseData.revisedOverallSanction.includes('WARNING') ||
        props.data.caseData.revisedOverallSanction === 'NOT_APPLICABLE'
      ) {
        return 'noEmail';
      } else {
        return 'dismissal';
      }
    }
  };

  const misconductEmailsMap: Record<string, Record<string, EmailType>> = {
    facilities: {
      dismissal: EmailType.NOTIFY_FACILITIES_DISMISSAL_MISCONDUCT,
      suspension_without_pay: EmailType.NOTIFY_FACILITIES_SUSPENSION_WITHOUT_PAY,
      overturnDismissal: EmailType.NOTIFY_FACILITIES_DISMISSAL_OVERTURNED_MISCONDUCT,
    },
    payroll: {
      dismissal: EmailType.NOTIFY_PAYROLL_GENERIC_MISCONDUCT,
      demotion: EmailType.NOTIFY_PAYROLL_GENERIC_MISCONDUCT,
      suspension_without_pay: EmailType.NOTIFY_PAYROLL_GENERIC_MISCONDUCT,
      overturnDismissal: EmailType.NOTIFY_PAYROLL_DISMISSAL_OVERTURNED_MISCONDUCT,
    },
    IT: {
      dismissal: EmailType.NOTIFY_IT_DISMISSAL_MISCONDUCT,
      suspension_without_pay: EmailType.NOTIFY_IT_SUSPENSION_WITHOUT_PAY,
      overturnDismissal: EmailType.NOTIFY_IT_DISMISSAL_OVERTURNED_MISCONDUCT,
    },
  };

  const poorPerformanceEmailsMap: Record<string, Record<string, EmailType>> = {
    facilities: {
      dismissal: EmailType.NOTIFY_FACILITIES_DISMISSAL_POOR_PERFORMANCE,
      suspension_without_pay: EmailType.NOTIFY_FACILITIES_SUSPENSION_WITHOUT_PAY,
      overturnDismissal: EmailType.NOTIFY_FACILITIES_DISMISSAL_OVERTURNED_POOR_PERFORMANCE,
    },
    payroll: {
      dismissal: EmailType.NOTIFY_PAYROLL_GENERIC_POOR_PERFORMANCE,
      demotion: EmailType.NOTIFY_PAYROLL_GENERIC_POOR_PERFORMANCE,
      suspension_without_pay: EmailType.NOTIFY_PAYROLL_GENERIC_POOR_PERFORMANCE,
      overturnDismissal: EmailType.NOTIFY_PAYROLL_DISMISSAL_OVERTURNED_POOR_PERFORMANCE,
    },
    IT: {
      dismissal: EmailType.NOTIFY_IT_DISMISSAL_POOR_PERFORMANCE,
      suspension_without_pay: EmailType.NOTIFY_IT_SUSPENSION_WITHOUT_PAY,
      overturnDismissal: EmailType.NOTIFY_IT_DISMISSAL_OVERTURNED_POOR_PERFORMANCE,
    },
  };

  const getEmailTypeV2 = (recipient: string, sanction: string): EmailType | null => {
    return props.data.caseType === CaseType.POOR_PERFORMANCE
      ? poorPerformanceEmailsMap[recipient][sanction]
      : misconductEmailsMap[recipient][sanction] || null;
  };

  useEffect(() => {
    if (caseData) {
      loadOrganisation();
    }
  }, [caseData]);

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Conclude Hearing Administration</h4>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={submitValues}>
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <AppealHearingConcludeHearingAdministrationForm data={props.data} />
            <Row>
              <Col className="mt-3">
                <EmailPreviewModalv3
                  buttonText={'Notify Payroll'}
                  disabled={
                    !(organisation && organisation.payrollEmail) || !getEmailTypeV2('payroll', getSanctionType(values))
                  }
                  formValues={values}
                  emailType={getEmailTypeV2('payroll', getSanctionType(values))}
                  masterProcessInstanceId={props.data.masterProcessInstanceId}
                  processInstanceId={props.data.processInstanceId}
                  currentUserId={currentUser?.id}
                  getFlowableVariables={() => convertToFlowableVariables(getFormValuesForSubmission(values))}
                />
                <EmailPreviewModalv3
                  buttonText={'Notify Facilities'}
                  disabled={
                    !(organisation && organisation.facilitiesEmail) ||
                    !getEmailTypeV2('facilities', getSanctionType(values))
                  }
                  formValues={values}
                  emailType={getEmailTypeV2('facilities', getSanctionType(values))}
                  masterProcessInstanceId={props.data.masterProcessInstanceId}
                  processInstanceId={props.data.processInstanceId}
                  currentUserId={currentUser?.id}
                  getFlowableVariables={() => convertToFlowableVariables(getFormValuesForSubmission(values))}
                />
                <EmailPreviewModalv3
                  buttonText={'Notify I.T.'}
                  disabled={!(organisation && organisation.itEmail) || !getEmailTypeV2('IT', getSanctionType(values))}
                  formValues={values}
                  emailType={getEmailTypeV2('IT', getSanctionType(values))}
                  masterProcessInstanceId={props.data.masterProcessInstanceId}
                  processInstanceId={props.data.processInstanceId}
                  currentUserId={currentUser?.id}
                  getFlowableVariables={() => convertToFlowableVariables(getFormValuesForSubmission(values))}
                />
              </Col>
            </Row>
            <WorkFlowFooter
              data={props.data}
              finalPage={props.data.caseData.isAppeal}
              onNext={handleSubmit}
              onCancel={() => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
