import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import List from '../../../PDF/List.component';
import ListItem from '../../../PDF/ListItem.component';
import Paragraph from '../../../PDF/Paragraph.component';
import LetterHead from '../../../PDF/Letterhead.component';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const SuspensionPendingHearingLetterPDF: React.FC<DocumentProps> = props => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Confidential</Text>
        <Table>
          <TableHeader title="attention" />
          <TableRow>
            <TableCell>Name of Employee: </TableCell>
            <TableCell>{props.content.employeeFullName.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Employee Number: </TableCell>
            <TableCell>{props.content.employeeNumber.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Department/Business Unit</TableCell>
            <TableCell>{props.content.employeeDepartment.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{props.content.employeeAddress.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>{props.content.date.value}</TableCell>
          </TableRow>
        </Table>
        <Paragraph> Dear {props.content.employeeFirstName.value}, </Paragraph>
        <Text style={{ ...styles.subject }}>Notice of Suspension Pending Disciplinary Hearing </Text>
        <Paragraph>
          The employer has justifiable reason to believe that you may have made yourself guilty of serious misconduct.
          In summary, the allegations against you relate to the following:
        </Paragraph>
        <Table>
          {Array.isArray(props.content.allegations.value) &&
            props.content.allegations.value.map((item: string, index: number) => (
              <TableRow key={index}>
                <TableCell width={'100%'}>{item} </TableCell>
              </TableRow>
            ))}
        </Table>
        <Paragraph>
          After considering all relevant factors and surrounding circumstances, the employer has decided to temporarily
          suspend your services with immediate effect on the basis that:
        </Paragraph>
        <List>
          <ListItem>
            You are relieved of all your authorities and any powers you may have in terms of your employment with the
            company. You are accordingly relieved of the responsibility to perform any duties with immediate effect;
          </ListItem>
          <ListItem>
            You remain entitled to your full remuneration and all your employment benefits for the duration of the
            suspension - subject to the provisions below;
          </ListItem>
          <ListItem>
            You shall not be permitted to enter and be upon any of the employer’s premises or be in any contact with any
            fellow employee/ without the prior permission of writer hereof. Should such permission be granted, you will
            be escorted by an appointed designate of the employer during such visits at all times;
          </ListItem>
          <ListItem>
            You should refrain at all times from contacting the employer’s clients/customers/service providers and other
            similar role players without the prior permission of writer hereof;
          </ListItem>
          <ListItem>
            Save as aforesaid you shall remain bound by all your conditions of employment including, but not limited to
            the confidentiality provisions as contained in your employment conditions.
          </ListItem>
        </List>
        <Paragraph>
          The employer is of the opinion that the suspension is justified, amongst other reasons, in light of the
          seriousness of the allegations raised against you, perceived risks created by your continued presence on
          employer premises and/or in order to avoid any interference during the disciplinary process pending the
          outcome of a disciplinary hearing.
        </Paragraph>
        <Paragraph>
          You are required to vacate the premises with immediate effect and to hand in your access card to the writer
          hereof (or designate). The same applies to any company property that you may have in your possession at this
          time.
        </Paragraph>
        <Paragraph>
          You are required to be available during the suspension period to assist us in dealing with the allegations and
          bringing the matter to conclusion. In this regard we require a contactable number and physical address to be
          given to the writer hereof prior to your departure.
        </Paragraph>
        <Paragraph>
          The employer will provide you with a disciplinary notification setting out the charges against you and the
          details of a disciplinary hearing shortly. It is our objective to finalise the disciplinary process without
          any unnecessary delay and to treat the matter confidentially as far as reasonably possible.
        </Paragraph>
        <Paragraph>Should you have any queries relating to this letter please speak to writer hereof.</Paragraph>
        <Paragraph>
          This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a copy
          to writer hereof in acknowledgement of receipt hereof or, alternatively, acknowledge by way of return e-mail.
        </Paragraph>
        <Paragraph>Yours sincerely,</Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
    </Document>
  );
};
