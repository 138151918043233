import React, { ReactElement, useState } from 'react';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../WorkflowContainer/workflow-utils';
import { Formik, FormikProps, FormikValues } from 'formik';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import NotifyPayrollOfDemotionForm from './NotifyPayrollOfDemotionForm';
import { NotifyPayrollOfDemotionSchema } from '../../../forms/ValidationSchema/PoorPerformanceValidationSchema';

const NotifyPayrollOfDemotion: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const fields = {
    didEmployeeSignedDemotionLetterInAcceptance: 'boolean',
    demotionDiscussionDate: 'string',
    demotionDiscussionTime: 'string',
    demotionDiscussionLocation: 'string',
    demotionDiscussionAttendees: 'array',
  };
  const [data] = useState(() => getFormDataFromCaseData(fields, caseData, caseData.isAppeal));

  const submitValues = async (values: FormikValues): Promise<void> => {
    flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  const getFormValuesForSubmission = (values: FormikValues): Record<string, any> => {
    const formValues = { ...values };
    formValues.demotionDiscussionAttendees = JSON.stringify(formValues.demotionDiscussionAttendees);
    return filterFormData(formValues, fields);
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Notify Payroll of Demotion</h4>
      <Formik
        initialValues={data}
        onSubmit={submitValues}
        validationSchema={NotifyPayrollOfDemotionSchema}
        enableReinitialize
      >
        {({ values, errors, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
          <>
            <NotifyPayrollOfDemotionForm data={props.data} getFormValuesForSubmission={getFormValuesForSubmission} />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => flowableFunctions.onSaveAndClose(values)}
              finalPage={values.didEmployeeSignedDemotionLetterInAcceptance}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default NotifyPayrollOfDemotion;
