import { TemplateType } from '../../../API';

interface PipDocument {
  draftVersion: TemplateType;
  revisedVersion: TemplateType;
}

export const pipDocumentTypeConfig: Record<string, PipDocument> = {
  BASIC_PIP: {
    draftVersion: TemplateType.PERFORMANCE_IMPROVEMENT_PLAN,
    revisedVersion: TemplateType.PERFORMANCE_IMPROVEMENT_PLAN_REVISED,
  },
  EXTENDED_PIP: {
    draftVersion: TemplateType.EXTENSION_OF_PIP,
    revisedVersion: TemplateType.EXTENSION_OF_PIP_REVISED,
  },
  FINAL_WRITTEN_WARNING_PIP: {
    draftVersion: TemplateType.EXTENSION_OF_PIP_FOLLOWING_HEARING,
    revisedVersion: TemplateType.EXTENSION_OF_PIP_FOLLOWING_HEARING_REVISED,
  },
};
