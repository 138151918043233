import React, { useEffect, useState } from 'react';
//@ts-ignore
import Loader from '../../components/loader/Loader';
import './style.css';
import { Document, Page, PDFViewer, Text } from '@react-pdf/renderer';
import { TemplateDocumentVersion } from '../../models';
import { FieldData } from '../../forms/fields/FieldTypeDefinitions';
import { LogoPosition } from '../../API';
import { get } from '../../utils/graphql-utils';
import { styles } from '../../components/PDF/PDFStyles.style';

import ButtonWithIcons from '../../components/buttons/ButtonWIthIcons.component';
import { getTemplateDocumentVersion } from '../../graphql/queries';
import { bucketPath } from '../../utils/storage-utils';
import { WorkflowComponentProps } from '../WorkflowContainer/workflow-utils';
import { documentConfigs } from '../../configs/document-configs/document-configs';

interface DocumentWithContent {
  document: TemplateDocumentVersion;
  jsonContent: DocumentJSONContent | null;
}

interface Logo {
  imageUrl: string;
  position: LogoPosition;
}

interface DocumentJSONContent {
  [key: string]: FieldData;
}

export const ApproveDocumentView: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const [documentData, setDocumentData] = useState<DocumentWithContent | null>(null);
  const [logo, setLogo] = useState<Logo>({
    imageUrl: '',
    position: LogoPosition.LEFT,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isTaskComplete] = useState(false);

  const getDocumentJSXForPDFViewer = (document: DocumentWithContent): JSX.Element => {
    if (document.jsonContent) {
      // @ts-ignore
      const component = documentConfigs[document.document.templateType]?.component({
        content: document.jsonContent,
        logo: logo,
      });
      if (component) {
        return component;
      } else {
        return (
          <Document>
            <Page style={styles.body} size="A4" wrap>
              <Text style={styles.title}>Error: Could not find document template</Text>
            </Page>
          </Document>
        );
      }
    } else {
      return <div>Could not load document</div>;
    }
  };

  const loadDocument = (id: string): Promise<TemplateDocumentVersion> => {
    return new Promise((resolve, reject) => {
      get(getTemplateDocumentVersion, id)
        .then(res => {
          if (res.data && (res.data as any).getTemplateDocumentVersion) {
            resolve((res.data as any).getTemplateDocumentVersion);
          } else reject(new Error('No data on graphql response'));
        })
        .catch(error => reject(error));
    });
  };

  useEffect(() => {
    setIsLoading(true);
    const org = props.data.organisation;
    if (org?.logo && org?.logoPosition) {
      const url = bucketPath + '/public/' + org.logo.key;
      let position;
      switch (org.logoPosition) {
        case 'CENTER':
          position = LogoPosition.CENTER;
          break;
        case 'LEFT':
          position = LogoPosition.LEFT;
          break;
        case 'RIGHT':
          position = LogoPosition.RIGHT;
          break;
        default:
          position = LogoPosition.CENTER;
          break;
      }
      setLogo({ imageUrl: url, position });
    }
    loadDocument(props.data.taskData.documentId).then(res => {
      setDocumentData({
        document: res,
        jsonContent: res.stringifiedContent ? JSON.parse(res.stringifiedContent) : null,
      });
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <Loader />
      </div>
    );
  } else if (isTaskComplete) {
    return (
      <div className="d-flex justify-content-center mt-5 text-primary">
        <div>Thank you!</div>
      </div>
    );
  } else {
    return (
      <div className="container" style={{ maxWidth: '1400px', paddingTop: '25px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="email_preview">
            <div className="p-0 m-0" style={{ height: '100%' }}>
              {documentData && (
                <PDFViewer style={{ width: '100%', height: '100vh' }}>
                  {getDocumentJSXForPDFViewer(documentData)}
                </PDFViewer>
              )}
            </div>
          </div>
          <div className="sigContainer">
            <ButtonWithIcons
              disabled={!documentData}
              title={'Approve Document'}
              buttonType={'btn-bd-purple'}
              handleClick={() => props.data.flowableFunctions.onNext()}
              style={{ marginTop: '40px', width: '160px' }}
            />
          </div>
        </div>
      </div>
    );
  }
};
