import {
  appeal_hearing_appointChairpersonSchema,
  appeal_hearing_appointEmployerRepresentativeSchema,
  appeal_hearing_hearingLogisticsSchema,
  appeal_hearing_minutesAndRecordingSchema,
  appeal_hearing_recordEmployeeRightsAndResponsesSchema,
  appeal_hearing_selectWitnessSchema,
} from '../../forms/ValidationSchema/AppealHearingValidationSchema';
import {
  initial_hearing_appointChairpersonSchema,
  initial_hearing_appointEmployerRepresentativeSchema,
  initial_hearing_hearingLogisticsSchema,
  initial_hearing_minutesAndRecordingSchema,
  initial_hearing_recordEmployeeRightsAndResponsesSchema,
  SelectWitnessSchema,
} from '../../forms/ValidationSchema/InitialHearingValidationSchema';

export const initial_hearing_AppointEmployerRepresentative = {
  fields: {
    initial_hearing_suspendPendingHearing: 'boolean',
    initial_hearing_employerRepresentativeType: 'string',
    initial_hearing_employerRepresentativeEmailAddress: 'string',
    initial_hearing_employerRepresentativeFirstName: 'string',
    initial_hearing_employerRepresentativeLastName: 'string',
    // initial_hearing_employerRepresentativeId: 'string',
  },
  validationSchema: initial_hearing_appointEmployerRepresentativeSchema,
};

export const appeal_hearing_AppointEmployerRepresentative = {
  fields: {
    appeal_hearing_suspendPendingHearing: 'boolean',
    appeal_hearing_employerRepresentativeType: 'string',
    appeal_hearing_employerRepresentativeEmailAddress: 'string',
    appeal_hearing_employerRepresentativeFirstName: 'string',
    appeal_hearing_employerRepresentativeLastName: 'string',
    // appeal_hearing_employerRepresentativeId: 'string',
  },
  validationSchema: appeal_hearing_appointEmployerRepresentativeSchema,
};

export const initial_hearing_AppointChairperson = {
  fields: {
    initial_hearing_chairpersonType: 'string',
    initial_hearing_chairpersonFirstName: 'string',
    initial_hearing_chairpersonLastName: 'string',
    initial_hearing_chairpersonEmailAddress: 'string',
    // initial_hearing_chairpersonId: 'string',
  },
  validationSchema: initial_hearing_appointChairpersonSchema,
};

export const appeal_hearing_AppointChairperson = {
  fields: {
    appeal_hearing_chairpersonType: 'string',
    appeal_hearing_chairpersonFirstName: 'string',
    appeal_hearing_chairpersonLastName: 'string',
    appeal_hearing_chairpersonEmailAddress: 'string',
    //appeal_hearing_chairpersonId: 'string',
  },
  validationSchema: appeal_hearing_appointChairpersonSchema,
};

export const initial_hearing_SelectWitness = {
  fields: {
    initial_hearing_hasWitness: 'boolean',
    initial_hearing_hearingWitnesses: 'array',
  },
  validationSchema: SelectWitnessSchema,
};

export const appeal_hearing_SelectWitness = {
  fields: {
    appeal_hearing_hasWitness: 'boolean',
    appeal_hearing_hearingWitnesses: 'array',
  },
  validationSchema: appeal_hearing_selectWitnessSchema,
};

export const initial_hearing_HearingLogistics = {
  fields: {
    initial_hearing_hearingDate: 'string',
    initial_hearing_hearingTime: 'string',
    initial_hearing_hearingLocation: 'string',
    initial_hearing_chairpersonFirstName: 'string',
    initial_hearing_chairpersonLastName: 'string',
    initial_hearing_chairpersonEmailAddress: 'string',
    initial_hearing_employerRepresentativeFirstName: 'string',
    initial_hearing_employerRepresentativeLastName: 'string',
    initial_hearing_employerRepresentativeEmailAddress: 'string',
    initial_hearing_hearingWitnesses: 'array',
  },
  validationSchema: initial_hearing_hearingLogisticsSchema,
};

export const appeal_hearing_HearingLogistics = {
  fields: {
    appeal_hearing_hearingDate: 'string',
    appeal_hearing_hearingTime: 'string',
    appeal_hearing_hearingLocation: 'string',
    appeal_hearing_ChairpersonFirstName: 'string',
    appeal_hearing_ChairpersonLastName: 'string',
    appeal_hearing_ChairpersonEmailAddress: 'string',
    appeal_hearing_employerRepresentativeFirstName: 'string',
    appeal_hearing_employerRepresentativeLastName: 'string',
    appeal_hearing_employerRepresentativeEmailAddress: 'string',
    appeal_hearing_hearingWitnesses: 'array',
  },
  validationSchema: appeal_hearing_hearingLogisticsSchema,
};

export const initial_hearing_EmployeeRightsAndResponses = {
  fields: {
    initial_hearing_requestedTranslator: 'string',
    initial_hearing_language: 'string',
    initial_hearing_requestedExternalRepresentation: 'string',
    initial_hearing_reasonForRequestingExternalRepresentation: 'string',
    initial_hearing_eERepresentativePresent: 'string',
    initial_hearing_reasonForEeRepresentativePresence: 'string',
    initial_hearing_disabledAndRequiresAccommodation: 'string',
    initial_hearing_reasonForRequiringAccommodation: 'string',
  },
  validationSchema: initial_hearing_recordEmployeeRightsAndResponsesSchema,
};

export const appeal_hearing_EmployeeRightsAndResponses = {
  fields: {
    appeal_hearing_requestedTranslator: 'string',
    appeal_hearing_language: 'string',
    appeal_hearing_requestedExternalRepresentation: 'string',
    appeal_hearing_reasonForRequestingExternalRepresentation: 'string',
    appeal_hearing_eERepresentativePresent: 'string',
    appeal_hearing_reasonForEeRepresentativePresence: 'string',
    appeal_hearing_disabledAndRequiresAccommodation: 'string',
    appeal_hearing_reasonForRequiringAccommodation: 'string',
  },
  validationSchema: appeal_hearing_recordEmployeeRightsAndResponsesSchema,
};

export const initial_hearing_MinutesAndRecording = {
  fields: {
    initial_hearing_employerRepresentativeFirstName: 'string',
    initial_hearing_employerRepresentativeLastName: 'string',
    initial_hearing_chairpersonFirstName: 'string',
    initial_hearing_chairpersonLastName: 'string',
    initial_hearing_hearingWitnesses: 'array',
    initial_hearing_otherAttendees: 'array',
    initial_hearing_hearingDate: 'string',
    initial_hearing_hearingTime: 'string',
    initial_hearing_hearingLocation: 'string',
    initial_hearing_minutesDocuments: 'array',
    initial_hearing_evidenceDocuments: 'array',
    initial_hearing_isChairpersonPresent: 'boolean',
    initial_hearing_isEmployeePresent: 'boolean',
    initial_hearing_isEmployerRepresentativePresent: 'boolean',
    initial_hearing_reasonForDeclining: 'string',
  },
  validationSchema: initial_hearing_minutesAndRecordingSchema,
};

export const appeal_hearing_MinutesAndRecording = {
  fields: {
    appeal_hearing_employerRepresentativeFirstName: 'string',
    appeal_hearing_employerRepresentativeLastName: 'string',
    appeal_hearing_chairpersonFirstName: 'string',
    appeal_hearing_chairpersonLastName: 'string',
    appeal_hearing_hearingWitnesses: 'array',
    appeal_hearing_otherAttendees: 'array',
    appeal_hearing_hearingDate: 'string',
    appeal_hearing_hearingTime: 'string',
    appeal_hearing_hearingLocation: 'string',
    appeal_hearing_minutesDocuments: 'array',
    appeal_hearing_evidenceDocuments: 'array',
    appeal_hearing_isChairpersonPresent: 'boolean',
    appeal_hearing_isEmployeePresent: 'boolean',
    appeal_hearing_isEmployerRepresentativePresent: 'boolean',
    appeal_hearing_reasonForDeclining: 'string',
  },
  validationSchema: appeal_hearing_minutesAndRecordingSchema,
};
