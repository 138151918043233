import * as Yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '../fields/FormField.component';

export const RecordCounsellingSchema = Yup.object().shape({
  counsellingSession: Yup.object().shape({
    counsellingDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Required'),
    counsellingTime: Yup.string()
      .test('time', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid())
      .required('Required'),
    counsellingLocation: Yup.string().required('Required'),
    counsellingAttendees: Yup.array()
      .required('Required')
      .nullable(),
    counsellingDocuments: Yup.array()
      .of(
        Yup.object().shape({
          fileName: Yup.string(),
          key: Yup.string(),
          url: Yup.string(),
        }),
      )
      .nullable(),
    counsellingSummary: Yup.string().when('counsellingDocuments', {
      is: null,
      then: Yup.string().required('Required'),
    }),
  }),
  performanceShortfalls: Yup.array()
    .of(
      Yup.object().shape({
        shortfallDescription: Yup.string(),
        date: Yup.string().test('Date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid() || !val),
        counsellingProgressUpdates: Yup.array().of(
          Yup.object().shape({
            update: Yup.string().required('Required'),
            date: Yup.string()
              .test('updatedDate', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid() || !val)
              .required('Required'),
          }),
        ),
        documents: Yup.array()
          .of(
            Yup.object().shape({
              fileName: Yup.string(),
              key: Yup.string(),
              url: Yup.string(),
            }),
          )
          .nullable(),
      }),
    )
    .nullable(),
});

export const CaseAssessmentSchema = Yup.object().shape({
  remedialActionsInstituted: Yup.boolean(),
  performanceReviewFeedbackGiven: Yup.boolean(),
  performanceReviewFeedbackSummary: Yup.string().when('performanceReviewFeedbackGiven', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  haveMetInformally: Yup.boolean(),
  informalMeetingSummary: Yup.string().when('haveMetInformally', { is: true, then: Yup.string().required('Required') }),
  hasReceivedCoaching: Yup.boolean(),
  coachingSummary: Yup.string().when('hasReceivedCoaching', { is: true, then: Yup.string().required('Required') }),
  hasReceivedOnTheJobTraining: Yup.boolean(),
  onTheJobTrainingSummary: Yup.string().when('hasReceivedOnTheJobTraining', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  hasReceivedClassroomBasedSupport: Yup.boolean(),
  classroomBasedSupportSummary: Yup.string().when('hasReceivedClassroomBasedSupport', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  extraInformation: Yup.boolean(),
  extraInformationSummary: Yup.string().when('extraInformation', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  caseAssessmentSupportingDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string(),
        url: Yup.string(),
        key: Yup.string(),
      }),
    )
    .nullable()
    .when('remedialActionsInstituted', { is: true, then: Yup.array().required('Required') }),
});

export const PrepareEmployeeDiscussionSchema = Yup.object().shape({
  compileInvite: Yup.boolean(),
  employeeDiscussionDate: Yup.string()
    .nullable()
    .when('compileInvite', {
      is: true,
      then: Yup.string()
        .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid() || !val)
        .required('Required'),
    }),
  employeeDiscussionTime: Yup.string()
    .nullable()
    .when('compileInvite', {
      is: true,
      then: Yup.string()
        .test('date', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid() || !val)
        .required('Required'),
    }),
  employeeDiscussionAttendees: Yup.array()
    .of(
      Yup.object().shape({
        attendeeName: Yup.string().required('Please enter attendee name'),
        attendeeEmailAddress: Yup.string()
          .email('Email is not valid')
          .required('Please enter email'),
        attendeeId: Yup.string().required('Please enter attendee id'),
      }),
    )
    .nullable()
    .when('compileInvite', { is: true, then: Yup.array().required('Required') }),
  employeeDiscussionLocation: Yup.string().when('compileInvite', { is: true, then: Yup.string().required('Required') }),
});

export const PreparePerformanceCounsellingSchema = Yup.object().shape({
  compileInvite: Yup.boolean().required('Required'),
  counsellingSession: Yup.object().shape({
    counsellingDate: Yup.string()
      .test('testDate', 'Required', function(value): boolean {
        //@ts-ignore
        const { from } = this;
        return !from[1].value.compileInvite || value;
      })
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid() || !val),
    counsellingTime: Yup.string()
      .test('testTime', 'Required', function(value): boolean {
        //@ts-ignore
        const { from } = this;
        return !from[1].value.compileInvite || value;
      })
      .test('time', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid() || !val),
    counsellingLocation: Yup.string().test('testLocation', 'Required', function(value): boolean {
      // @ts-ignore
      const { from } = this;
      return !from[1].value.compileInvite || value;
    }),
    counsellingAttendees: Yup.array()
      .of(
        Yup.object().shape({
          attendeeName: Yup.string().required('Required'),
          attendeeEmailAddress: Yup.string().required('Required'),
          attendeeId: Yup.string().required('Required'),
        }),
      )
      .test('testAttendees', 'Required', function(value): boolean {
        // @ts-ignore
        const { from } = this;
        return !from[1].value.compileInvite || value;
      })
      .nullable()
      .when('compileInvite', { is: true, then: Yup.array().required('Required') }),
  }),
});

export const RecordEmployeeDiscussionSchema = Yup.object().shape({
  employeeDiscussionDate: Yup.string()
    .test('date', 'Please select a valid date', val => moment(val, DATE_FORMAT).isValid())
    .required('Required'),
  employeeDiscussionTime: Yup.string()
    .test('date', 'Please select a valid time', val => moment(val, TIME_FORMAT).isValid())
    .required('Required'),
  employeeDiscussionAttendees: Yup.array()
    .of(
      Yup.object().shape({
        attendeeName: Yup.string().required('Please enter attendee name'),
        attendeeEmailAddress: Yup.string()
          .email('Email is not valid')
          .required('Please enter email'),
        attendeeId: Yup.string().required('Please enter attendee id'),
      }),
    )
    .required('Required')
    .nullable(),
  employeeDiscussionLocation: Yup.string().required('Required'),
  employeeDiscussionDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string(),
        url: Yup.string(),
        key: Yup.string(),
      }),
    )
    .nullable(),
  employeeDiscussionSummary: Yup.string().when('employeeDiscussionDocuments', {
    is: null,
    then: Yup.string().required('Please upload discussion document  or complete summary'),
  }),
});

export const DetermineNatureOfPerformanceShortfallsSchema = Yup.object().shape({
  furtherActionRequired: Yup.boolean(),
  reasonForDecision: Yup.string().required('Required'),
  wayForward: Yup.string().when('furtherActionRequired', {
    is: true,
    then: Yup.string().required('Required'),
  }),
});

export const ScheduleAdditionalCounsellingSessionSchema = Yup.object().shape({
  additionalSessions: Yup.boolean().required('Required'),
  compileInvite: Yup.boolean().required('Required'),
  counsellingHavePerformanceConcernsBeenAddressed: Yup.boolean().required('Required'),
  reasonForFurtherActionAfterCounselling: Yup.string().when(
    ['counsellingHavePerformanceConcernsBeenAddressed', 'additionalSessions'],
    {
      is: (counsellingHavePerformanceConcernsBeenAddressed, additionalSessions) =>
        !counsellingHavePerformanceConcernsBeenAddressed && !additionalSessions,
      then: Yup.string().required('Required'),
    },
  ),
  furtherActionAfterCounselling: Yup.string().when(
    ['counsellingHavePerformanceConcernsBeenAddressed', 'additionalSessions'],
    {
      is: (counsellingHavePerformanceConcernsBeenAddressed, additionalSessions) =>
        !counsellingHavePerformanceConcernsBeenAddressed && !additionalSessions,
      then: Yup.string().required('Required'),
    },
  ),
  counsellingSession: Yup.object().shape({
    counsellingDate: Yup.string()
      .test('testDate', 'Required', function(value): boolean {
        //@ts-ignore
        const { from } = this;
        return !from[1].value.compileInvite || !from[1].value.additionalSessions || value;
      })
      .test('date', 'Date is not valid', value => moment(value, DATE_FORMAT).isValid() || !value),
    counsellingTime: Yup.string()
      .test('testTime', 'Required', function(value): boolean {
        //@ts-ignore
        const { from } = this;
        return !from[1].value.compileInvite || !from[1].value.additionalSessions || value;
      })
      .test('time', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid() || !val),
    counsellingLocation: Yup.string().test('testLocation', 'Required', function(value): boolean {
      // @ts-ignore
      const { from } = this;
      return !from[1].value.compileInvite || !from[1].value.additionalSessions || value;
    }),
    counsellingAttendees: Yup.array()
      .of(
        Yup.object().shape({
          attendeeName: Yup.string().required('Required'),
          attendeeEmailAddress: Yup.string().required('Required'),
          attendeeId: Yup.string().required('Required'),
        }),
      )
      .test('testAttendees', 'Required', function(value): boolean {
        // @ts-ignore
        const { from } = this;
        return !from[1].value.compileInvite || !from[1].value.additionalSessions || value;
      })
      .nullable(),
  }),
});

export const InitiatePipSchema = Yup.object().shape({
  compilePipDiscussionInvite: Yup.boolean(),
  pipDiscussionDate: Yup.string().when('compilePipDiscussionInvite', {
    is: true,
    then: Yup.string()
      .test('date', 'Required', val => moment(val, DATE_FORMAT).isValid())
      .required('Required'),
  }),
  pipDiscussionTime: Yup.string().when('compilePipDiscussionInvite', {
    is: true,
    then: Yup.string()
      .test('time', 'Required', val => moment(val, TIME_FORMAT).isValid())
      .required('Required'),
  }),
  pipDiscussionLocation: Yup.string().when('compilePipDiscussionInvite', {
    is: true,
    then: Yup.string().required('Required'),
  }),

  pipDiscussionAttendees: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
        attendeeId: Yup.string(),
        attendeeEmailAddress: Yup.string(),
        attendeeName: Yup.string(),
      }),
    )
    .nullable()
    .when('compilePipDiscussionInvite', { is: true, then: Yup.array().required('Required') }),

  performanceShortfalls: Yup.array()
    .of(
      Yup.object().shape({
        shortfallDescription: Yup.string().required('Required'),
        performanceGoal: Yup.string().required('Required'),
        qualityStandard: Yup.string().required('Required'),
        deadline: Yup.string().required('Required'),
        assistanceRequiredFromEmployer: Yup.string().required('Required'),
        actionsToBeTakenByEmployee: Yup.string().required('Required'),
      }),
    )
    .nullable()
    .required('Required'),

  pipStartDate: Yup.string()
    .test('pipStartDate', 'Required', val => moment(val, DATE_FORMAT).isValid())
    .test('checkIfToday', 'Start date cannot be before today', function(value) {
      return moment(value, DATE_FORMAT).isSameOrAfter(moment(), 'day');
    })
    .required('Required'),
  pipEndDate: Yup.string()
    .test('pipEndDate', 'Required', val => moment(val, DATE_FORMAT).isValid())
    .test('checkAgainstPipStartDate', 'End date cannot be before start date', function(value) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const { from } = this;
      const pipStartDate = from[0].value.pipStartDate;
      return moment(value, DATE_FORMAT).isAfter(moment(pipStartDate, DATE_FORMAT), 'day');
    })
    .required('Required'),
});

export const RecordPIPDiscussionSchema = Yup.object().shape({
  pipDiscussionDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string(),
        url: Yup.string(),
        key: Yup.string(),
      }),
    )
    .nullable(),
  pipDiscussionSummary: Yup.string().when('pipDiscussionDocuments', {
    is: null,
    then: Yup.string().required('Required. Please enter summary or upload a document'),
  }),
  pipProgressMeetings: Yup.array()
    .of(
      Yup.object().shape({
        pipProgressMeetingDate: Yup.string()
          .test('meetingDate', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
          .test('checkIfToday', 'You cannot schedule a date before today', function(value) {
            return moment(value, DATE_FORMAT).isSameOrAfter(moment(), 'day');
          })
          .test('checkAgainstPipDuration', 'You cannot schedule a date past the PIP end date', function(value) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const { from } = this;
            const pipEndDate = from[1].value.pipEndDate;
            return moment(value, DATE_FORMAT).isSameOrBefore(moment(pipEndDate, DATE_FORMAT), 'day');
          })
          .required('Required'),
        pipProgressMeetingTime: Yup.string()
          .test('meetingTime', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid())
          .required('Required'),
      }),
    )
    .nullable(),
  performanceShortfalls: Yup.array()
    .of(
      Yup.object().shape({
        shortfallDescription: Yup.string().required('Required'),
        performanceGoal: Yup.string().required('Required'),
        qualityStandard: Yup.string().required('Required'),
        deadline: Yup.string().required('Required'),
        assistanceRequiredFromEmployer: Yup.string().required('Required'),
        actionsToBeTakenByEmployee: Yup.string().required('Required'),
      }),
    )
    .nullable()
    .required('Required'),
  pipStartDate: Yup.string()
    .test('pipStartDate', 'Required', val => moment(val, DATE_FORMAT).isValid())
    .test('checkIfToday', 'Start date cannot be before today', function(value) {
      return moment(value, DATE_FORMAT).isSameOrAfter(moment(), 'day');
    })
    .required('Required'),
  pipEndDate: Yup.string()
    .test('pipEndDate', 'Required', val => moment(val, DATE_FORMAT).isValid())
    .test('checkAgainstPipStartDate', 'End date cannot be before start date', function(value) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const { from } = this;
      const pipStartDate = from[0].value.pipStartDate;
      return moment(value, DATE_FORMAT).isAfter(moment(pipStartDate, DATE_FORMAT), 'day');
    })
    .required('Required'),
});

export const RecordPIPSessionSchema = Yup.object().shape({
  session: Yup.object()
    .shape({
      pipProgressMeetingDate: Yup.string()
        .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid() || !val)
        .required('Required'),
      pipProgressMeetingTime: Yup.string()
        .test('time', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid() || !val)
        .required('Required'),
      pipProgressMeetingLocation: Yup.string().required('Required'),
      pipProgressMeetingAttendees: Yup.array()
        .of(
          Yup.object().shape({
            attendeeEmailAddress: Yup.string()
              .email('Email is not valid')
              .required('Required'),
            attendeeName: Yup.string().required('Required'),
          }),
        )
        .required('Required')
        .nullable(),
    })
    .required('Required'),
  performanceShortfalls: Yup.array().of(
    Yup.object().shape({
      pipProgressUpdates: Yup.array().of(
        Yup.object().shape({
          progressRemarks: Yup.string().required('Required'),
          reviewPeriod: Yup.string()
            .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid() || !val)
            .required('Required'),
          progressStatus: Yup.string().required('Required'),
        }),
      ),
    }),
  ),
});

export const NotifyPayrollOfDemotionSchema = Yup.object().shape({
  didEmployeeSignedDemotionLetterInAcceptance: Yup.boolean().required('Required'),
  demotionDiscussionDate: Yup.string()
    .test('discussionDate', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid() || !val)
    .when('didEmployeeSignedDemotionLetterInAcceptance', {
      is: false,
      then: Yup.string()
        .test('discussionDate', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
        .required('Required'),
    }),
  demotionDiscussionTime: Yup.string()
    .test('discussionTime', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid() || !val)
    .when('didEmployeeSignedDemotionLetterInAcceptance', {
      is: false,
      then: Yup.string()
        .test('discussionTime', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid())
        .required('Required'),
    }),
  demotionDiscussionLocation: Yup.string().when('didEmployeeSignedDemotionLetterInAcceptance', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  demotionDiscussionAttendees: Yup.array()
    .of(
      Yup.object().shape({
        attendeeName: Yup.string(),
        attendeeEmailAddress: Yup.string().email('Email is not valid'),
      }),
    )
    .nullable()
    .when('didEmployeeSignedDemotionLetterInAcceptance', { is: false, then: Yup.array().required('Required') }),
});

export const RecordDemotionDiscussionSchema = Yup.object().shape({
  demotionDiscussionDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Required'),
  demotionDiscussionTime: Yup.string()
    .test('date', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid())
    .required('Required'),
  demotionDiscussionAttendees: Yup.array()
    .of(
      Yup.object().shape({
        attendeeName: Yup.string().required('Please enter attendee name'),
        attendeeEmailAddress: Yup.string()
          .email('Email is not valid')
          .required('Please enter email'),
        attendeeId: Yup.string().required('Please enter attendee id'),
      }),
    )
    .required('Required')
    .nullable(),
  demotionDiscussionLocation: Yup.string().required('Required'),
  demotionDiscussionDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string(),
        url: Yup.string(),
        key: Yup.string(),
      }),
    )
    .nullable(),
  demotionDiscussionSummary: Yup.string().when('demotionDiscussionDocuments', {
    is: null,
    then: Yup.string().required('Required'),
  }),
});

export const PipAssessmentSchema = Yup.object().shape({
  pipHavePerformanceConcernsBeenAddressed: Yup.boolean(),
  furtherActionAfterPip: Yup.string().when('pipHavePerformanceConcernsBeenAddressed', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  reasonForFurtherActionAfterPip: Yup.string().when('pipHavePerformanceConcernsBeenAddressed', {
    is: false,
    then: Yup.string().required('Required'),
  }),
});

export const ExtendedPipAssessmentSchema = Yup.object().shape({
  extendedPipHavePerformanceConcernsBeenAddressed: Yup.boolean(),
  furtherActionAfterExtendedPip: Yup.string().when('pipHavePerformanceConcernsBeenAddressed', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  reasonForFurtherActionAfterExtendedPip: Yup.string().when('pipHavePerformanceConcernsBeenAddressed', {
    is: false,
    then: Yup.string().required('Required'),
  }),
});

export const PrepareAgreedDemotionSchema = Yup.object().shape({
  demotionNewJobTitle: Yup.string().required('Required'),
  demotionNewJobLevel: Yup.string().required('Required'),
  demotionNewDepartment: Yup.string().required('Required'),
  demotionNewRemunerationPackage: Yup.string().required('Required'),
  demotionDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Required'),
  demotionNewTerms: Yup.string().required('Required'),
});

export const WrittenWarningWithPipHearingSchema = Yup.object().shape({
  warningValidityPeriod: Yup.string().required('Please select validity period'),
  warningExpiryDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Required'),
  hearingDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Required'),
  performanceShortfalls: Yup.array()
    .of(
      Yup.object().shape({
        shortfallDescription: Yup.string().required('Please select transgression'),
        correctiveAction: Yup.string().required('Please enter corrective action'),
      }),
    )
    .required()
    .nullable(),
});
