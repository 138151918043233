import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';

interface PopupProps {
  /**
   * Message to show the user.
   */
  message: string;
  /**
   * Heading for the modal to show to the user.
   */
  heading: string;
}

interface ModalProps extends PopupProps {
  /**
   * Function that should return with the result.
   */
  callback: Function;
}

const Confirm: React.FunctionComponent<ModalProps> = ({ callback, message, heading }): React.ReactElement => {
  const [open, setOpen] = useState(true);
  const onClick = (res: boolean): void => {
    setOpen(false);
    setTimeout(() => callback(res), 1000);
  };
  useEffect(() => {
    if (!callback) {
      throw new Error('No callback specified, please specify a callback!');
    }
  });
  return (
    <Modal size="sm" isOpen={open} centered>
      <ModalHeader>
        <div>
          <h3 className="text-muted">{heading}</h3>
        </div>
      </ModalHeader>
      <ModalBody className="document-modal-body pt-0" style={{ minHeight: 'auto' }}>
        <h5 className="text-muted">{message}</h5>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-center p-0 pb-4">
        <button
          className="modal-close-button text-white btn btn-primary text-uppercase"
          style={{ height: '48px' }}
          onClick={(): void => onClick(false)}
        >
          Cancel
        </button>
        <button onClick={(): void => onClick(true)} className="btn btn-secondary modal-send-button text-uppercase">
          Continue
        </button>
      </ModalFooter>
    </Modal>
  );
};

const Alert: React.FunctionComponent<ModalProps> = ({ callback, message, heading }): React.ReactElement => {
  const [open, setOpen] = useState(true);
  const onClick = (res: boolean): void => {
    setOpen(false);
    setTimeout(() => callback(res), 1000);
  };
  useEffect(() => {
    if (!callback) {
      throw new Error('No callback specified, please specify a callback!');
    }
  });
  return (
    <Modal size="sm" isOpen={open} centered>
      <ModalHeader className="text-muted">
        <div>
          <h3 className="text-muted">{heading}</h3>
        </div>
      </ModalHeader>
      <ModalBody className="document-modal-body pt-0" style={{ minHeight: 'auto' }}>
        <h5 className="text-muted">{message}</h5>
      </ModalBody>
      <ModalFooter>
        <button onClick={(): void => onClick(true)} className="modal-send-button text-uppercase ml-auto">
          Ok
        </button>
      </ModalFooter>
    </Modal>
  );
};

/**
 * Popop manager
 */
const Popup = {
  /**
   * Show a confirmation modal with a message where the user must accept or decline.
   * @param {string} heading - Heading to show to user
   * @param {string} message - Message to ask the user
   */
  confirm: (heading: string, message: string): Promise<boolean> =>
    new Promise(resolve => {
      const res = (ans: boolean): void => {
        resolve(ans);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ReactDOM.unmountComponentAtNode(document.querySelector('#popup-container'));
      };
      const element = <Confirm callback={res} message={message} heading={heading} />;
      ReactDOM.render(element, document.querySelector('#popup-container'));
    }),
  // TODO!: Split alert into info, warning and alert(error)
  /**
   * Show an alert to a user with a message.
   * @param {string} heading - Heading to show to user
   * @param {string} message - Message to ask the user
   */
  alert: (heading: string, message: string): Promise<boolean> =>
    new Promise(resolve => {
      const res = (ans: boolean): void => {
        resolve(ans);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ReactDOM.unmountComponentAtNode(document.querySelector('#popup-container'));
      };
      const element = <Alert callback={res} message={message} heading={heading} />;
      ReactDOM.render(element, document.querySelector('#popup-container'));
    }),
};

export default Popup;
