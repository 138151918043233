export const InitialHearingGuidancePageTabs = [
  'Role of Chairperson',
  'Role of Employer Representative',
  'Employee Rights',
  'Disciplinary Hearing Steps',
  'Evidence',
  'Sanctions',
  'FAQ',
];

export const GuidancePageIncapacityTabs = [
  'Role of Chairperson',
  'Role of Employer Representative',
  'Employee Rights',
  'Incapacity Hearing Steps',
  'Incapacity Outcomes',
];
