import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { UserContext, UserContextProps } from '../../App';
import TopBarComponent from '../../components/topBar/TopBar.component';
import { useErrorHandler } from '../../utils/notification-utils';
import { CaseTableData } from '../../utils/case-utils';
import CaseTable from '../../components/tables/CaseTable.component';
import { getFlowableCasesForExternalUsers } from '../../components/tables/case-table-utils';
import MyTasks from '../../components/dashboardWidgets/myTasks';

const ViewCasesExternalUser: React.FC = () => {
  const [, setLoading] = useState(true);
  const [caseData, setCaseData] = useState<CaseTableData[]>([]);
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const handleError = useErrorHandler();

  const loadCases = async (userId: string): Promise<void> => {
    setLoading(true);
    const cases = await getFlowableCasesForExternalUsers(userId).catch(error => {
      handleError(error);
      setLoading(false);
    });
    if (cases) {
      setCaseData(cases);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currentUser && !currentUser.organisationId) {
      loadCases(currentUser.id);
    }
  }, []);

  return (
    <>
      <TopBarComponent title={'Cases'} subTitle={'Cases In Progress'} hideSearch={true}></TopBarComponent>
      <div className="content">
        <div className="widgets">
          <div className="row">
            <div className="col-4">
              <MyTasks />
            </div>
          </div>
        </div>
        <Row>
          <Col className="mb-5" md="12">
            <Card>
              <CardBody>
                <CaseTable
                  caseData={caseData}
                  caseNumberPath={'case/'}
                  showActionButtons={true}
                  showEmployeeName={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewCasesExternalUser;
