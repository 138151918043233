import React, { ReactElement } from 'react';
import { Col, Row } from 'reactstrap';
import Tabs from '../../../../../components/HearingAndAppealTabs/HearingAndAppealTabs.component';
import { HEARING_TABS } from '../../../../../utils/case-utils';
import { Formik, FormikProps, FormikValues } from 'formik';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { getFieldsAndValidationSchema } from '../../hearing-utils';
import InitialHearingRecordEmployeeRightsAndResponsesForm from './InitialHearingRecordEmployeeRightsAndResponsesForm';

const InitialHearingRecordEmployeeRightsAndResponses: React.FC<WorkflowComponentProps> = (
  props: WorkflowComponentProps,
) => {
  const setupValues = getFieldsAndValidationSchema(props.data.caseType, props.data.flowableFunctions.formKey);

  const { fields, validationSchema } = setupValues;

  const initialValues = getFormDataFromCaseData(fields, props.data.caseData);

  const submitValues = (values: FormikValues): void => {
    const formValues = { ...values };
    props.data.flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Hearing Preparations</h4>
      <Row>
        <Col md={12}>
          <Tabs page={5} tabs={HEARING_TABS} />
        </Col>
      </Row>
      <h5 className="text-h5 text-capitalize font-weight-500 mt-3">Record Employee Rights/Responses</h5>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submitValues}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, errors }: FormikProps<FormikValues>): ReactElement => (
          <>
            <InitialHearingRecordEmployeeRightsAndResponsesForm data={props.data} />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={() => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default InitialHearingRecordEmployeeRightsAndResponses;
