import { CaseData } from '../../WorkflowContainer/workflow-utils';
import { Employee } from '../../../models';
import { UserDetails } from '../../../App';
import { DocumentJSONContent } from '../DocumentEditor';
import { getCommonFieldValuesFromCaseData } from '../common-document-utils';
import {
  prepareFieldValuesPoorPerformance,
  demotionLetterFields,
  agreedDemotionLetterFields,
  hearingFindingsFormFields,
  appealFormFields,
  appealFindingsFormFields,
  noticeOfIncapacityHearingFields,
  appealOutcomeFormFields,
  dismissalWithPayLetterFields,
  noticeOfAppealIncapacityHearingFields,
  performanceImprovementPlanFields,
  pipConclusionLetterFields,
  pipExtensionFollowingHearingFields,
  summaryDismissalLetterFields,
  pipExtensionFields,
} from './prepareFieldValues';
import { TemplateType } from '../../../API';

const getFieldValuesFromCaseData = (
  caseData: CaseData,
  employee: Employee,
  author: UserDetails,
  fieldNames: string[],
): DocumentJSONContent => {
  const values = prepareFieldValuesPoorPerformance(caseData);
  const documentContent: DocumentJSONContent = {};
  fieldNames.forEach((field: string) => {
    documentContent[field] = values[field];
  });

  const commonValues = getCommonFieldValuesFromCaseData(caseData, employee, author);
  return { ...commonValues, ...documentContent };
};

const getRequiredFieldsByTemplateType = (
  templateType: TemplateType,
  caseData: CaseData,
  employee: Employee,
  author: UserDetails,
): DocumentJSONContent | null => {
  const templateTypeMap: { [key: string]: string[] | null } = {
    [TemplateType.NOTICE_OF_HEARING_POOR_PERFORMANCE]: noticeOfIncapacityHearingFields,
    [TemplateType.NOTICE_OF_HEARING_POOR_PERFORMANCE_APPEAL]: noticeOfAppealIncapacityHearingFields,
    //************************ PIPs ************************
    [TemplateType.PERFORMANCE_IMPROVEMENT_PLAN]: performanceImprovementPlanFields,
    [TemplateType.PERFORMANCE_IMPROVEMENT_PLAN_REVISED]: performanceImprovementPlanFields,
    [TemplateType.EXTENSION_OF_PIP]: pipExtensionFields,
    [TemplateType.EXTENSION_OF_PIP_REVISED]: pipExtensionFields,
    [TemplateType.EXTENSION_OF_PIP_FOLLOWING_HEARING]: pipExtensionFollowingHearingFields,
    [TemplateType.EXTENSION_OF_PIP_FOLLOWING_HEARING_REVISED]: pipExtensionFollowingHearingFields,
    //******************************************************
    [TemplateType.DEMOTION_LETTER_POOR_PERFORMANCE]: demotionLetterFields,
    [TemplateType.SUMMARY_DISMISSAL_LETTER_POOR_PERFORMANCE]: summaryDismissalLetterFields,
    [TemplateType.DISMISSAL_WITH_NOTICE_PAY_LETTER_POOR_PERFORMANCE]: dismissalWithPayLetterFields,
    [TemplateType.HEARING_FINDINGS_FORM_POOR_PERFORMANCE_APPEAL]: appealFindingsFormFields,
    [TemplateType.APPEAL_FORM_POOR_PERFORMANCE]: appealFormFields,
    [TemplateType.HEARING_OUTCOME_POOR_PERFORMANCE_APPEAL]: appealOutcomeFormFields,
    [TemplateType.HEARING_FINDINGS_FORM_POOR_PERFORMANCE]: hearingFindingsFormFields,
    [TemplateType.SUCCESSFUL_CONCLUSION_PERFORMANCE_PROCESS_LETTER]: pipConclusionLetterFields,
    [TemplateType.AGREED_DEMOTION_LETTER]: agreedDemotionLetterFields,
    default: null,
  };

  const fieldNames = templateTypeMap[templateType] || templateTypeMap['default'];
  if (fieldNames) {
    return getFieldValuesFromCaseData(caseData, employee, author, fieldNames);
  } else {
    return null;
  }
};

export default class PoorPerformanceDocumentUtils {
  public static getRequiredFields(
    templateType: TemplateType,
    caseData: CaseData,
    employee: Employee,
    author: UserDetails,
  ): DocumentJSONContent | null {
    return getRequiredFieldsByTemplateType(templateType, caseData, employee, author);
  }
}
