import React from 'react';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import FormField from '../../../forms/fields/FormField.component';
import { ErrorMessage } from 'formik';
import { CaseData } from '../../WorkflowContainer/workflow-utils';

interface PrepareAgreedDemotionFormProps {
  data: CaseData;
}

const PrepareAgreedDemotionForm: React.FC<PrepareAgreedDemotionFormProps> = (props: PrepareAgreedDemotionFormProps) => {
  return (
    <Form>
      <Row className="my-2">
        <Col md={4}>
          <FormGroup>
            <Label for="demotionNewJobTitle" className="text-default text-capitalize">
              New Job Title*
            </Label>
            <FormField type={'text'} placeholder="New Job title" name="demotionNewJobTitle" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'demotionNewJobTitle'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="demotionNewJobLevel" className="text-default text-capitalize">
              New Job Level / Grade*
            </Label>
            <FormField type={'text'} placeholder="New Job level / grade" name="demotionNewJobLevel" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'demotionNewJobLevel'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="demotionNewDepartment" className="text-default text-capitalize">
              Department*
            </Label>
            <FormField type={'text'} placeholder="Department" name="demotionNewDepartment" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'demotionNewDepartment'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row className="my-2">
        <Col md={4}>
          <FormGroup>
            <Label for="demotionNewRemunerationPackage" className="text-default text-capitalize">
              New Remuneration Package per annum*
            </Label>
            <FormField type={'text'} placeholder="New Remuneration Package" name="demotionNewRemunerationPackage" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'demotionNewRemunerationPackage'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="demotionNewTerms" className="text-default text-capitalize">
              Any other changed tc's related to the new level / grade*
            </Label>
            <FormField type={'text'} placeholder="New Terms and conditions" name="demotionNewTerms" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'demotionNewTerms'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="demotionDate" className="text-default text-capitalize">
              Effective date of demotion*
            </Label>
            <FormField type={'date'} placeholder="Effective Date of demotion" name="demotionDate" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'demotionDate'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default PrepareAgreedDemotionForm;
