import React, { ReactElement } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import CCMADetailsAForm from './CCMADetailsAForm';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import { CCMADetailsASchema } from '../../../forms/ValidationSchema/GeneralCaseSchema';

const CCMADetailsA: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const fields = {
    disputeReferredToCCMA: 'boolean',
    requestedCondonation: 'boolean',
    grantedCondonation: 'boolean',
    settledAtConciliation: 'boolean',
    lodgedObjection: 'boolean',
    disputeReferralFormDocuments: 'array',
    certificateOfNoOutcomeDocuments: 'array',
    settlementReached: 'string',
    furtherDetails: 'string',
    settlementValue:'string',
    employerRepresentativesAtConciliation: 'array',
    conciliationDate: 'string',
    natureOfDispute: 'string',
  };

  const initialValues: FormikValues = getFormDataFromCaseData(fields, props.data.caseData);

  const submitValues = (values: FormikValues): void => {
    const variables = { ...values };
    variables.disputeReferralFormDocuments = JSON.stringify(variables.disputeReferralFormDocuments);
    variables.certificateOfNoOutcomeDocuments = JSON.stringify(variables.certificateOfNoOutcomeDocuments);
    variables.employerRepresentativesAtConciliation = JSON.stringify(variables.employerRepresentativesAtConciliation);
    props.data.flowableFunctions.onNext(filterFormData(variables, fields));
  };
  return (
    <div className="content">
      <h4 className="text-h4 text-blue font-weight-500">CCMA/Bargaining Council - Dispute Referral</h4>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={CCMADetailsASchema}
        onSubmit={submitValues}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <CCMADetailsAForm data={props.data} />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default CCMADetailsA;
