import React, { useState } from 'react';
import GuidanceModal from '../../../../../../../components/guidanceModals/GuidanceModal.component';

export const GuidanceModalSummaryDismissal: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <GuidanceModal
      isOpen={modalIsOpen}
      setModal={setModalIsOpen}
      buttonTitle={'Summary Dismissal'}
      title="Guidance: Summary Dismissal"
    >
      <>
        <p className="text-muted font-weight-bold text-uppercase">
          In some circumstances it may be appropriate to end the employment relationship due to misconduct on the side
          of the employee. It is a sanction of last resort and only after a proper disciplinary hearing has been held.
          The following factors must be considered holistically:
        </p>
        <br />
        <ul className="px-3">
          <li className="text-muted">
            • The severity of the misconduct that the employee was found guilty of i.e. was it “gross” misconduct;
          </li>
          <li className="text-muted">
            • The employee is a habitual offender and chances of future rehabilitation is slim;
          </li>
          <li className="text-muted">• The nature of the offence, for example, dishonesty related offences;</li>
          <li className="text-muted">
            • The impact on the employer-employee trust relationship and whether such has been irreparably breached;
          </li>
          <li className="text-muted">
            • Whether or not continued employment has been made intolerable or alternative means exist;
          </li>
          <li className="text-muted">• The guidelines on sanction as contained in the employer’s Disciplinary Code;</li>
          <li className="text-muted">• Past precedents for similar transgressions;</li>
          <li className="text-muted">
            • Monetary losses suffered by the employer because of the misconduct, real or potential;
          </li>
          <li className="text-muted">
            • Other risks arising such as health and safety related, security breaches and the like;
          </li>
          <li className="text-muted">• Reputational damage suffered by the employer, real or potential, </li>
          <li className="text-muted">
            • Remorse shown on the side of the employee and whether or not he/she takes accountability for their
            actions;
          </li>
        </ul>
        <br />
        <p className="text-muted">
          Ultimately dismissal is not a sign of outrage or meant to be punitive in nature. It is a common-sense approach
          by the employer to avoid future repeat behaviour and/or mitigate operational, monetary or reputational risks.
        </p>
      </>
    </GuidanceModal>
  );
};
