import * as Yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '../fields/FormField.component';

export const appeal_hearing_appointEmployerRepresentativeSchema = Yup.object().shape({
  appeal_hearing_suspendPendingHearing: Yup.boolean().required(),
  appeal_hearing_employerRepresentativeType: Yup.string().required(),
  appeal_hearing_employerRepresentativeFirstName: Yup.string().required('Required'),
  appeal_hearing_employerRepresentativeLastName: Yup.string().required('Required'),
  appeal_hearing_employerRepresentativeEmailAddress: Yup.string()
    .email('Email is not valid')
    .required('Required'),
  // appeal_hearing_employerRepresentativeId: Yup.string().when('appeal_hearing_employerRepresentativeType', {
  //   is: 'internal',
  //   then: Yup.string().required('Required'),
  // }),
});

export const appeal_hearing_appointChairpersonSchema = Yup.object().shape({
  appeal_hearing_chairpersonType: Yup.string().required(),
  appeal_hearing_chairpersonFirstName: Yup.string().required('Required'),
  appeal_hearing_chairpersonLastName: Yup.string().required('Required'),
  appeal_hearing_chairpersonEmailAddress: Yup.string()
    .email('Email is not valid')
    .required('Required'),
  // appeal_hearing_chairpersonId: Yup.string().when('appeal_hearing_chairpersonType', {
  //   is: 'internal',
  //   then: Yup.string().required('Required'),
  // }),
});

export const appeal_hearing_selectWitnessSchema = Yup.object().shape({
  appeal_hearing_hasWitness: Yup.boolean(),
  appeal_hearing_internalWitnesses: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        emailAddress: Yup.string(),
        employeeId: Yup.string(),
      }),
    )
    .nullable(),
  appeal_hearing_externalWitnesses: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        emailAddress: Yup.string().email('Email is not valid'),
      }),
    )
    .nullable()
    .when(['appeal_hearing_hasWitness', 'appeal_hearing_internalWitnesses'], {
      is: (appeal_hearing_hasWitness, appeal_hearing_internalWitnesses) =>
        appeal_hearing_hasWitness && (!appeal_hearing_internalWitnesses || !appeal_hearing_internalWitnesses.length),
      then: Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string().required("Please enter witness's first name"),
          lastName: Yup.string().required("Please enter witness's last name"),
          emailAddress: Yup.string()
            .email('Email is not valid')
            .required('Please enter email address'),
        }),
      ),
    }),
});

export const appeal_hearing_hearingLogisticsSchema = Yup.object().shape({
  appeal_hearing_hearingDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  appeal_hearing_hearingTime: Yup.string()
    .test('time', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid())
    .required('Please select time'),
  appeal_hearing_hearingLocation: Yup.string().required('Please enter location'),
  appeal_hearing_employerRepresentativeFirstName: Yup.string().required('Please enter representatives first name'),
  appeal_hearing_employerRepresentativeLastName: Yup.string().required('Please enter representatives last name'),
  appeal_hearing_employerRepresentativeEmailAddress: Yup.string()
    .email('Email is not valid')
    .required('Please enter email'),
  appeal_hearing_chairpersonFirstName: Yup.string().required('Please enter chairpersons first name'),
  appeal_hearing_chairpersonLastName: Yup.string().required('Please enter chairpersons last name'),
  appeal_hearing_chairpersonEmailAddress: Yup.string()
    .email('Email is not valid')
    .required('Please enter email'),
  appeal_hearing_hearingWitnesses: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().required("Please enter witness's first name"),
        lastName: Yup.string().required("Please enter witness's last name"),
        emailAddress: Yup.string()
          .email('Email is not valid')
          .required('Please enter email address'),
      }),
    )
    .nullable(),
});

export const appeal_hearing_misconductCompileNoticeOfHearingSchema = Yup.object().shape({
  incidents: Yup.array().of(
    Yup.object().shape({
      summaryOfFacts: Yup.string().required('Please enter Summary of Allegations'),
      date: Yup.string().test('date', 'Date is not valid', value => moment(value, DATE_FORMAT).isValid() || !value),
      time: Yup.string().test('time', 'Time is not valid', value => moment(value, TIME_FORMAT).isValid() || !value),
      transgression: Yup.string().required('Required'),
    }),
  ),
});

export const appeal_hearing_poorPerformanceCompileNoticeOfHearingSchema = Yup.object().shape({
  performanceShortfalls: Yup.array().of(
    Yup.object().shape({
      shortfallDescription: Yup.string().required('Please enter Summary of Allegations'),
      date: Yup.string().test('date', 'Date is not valid', value => moment(value, DATE_FORMAT).isValid() || !value),
    }),
  ),
});

export const appeal_hearing_recordEmployeeRightsAndResponsesSchema = Yup.object().shape({
  appeal_hearing_requestedTranslator: Yup.string().required('Required'),
  appeal_hearing_language: Yup.string().when('requestedTranslator', {
    is: 'YES',
    then: Yup.string().required('Please select a language'),
  }),
  appeal_hearing_requestedExternalRepresentation: Yup.string()
    .required('Required')
    .nullable(),
  appeal_hearing_reasonForRequestingExternalRepresentation: Yup.string().when('requestedExternalRepresentation', {
    is: 'YES',
    then: Yup.string().required('Required'),
  }),
  appeal_hearing_eERepresentativePresent: Yup.string().required('Required'),
  appeal_hearing_reasonForEeRepresentativePresence: Yup.string().when('appeal_hearing_eERepresentativePresent', {
    is: 'YES',
    then: Yup.string().required('Required'),
  }),
  appeal_hearing_disabledAndRequiresAccommodation: Yup.string().required('Required'),
  appeal_hearing_reasonForRequiringAccommodation: Yup.string().when('disabledAndRequiresAccommodation', {
    is: 'YES',
    then: Yup.string().required('Required'),
  }),
});

export const appeal_hearing_externalRepresentationOutcomeSchema = Yup.object().shape({
  appeal_hearing_externalRepresentationApplicationRulingDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .required('Please upload a file')
    .nullable()
    .when('isUpload', {
      is: true,
      then: Yup.array().required('Please upload a file'),
    }),
});

export const appeal_hearing_minutesAndRecordingSchema = Yup.object().shape({
  appeal_hearing_hearingDate: Yup.string()
    .required('Please select date')
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid()),
  appeal_hearing_hearingTime: Yup.string()
    .required('Please select time')
    .test('time', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid()),
  appeal_hearing_hearingLocation: Yup.string().required('Please enter location'),
  appeal_hearing_reasonForDeclining: Yup.string(),
  appeal_hearing_minutesOrRecordingsFiles: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .when('appeal_hearing_documentaryEvidenceFiles', {
      is: null,
      then: Yup.array().required('Please upload a file '),
    })
    .nullable(),
  appeal_hearing_documentaryEvidenceFiles: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .nullable(),
});

export const appeal_hearing_misconductChairpersonsRulingSchema = Yup.object().shape({
  isUpload: Yup.boolean().required(),
  appeal_hearing_chairpersonsRulingDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    ).typeError('*Please submit here'),
  appeal_hearing_backgroundLeadingToHearing: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  appeal_hearing_summaryOfEmployerRepresentativesArguments: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  appeal_hearing_conclusionsReached: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  appeal_hearing_summaryOfLegalPrinciples: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  appeal_hearing_newEvidenceConsidered: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  appeal_hearing_summaryOfAppellantsArguments: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  incidents: Yup.array()
    .of(
      Yup.object().shape({
        appeal_hearing_findingsOnGuiltConfirmed: Yup.boolean().when('isUpload', {
          is: false,
          then: Yup.boolean().required('Required'),
        }),
        reasonForOverturningGuiltyFinding: Yup.string().when(['isUpload', 'appeal_hearing_findingsOnGuiltConfirmed'], {
          is: false,
          then: Yup.boolean().required('Required'),
        }),
        sanctionConfirmed: Yup.boolean().when('isUpload', { is: false, then: Yup.boolean().required('Required') }),
        reasonForOverturningSanction: Yup.string().when(['isUpload', 'sanctionConfirmed'], {
          is: false,
          then: Yup.boolean().required('Required'),
        }),
      }),
    )
    .nullable(),
});

export const appeal_hearing_pp_chairpersonsRulingSchema = Yup.object().shape({
  isUpload: Yup.boolean().required(),
  appeal_hearing_chairpersonsRulingDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .nullable()
    .when('isUpload', {
      is: true,
      then: Yup.array().required('Please upload a file'),
    }),
  appeal_hearing_backgroundLeadingToHearing: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  appeal_hearing_summaryOfEmployerRepresentativesArguments: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  appeal_hearing_conclusionsReached: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  appeal_hearing_summaryOfLegalPrinciples: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  appeal_hearing_newEvidenceConsidered: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  appeal_hearing_summaryOfAppellantsArguments: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  performanceShortfalls: Yup.array()
    .of(
      Yup.object().shape({
        appeal_hearing_findingsOnGuiltConfirmed: Yup.boolean().when('isUpload', {
          is: false,
          then: Yup.boolean().required('Required'),
        }),
        appeal_hearing_reasonForOverturningGuiltyFinding: Yup.string().when(
          ['isUpload', 'appeal_hearing_findingsOnGuiltConfirmed'],
          {
            is: false,
            then: Yup.boolean().required('Required'),
          },
        ),
        appeal_hearing_sanctionConfirmed: Yup.boolean().when('isUpload', {
          is: false,
          then: Yup.boolean().required('Required'),
        }),
        appeal_hearing_reasonForOverturningSanction: Yup.string().when(['isUpload', 'sanctionConfirmed'], {
          is: false,
          then: Yup.boolean().required('Required'),
        }),
      }),
    )
    .nullable(),
});

export const appeal_hearing_confirmOverallSanctionSchema = Yup.object().shape({
  incidents: Yup.array().of(
    Yup.object().shape({
      appeal_hearing_chairpersonsFinding: Yup.string().required('Required'),
      appeal_hearing_chairpersonsSanction: Yup.string().required('Required'),
      appeal_hearing_chairpersonConfirmedSanction: Yup.string().required('Required'),
    }),
  ),
  revisedOverallSanction: Yup.string().required('Required'),
});

export const appeal_hearing_pp_confirmOverallSanction = Yup.object().shape({
  incidents: Yup.array().of(
    Yup.object().shape({
      appeal_hearing_chairpersonsFinding: Yup.string()
        .required('Required')
        .nullable(),
      appeal_hearing_chairpersonsSanction: Yup.string()
        .required('Required')
        .nullable(),
      appeal_hearing_chairpersonConfirmedSanction: Yup.string()
        .required('Required')
        .nullable(),
    }),
  ),
  revisedOverallSanction: Yup.string().required('Required'),
});

export const appeal_hearing_misconduct_suspensionWithoutPaySchema = Yup.object().shape({
  appeal_hearing_hearingDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  dismissalDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  suspensionStartDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  suspensionEndDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  // periodOfUnpaidSuspension: Yup.number().required('Please enter a number'),
  // returnToWorkDate: Yup.string()
  //   .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
  //   .required('Please select date'),
  incidents: Yup.array()
    .of(
      Yup.object().shape({
        transgression: Yup.string().required('Please transgression'),
        correctiveAction: Yup.string().required('Please enter corrective action'),
      }),
    )
    .required()
    .nullable(),
});

export const appeal_hearing_pp_suspensionWithoutPaySchema = Yup.object().shape({
  initial_hearing_hearingDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  periodOfUnpaidSuspension: Yup.number().required('Please enter a number'),
  returnToWorkDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  performanceShortfalls: Yup.array()
    .of(
      Yup.object().shape({
        shortfallDescription: Yup.string().required('Required'),
        correctiveAction: Yup.string().required('Please enter corrective action'),
      }),
    )
    .required()
    .nullable(),
});

export const appeal_hearing_misconduct_summaryDismissalTableSchema = Yup.object().shape({
  incidents: Yup.array()
    .nullable()
    .required('Incidents are required'),
  appeal_hearing_hearingDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  dismissalDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  lastRemunerationDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  contactPersonInCaseOfCCMA: Yup.string().required('Please enter contact name'),
  physicalAddress: Yup.string().required('Please enter address'),
  emailAddress: Yup.string()
    .email('Email is not valid')
    .required('Please enter representative email address'),
});

export const appeal_hearing_pp_summaryDismissalTableSchema = Yup.object().shape({
  performanceShortfalls: Yup.array()
    .nullable()
    .required('Performance Shortfalls are required'),
  appeal_hearing_hearingDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  dismissalDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  lastRemunerationDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  contactPersonInCaseOfCCMA: Yup.string().required('Please enter contact name'),
  physicalAddress: Yup.string().required('Please enter address'),
  emailAddress: Yup.string()
    .email('Email is not valid')
    .required('Please enter representative email address'),
});
