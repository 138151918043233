import React from 'react';
import { Document, Page, PDFViewer, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import Paragraph from '../../../PDF/Paragraph.component';
import LetterHead from '../../../PDF/Letterhead.component';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const PoorPerformanceAppealFindingsFormPDF: React.FC<DocumentProps> = props => {
  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page style={styles.body} size="A4" wrap>
          {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
          <Paragraph>
            <Text style={{ ...styles.subject }}>FINDINGS ON APPEAL – INCAPACITY POOR PERFORMANCE</Text>
          </Paragraph>
          <Paragraph>
            <Text style={{ ...styles.subject }}>COMPLETED BY APPEAL CHAIRPERSON</Text>
          </Paragraph>
          <Paragraph>
            <Text>1. State briefly the background of what led to the appeal process:</Text>
          </Paragraph>
          <Paragraph>{/*<Text>{props.content.appealProcessBackground.value}</Text>*/}</Paragraph>
          <Paragraph>
            <Text>2. Summarise arguments of the appellant: </Text>
          </Paragraph>
          <Paragraph>{/*<Text>{props.content.appellantArgumentSummary.value}</Text>*/}</Paragraph>
          <Paragraph>
            <Text>3. Summarise arguments of the employer representative:</Text>
          </Paragraph>
          <Paragraph>{/*<Text>{props.content.employerRepArgumentSummary.value}</Text>*/}</Paragraph>
          <Paragraph>
            <Text>4. Summarise any NEW evidence considered, if any:</Text>
          </Paragraph>
          <Paragraph>{/*<Text>{props.content.newEvidenceSummary.value}</Text>*/}</Paragraph>
          <Paragraph>
            <Text>5. Legal principles applied:</Text>
          </Paragraph>
          <Paragraph>{/*<Text>{props.content.legalPrinciplesApplied.value}</Text>*/}</Paragraph>
          <Paragraph>
            <Text>6. Conclusions reached:</Text>
          </Paragraph>
          <Paragraph>{/*<Text>{props.content.conclusionsReached.value}</Text>*/}</Paragraph>
          <Paragraph>
            <Text>7. Appeal outcomes in relation to original incapacity findings. Tick as appropriate:</Text>
          </Paragraph>
          <Text style={{ ...styles.subject }}>Findings on guilt:</Text>
          <TableRow>
            <TableCell width={'16%'}>Confirmed</TableCell>
            <TableCell checkbox width={'8%'} />
            <TableCell width={'16%'}>Overturned</TableCell>
            <TableCell checkbox width={'8%'} />
          </TableRow>
          <Paragraph>
            <Text>If overturned, new finding(s) with reasons:</Text>
          </Paragraph>
          <Paragraph>{/*<Text>{props.content.overturnedGuiltFindings.value}</Text>*/}</Paragraph>
          <Text style={{ ...styles.subject }}>Findings on sanction:</Text>
          <TableRow>
            <TableCell width={'16%'}>Confirmed</TableCell>
            <TableCell checkbox width={'8%'} />
            <TableCell width={'16%'}>Overturned</TableCell>
            <TableCell checkbox width={'8%'} />
          </TableRow>
          <Paragraph>
            <Text>If overturned, new sanction, with reasons:</Text>
          </Paragraph>
          <Paragraph>{/*<Text>{props.content.overturnedSanctionFindings.value}</Text>*/}</Paragraph>
        </Page>
      </Document>
    </PDFViewer>
  );
};
