import { FlowableHistoricTask } from '../../../utils/flowable/flowable-types';
import { findFlowableVariablesFromTask } from '../case-history-utils';
import { FormConfig } from '../../../configs/form-configs/form-config-types';
import { misconductFormConfig } from '../../../configs/form-configs/misconduct-forms';
import { poorPerformanceFormConfig } from '../../../configs/form-configs/poor-performance-forms';
import { CaseType } from '../../../API';
import { componentsAndFormsBySanction } from '../../MisconductScreens/HearingScreens/InitialHearingScreens/ExecuteOutcome/execute-outcome-form-configs';

export const getFieldDefinitionsByFormKey = (task: FlowableHistoricTask): FormConfig => {
  const { caseType } = findFlowableVariablesFromTask(task, ['caseType']);
  if (!caseType?.value || typeof caseType.value !== 'string') {
    throw new Error('missing caseType on task');
  } else if (!task.formKey) {
    throw new Error('missing formKey on task');
  }

  if (task.formKey === 'execute-outcome') {
    const { overallSanction } = findFlowableVariablesFromTask(task, ['overallSanction']);
    if (overallSanction && typeof overallSanction.value === 'string') {
      const config = componentsAndFormsBySanction[caseType.value][overallSanction.value];
      return { fields: config.formConfig.caseHistoryFields };
    } else {
      throw new Error('Missing overallSanctionVar');
    }
  } else if (task.formKey === 'execute-outcome-appeal') {
    const { revisedOverallSanction } = findFlowableVariablesFromTask(task, ['revisedOverallSanction']);
    if (revisedOverallSanction && typeof revisedOverallSanction.value === 'string') {
      const config = componentsAndFormsBySanction[caseType.value][revisedOverallSanction.value];
      return { fields: config.formConfig.caseHistoryFields };
    } else {
      throw new Error('Missing revisedOverallSanction');
    }
  }

  const taskMapByCaseType: Record<string, Record<string, FormConfig>> = {
    [CaseType.MISCONDUCT]: misconductFormConfig,
    [CaseType.POOR_PERFORMANCE]: poorPerformanceFormConfig,
  };
  const taskMap = taskMapByCaseType[caseType.value];

  return task.formKey && taskMap[task.formKey]
    ? taskMap[task.formKey]
    : {
        fields: {},
        hasDocument: false,
      };
};
