import React from 'react';

export const RoleOfChairperson: React.FC = () => {
  return (
    <div>
      <h3 className="font-weight-bold">GUIDANCE: ROLE OF THE CHAIRPERSON</h3>
      <p className="font-weight-bold">What is my role as chairperson of a disciplinary hearing?</p>
      <p>
        As chairperson you will be expected to guide all parties during the disciplinary hearing process to obtain a
        fair outcome.
      </p>
      <p>
        Firstly, you will decide on whether or not the alleged offender is guilty as charged with reference to the
        charges as contained in the Notice of Disciplinary Hearing and the evidence brought by both the employer and
        employee party in the hearing. You need to decide on a balance of probability whether the employee is guilty as
        charged.
      </p>
      <br />
      <p>
        Secondly, should you find the employee guilty or should the employee plead guilty, you will decide on an
        appropriate sanction - again with due regard for submissions made by both the employer and employee party.
      </p>
      <br />
      <p>
        The independence of a chairperson of a hearing is a critical precondition to the result of the hearing being
        procedurally and substantively fair. The law recognises that you may well be part of the management team and can
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        therefore never strictly be absolutely neutral. You may, for example, know the employee's personal traits and
        past history. This does not in itself disqualify you from chairing the hearing. In essence, the requirement is
        that the chairperson must approach the case with an open and objective mind. As chairperson, you must not have
        an emotional or vested interest in the case. You may seek outside assistance on how to arrive at a balanced
        decision.
      </p>
      <br />
      <p>
        Your behaviour and the manner in which you conduct the proceedings of the hearing will ultimately determine the
        fairness or otherwise of the hearing.
      </p>
    </div>
  );
};

export const RoleOfEmployerRep: React.FC = () => {
  return (
    <div>
      <h3 className="font-weight-bold">GUIDANCE: THE ROLE OF THE EMPLOYER REPRESENTATIVE</h3>
      <p className="font-weight-bold">What is my role as employer representative?</p>
      <p>
        As employer representative you will be expected to argue the employer’s case at the disciplinary hearing. You
        need to prepare thoroughly for the hearing, making sure you understand the evidence available and have secured
        other relevant evidence such as documents or video recordings (if applicable). You may well have acted as
        investigator of the case previously.
      </p>
      <br />
      <p>
        You must liaise with the employer witnesses beforehand, so you understand their testimonies, but without
        influencing their versions.
      </p>
      <br />
      <p>
        The onus of proof is on the employer party and you must convince the chairperson that the employee is guilty as
        charged. The employee does not need to prove they are innocent.
      </p>
      <br />
      <p>
        If found guilty or the employee pleads guilty, you will be expected to argue what an appropriate sanction is
        with due regard for the employer’s Disciplinary Code, past precedents in the workplace and relevant
        circumstances.
      </p>
      <br />
      <p className="font-weight-bold">How should I prepare for the hearing?</p>
      <p>
        It is vital that you prepare thoroughly. This includes preparing documentation and evidence, as well as any
        witnesses that will be called to testify at the hearing.
      </p>
      <br />
      <p>
        Potential witnesses should first be questioned separately. Test the credibility of the witnesses. Contradictions
        in evidence between witnesses should be probed and checked be fore the hearing. Attempting to get witnesses to
        alter their evidence can often be counter - productive as the truth may emerge during the hearing discrediting
        the witness and the employer’s case. Going through the evidence with all the witnesses together at a later time
        allows them to listen to the entire story and hear where their particular evidence slots in. Prepare a framework
        of possible questions for each witness.
      </p>
      <br />
      <p className="font-weight-bold">Make sure that you’re prepared for every relevant issue which must be proven:</p>
      <ul>
        <li className="list-style">The underlying issues and facts;</li>
        <li className="list-style">
          The elements of the misconduct charge that needs to be proven (for example, intent in cases of dishonesty);
        </li>
        <li className="list-style">Possible defenses and excuses and how to refute those.</li>
      </ul>
      <br />
      <p>
        Prepare an opening statement summarising what you are going to attempt to prove to the chairperson during the
        hearing.
      </p>
      <br />
      <p>
        Prepare a paginated bundle of all the documentary evidence (if any) for yourself, and make copies for the
        chairperson, the alleged offending employee and one to use for witness es called into the hearing.
      </p>
      <br />
      <p>
        Have a recording device ready on the day of the hearing and ask the chairperson’s permission upfront to record
        the proceedings.
      </p>
    </div>
  );
};

export const EmployeeRights: React.FC = () => {
  return (
    <div>
      <h3 className="font-weight-bold">GUIDANCE: EMPLOYEE RIGHTS</h3>
      <p className="font-weight-bold">
        What rights does the alleged offending employee have during the hearing process?
      </p>
      <p>The alleged offender has the right to:</p>
      <br />
      <ul>
        <li className="list-style">
          Receive beforehand a formal Notice of a Disciplinary Hearing, setting out the charge(s) against him/her
        </li>
        <li className="list-style">A reasonable timeframe to prepare his/her response;</li>
        <li className="list-style">
          State his/her case in response to the allegations made by the employer (known as the audi alteram partem
          rule).
        </li>
        <li className="list-style">
          Be represented by a fellow employee or shop steward of a recognised union in the workplace, if a member of
          such union.
        </li>
        <li className="list-style">
          A translator in his/her language of choice, even if in the normal course of business, he/she communicates in
          the business language (typically English).
        </li>
        <li className="list-style">Call witnesses and present his/her evidence;</li>
        <li className="list-style">
          Cross examination employer witnesses either himself/herself or through his/her representative.
        </li>
        <li className="list-style">Be informed of the hearing outcomes and reasons for those outcomes;</li>
        <li className="list-style">
          Be informed of the reason for a dismissal and his/her right to refer a dispute to the CCMA, if dissatisfied
          with the out come.
        </li>
      </ul>
      <br />
      <p className="font-weight-bold">Who may represent an employee in a disciplinary hearing?</p>
      <p>
        Employees accused of misconduct have the right to be represented by a fellow employee of their choice. If a
        trade union is recognised in the workplace and shop stewards have been elected, the latter have the right to
        represent their members. It remains the employee’s prerogative to choose an internal representative, or to
        represent himself/herself.
      </p>
      <br />
      <p>
        Sometimes employees wish for external legal representatives or unrecognised trade unions to assist and represent
        them.
      </p>
      <br />
      <p>
        You need to check your own Disciplinary Code, if any, and abide by the provisions contained therein. If the Code
        is silent on the issue or even where it prohibits external representation or if you have no Code, it is best to
        all ow the alleged offender to motivate the need for an external representative to the chairperson of the
        hearing. The chairperson must make a decision on the matter with due regard for procedural fairness, meaning
        that he/she must decide whether and to what e xtent the employee will be prejudiced, if such external
        representation is not allowed.
      </p>
      <br />
      <p className="font-weight-bold">Factors to be considered holistically by the chairperson include:</p>
      <ul>
        <li className="list-style">
          The seriousness of the alleged offence and possible consequences if found guilty;
        </li>
        <li className="list-style">
          The profile of the employer representative and the need to “level the playing field”;
        </li>
        <li className="list-style">
          Whether the matter is a simple factual matter or whether technical legal issues are at stake;
        </li>
        <li className="list-style">
          The need to keep an internal process simple and expedient and avoid unnecessary precedents from being created;
        </li>
        <li className="list-style">
          Whether and how either the employer or employee could be prejudiced by the ruling.
        </li>
      </ul>
      <br />
      <p>
        The mere fact that dismissal may follow if found guilty, is not in itself sufficient ground to justify external
        representation. Written or oral submis sions by both parties should be invited prior to the chairperson coming
        to a ruling in this regard. LabourTeq provides for the recording of these submissions as well as the
        chairperson’s decision.
      </p>
      <br />
      <p>
        External representation should generally speaking not be al lowed into the internal disciplinary process. It
        should be the exception rather than the norm, unless your Disciplinary Code expressly allows for it.
      </p>
      <br />
      <p className="font-weight-bold">Does the employee have a right of appeal?</p>
      <p>
        The employee has a right to appeal,<span className="font-weight-bold">only if</span>the employer’s own
        Disciplinary Code provides for an appeal process. In principle the employee can then appeal against any
        disciplinary sanction, including against dismissal. If no internal appeal process is available, he/she has the
        right to directly refer the matter to a dispute resolution body (i.e. the CCMA or Bargaining Council, as
        applicable) as an unfair labour practice dispute or unfair dismissal, as applicable in the circumstances.
      </p>
    </div>
  );
};

export const DisciplinaryHearingSteps: React.FC = () => {
  return (
    <div>
      <h3 className="font-weight-bold">GUIDANCE: DISCIPLINARY HEARING STEPS</h3>
      <p className="font-weight-bold">What is the purpose of a disciplinary hearing?</p>
      <p>
        The hearing gives an alleged offending employee a fair and objective opportunity to present his/her case in
        response to allegations of serious or habitual misconduct allegations arising in the workplace. This is in line
        with legislative requirements. Proce dural and substantive fairness principles must be complied with to ensure a
        justifiable outcome.
      </p>
      <br />
      <p className="font-weight-bold">What steps should be followed by the chairperson during the hearing ?</p>
      <br />
      <p className="font-weight-bold">OPEN THE HEARING, CONFIRMING EMPLOYEE RIGHTS AND PROCESS:</p>
      <br />
      <p>
        1. Confirm the names of the parties present and the capacity in which they are attending. Confirm whether a
        scribe is used and/or a recording is made.
      </p>
      <p>
        2. Explain the purpose of the hearing namely, first, to establish the guilt or innocence of the employee on the
        charges and, second, if guilty , to decide what an appropriate sanction is.
      </p>
      <p>
        3. Confirm the employee received the Notice of hearing and that he/she had sufficient time to prepare. If he/she
        alleges not, debate the merits of a postponement and make a ruling.
      </p>
      <p>
        4. Confirm the language in which the hearing will be held and if a translator is required. If the employee did
        not request a translator prior to the hearing ( as per the Notice of Hearing) and requests one in the hearing,
        consider postponing the hearing to arrange one, based on the merits of the situation.
      </p>
      <p>
        5. Confirm whether the employee is represented by a fellow employee or shop steward or whether they will
        represent themselves . If they request external representation, allow both the employer and employee parties to
        make submissions on the merits and make a ruling.
      </p>
      <p>
        6. Confirm the process to be followed, the right to present evidence, to testify, to call witnesses, and to
        cross - examine the other parties’ witnesses.
      </p>
      <p>
        7. Read /have the charge (s) read as per the Notice of the Hearing and ask the employee to plead – Guilty or Not
        guilt.
      </p>
      <br />
      <p className="font-weight-bold">IF THE EMPLOYEE PLEA DS GUILTY :</p>
      <p>
        8a. Ask the employee to explain what happened and why they think they are guilty. Ask the employer
        representative to comment. Consider this in relation to the charge(s).
      </p>
      <p>
        8b. If satisfied with the Guilty plea in relation to charge, proceed with the sanction phase, as below [ 16
        onwards ] . If not, rather enter a plea of not guilty and proceed with the evidence phase, as below [ 9 onwards
        ] . The employer representative may also rather want to proceed leading evidence on a main charge, even though
        the employee pleads guilty on an alter native charge.
      </p>
      <br />
      <p className="font-weight-bold">IF THE EMPLOYEE PLEADS NOT GUILTY , PROCEED WITH EVIDENCE PHASE :</p>
      <p>9. Allow both parties to make Opening Statements, if they want to.</p>
      <p>10. Ask the employer representative to proceed with his/her evidence.</p>
      <p>
        10a. Allow for employer witnesses to be called, one by one . First the employer representative will question
        his/her own witness or will testify himself/herself . Then allow for cross - examination by the employee or
        his/her representative. The employer representative may redress their witness thereafter, should they want to.
      </p>
      <p>
        10b. Allow for documentary evidence or other evidence, such as video recordings, to be submitted into record, if
        any. As chairperson, ask clarifying questions if required.
      </p>
      <p>
        11. After the employer representative has completed leading evidence, ask the employee or his/her representative
        to lead evidence. The employee can first testify on his/her own behalf and state their case in response to the
        charges.
      </p>
      <p>11a. Repeat the evidence leading process as above, as applicable ( 10a and 10b above ).</p>
      <p>12. Allow both parties to make Closing Arguments, if they want to.</p>
      <p>
        13. Assess the evidence to come to a conclusion of whether the employee is guilty or not. The hearing may be
        adjourned for this purpose. The period of adjournment, if any, is within the discretion of the c hairperson OR
        as prescribed in the employer’s Disciplinary Code.
      </p>
      <p>
        14. Decide on the employee’s guilt or otherwise solely on the facts of the case before you on a balance of
        probability.
      </p>
      <br />
      <p className="font-weight-bold">IF THE EMPOYEE IS FOUND NOT GUILTY: </p>
      <p>
        15. Communicate the outcome and close the case. Prepare a written ruling summarising the outcomes of the
        hearing.
      </p>
      <br />
      <p className="font-weight-bold">IF THE EMPLOYEE IS FOUND GUILTY, PROCEED WITH SANCTION PHASE: </p>
      <p>16. Communicate ruling on guilt with reasons and ask for sanction arguments to be led. </p>
      <p>16a. Allow the employer representative to submit aggravating factors and make a recommendation on sanction.</p>
      <p>
        16b. Allow the employee or his/her representative to comment and/or submit mitigating factors. He/she may make a
        recommendation on sanction.
      </p>
      <p>16c. Consider the employee’s past disciplinary record, as relevant.</p>
      <p>16d. Consider the employer’s Disciplinary Code prescripts on sanction and past precedents, if known.</p>
      <p>
        17. Apply your mind and come to a decision on an appropriate sanction. The hearing may be adjourned for this
        purpose. The period of adjournment, if any, is within the discretion of the chairperson OR as prescribed in the
        employer’s Disciplinary Code.
      </p>
      <p>18. Communicate the ruling on sanction and close the case.</p>
      <p>19. Only if the employer’s Disciplinary Code allows for an appeal, inform the employee accordingly.</p>
      <p>
        20. In case of dismissal, inform the employee that he/she has the right to refer the matter to the Commissioner
        for Conciliation, Mediation and Arbitration (CCMA) or applicable bargaining council dispute resolution body,
        should he/she be dissatisfied with the outcome of the hearing.
      </p>
      <p>
        21. Prepare a written ruling summarising the outcomes of the hearing. The employer thereafter should issue the
        relevant documentation, for example a dismissal or final written warning letter, to give effect to your ruling.
      </p>
      <br />
      <p>Ensure throughout this process that you remain objective and impartial.</p>
    </div>
  );
};

export const Evidence: React.FC = () => {
  return (
    <div>
      <h3 className="font-weight-bold">GUIDANCE: EVIDENCE</h3>
      <p className="font-weight-bold">What is direct evidence? </p>
      <p>
        Direct evidence is clear and reliable on the face of it. For example, whilst Mary is standing in the room having
        a discussion with John and Pete, Pete gets upset and hits John. She sees the whole incident. Her oral testimony
        in the hearing constitutes direct evidence, the best type of evidence to have.
      </p>
      <br />
      <p className="font-weight-bold">What is circumstantial evidence? </p>
      <p>
        Sometimes direct evidence is not available and an inference can be drawn that a transgression did take place.
        This is called circumstantial evidence. For example, Mary wasn’t in the same room as John and Pete, but she
        heard a disturbance. When she ran around the corner, she saw that John’s nose was bleeding and Pete was shaking
        his hand as if in pain. Although she did not see Pete hit John, the surrounding circumstances lead her to make
        certain deductions, namely that Pete hit John. Mere suspicions are not sufficient.
      </p>
      <br />
      <p className="font-weight-bold">What is the difference between a confession and an admission?</p>
      <p>
        A <span className="font-weight-bold">confession</span> is when the employee admits guilt in full. Hopefully the
        employee does not later change his/her mind. In this case, his/her credibility must be measured against factors
        such as him/her alleging that he/she was forced into making the confession through whatever means. In the case
        of an <span className="font-weight-bold">admission</span>, certain facts related to the incident are confirmed
        by the alleged offending employee. Such facts are no longer placed in dispute and therefore do not need to be
        proven by leading other evidence.
      </p>
      <br />
      <p className="font-weight-bold">What weight does sworn statements/affidavits carry? </p>
      <p>
        Statements made under oath can be a useful form of evidence. However, the prejudice for the opposing party is
        that he/she cannot cross examine the person who made the statement, if the document is the only thing presented
        during a hearing process. If at all possible, the sworn statements should be supported by oral testimony of the
        writer in the hearing for it to carry weight. Hopefully, the sworn statement and testimony given in the hearing
        corresponds in all material aspects.
      </p>
      <br />
      <p className="font-weight-bold">What is the onus of proof in misconduct cases?</p>
      <p>
        In the case of a disciplinary process, you only need to prove your case on a balance of probability. The test to
        be applied is whether the employer has reasonable grounds for believing that the employee has committed the
        offence, provided that the employee is afforded a fair opportunity of stating his/her side of the story before
        coming to a conclusion. This means that all the evidence together must be evaluated to determine the matter. The
        version presented by the employer must be found on the whole to be more probable or likely than that of the
        employee.
      </p>
      <br />
      <p className="font-weight-bold">How is the evidence presented in the hearing? </p>
      <p>
        Because the employer bears the onus of proving the allegation/s it is making against the employee, it is first
        to call in any witnesses in support of its allegations. This allows for the questioning of the employer’s own
        witness/es to prove one’s own case (i.e. evidence in chief) and then for them to be cross-examined by the
        employee or his/her representative.
      </p>
      <br />
      <p>
        Re-examination of witnesses after cross-examination may occur at the discretion of the employer representative
        to clarify or minimise any “damage” that occurred due to the cross-examination. The party re-examining its
        witness may not introduce new evidence under re-examination that was not presented as the evidence in chief. If
        this does occur, however, then the other party must be allowed to cross-examine the witness again on the new
        evidence that was introduced.
      </p>
      <br />
      <p>
        Neither the employer nor the alleged offender or his/her representative can force any witness, whether such
        witness is an employee or not, to participate in any disciplinary hearing. Witness participation is voluntary at
        all times. The employer should nevertheless make every reasonable attempt to ensure the presence of key
        witnesses and if such witnesses are unavailable, state in the hearing for the record, that it has attempted to
        persuade the witness to attend the hearing, with no success.
      </p>
      <br />
      <p>
        Ensure that all documentary evidence is presented on record and made available for cross-examination. The same
        applies to evidence such as video or voice recordings.
      </p>
      <br />
      <p className="font-weight-bold">What is hearsay evidence? </p>
      <p>
        Hearsay evidence is when a person testifies to a fact of which he/she has become aware by means of a report from
        another person and not through his/her own senses of perception. For example, if Thabo states in a hearing that
        somebody else, namely Mary, reported to him that Pete hit John, then Thabo’s evidence about what Mary told him
        is hearsay. If Mary submitted only a written statement, then this would also be hearsay. Mary should rather
        testify in the hearing to what she saw the alleged offender do.
      </p>
      <br />
      <p className="font-weight-bold">Is hearsay evidence admissible?</p>
      <p>
        As chairperson of a disciplinary hearing, you are in principle entitled to admit hearsay evidence. The test for
        determining whether hearsay evidence is admissible is whether the admission of that evidence would materially
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        prejudice the other party's case. It should be excluded when it amounts to mere idle chatter or rumours. The
        weight to be attached to the hearsay evidence if allowed, will depend upon the following:
      </p>
      <ul>
        <li className="list-style">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          The reason for the witness's non-appearance at the hearing and the circumstances under which the hearsay
          statement(s) was made;
        </li>
        <li className="list-style">
          The existence of factors which indicate that the reported statement was clear and consistent;{' '}
        </li>
        <li className="list-style">
          The existence of corroboration of the evidence which is directly available for the hearing (for example,
          another eyewitness account);
        </li>
        <li className="list-style">
          The intrinsic quality of any evidence given by the person giving the hearsay statement.
        </li>
      </ul>
      <br />
      <p className="font-weight-bold">What if conflicting versions are presented in evidence?</p>
      <p>
        You need to weigh up the probabilities or improbabilities of each party’s version as well as the credibility and
        reliability of the respective parties’ witnesses to come to a qualitative, holistic conclusion.
      </p>
      <br />
      <p className="font-weight-bold">How do you assess the credibility of witnesses? </p>
      <p>
        As chairperson, you must assess the credibility of witnesses testifying and determine what weight you will
        attribute to their version. Some questions you can ask yourself to assist in this regard include:
      </p>
      <ul>
        <li className="list-style">Would the witness’s story make sense on its own? </li>
        <li className="list-style">Did they exhibit verbal or physical signs of truth or deception? </li>
        <li className="list-style">Is there other evidence to corroborate their version? </li>
        <li className="list-style">Does their version make logical sense? </li>
        <li className="list-style">Are other witnesses saying the same things? </li>
        <li className="list-style">Do they have any reason to lie or bring the alleged offender into disrepute? </li>
        <li className="list-style">Does the alleged offender have a history of similar past misconduct? </li>
      </ul>
    </div>
  );
};

export const Sanctions: React.FC = () => {
  return (
    <div>
      <h3 className="font-weight-bold">GUIDANCE: SANCTIONS</h3>
      <p className="font-weight-bold">
        What are possible sanctions that can be issued if the employee is found guilty of misconduct?
      </p>
      <br />
      <p>Possible disciplinary sanctions are:</p>
      <p>
        <span className="font-weight-bold">Verbal warning:</span> This applies in case of minor transgressions, is
        corrective in nature and attempts to avoid any future issues from arising.
      </p>
      <br />
      <p>
        <span className="font-weight-bold">Written warning:</span> A written warning is appropriate if the transgression
        appears for the first time, but the line manager considers it sufficiently serious to justify a written warning.
        Alternatively, a verbal warning may have been issued already with no corrective response from the employee and
        progressive discipline is applied through a written warning.
      </p>
      <br />
      <p>
        <span className="font-weight-bold">Final written warning:</span> This is appropriate in the case of habitual
        minor transgressions for which corrective steps have been taken by the employer without success. Alternatively,
        it is a first offence but viewed in such a serious light that it justifies more formal action to be taken.
      </p>
      <br />
      <p>
        <span className="font-weight-bold">Dismissal with notice pay:</span> Dismissal is appropriate when the alleged
        offence constitutes gross misconduct or in case of a habitual offender. Dismissal with notice pay means that the
        transgression is so serious that it leads to the termination of the employment relationship. However, due to
        surrounding circumstances and mitigating factors, a notice period is still paid out to the employee, but he/she
        no longer needs to render physical services in return.
      </p>
      <br />
      <p>
        <span className="font-weight-bold">Summary dismissal:</span> This is the most serious and harshest of penalties.
        Services are terminated on the date of the finding with no further monies payable to the employee due to the
        seriousness of the matter.
      </p>
      <br />
      <p>
        <span className="font-weight-bold">Demotion:</span> Some Disciplinary Codes allow, as an alternative to
        dismissal, the demotion of an employee, meaning he/she moves to a lower grade job with less pay. The employee
        may be given this as an option to avoid dismissal, but only if it has no negative operational impact on the
        employer. This sanction would not be appropriate where the trust relationship was irreparably damaged.
      </p>
      <br />
      <p>
        <span className="font-weight-bold">Unpaid suspension:</span> Some Disciplinary Codes allow, as an alternative to
        dismissal, the temporary suspension of the employee without pay. The period of suspension would be dependent
        upon the circumstances of each case. The employee may be given this as an option to avoid dismissal. This
        sanction would not be appropriate where the trust relationship was irreparably damaged.
      </p>
      <p>
        Your Disciplinary Code matrix will give you guidance on what sanction is appropriate for which offence, but you
        also still need to consider the circumstances of each case.
      </p>
      <br />
      <p>How do I decide what is an appropriate sanction? </p>
      <p>
        Whether in a disciplinary discussion or hearing, the employee must first be found guilty of some transgression
        in the circumstances. Then only does sanction become relevant. It is also only then that the employee’s past
        disciplinary record becomes relevant. The employer’s Disciplinary Code provides guidance on a particular offence
        and should normally be followed as the workplace norm. Aggravating and mitigating factors should also be
        considered holistically. Past precedents in the workplace on sanctions for similar offences play a role to
        ensure consistency in treatment.
      </p>
      <br />
      <p className="font-weight-bold">
        The employer may present aggravating factors when considering sanction. What does that mean?
      </p>
      <p>
        Any factor that counts in favour of a harsher sanction, should be brought to the chairperson’s attention. This
        includes the following:
      </p>
      <ul>
        <li className="list-style">The seriousness of the offence in the workplace;</li>
        <li className="list-style">
          The nature of the conduct, for example, where the offence involves dishonesty on the side of the employee;
        </li>
        <li className="list-style"> Any communicated zero tolerance stance of the employer;</li>
        <li className="list-style">The operational impact of the offence;</li>
        <li className="list-style">Monetary loss/damages suffered by the employer due to the misconduct;</li>
        <li className="list-style">
          Risks arising due to the misconduct – financial, social reputation of the employer, health and safety and the
          like;
        </li>
        <li className="list-style">Intent on the side of the offender;</li>
        <li className="list-style">The offender’s position in the workplace and seniority level;</li>
        <li className="list-style">The employee’s past disciplinary record;</li>
        <li className="list-style">
          Long years of service thus familiar with the rules and consequences of the breach;
        </li>
        <li className="list-style">
          Negative impact on trust relationship, alternatively, irreparable breakdown in the trust relationship.
        </li>
      </ul>
      <br />
      <p className="font-weight-bold">
        The employee may argue mitigating factors when discussing sanction. What does that mean?
      </p>
      <p>
        Any factor that counts in favour of a less harsh sanction, should be brought to the chairperson’s attention.
        This includes the following:
      </p>
      <ul>
        <li className="list-style"> Size of family and financial responsibilities of the offending employee;</li>
        <li className="list-style"> He/she may be the sole breadwinner;</li>
        <li className="list-style"> First offender;</li>
        <li className="list-style"> Long years of loyal service;</li>
        <li className="list-style">Remorse shown by the employee;</li>
        <li className="list-style">Personal circumstances of the employee.</li>
      </ul>
      <br />
      <p className="font-weight-bold">When is dismissal appropriate? </p>
      <p>
        In some circumstances it may be appropriate to end the employment relationship due to misconduct on the side of
        the employee. It is a sanction of last resort and only after a proper disciplinary hearing has been held. The
        following factors must be considered holistically:
      </p>
      <ul>
        <li className="list-style">
          The severity of the misconduct that the employee was found guilty of (i.e. was it gross misconduct) and/or if
          the employee is a habitual offender and chances of future rehabilitation is slim;
        </li>
        <li className="list-style"> The nature of the offence, for example, dishonesty related offences;</li>
        <li className="list-style"> Irreparable breach of the employer-employee trust relationship;</li>
        <li className="list-style">
          Whether or not continued employment has been made intolerable or alternative means exist to deal with the
          situation, such as demotion or unpaid suspension;
        </li>
        <li className="list-style"> Past precedents for similar transgressions;</li>
        <li className="list-style">
          {' '}
          Monetary losses suffered by the employer because of the misconduct, real or potential;
        </li>
        <li className="list-style">
          {' '}
          Other risks arising such as health and safety related, security breaches and the like;
        </li>
        <li className="list-style"> Reputational damage suffered by the employer, real or potential;</li>
        <li className="list-style">
          {' '}
          Remorse shown on the side of the employee and whether or not he/she takes accountability for actions.
        </li>
      </ul>
      <br />
      <p>
        Ultimately dismissal is not a sign of outrage or meant to be punitive in nature. It is a common-sense approach
        by the employer to avoid future repeat behaviour and/or mitigate foreseeable operational, monetary or
        reputational risks.
      </p>
      <br />
      <p className="font-weight-bold">
        Should one expressly address the impact of the misconduct on the trust relationship with the employee?
      </p>
      <p>
        Yes, you must formally address this matter and indicate to the chairperson how the trust relationship was
        impacted, especially if alleging the breach is irreparable and therefore justifies dismissal. You would need to
        motivate this reasoning, for example, by calling the employee’s line manager to testify why he/she no longer
        trusts the employee and cannot continue working with him/her based on the nature of the misconduct that was
        proven. A mere statement to this effect is not enough. Evidence must be led and the chairperson should not just
        assume a break down by virtue of their knowledge of the employer’s business or their own views. The employer
        must argue the matter with reference to factors such as the nature and scope of the employee’s duties, his/her
        place in the hierarchy, the importance of trust in the position the person holds or in the performance of tasks
        and the adverse effects (either direct or indirect) on the employer’s business of the conduct in question.
      </p>
      <br />
      <p className="font-weight-bold">Should the employer be consistent in its sanctioning of employees?</p>
      <p>
        Yes, the employer’s consistency in applying sanctions is an important element of fairness. The following should
        apply:
      </p>
      <ul>
        <li className="list-style">
          You should consistently act against employees making themselves guilty of same or similar transgressions. The
          workplace rules must be enforced, irrespective of who the alleged offender is. When the wrongful conduct of
          one employee is condoned (i.e. allowed without repercussions), action taken against another employee for the
          same conduct will be unfair.
        </li>
        <li className="list-style">
          You should issue the same sanction for proven same or similar offences. Same or similar cases of proven
          misconduct must lead to same or similar outcomes, unless objective mitigating factors exist justifying a
          deviation. LabourTeq will alert you if you’re potentially making inconsistent decisions and also requires you
          to record your rationale for deviations from your Disciplinary Code.
        </li>
      </ul>
      <br />
      <p className="font-weight-bold">
        What should I do if the employee refuses to sign a sanction document, like a final written warning?
      </p>
      <p>
        You are merely requesting the employee to sign in acknowledgement of receipt. This does not mean that he/she
        necessarily agrees with the outcome of the disciplinary hearing. Explain this to him/her. However, it is within
        his/her right to refuse to sign. In this instance hand the hearing outcome to the employee in the presence of a
        witness. The witness then signs the document indicating that it was handed to him/her in their presence. Place
        the document on the employee’s record. The employee’s signature is not required for any sanction to be valid.
      </p>
    </div>
  );
};

export const FAQ: React.FC = () => {
  return (
    <div>
      <h3 className="font-weight-bold">GUIDANCE: FAQ</h3>
      <p>Here are some frequently asked questions and answers relating to the disciplinary hearing process: </p>
      <br />
      <p className="font-weight-bold">
        What is the minimum period of notice of hearing that must be given to an employee?
      </p>
      <p>
        At least 2 working days’ notice of the hearing should be given to the employee to allow him/her to prepare
        his/her case. Depending on how complex the case may be, a longer period may be required to put the employee in a
        fair position to prepare. Should there be a reasonable request by the employee for additional time in order to
        prepare, this should not be unreasonably withheld. However, continuous requests for extensions of time, or
        delays that are unreasonable and obstructive, should not be granted. A notification of the hearing must be in
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        writing and not given verbally, in order to ensure that the nature of the charges, as well as the employee's
        rights, are clearly understood by the employee.{' '}
      </p>
      <br />
      <p className="font-weight-bold">Why should an interpreter be allowed?</p>
      <p>
        The normal business language in most workplaces is English. However, an employee may request an interpreter in a
        language of his/her choice to avoid any prejudice from arising during the hearing process, even if in day-to-day
        business he/she engages in English. The employee may feel more comfortable expressing themselves and/or
        testifying in their own language. It is the duty of the employer to provide this as part of the hearing process.
        The employee should be asked upfront to indicate the need for an interpreter to avoid unnecessary delays later.
      </p>
      <br />
      <p className="font-weight-bold">What are the stages in a hearing? </p>
      <p>
        There are two distinct stages in the disciplinary hearing: Firstly, the chairperson needs to determine whether
        the employee is guilty as charged in the Notice issued based on the evidence before him/her. Secondly, if found
        guilty, the chairperson needs to decide on an appropriate sanction commensurate with the misconduct.
      </p>
      <br />
      <p className="font-weight-bold">What role does fairness play in the disciplinary process?</p>
      <p>
        When dealing with misconduct in the workplace, you should always be guided by the principle of fairness. This is
        twofold. You should always follow a fair process in coming to a decision whether or not misconduct occurred and
        what the applicable sanction should be. This applies even when the transgressor is caught red-handed. This is
        known as procedural fairness.
      </p>
      <p>
        There should further always be sufficient evidence that a transgression was indeed committed on a balance of
        probabilities. This is known as substantive fairness.
      </p>
      <br />
      <p className="font-weight-bold">When should a postponement be granted?</p>
      <p>
        Postponements of hearing dates should only be granted when applied for timeously and where good reason is
        presented. It becomes part of the procedural fairness of the process whether or not a request for a postponement
        was unreasonably refused. Agreeing to a postponement depends on the merit of the case at the discretion of the
        chairperson.
      </p>
      <br />
      <p className="font-weight-bold">
        Should the employee be provided with the bundle of documentary evidence the employer will be using prior to the
        hearing?
      </p>
      <p>
        There is no harm in this but also no obligation to give copies of evidence to be presented at the hearing at the
        time when the Notice of the hearing is issued. Much will depend on the fairness of the matter; how much
        documentary proof is going to be used and whether or not the employee will be in a fair position to respond if
        he/she only sees the documents at the hearing for the first time. Providing a copy of the evidence to the
        employee before the hearing could avoid unnecessary postponements later. You are under no legal duty, however,
        to provide this upfront.
      </p>
      <br />
      <p className="font-weight-bold">Can polygraph reports be used as evidence? </p>
      <p>
        The use of polygraph tests is quite controversial and should be handled with care. Although an employee can be
        requested to submit to such a test, participation is voluntary. Some employers secure consent upfront when
        signing employment contracts. The outcome of a polygraph test can never be used in isolation as proof of guilt,
        but must support some other source of evidence indicating guilt on the side of the alleged offender. A
        professional accredited polygraphist should be used.
      </p>
      <br />
      <p className="font-weight-bold">Can an employee be disciplined for the same offence twice?</p>
      <p>
        According to the principle of double jeopardy, no. It is thus of the utmost importance to formulate the charges
        in the Notice correctly and to find and lead all relevant evidence the first time around to ensure a finding of
        guilt. Only in special circumstances, where new evidence comes to light that could not have been reasonably
        known at the time of the first hearing, can it lead to subsequent disciplinary action being taken. Failure to
        adhere to these standards will make subsequent action invalid.
      </p>
      <br />
      <p className="font-weight-bold">Is the chairperson allowed to ask questions during the hearing? </p>
      <p>
        The chairperson of the hearing is allowed to ask witnesses questions, in order to allow the chairperson to
        understand more fully the evidence given by the witness concerned. However, under no circumstances should the
        chairperson adopt a position in favour of or against the witness concerned while conducting such questioning, as
        this could be seen to compromise his/her impartiality. He/she may follow an inquisitorial approach during the
        hearing.
      </p>
      <br />
      <p className="font-weight-bold">What is the onus of proof in disciplinary hearings? </p>
      <p>
        The chairperson must make his/her decision based on a balance of probabilities. This means that he/she must be
        satisfied that the employer has shown that its version of the facts as presented at the hearing is more probable
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        than the employee's. This is the only test that is required at disciplinary hearings. The above test is
        different to that required in a criminal court for criminal offences, namely that the State must prove its case
        beyond a reasonable doubt.
      </p>
      <br />
      <p className="font-weight-bold">
        If a criminal matter is referred or pending, what is the impact on the hearing process?
      </p>
      <p>
        The two processes are separate and may enfold alongside each other. The internal workplace process does not need
        to be suspended pending the criminal trial.
      </p>
      <br />
      <p className="font-weight-bold">
        What happens if the employee is found not guilty at a criminal hearing for something he was found guilty of in
        the workplace?
      </p>
      <p>
        The outcome of a criminal trial has no bearing on the outcome of a disciplinary hearing, even though they may
        both arise out of exactly the same facts. For example, if an employee is found guilty in a disciplinary hearing
        of unauthorised possession of employer property and is subsequently dismissed, the employer will not have to
        reinstate him/her if he/she is later found not guilty of theft in the criminal trial. The two processes are
        mutually exclusive and the onus of proof differs.
      </p>
      <br />
      <p className="font-weight-bold">What is the difference between theft and unauthorised possession?</p>
      <p>
        Theft can be defined as the unauthorised possession of or removal of company/fellow employee/client property
        with the intention to deprive the owner permanently of it. It requires proof of this latter intention and
        knowledge that the act was unlawful. Unauthorised possession or removal is easier to prove as the intent to
        steal does not need to be proven. He/she must just not have permission to have the employer’s property in their
        possession or to remove it, whatever the motive may be. An element of dishonesty is still present.
      </p>
      <br />
      <p className="font-weight-bold">
        What is the difference between unauthorised absence, abscondment and desertion?
      </p>
      <p>
        The employee must make his/her service available to the employer, the employer must agree/permit any absence and
        the employee must communicate timeously in the event of unforeseen absence.
      </p>
      <p>
        Unauthorised absence occurs when the employee is absent from work without permission at a time when he/she is
        contractually obliged to render services and without any reasonable excuse for the absence. Abscondment occurs
        when the employee is absent for so long a period that a reasonable inference can be drawn that he/she does not
        intend to return to work. In the case of desertion, the employee is also absent, and has actually expressed that
        he/she has no intent to return to work.
      </p>
      <p>
        In all these instances a fair hearing process must still be followed. If the employee does not appear at the
        hearing, it may continue in his/her absence.
      </p>
      <br />
      <p className="font-weight-bold">What is the difference between negligence and dereliction of duty?</p>
      <p>
        The failure to comply with reasonable standards of care is classified as negligence. The employee makes a
        mistake. This should be distinguished from wilful or intentional acts or omissions. The standards against which
        you measure the employee must be reasonable and clear and known. Gross negligence occurs when the employee is
        persistently negligent, or, if the particular act or omission is particularly serious in itself. Dereliction of
        duty on the other hand refers to an intentional or conscious failure of an employee to do his/her duty.
      </p>
      <br />
      <p className="font-weight-bold">Is intent a necessary element of dishonesty?</p>
      <p>
        Dishonesty represents a breach of trust and a breach in the employment relationship. Dishonesty can take various
        forms, for example, misrepresentation, falsification of documents, presenting false evidence and the like. It
        involves deception on the side of the employee. This intent to deceive on the side of the offending employee
        must be proven.
      </p>
      <br />
      <p className="font-weight-bold">What is insubordination?</p>
      <p>
        There is a basic duty on the employee to obey reasonable, lawful instructions. Insubordination is behaviour that
        goes beyond mere failure to comply with or obey instructions. The employee actually challenges the authority of
        the employer and/or its manager and this must be proven. It includes various forms of wilful disobedient
        behaviour. Gross insubordination could justify dismissal in the first instance.
      </p>
      <br />
      <p className="font-weight-bold">Can I discipline someone for off-duty conduct?</p>
      <p>
        Normally you can only act against misconduct in the workplace. What the employee does in his spare time is his
        own business. However, where the private conduct of the employee has a causal link to the workplace you will be
        able to act. For example, where the employee makes negative remarks after hours at a party about the employer in
        front of a client which negatively impacts on the employer’s reputation, action can be taken.
      </p>
    </div>
  );
};
