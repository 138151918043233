import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
});
export const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 30,
    paddingHorizontal: 35,
  },
  logoImage: {
    paddingBottom: 20,
    width: 320,
    height: 85,
  },
  logoLeft: {
    marginRight: '80%',
  },
  logoRight: {
    marginLeft: '80%',
  },
  logoCenter: {
    marginLeft: '40%',
    marginRight: '40%',
  },
  fonts: {
    fontFamily: 'Roboto',
  },
  title: {
    fontSize: 10,
    marginBottom: 10,
    textAlign: 'left',
  },
  subject: {
    fontSize: 11,
    fontWeight: 'heavy',
    marginBottom: 3,
    marginTop: 3,
    textTransform: 'uppercase',
  },
  bold: {
    fontSize: 11,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 11,
    textAlign: 'left',
    lineHeight: 2,
  },
  textBold: {
    fontSize: 11,
    textAlign: 'left',
    lineHeight: 2,
    fontWeight: 'heavy',
  },
  checkbox: {
    width: '15px',
    height: '15px',
    borderWidth: 1,
    borderColor: '#333333',
    position: 'absolute',
    top: '30%',
    left: '30%',
  },
  bulletPoint: {
    height: '3px',
    width: '3px',
    borderRadius: 10,
    backgroundColor: '#333333',
  },
  paragraph: {
    marginTop: 6,
    marginBottom: 6,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableHeader: {
    backgroundColor: '#e9e9e9',
    padding: 8,
    fontSize: 9,
  },
  tableCell: {
    alignItems: 'center',
    height: 'auto',
    flex: 1,
    fontSize: 10,
    borderTop: 0.5,
    borderBottom: 0.5,
    borderLeft: 0.8,
    borderRight: 0.8,
    borderStyle: 'solid',
    borderColor: '#e5e5e5',
    paddingLeft: 10,
    paddingRight: 5,
    paddingTop: 8,
    paddingBottom: 10,
    color: '#333333',
    fontWeight: 'light',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  line: {
    borderBottom: 0.6,
    borderBottomColor: '#000000',
    borderBottomStyle: 'solid',
    alignSelf: 'center',
  },
  image: {
    objectFit: 'cover',
    width: 110,
    height: 55,
  },
});
