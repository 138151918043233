import * as Yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '../fields/FormField.component';

export const ConductDisciplinaryDiscussionSchema = Yup.object().shape({
  disciplinaryDiscussionDate: Yup.string().test(
    'date',
    'Please select a valid date',
    val => moment(val, DATE_FORMAT).isValid() || !val,
  ),
  disciplinaryDiscussionTime: Yup.string().test(
    'date',
    'Please select a valid time',
    val => moment(val, TIME_FORMAT).isValid() || !val,
  ),
  disciplinaryDiscussionAttendees: Yup.array()
    .of(
      Yup.object().shape({
        attendeeName: Yup.string(),
        attendeeEmailAddress: Yup.string().email('Email is not valid'),
        attendeeId: Yup.string(),
      }),
    )
    .nullable()
    .notRequired(),
});

export const RecordDisciplinaryDiscussionSchema = Yup.object().shape({
  disciplinaryDiscussionDate: Yup.string()
    .test('date', 'Please select a valid date', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  disciplinaryDiscussionTime: Yup.string()
    .test('date', 'Please select a valid time', val => moment(val, TIME_FORMAT).isValid())
    .required('Please select time'),
  disciplinaryDiscussionAttendees: Yup.array()
    .of(
      Yup.object().shape({
        attendeeName: Yup.string().required('Please enter attendee name'),
        attendeeEmailAddress: Yup.string()
          .email('Email is not valid')
          .required('Please enter email'),
        attendeeId: Yup.string().required('Please enter attendee id'),
      }),
    )
    .nullable()
    .required('Please select attendees'),
  disciplinaryDiscussionSummary: Yup.string().required('Please enter summary'),
  disciplinaryDiscussionLocation: Yup.string().required('Please enter location'),
  disciplinaryDiscussionDocuments: Yup.object()
    .shape({
      fileName: Yup.string().required('Required'),
      url: Yup.string().required('Required'),
      key: Yup.string().required('Required'),
    })
    .nullable(),
});

export const ConfirmDisciplinaryDecisionSchema = Yup.object().shape({
  disciplinaryDiscussionDecision: Yup.string().required('Please select decision'),
  disciplinaryDecisionReason: Yup.string().required('Please enter reason for decision'),
});

export const ExecuteDisciplinaryActionSchema = Yup.object().shape({
  warningValidityPeriod: Yup.string().required('Please select validity period'),
  warningExpiryDate: Yup.string()
    .test('date', 'Please select a valid date', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  disciplinaryDiscussionDate: Yup.string()
    .test('date', 'Please select a valid date', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  disciplinaryDiscussionTime: Yup.string()
    .test('date', 'Please select a valid time', val => moment(val, TIME_FORMAT).isValid())
    .required('Please select time'),
  incidents: Yup.array()
    .of(
      Yup.object().shape({
        transgression: Yup.string().required('Please transgression'),
        correctiveAction: Yup.string().required('Please enter corrective action'),
      }),
    )
    .required()
    .nullable(),
});
