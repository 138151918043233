import React from 'react';
import TopBarComponent from '../../components/topBar/TopBar.component';
import StaticIndicator from '../../components/staticIndicators/StaticIndicator';
import MyTasks from '../../components/dashboardWidgets/myTasks';
import TeamTasks from '../../components/dashboardWidgets/teamTasks';
import '../../components/dashboardWidgets/widgets.style.scss';
import SingleVarChart from '../AnalyticsScreen.tsx/charts/SingleVarChart';

const Dashboard: React.FC = props => {
  return (
    <>
      <TopBarComponent title={'Dashboard'} hideSearch color={'white'} style={{ height: '60px' }}>
        {' '}
      </TopBarComponent>
      <br />

      <div className="content" style={{ background: '#F5F5F5' }}>
      <StaticIndicator showMain />
      <div className="widgets">
          <div className="row">
            <div className="col-4">
              <MyTasks />
            </div>
            <div className="col-8">
              <TeamTasks />
            </div>
          </div>
        </div>

        <SingleVarChart />
        {/*<div style={{ display: 'flex', flexDirection: 'row' }}>*/}
        {/*  <LineGraphThin />*/}
        {/*  /!*<BarGraph data={quarterData} heading={`Quarterly Cases (${year})`} dataHeading="Active Cases" />*!/*/}
        {/*</div>*/}
        {/*<div style={{ display: 'flex', flexDirection: 'row' }}>*/}
        {/*  <PieChart />*/}
        {/*  /!*<BarGraph data={quarterData} heading={`Quarterly Cases (${year})`} dataHeading="Active Cases" />*!/*/}
        {/*</div>*/}
      </div>
    </>
  );
};

export default Dashboard;
