import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { generateGraphData } from './graphs.utils';
import { GraphData, MultiDataSet } from '../chart-options/case-reports-types';

type LineData = {
  /**
   * Data to show in the table.
   */
  // datasets: DataSet[];
  // graphData: GraphData[];
  multiDataSets: MultiDataSet[];
  /**
   * Heading of the table
   */
  heading: string;
  /**
  //  * Should the chart generate gradients for the data visualization.
  //  */
  gradients?: boolean;
  // /**
  //  * Where to start the colors of the chart? 0 - 360 (Based on color wheel, HSL colors)
  //  */
  colourStart?: number;
};

export const LineGraph: React.FC<LineData> = ({ multiDataSets, heading }) => {
  const data: GraphData = useMemo(() => generateGraphData(multiDataSets), [multiDataSets]);

  return (
    <Line
      data={data}
      options={{
        elements: {
          point: {
            radius: 0,
          },
        },
        title: {
          display: true,
          text: heading,
          fontSize: 16,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
              },
              ticks: {
                min: 0,
                // max: 5,
                stepSize: 2,
              },
            },
          ],
        },
      }}
    />
  );
};
