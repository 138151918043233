module.exports = options => {
  console.log('options', options);
  const headingText = options.headingText;
  const preheaderText = options.preheaderText;
  const bodyHtml = options.bodyHtml;
  const buttonHtml = options.buttonHtml;
  const logoUrl = options.logoUrl ? options.logoUrl : '';

  return `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html data-editor-version="2" class="sg-campaigns" xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1">
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=Edge">
    <!--<![endif]-->
    <!--[if (gte mso 9)|(IE)]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <!--[if (gte mso 9)|(IE)]>
    <style type="text/css">
        body {
            width: 600px;
            margin: 0 auto;
        }

        table {
            border-collapse: collapse;
        }

        table, td {
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
        }

        img {
            -ms-interpolation-mode: bicubic;
        }
    </style>
    <![endif]-->
    <style type="text/css">
        body, p, div {
            font-family: trebuchet ms, helvetica, sans-serif;
        }

        body {
            color: #65676e;
        }

        body a {
            color: #0055B8;
            text-decoration: none;
        }

        p {
            margin: 0;
            padding: 0;
        }

        table.wrapper {
            width: 100% !important;
            table-layout: fixed;
            -webkit-font-smoothing: antialiased;
            -webkit-text-size-adjust: 100%;
            -moz-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
        }

        center.wrapper{
            max-height: 50vh;
            overflow-y: auto;
            overflow-x: hidden;
        }

        img.max-width {
            max-width: 100% !important;
        }

        .column.of-2 {
            width: 50%;
        }

        .column.of-3 {
            width: 33.333%;
        }

        .column.of-4 {
            width: 25%;
        }

        @media screen and (max-width: 480px) {
            .preheader .rightColumnContent,
            .footer .rightColumnContent {
                text-align: left !important;
            }

            .preheader .rightColumnContent div,
            .preheader .rightColumnContent span,
            .footer .rightColumnContent div,
            .footer .rightColumnContent span {
                text-align: left !important;
            }

            .preheader .rightColumnContent,
            .preheader .leftColumnContent {
                font-size: 80% !important;
                padding: 5px 0;
            }

            table.wrapper-mobile {
                width: 100% !important;
                table-layout: fixed;
            }

            img.max-width {
                height: auto !important;
                max-width: 100% !important;
            }

            a.bulletproof-button {
                display: block !important;
                width: auto !important;
                font-size: 80%;
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            .columns {
                width: 100% !important;
            }

            .column {
                display: block !important;
                width: 100% !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }

            .social-icon-column {
                display: inline-block !important;
            }
        }
    </style>
    <!--user entered Head Start-->

    <!--End Head user entered-->
</head>
<body>
<center class="wrapper" data-link-color="#0055B8"
        data-body-style="font-size:14px; font-family:trebuchet ms,helvetica,sans-serif; color:#65676e; background-color:#EAEDED;">
    <div class="webkit">
        <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#EAEDED">
            <tr>
                <td valign="top" bgcolor="#EAEDED" width="100%">
                    <table width="100%" role="content-container" class="outer" align="center" cellpadding="0"
                           cellspacing="0" border="0">
                        <tr>
                            <td width="100%">
                                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                    <tr>
                                        <td>
                                            <!--[if mso]>
                                            <center>
                                                <table>
                                                    <tr>
                                                        <td width="600">
                                            <![endif]-->
                                            <table width="100%" cellpadding="0" cellspacing="0" border="0"
                                                   style="width:100%; max-width:600px;" align="center">
                                                <tr>
                                                    <td role="modules-container"
                                                        style="padding:20px 0px 0px 0px; color:#65676e; text-align:left;"
                                                        bgcolor="#eaeded" width="100%" align="left">
                                                        <table class="module preheader preheader-hide" role="module"
                                                               data-type="preheader" border="0" cellpadding="0"
                                                               cellspacing="0" width="100%"
                                                               style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
                                                            <tr>
                                                                <td role="module-content">
                                                                    <p>${preheaderText}</p>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table class="module" role="module" data-type="text" border="0"
                                                               cellpadding="0" cellspacing="0" width="100%"
                                                               style="table-layout: fixed;"
                                                               data-muid="10baab48-8586-4555-9a3d-f4fdcead3161"
                                                               data-mc-module-version="2019-10-22">
                                                            <tbody>
                                                            <tr>
                                                                        <td  style="width:320px; font-size:6px; line-height:10px; padding:0px 0px 0px 0px;" valign="top" align="left">
          <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px;" alt="" data-proportionally-constrained="true" data-responsive="false"
         src=${logoUrl} height="85">
        </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                        <table class="module" role="module" data-type="text" border="0"
                                                               cellpadding="0" cellspacing="0" width="100%"
                                                               style="table-layout: fixed;"
                                                               data-muid="6dcd759b-6dc8-473a-b314-6a6738013d26"
                                                               data-mc-module-version="2019-10-22">
                                                            <tbody>
                                                            <tr>
                                                                <td style="padding:18px 0px 18px 40px; line-height:20px; text-align:inherit; background-color:#ffffff;"
                                                                    height="100%" valign="top" bgcolor="#ffffff"
                                                                    role="module-content">
                                                                    <div>
                                                                        <div style="font-family: inherit; text-align: inherit">
                                                                            <span style="font-size: 24px; color: #000000; font-family: arial, helvetica, sans-serif">${headingText}</span>
                                                                        </div>
                                                                        <div></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                        ${bodyHtml}
                                                        ${buttonHtml}
                                                        <table class="module" role="module" data-type="spacer"
                                                               border="0" cellpadding="0" cellspacing="0" width="100%"
                                                               style="table-layout: fixed;"
                                                               data-muid="78bbd82f-2691-4a4f-a9e3-ddac305dfa47">
                                                            <tbody>
                                                            <tr>
                                                                <td style="padding:0px 0px 100px 0px;"
                                                                    role="module-content" bgcolor="#eaeded">
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                            <!--[if mso]>
                                            </td>
                                            </tr>
                                            </table>
                                            </center>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </div>
</center>
</body>
</html>
`;
};
