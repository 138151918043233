import React, { useContext } from 'react';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Tabs from '../../../../../components/HearingAndAppealTabs/HearingAndAppealTabs.component';
import { HEARING_TABS } from '../../../../../utils/case-utils';
import { AddIcon, RemoveIcon } from '../../../../../components/icon/Icon.component';
import { Tooltip } from '../../../../../components/tooltips/Tooltip.component';
import FormField from '../../../../../forms/fields/FormField.component';
import AsyncSelect from 'react-select/async';
import { selectStyles, ValueContainer } from '../../../../../components/reactSelect/ReactSelectComponents.component';
import { UserContext, UserContextProps } from '../../../../../App';
import { CaseType } from '../../../../../API';
import { listActiveEmployeesByOrganisationId } from '../../../../../utils/graphql-utils';
import { FormProps, Witness } from '../../../../WorkflowContainer/workflow-utils';
import { EmailRecipient, EmailType } from '../../../../../utils/email-utils';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../../../components/taskContainer/TaskContainer';
import { toTitleCase } from '../../../../../utils/string-utils';
import { Employee } from '../../../../../models';
import { EmailPreviewModalv3 } from '../../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { convertToFlowableVariables } from '../../../../../utils/flowable/flowable-utils';
import { notEmpty } from '../../../../../utils/typescript-utils';

interface SelectType {
  [key: string]: string | null | undefined | string[] | any;
}

const AppealHearingSelectWitnessForm: React.FC<FormProps> = props => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const { values, setFieldValue, errors }: FormikProps<FormikValues> = useFormikContext();

  const handleChange = (items: Witness[]): void => {
    setFieldValue('appeal_hearing_internalWitnesses', items);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data
      .filter(employee => employee.id !== props.data.caseData.employeeId)
      .map((employee: Employee) => {
        const emailAddresses = employee.emails.filter(notEmpty).map(item => item.address);
        return {
          label: employee.firstName + ' ' + employee.lastName,
          value: {
            employeeId: employee.id,
            emailAddress: emailAddresses[0],
            firstName: employee.firstName,
            lastName: employee.lastName,
          },
        };
      });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    return data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    });
  };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId) {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    }
  };

  const getEmailRecipients = (): EmailRecipient[] => {
    const recipients: EmailRecipient[] = [];
    if (values.appeal_hearing_internalWitnesses && values.appeal_hearing_internalWitnesses.length) {
      for (const witness of values.appeal_hearing_internalWitnesses) {
        if (witness.emailAddress && witness.firstName) {
          recipients.push({
            emailAddress: witness.emailAddress,
            name: toTitleCase(witness.firstName, ' '),
          });
        }
      }
    }
    if (values.appeal_hearing_externalWitnesses && values.appeal_hearing_externalWitnesses.length) {
      for (const witness of values.appeal_hearing_externalWitnesses) {
        if (witness.emailAddress && witness.firstName) {
          recipients.push({
            emailAddress: witness.emailAddress,
            name: toTitleCase(witness.firstName, ' '),
          });
        }
      }
    }
    return recipients;
  };

  return (
    <Form>
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Appeal Hearing Preparations</h4>
      <Row>
        <Col md={12}>
          <Tabs page={2} tabs={HEARING_TABS} />
        </Col>
      </Row>
      <h5 className="text-h5 text-capitalize font-weight-500 mt-3">Select Witness (if applicable)</h5>
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            Are there witnesses who should testify at the hearing?
            <span className="ml-3">
              <Tooltip
                id="witness"
                title="Witness"
                text={
                  props.data.caseType === CaseType.POOR_PERFORMANCE
                    ? 'This is a person who directly observed or who had first-hand exposure to the performance shortfalls and/or its consequences. They can testify on, for example, shortfalls they detected first-hand. Generally, they will be employees, but can be external individuals. They cannot be forced to testify or give evidence in the hearing. '
                    : 'This is a person who directly observed or who had first-hand exposure to the alleged incident. They can testify on, for example, what they heard or saw. Generally, they will be employees, but can be external individuals. They cannot be forced to testify or give evidence in the hearing'
                }
              />
            </span>
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.appeal_hearing_hasWitness}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_hasWitness', true);
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">Yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.appeal_hearing_hasWitness}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_hasWitness', false);
                      setFieldValue('appeal_hearing_internalWitnesses', null);
                      setFieldValue('appeal_hearing_externalWitnesses', null);
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">No</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      {values.appeal_hearing_hasWitness && (
        <>
          <Label className="text-default d-block mt-3">External Witnesses</Label>
          <FieldArray name="appeal_hearing_externalWitnesses">
            {(arrayHelpers: FieldArrayRenderProps) => (
              <>
                {!values.appeal_hearing_externalWitnesses &&
                  arrayHelpers.push({ firstName: '', lastName: '', emailAddress: '' })}
                {values.appeal_hearing_externalWitnesses &&
                  values.appeal_hearing_externalWitnesses.length &&
                  values.appeal_hearing_externalWitnesses.map((item: Witness, index: number) => {
                    return (
                      <Row key={index} className="mt-1 mb-2">
                        <Col md={3}>
                          <FormGroup>
                            <Label for="Witness First Name" className="text-default text-capitalize">
                              Witness First Name*
                            </Label>
                            <FormField
                              type={'text'}
                              placeholder="First Name"
                              name={`appeal_hearing_externalWitnesses.${index}.firstName`}
                              className="text-capitalize"
                            />
                            <span className="text-danger">
                              <ErrorMessage
                                className="text-danger"
                                name={`appeal_hearing_externalWitnesses.${index}.firstName`}
                              />
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label for="Last Name" className="text-default text-capitalize">
                              Witness Last Name*
                            </Label>
                            <FormField
                              type={'text'}
                              placeholder="Last Name"
                              name={`appeal_hearing_externalWitnesses.${index}.lastName`}
                              className="text-capitalize"
                            />
                            <span className="text-danger">
                              <ErrorMessage
                                className="text-danger"
                                name={`appeal_hearing_externalWitnesses.${index}.lastName`}
                              />
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label for="Witness Email Address" className="text-default text-capitalize">
                              Witness Email Address*
                            </Label>
                            <FormField
                              type={'text'}
                              placeholder="Witness Email Address"
                              name={`appeal_hearing_externalWitnesses.${index}.emailAddress`}
                            />
                            <span className="text-danger">
                              <ErrorMessage
                                className="text-danger"
                                name={`appeal_hearing_externalWitnesses.${index}.emailAddress`}
                              />
                            </span>
                          </FormGroup>
                        </Col>
                        <Col>
                          <div className="mt-sm-2 mt-md-4 mt-lg-4 pt-sm-0 pt-md-3 pt-lg-3 ">
                            {index === 0 ? (
                              <span
                                className="mt-4 pointer"
                                onClick={(): void =>
                                  arrayHelpers.push({ firstName: '', lastName: '', emailAddress: '' })
                                }
                              >
                                <AddIcon />
                              </span>
                            ) : (
                              <span className="pointer" onClick={(): void => arrayHelpers.remove(index)}>
                                <RemoveIcon />
                              </span>
                            )}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </>
            )}
          </FieldArray>
          <Row className="mt-2 mb-2">
            <Col md={6}>
              <span className={'text-default'}>AND/OR</span>
            </Col>
          </Row>
          <Row className="mt-2 mb-2">
            <Col md={6}>
              <FormGroup>
                <Label for="employee" className="text-default text-capitalize">
                  Select internal Witnesses
                </Label>
                <AsyncSelect
                  placeholder="Select Employee"
                  cacheOptions
                  defaultOptions
                  value={
                    values.appeal_hearing_internalWitnesses &&
                    values.appeal_hearing_internalWitnesses.length &&
                    values.appeal_hearing_internalWitnesses.map((item: Witness) => ({
                      label: item.firstName + ' ' + item.lastName,
                      value: item,
                    }))
                  }
                  loadOptions={loadOptions}
                  closeMenuOnSelect={false}
                  styles={selectStyles}
                  isMulti
                  onChange={(items: any[] | null): void => handleChange(items ? items.map(item => item.value) : [])}
                  components={{ ValueContainer }}
                  isDisabled={readOnly}
                />
                <span className="text-danger">
                  {errors && errors.appeal_hearing_internalWitnesses && 'Please select Witnesses'}
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <EmailPreviewModalv3
                buttonText={'Email Witnesses'}
                emailType={
                  props.data.caseType === CaseType.POOR_PERFORMANCE
                    ? EmailType.HEARING_WITNESS_POOR_PERFORMANCE
                    : EmailType.HEARING_WITNESS_MISCONDUCT
                }
                currentUserId={currentUser?.id}
                formValues={values}
                masterProcessInstanceId={props.data.masterProcessInstanceId}
                processInstanceId={props.data.processInstanceId}
                getRecipients={getEmailRecipients}
                disabled={!!Object.keys(errors).length}
                getFlowableVariables={() => {
                  return convertToFlowableVariables(props.getFormValuesForSubmission(values));
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default AppealHearingSelectWitnessForm;
