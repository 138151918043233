import React, { ReactElement, useContext, useState, useEffect } from 'react';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import TopBarComponent from '../../components/topBar/TopBar.component';
import './IncidentFormValues';
import { Button, Col, Row } from 'reactstrap';
import PastCaseForm from '../../forms/addCase/PastCaseForm';
import { useHistory, useLocation, useParams } from 'react-router';
import { createPastCase, updatePastCase } from '../../graphql/mutations';
import { AuditLogEventType, CaseType } from '../../API';
import { mutate } from '../../utils/graphql-utils';
import { UserContext, UserContextProps } from '../../App';
import { PastCaseSchema } from './CaseValidationSchema';
import { useErrorHandler } from '../../utils/notification-utils';
import { v4 as uuidV4 } from 'uuid';
import { isBucketFile } from '../../components/Uploader/FileDisplay';
import { BucketFile } from '../../models';
import { Storage } from 'aws-amplify';
import { createLog } from '../../utils/audit-log-utils';
import Loader from '../../components/loader/Loader';
import { CaseTableData } from '../../utils/case-utils';

const PastCase: React.FC = () => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const location = useLocation();
  const handleError = useErrorHandler();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    employeeId: id,
    caseNumber: '',
    sanction: '',
    expiryDate: '',
    transgression: '',
    shortfallDescription: '',
    frequency: '',
    caseType: 'MISCONDUCT',
    caseStatus: 'Case Closed',
    organisationId: currentUser?.organisationId,
    caseFiles: [],
  });

  const uploadFile = async (file: File, bucketPath: string): Promise<BucketFile> => {
    return new Promise((resolve, reject) => {
      Storage.put(`${bucketPath}/${file.name}`, file, {
        level: 'public',
      })
        .then(async (response: Record<string, any>) => {
          if (currentUser) {
            createLog(
              currentUser,
              AuditLogEventType.CASE_FILE_UPLOADED,
              null,
              null,
              'File uploaded',
              null,
            ).catch(error => handleError(error));
          }
          // getDocument
          await Storage.get(response['key'], { level: 'public' })
            .then(item => {
              const value = {
                fileName: file.name,
                key: response['key'] as string,
                url: item as string,
              };
              // resolve here
              resolve(value);
            })
            .catch(error => handleError(error));
        })
        .catch(error => {
          handleError(error);
          reject(error);
        });
    });
  };

  const handleSubmit = async (values: FormikValues): Promise<void> => {
    const caseObject = { ...values };
    let uploadedFiles: any[] = [];
    let stateFiles: any[] = [];

    if (caseObject.caseFiles && caseObject.caseFiles.length) {
      uploadedFiles = caseObject.caseFiles?.filter((file: BucketFile | File) => isBucketFile(file));
      stateFiles = caseObject.caseFiles?.filter((file: BucketFile | File) => !isBucketFile(file));
    }

    if (!uploadedFiles.length) {
      caseObject.caseFiles = null;
    } else {
      caseObject.caseFiles = uploadedFiles;
    }

    const query = caseObject.id ? updatePastCase : createPastCase;
    caseObject.id = caseObject.id ? caseObject.id : uuidV4();
    setLoading(true);
    await mutate(query, caseObject)
      .then(async () => {
        if (stateFiles.length) {
          const newFiles = [];
          for (let i = 0; i < stateFiles.length; i++) {
            const file = await uploadFile(stateFiles[i], `cases/${caseObject.id}`);
            newFiles.push(file);
          }
          if (newFiles.length) {
            caseObject.caseFiles = [...uploadedFiles, ...newFiles];
            await mutate(updatePastCase, caseObject).catch(error => handleError(error));
          }
        }
        history.goBack();
      })
      .catch(error => handleError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (location.state) {
      const tableData: CaseTableData = location.state as CaseTableData;
      const { description, ...rest } = tableData;
      const caseObject = {
        transgression: tableData.caseType === CaseType.MISCONDUCT ? description : 'N/A',
        shortfallDescription: tableData.caseType === CaseType.POOR_PERFORMANCE ? description : 'N/A',
        ...rest,
      };
      setState((oldState: any) => ({ ...oldState, ...caseObject }));
    }
  }, [JSON.stringify(state)]);

  return (
    <>
      <TopBarComponent title={'cases'} subTitle={'Employee Past Case'} />
      <div className="content ml-4">
        {loading && (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        )}
        {!loading && (
          <Formik initialValues={state} onSubmit={handleSubmit} validationSchema={PastCaseSchema} enableReinitialize>
            {({ values, handleSubmit, errors }: FormikProps<FormikValues>): ReactElement => (
              <>
                <Row>
                  <Form className="col">
                    <PastCaseForm />
                  </Form>
                </Row>
                <hr style={{ border: '0.06em solid #adb5bd' }} />
                <Row className="d-flex justify-content-between">
                  <Col className="align-items-start">
                    <Button className="text-uppercase rounded-0 btn-simple" onClick={(): void => history.goBack()}>
                      Cancel
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      className="text-uppercase rounded-0 btn btn-secondary"
                      type={'submit'}
                      onClick={(): void => handleSubmit()}
                    >
                      Save & Close
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default PastCase;
