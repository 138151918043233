import AddCaseScreen from '../addCase/addCaseScreen';
import ConsiderInvestigation from '../MisconductScreens/ConsiderInvestigation';
import RecordSuspensionDiscussion from '../MisconductScreens/RecordSuspensionDiscussion';
import SuspensionDecision from '../MisconductScreens/SuspensionDecision';
import { PrepareSuspensionLetter } from '../MisconductScreens/PrepareSuspensionLetter/PrepareSuspensionLetter';
import ProceedWithInvestigation from '../MisconductScreens/ProceedWithInvestigation/ProceedWithInvestigation';
import CompleteInvestigation from '../MisconductScreens/CompleteInvestigation/CompleteInvestigation';
import DisciplinaryAction from '../MisconductScreens/DisciplinaryAction/DisciplinaryAction';
import ConductDisciplinaryDiscussion from '../MisconductScreens/DiscussionScreens/ConductDisciplinaryDiscussion/ConductDisciplinaryDiscussion';
import RecordDisciplinaryDiscussion from '../MisconductScreens/DiscussionScreens/RecordDisciplinaryDiscussion/RecordDisciplinaryDiscussion';
import ConfirmDisciplinaryDecision from '../MisconductScreens/DiscussionScreens/ConfirmDisciplinaryDecision/ConfirmDisciplinaryDecision';
import ExecuteDisciplinaryAction from '../MisconductScreens/DiscussionScreens/ExecuteDisciplinaryAction/ExecuteDisciplinaryAction';
import InitialHearingAppointEmployerRepresentative from '../MisconductScreens/HearingScreens/InitialHearingScreens/AppointEmployerRepresentative/InitialHearingAppointEmployerRepresentative';
import InitialHearingAppointChairperson from '../MisconductScreens/HearingScreens/InitialHearingScreens/AppointChairperson/InitialHearingAppointChairperson';
import InitialHearingLogistics from '../MisconductScreens/HearingScreens/InitialHearingScreens/HearingLogistics/InitialHearingLogistics';
import InitialHearingCompileNoticeOfHearing from '../MisconductScreens/HearingScreens/InitialHearingScreens/CompileNoticeOfHearing/InitialHearingCompileNoticeOfHearing';
import InitialHearingRecordEmployeeRightsAndResponses from '../MisconductScreens/HearingScreens/InitialHearingScreens/RecordEmployeeRightsAndResponses/InitialHearingRecordEmployeeRightsAndResponses';
import InitialHearingExternalRepresentationSubmissions from '../MisconductScreens/HearingScreens/InitialHearingScreens/ExternalRepresentationSubmissions/InitialHearingExternalRepresentationSubmissions';
import InitialHearingExternalRepresentationOutcome from '../MisconductScreens/HearingScreens/InitialHearingScreens/ExternalRepresentationOutcome/InitialHearingExternalRepresentationOutcome';
import InitialHearingMinutesAndRecording from '../MisconductScreens/HearingScreens/InitialHearingScreens/MinutesAndRecording/InitialHearingMinutesAndRecording';
import InitialHearingChairpersonsRuling from '../MisconductScreens/HearingScreens/InitialHearingScreens/ChairpersonsRuling/InitialHearingChairpersonsRuling';
import { InitialHearingConfirmOverallSanction } from '../MisconductScreens/HearingScreens/InitialHearingScreens/ConfirmOverallSanction/InitialHearingConfirmOverallSanction';
import { AppealProcess } from '../MisconductScreens/HearingScreens/InitialHearingScreens/AppealProcess/AppealProcess';
import CCMADetailsA from '../CCMAScreens/CCMADetailsA/CCMADetailsA';
import CCMADetailsB from '../CCMAScreens/CCMADetailsB/CCMADetailsB';
import CaseAssessment from '../PoorPerformanceScreens/CaseAssessment/CaseAssessment';
import PrepareEmployeeDiscussion from '../PoorPerformanceScreens/DiscussionScreens/PrepareEmployeeDiscussion/PrepareEmployeeDiscussion';
import RecordEmployeeDiscussion from '../PoorPerformanceScreens/DiscussionScreens/RecordEmployeeDiscussion/RecordEmployeeDiscussion';
import DetermineNatureOfPerformanceShortfalls from '../PoorPerformanceScreens/DetermineNatureOfPerformanceShortfalls/DetermineNatureOfPerformanceShortfalls';
import InitiatePIP from '../PoorPerformanceScreens/PIP/InitiatePIP/InitiatePIP';
import RecordPIPDiscussion from '../PoorPerformanceScreens/PIP/RecordPIPDiscussion/RecordPIPDiscussion';
import RecordPIPProgressMeeting from '../PoorPerformanceScreens/PIP/RecordPIPProgressMeeting/RecordPIPProgressMeeting';
import CompletePipSession from '../PoorPerformanceScreens/PIP/CompletePipSession/CompletePipSession';
import PipAssessment from '../PoorPerformanceScreens/PIP/PipAssessment/PipAssessment';
import PrepareAgreedDemotion from '../PoorPerformanceScreens/PrepareAgreedDemotion/PrepareAgreedDemotion';
import NotifyPayrollOfDemotion from '../PoorPerformanceScreens/NotifyPayrollOfDemotion/NotifyPayrollOfDemotion';
import RecordDemotionDiscussion from '../PoorPerformanceScreens/RecordDemotionDiscussion/RecordDemotionDiscussion';
import RecordCounseling from '../PoorPerformanceScreens/RecordCounselling/RecordCounselling';
import ScheduleAdditionalCounsellingSession from '../PoorPerformanceScreens/ScheduleAdditionalCounsellingSession/SheduleAdditionalCounsellingSession';
import RecordAdditionalCounsellingSession from '../PoorPerformanceScreens/RecordAdditionalCounsellingSession/RecordAdditionalCounsellingSession';
import React from 'react';
import { Data } from './workflow-utils';
import { InitialHearingConcludeHearingAdministration } from '../MisconductScreens/HearingScreens/InitialHearingScreens/ConcludeHearingAdministration/InitialHearingConcludeHearingAdministration';
import { AppealHearingAppointChairperson } from '../MisconductScreens/HearingScreens/AppealHearingScreens/AppointChairperson/AppealHearingAppointChairperson';
import AppealHearingLogistics from '../MisconductScreens/HearingScreens/AppealHearingScreens/HearingLogistics/AppealHearingLogistics';
import AppealHearingRecordEmployeeRightsAndResponses from '../MisconductScreens/HearingScreens/AppealHearingScreens/RecordEmployeeRightsAndResponses/AppealHearingRecordEmployeeRightsAndResponses';
import { AppealHearingExternalRepresentationSubmissions } from '../MisconductScreens/HearingScreens/AppealHearingScreens/ExternalRepresentationSubmissions/AppealHearingExternalRepresentationSubmissions';
import { AppealHearingConcludeHearingAdministration } from '../MisconductScreens/HearingScreens/AppealHearingScreens/ConcludeHearingAdministration/AppealHearingConcludeHearingAdministration';
import AppealHearingCompileNoticeOfHearing from '../MisconductScreens/HearingScreens/AppealHearingScreens/CompileNoticeOfHearing/AppealHearingCompileNoticeOfHearing';
import { AppealHearingExternalRepresentationOutcome } from '../MisconductScreens/HearingScreens/AppealHearingScreens/ExternalRepresentationOutcome/AppealHearingExternalRepresentationOutcome';
import AppealHearingMinutesAndRecording from '../MisconductScreens/HearingScreens/AppealHearingScreens/MinutesAndRecording/AppealHearingMinutesAndRecording';
import { AppealHearingChairpersonsRuling } from '../MisconductScreens/HearingScreens/AppealHearingScreens/ChairpersonsRuling/AppealHearingChairpersonsRuling';
import { AppealHearingConfirmOverallSanction } from '../MisconductScreens/HearingScreens/AppealHearingScreens/ConfirmOverallSanction/AppealHearingConfirmOverallSanction';
import { AppealHearingAppointEmployerRepresentative } from '../MisconductScreens/HearingScreens/AppealHearingScreens/AppointEmployerRepresentative/AppealHearingAppointEmployerRepresentative';
import InitialHearingSelectWitness from '../MisconductScreens/HearingScreens/InitialHearingScreens/SelectWitness/InitialHearingSelectWitness';
import AppealHearingSelectWitness from '../MisconductScreens/HearingScreens/AppealHearingScreens/SelectWitness/AppealHearingSelectWitness';
import { ApproveDocumentView } from '../../screens/ConfirmReceiptView/ApproveDocumentView';
import { InitialHearingGuidance } from '../MisconductScreens/HearingScreens/GuidancePage/InitialHearing/InitialHearingGuidance';
import { AppealHearingGuidancePage } from '../MisconductScreens/HearingScreens/GuidancePage/AppealHearing/AppealHearingGuidancePage';
import InitialHearingExecuteOutcome from '../MisconductScreens/HearingScreens/InitialHearingScreens/ExecuteOutcome/InitialHearingExecuteOutcome';
import { AppealHearingExecuteOutcome } from '../MisconductScreens/HearingScreens/AppealHearingScreens/ExecuteOutcome/AppealHearingExecuteOutcome';
import PreparePerformanceCounselling from '../PoorPerformanceScreens/PreparePerformanceCounselling/PreparePerformanceCounselling';
import ExtendedPipAssessment from '../PoorPerformanceScreens/PIP/ExtendedPipAssessment/ExtendedPipAssessment';

export const getComponentByFormKey = (formKey: string, data: Data): JSX.Element => {
  switch (formKey) {
    case 'case-overview':
      return <AddCaseScreen data={data} />;
    case 'consider-investigation':
      return <ConsiderInvestigation data={data} />;
    case 'record-suspension-discussion':
      return <RecordSuspensionDiscussion data={data} />;
    case 'make-suspension-decision':
      return <SuspensionDecision data={data} />;
    case 'prepare-suspension-letter':
      return <PrepareSuspensionLetter data={data} />;
    case 'proceed-with-investigation':
      return <ProceedWithInvestigation data={data} />;
    case 'complete-investigation':
      return <CompleteInvestigation data={data} />;
    case 'disciplinary-action':
      return <DisciplinaryAction data={data} />;

    //    Discussion screens
    case 'conduct-disciplinary-discussion':
      return <ConductDisciplinaryDiscussion data={data} />;
    case 'record-disciplinary-discussion':
      return <RecordDisciplinaryDiscussion data={data} />;
    case 'confirm-disciplinary-decision':
      return <ConfirmDisciplinaryDecision data={data} />;
    case 'execute-disciplinary-action':
      return <ExecuteDisciplinaryAction data={data} />;

    //    Hearing screens
    case 'read-guidance':
      return <InitialHearingGuidance data={data} />;
    case 'read-guidance-appeal':
      return <AppealHearingGuidancePage data={data} />;
    case 'appoint-employer-representative':
      return <InitialHearingAppointEmployerRepresentative data={data} />;
    case 'appoint-employer-representative-appeal':
      return <AppealHearingAppointEmployerRepresentative data={data} />;
    case 'appoint-chairperson':
      return <InitialHearingAppointChairperson data={data} />;
    case 'appoint-chairperson-appeal':
      return <AppealHearingAppointChairperson data={data} />;
    case 'select-witness':
      return <InitialHearingSelectWitness data={data} />;
    case 'select-witness-appeal':
      return <AppealHearingSelectWitness data={data} />;
    case 'hearing-logistics':
      return <InitialHearingLogistics data={data} />;
    case 'hearing-logistics-appeal':
      return <AppealHearingLogistics data={data} />;
    case 'compile-notice-of-hearing':
      return <InitialHearingCompileNoticeOfHearing data={data} />;
    case 'compile-notice-of-hearing-appeal':
      return <AppealHearingCompileNoticeOfHearing data={data} />;
    case 'employee-rights':
      return <InitialHearingRecordEmployeeRightsAndResponses data={data} />;
    case 'employee-rights-appeal':
      return <AppealHearingRecordEmployeeRightsAndResponses data={data} />;
    case 'external-representation-submissions':
      return <InitialHearingExternalRepresentationSubmissions data={data} />;
    case 'external-representation-submissions-appeal':
      return <AppealHearingExternalRepresentationSubmissions data={data} />;
    case 'external-representation-outcome':
      return <InitialHearingExternalRepresentationOutcome data={data} />;
    case 'external-representation-outcome-appeal':
      return <AppealHearingExternalRepresentationOutcome data={data} />;
    case 'minutes-and-recording':
      return <InitialHearingMinutesAndRecording data={data} />;
    case 'minutes-and-recording-appeal':
      return <AppealHearingMinutesAndRecording data={data} />;
    case 'chairpersons-ruling':
      return <InitialHearingChairpersonsRuling data={data} />;
    case 'chairpersons-ruling-appeal':
      return <AppealHearingChairpersonsRuling data={data} />;
    case 'confirm-sanction':
      return <InitialHearingConfirmOverallSanction data={data} />;
    case 'confirm-sanction-appeal':
      return <AppealHearingConfirmOverallSanction data={data} />;
    case 'execute-outcome':
      return <InitialHearingExecuteOutcome data={data} />;
    case 'execute-outcome-appeal':
      return <AppealHearingExecuteOutcome data={data} />;
    case 'conclude-hearing-administration':
      return <InitialHearingConcludeHearingAdministration data={data} />;
    case 'conclude-hearing-administration-appeal':
      return <AppealHearingConcludeHearingAdministration data={data} />;
    case 'appeal-process':
      return <AppealProcess data={data} />;
    case 'ccma-details-a':
      return <CCMADetailsA data={data} />;
    case 'ccma-details-b':
      return <CCMADetailsB data={data} />;

    //  Poor Performance screens:
    case 'case-assessment':
      return <CaseAssessment data={data} />;
    case 'pp-prepare-employee-discussion':
      return <PrepareEmployeeDiscussion data={data} />;
    case 'pp-record-employee-discussion':
      return <RecordEmployeeDiscussion data={data} />;
    case 'determine-shortfalls':
      return <DetermineNatureOfPerformanceShortfalls data={data} />;

    //  PIP
    case 'initiate-pip':
    case 'initiate-extended-pip':
      return <InitiatePIP data={data} />;
    case 'record-pip':
    case 'record-extended-pip':
      return <RecordPIPDiscussion data={data} />;
    case 'record-progress-meeting':
    case 'record-extended-pip-progress-meeting':
      return <RecordPIPProgressMeeting data={data} />;
    case 'complete-pip-session':
    case 'complete-extended-pip-session':
      return <CompletePipSession data={data} />;
    case 'pip-assessment':
      return <PipAssessment data={data} />;
    case 'extended-pip-assessment':
      return <ExtendedPipAssessment data={data} />;
    case 'pip-assessment-final-ww':
      return <PipAssessment data={data} />;
    case 'prepare-agreed-demotion':
      return <PrepareAgreedDemotion data={data} />;
    case 'notify-payroll-of-demotion':
      return <NotifyPayrollOfDemotion data={data} />;
    case 'record-demotion-discussion':
      return <RecordDemotionDiscussion data={data} />;
    case 'prepare-performance-counselling':
      return <PreparePerformanceCounselling data={data} />;
    case 'record-counselling-discussion':
      return <RecordCounseling data={data} />;
    case 'prepare-additional-counselling-session':
      return <ScheduleAdditionalCounsellingSession data={data} />;
    case 'record-additional-counselling-session':
      return <RecordAdditionalCounsellingSession data={data} />;
    case 'approve-document-approver':
      return <ApproveDocumentView data={data} />;
    default:
      return <div />;
  }
};
