import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../../../components/PDF/PDFStyles.style';
import Table from '../../../../../components/PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../../../components/PDF/PDFTable/TableHeader.component';
import TableRow from '../../../../../components/PDF/PDFTable/TableRow.component';
import TableCell from '../../../../../components/PDF/PDFTable/TableCell.component';
import Paragraph from '../../../../../components/PDF/Paragraph.component';
import { LogoPosition } from '../../../../../API';
import LetterHead from '../../../../../components/PDF/Letterhead.component';
import { FormikValues } from 'formik';
import { toTitleCase } from '../../../../../utils/string-utils';

interface ChairpersonRulingPDFProps {
  values: FormikValues;
  logo?: { imageUrl: string; position: LogoPosition };
}

const InitialHearingPoorPerformanceChairpersonsRulingPDF = (props: ChairpersonRulingPDFProps): JSX.Element => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Chairperson’s Ruling</Text>
        <TableHeader title="Background of what led to the incapacity hearing process:" />
        <Paragraph>{props.values.initial_hearing_backgroundLeadingToHearing}</Paragraph>
        <TableHeader title="Employee’s rights exercised in the hearing:" />
        <Paragraph>{props.values.initial_hearing_employeeRightsExercisedInHearing}</Paragraph>
        <TableHeader title="Evidence submitted by the employer representative:" />
        <Paragraph>{props.values.initial_hearing_summaryOfEmployerRepresentativeEvidence}</Paragraph>
        <TableHeader title="Evidence submitted by the employee and/or his/her representative:" />
        <Paragraph>{props.values.initial_hearing_summaryOfEmployeesEvidence}</Paragraph>
        <TableHeader title="Legal principles applied, if any:" />
        <Paragraph>{props.values.initial_hearing_summaryOfLegalPrinciples}</Paragraph>
        <TableHeader
          title="Conclusions reached on sub-standard performance/shortfalls or otherwise based on factual evidence and
                  legal principles:"
        />
        <Paragraph>{props.values.initial_hearing_conclusionsReachedOnSubStandardPerformance}</Paragraph>
        <TableHeader title=" Findings On Incapacity. Tick as appropriate per shortfall:" />
        <Table>
          {props.values.performanceShortfalls.map((item: any) => (
            <TableRow key={item}>
              <TableCell>Shortfall: {item.shortfallDescription}</TableCell>
              <TableCell>
                {item.incapacitatedBasedOnDisciplinaryFindings ? 'Incapacitated' : ' Not Incapacitated'}
              </TableCell>
            </TableRow>
          ))}
        </Table>
        <TableHeader title="Summarise aggravating factors considered to determine way forward:" />
        <Paragraph>{props.values.initial_hearing_summaryOfAggravatingFactors}</Paragraph>
        <TableHeader title="Conclusions reached on way forward:" />
        <Paragraph>{props.values.initial_hearing_conclusionsReachedOnWayForward}</Paragraph>
        <TableHeader title="Summarise employee comments and mitigating factors considered to determine way forward:" />
        <Paragraph>{props.values.initial_hearing_employeeCommentsAndMitigatingFactors}</Paragraph>
        <TableHeader title="Selected Findings:" />
        <Paragraph>{toTitleCase(props.values.initial_hearing_findingsOnWayForward, '_')}</Paragraph>
        {props.values.initial_hearing_findingsOnWayForward === 'OTHER' && (
          <>
            <TableHeader title="Reason for selecting other:" />
            <Paragraph>{toTitleCase(props.values.initial_hearing_reasonForSelectingOther, '_')}</Paragraph>
          </>
        )}
      </Page>
    </Document>
  );
};

export default InitialHearingPoorPerformanceChairpersonsRulingPDF;
