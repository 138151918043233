import React, { ReactElement, useEffect } from 'react';
import { Formik, FormikProps, FormikValues, FormikErrors, getIn } from 'formik';
import InitialHearingSelectWitnessForm from './InitialHearingSelectWitnessForm';
import {
  filterFormData,
  getFormDataFromCaseData,
  Witness,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { ObjectSchema } from 'yup';
import { getFieldsAndValidationSchema } from '../../hearing-utils';

const InitialHearingSelectWitness: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const setupValues: {
    fields: { [key: string]: string };
    validationSchema: ObjectSchema<object>;
  } = getFieldsAndValidationSchema(props.data.caseType, flowableFunctions.formKey);
  const { fields, validationSchema } = setupValues;
  const initialValues: FormikValues = getFormDataFromCaseData(fields, caseData, props.data.caseData.isAppeal);

  const witnessesByType = (): void => {
    const initial_hearing_hearingWitnesses = initialValues.initial_hearing_hearingWitnesses;
    initialValues.initial_hearing_internalWitnesses = [];
    initialValues.initial_hearing_externalWitnesses = [];
    if (initial_hearing_hearingWitnesses) {
      initial_hearing_hearingWitnesses.map((witness: Witness) => {
        if (witness.employeeId) {
          initialValues.initial_hearing_internalWitnesses.push(witness);
        } else {
          initialValues.initial_hearing_externalWitnesses.push(witness);
        }
      });
      if (!initialValues.initial_hearing_externalWitnesses.length)
        initialValues.initial_hearing_externalWitnesses = null;
    } else {
      initialValues.initial_hearing_internalWitnesses = null;
      initialValues.initial_hearing_externalWitnesses = null;
    }
    initialValues.initial_hearing_hearingWitnesses = null;
  };

  const getFormValuesForSubmission = (values: FormikValues): { [key: string]: any } => {
    const formValues = { ...values };
    formValues.initial_hearing_hearingWitnesses = [];
    if (formValues.initial_hearing_internalWitnesses?.length) {
      formValues.initial_hearing_hearingWitnesses = formValues.initial_hearing_internalWitnesses.map(
        (witness: Witness) => {
          return {
            firstName: witness.firstName,
            lastName: witness.lastName,
            emailAddress: witness.emailAddress,
            employeeId: witness.employeeId,
          };
        },
      );
    }
    if (formValues.initial_hearing_externalWitnesses) {
      // look for non-empty objects. external witness variables can have objects with no property values.
      const initial_hearing_externalWitnesses = formValues.initial_hearing_externalWitnesses.filter(
        (witness: Witness) =>
          Object.keys(witness).length && witness.firstName && witness.lastName && witness.emailAddress,
      );
      formValues.initial_hearing_hearingWitnesses = [
        ...formValues.initial_hearing_hearingWitnesses,
        ...initial_hearing_externalWitnesses,
      ];
    }
    formValues.initial_hearing_hearingWitnesses = JSON.stringify(formValues.initial_hearing_hearingWitnesses);
    return filterFormData(formValues, fields);
  };

  const submitValues = (formValues: FormikValues): void => {
    props.data.flowableFunctions.onNext(getFormValuesForSubmission(formValues));
  };

  const setErrors = (
    errors: FormikErrors<FormikValues>,
    setFieldTouched: (field: string, touched: boolean) => void,
  ): void => {
    const errorKeys = Object.keys(errors);
    errorKeys.forEach(field => {
      //if fieldArray
      if (Array.isArray(errors[field])) {
        const err = getIn(errors, field);
        const innerArr = Object.keys(err);
        innerArr.forEach((item, index) => {
          let fieldNames;
          if (err[item]) {
            fieldNames = Object.keys(err[item]);
            fieldNames.forEach(name => setFieldTouched(`${field}.${index}.${name}`, true));
          }
        });
      } else {
        setFieldTouched(field, true);
      }
    });
  };

  useEffect(() => {
    if (initialValues) witnessesByType();
  }, [initialValues]);

  return (
    <div className="content">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={submitValues}
      >
        {({ values, handleSubmit, errors, setFieldTouched }: FormikProps<FormikValues>): ReactElement => (
          <>
            <div style={{ marginBottom: '8em' }}>
              <InitialHearingSelectWitnessForm
                data={props.data}
                getFormValuesForSubmission={getFormValuesForSubmission}
              />
            </div>
            <WorkFlowFooter
              data={props.data}
              onNext={(): void => {
                setErrors(errors, setFieldTouched);
                if (!Object.keys(errors).length) handleSubmit();
              }}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default InitialHearingSelectWitness;
