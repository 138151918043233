import { ErrorMessage, Field, FieldAttributes, Form, FormikValues, useFormikContext } from 'formik';
import React, { useContext, useEffect } from 'react';
import { ValueType } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { CaseType } from '../../../../../API';
import { UserContext, UserContextProps } from '../../../../../App';
import Tabs from '../../../../../components/HearingAndAppealTabs/HearingAndAppealTabs.component';
import { selectStyles } from '../../../../../components/reactSelect/ReactSelectComponents.component';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../../../components/taskContainer/TaskContainer';
import { Tooltip } from '../../../../../components/tooltips/Tooltip.component';
import FormField from '../../../../../forms/fields/FormField.component';
import { HEARING_TABS } from '../../../../../utils/case-utils';
import { FormProps } from '../../../../WorkflowContainer/workflow-utils';
import { appointEmployerRepresentative } from '../../../../../utils/user-utils';
import { toTitleCase } from '../../../../../utils/string-utils';
import { EmailParamsV2, EmailRecipient, EmailType } from '../../../../../utils/email-utils';
import { AxiosResponse } from 'axios';
import { EmailPreviewModalv3 } from '../../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { loadOptions } from '../../../../../utils/employee-user-selector';

export const AppealHearingAppointEmployerRepresentativeForm: React.FC<FormProps> = props => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const { values } = useFormikContext<FormikValues>();
  const { setFieldValue } = useFormikContext();

  const handleChange = (item: {
    value: string;
    label: string;
    firstName: string;
    lastName: string;
    employeeId: string;
    userId: string;
    emailAddress: string;
  }): void => {
    setFieldValue('appeal_hearing_employerRepresentativeFirstName', item.firstName);
    setFieldValue('appeal_hearing_employerRepresentativeLastName', item.lastName);
    setFieldValue('appeal_hearing_employerRepresentativeEmailAddress', item.emailAddress);
    setFieldValue('appeal_hearing_employerRepresentativeUserId', item.userId);
    setFieldValue('appeal_hearing_employerRepresentativeEmployeeId', item.employeeId);
    setFieldValue('appeal_hearing_employerRepresentativeId', item.value);
  };

  const sendEmail = async (): Promise<AxiosResponse> => {
    if (!currentUser?.id) {
      throw new Error('No user');
    }
    const emailParams: EmailParamsV2 = {
      currentUserId: currentUser.id,
      emailType: EmailType.NOTIFY_EMPLOYER_REPRESENTATIVE_OF_APPEAL,
      formValues: values,
      masterProcessInstanceId: props.data.masterProcessInstanceId,
      processInstanceId: props.data.processInstanceId,
      recipients: getRecipients(),
      attachmentBucketKeys: [],
    };
    return appointEmployerRepresentative(
      emailParams,
      props.getFormValuesForSubmission(values),
      values.appeal_hearing_employerRepresentativeEmailAddress,
      values.appeal_hearing_employerRepresentativeFirstName,
      values.appeal_hearing_employerRepresentativeLastName,
    );
  };

  const getRecipients = (): EmailRecipient[] => {
    return [
      {
        emailAddress: values.appeal_hearing_employerRepresentativeEmailAddress,
        name: toTitleCase(values.appeal_hearing_employerRepresentativeFirstName, ' '),
      },
    ];
  };

  useEffect(() => {
    if (!values.appeal_hearing_employerRepresentativeType)
      setFieldValue('appeal_hearing_employerRepresentativeType', 'internal');
  }, [values.appeal_hearing_employerRepresentativeType]);

  return (
    <Form>
      {props.data.caseType !== CaseType.POOR_PERFORMANCE && (
        <Row>
          <Col>
            {/*<Label className="text-default d-block mt-2">*/}
            {/*  Should the employee be suspended pending a hearing?*/}
            {/*  <span className="ml-3">*/}
            {/*    <Tooltip*/}
            {/*      id="suspendedBeforeHearing"*/}
            {/*      title="Should the employee be suspended pending the hearing?"*/}
            {/*      jsx={*/}
            {/*        <>*/}
            {/*          There is no hard-and-fast rule to determine when an employee should be suspended, but this action*/}
            {/*          could constitute an unfair labour practice if not justified. You should apply your mind whether a*/}
            {/*          reasonable, justifiable cause exists to suspect, for example, that:*/}
            {/*          <ul>*/}
            {/*            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>*/}
            {/*              The nature of the alleged offence poses an operational risk or impacts on the trust*/}
            {/*              relationship.*/}
            {/*            </li>*/}
            {/*            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>*/}
            {/*              The employee may interfere with the investigation or the operations of the employer.*/}
            {/*            </li>*/}
            {/*            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>*/}
            {/*              The employee may interfere with or retaliate against a complainant.*/}
            {/*            </li>*/}
            {/*            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>*/}
            {/*              The alleged offence/s may be repeated if the employee continues in his/her role and retains*/}
            {/*              access to the employer’s premises and equipment.*/}
            {/*            </li>*/}
            {/*            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>*/}
            {/*              When the health and safety of fellow employees is at risk.*/}
            {/*            </li>*/}
            {/*          </ul>*/}
            {/*          <br />*/}
            {/*          <u>Only</u> if such is reasonably foreseeable, should you suspend the employee pending an*/}
            {/*          investigation or disciplinary hearing. It is reserved for serious cases of alleged misconduct.*/}
            {/*          Such suspension is always with pay. See Guidance: Suspension for more information, if required.*/}
            {/*        </>*/}
            {/*      }*/}
            {/*    />*/}
            {/*  </span>*/}
            {/*</Label>*/}
            {/*<div className="d-flex">*/}
            {/*  <div className="mr-4">*/}
            {/*    <FormGroup check>*/}
            {/*      <Label check>*/}
            {/*        <Input*/}
            {/*          type="checkbox"*/}
            {/*          value="yes"*/}
            {/*          checked={values.appeal_hearing_suspendPendingHearing}*/}
            {/*          onChange={(): void => {*/}
            {/*            setFieldValue('appeal_hearing_suspendPendingHearing', true);*/}
            {/*          }}*/}
            {/*          disabled={readOnly}*/}
            {/*        />*/}
            {/*        <span className="form-check-sign">*/}
            {/*          <span className="check text-muted">Yes</span>*/}
            {/*        </span>*/}
            {/*      </Label>*/}
            {/*    </FormGroup>*/}
            {/*  </div>*/}
            {/*  <div className="ml-4">*/}
            {/*    <FormGroup check>*/}
            {/*      <Label check>*/}
            {/*        <Input*/}
            {/*          type="checkbox"*/}
            {/*          checked={!values.appeal_hearing_suspendPendingHearing}*/}
            {/*          onChange={(): void => {*/}
            {/*            setFieldValue('appeal_hearing_suspendPendingHearing', false);*/}
            {/*          }}*/}
            {/*          disabled={readOnly}*/}
            {/*        />*/}
            {/*        <span className="form-check-sign">*/}
            {/*          <span className="check text-default text-muted">No</span>*/}
            {/*        </span>*/}
            {/*      </Label>*/}
            {/*    </FormGroup>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </Col>
        </Row>
      )}
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Hearing preparations</h4>
      <Row>
        <Col md={12}>
          <Tabs page={0} tabs={HEARING_TABS} />
        </Col>
      </Row>
      <h5 className="text-h5 text-blue text-capitalize font-weight-500 mt-3">Appoint Employer Representative</h5>
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">
            Appoint Employer Representative?
            <span className="ml-3">
              <Tooltip
                id="employerRep"
                title="Employer Representative"
                text={
                  props.data.caseType === CaseType.POOR_PERFORMANCE
                    ? `This is the person that presents the employer’s case at the hearing and leads all the evidence in support of its allegation of continued and/or  serious  poor  performance.  It  typically  is  the  same  person  who  initially counselled  the  employee  for  his/her  performance  shortfalls  or  managed  the employee’s Performance Improvement Plan.`
                    : `This is the person that presents the employer’s case at the hearing and leads all the evidence in support of its case. It typically is the same person who initially investigated the matter.`
                }
              />
            </span>
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.appeal_hearing_employerRepresentativeType === 'internal'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_employerRepresentativeType', 'internal');
                      setFieldValue('appeal_hearing_employerRepresentativeEmailAddress', '');
                      setFieldValue('appeal_hearing_employerRepresentativeFirstName', '');
                      setFieldValue('appeal_hearing_employerRepresentativeLastName', '');
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted">Internal</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.appeal_hearing_employerRepresentativeType === 'external'}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_employerRepresentativeType', 'external');
                      setFieldValue('appeal_hearing_employerRepresentativeEmailAddress', '');
                      setFieldValue('appeal_hearing_employerRepresentativeFirstName', '');
                      setFieldValue('appeal_hearing_employerRepresentativeLastName', '');
                      setFieldValue('appeal_hearing_employerRepresentativeId', undefined);
                    }}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted">External</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      {values.appeal_hearing_employerRepresentativeType === 'external' && (
        <Row className="mt-4 mb-4">
          <Col md={3}>
            <FormGroup>
              <Label for="Representative First Name" className="text-default text-capitalize">
                Representative First Name*
              </Label>
              <FormField type={'text'} placeholder="First Name" name="appeal_hearing_employerRepresentativeFirstName" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'appeal_hearing_employerRepresentativeFirstName'} />
              </span>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="Representative Last Name" className="text-default text-capitalize">
                Representative Last Name*
              </Label>
              <FormField type={'text'} placeholder="Last Name*" name="appeal_hearing_employerRepresentativeLastName" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'appeal_hearing_employerRepresentativeLastName'} />
              </span>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="Representative Email Address" className="text-default text-capitalize">
                Representative Email Address*
              </Label>
              <FormField
                type={'text'}
                placeholder="Email Address"
                name="appeal_hearing_employerRepresentativeEmailAddress"
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'appeal_hearing_employerRepresentativeEmailAddress'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
      )}

      {values.appeal_hearing_employerRepresentativeType === 'internal' && (
        <Row className="mt-4 mb-4">
          <Col md={3}>
            <FormGroup>
              <Label for="employee" className="text-default text-capitalize">
                Select Employee*
              </Label>
              <Field name={'appeal_hearing_employerRepresentativeId'}>
                {({ field }: FieldAttributes<FormikValues>) => (
                  <AsyncSelect
                    placeholder="Select Representative"
                    cacheOptions
                    value={{
                      label:
                        values.appeal_hearing_employerRepresentativeFirstName &&
                        values.appeal_hearing_employerRepresentativeLastName
                          ? values.appeal_hearing_employerRepresentativeFirstName +
                            ' ' +
                            values.appeal_hearing_employerRepresentativeLastName
                          : '',
                      value: values.appeal_hearing_employerRepresentativeId,
                    }}
                    closeMenuOnSelect={false}
                    defaultOptions
                    loadOptions={(inputValue: string | null) => {
                      return loadOptions(
                        inputValue,
                        props.data.caseData.organisationId,
                        props.data.caseData.employeeId,
                      );
                    }}
                    styles={selectStyles}
                    onChange={(value: ValueType<any>): void => handleChange(value)}
                    isDisabled={readOnly}
                  />
                )}
              </Field>
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'appeal_hearing_employerRepresentativeEmailAddress'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <EmailPreviewModalv3
            buttonText={'Email Representative'}
            disabled={
              !values.appeal_hearing_employerRepresentativeFirstName ||
              !values.appeal_hearing_employerRepresentativeLastName ||
              !values.appeal_hearing_employerRepresentativeEmailAddress ||
              readOnly
            }
            formValues={values}
            emailType={EmailType.NOTIFY_EMPLOYER_REPRESENTATIVE_OF_APPEAL}
            masterProcessInstanceId={props.data.masterProcessInstanceId}
            processInstanceId={props.data.processInstanceId}
            currentUserId={currentUser?.id}
            getRecipients={getRecipients}
            sendFunction={sendEmail}
            getFlowableVariables={() => []}
          />
        </Col>
      </Row>
    </Form>
  );
};
