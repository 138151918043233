import moment from 'moment';
import { get } from '../../utils/api-utils';
import { taskMappings } from './task-mappings';
import { DATE_FORMAT_SLASHES } from '../../utils/date-formats';

export enum TaskStatus {
  'NO_DUE_DATE',
  'IN_PROGRESS',
  'DUE_SOON',
  'OVERDUE',
}

// TODO: GET HEARING DATES SEPARATELY, USING SQL
export interface WidgetTask {
  id: string;
  name: string;
  status: TaskStatus;
  caseNumber: string;
  startDate: Date | null;
  masterProcessInstanceId: string;
  assigneeUserId: string;
  assigneeUserName?: string;
  employeeName?: string;
  dueDate: string;
}

export interface ApiTask {
  id: string;
  assigneeUserId: string;
  assigneeUserName?: string;
  startDate: Date;
  caseNumber: string;
  masterProcessInstanceId: string;
  employeeName?: string;
  caseType?: string;
  dueDate?: Date;
}

const formatTask = (t: ApiTask): WidgetTask | null => {
  const taskMapping = taskMappings[t.id];
  if (taskMapping) {
    let displayName;
    if (taskMapping.name.includes('$')) {
      const replaceValue = t.caseType ? (t.caseType === 'POOR_PERFORMANCE' ? 'incapacity' : 'disciplinary') : '';
      /* eslint-disable no-template-curly-in-string */
      displayName = taskMapping.name.replace('${hearingType}', replaceValue);
    } else {
      displayName = taskMapping.name;
    }

    let status: TaskStatus = TaskStatus.IN_PROGRESS;
    if (taskMapping.timeFrame && t.dueDate) {
      if (moment().isAfter(moment(t.dueDate).subtract(taskMapping.timeFrame.overdue, 'hours'))) {
        status = TaskStatus.OVERDUE;
      } else if (moment().isAfter(moment(t.dueDate).subtract(taskMapping.timeFrame.dueSoon, 'hours'))) {
        status = TaskStatus.DUE_SOON;
      } else {
        status = TaskStatus.IN_PROGRESS;
      }
    } else if (taskMapping.timeFrame && taskMapping.timeFrameWaitsForDueDate && !t.dueDate) {
      status = TaskStatus.IN_PROGRESS;
    } else if (taskMapping.timeFrame && !t.dueDate) {
      const age = moment().diff(moment(t.startDate), 'hours');

      status =
        age > taskMapping.timeFrame.overdue
          ? TaskStatus.OVERDUE
          : age > taskMapping.timeFrame.dueSoon
          ? TaskStatus.DUE_SOON
          : TaskStatus.IN_PROGRESS;
    } else {
      status = TaskStatus.IN_PROGRESS;
    }
    return {
      startDate: t.startDate,
      id: t.id,
      assigneeUserId: t.assigneeUserId,
      assigneeUserName: t.assigneeUserName,
      caseNumber: t.caseNumber,
      employeeName: t.employeeName,
      masterProcessInstanceId: t.masterProcessInstanceId,
      name: displayName,
      status: status,
      dueDate: t.dueDate ? moment(t.dueDate).format(DATE_FORMAT_SLASHES) : '',
    };
  } else {
    console.error('No task mapping for id ' + t.id);
    return null;
  }
};

// sort by dueDate, then by status, then by whether the task involves a suspension
const sortTasksByDateAndInvolvesSuspension = (tasks: WidgetTask[]): WidgetTask[] => {
  return tasks
    .sort((a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix())
    .sort((a, b) => {
      const rankA = a.status + (a.name.includes('suspen') ? 999 : 0);
      const rankB = b.status + (b.name.includes('suspen') ? 999 : 0);
      return rankB - rankA;
    });
};

export const getMyTasks = async (userId: string): Promise<WidgetTask[]> => {
  const res = await get<ApiTask[]>(`/my-tasks-widget/${userId}`);
  const tasks = res.data;

  const formattedTasks = tasks.map(t => formatTask(t));

  return sortTasksByDateAndInvolvesSuspension(formattedTasks.filter(t => !!t) as WidgetTask[]);
};

export const getTeamTasks = async (userId: string): Promise<WidgetTask[]> => {
  const res = await get<ApiTask[]>(`/team-tasks-widget/${userId}`);
  const tasks = res.data;

  const formattedTasks = tasks.map(t => formatTask(t));

  return sortTasksByDateAndInvolvesSuspension(formattedTasks.filter(t => !!t) as WidgetTask[]);
};
// return res.data
//   .filter(t => showTask(t) && !!t.assignee)
//   .map(item => {
//     const formattedTask = formatTask(item);
//     formattedTask.dueDate = moment(formattedTask.startTime)
//       .add(24, 'hours')
//       .toDate();
//     return formattedTask;
//   });

// if (taskMapping.timeFrame) {
//   if (upcomingEventDateTime) {
//     dueDateTime = moment(upcomingEventDateTime)
//         .subtract(taskMapping.timeFrame.overdue, 'hours')
//   } else if (!taskMapping.timeFrameWaitsForDueDate) {
//     dueDateTime = moment(startTasks[0].date)
//         .add(taskMapping.timeFrame.overdue, 'hours')
//   } else {
//     dueDateTime = null
//   }
// }

//
// const status = dueDateTime  > taskMapping.timeFrame.overdue
//     ? TaskStatus.OVERDUE
//     : age > taskMapping.timeFrame.dueSoon
//         ? TaskStatus.DUE_SOON
//         : TaskStatus.IN_PROGRESS;
//
//
//
// const timeLeft = (moment()).diff(moment(upcomingEventDateTime), 'hours');
//       status = timeLeft < taskMapping.timeFrame.overdue
//           ? TaskStatus.OVERDUE
//           : timeLeft < taskMapping.timeFrame.dueSoon
//               ? TaskStatus.DUE_SOON
//               : TaskStatus.IN_PROGRESS;
//     } else {
//       status = TaskStatus.IN_PROGRESS
//     }
//
//   else {
//     status = age > taskMapping.timeFrame.overdue
//         ? TaskStatus.OVERDUE
//         : age > taskMapping.timeFrame.dueSoon
//             ? TaskStatus.DUE_SOON
//             : TaskStatus.IN_PROGRESS;
//   }
// } else {
//   status = TaskStatus.IN_PROGRESS
// }
