import React, { ReactElement } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import InitialHearingLogisticsForm from './InitialHearingLogisticsForm';
import { initial_hearing_hearingLogisticsSchema } from '../../../../../forms/ValidationSchema/InitialHearingValidationSchema';

const InitialHearingLogistics: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const fields = {
    initial_hearing_hearingDate: 'string',
    initial_hearing_hearingTime: 'string',
    initial_hearing_hearingLocation: 'string',
    initial_hearing_chairpersonFirstName: 'string',
    initial_hearing_chairpersonLastName: 'string',
    initial_hearing_chairpersonEmailAddress: 'string',
    initial_hearing_employerRepresentativeFirstName: 'string',
    initial_hearing_employerRepresentativeLastName: 'string',
    initial_hearing_employerRepresentativeEmailAddress: 'string',
    initial_hearing_hearingWitnesses: 'array',
  };

  const initialValues: FormikValues = getFormDataFromCaseData(fields, caseData);

  const submitValues = async (values: FormikValues): Promise<void> => {
    await flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  const getFormValuesForSubmission = (values: FormikValues): Record<string, any> => {
    const formValues = { ...values };
    formValues.initial_hearing_hearingWitnesses = JSON.stringify(formValues.initial_hearing_hearingWitnesses);
    return filterFormData(formValues, fields);
  };

  return (
    <div className="content">
      <Formik
        initialValues={initialValues}
        validationSchema={initial_hearing_hearingLogisticsSchema}
        enableReinitialize
        onSubmit={submitValues}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <InitialHearingLogisticsForm data={props.data} getFormValuesForSubmission={getFormValuesForSubmission} />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default InitialHearingLogistics;
