import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { CaseClosedIcon } from '../icon/Icon.component';
import { useHistory } from 'react-router-dom';

export interface ClosedCaseModalProps {
  closeModal: () => void;
  employeeId: string;
  id: string;
  isOpen: boolean;
}

export const ClosedCaseModal: React.FC<ClosedCaseModalProps> = (props: ClosedCaseModalProps) => {
  const history = useHistory();
  const { id, employeeId, isOpen, closeModal } = props;
  return (
    <Modal className="p-0" size="md" isOpen={isOpen} centered>
      <ModalBody className="p-0 rounded-0">
        <div className="d-flex justify-content-between px-3 py-2 guidance-modal-header">
          <div className="text-capitalize ml-auto mr-auto font-weight-bold">This case is closed</div>
        </div>
        <div className="d-flex align-items-center flex-column px-3 py-3 mb-3">
          <div className="text-muted mb-2">Please choose where you want to go</div>
          <div className="pl-2">
            <CaseClosedIcon />
          </div>
        </div>
        <div className="d-flex justify-content-between my-2">
          <>
            <Button
              className="btn-simple text-uppercase rounded-0 ml-2 font-weight-light"
              title={'Case History'}
              onClick={(): void => {
                closeModal();
                history.push(`/create-employee/${employeeId}/history`);
              }}
            >
              Case History
            </Button>
            <Button
              className="btn-default text-uppercase rounded-0 mr-2 font-weight-light"
              title={'CCMA'}
              onClick={(): void => {
                closeModal();
                history.push(`/case/${id}`);
              }}
            >
              CCMA
            </Button>
          </>
        </div>
      </ModalBody>
    </Modal>
  );
};
