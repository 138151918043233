import React from 'react';
import './caseStatusBar.styles.scss';

interface CaseProgressBarProps {
  status: string | null;
}

export const CaseStatusBar: React.FC<CaseProgressBarProps> = (props: CaseProgressBarProps) => {
  return (
    <div className="content case-status-bar" style={{ height: '30px' }}>
      <span className="font-weight-bold">STATUS: </span>
      <span className="ml-2">{'     ' + props.status ? props.status : ''}</span>
    </div>
  );
};
