import React, { useState } from 'react';
import { BucketFile } from '../../models';
import { Storage } from 'aws-amplify';

export interface SessionHistory {
  sessionId: string;
  date: string;
  time: string;
  attendees: any[] | null;
  location: string;
  sessionCompleted: boolean;
  summary?: string;
  documents?: BucketFile[] | null;
}
interface PerformanceCounsellingHistoryAccordionProps {
  index: number;
  data: SessionHistory;
}
interface PerformanceCounsellingHistoryAccordionState {
  open: boolean;
}
const PerformanceCounsellingHistoryAccordion: React.FC<PerformanceCounsellingHistoryAccordionProps> = (
  props: PerformanceCounsellingHistoryAccordionProps,
) => {
  const [state, setState] = useState<PerformanceCounsellingHistoryAccordionState>({ open: false });
  const { date, time, location, attendees, documents, summary, sessionCompleted } = props.data;

  const getDocumentUrl = async (key: string): Promise<Record<string, any> | string> => {
    return new Promise((resolve, reject) => {
      Storage.get(key, { level: 'public' })
        .then(item => {
          if (item) {
            resolve(item);
          } else reject(new Error('could not get key'));
        })
        .catch(error => reject(error));
    });
  };

  return (
    <>
      <div
        className="d-flex justify-content-between tab-background pointer px-3 py-3 my-1"
        onClick={(): void => setState(oldState => ({ ...oldState, open: !state.open }))}
      >
        <span className="text-default">{props.index + 1}.</span>
        <span className="h-75 font-weight-light text-muted">{date}</span>
        <span className="h-75 font-weight-light text-muted">{time}</span>
        <span className="h-75 font-weight-light text-muted">{location ? location : 'TBA'}</span>
        <span className="h-75 font-weight-light text-muted">{sessionCompleted ? 'Completed' : 'Pending'}</span>
        <span>
          {state.open ? (
            <i className="tim-icons icon-minimal-up text-default font-weight-bold" />
          ) : (
            <i className="tim-icons icon-minimal-down text-default font-weight-bold" />
          )}
        </span>
      </div>
      {state.open && (
        <div className="d-flex flex-column">
          <div className="py-2" style={{ border: '#f6f9fc 0.05em solid' }}>
            <span className="px-2 d-flex flex-column">
              <span className="text-default font-weight-bold">Attendees</span>
              <span className="text-muted text-capitalize">
                {attendees &&
                  attendees.map((attendee: any, index: number) => (
                    <span key={index}>
                      {attendee.attendeeName}
                      {index !== attendees.length - 1 && ', '}
                    </span>
                  ))}
              </span>
            </span>
          </div>
          <div className="py-1" style={{ border: '#f6f9fc 0.05em solid' }}>
            <span className="px-2 d-flex flex-column">
              {documents &&
                documents.map((document: BucketFile, index: number) => (
                  <div key={index} className=" tab-background pointer px-2 py-3 my-1 w-50">
                    {index === 0 && <span className="text-default font-weight-bold">Discussion Documents</span>}
                    <a
                      href={undefined}
                      onClick={(e: any): void => {
                        getDocumentUrl(document.key).then((url: any) => {
                          e.preventDefault();
                          window.open(url, '_blank');
                        });
                      }}
                      className="text-success col-sm-md mx-2 d-flex justify-content-between"
                    >
                      <span key={index} className="text-muted" style={{ wordWrap: 'break-word' }}>
                        {document.fileName}
                      </span>
                      <span>
                        <i className="tim-icons icon-cloud-download-93 text-default font-weight-bold" />
                      </span>
                    </a>
                  </div>
                ))}
              {summary && (
                <div className="d-flex flex-column">
                  <span className="text-default font-weight-bold">Discussion Summary</span>
                  <span className="text-muted">{summary}</span>
                </div>
              )}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default PerformanceCounsellingHistoryAccordion;
