import {
  appeal_hearing_AppointChairperson,
  appeal_hearing_AppointEmployerRepresentative,
  appeal_hearing_EmployeeRightsAndResponses,
  appeal_hearing_HearingLogistics,
  appeal_hearing_MinutesAndRecording,
  appeal_hearing_SelectWitness,
  initial_hearing_AppointChairperson,
  initial_hearing_AppointEmployerRepresentative,
  initial_hearing_EmployeeRightsAndResponses,
  initial_hearing_HearingLogistics,
  initial_hearing_MinutesAndRecording,
  initial_hearing_SelectWitness,
} from './common-fields';

import { FieldsAndValidationSchema } from '../../screens/MisconductScreens/HearingScreens/hearing-utils';
import {
  appeal_hearing_chairpersonsRuling,
  appeal_hearing_confirmOverallSanction,
  initial_hearing_chairpersonsRuling,
  initial_hearing_confirmOverallSanction,
  startAppeal,
  initial_hearing_misconductCompileNoticeOfHearing,
  appeal_hearing_misconductCompileNoticeOfHearing,
} from './misconduct-fields';

export const misconductFieldsMap: {
  [key: string]: FieldsAndValidationSchema;
} = {
  'appoint-employer-representative': initial_hearing_AppointEmployerRepresentative,
  'appoint-chairperson': initial_hearing_AppointChairperson,
  'select-witness': initial_hearing_SelectWitness,
  'hearing-logistics': initial_hearing_HearingLogistics,
  'compile-notice-of-hearing': initial_hearing_misconductCompileNoticeOfHearing,
  'employee-rights': initial_hearing_EmployeeRightsAndResponses,
  'minutes-and-recording': initial_hearing_MinutesAndRecording,
  'chairpersons-ruling': initial_hearing_chairpersonsRuling,
  'confirm-sanction': initial_hearing_confirmOverallSanction,
  'appeal-process': startAppeal,

  // appeal
  'appoint-employer-representative-appeal': appeal_hearing_AppointEmployerRepresentative,
  'appoint-chairperson-appeal': appeal_hearing_AppointChairperson,
  'select-witness-appeal': appeal_hearing_SelectWitness,
  'hearing-logistics-appeal': appeal_hearing_HearingLogistics,
  'compile-notice-of-hearing-appeal': appeal_hearing_misconductCompileNoticeOfHearing,
  'employee-rights-appeal': appeal_hearing_EmployeeRightsAndResponses,
  'minutes-and-recording-appeal': appeal_hearing_MinutesAndRecording,
  'chairpersons-ruling-appeal': appeal_hearing_chairpersonsRuling,
  'confirm-sanction-appeal': appeal_hearing_confirmOverallSanction,
};
