import React, { ReactElement, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { RecordEmployeeDiscussionSchema } from '../../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import { WorkFlowFooter } from '../../../../components/workflowFooter/WorkFlowFooter';
import RecordEmployeeDiscussionForm from './RecordEmployeeDiscussionForm';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../WorkflowContainer/workflow-utils';

const RecordEmployeeDiscussion: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;

  const fields = {
    employeeDiscussionDate: 'string',
    employeeDiscussionTime: 'string',
    employeeDiscussionSummary: 'string',
    employeeDiscussionAttendees: 'array',
    employeeDiscussionDocuments: 'array',
    employeeDiscussionLocation: 'string',
  };
  const [data] = useState(getFormDataFromCaseData(fields, caseData, caseData.isAppeal));

  const submitValues = async (values: FormikValues) => {
    const formValues = { ...values };
    // add values from temporary performanceShortfalls table to main
    formValues.employeeDiscussionDocuments = JSON.stringify(formValues.employeeDiscussionDocuments);
    formValues.employeeDiscussionAttendees = JSON.stringify(formValues.employeeDiscussionAttendees);
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };
  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Record Employee Discussion</h4>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={RecordEmployeeDiscussionSchema}
        onSubmit={submitValues}
      >
        {({ values, errors, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
          <>
            <RecordEmployeeDiscussionForm />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={() => console.log('cancel')}
              onSaveAndClose={() => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
            {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
          </>
        )}
      </Formik>
    </div>
  );
};

export default RecordEmployeeDiscussion;
