import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { Col, Row } from 'reactstrap';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import GeneralCompanyInformationForm from './GeneralCompanyInformationForm';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import { CreateOrganisationInput, LogoPosition, ReportingPeriod } from '../../../API';
import { updateOrganisationSettings } from '../../../graphql/mutations';
import { getOrganisation } from '../../../graphql/queries';

import { get, mutate } from '../../../utils/graphql-utils';
import { UserContext, UserContextProps } from '../../../App';
import {
  NotificationAlertContext,
  NotificationAlertContextProps,
  NotificationAlertOptions,
} from '../../../layouts/AdminLayout';
import Loader from '../../../components/loader/Loader';
import { useErrorHandler } from '../../../utils/notification-utils';
import GeneralCompanyInformationSchema from './GeneralCompanyInformationSchema';
import { Month } from '../../../models';
import AddEmployeeButton from '../../../components/AddEmployeeButton/AddEmployeeButton';

const INITIAL_VALUES: CreateOrganisationInput = {
  id: null,
  organisationName: '',
  registrationNumber: '',
  vatNumber: '',
  webUrl: '',
  switchboardTelephoneNumber: '',
  companyHeadcount: '',
  mainContactEmail: 'hello',
  logo: {
    fileName: '',
    url: '',
    key: '',
  },
  logoPosition: LogoPosition.LEFT,
  mainContactNumber: '',
  facilitiesEmail: '',
  payrollEmail: '',
  itEmail: '',
  // locations: [],
  ccmaContactName: '',
  ccmaContactEmail: '',
  ccmaContactPhysicalAddress: '',
  monthForEndOfReportingYear: Month.DECEMBER,
  defaultReportingPeriod: ReportingPeriod.YEAR,
  useCustomDatesForReportingPeriod: false,
  useHCApprover: false,
};

const GeneralCompanyInformation: React.FC = () => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const notificationContext = useContext<Partial<NotificationAlertContextProps>>(NotificationAlertContext);
  const [organisation, setOrganisation] = useState<CreateOrganisationInput>(INITIAL_VALUES);
  const [loading, setLoading] = useState(true);
  const handleError = useErrorHandler();

  const showOrganisationUpdateNotification = (): void => {
    if (notificationContext.showNotificationCallback) {
      const options: NotificationAlertOptions = {
        place: 'tr',
        message: 'Company Information Updated!',
        type: 'primary',
        icon: 'tim-icons icon-bell-55',
        autoDismiss: 7,
      };
      notificationContext.showNotificationCallback(options);
    }
  };

  const submitValues = async (values: FormikValues): Promise<void> => {
    const organisationObject = { ...values } as any;
    delete organisationObject.createdAt;
    delete organisationObject.updatedAt;
    delete organisationObject.locations;
    delete organisationObject.departments;
    delete organisationObject.hrContactUser;
    if (currentUser?.email) {
      if (organisationObject.id) {
        await mutate(updateOrganisationSettings, organisationObject)
          .then(() => {
            showOrganisationUpdateNotification();
          })
          .catch(err => console.error(err));
      }
    }
  };

  useEffect(() => {
    const loadOrganisation = async (): Promise<void> => {
      if (currentUser?.organisationId) {
        await get<{ getOrganisation?: CreateOrganisationInput }>(getOrganisation, currentUser.organisationId)
          .then(res => {
            const organisation = res?.data?.getOrganisation;
            if (organisation) {
              setOrganisation(organisation);
              setLoading(false);
            } else {
              throw new Error('Could not load organisation');
            }
          })
          .catch(error => {
            handleError(error);
          });
      } else {
        handleError(new Error('Missing user Id'));
      }
    };
    loadOrganisation().catch(error => handleError(error));
  }, [currentUser?.organisationId, handleError]);

  return (
    <>
      <TopBarComponent title={'General'} subTitle={'View Company Information'}>
        <AddEmployeeButton />
      </TopBarComponent>
      <div className="content">
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <>
            <Formik
              initialValues={organisation}
              enableReinitialize
              validationSchema={GeneralCompanyInformationSchema}
              onSubmit={submitValues}
            >
              {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
                <>
                  <GeneralCompanyInformationForm />
                  <div className="mt-2 w-auto">
                    <hr style={{ border: '0.06em solid #adb5bd' }} />
                    <Row className="d-flex justify-content-between">
                      <Col className="text-right">
                        <ButtonWithIcons title="Save" handleClick={handleSubmit} buttonType="btn" />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </Formik>
          </>
        )}
      </div>
    </>
  );
};

export default GeneralCompanyInformation;
