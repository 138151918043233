import { startCase as _startCase } from 'lodash';
import { matrixSanctionNames, TransgressionCategory, TransgressionInput } from '../../../API';

export const timeKeepingAndAttendance = [
  'Abscondment or desertion',
  'Abuse of sick leave',
  'Attending to private business during work hours',
  'Clocking or signing attendance registers on behalf of a fellow employee ',
  'Extended unauthorised breaks ',
  'Failure to attend to duties during working hours',
  'Failure to clock in/out in prescribed manner ',
  'Failure to inform the employer of absence timeously',
  'Failure to properly complete attendance register',
  'Failure to report for overtime without a valid reason after previously having agreed to do work such',
  'Negligent timekeeping - recording times incorrectly on time sheets',
  'Fraudulent timekeeping - intentionally recording times incorrectly on time sheets',
  'Unauthorised absence from workstation ',
  'Habitual unauthorised absence from workstation ',
  'Late for work or leaving work early without permission ',
  'Sleeping on duty',
  'Unauthorised absence – away from work for three or less working days without prior permission',
  'Unauthorised entry of employer/client premises after working hours ',
];

export const workOutputsInstructionsAndProcedures = [
  'Dereliction of duty',
  'Failure to complete or submit prescribed documentation timeously',
  'Failure to comply with lawful instruction given by a superior',
  'Gross failure to comply with lawful instruction given by a superior',
  'Failure to comply with standing instructions and/or work procedures',
  'Gross failure to comply with standing instructions and/or work procedures',
  'Failure to comply with workplace policies, procedures, agreements, conditions of service or statutory obligations',
  'Gross failure to comply with workplace policies, procedures, agreements, conditions of service or statutory obligations',
  'Failure to report known wrongdoings of fellow employees',
  'Failure to submit documentation in support of any form of leave when required to do so',
  'Negligence',
  'Gross negligence',
  'Horseplay',
  'Intentionally disobeying a lawful instruction given by a superior',
  'Malingering',
  'Refusal to carry out a lawful instruction given by a superior',
  'Gross refusal to carry out a lawful instruction given by a superior',
  'Refusal to work',
  'Unsatisfactory work performance',
  'Serious or continued unsatisfactory work performance',
];

export const alcoholAndSubstances = [
  'Buying or selling of alcohol or intoxicating/illegal drugs or marijuana whilst at work or in the scope of duty',
  'Driving a vehicle to or from a workplace function with a blood alcohol content level higher than the prescribed statutory threshold and/or whilst being under the influence of drugs/substances',
  'Having alcohol or intoxicating/illegal drugs or marijuana in system whilst at work or in the scope of duty',
  'Unauthorised possession or use of alcohol or intoxicating/illegal drugs or marijuana whilst at work or in the scope of duty',
  'Under the influence of alcohol or intoxicating/illegal drugs or marijuana whilst at work or in the scope of duty, leading to incapacity',
];

export const violenceAndAgressiveBehavior = [
  'Possession of a dangerous weapon ',
  'Unauthorised or dangerous use of a weapon',
  'Fighting (physical)',
  'Threatening fellow employees, superiors, client employees or any other person in scope of duty',
  'Threat of assault',
  'Assault',
  'Intimidation',
  'Incitement of violence, riotous behaviour or fighting on duty',
  'Participating in violence or riotous behaviour',
];

export const getTransgressionSubCategories = (category: string): string[] => {
  const categoryName = _startCase(category.toLowerCase());
  switch (categoryName) {
    case 'Time Keeping And Attendance':
      return timeKeepingAndAttendance;
    case 'Work Outputs Instructions And Procedures':
      return workOutputsInstructionsAndProcedures;
    case 'Alcohol And Substances':
      return alcoholAndSubstances;
    case 'Violence And Agressive Behavior':
      return violenceAndAgressiveBehavior;
  }
  return [];
};

export const mapSelectValues = (items: string[]): any => {
  return items.map(value => {
    return {
      label: _startCase(value.toLowerCase()),
      value: value,
    };
  });
};

export const toStartCase = (value: string): string => {
  if (!value) return '';
  return _startCase(value.toLowerCase());
};

export const defaultDisciplinaryCodeValues: TransgressionInput[] = [
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Abscondment or desertion ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Abuse of sick leave',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Attending to private business during work hours',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Clocking in or signing attendance registers on behalf of a fellow employee',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Taking unauthorised extended breaks',
    sanction: {
      firstOffence: matrixSanctionNames.VERBAL_WARNING,
      secondOffence: matrixSanctionNames.WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      fourthOffence: matrixSanctionNames.DISMISSAL,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Failure to attend to duties during working hours',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Failure to clock in/out in prescribed manner',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Failure to inform the employer of absence timeously',
    sanction: {
      firstOffence: matrixSanctionNames.VERBAL_WARNING,
      secondOffence: matrixSanctionNames.WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      fourthOffence: matrixSanctionNames.DISMISSAL,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Failure to properly complete attendance register',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression:
      'Failure to report for overtime without a valid reason after previously having agreed to do such work',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Negligent timekeeping - recording times incorrectly on time sheets',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Fraudulent timekeeping - intentionally recording times incorrectly on time sheets',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Unauthorised absence from workstation',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Habitual unauthorised absence from workstation',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Late for work or leaving work early without permission ',
    sanction: {
      firstOffence: matrixSanctionNames.VERBAL_WARNING,
      secondOffence: matrixSanctionNames.WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      fourthOffence: matrixSanctionNames.DISMISSAL,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Loitering around premises during or after working hours',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Sleeping on duty ',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Unauthorised absence – away from work for three or less working days without prior permission',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Unauthorised absence – away from work for four or more working days without prior permission',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.TIME_KEEPING_AND_ATTENDANCE,
    default: true,
    active: true,
    transgression: 'Unauthorised entry of employer/client premises after working hours ',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Dereliction of duty',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Failure to complete or submit prescribed documentation timeously',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Failure to comply with lawful instruction given by a superior',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Gross Failure to comply with lawful instruction given by a superior',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Failure to comply with standing instructions and/or work procedures',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Gross failure to comply with standing instructions and/or work procedures',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression:
      'Failure to comply with workplace policies, procedures, agreements, conditions of service or statutory obligations',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression:
      'Gross failure to comply with workplace policies, procedures, agreements, conditions of service or statutory obligations',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Failure to report known wrongdoings of fellow employees',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Failure to submit documentation in support of any form of leave when required to do so',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Negligence',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Gross Negligence',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Horseplay',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Intentionally disobeying a lawful instruction given by a superior',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Malingering',
    sanction: {
      firstOffence: matrixSanctionNames.VERBAL_WARNING,
      secondOffence: matrixSanctionNames.WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      fourthOffence: matrixSanctionNames.DISMISSAL,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Refusal to carry out a lawful instruction given by a superior',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Gross refusal to carry out a lawful instruction given by a superior',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Refusal to work',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Unsatisfactory work performance',
    sanction: {
      firstOffence: matrixSanctionNames.VERBAL_WARNING,
      secondOffence: matrixSanctionNames.WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      fourthOffence: matrixSanctionNames.DISMISSAL,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Serious or continued unsatisfactory work performance',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Failure to cooperate during internal investigations',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Unauthorised cell phone usage inside the warehouse',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Failure to complete all deliveries allocated to invoice without informing management',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Gross failure to complete all deliveries allocated to invoice without informing management',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Stopping or making unauthorised stops on-route without management approval for reasons unrelated to work',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Deviation from prescribed delivery route without informing management',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Gross deviation from prescribed delivery route without informing management',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.WORK_OUTPUTS_INSTRUCTIONS_AND_PROCEDURES,
    default: true,
    active: true,
    transgression: 'Commissioning, loading, dispatching or unloading of any merchandise without official documentation or management consent',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.ALCOHOL_AND_SUBSTANCES,
    default: true,
    active: true,
    transgression:
      'Buying or selling of alcohol or intoxicating/illegal substances or marijuana whilst at work or in the scope of duty',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.ALCOHOL_AND_SUBSTANCES,
    default: true,
    active: true,
    transgression:
      'Driving a vehicle to or from a workplace function with a blood alcohol content level higher than the prescribed statutory threshold and/or whilst being under the influence of intoxicating/illegal substances',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.ALCOHOL_AND_SUBSTANCES,
    default: true,
    active: true,
    transgression:
      'Having alcohol or intoxicating/illegal substances or marijuana in system whilst at work or in the scope of duty',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.ALCOHOL_AND_SUBSTANCES,
    default: true,
    active: true,
    transgression:
      'Testing positive for alcohol or other substances during breathalyser or similar testing procedure',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.ALCOHOL_AND_SUBSTANCES,
    default: true,
    active: true,
    transgression:
      'Testing positive for alcohol or other substances during breathalyser or similar testing procedure',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.ALCOHOL_AND_SUBSTANCES,
    default: true,
    active: true,
    transgression:
      'Refusal to submit self for alcohol or drug testing',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.ALCOHOL_AND_SUBSTANCES,
    default: true,
    active: true,
    transgression:
      'Unauthorised possession or use of alcohol or intoxicating/illegal substances or marijuana whilst at work or in the scope of duty',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.ALCOHOL_AND_SUBSTANCES,
    default: true,
    active: true,
    transgression:
      'Under the influence of alcohol or intoxicating/illegal drugs or marijuana whilst at work or in the scope of duty, leading to incapacity',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.ALCOHOL_AND_SUBSTANCES,
    default: true,
    active: true,
    transgression:
      'Driving employer/client vehicle/machinery/forklift whilst under the influence of alcohol or intoxicating/illegal drugs or marijuana  ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.ALCOHOL_AND_SUBSTANCES,
    default: true,
    active: true,
    transgression:
      'Consuming alcohol, illegal drugs or substances during working hours or in the scope of duty [unless authorised]',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VIOLENCE_AND_AGGRESSIVE_BEHAVIOR,
    default: true,
    active: true,
    transgression: 'Possession of a dangerous weapon ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VIOLENCE_AND_AGGRESSIVE_BEHAVIOR,
    default: true,
    active: true,
    transgression: 'Unauthorised or dangerous use of a weapon',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VIOLENCE_AND_AGGRESSIVE_BEHAVIOR,
    default: true,
    active: true,
    transgression: 'Fighting (Physical)',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VIOLENCE_AND_AGGRESSIVE_BEHAVIOR,
    default: true,
    active: true,
    transgression: 'Serious fighting (Physical)',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VIOLENCE_AND_AGGRESSIVE_BEHAVIOR,
    default: true,
    active: true,
    transgression: 'Threatening fellow employees, superiors, client employees or any other person in scope of duty',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VIOLENCE_AND_AGGRESSIVE_BEHAVIOR,
    default: true,
    active: true,
    transgression: 'Threat of assault',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VIOLENCE_AND_AGGRESSIVE_BEHAVIOR,
    default: true,
    active: true,
    transgression: 'Assault',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VIOLENCE_AND_AGGRESSIVE_BEHAVIOR,
    default: true,
    active: true,
    transgression: 'Intimidation',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VIOLENCE_AND_AGGRESSIVE_BEHAVIOR,
    default: true,
    active: true,
    transgression: 'Incitement of violence, riotous behaviour or fighting on duty',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VIOLENCE_AND_AGGRESSIVE_BEHAVIOR,
    default: true,
    active: true,
    transgression: 'Participating in violence or riotous behaviour ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VIOLENCE_AND_AGGRESSIVE_BEHAVIOR,
    default: true,
    active: true,
    transgression: 'Instigation of unrest, unprocedural or unlawful conduct',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VIOLENCE_AND_AGGRESSIVE_BEHAVIOR,
    default: true,
    active: true,
    transgression: 'Participating in unrest, unprocedural or unlawful conduct',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.EMPLOYER_REPUTATION,
    default: true,
    active: true,
    transgression: 'Conduct that brings the name of the employer into disrepute',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.EMPLOYER_REPUTATION,
    default: true,
    active: true,
    transgression: 'Gross conduct that brings the name of the employer into disrepute',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Bringing or allowing unauthorised visitors onto employer/client premises',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression:
      'Failure to carry out the necessary/prescribed machine/ equipment checks at the beginning, during, or end of shift/workday',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Failure to observe safety and security regulations',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Gross failure to observe safety and security regulations ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Failure to report or timeously report an Injury on Duty (IOD)',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Failure to report or timeously report an accident/incident',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Failure to wear/use prescribed protective clothing or equipment',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Altering or amending company issued PPE without prior management approval',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Poor maintenance of machinery or equipment for which the employee is responsible',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Gross - Poor maintenance of machinery or equipment for which the employee is responsible',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Injury to others through negligence or horseplay',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Smoking in non-designated areas',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Refusal to obey security regulations',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Endangering the lives of self or others by disregarding safety or security rules or procedures',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.HEALTH_SAFETY_AND_SECURITY,
    default: true,
    active: true,
    transgression: 'Operating moving machinery, including forklifts, without a licence or authorisation',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Abusive and/or derogatory and/or offensive language or signs',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Acting in an unbecoming/ unprofessional manner whilst at a work function',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Behaviour leading to a negative impact on team',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Breach of employee’s duty of good faith',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Conflict of interest ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Disrespect, impudence, insolence or disorderly behaviour or language',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Gross disrespect, impudence, insolence or disorderly behaviour or language',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Insubordination',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Gross insubordination ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Unprofessional/improper/disgraceful conduct towards fellow employees, superiors or any other person in scope of duty',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression:
      'Gross unprofessional/improper/disgraceful conduct towards fellow employees, superiors or any other person in scope of duty',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Lending or borrowing of money to/from fellow employees or clients',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Rumour mongering',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Interference  with  or  coercing  of  fellow employee',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Gross  interference  with  or  coercing  of fellow employees',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.UNPROFESSIONAL_CONDUCT,
    default: true,
    active: true,
    transgression: 'Making  any  media  statement  without the necessary approval',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Abuse of authority',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Gross abuse of authority ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Breach of employee’s duty of good faith',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Conflict of interest ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Conducting private work for compensation/ a business without prior management consent',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Conducting private work for compensation/ a business in competition with the employer',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Breach of employer’s Code of Conduct & Ethics',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Gross breach of employer’s Code of Conduct & Ethics',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Victimisation',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Gross victimisation',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Nepotism',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Soliciting for or accepting a bribe ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Soliciting for tips',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Unauthorised, unnecessary or illegal expenditure ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Anti-competitive and/or collusive behaviour',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CORPORATE_GOVERNANCE,
    default: true,
    active: true,
    transgression: 'Contravening  any  statute/law  or  legal obligation applicable in the workplace',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to be contactable during working hours',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to keep in contact with manager as per prescribed times/intervals',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to report on deliverables/tasks as prescribed ',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure by the employee to avail himself/herself for online/virtual meetings',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Unprofessional behaviour during online/virtual meetings ',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Gross unprofessional behaviour during online/virtual meetings',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Use of employer equipment provided for remote work for private purposes',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Use of data provided for remote work purposes or paid for by the employer for private purposes',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to attend to duties remotely during working hours',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to inform the employer of absence timeously',
    sanction: {
      firstOffence: matrixSanctionNames.VERBAL_WARNING,
      secondOffence: matrixSanctionNames.WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      fourthOffence: matrixSanctionNames.DISMISSAL,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to apply for appropriate leave ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Negligent timekeeping - recording remote working times incorrectly on time sheets',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Fraudulent timekeeping - intentionally recording remote working times incorrectly on time sheets',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Unauthorised absence from remote workstation ',
    sanction: {
      firstOffence: matrixSanctionNames.VERBAL_WARNING,
      secondOffence: matrixSanctionNames.WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      fourthOffence: matrixSanctionNames.DISMISSAL,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Habitual unauthorised absence from remote workstation ',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to perform remote work – for three or less working days without prior permission',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to perform remote work – for four or more working days without prior permission',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Dereliction of duty',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to upload/send prescribed documentation timeously',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Refusal to perform remote work',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Unsatisfactory remote work outputs',
    sanction: {
      firstOffence: matrixSanctionNames.VERBAL_WARNING,
      secondOffence: matrixSanctionNames.WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      fourthOffence: matrixSanctionNames.DISMISSAL,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Serious or continued unsatisfactory remote work outputs',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression:
      'Under the influence of alcohol or intoxicating/illegal substances or marijuana whilst rendering remote work, leading to incapacity',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Behaviour leading to a negative impact on the remote team',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to notify the employer that employer equipment was stolen from remote work site',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to take reasonable care of and/or secure IT equipment at remote work site',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression:
      'Failure to take reasonable steps to protect confidential employer/client information when working remotely',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression:
      'Gross failure to take reasonable steps to protect confidential employer/client information when working remotely',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to comply with cyber security measures governing remote work ',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Gross Failure to comply with cyber security measures governing remote work ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.REMOTE_WORK,
    default: true,
    active: true,
    transgression: 'Failure to report cybersecurity threats or breaches',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT,
    default: true,
    active: true,
    transgression: 'Bullying',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT,
    default: true,
    active: true,
    transgression: 'Gross Bullying',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT,
    default: true,
    active: true,
    transgression: 'Harassment',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT,
    default: true,
    active: true,
    transgression: 'Gross Harassment',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT,
    default: true,
    active: true,
    transgression: 'Sexual Harassment',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT,
    default: true,
    active: true,
    transgression: 'Gross Sexual Harassment',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT,
    default: true,
    active: true,
    transgression: 'Unfair discriminatory behaviour',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT,
    default: true,
    active: true,
    transgression: 'Gross Unfair discriminatory behaviour',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISCRIMINATION_HARASSMENT_AND_UNFAIR_CONDUCT,
    default: true,
    active: true,
    transgression: 'Hate expressions, ridicule or contempt',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Allowing an unauthorised person to use employer/client’s equipment or property ',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Failure to report or timeously report damage, loss or theft of employer/client property',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Negligence leading to loss or theft of employer/client property/equipment/assets',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Gross negligence leading to loss or theft of employer/client property/equipment/assets',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Industrial sabotage',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Malicious/intentional damage to employer/client property/equipment/assets',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Misuse or wastage of employer/client property, e.g. telephone, stationery, vehicles etc.',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Negligent damage to employer/client property/equipment/assets',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Gross negligent damage to employer/client property/equipment/assets',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },


  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Misuse or neglect of employer/client property/equipment/assets',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Gross misuse or neglect of employer/client property/equipment/assets',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },


  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Possession of prohibited goods/items in designated areas',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Sabotage of employer/client plant, machinery, equipment, vehicles, IT systems, or property',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Theft or attempted theft – irrespective of value of item',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Collusion in theft or attempted theft – irrespective of value of item',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression:
      'Unauthorised or attempted unauthorised removal of company/client property or that of any other person',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Unlawful or unauthorised possession of employer/client property or that of any other person',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Unlawful or unauthorised use of employer/client property or that of any other person',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.PROPERTY_AND_EQUIPMEMT,
    default: true,
    active: true,
    transgression: 'Unauthorised selling of employer/client property',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.INDUSTRIAL_ACTION,
    default: true,
    active: true,
    transgression: 'Inciting or encouraging unlawful collective industrial action ',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.INDUSTRIAL_ACTION,
    default: true,
    active: true,
    transgression: 'Participating in unlawful collective industrial action',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.INDUSTRIAL_ACTION,
    default: true,
    active: true,
    transgression: 'Engaging in any form of intimidation or threatening behaviour during industrial action',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.INDUSTRIAL_ACTION,
    default: true,
    active: true,
    transgression: 'Engaging in unlawful or violent actions during industrial action',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.INDUSTRIAL_ACTION,
    default: true,
    active: true,
    transgression: 'Carrying or displaying weapons of any kind during industrial action',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.INDUSTRIAL_ACTION,
    default: true,
    active: true,
    transgression: 'Preventing fellow employees from belonging to a trade union or association',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VEHICLES,
    default: true,
    active: true,
    transgression: 'Allowing an unauthorised person to drive employer/client vehicle/machinery',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VEHICLES,
    default: true,
    active: true,
    transgression: 'Disobeying traffic ordinances whilst using employer/client vehicles',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VEHICLES,
    default: true,
    active: true,
    transgression:
      'Driving employer/client vehicle/machinery whilst under the influence of alcohol or intoxicating/illegal substances or marijuana ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VEHICLES,
    default: true,
    active: true,
    transgression: 'Driving employer/client vehicle/machinery without a valid driver’s licence',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VEHICLES,
    default: true,
    active: true,
    transgression: 'Driving employer/client vehicle/machinery without authority or permission',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VEHICLES,
    default: true,
    active: true,
    transgression: 'Failure to report or timeously report an accident involving a company vehicle/machinery ',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VEHICLES,
    default: true,
    active: true,
    transgression: 'Unauthorised carrying of passengers in employer/client vehicles/machinery',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.VEHICLES,
    default: true,
    active: true,
    transgression: 'Reckless or gross negligent driving',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CRIMINAL_OFFENCES,
    default: true,
    active: true,
    transgression:
      'Being charged with or arrested for a criminal offence which renders the employee unsuitable for his/her position',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CRIMINAL_OFFENCES,
    default: true,
    active: true,
    transgression: 'Being convicted of a criminal offence which renders the employee unsuitable for his/her position',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CRIMINAL_OFFENCES,
    default: true,
    active: true,
    transgression: 'Failure to inform employer of a conviction of a criminal offence whilst in employment',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CRIMINAL_OFFENCES,
    default: true,
    active: true,
    transgression: 'Committing  a  common  law  or  statutory offence whilst on employer premises or in the scope of work',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CONFIDENTIAL_INFORMATION_AND_DATA_BREACH,
    default: true,
    active: true,
    transgression: 'Divulgence, unauthorised use or abuse of confidential employer/client information',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CONFIDENTIAL_INFORMATION_AND_DATA_BREACH,
    default: true,
    active: true,
    transgression: 'Inappropriate use or distribution of personal data of fellow employees or any other person',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CONFIDENTIAL_INFORMATION_AND_DATA_BREACH,
    default: true,
    active: true,
    transgression:
      'Gross inappropriate and/or illegal use or distribution of personal data of fellow employees or any other person',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CONFIDENTIAL_INFORMATION_AND_DATA_BREACH,
    default: true,
    active: true,
    transgression: 'Inappropriate use of the employer’s brand, logo, e-mail addresses or similar',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CONFIDENTIAL_INFORMATION_AND_DATA_BREACH,
    default: true,
    active: true,
    transgression: 'Insider trading',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CONFIDENTIAL_INFORMATION_AND_DATA_BREACH,
    default: true,
    active: true,
    transgression: 'Negligently submitting employer related documentation containing inaccurate information ',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.CONFIDENTIAL_INFORMATION_AND_DATA_BREACH,
    default: true,
    active: true,
    transgression: 'Use of employer information for financial or other personal gain',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Bribery',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Corruption',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Deliberately supplying incorrect or falsified information or making false statements',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Submitting false medical certificates',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression:
      'Submitting incorrect or falsified job application information or omitting material job application information',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Dishonesty',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Faking an illness, injury or disability ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Falsification of records or documents or attempts to do so',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Fraud',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Giving untrue, erroneous or misleading information or testimony',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Lack of skill which the employee expressly or implicitly claimed him or herself to possess',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Making malicious allegations against a fellow employee',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Misrepresentation through actions, words or omission of relevant information',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Plagiarism',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Starting a business in competition with the employer',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Submitting fraudulent expense and/or mileage claims',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.DISHONESTY,
    default: true,
    active: true,
    transgression: 'Making false or damaging statements concerning the employer, its products/services, its clients/ customers or fellow employees',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Accessing, viewing, and/or posting of offensive content of any kind, including pornographic material using employer/client equipment, resources or networks',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Gross - Accessing, viewing, and/or posting of offensive content of any kind, including pornographic material using employer/client equipment or networks',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Allowing unauthorised persons to use employer/client equipment giving them access to employer/client data',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Use of employer/client equipment, social media or networks which may reflect badly on the employer/client’s social reputation or its relationship with clients, business partners or the general public',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Gross - Use of employer/client equipment, social media or networks which may reflect badly on the employer/client’s social reputation or its relationship with clients, business partners or the general public',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Downloading or distribution of unauthorised or illicit material using employer/client equipment or networks',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Gross - Downloading or distribution of unauthorised or illicit material using employer/client equipment or network',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Excessive downloading of files for non-business use',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Excessive usage of remote access services for non-business use',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Excessive usage of the employer’s telephone lines for non-business matters',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Failure to notify the employer if a personal smart device that has direct access to employer data is lost or stolen',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Failure to take reasonable care of and/or secure IT equipment ',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Gain or attempt to gain unauthorised or inappropriate access to any employer IT system and/or any illegal activities (hacking)',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Generating or disseminating chain letters/mail using employer/client equipment/networks ',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Inappropriate or unprofessional use of or postings on employer/client social media platforms',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Gross - Inappropriate or unprofessional use of or postings on employer/client social media platforms',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Posting of confidential, personal or derogatory information on the employer, its clients or fellow employees on any social media platforms',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Gross - Posting of confidential, personal, or derogatory information on the employer, its clients, or fellow employees on any social media platforms',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Failure to comply with security measures relating to employer/client IT equipment or networks',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Gross failure to comply with security measures relating to employer/client IT equipment or resources',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Unauthorised entry to, use of or changes to employer information on its IT network',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Gross unauthorised entry to, use of or changes to employer information on its IT network ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Illegal use of unlicensed software on employer equipment ',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Misrepresenting the communication sender’s identity (spoofing)',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Posting or communication of any defamatory, threatening, harassing or unfairly discriminatory content/messages using employer/client equipment, networks or social media platforms',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Sending unsolicited e-mails or other communications (spamming)',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Unauthorised installation of software on employer/client IT equipment',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression:
      'Unauthorised or inappropriate use of the employer’s IT resources to gain or attempt to gain access to any other parties’ systems or information',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Unauthorised use of employer/client internet',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Use of unapproved external devices to transfer data to and from employer/client equipment ',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Use of data provided for work purposes or paid for by the employer for private purposes',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Failure to comply with cyber security measures',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Gross failure to comply with cyber security measures',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Gross failure to comply with cyber security measures',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.IT_RESOURCES_AND_SOCIAL_MEDIA,
    default: true,
    active: true,
    transgression: 'Failure to report cybersecurity threats or breaches ',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.GENERAL,
    default: true,
    active: true,
    transgression: 'Committing unsanitary acts',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.GENERAL,
    default: true,
    active: true,
    transgression: 'Inappropriate dress',
    sanction: {
      firstOffence: matrixSanctionNames.VERBAL_WARNING,
      secondOffence: matrixSanctionNames.WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      fourthOffence: matrixSanctionNames.DISMISSAL,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.GENERAL,
    default: true,
    active: true,
    transgression: 'Wearing of political party branding or regalia whilst rendering services',
    sanction: {
      firstOffence: matrixSanctionNames.VERBAL_WARNING,
      secondOffence: matrixSanctionNames.WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      fourthOffence: matrixSanctionNames.DISMISSAL,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.GENERAL,
    default: true,
    active: true,
    transgression: 'Displaying or distributing unauthorised notices or documents ',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.GENERAL,
    default: true,
    active: true,
    transgression: 'Failure to complete mandatory training',
    sanction: {
      firstOffence: matrixSanctionNames.WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.GENERAL,
    default: true,
    active: true,
    transgression: 'Convening, conducting, attending or participating in unauthorised meetings on employer premises',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.DISMISSAL,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.COVID_19,
    default: true,
    active: true,
    transgression: 'Failure to comply with COVID-19 regulations and/or workplace prescripts',
    sanction: {
      firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
      secondOffence: matrixSanctionNames.DISMISSAL,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
  {
    transgressionCategory: TransgressionCategory.COVID_19,
    default: true,
    active: true,
    transgression: 'Gross failure to comply with COVID-19 regulations and/or workplace prescripts',
    sanction: {
      firstOffence: matrixSanctionNames.DISMISSAL,
      secondOffence: matrixSanctionNames.NOT_APPLICABLE,
      thirdOffence: matrixSanctionNames.NOT_APPLICABLE,
      fourthOffence: matrixSanctionNames.NOT_APPLICABLE,
      fifthOffence: matrixSanctionNames.NOT_APPLICABLE,
      sixthOffence: matrixSanctionNames.NOT_APPLICABLE,
    },
  },
];

// temporary list of new transgressions.
// should be replaced any time there's a need to add new transgressions to the default code
// export const transgressionsToAdd = [
//   {
//     transgressionCategory: TransgressionCategory.COVID_19,
//     default: true,
//     active: true,
//     transgression: 'Failure to comply with COVID-19 regulations and/or workplace prescripts',
//     sanction: {
//       firstOffence: matrixSanctionNames.FINAL_WRITTEN_WARNING,
//       secondOffence: matrixSanctionNames.DISMISSAL,
//       thirdOffence: matrixSanctionNames.DISMISSAL,
//       fourthOffence: matrixSanctionNames.DISMISSAL,
//       fifthOffence: matrixSanctionNames.DISMISSAL,
//       sixthOffence: matrixSanctionNames.DISMISSAL,
//     },
//   },
//   {
//     transgressionCategory: TransgressionCategory.COVID_19,
//     default: true,
//     active: true,
//     transgression: 'Gross failure to comply with COVID-19 regulations and/or workplace prescripts',
//     sanction: {
//       firstOffence: matrixSanctionNames.DISMISSAL,
//       secondOffence: matrixSanctionNames.DISMISSAL,
//       thirdOffence: matrixSanctionNames.DISMISSAL,
//       fourthOffence: matrixSanctionNames.DISMISSAL,
//       fifthOffence: matrixSanctionNames.DISMISSAL,
//       sixthOffence: matrixSanctionNames.DISMISSAL,
//     },
//   },
// ];
