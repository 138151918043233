import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { Col, Row } from 'reactstrap';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { CaseType, UpdateOrganisationInput } from '../../../../../API';
import { get } from '../../../../../utils/graphql-utils';
import { getOrganisation } from '../../../../../graphql/queries';
import { useErrorHandler } from '../../../../../utils/notification-utils';
import { UserContext, UserContextProps } from '../../../../../App';
import { EmailType } from '../../../../../utils/email-utils';
import { InitialHearingConcludeHearingAdministrationForm } from './InitialHearingConcludeHearingAdministrationForm';
import { initial_hearing_concludeHearingAdministrationSchema } from '../../../../../forms/ValidationSchema/InitialHearingValidationSchema';
import { EmailPreviewModalv3 } from '../../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { AllSanctionOptions } from '../ConfirmOverallSanction/sanction-options';
import { convertToFlowableVariables } from '../../../../../utils/flowable/flowable-utils';

export const InitialHearingConcludeHearingAdministration: React.FC<WorkflowComponentProps> = props => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const { caseData } = props.data;
  const handleError = useErrorHandler();
  const [organisation, setOrganisation] = useState<UpdateOrganisationInput>();

  const fields = {
    initial_hearing_deliveredSanctionOutcomeLetter: 'boolean',
    initial_hearing_acceptedDemotion: 'string',
    initial_hearing_acceptedSuspensionWithoutPay: 'string',
    initial_hearing_chairpersonsRulingDocuments: 'array',
  };

  const init = getFormDataFromCaseData(fields, caseData);
  const isAcceptDemotionApplicable = props.data.caseData.overallSanction === AllSanctionOptions.DEMOTION;
  const isAcceptSuspensionWithoutPayApplicable =
    props.data.caseData.overallSanction === AllSanctionOptions.SUSPENSION_WITHOUT_PAY;
  const initialValues = {
    ...init,
    initial_hearing_deliveredSanctionOutcomeLetter: init.initial_hearing_deliveredSanctionOutcomeLetter,
    initial_hearing_acceptedDemotion: init.initial_hearing_acceptedDemotion
      ? init.initial_hearing_acceptedDemotion
      : isAcceptDemotionApplicable
      ? ''
      : 'NOT APPLICABLE',
    initial_hearing_acceptedSuspensionWithoutPay: init.initial_hearing_acceptedSuspensionWithoutPay
      ? init.initial_hearing_acceptedSuspensionWithoutPay
      : isAcceptSuspensionWithoutPayApplicable
      ? ''
      : 'NOT APPLICABLE',
  };

  const getFormValuesForSubmission = (values: FormikValues): Record<string, any> => {
    values.initial_hearing_chairpersonsRulingDocuments = JSON.stringify(
      values.initial_hearing_chairpersonsRulingDocuments,
    );
    return filterFormData(values, fields);
  };

  const submitValues = (values: FormikValues): void => {
    props.data.flowableFunctions.onNext(getFormValuesForSubmission(values), props.data.caseData.isAppeal);
  };

  const loadOrganisation = () => {
    get(getOrganisation, props.data.caseData.organisationId)
      .then(res => {
        const org = (res.data as any).getOrganisation;
        setOrganisation(org);
      })
      .catch(error => handleError(error));
  };

  // misconduct alts:
  // suspension without pay => dismissal
  // demotion letter => dismissal

  // summary dismissal: none
  // final written warning: none
  // written warning: none
  // dismissal with notice: none

  // const setValues = (values: FormikValues): => {
  //   const sanction = props.data.caseData.overallSanction;
  //   if (sanction === AllSanctionOptions.DEMOTION) {
  //     values.
  //   }
  // }

  const getSanctionType = (values: FormikValues): 'dismissal' | 'demotion' | 'suspension' | '' => {
    const sanction = props.data.caseData.isAppeal
      ? props.data.caseData.revisedOverallSanction
      : props.data.caseData.overallSanction;

    const acceptedDemotion = values.initial_hearing_acceptedDemotion;
    const acceptedSuspensionWithoutPay = values.initial_hearing_acceptedSuspensionWithoutPay;

    if (sanction.includes('DISMISSAL')) {
      return 'dismissal';
    } else if (sanction.includes('DEMOTION')) {
      if (acceptedDemotion === 'YES') {
        return 'demotion';
      } else {
        return 'dismissal';
      }
    } else if (sanction.includes('SUSPENSION')) {
      if (acceptedSuspensionWithoutPay === 'YES') {
        return 'suspension';
      } else {
        return 'dismissal';
      }
    } else {
      return '';
    }
  };

  const misconductEmailsMap: Record<string, Record<string, EmailType>> = {
    facilities: {
      dismissal: EmailType.NOTIFY_FACILITIES_DISMISSAL_MISCONDUCT,
      suspension: EmailType.NOTIFY_FACILITIES_SUSPENSION_WITHOUT_PAY,
    },
    payroll: {
      dismissal: EmailType.NOTIFY_PAYROLL_GENERIC_MISCONDUCT,
      demotion: EmailType.NOTIFY_PAYROLL_GENERIC_MISCONDUCT,
      suspension: EmailType.NOTIFY_PAYROLL_GENERIC_MISCONDUCT,
    },
    IT: {
      dismissal: EmailType.NOTIFY_IT_DISMISSAL_MISCONDUCT,
      suspension: EmailType.NOTIFY_IT_SUSPENSION_WITHOUT_PAY,
    },
  };

  const poorPerformanceEmailsMap: Record<string, Record<string, EmailType>> = {
    facilities: {
      dismissal: EmailType.NOTIFY_FACILITIES_DISMISSAL_POOR_PERFORMANCE,
      suspension: EmailType.NOTIFY_FACILITIES_SUSPENSION_WITHOUT_PAY,
    },
    payroll: {
      dismissal: EmailType.NOTIFY_PAYROLL_GENERIC_POOR_PERFORMANCE,
      demotion: EmailType.NOTIFY_PAYROLL_GENERIC_POOR_PERFORMANCE,
      suspension: EmailType.NOTIFY_PAYROLL_GENERIC_POOR_PERFORMANCE,
    },
    IT: {
      dismissal: EmailType.NOTIFY_IT_DISMISSAL_POOR_PERFORMANCE,
      suspension: EmailType.NOTIFY_IT_SUSPENSION_WITHOUT_PAY,
    },
  };

  const getEmailTypeV2 = (recipient: string, sanction: string): EmailType | null => {
    return props.data.caseType === CaseType.POOR_PERFORMANCE
      ? poorPerformanceEmailsMap[recipient][sanction]
      : misconductEmailsMap[recipient][sanction] || null;
  };

  useEffect(() => {
    if (caseData) {
      loadOrganisation();
    }
  }, [caseData]);

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Conclude Hearing Administration</h4>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submitValues}
        validationSchema={initial_hearing_concludeHearingAdministrationSchema}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <InitialHearingConcludeHearingAdministrationForm data={props.data} />
            <Row>
              <Col className="mt-3">
                <EmailPreviewModalv3
                  buttonText={'Notify Payroll'}
                  disabled={
                    !(organisation && organisation.payrollEmail) || !getEmailTypeV2('payroll', getSanctionType(values))
                  }
                  formValues={values}
                  emailType={getEmailTypeV2('payroll', getSanctionType(values))}
                  masterProcessInstanceId={props.data.masterProcessInstanceId}
                  processInstanceId={props.data.processInstanceId}
                  currentUserId={currentUser?.id}
                  getFlowableVariables={() => convertToFlowableVariables(getFormValuesForSubmission(values))}
                />
                <EmailPreviewModalv3
                  buttonText={'Notify Facilities'}
                  disabled={
                    !(organisation && organisation.facilitiesEmail) ||
                    !getEmailTypeV2('facilities', getSanctionType(values))
                  }
                  formValues={values}
                  emailType={getEmailTypeV2('facilities', getSanctionType(values))}
                  masterProcessInstanceId={props.data.masterProcessInstanceId}
                  processInstanceId={props.data.processInstanceId}
                  currentUserId={currentUser?.id}
                  getFlowableVariables={() => convertToFlowableVariables(getFormValuesForSubmission(values))}
                />
                <EmailPreviewModalv3
                  buttonText={'Notify I.T.'}
                  disabled={!(organisation && organisation.itEmail) || !getEmailTypeV2('IT', getSanctionType(values))}
                  formValues={values}
                  emailType={getEmailTypeV2('IT', getSanctionType(values))}
                  masterProcessInstanceId={props.data.masterProcessInstanceId}
                  processInstanceId={props.data.processInstanceId}
                  currentUserId={currentUser?.id}
                  getFlowableVariables={() => convertToFlowableVariables(getFormValuesForSubmission(values))}
                />
              </Col>
            </Row>
            <WorkFlowFooter
              data={props.data}
              finalPage={props.data.caseData.isAppeal}
              onNext={handleSubmit}
              onCancel={() => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
