import React, { ChangeEvent, useEffect, useState } from 'react';
import { useErrorHandler } from '../../utils/notification-utils';
import { Storage } from 'aws-amplify';
import './DocumentVersionControlTable.styles.scss';
import { mutate } from '../../utils/graphql-utils';
import { UserDetails } from '../../App';
import { updateDocumentFile } from '../../graphql-custom/custom-mutations';
import { cleanFileName } from '../../utils/storage-utils';
interface SingleDocumentUploaderProps {
  processInstanceId: string;
  iconSize?: { height: string; width: string };
  alreadyDocument: boolean;
  documentId: string;
  currentUser: UserDetails;
  callback: (documentId: string) => void;
}

export const TableUploader: React.FC<SingleDocumentUploaderProps> = (props: SingleDocumentUploaderProps) => {
  const { processInstanceId } = props;
  const [file, setFile] = useState<File | null>();
  const [isUploading, setIsUploading] = useState(false);
  const handleError = useErrorHandler();

  const uploadFile = async (): Promise<void> => {
    if (file) {
      setIsUploading(true);
      const cleanedFileName = cleanFileName(file.name);
      await Storage.put(`cases/${processInstanceId}/${cleanedFileName}`, file, {
        level: 'public',
      })
        .then((response: Record<string, any>) => {
          // completeDocumentTask(props.processInstanceId, props.documentId, 'upload-signed-document', [
          //   { name: 'documentDeliveryMethod', value: DocumentDeliveryMethod.NOMINATE_EMPLOYEE_TO_DELIVER },
          // ])
          //   .then(() => {
          mutate(updateDocumentFile, { id: props.documentId, uploadedFileBucketPath: response.key })
            .then(() => {
              props.callback(props.documentId);
            })
            .catch(error => handleError(error));
          // })
        })
        .catch(error => handleError(error))
        .finally(() => setIsUploading(false));
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileItem = event.target.files;
    if (fileItem) {
      setFile(fileItem[0]);
    }
  };

  useEffect(() => {
    uploadFile().catch(error => handleError(error));
  }, [file]);

  return (
    <>
      <label>
        {/*<span className="btn upload-signed-document-button">*/}
        <span className="btn upload-signed-document-button">
          {' '}
          {isUploading ? <i className="spinner-border spinner-border-sm" /> : 'UPLOAD SIGNED DOCUMENT'}
        </span>
        {/*</span>*/}
        <input style={{ display: 'none' }} type={'file'} multiple={false} onChange={handleFileChange} />
      </label>
    </>
  );
};
