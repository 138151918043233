import React, { useCallback, useEffect, useState } from 'react';
import './App.styles.scss';
import { Router } from './Router';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { API, Auth } from 'aws-amplify';
import { useErrorHandler } from './utils/notification-utils';
import { onCreateNotification, onUpdateNotification } from './graphql/subscriptions';
import { CreateNotificationInput, UserRole } from './API';
import { list } from './utils/graphql-utils';
import { listNotifications } from './graphql/queries';
import { FontPreference, User, PhysicalAddress, JobTitle } from './models';
import { listUsersBasic } from './graphql-custom/custom-queries';
import { getUserById } from './utils/user-utils';
import { notEmpty } from './utils/typescript-utils';

Auth.configure({
  authenticationFlowType: 'CUSTOM_AUTH',
});
// sets theme for whole site.
document.body.classList.toggle('white-content');

interface AppState {
  currentUser: UserDetails | null;
  userNotifications: CreateNotificationInput[];
  sidebarOpened: boolean;
}

export interface UserContextProps {
  loading: boolean;
  currentUser: UserDetails | null;
  userNotifications: CreateNotificationInput[];
  loginCallBack: (user: CognitoUser) => void;
  logoutCallBack: () => void;
  impersonateUser: (impersonatorUserId: string, impersonateeUserId: string) => Promise<void>;
  unimpersonate: () => Promise<void>;
}

export interface UserDetails {
  id: string;
  email: string;
  organisationId: string | null;
  userRoles: UserRole[] | null;
  firstName: string | null;
  lastName: string | null;
  jobTitle: JobTitle | null;
  employeeId: string | null;
  employeeNumber: string | null;
  departmentId: string | null;
  departmentName: string | null;
  fontPreference: string | null;
  physicalAddresses: PhysicalAddress[];
  impersonatorUserId?: string | null;
}

export const UserContext = React.createContext<Partial<UserContextProps>>({});
export const userRolesOrderOfPriority: string[] = [
  'SUPER_ADMIN',
  'COMPANY_ADMIN',
  'HR_MANAGER',
  'LINE_EXECUTIVE',
  'LINE_MANAGER',
  'CASE_USER',
];
export const getHighestUserTypeFromRoles = (user: UserDetails): string | undefined => {
  for (let i = 0; i < userRolesOrderOfPriority.length; i++) {
    const userType: string = userRolesOrderOfPriority[i];
    if ((user.userRoles as string[]).includes(userType)) {
      return userType;
    }
  }
};
const App: React.FC = () => {
  const [state, setState] = useState<AppState>({
    currentUser: null,
    sidebarOpened: true,
    userNotifications: [],
  });
  // const appBody = useRef(document.body);

  const [loadingUser, setLoadingUser] = useState<boolean>(true);

  const handleError = useErrorHandler();

  const loadUser = (cognitoSub: string): Promise<User> => {
    return new Promise((resolve, reject) => {
      const variables = { filter: { cognitoSub: { eq: cognitoSub } }, limit: 10000 };
      list(listUsersBasic, variables)
        .then(res => {
          if (res.data && (res.data as any).listUsers?.items && (res.data as any).listUsers.items.length) {
            resolve((res.data as any).listUsers.items[0]);
          } else reject(new Error('No user data on graphql response'));
        })
        .catch(error => reject(error));
    });
  };

  const processCognitoUser = (attributes: CognitoUserAttribute[], cognitoSub: string): any => {
    const user: any = {
      cognitoSub: cognitoSub,
    };
    attributes.forEach(attribute => {
      if (attribute.getName() === 'email') user.email = attribute.getValue();
    });
    return user;
  };

  const isValid = (user: any): boolean => {
    return !!user.cognitoSub && !!user.email;
  };

  const getNotifications = useCallback(async (userId: string): Promise<void> => {
    const res = await list(listNotifications, { filter: { userID: { eq: userId } } });
    if (res.data && (res.data as any).listNotifications) {
      const data: CreateNotificationInput[] = (res.data as { [key: string]: any }).listNotifications.items;
      const notifications = data.sort((a, b) => b.lastModifiedDate.localeCompare(a.lastModifiedDate));
      setState(oldState => ({ ...oldState, userNotifications: notifications }));
    }
  }, []);

  const subscribeToNotifications = useCallback((userId: string): void => {
    const subscription = API.graphql({
      query: onCreateNotification,
    });
    subscription.subscribe({
      next: (payload: { [key: string]: any }) => getNotifications(userId),
    });
    const updateSubscription = API.graphql({
      query: onUpdateNotification,
    });
    updateSubscription.subscribe({
      next: (payload: { [key: string]: any }) => getNotifications(userId),
    });
  }, [getNotifications]);

  //@ts-ignore
  const logoutCallBack = (idle): void => {
    Auth.signOut()
      .then(() => {
        setState(oldState => ({ ...oldState, currentUser: null }));
        if (idle && state.currentUser) {
          setTimeout(() => {
            alert('You have been idle for a time and automatically logged out');

            window.location.reload();
          }, 1500);
        }
      })
      .catch(err => console.log(err));
  };

  const loadData = useCallback(
    async (cognitoUser: CognitoUser): Promise<UserDetails | null> => {
      return new Promise<UserDetails | null>((resolve, reject) => {
        cognitoUser.getUserAttributes(async (error, attributes) => {
          if (attributes) {
            const user = processCognitoUser(attributes, cognitoUser.getUsername());
            if (isValid(user)) {
              await loadUser(user.cognitoSub)
                .then((dbUser: User) => {
                  // const userType = getHighestUserTypeFromRoles(dbUser);
                  const userDetails: UserDetails = {
                    firstName: dbUser.firstName,
                    lastName: dbUser.lastName,
                    id: dbUser.id,
                    email: user.email ? user.email : '',
                    userRoles: dbUser.roles as UserRole[],
                    organisationId: dbUser.organisationId ? dbUser.organisationId : null,
                    employeeId: dbUser.employee ? dbUser.employee.id : null,
                    employeeNumber: dbUser.employee ? dbUser.employee.employeeNumber : null,
                    departmentId: dbUser.employee?.department?.id ? dbUser.employee?.department?.id : null,
                    departmentName: dbUser.employee?.department?.name ? dbUser.employee?.department?.name : null,
                    jobTitle: dbUser.employee?.jobTitle ? dbUser.employee?.jobTitle : null,
                    physicalAddresses: dbUser.employee?.physicalAddresses
                      ? dbUser.employee?.physicalAddresses.filter(notEmpty)
                      : [],
                    fontPreference: dbUser.fontPreference ? dbUser.fontPreference : FontPreference.DEFAULT,
                  };
                  resolve(userDetails);
                })
                .catch(error => handleError(error));
            } else {
              const error = new Error('Error: invalid user details');
              handleError(error);
              reject(null);
            }
          } else {
            const error = new Error('Error: could not retrieve user attributes');
            handleError(error);
            reject(null);
          }
        });
      });
    },
    [handleError],
  );

  const unimpersonate = async (): Promise<void> => {
    if (state.currentUser?.impersonatorUserId) {
      const dbUser = await getUserById(state.currentUser.impersonatorUserId);
      if (dbUser) {
        const userDetails: UserDetails = {
          firstName: dbUser.firstName,
          lastName: dbUser.lastName,
          id: dbUser.id,
          //TODO: during a normal login, the cognito email is used, but during an impersonation, the dynamoDB email is used
          email: dbUser.emailAddress ? dbUser.emailAddress : '',
          userRoles: dbUser.roles as UserRole[],
          organisationId: dbUser.organisationId ? dbUser.organisationId : null,
          employeeId: dbUser.employee ? dbUser.employee.id : null,
          employeeNumber: dbUser.employee ? dbUser.employee.employeeNumber : null,
          departmentId: dbUser.employee?.department?.id ? dbUser.employee?.department?.id : null,
          departmentName: dbUser.employee?.department?.name ? dbUser.employee?.department?.name : null,
          jobTitle: dbUser.employee?.jobTitle ? dbUser.employee?.jobTitle : null,
          physicalAddresses: dbUser.employee?.physicalAddresses
            ? dbUser.employee?.physicalAddresses.filter(notEmpty)
            : [],
          fontPreference: dbUser.fontPreference ? dbUser.fontPreference : FontPreference.DEFAULT,
          impersonatorUserId: null,
        };
        setState(oldState => ({ ...oldState, currentUser: userDetails }));
        sessionStorage.setItem('loggedInUser', JSON.stringify(userDetails));
      }
    }
  };

  const impersonateUser = async (impersonatorUserId: string, impersonateeUserId: string): Promise<void> => {
    const dbUser = await getUserById(impersonateeUserId);
    if (dbUser) {
      const userDetails: UserDetails = {
        firstName: dbUser.firstName,
        lastName: dbUser.lastName,
        id: dbUser.id,
        //TODO: during a normal login, the cognito email is used, but during an impersonation, the dynamoDB email is used
        email: dbUser.emailAddress ? dbUser.emailAddress : '',
        userRoles: dbUser.roles as UserRole[],
        organisationId: dbUser.organisationId ? dbUser.organisationId : null,
        employeeId: dbUser.employee ? dbUser.employee.id : null,
        employeeNumber: dbUser.employee ? dbUser.employee.employeeNumber : null,
        departmentId: dbUser.employee?.department?.id ? dbUser.employee?.department?.id : null,
        departmentName: dbUser.employee?.department?.name ? dbUser.employee?.department?.name : null,
        jobTitle: dbUser.employee?.jobTitle ? dbUser.employee?.jobTitle : null,
        physicalAddresses: dbUser.employee?.physicalAddresses
          ? dbUser.employee?.physicalAddresses.filter(notEmpty)
          : [],
        fontPreference: dbUser.fontPreference ? dbUser.fontPreference : FontPreference.DEFAULT,
        impersonatorUserId: impersonatorUserId,
      };
      setState(oldState => ({ ...oldState, currentUser: userDetails }));
      sessionStorage.setItem('loggedInUser', JSON.stringify(userDetails));
    }
  };

  const loginCallBack = async (cognitoUser: CognitoUser): Promise<void> => {
    //@ts-ignore
    if (cognitoUser.challengeName === 'CUSTOM_CHALLENGE') {
      return;
    }
    await loadData(cognitoUser).then(user => {
      if (user) {
        setState(oldState => ({ ...oldState, currentUser: user }));
        sessionStorage.setItem('loggedInUser', JSON.stringify(user));
      }
    });
  };

  const reloadUser = useCallback(
    async (sessionUser: UserDetails): Promise<void> => {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      if (sessionUser.impersonatorUserId) {
        await impersonateUser(sessionUser.impersonatorUserId, sessionUser.id);
      } else {
        const dbUser = await loadData(cognitoUser);
        if (dbUser) {
          setState(oldState => ({ ...oldState, currentUser: dbUser }));
        } else {
          throw new Error('Unable to load user');
        }
      }
    },
    [loadData],
  );

  useEffect(() => {
    setLoadingUser(true);
    const sessionUserString = sessionStorage.getItem('loggedInUser');
    if (sessionUserString) {
      reloadUser(JSON.parse(sessionUserString))
        .then(() => setLoadingUser(false))
        .catch(error => {
          handleError(error);
          setLoadingUser(false);
        });
    } else {
      setLoadingUser(false);
    }
  }, [handleError, reloadUser]);

  useEffect(() => {
    window.addEventListener('unload', sessionStorage.clear);
    return (): void => {
      window.removeEventListener('unload', sessionStorage.clear);
    };
  }, []);

  useEffect(() => {
    if (state.currentUser?.id) {
      getNotifications(state.currentUser.id);
      subscribeToNotifications(state.currentUser.id);
    }
  }, [state.currentUser && state.currentUser.id, subscribeToNotifications, getNotifications]);

  return (
    <UserContext.Provider
      value={{
        loading: loadingUser,
        currentUser: state.currentUser,
        userNotifications: state.userNotifications,
        loginCallBack: loginCallBack,
        //@ts-ignore
        logoutCallBack: logoutCallBack,
        impersonateUser: impersonateUser,
        unimpersonate: unimpersonate,
      }}
    >
      <Router />;
    </UserContext.Provider>
  );
};

export default App;
