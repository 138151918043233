import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import InitiatePIPForm from './InitiatePIPForm';
import { WorkFlowFooter } from '../../../../components/workflowFooter/WorkFlowFooter';
import { InitiatePipSchema } from '../../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import GuidanceModal from '../../../../components/guidanceModals/GuidanceModal.component';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../WorkflowContainer/workflow-utils';
import { DocumentVersionControlTable } from '../../../../components/documentVersionControlTable/DocumentVersionControlTable';
import { documentConfigs } from '../../../../configs/document-configs/document-configs';
import { pipDocumentTypeConfig } from '../pip-config';

const InitiatePIP: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const documentType = pipDocumentTypeConfig[props.data.caseData.pipType].draftVersion;
  const config = documentConfigs[documentType];
  const { caseData, flowableFunctions } = props.data;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const fields = {
    pipDiscussionDate: 'string',
    pipDiscussionTime: 'string',
    pipDiscussionLocation: 'string',
    compilePipDiscussionInvite: 'boolean',
    pipDiscussionAttendees: 'array',
    performanceShortfalls: 'array',
    pipStartDate: 'string',
    pipEndDate: 'string',
  };
  const initialValues = getFormDataFromCaseData(fields, caseData, caseData.isAppeal);
  const [data, setData] = useState(initialValues);

  const getFormValuesForSubmission = (values: FormikValues): Record<string, any> => {
    const formValues = { ...values };
    formValues.pipDiscussionAttendees = JSON.stringify(formValues.pipDiscussionAttendees);
    formValues.performanceShortfalls = JSON.stringify(formValues.performanceShortfalls);
    return filterFormData(formValues, fields);
  };

  const submitValues = async (values: FormikValues): Promise<void> => {
    flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  useEffect(() => {
    if (!data.performanceShortfalls) {
      const shortfalls = [
        {
          shortfallDescription: '',
          date: '',
          performanceGoal: '',
          qualityStandard: '',
          deadline: '',
          assistanceRequiredFromEmployer: '',
          actionsToBeTakenByEmployee: '',
        },
      ];
      setData(data => ({ ...data, performanceShortfalls: shortfalls }));
    } else {
      const shortfalls = data.performanceShortfalls.map((shortfall: { [key: string]: any }) => {
        return {
          ...shortfall,
          performanceGoal: shortfall.performanceGoal ? shortfall.performanceGoal : '',
          qualityStandard: shortfall.qualityStandard ? shortfall.qualityStandard : '',
          deadline: shortfall.deadline ? shortfall.deadline : '',
          assistanceRequiredFromEmployer: shortfall.assistanceRequiredFromEmployer
            ? shortfall.assistanceRequiredFromEmployer
            : '',
          actionsToBeTakenByEmployee: shortfall.actionsToBeTakenByEmployee ? shortfall.actionsToBeTakenByEmployee : '',
        };
      });
      setData(data => ({ ...data, performanceShortfalls: shortfalls }));
    }
  }, [JSON.stringify(data)]);

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">
        {'Initiate' + ' ' + config.name}
        <GuidanceModal
          buttonTitle="Performance improvement plan"
          title="Guidance: Performance improvement plan"
          isOpen={modalIsOpen}
          setModal={setModalIsOpen}
        >
          <>
            <p>
              <div className="font-weight-bold font-italic text-dark">
                What is the purpose of a Performance Improvement Plan (PIP)?
              </div>
            </p>
            <p>
              <span className="text-muted">
                A PIP is intended to address perceived performance shortfalls in a structured, corrective manner. It
                should clearly and transparently outline performance goals/expectations, what remedial steps the
                employee will implement and what assistance is required from the employer. A performance improvement
                period is set. When implementing a PIP, the objective is to assist the employee in overcoming
                performance difficulties, and in so doing enable the employer to operate effectively and meet its
                business objectives. LabourTeq provides a template of what a PIP should typically include.
              </span>{' '}
            </p>
            <br />
            <p>
              <div className="font-weight-bold font-italic text-dark">
                What process should be followed when implementing a PIP?
              </div>

              <ul className="px-3">
                <li className="text-muted">• Prepare a draft PIP;</li>
                <li className="text-muted">• Discuss the draft PIP with the employee and obtain his/her inputs;</li>
                <li className="text-muted">
                  • Obtain confirmation from the employee that he/she understands the content and give him/her an
                  opportunity to respond to the proposed content;
                </li>
                <li className="text-muted">
                  • Listen to factors he/she may raise impacting on his/her performance and respond appropriately;
                </li>
                <li className="text-muted">• Ask what assistance, if any, is required from you;</li>
                <li className="text-muted">
                  • Indicate areas of concerns citing specific examples of performance shortfalls;
                </li>
                <li className="text-muted">
                  • Focus on the poor performance, not personalities or other extraneous issues;
                </li>
                <li className="text-muted">
                  • Ask him/her what he/she thinks the reasons are for the shortfalls and contributing factors;
                </li>
                <li className="text-muted">• Document the employee’s responses;</li>
                <li className="text-muted">
                  • Decide on an appropriate performance improvement period i.e. the total PIP duration;
                </li>
                <li className="text-muted">• Confirm when/how often review meetings to assess progress will happen;</li>
                <li className="text-muted">• Finalise the PIP and send a copy to the employee;</li>
                <li className="text-muted">• Agree and sign off formally on the content;</li>
                <li className="text-muted">
                  • If the employee refuses to sign in agreement, it’s good practice to check that the content is
                  reasonable and fair. Obtain input from HR or another objective source and revise, if needed.
                  Communicate to the employee that the PIP will be implemented irrespective as it remains the employer’s
                  prerogative to address perceived performance shortfalls;
                </li>
                <li className="text-muted">
                  • Implement the measures set out in the PIP and monitor progress during the review period(s);
                </li>
                <li className="text-muted">
                  • Ensure that any assistance that you committed to, is provided timeously;
                </li>
                <li className="text-muted">• Provide a fair opportunity for the employee to improve;</li>
                <li className="text-muted">
                  • Follow up. If the employee improves, communicate this to him/her and acknowledge progress;
                </li>
                <li className="text-muted">
                  • If he/she is still struggling, counsel and re-assess whether the PIP measures are being implemented
                  and whether additional remedial actions are required;
                </li>
                <li className="text-muted">
                  • Clearly communicate the possible consequences of continued failure to meet the performance standards
                  and goals set;
                </li>
                <li className="text-muted">
                  • The process is confidential (share on a needs-only basis) and should not be discussed with
                  colleagues unless their assistance is required when collaborating with the employee on their
                  deliverables or when input is needed on the employee’s progress;
                </li>
                <li className="text-muted">• Escalate matter(s) to HR or Executive management, as required;</li>{' '}
                <li className="text-muted">
                  • LabourTeq allows you to record all documentation and progress discussions held, to ensure proper
                  documentation of your incapacity process;
                </li>{' '}
                <li className="text-muted">
                  • Meet at the predetermined times to share feedback and measure progress;
                </li>{' '}
                <li className="text-muted">
                  • Meet shortly before the end of the PIP period to assess overall achievement and decide on an
                  appropriate way forward;
                </li>{' '}
                <li className="text-muted">
                  • Allow a colleague or union representative (if applicable) to assist the employee during these
                  meetings should he/she prefer. Although the purpose of a PIP process is to correct poor performance
                  and not to punish, it is foreseeable that serious consequences could follow if poor performance were
                  to continue. Allowing the employee representation speaks to the procedural fairness of your process.
                </li>
              </ul>
            </p>
            <br />
            <p>
              <div className="font-weight-bold font-italic text-dark">What is the typical duration of a PIP?</div>

              <span className="text-muted">
                There is no legal prescript determining the duration of a PIP. Duration depends on the profile of the
                employee and the nature of the goals set. Typically the more junior and inexperienced the employee, the
                longer the PIP period and the more interim review meetings. The period is designed to give the employee
                an opportunity to raise his/her performance to the necessary level. It therefore varies. It could mirror
                timelines set for certain Key Performance deliverables, for example. On the other hand, the PIP should
                also not continue for months on end without discernible progress. Take into account any periods of
                approved leave and/or office closures.
              </span>
              <span className="text-muted">
                If the employee fails or fails to sufficiently and consistently improve his/her performance to the
                necessary level by the end of the PIP period, further action is warranted.
              </span>
            </p>
            <br />
            <p>
              <p>
                <div className="font-weight-bold font-italic text-dark">
                  How should I set performance objectives in a PIP?
                </div>
              </p>
              <span className="text-muted">
                Managers sometimes struggle with how to set performance objectives in a PIP. There is no singular way to
                do this. It is useful to bear the following in mind:
              </span>
              <ul className="px-3">
                <li className="text-muted">
                  • Set SMART objectives - specific, measurable, achievable, realistic and time-based.
                </li>
                <li className="text-muted">
                  • Set objectives that are commensurate with the role and level of the employee. Don’t set objectives
                  that are too difficult or too easy to achieve. In both scenarios, at the end of the PIP you will
                  struggle to assess whether the employee is in fact capable of performing the role. Setting objectives
                  at standards above the employee’s current level may also be seen as an unfair action.
                </li>
                <li className="text-muted">
                  • If applicable, qualitative objectives usually related to soft skills such as communication skills,
                  teamwork etc., must be included and relevant measurement criteria set (e.g. client feedback, peer
                  feedback)
                </li>
              </ul>
              <p>
                <span className="text-muted">
                  Managers often benefit from using a trusted fellow manager as a sounding board to help craft PIP
                  objectives.
                </span>
              </p>
            </p>
            <br />
            <p>
              <div className="font-weight-bold font-italic text-dark">What happens at the end of the PIP period?</div>

              <span className="text-muted">
                Deciding on what to do at the conclusion of the PIP period requires a final assessment. If the employee
                has achieved acceptable levels of performance, inform him/her and close the case on LabourTeq. If not,
                determine a way forward. This may include an extended PIP (for example, if noticeable improvement has
                already occurred, but there is still a need for consistency or other improvements), agreeing a demotion
                with the employee if operationally viable, or escalating the matter to an Incapacity poor performance
                hearing (for example, where little or no progress has occurred or improvements have not been sustained).
              </span>
            </p>
            <br />
            <p>
              <p>
                <div className="font-weight-bold font-italic text-dark">
                  Must I wait until the end of the PIP period before taking further measures?
                </div>
              </p>
              <span className="text-muted">
                Typically you will see through the performance improvement periods set out in the PIP. However,
                circumstances may arise where you need to act more decisively whilst still in this period, for example,
                where a particularly serious incident of poor performance occurred and/or where poor performance
                resulted in a highly undesirable situation or monetary loss for the employer. You may then escalate the
                matter to an incapacity hearing.
              </span>
            </p>
            <br />
            <p>
              <div className="font-weight-bold font-italic text-dark">
                What is appropriate to offer by way of assistance to the employee?
              </div>

              <span className="text-muted">
                This varies based on the facts of the situation. Consistency in addressing the same or similar
                shortfalls in different employees’ performance must be considered. An employee may, for example, be:
              </span>
              <ul className="px-3">
                <li className="text-muted">• Given a checklist of standing procedures;</li>
                <li className="text-muted">• Paired with another employee for a period of time;</li>
                <li className="text-muted">• Offered formal training;</li>
                <li className="text-muted">• Offered formal coaching or mentoring;</li>
                <li className="text-muted">• Given closer supervision.</li>
              </ul>
            </p>
            <p>
              <span className="text-muted">
                Not every employee will require every type of assistance. Once assistance is identified or offered, be
                sure to follow through on those actions. Often the employer is partly to blame at the end of the PIP
                period, because the required assistance was not put in place timeously, or at all.
              </span>
            </p>
            <br />
            <p>
              <div className="font-weight-bold font-italic text-dark">
                What role does the Employee Assistance Programme (EAP) play?
              </div>
              <span className="text-muted">
                At times, you may need the support of the Employee Assistance Programme (EAP), if available in your
                workplace, to assist the employee to deal with a range of issues, including personal circumstances or
                mental health conditions. Make sure that you understand the available services and how to refer an
                employee. The EAP can help to address personal circumstances impacting on the employee’s work.
                Ultimately, however, the employee remains responsible for utilising the EAP and meeting the employer’s
                performance standards and expectations.
              </span>
            </p>
            <br />
            <p>
              <div className="font-weight-bold font-italic text-dark">
                If the employee applies for annual leave during the PIP period, must it be granted?
              </div>

              <span className="text-muted">
                Leave is subject to the employer’s approval and operational requirements. Depending on the
                circumstances, the application could be denied based on the importance of focussing on improving
                performance in the time period allocated. Also consider the impact of any leave granted on deadlines
                that have been set in the PIP. Taking these factors into account, leave can be granted during a PIP
                period. In this case, if necessary, you may need to extend the PIP timelines accordingly.
              </span>
            </p>
            <p>
              <span className="text-muted">
                A need for extended periods of sick leave may be indicative of a medical condition potentially impacting
                on performance. You may need to consider medical incapacity depending on the situation.
              </span>
            </p>
            <br />
            <p>
              <div className="font-weight-bold font-italic text-dark">
                Why should I keep documenting the process and discussions held?
              </div>

              <span className="text-muted">
                The procedural success or failure of your process is dependent on all meetings, evaluations,
                communications{' '}
                <strong>
                  <i>being documented.</i>
                </strong>
                {/* eslint-disable-next-line no-irregular-whitespace */}
                 Failure to do so, will create weaknesses in your incapacity procedure and gaps in any defence put
                forward by the employer should the employee decide to ultimately challenge a perceived unfair dismissal
                at the CCMA or applicable bargaining council dispute resolution body. LabourTeq provides you with the
                necessary tool to conveniently and speedily record all PIP and progress meetings.
              </span>
            </p>{' '}
            <br />
            <p>
              <div className="font-weight-bold font-italic text-dark">What happens if the employee fails a PIP?</div>

              <span className="text-muted">
                As a manager, you have the responsibility to progress a formal incapacity process if the employee’s
                performance does not improve within the PIP period. This will depend on what is reasonable and fair in
                the circumstances taking into account factors such as the seriousness and extent of the shortfalls, the
                impact or potential impact of the shortfalls, the seniority of the employee and how he/she is reacting
                and taking accountability for his/her actions. You should not unduly delay progressing the matter and
                allow poor performance to continue unabated. Escalate the matter to an incapacity hearing and argue for
                possible termination of the employee’s services.
              </span>
            </p>{' '}
            <br />
            <p>
              <div className="font-weight-bold font-italic text-dark">
                What should I do about an employee who is uncooperative during the PIP process?
              </div>

              <span className="text-muted">
                The employee remains responsible for his/her performance and for improving it. An employee who fails to
                participate in the PIP process, will need to accept the consequences of his/her unwillingness to engage
                in the process. Ensure that you communicate the potential consequences of the unwillingness to engage.
                Document all your efforts to communicate with the employee and escalate the situation to HR or Executive
                management timeously if need be. Continue your process in a fair manner.
              </span>
            </p>{' '}
            <br />
            <p>
              <div className="font-weight-bold font-italic text-dark">
                What if the employee does not want to sign in confirmation of the PIP content, or at minimum, that
                he/she has received a copy thereof?
              </div>

              <span className="text-muted">
                The goal is that the parties agree on the PIP content. This is why it is generally good practice to
                prepare a draft PIP and discuss the content with the employee, obtaining their input, before finalising
                it.
              </span>
            </p>
            <p>
              <span className="text-muted">
                However, it remains your prerogative as employer to determine the way forward in dealing with
                allegations of incapacity poor performance. If no agreement is reached, you can merely request the
                employee to sign in acknowledgement of receipt. This does not mean that he/she necessarily agrees with
                the content of the PIP. Explain this to the employee. However, it is within his/her right to refuse to
                sign. In this instance hand the PIP to the employee in the presence of a witness. The latter then signs
                the document indicating that it was handed to the employee in the presence of the witness. Place the
                document on the employee’s record. The employee’s signature is not required for the PIP to be valid.
              </span>
            </p>
            <p>
              <span className="text-muted">
                You must be comfortable that the content is justified, defendable and reasonable should a dispute arise
                in future. It is the employer’s prerogative to expect achievement of reasonable performance expectations
                as set out in the PIP. The level of performance required must be relevant to the employee’s role and
                level, must be reasonable and attainable, and must be made known to the employee.
              </span>
            </p>
          </>
        </GuidanceModal>
      </h4>
      <Formik initialValues={data} onSubmit={submitValues} validationSchema={InitiatePipSchema} enableReinitialize>
        {({ values, errors, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
          <>
            <h5 className="text-default font-weight-bold">Invite Employee to Discuss Draft PIP</h5>
            <InitiatePIPForm
              employeeFullName={props.data.employeeFullName}
              employeeNumber={props.data.employeeNumber}
              caseData={caseData}
              processInstanceId={props.data.processInstanceId}
              employeeId={props.data.employeeNumber}
              employeeEmailAddress={props.data.employeeWorkEmailAddresses[0]}
              data={props.data}
              getFormValuesForSubmission={getFormValuesForSubmission}
              pipDocumentName={config.name}
            />
            <button
              className="btn prepare-letter-button mb-3"
              onClick={(): void => {
                props.data.flowableFunctions.onOpenNewDocument(values, fields, documentType);
              }}
            >
              {'PREPARE DRAFT' + ' ' + config.name.toUpperCase()}
            </button>
            {props.data.processInstanceId ? (
              <DocumentVersionControlTable
                data={props.data}
                processInstanceId={props.data.processInstanceId}
                templateType={documentType}
                completeTask={props.data.flowableFunctions.completeTask}
              />
            ) : null}
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => flowableFunctions.onSaveAndClose(values)}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default InitiatePIP;
