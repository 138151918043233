import React, { ReactElement, useState } from 'react';
import { Formik, FormikProps, FormikErrors, FormikValues, getIn } from 'formik';
import GuidanceModal from '../../../../components/guidanceModals/GuidanceModal.component';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../WorkflowContainer/workflow-utils';
import ExecuteDisciplinaryActionForm from './ExecuteDisciplinaryActionForm';
import { WorkFlowFooter } from '../../../../components/workflowFooter/WorkFlowFooter';
import { DocumentVersionControlTable } from '../../../../components/documentVersionControlTable/DocumentVersionControlTable';
import { TemplateType } from '../../../../API';
import { ExecuteDisciplinaryActionSchema } from '../../../../forms/ValidationSchema/DiscussionValidationSchema';

const ExecuteDisciplinaryAction: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const fields = {
    warningValidityPeriod: 'string',
    warningExpiryDate: 'string',
    disciplinaryDiscussionTime: 'string',
    disciplinaryDiscussionDate: 'string',
    incidents: 'array',
  };

  const initialValues: FormikValues = getFormDataFromCaseData(fields, caseData);

  const getWarningLetterDescription = (warningType: TemplateType): string => {
    switch (warningType) {
      case TemplateType.WRITTEN_WARNING_DISCUSSION:
        return 'Written Warning';
      case TemplateType.FINAL_WRITTEN_WARNING_DISCUSSION:
        return 'Final Written Warning';
      case TemplateType.VERBAL_WARNING_DISCUSSION:
        return 'Verbal Warning';
      default:
        return 'Warning';
    }
  };

  const getWarningType = (): TemplateType => {
    //Todo: fix this
    const decision = caseData.disciplinaryDiscussionDecision;
    if (decision === 'WRITTEN WARNING' || decision === 'WRITTEN_WARNING') {
      return TemplateType.WRITTEN_WARNING_DISCUSSION;
    } else if (decision === 'FINAL WRITTEN WARNING' || decision === 'FINAL_WRITTEN_WARNING')
      return TemplateType.FINAL_WRITTEN_WARNING_DISCUSSION;
    else return TemplateType.VERBAL_WARNING_DISCUSSION;
  };

  const text = getWarningLetterDescription(getWarningType());
  const submitValues = (values: FormikValues): void => {
    const formValues = { ...values };
    formValues.incidents = JSON.stringify(formValues.incidents);
    flowableFunctions.onNext(filterFormData(formValues, fields), true);
  };

  const setErrors = (
    errors: FormikErrors<FormikValues>,
    setFieldTouched: (field: string, touched: boolean) => void,
  ): void => {
    const errorKeys = Object.keys(errors);
    errorKeys.forEach(field => {
      //if fieldArray
      if (Array.isArray(errors[field])) {
        const err = getIn(errors, field);
        const innerArr = Object.keys(err);
        innerArr.forEach((item, index) => {
          let fieldNames;
          if (err[item]) {
            fieldNames = Object.keys(err[item]);
            fieldNames.forEach(name => setFieldTouched(`${field}.${index}.${name}`, true));
          }
        });
      } else {
        setFieldTouched(field, true);
      }
    });
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Execute Disciplinary Action - Prepare {text}</h4>
      <GuidanceModal
        isOpen={modalIsOpen}
        setModal={setModalIsOpen}
        buttonTitle={text}
        title={'Guidance: ' + text.toUpperCase()}
      >
        <>
          {caseData.disciplinaryDiscussionDecision === 'VERBAL WARNING' ||
            (caseData.disciplinaryDiscussionDecision === 'VERBAL_WARNING' && (
              <>
                <p>
                  <div className="font-weight-bold font-italic text-dark">What is the purpose of a verbal warning?</div>
                  <span className="text-muted">
                    A verbal warning is usually issued for a very minor offence accompanied by counselling to avoid
                    repeat behaviour. A corrective approach is followed. It serves as the basis for progressive
                    disciplinary action, should the conduct be repeated in future. Your Disciplinary Code will provide
                    guidance on the types of offences justifying a verbal warning for a first offence. LabourTeq
                    provides a verbal warning stating the offence in a particular instance and, where you as manager,
                    can add the remedial actions you require going forward. A pro-active approach to minor misconduct is
                    preferred to avoid it it from becoming a bigger problem later.
                  </span>
                </p>
                <p>
                  <div className="font-weight-bold font-italic text-dark">
                    What should I do if the employee refuses to sign a verbal warning?
                  </div>
                  <span className="text-muted">
                    A difference of opinion exists whether a verbal warning should formally be recorded or not. Some
                    unions, for example, argue that the verbal warning is no longer a verbal warning, if it is
                    documented. You must be led by any collective agreement you may have in place in this regard. We
                    prefer to document it notwithstanding, so there is proper record on which to progress your
                    discipline in case of repeat behaviour. LabourTeq provides a verbal warning that can be used for
                    this purpose, but completion and issuing to the employee is optional. If you do issue such and the
                    employee refuses to sign, you can indicate you are merely requesting the employee to sign in
                    acknowledgement of receipt. This does not mean that he/she necessarily agrees with the verbal
                    warning . Explain this to him/her. However, it is within his/her right to refuse to sign. In this
                    instance just hand the verbal warning to the employee in the presence of a witness. The latter then
                    signs the document indicating that it was handed to him/her in their presence. Place the document on
                    the employee’s record. The employee’s signature is not required for the verbal warning to be valid.
                  </span>
                </p>
                <p>
                  <div className="font-weight-bold font-italic text-dark">
                    For how long does a verbal warning remain valid?
                  </div>
                  <span className="text-muted">
                    That depends on the provisions of your Disciplinary Code. Typically, verbal warnings are valid for 3
                    months. Check your Code. LabourTeq would have been customised in accordance with the latter. The
                    employee is counselled to avoid minor transgressions from becoming a workplace problem that requires
                    formal disciplinary action later.
                  </span>
                </p>
              </>
            ))}
          {caseData.disciplinaryDiscussionDecision === 'WRITTEN WARNING' ||
            (caseData.disciplinaryDiscussionDecision === 'WRITTEN_WARNING' && (
              <>
                <p>
                  <div className="font-weight-bold font-italic text-dark">
                    What is the purpose of a written warning?
                  </div>
                  <span className="text-muted">
                    A written Warning is a formal outcome of a disciplinary process wherein the transgression and
                    required remedial action are documented. It serves as the basis for progressive disciplinary action,
                    should the conduct be repeated in future. A written warning is issued when a previous verbal warning
                    has not had the desired effect, or where the seriousness of the particular offence renders a verbal
                    warning inadequate. It must be properly recorded and the employee must acknowledge receipt thereof.
                    Your Disciplinary Code will provide guidance on the types of offences justifying a written warning.
                    Warnings must be clear and concise and leave the employee in no doubt what the consequences of
                    repeated failure will be. LabourTeq provides a written warning stating the offence in a particular
                    instance and, where you as manager, can add the remedial actions you require going forward.
                  </span>
                </p>
                <p>
                  <div className="font-weight-bold font-italic text-dark">
                    What should I do if the employee refuses to sign a written warning?
                  </div>
                  <span className="text-muted">
                    You are merely requesting the employee to sign in acknowledgement of receipt. This does not mean
                    that he/she necessarily agrees with the written warning or that he/she made himself guilty of any
                    misconduct. You, as manager, is exercising your right to discipline. Explain this to him/her.
                    However, it is within his/her right to refuse to sign. In this instance just hand the written
                    warning to the employee in the presence of a witness. The latter then signs the document indicating
                    that it was handed to him/her in their presence. Place the document on the employee’s record. The
                    employee’s signature is not required for the warning to be valid
                  </span>
                </p>
                <p>
                  <div className="font-weight-bold font-italic text-dark">
                    For how long does a written warning remain valid?
                  </div>
                  <span className="text-muted">
                    That depends on the provisions of your Disciplinary Code. Typically, written warnings are valid for
                    6 months. Check your Code. LabourTeq would have been customised in accordance with the latter.
                  </span>
                </p>
                <p>
                  <div className="font-weight-bold font-italic text-dark">
                    Does the employee have a right of appeal in these circumstances?
                  </div>
                  <span className="text-muted">
                    Only if your own Disciplinary Code provides for an appeal process, does the employee have a right to
                    such. In principle he/she can then appeal against any disciplinary sanction, including against a
                    written warning. Try and avoid drawn-out processes about minor transgressions. If no internal appeal
                    process is available he/she has the right to refer to the matter to a dispute resolution body (i.e.
                    the CCMA or Bargaining Council, as applicable) as an unfair labour practice dispute. This seldom
                    happens in case a written warning was issued.
                  </span>
                </p>
              </>
            ))}
          {caseData.disciplinaryDiscussionDecision === 'FINAL WRITTEN WARNING' ||
            (caseData.disciplinaryDiscussionDecision === 'FINAL_WRITTEN_WARNING' && (
              <>
                <p>
                  <div className="font-weight-bold font-italic text-dark">
                    What is the purpose of a final written warning?
                  </div>
                  <span className="text-muted">
                    A final written warning is a formal outcome of a disciplinary process wherein the transgression and
                    required remedial action are documented. A final written warning is issued when a previous written
                    warning has not had the desired effect, or where the seriousness of the particular offence justifies
                    a strict employer response short of dismissal. It must be properly recorded and the employee must
                    acknowledge receipt thereof. Your Disciplinary Code will provide guidance on the types of offences
                    justifying a final written warning. It may be issued even for a first offence, if the seriousness
                    and nature of the misconduct justifies such. The employee is given a last opportunity to correct his
                    conduct, failing which he/she may be dismissed for a same or similar offence. Warnings must be clear
                    and concise and leave the employee in no doubt what the consequences of repeated failure will be.
                    LabourTeq provides a final written warning stating the offence in a particular instance and, where
                    you as manager, can add the remedial actions you require going forward.
                  </span>
                </p>
                <p>
                  <div className="font-weight-bold font-italic text-dark">
                    What should I do if the employee refuses to sign a final written warning?
                  </div>
                  <span className="text-muted">
                    You are merely requesting the employee to sign in acknowledgement of receipt. This does not mean
                    that he/she necessarily agrees with the final written warning or that he/she made himself guilty of
                    any habitual or serious misconduct. You, as manager, are exercising your right to discipline.
                    Explain this to him/her. However, it is within his/her right to refuse to sign. In this instance
                    just hand the final written warning to the employee in the presence of a witness. The latter then
                    signs the document indicating that it was handed to him/her in their presence. Place the document on
                    the employee’s record. The employee’s signature is not required for the warning to be valid.
                  </span>
                </p>
                <p>
                  <div className="font-weight-bold font-italic text-dark">
                    For how long does a final written warning remain valid?
                  </div>
                  <span className="text-muted">
                    That depends on the provisions of your Disciplinary Code. Typically, final written warnings are
                    valid for 12 months. Check your Code. LabourTeq would have been customised in accordance with the
                    latter.
                  </span>
                </p>
                <p>
                  <div className="font-weight-bold font-italic text-dark">
                    Does the employee have a right of appeal in these circumstances?
                  </div>
                  <span className="text-muted">
                    Only if your own Disciplinary Code provides for an appeal process, does the employee have a right to
                    such. In principle he/she can then appeal against any disciplinary sanction, including against a
                    final written warning. Try and avoid drawn-out processes about minor transgressions. If no internal
                    appeal process is available he/she has the right to refer to the matter to a dispute resolution body
                    (i.e. the CCMA or Bargaining Council, as applicable) as an unfair labour practice dispute.
                  </span>
                </p>
              </>
            ))}
        </>
      </GuidanceModal>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={ExecuteDisciplinaryActionSchema}
        onSubmit={submitValues}
      >
        {({ values, handleSubmit, errors, setFieldTouched }: FormikProps<FormikValues>): ReactElement => (
          <>
            <ExecuteDisciplinaryActionForm templateType={getWarningType()} />
            <button
              className="btn prepare-letter-button mb-3"
              onClick={(): void => {
                props.data.flowableFunctions.onOpenNewDocument(values, fields, getWarningType());
              }}
            >
              PREPARE {text.toUpperCase()} LETTER
            </button>
            {props.data.processInstanceId && !!getWarningType() ? (
              <DocumentVersionControlTable
                data={props.data}
                processInstanceId={props.data.processInstanceId}
                templateType={getWarningType()}
                completeTask={props.data.flowableFunctions.completeTask}
              />
            ) : null}
            <WorkFlowFooter
              data={props.data}
              finalPage={true}
              onNext={(): void => {
                setErrors(errors, setFieldTouched);
                if (!Object.keys(errors).length) handleSubmit();
              }}
              onCancel={() => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default ExecuteDisciplinaryAction;
