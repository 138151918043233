import React from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import FormField from '../../../forms/fields/FormField.component';
import { ErrorMessage, FormikProps, FormikValues, useFormikContext } from 'formik';
import { Tooltip } from '../../../components/tooltips/Tooltip.component';
import { WorkflowComponentProps } from '../../WorkflowContainer/workflow-utils';

const DetermineNatureOfPerformanceShortfallsForm: React.FC<WorkflowComponentProps> = (
  props: WorkflowComponentProps,
) => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();

  const getOptionsForWayFoward = (): string[] => {
    if (props.data.caseData.hasCompletedBasicPIP) {
      return [
        'PERFORMANCE_IMPROVEMENT_PLAN',
        'EXTENDED_PERFORMANCE_IMPROVEMENT_PLAN',
        'PERFORMANCE_COUNSELLING',
        'INCAPACITY_HEARING',
        'OTHER',
      ];
    } else {
      return ['PERFORMANCE_COUNSELLING', 'PERFORMANCE_IMPROVEMENT_PLAN', 'INCAPACITY_HEARING', 'OTHER'];
    }
  };

  return (
    <Form>
      <Row className="mb-3">
        <Col>
          <Label className="text-default d-block mt-3">
            Is further action required
            <span className="align-self-center pl-2 pointer">
              <Tooltip
                id="requiresFurtherAction"
                title="Is further action required?"
                jsx={
                  <>
                    Determine whether the performance shortfalls are due to an inability on the part of the employee to
                    meet the workplace standards, based on a lack of skills, knowledge, abilities, attitudes or
                    efficiency. An incapacity poor performance process requires substantive and procedural fairness.
                    Consider the following factors:
                    <ul>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        Objective proof must exist that the employee actually failed to meet the performance standard/s
                        set;
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>The standard/s must be reasonable;</li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        The employee should have been aware of or could reasonably have been expected to be aware of the
                        performance standard/s;
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        The more subjective the nature of an employee’s work, the more difficult it may be to prove the
                        existence of a standard or the assertion that the employee failed to meet the standard;
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        The employee must be given a reasonable opportunity to meet the required standard/s. This
                        implies that the employee should be counselled, monitored, offered assistance (such as training
                        and coaching) and given a reasonable time period to improve. The extent of the assistance and
                        the time period is to be determined on a case-by-case basis;
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        The more senior, highly paid or experienced the employee is, the higher the standard of work
                        that can reasonably be expected of him/her, and the less onerous the duty on the employer to
                        assist;
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        The employee should be warned of the possible consequences if poor performance persists;
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        Before dismissing an employee, an incapacity hearing is to be held where the employee is given
                        an opportunity to state his/her case;
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        Alternatives short of dismissal should be considered.
                      </li>
                    </ul>
                    If the above is not applicable in the circumstances, close the case on LabourTeq and begin another
                    more appropriate process to deal with the performance concerns.
                  </>
                }
              />
            </span>
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.furtherActionRequired}
                    onChange={(): void => setFieldValue('furtherActionRequired', true)}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.furtherActionRequired}
                    onChange={(): void => setFieldValue('furtherActionRequired', false)}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup>
            <Label for="reasonForDecision" className="text-default">
              Provide Reason/s*
            </Label>
            <FormField type={'textarea'} placeholder={'Motivation for action'} name={'reasonForDecision'} />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'reasonForDecision'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      {values.furtherActionRequired && (
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="wayForward" className="text-default text-capitalize">
                Select the way forward*
              </Label>
              <FormField
                type={'select'}
                placeholder={'Select way forward'}
                name={'wayForward'}
                selectOptions={getOptionsForWayFoward()}
              />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'wayForward'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default DetermineNatureOfPerformanceShortfallsForm;
