// javascript plugin used to create scrollbars on windows
import React, { createRef, useContext, useState } from 'react';
import { NavLink, RouteProps } from 'react-router-dom';
// reactstrap components
import { Collapse, Nav } from 'reactstrap';
import { RouteType, RouteWithCollapse, RouteWithoutCollapse } from '../../routes';
import { UserContext, UserContextProps } from '../../App';
import { Auth } from 'aws-amplify';
import { useErrorHandler } from '../../utils/notification-utils';

interface SideBarProps extends RouteProps {
  routes: RouteType[];
  closeSidebar: () => void;
  isSidebarOpen: boolean;
}

interface SidebarState {
  [key: string]: boolean | SidebarState;
}

const Sidebar: React.FC<SideBarProps> = (props: SideBarProps) => {
  const [state, setState] = useState<SidebarState>();
  const [hover, setHover] = useState<boolean>();
  const sidebarRef = createRef<HTMLDivElement>();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const contextValue = useContext<Partial<UserContextProps>>(UserContext);

  const getCollapseInitialState = (routes: RouteType[]) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState((routes[i] as RouteWithCollapse).views)) {
        return true;
      } else if (window.location.href.indexOf((routes[i] as RouteWithoutCollapse).path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const createLinks = (routes: RouteType[]) => {

    return (
      <>
        {routes.map((prop: RouteType, key) => {
          //@ts-ignore
          if(currentUser && !prop.allowedRoles?.filter(role => currentUser?.userRoles.includes(role)).length){
            return null;
          }
          if (prop.redirect) {
            return null;
          }
          if (prop.collapse) {
            const st: { [key: string]: boolean } = {};
            st[prop.state!] = !state?.[prop.state];
            const Icon: undefined | React.FunctionComponent<Record<string, unknown>> = prop.svgIcon;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return (
              <li className={getCollapseInitialState(prop.views) ? 'active' : ''} key={key}>
                <a
                  href="#pablo"
                  data-toggle="collapse"
                  aria-expanded={!!state?.[prop.state]}
                  onClick={e => {
                    e.preventDefault();
                    setState(st);
                  }}
                >
                  {prop.icon !== undefined ? (
                    <>
                      {prop.svgIcon ? (
                        <i>
                          {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            <Icon />
                          }
                        </i>
                      ) : (
                        <i className={prop.icon} />
                      )}
                      <p>
                        <span>{prop.name}</span>
                        <span className="px-1">
                          <b className="caret" />
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      <span className="sidebar-mini-icon">{prop.mini}</span>
                      <span className="sidebar-normal">
                        {prop.name}
                        <b className="caret" />
                      </span>
                    </>
                  )}
                </a>
                <Collapse
                  isOpen={
                    (!!state?.[prop.state] && !props.isSidebarOpen) ||
                    (hover && !!state?.[prop.state] && props.isSidebarOpen)
                  }
                >
                  <ul className={`nav ${prop.collapse && 'ml-4'}`}>{createLinks(prop.views)}</ul>
                </Collapse>
              </li>
            );
          }

          if (!prop.collapse && !prop.hide) {
            const Icon: undefined | React.FunctionComponent<Record<string, unknown>> = prop.svgIcon;

            return (
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              <li className={activeRoute(prop.path)} key={key}>
                <NavLink to={prop.path} className={prop.inactive ? 'disabled' : ''} onClick={props.closeSidebar}>
                  {prop.icon !== undefined ? (
                    <>
                      {prop.svgIcon ? (
                        <i>
                          {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            <Icon />
                          }
                        </i>
                      ) : (
                        <i className={prop.icon} />
                      )}
                      <p>{prop.name}</p>
                    </>
                  ) : (
                    <>
                      <span className="sidebar-mini-icon">{prop.mini}</span>
                      <span className="sidebar-normal">{prop.name}</span>
                    </>
                  )}
                </NavLink>
              </li>
            );
          }
        })}
      </>
    );
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    if (props.location) {
      return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
    }
    return '';
  };

  const handleError = useErrorHandler();

  const logOut = (): void => {
    Auth.signOut()
      .then(() => {
        if (contextValue.logoutCallBack) contextValue.logoutCallBack();
        // setState(oldState => ({ ...oldState, user: undefined }));
      })
      .catch(error => handleError(error));
  };

  return (
    <div
      className="sidebar"
      /*data={activeColor}*/ onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="sidebar-wrapper" ref={sidebarRef} style={{ overflow: 'hidden' }}>
        <Nav className=" pt-0 mt-0 mb-5">
          {createLinks(props.routes)}
          <li style={{ borderTop: '1px solid grey' }}>
            <NavLink to="/" className="d-flex flex-wrap-reverse" onClick={(): void => logOut()}>
              <div>
                <p style={{ lineHeight: '15px' }}>LOGOUT</p>
                <p style={{ lineHeight: '15px' }} className="font-weight-bold">
                  {currentUser?.firstName + ' ' + currentUser?.lastName}
                </p>
              </div>
              <i className="fa fa-sign-out-alt pointer m-1" />
            </NavLink>
          </li>
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
