import * as Yup from 'yup';

const CustomTransgressionsSchema = Yup.object().shape({
  customTransgressions: Yup.array()
    .of(
      Yup.object().shape({
        transgressionCategory: Yup.string().required('Please select a transgression category'),
        sanction: Yup.object().shape({
          firstOffence: Yup.string().required('select sanction'),
          secondOffence: Yup.string().nullable(),
          thirdOffence: Yup.string().nullable(),
          fourthOffence: Yup.string().nullable(),
          fifthOffence: Yup.string().nullable(),
          sixthOffence: Yup.string().nullable(),
        }),
        transgression: Yup.string().required('Please enter transgression'),
      }),
    )
    .nullable(),
});

export default CustomTransgressionsSchema;
