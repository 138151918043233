//Validation schema with Yup
//first wizard page
import * as Yup from 'yup';

const UserSchema = Yup.object().shape({
  firstName: Yup.string().required('Required field'),
  lastName: Yup.string().required('Required field'),
  emailAddress: Yup.string()
    .email()
    .required('Required field'),
  roles: Yup.array().required('Required field'),
});

export default UserSchema;
