import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useDebounce } from 'use-debounce';
import { UserContext, UserContextProps, UserDetails } from '../../App';
import ButtonWithIcons from '../../components/buttons/ButtonWIthIcons.component';
import DynamicCaseTable, { ColumnHeading } from '../../components/dynamicCaseTable/DynamicCaseTable';
import { PlusIcon } from '../../components/icon/Icon.component';
import Loader from '../../components/loader/Loader';
import TopBarComponent from '../../components/topBar/TopBar.component';
import { listEmployees } from '../../graphql/queries';
import { Employee } from '../../models';
import { list, listAllEmployeesByOrganisationId } from '../../utils/graphql-utils';
import { useErrorHandler } from '../../utils/notification-utils';
import Search from './SearchCases';
import { filterViewableEmployeesByCurrentUser } from '../../utils/employee-utils';
import { toTitleCase } from '../../utils/string-utils';
import { CaseTableData } from '../../utils/case-utils';
import { getFlowableCasesForOrganisationCaseTable } from '../../components/tables/case-table-utils';
import { useHistory } from 'react-router';
import useQuery from '../../components/hooks/hooks';

interface EmployeeCaseObject {
  [key: string]: string;
  id: string;
  caseNumber: string;
  employeeNumber: string;
  employeeFirstName: string;
  employeeLastName: string;
  employeeId: string;
  caseStatus: string;
  sanction: string;
  isSuspensionActive: any;
}

interface CaseViewState {
  showModal: boolean;
  cases: CaseTableData[];
  refinedData: unknown[];
  loading?: boolean;
  searchString: string;
  searchResult: Employee[];
}

const ViewCases: React.FC = () => {
  const [state, setState] = useState<CaseViewState>({
    showModal: false,
    cases: [],
    refinedData: [],
    loading: true,
    searchString: '',
    searchResult: [],
  });
  const query = useQuery();
  const filter = query.get('filter');

  const [debounceSearch] = useDebounce(state.searchString, 300);
  const [employees, setEmployees] = useState<Employee[]>();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const handleError = useErrorHandler();
  const history = useHistory();
  const [caseStatus, setCaseStatus] = useState<string>();
  const [caseStatuses, setCaseStatuses] = useState<unknown>();

  useEffect(() => {
    if (!caseStatus && filter) {
      switch (filter) {
        case 'open':
          setCaseStatus('Current');
          break;
        case 'closed':
          setCaseStatus('Case Closed');
          break;
        case 'misconduct':
          setCaseStatus('MisconductCurrent');
          break;
        case 'activepip':
          setCaseStatus('Current PIP');
          break;
        case 'counselling':
          setCaseStatus('Current Counselling');
          break;
        case 'performance':
          setCaseStatus('PerformanceCurrent');
          break;
        case 'hearings':
          setCaseStatus('Disciplinary Hearings');
          break;
        case 'discussions':
          setCaseStatus('Discussions');
          break;

        default:
          break;
      }
    }
  }, []);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const searchString: string = event.target.value.trim();
    setState(oldState => ({ ...oldState, searchString: searchString }));
  };

  const onHandleCaseStatus = (value: any): void => {
    setCaseStatus(value?.value);
  };

  const clearSearchString = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(oldState => ({ ...oldState, searchString: '' }));
    setCaseStatus('');
  };

  const search = (employee: Employee, cases: CaseTableData[]): EmployeeCaseObject[] => {
    const matchingCases = cases.filter(c => c.employeeId === employee.id);
    //@ts-ignore
    return matchingCases.map(c => {
      return {
        id: c.id,
        caseNumber: c.caseNumber,
        employeeNumber: employee.employeeNumber,
        employeeFirstName: toTitleCase(employee.firstName, ' '),
        employeeLastName: toTitleCase(employee.lastName, ' '),
        employeeId: c.employeeId,
        caseStatus: c.isDisputeReferred ? 'Dispute Referred' : c.caseStatus,
        isSuspensionActive: c.isSuspensionActive,
        sanction: c.sanction,
      };
    });
  };

  const loadData = useCallback(
    async (currentUser: UserDetails): Promise<void> => {
      if (currentUser.organisationId) {
        const caseData = await getFlowableCasesForOrganisationCaseTable(currentUser.organisationId);

        const distinctStatuses = Array.from(new Set(caseData.map(c => c.caseStatus).filter(c => !!c))).map(
          caseStatus => ({
            value: caseStatus,
            label: caseStatus,
          }),
        );
        const complexStatus = [
          { value: 'Current', label: 'Current Cases' },
          { value: 'Discussions', label: 'Discussions' },
          { value: 'Current PIP', label: 'Current PIP' },
          { value: 'Current Counselling', label: 'Current Counselling' },
          { value: 'Disciplinary Hearings', label: 'Disciplinary Hearings' },
          { value: 'Misconduct', label: 'Misconduct - Past & Current' },
          { value: 'MisconductCurrent', label: 'Misconduct - Current' },
          { value: 'Performance', label: 'Poor Performance - Past & Current' },
          { value: 'PerformanceCurrent', label: 'Poor Performance - Current' },
          { value: 'Dispute Referred', label: 'Dispute Referred' },
          { value: 'Current Suspension', label: 'Current Suspension' },
        ];

        setCaseStatuses(
          [...complexStatus, ...distinctStatuses].sort((a, b) => {
            const fa = a.label.toLowerCase(),
              fb = b.label.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          }),
        );
        if (!debounceSearch) {
          const employees = await listAllEmployeesByOrganisationId(currentUser.organisationId);
          const viewableEmployees = filterViewableEmployeesByCurrentUser(employees, currentUser);
          setEmployees(viewableEmployees);
        }
        setState(oldState => ({ ...oldState, cases: caseData, loading: false }));
      }
    },
    [debounceSearch],
  );

  useEffect(() => {
    if (currentUser) {
      loadData(currentUser).catch(handleError);
    }
  }, [debounceSearch, currentUser, handleError, loadData]);

  const Data = useMemo(() => {
    if (state.cases.length > 0 && employees && employees.length > 0) {
      const cleanedCases = state.cases.filter(el => el.caseNumber !== 'None');
      const employeeCaseObjects: EmployeeCaseObject[] = employees.flatMap((employee: Employee) => {
        return search(employee, cleanedCases);
      });
      console.log(
        employeeCaseObjects.filter(
          el => el.isSuspensionActive && ['Case Closed', 'Dispute Referred'].includes(el.caseStatus),
        ),
      );
      if (caseStatus && !state.searchString) {
        if (caseStatus === 'Current') {
          return employeeCaseObjects.filter(data => !['Case Closed', 'Dispute Referred'].includes(data.caseStatus));
        }
        if (caseStatus === 'Case Closed') {
          return employeeCaseObjects.filter(data => ['Case Closed', 'Dispute Referred'].includes(data.caseStatus));
        }
        if (caseStatus === 'Misconduct') {
          return employeeCaseObjects.filter(data => data.caseNumber.includes('M'));
        }
        if (caseStatus === 'MisconductCurrent') {
          return employeeCaseObjects.filter(
            data => data.caseNumber.includes('M') && !['Case Closed', 'Dispute Referred'].includes(data.caseStatus),
          );
        }
        if (caseStatus === 'Performance') {
          return employeeCaseObjects.filter(data => data.caseNumber.includes('P'));
        }
        if (caseStatus === 'PerformanceCurrent') {
          return employeeCaseObjects.filter(
            data => data.caseNumber.includes('P') && !['Case Closed', 'Dispute Referred'].includes(data.caseStatus),
          );
        }
        if (caseStatus === 'Discussions') {
          return employeeCaseObjects.filter(
            data => data.caseStatus.includes('Discussion') && data.caseNumber.includes('M'),
          );
        }
        if (caseStatus === 'Disciplinary Hearings') {
          return employeeCaseObjects.filter(
            data => data.caseStatus.includes('Hearing') && data.caseNumber.includes('M'),
          );
        }
        if (caseStatus === 'Current PIP') {
          return employeeCaseObjects.filter(data => data.caseStatus.includes('PIP'));
        }
        if (caseStatus === 'Current Counselling') {
          return employeeCaseObjects.filter(data => data.caseStatus.includes('counselling'));
        }
        if (caseStatus === 'Current Suspension') {
          return employeeCaseObjects.filter(
            data => data.isSuspensionActive && ['Case Closed', 'Dispute Referred'].includes(data.caseStatus),
          );
        }
        return employeeCaseObjects.filter(data => data.caseStatus === caseStatus);
      }
      if (caseStatus && state.searchString) {
        return employeeCaseObjects.filter(
          data =>
            data.caseNumber.includes(state.searchString) ||
            data.employeeFirstName.includes(state.searchString) ||
            data.employeeLastName.includes(state.searchString),
        );
      }
      if (state.searchString) {
        return employeeCaseObjects.filter(
          data =>
            data.caseNumber.includes(state.searchString) ||
            data.employeeFirstName.includes(state.searchString) ||
            data.employeeLastName.includes(state.searchString),
        );
      }

      return employeeCaseObjects;
    }
    return [];
  }, [state.cases, state.searchString, employees, caseStatus]);

  const columns: ColumnHeading[] = [
    { key: 'employeeNumber', label: 'Employee No' },
    { key: 'employeeFirstName', label: 'First Name' },
    { key: 'employeeLastName', label: 'Last Name' },
    { key: 'caseNumber', label: 'Case No' },
    { key: 'caseStatus', label: 'Status' },
    { key: 'sanction', label: 'Sanction' },
  ];
  console.log('**********');
  console.log('Total cases:', Data.length);
  console.log('***', [...new Set(Data.map(el => el.caseStatus))]);
  console.log('**********');

  return (
    <>
      <TopBarComponent title={'Cases'} subTitle={' '} hideSearch>
        <Row>
          <div style={{ marginRight: 15 }}>
            <Search
              handleTextChange={handleTextChange}
              caseStatuses={caseStatuses}
              selectedCaseStatus={caseStatus}
              onHandleCaseStatus={(value: unknown): void => onHandleCaseStatus(value)}
              onClearSearch={clearSearchString}
            />
          </div>
          <ButtonWithIcons
            title={'start new case'}
            leftIcon={<PlusIcon />}
            buttonType={'btn-bd-purple'}
            handleClick={() => {
              history.push('/employees');
            }}
          />
        </Row>
      </TopBarComponent>
      <div className="content">
        {state.loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <>
            <Row>
              <Col className="mb-5" md="12">
                <Card>
                  <CardBody>
                    <DynamicCaseTable
                      data={Data.sort((a, b) => {
                        if (a.employeeLastName === b.employeeLastName) {
                          return a.caseNumber < b.caseNumber ? -1 : 1;
                        } else {
                          return a.employeeLastName < b.employeeLastName ? -1 : 1;
                        }
                      })}
                      columns={columns}
                      loading={state.loading}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default ViewCases;
