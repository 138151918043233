import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  ButtonProps,
  ButtonToolbar,
  Table as BootStrapTable,
  TableProps as BootStrapTableProps,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {ClosedCaseModal} from '../../components/ClosedCaseModal/ClosedCaseModal';

export type RowData = { [key: string]: string | number | boolean | null | undefined | object };
export interface TableProps extends BootStrapTableProps {
  data?: RowData[];
  columns: ColumnHeading[];
  loading?: boolean;
}
export type ColumnHeading = ColumnHeadingData | ColumnHeadingAction;
interface ColumnHeadingData {
  label: string;
  key: string;
  type?: 'data';
}
interface ColumnHeadingAction {
  label: string;
  type: 'action';
  actions: Action[];
}
interface Action extends ButtonProps {
  label: string;
  func: (data: RowData) => void;
}
const DynamicCaseTable: React.FC<TableProps> = (props: TableProps) => {
  const { data = [], columns, ...bootstrapProps } = props;
  const [modalShow, setModalShow] = useState(false)
  const [modalParams, setModalParams] = useState({})

  function renderHeadings(): JSX.Element {
    const renderHeading = (column: ColumnHeading, headingIndex: number): JSX.Element => (
      <th key={`heading_${headingIndex}`}>{column.label}</th>
    );
    return (
      <thead>
        <tr>{columns.map(renderHeading)}</tr>
      </thead>
    );
  }
  const renderRow = (row: RowData, rowIndex: number): JSX.Element => {
    const renderButton = (action: Action, buttonIndex: number): JSX.Element => {
      const { func, label, ...buttonProps } = action;
      return (
        <Button key={`row_${rowIndex}_button_${buttonIndex}`} {...buttonProps} onClick={(): void => func(row)}>
          {label}
        </Button>
      );
    };
    const renderItem = (column: ColumnHeading, itemIndex: number): JSX.Element => {
      if (column.type === 'action') {
        return (
          <td key={`row_${rowIndex}_item_${itemIndex}`}>
            <ButtonToolbar>
              <ButtonGroup>{column.actions.map(renderButton)}</ButtonGroup>
            </ButtonToolbar>
          </td>
        );
      }
      if (!itemIndex && itemIndex !== 0) {

        return (
          <th key={`row_${rowIndex}_item_${itemIndex}`} scope="row">
            <Link to={'/case/' + row['id']}>{row[column.key]}</Link>
          </th>
        );
      }

      if (row['caseStatus'] === 'Case Closed') {
        return (
          <td key={`row_${rowIndex}_item_${itemIndex}`}>
            <span style={{ color: '#BD7AE3', cursor: 'pointer' }} onClick={() => { setModalShow(true); setModalParams({id:row['id'], employeeId:row['employeeId'] })}
          }>{row[column.key]}</span>
          </td>
        );
      }
      return (
        <td key={`row_${rowIndex}_item_${itemIndex}`}>
          <Link to={'/case/' + row['id']}>{row[column.key]}</Link>
        </td>
      );
    };
    return <tr key={`row_${rowIndex}`}>{columns.map(renderItem)}</tr>;
  };
  return (
    <BootStrapTable {...bootstrapProps}>
      {renderHeadings()}
      {data.length > 0 ? (
        <tbody>{data.map(renderRow)}</tbody>
      ) : (
        <tbody>
          <tr>
            <td colSpan={columns.length}>
              <div className="w-100 d-flex flex-column py-2 align-items-center justify-content-center">
                {props.loading ? (
                  'loading'
                ) : (
                  <>
                    <div className="mb-2 position-relative">
                      <span className="far fa-file fa-4x text-light" />
                      <span className="fa fa-times fa-2x text-light position-absolute floating-times" />
                    </div>
                    <h3 className="text-light my-1">No cases to display</h3>
                    <h4 className="text-light my-1">Press the + button to add some!</h4>
                  </>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      )}
    
      <ClosedCaseModal
        //@ts-ignore
      closeModal={()=>setModalShow(false)} id={modalParams.id} isOpen={modalShow} employeeId={modalParams.employeeId} />
    </BootStrapTable>
  );
};
export default DynamicCaseTable;
