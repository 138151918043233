import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikValues, useFormikContext } from 'formik';
import './dateTime.styles.scss';
import FormField from '../fields/FormField.component';
import { EmailAddressInput, PhoneNumberInput, PhysicalAddressInput, Province } from '../../API';
import InputRow from '../../components/inputRow/InputRow.component';
import { AddIcon, RemoveIcon } from '../../components/icon/Icon.component';

const PersonalInfo: React.FC = () => {
  const { values, errors } = useFormikContext<FormikValues>();
  return (
    <>
      <h4 className="text-default mt-4 font-weight-bold">Personal Information</h4>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="firstName" className="text-default">
              First Name*
            </Label>
            <FormField className="text-capitalize" type="text" placeholder="Name" name="firstName" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="firstName" />
            </span>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="lastName" className="text-default">
              Last Name*
            </Label>
            <FormField className="text-capitalize" type="text" placeholder="Last Name" name="lastName" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="lastName" />
            </span>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="idNumber" className="text-default">
              ID Number
            </Label>
            <FormField type="text" placeholder="ID Number" name="idNumber" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="idNumber" />
            </span>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="passportNumber" className="text-default">
              Passport Number
            </Label>
            <FormField type="text" placeholder="Passport Number" name="passportNumber" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name="passportNumber" />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <h4 className="text-default mt-2 font-weight-bold">Contact Information</h4>
      <FieldArray name="phoneNumbers">
        {(arrayHelpers: FieldArrayRenderProps) => (
          <>
            {!values.phoneNumbers.length && arrayHelpers.push({})}
            {values.phoneNumbers.length &&
              values.phoneNumbers.map((item: PhoneNumberInput, index: number) => (
                <InputRow
                  key={index}
                  index={index}
                  addCallback={arrayHelpers.push}
                  removeCallback={arrayHelpers.remove}
                  defaultValues={{}}
                >
                  <>
                    <Col md={3}>
                      <FormGroup>
                        {index === 0 && <Label className="text-default">Phone Type</Label>}
                        <FormField
                          name={`phoneNumbers.${index}.phoneNumberType`}
                          placeholder={'Select Phone Type'}
                          type={'select'}
                          selectOptions={['Mobile', 'Home', 'Work']}
                        />
                        <span className="text-danger">
                          <ErrorMessage name={`phoneNumbers.${index}.phoneNumberType`} />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        {index === 0 && <Label className="text-default">Phone Number</Label>}
                        <FormField name={`phoneNumbers.${index}.number`} placeholder={'Phone Number'} type={'text'} />
                        <span className="text-danger">
                          <ErrorMessage name={`phoneNumbers.${index}.number`} />
                        </span>
                      </FormGroup>
                    </Col>
                  </>
                </InputRow>
              ))}
          </>
        )}
      </FieldArray>

      <FieldArray name="physicalAddresses">
        {(arrayHelpers: FieldArrayRenderProps) => (
          <>
            {!values.physicalAddresses.length && arrayHelpers.push({})}
            {values.physicalAddresses.length &&
              values.physicalAddresses.map((item: PhysicalAddressInput, index: number) => (
                <>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="physicalAddressType" className="text-default">
                          Address Type*
                        </Label>
                        <FormField
                          type="select"
                          placeholder="Select Address Type"
                          name={`physicalAddresses.${index}.physicalAddressType`}
                          selectOptions={['Residence', 'Work']}
                        />
                        <span className="text-danger">
                          <ErrorMessage
                            className="text-danger"
                            name={`physicalAddresses.${index}.physicalAddressType`}
                          />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="lineOne" className="text-default">
                          Address Line 1*
                        </Label>
                        <FormField
                          type="text"
                          placeholder="Address Line 1"
                          name={`physicalAddresses.${index}.lineOne`}
                        />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={`physicalAddresses.${index}.lineOne`} />
                        </span>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="addressLine2" className="text-default">
                          Address Line 2*
                        </Label>
                        <FormField
                          type="text"
                          placeholder="Address Line 2"
                          name={`physicalAddresses.${index}.lineTwo`}
                        />
                        {errors && (
                          <span className="text-danger">
                            <ErrorMessage className="text-danger" name={`physicalAddresses.${index}.lineTwo`} />
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={3} className="align-self-center">
                      {index === 0 && (
                        <div className="mt-md-3">
                          <span onClick={(): void => arrayHelpers.push({})}>
                            <AddIcon />
                          </span>
                        </div>
                      )}
                      {index > 0 && (
                        <span onClick={(): void => arrayHelpers.remove(index)}>
                          <RemoveIcon />
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="city" className="text-default">
                          City/Town*
                        </Label>
                        <FormField type="text" placeholder="City" name={`physicalAddresses.${index}.city`} />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={`physicalAddresses.${index}.city`} />
                        </span>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="province" className="text-default">
                          Province*
                        </Label>
                        <FormField
                          type="select"
                          placeholder="Choose Province"
                          name={`physicalAddresses.${index}.province`}
                          selectOptions={Object.keys(Province)}
                        />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={`physicalAddresses.${index}.province`} />
                        </span>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="postalCode" className="text-default">
                          Postal Code*
                        </Label>
                        <FormField
                          name={`physicalAddresses.${index}.postalCode`}
                          placeholder="Postal Code"
                          type="text"
                        />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={`physicalAddresses.${index}.postalCode`} />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ))}
          </>
        )}
      </FieldArray>

      <FieldArray name="emails">
        {(arrayHelpers: FieldArrayRenderProps) => (
          <>
            {!values.emails.length && arrayHelpers.push({})}
            {values.emails.length &&
              values.emails.map((item: EmailAddressInput, index: number) => (
                <InputRow
                  key={index}
                  index={index}
                  addCallback={arrayHelpers.push}
                  removeCallback={arrayHelpers.remove}
                  defaultValues={{}}
                >
                  <>
                    <Col md={3}>
                      <FormGroup>
                        {index === 0 && <Label className="text-default">Email Type</Label>}
                        <FormField
                          name={`emails.${index}.emailAddressType`}
                          placeholder={'Email Address Type'}
                          type={'select'}
                          selectOptions={['Personal', 'Work']}
                        />
                        <span className="text-danger">
                          <ErrorMessage name={`emails.${index}.emailAddressType`} />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        {index === 0 && <Label className="text-default">Email Address</Label>}
                        <FormField name={`emails.${index}.address`} placeholder={'Email Address'} type={'email'} />
                        <span className="text-danger">
                          <ErrorMessage name={`emails.${index}.address`} />
                        </span>
                      </FormGroup>
                    </Col>
                  </>
                </InputRow>
              ))}
          </>
        )}
      </FieldArray>
    </>
  );
};

export default PersonalInfo;
