import React, { ReactElement } from 'react';
import { Col, Row } from 'reactstrap';
import Tabs from '../../../../../components/HearingAndAppealTabs/HearingAndAppealTabs.component';
import { HEARING_TABS } from '../../../../../utils/case-utils';
import { Formik, FormikProps, FormikValues } from 'formik';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { InitialHearingExternalRepresentationSubmissionsForm } from './InitialHearingExternalRepresentationSubmissionsForm';

const InitialHearingExternalRepresentationSubmissions: React.FC<WorkflowComponentProps> = (
  props: WorkflowComponentProps,
) => {
  const { caseData, flowableFunctions } = props.data;
  const fields = {
    initial_hearing_externalRepresentationApplicationEmployeeSubmissionDocuments: 'array',
    initial_hearing_externalRepresentationApplicationEmployerResponseDocuments: 'array',
  };

  const initialValues = getFormDataFromCaseData(fields, caseData);

  const getFormValuesForSubmission = (values: FormikValues): Record<string, any> => {
    const formValues = { ...values };
    formValues.initial_hearing_externalRepresentationApplicationEmployeeSubmissionDocuments = JSON.stringify(
      values.initial_hearing_externalRepresentationApplicationEmployeeSubmissionDocuments,
    );
    formValues.initial_hearing_externalRepresentationApplicationEmployerResponseDocuments = JSON.stringify(
      values.initial_hearing_externalRepresentationApplicationEmployerResponseDocuments,
    );
    return filterFormData(formValues, fields);
  };

  const submitValues = (values: FormikValues): void => {
    flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Hearing preparations</h4>
      <Row>
        <Col md={12}>
          <Tabs page={5} tabs={HEARING_TABS} />
        </Col>
      </Row>
      {/*<h5 className="text-h5 text-capitalize font-weight-500 mt-3">*/}
      {/*  Decide on Employee's application for external representation*/}
      {/*</h5>*/}
      <h5 className="text-h5 text-blue text-capitalize font-weight-500 mt-3">
        Record the respective parties' submissions
      </h5>
      <Formik initialValues={initialValues} enableReinitialize onSubmit={submitValues}>
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <InitialHearingExternalRepresentationSubmissionsForm
              data={props.data}
              getFormValuesForSubmission={getFormValuesForSubmission}
            />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={() => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default InitialHearingExternalRepresentationSubmissions;
