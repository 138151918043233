import React from 'react';
import { Image, View } from '@react-pdf/renderer';
import { styles } from './PDFStyles.style';
import { LogoPosition } from '../../API';

interface LetterheadProps {
  imageUrl: string;
  logoPosition: string;
}

const LetterHead: React.FC<LetterheadProps> = (props: LetterheadProps) => {
  const { logoPosition, imageUrl } = props;
  return (
    <View
      style={
        logoPosition === LogoPosition.CENTER
          ? styles.logoCenter
          : logoPosition === LogoPosition.RIGHT
          ? styles.logoRight
          : styles.logoLeft
      }
    >
      <Image
        style={{ ...styles.logoImage }}
        src={{
          uri: imageUrl,
          method: 'GET',
          headers: {},
          body: '',
        }}
      />
    </View>
  );
};

export default LetterHead;
