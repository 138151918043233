import React, {useCallback, useContext, useEffect} from 'react';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import { Col, FormGroup, Label, Row, Table } from 'reactstrap';
import './NewCase.styles.scss';
import FormField from '../fields/FormField.component';
import { AddIcon, RemoveIcon } from '../../components/icon/Icon.component';
import { Tooltip } from '../../components/tooltips/Tooltip.component';
import TableFormField from '../fields/TableFormField';
import { UserContext, UserContextProps } from '../../App';
import { useErrorHandler } from '../../utils/notification-utils';
import { queryMasterProcessInstancesByOrganisationId } from '../../utils/flowable/flowable-utils';
import UploaderContainer from '../../components/Uploader/UploaderContainer';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../components/taskContainer/TaskContainer';
import { FlowableProcessInstance, FlowableVariable } from '../../utils/flowable/flowable-types';
import { CaseType } from '../../API';
import { CaseData, CasePerformanceShortfall } from '../../screens/WorkflowContainer/workflow-utils';
import { getEmployeeDetails } from '../../utils/employee-utils';
import { Employee } from '../../models';

const incidentColumns = ['Summary of Allegations*', 'Date', 'Time', 'Location', 'Complainants', 'Witnesses'];
const shortfallsColumns = ['Performance Shortfall*', 'Date/Period', 'Recurring', 'Upload supporting Documents'];
interface NewCaseFormProps {
  caseData: CaseData;
}

const isANumber = (num: any): boolean => {
  const value = parseInt(num);
  return !!(value && value !== Infinity && value !== -Infinity);
};

const checkPattern = (value: string): boolean => {
  const validPrefixes = ['M', 'P'];
  return (
      validPrefixes.includes(value.charAt(0).toUpperCase()) &&
      value.charAt(1) === '-' &&
      isANumber(value.slice(value.indexOf(value.charAt(2))))
  );
};
const NewCaseForm: React.FC<NewCaseFormProps> = (props: NewCaseFormProps) => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const handleError = useErrorHandler();
  const { employeeId, processInstanceId } = props.caseData;

  const getInitialCaseNumber = useCallback((): string => {
    const concatString = '-0001';
    return values.caseType.slice(0, 1) + concatString;
  }, [values.caseType]);

  const generateCaseNumber = useCallback(async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (currentUser && currentUser?.organisationId) {
        queryMasterProcessInstancesByOrganisationId(currentUser.organisationId)
          .then((res: FlowableProcessInstance[]) => {
            const flowableCases: FlowableProcessInstance[] = res;
            if (flowableCases.length) {
              const caseNumbers = flowableCases
                .map((caseItem: { [key: string]: any }) => {
                  const variables = caseItem.variables;
                  const caseNumberVariable = variables.find((item: FlowableVariable) => item.name === 'caseNumber');
                  const caseType = variables.find((item: FlowableVariable) => item.name === 'caseType');
                  if (caseType && caseType.value === values.caseType) return caseNumberVariable;
                  return null;
                })
                .filter(caseItem => caseItem && typeof caseItem.value === 'string' && checkPattern(caseItem.value));
              if (caseNumbers.length) {
                //sort in descending order
                const sortedCaseNumbers = caseNumbers
                  .map(caseNumber => {
                    // remove the prefixes eg M- from M-1000
                    const num = caseNumber!.value.slice(2);

                    return num;
                  })
                  .sort((a, b) => b - a);
                const number = parseInt(sortedCaseNumbers[0]) + 1;
                const newNum = ('000' + number).slice(-4);
                const newCaseNumber = values.caseType.slice(0, 1) + '-' + newNum;
                resolve(newCaseNumber);
              } else {
                resolve(getInitialCaseNumber());
              }
            } else {
              resolve(getInitialCaseNumber());
            }
          })
          .catch(error => reject(error));
      }
    });
  }, [values.caseType, currentUser, getInitialCaseNumber]);

  useEffect(() => {
    if (!values.caseType || values.caseType === CaseType.NONE) {
      setFieldValue('incidents', undefined);
      setFieldValue('performanceShortfalls', undefined);
      setFieldValue('caseNumber', '');
    } else {
      generateCaseNumber()
        .then(caseNumber => setFieldValue('caseNumber', caseNumber))
        .catch(error => handleError(error));
    }
    if (values.caseType === CaseType.POOR_PERFORMANCE && !values.jobTitle) {
      getEmployeeDetails(employeeId).then((employee: Employee) => {
        setFieldValue('jobTitle', employee.jobTitle!.name);
      });
    }
  }, [values.caseType, values.jobTitle, employeeId, handleError, setFieldValue, generateCaseNumber]);

  return (
    <div className="d-flex flex-column justify-content-between">
      <h4 className="text-h4 text-blue font-weight-500">Case Overview</h4>
      <Row className="d-flex align-items-center">
        <Col md={3}>
          <FormGroup>
            <Label for="caseNumber" className="text-default pt-1 pb-1">
              Case Number*
            </Label>
            <FormField type={'text'} placeholder="Case Number" name="caseNumber" disabled={true} />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'caseNumber'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="caseType" className="text-default pt-1 pb-1">
              Case Type*
            </Label>
            <FormField
              type={'select'}
              placeholder="Select Case type"
              name="caseType"
              selectOptions={['Misconduct', 'Poor_performance']}
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'caseType'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={2} className="pt-3">
          <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
            <Tooltip
              id="caseType"
              title="Select Case Type"
              jsx={
                <p>
                  Select the Misconduct workflow when it is alleged that the employee contravened a workplace rule or
                  committed a material breach of the employment contract. There is implied fault on the side of the
                  employee, either in the form of negligence or intent. Select the Incapacity Poor Performance workflow
                  when alleging that the employee is incapable of doing his/her job because of some perceived lack of
                  skill, knowledge, ability, or efficiency which is necessary to meet the performance standards required
                  by the employer. The behaviour is not perceived to be intentional or negligent. It is imperative that
                  the two are separated because they deal with different issues and require different procedures.
                </p>
              }
            />
          </span>
        </Col>
      </Row>
      {values.caseType === 'MISCONDUCT' && (
        <>
          <Row>
            <Col>
              <div>
                <p className="text-primary text-default text-capitalize mt-4">Incidents*</p>
                <hr style={{ border: '0.06em solid #adb5bd' }} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="table-responsive-lg table-responsive-md " bordered>
                <thead style={{ border: '1px solid white' }}>
                  <tr>
                    {incidentColumns.map((column: string, index: number) => (
                      <th key={index}>
                        <span className="text-default font-weight-bold text-capitalize">{column}</span>
                        {['Complainants'].includes(column) && (
                          <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                            <Tooltip
                              id="complainaints"
                              title="Who is a complainant?"
                              jsx={
                                <>
                                  A complainant can be:
                                  <ul>
                                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                                      An employee who raises a complaint/grievance, for example, an employee alleging
                                      sexual harassment;
                                    </li>
                                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                                      An employee that observed alleged misconduct being perpetrated by a fellow
                                      employee and wishes to report it;
                                    </li>
                                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                                      A manager who has identified potential misconduct by an employee. It can either be
                                      the employee’s direct line manager or a person in a position of authority outside
                                      of the employee’s reporting line;
                                    </li>
                                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                                      An external party reporting alleged misconduct in the workplace.
                                    </li>
                                  </ul>
                                </>
                              }
                            />
                          </span>
                        )}
                        {['Summary of Allegations*'].includes(column) && (
                          <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                            <Tooltip
                              id="summaryOfFacts"
                              title="Alleged Incident(s)"
                              jsx={
                                <>
                                  Note all details of the alleged incident(s). This should include:
                                  <ul>
                                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                                      A summary of what allegedly happened - specific actions and behaviours;
                                    </li>
                                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                                      Date and timelines, if applicable;
                                    </li>
                                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                                      Specific information on location, if applicable;
                                    </li>
                                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                                      Who came forward with the allegations of misconduct;
                                    </li>
                                    <li style={{ listStyle: 'initial', marginLeft: '30px' }}>Possible witnesses.</li>
                                  </ul>
                                  This record will be used to inform further disciplinary steps to be taken and
                                  determine what transgressions may have been committed by the employee in question.
                                </>
                              }
                            />
                          </span>
                        )}
                        {['Witnesses'].includes(column) && (
                          <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                            <Tooltip
                              id="witness"
                              title="Who is a witness?"
                              text={`A witness is any individual who directly observed or who had first hand exposure to an alleged incident. Witnesses are generally employees of the organisation, but can  also  be  external  individuals.  Witnesses  cannot  be  forced  to  testify  or  give evidence in a disciplinary process.`}
                            />
                          </span>
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <FieldArray name={'incidents'}>
                    {(arrayHelpers: FieldArrayRenderProps) => (
                      <>
                        {!values.incidents && arrayHelpers.push({})}
                        {values.incidents &&
                          values.incidents.length &&
                          values.incidents.map((item: {}, index: number) => (
                            <tr style={{ height: 'auto' }} key={index}>
                              <td style={{ minWidth: '140px', maxWidth: '200px', height: 'auto' }}>
                                <TableFormField
                                  type={'textarea'}
                                  placeholder={'Summary of Allegations'}
                                  name={`incidents.${index}.summaryOfFacts`}
                                  className="square-radius border-0 h-25 pt-4"
                                />
                                <span className="text-danger">
                                  <ErrorMessage className="text-danger" name={`incidents.${index}.summaryOfFacts`} />
                                </span>
                              </td>
                              <td
                                className="position-relative"
                                style={{ minWidth: '60px', maxWidth: '80px', height: 'auto' }}
                              >
                                <TableFormField
                                  type={'date'}
                                  placeholder={'Date'}
                                  name={`incidents.${index}.date`}
                                  className="square-radius border-0 h-25"
                                />
                                <span className="text-danger">
                                  <ErrorMessage className="text-danger" name={`incidents.${index}.date`} />
                                </span>
                              </td>
                              <td className="position-relative" style={{ minWidth: '60px', maxWidth: '60px' }}>
                                <TableFormField
                                  type={'time'}
                                  placeholder={'Time'}
                                  name={`incidents.${index}.time`}
                                  className="square-radius border-0"
                                />
                                <span className="text-danger">
                                  <ErrorMessage className="text-danger" name={`incidents.${index}.time`} />
                                </span>
                              </td>
                              <td className="position-relative" style={{ minWidth: '100px', maxWidth: '120px' }}>
                                <TableFormField
                                  type={'text'}
                                  placeholder={'Enter Location'}
                                  name={`incidents.${index}.location`}
                                  className="square-radius border-0 h-25"
                                />
                                <span className="text-danger">
                                  <ErrorMessage className="text-danger" name={`incidents.${index}.location`} />
                                </span>
                              </td>
                              <td className="position-relative" style={{ minWidth: '120px', maxWidth: '140px' }}>
                                <TableFormField
                                  type={'asyncSelect'}
                                  placeholder="Select Employees"
                                  name={`incidents.${index}.complainants`}
                                  currentEmployeeId={employeeId}
                                />
                                <span className="text-danger">
                                  <ErrorMessage className="text-danger" name={`incidents.${index}.complainants`} />
                                </span>
                              </td>
                              <td className="position-relative" style={{ minWidth: '120px', maxWidth: '140px' }}>
                                <TableFormField
                                  type={'asyncSelect'}
                                  placeholder="Select Employees"
                                  name={`incidents.${index}.witnesses`}
                                />
                                <span className="text-danger">
                                  <ErrorMessage className="text-danger" name={`incidents.${index}.witnesses`} />
                                </span>
                              </td>
                              <td className="add-remove-icon">
                                {index === 0 ? (
                                  <span
                                    className="d-flex justify-content-center align-items-center"
                                    onClick={(): void => {
                                      if (!readOnly) {
                                        arrayHelpers.push({
                                          summaryOfFacts: '',
                                          date: '',
                                          time: '',
                                          location: '',
                                          complainants: null,
                                          witnesses: null,
                                        });
                                      } else {
                                        handleError(new Error('You cannot add new incidents'));
                                      }
                                    }}
                                  >
                                    <AddIcon />
                                  </span>
                                ) : (
                                  <span
                                    className="d-flex justify-content-center align-items-center"
                                    onClick={(): void => {
                                      if (!readOnly) {
                                        arrayHelpers.remove(index);
                                      } else {
                                        handleError(new Error('You cannot remove incidents'));
                                      }
                                    }}
                                  >
                                    <RemoveIcon />
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                      </>
                    )}
                  </FieldArray>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
      {values.caseType === CaseType.POOR_PERFORMANCE && (
        <>
          <Row>
            <Col>
              <div>
                <div className="d-flex">
                  <p className="text-primary text-default text-capitalize mt-4">
                    Examples of specific performance shortfalls *
                  </p>
                  <span style={{ width: '60%' }} className="align-self-center pl-2 pt-2 pointer">
                    <Tooltip
                      id="shortfalls"
                      title="Describe the performance shortfalls"
                      jsx={
                        <>
                          Describe the performance shortfalls experienced. This will form the basis for the performance
                          processes to come. The descriptions do not need to be lengthy; brevity is better as long as
                          the description is specific. Consider this format for describing each shortfall:
                          <br />
                          <br />
                          <ul>
                            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                              Describe when, where and how the performance issues occurred;
                            </li>
                            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                              Describe the standard missed, i.e. a role responsibility not fulfilled, a performance
                              goal/KPI missed, a competency goal missed;
                            </li>
                            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                              Note the impact of the shortfall (on the team, client, customer etc.).
                            </li>
                          </ul>
                          <br />
                          For example, if poor communication is a performance issue, describe specific
                          instances/examples of poor communication. Do not simply note that communication is poor or
                          that there have been several issues with communication.
                          <br />
                          <br />
                          Remember that incapacity poor performance implies that the employee lacks critical KSAs -
                          knowledge, skills, abilities, attitudes (generally referred to as competencies) - necessary to
                          perform their roles, and that these shortfalls are the cause of the employee’s inability to
                          perform to the required performance standards.
                        </>
                      }
                    />
                  </span>
                </div>
                <hr style={{ border: '0.06em solid #adb5bd' }} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="table-responsive-lg table-responsive-md" bordered>
                <thead>
                  <tr>
                    {shortfallsColumns.map((column: string, index: number) => (
                      <th key={index}>
                        <span className="text-default font-weight-bold text-capitalize">{column}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <FieldArray name={'performanceShortfalls'}>
                    {(arrayHelpers: FieldArrayRenderProps) => (
                      <>
                        {!values.performanceShortfalls &&
                          arrayHelpers.push({
                            shortfallDescription: '',
                            date: '',
                            documents: [
                              {
                                key: '',
                                fileName: '',
                                url: '',
                              },
                            ],
                          })}
                        {values.performanceShortfalls &&
                          values.performanceShortfalls.length &&
                          values.performanceShortfalls.map((item: CasePerformanceShortfall, index: number) => (
                            <tr style={{ height: 'auto' }} key={index}>
                              <td style={{ minWidth: '180px', maxWidth: '180px' }}>
                                <TableFormField
                                  type={'textarea'}
                                  placeholder={'Describe performance shortfall'}
                                  name={`performanceShortfalls.${index}.shortfallDescription`}
                                  className="square-radius border-0"
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name={`performanceShortfalls.${index}.shortfallDescription`}
                                  />
                                </span>
                              </td>
                              <DateRecurringToggle index={index} item={item} />
                              <td style={{ maxWidth: '80px', minWidth: '90px' }}>
                                <UploaderContainer
                                  fieldName={'performanceShortfalls'}
                                  hasIcon={true}
                                  onTable={true}
                                  index={index}
                                  path={`cases/${processInstanceId}`}
                                />
                                <span className="text-danger">
                                  <ErrorMessage
                                    className="text-danger"
                                    name={`performanceShortfalls.${index}.shortfallDocuments`}
                                  />
                                </span>
                              </td>
                              <td className="border-white bg-white">
                                {index === 0 ? (
                                  <Col
                                    style={{ maxWidth: '10px' }}
                                    // className="mt-3 pr-4 border-white"
                                    onClick={(): void =>
                                      arrayHelpers.push({
                                        shortfallDescription: '',
                                        date: '',
                                        documents: [
                                          {
                                            key: '',
                                            fileName: '',
                                            url: '',
                                          },
                                        ],
                                      })
                                    }
                                  >
                                    <AddIcon />
                                  </Col>
                                ) : (
                                  <Col
                                    style={{ maxWidth: '10px' }}
                                    className="mt-3 pr-4 border-white"
                                    onClick={(): void => arrayHelpers.remove(index)}
                                  >
                                    <RemoveIcon />
                                  </Col>
                                )}
                              </td>
                            </tr>
                          ))}
                      </>
                    )}
                  </FieldArray>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              <FormGroup>
                <Label for="jobTitle" className="text-default">
                  Job Title*
                </Label>
                <FormField type={'text'} placeholder="Job title" name="jobTitle" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'jobTitle'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="jobDescriptionOrKPIDocuments" className="text-default">
                  Upload Job Description and or Performance Objectives, if available
                </Label>
                <div>
                  <UploaderContainer fieldName={'jobDescriptionOrKPIDocuments'} path={`cases/${processInstanceId}`} />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <FormGroup>
                <Label for="performanceRatingHistory" className="text-default">
                  Performance rating history / Competency evaluation history, if available:
                </Label>
                <FormField type={'textarea'} placeholder="Add Information" name="performanceRatingHistory" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'performanceRatingHistory'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={2} className="d-flex align-self-center">
              <FormGroup>
                <span className="align-self-center pl-2 pointer">
                  <Tooltip
                    id="performanceRatingHistory"
                    title="Performance rating history/competency evaluation history "
                    jsx={
                      <>
                        For completeness, note the employee’s historical performance and/or competency ratings, if
                        available.
                        <br />
                        Performance ratings typically refer to Key Performance Areas and Indicators or Performance Goals
                        - driving deliverables or outputs.
                        <br />
                        Competency evaluations typically refer to the knowledge, skills, abilities and attitudes (KSA’s)
                        employees require to perform their job responsibilities - driving inputs.
                      </>
                    }
                  />
                </span>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

const DateRecurringToggle = (props: { index: number; item: { [key: string]: any } }) => {
  const { index, item } = props;
  const { setFieldValue, values } = useFormikContext<FormikValues>();

  useEffect(() => {
    if (values.performanceShortfalls[index].date) {
      setFieldValue(`performanceShortfalls.${index}.recurring`, 'NO');
    }
    if (values.performanceShortfalls[index].recurring === 'YES') {
      setFieldValue(`performanceShortfalls.${index}.date`, '');
    }
  }, [index, setFieldValue, values.performanceShortfalls]);

  return (
    <>
      <td className="position-relative" style={{ minWidth: '60px', maxWidth: '60px' }}>
        <TableFormField
          type={'date'}
          placeholder={'Date/Period'}
          name={`performanceShortfalls.${index}.date`}
          className="square-radius border-0 h-25"
        />
        <span className="text-danger">
          <ErrorMessage className="text-danger" name={`performanceShortfalls.${index}.date`} />
        </span>
      </td>
      <td className="position-relative" style={{ minWidth: '60px', maxWidth: '90px' }}>
        <TableFormField
          type={'checkbox-yes-and-no'}
          placeholder={''}
          name={`performanceShortfalls.${index}.recurring`}
          checkedString={item.recurring}
        />
      </td>
    </>
  );
};

export default NewCaseForm;
