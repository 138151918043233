import React, { useState } from 'react';
import { Column } from '../EmployeeUploader/DynamicEmployeeUploadTable';
import Tabs, { Tab } from './EmployeeUploaderTabs.component';
import './employee-uploader.styles.scss';
import { Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { EmployeeImportResults } from './EmployeeUploader';
import DynamicEmployeeUploadTableForEmployeeObjects from './DynamicEmployeeUploadTableForEmployeeObjects';

interface EmployeeUploaderCompleteScreenState {
  selectedPage: number;
}
interface EmployeeUploaderCompleteScreenProps {
  employeeResults: EmployeeImportResults;
}

export const EmployeeUploaderCompleteScreen: React.FC<EmployeeUploaderCompleteScreenProps> = (
  props: EmployeeUploaderCompleteScreenProps,
) => {
  const history = useHistory();
  const [state, setState] = useState<EmployeeUploaderCompleteScreenState>({
    selectedPage: 0,
  });

  const columns: Column[] = [
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'idNumber', label: 'ID Number' },
    { key: 'employeeNumber', label: 'Employee Number' },
    { key: 'included', label: 'Included' },
  ];

  const getTabs = (): Tab[] => {
    return [
      { tabName: 'Joiners', employeeCount: props.employeeResults.successfulJoiners.length },
      { tabName: 'Leavers', employeeCount: props.employeeResults.successfulLeavers.length },
      { tabName: 'Updated', employeeCount: props.employeeResults.successfulUpdated.length },
    ];
  };

  return (
    <div className="content">
      <div className="import-employee-header mb-4 mt-4 p-3">
        <h3 className="text-secondary mb-3">Employees Imported</h3>
        <p className="text-secondary"></p>
      </div>
      <Tabs
        tabs={getTabs()}
        page={state.selectedPage}
        changeTab={(pageTo: number): void => setState(oldState => ({ ...oldState, selectedPage: pageTo }))}
      />
      <Card>
        <CardBody>
          {state.selectedPage === 0 && (
            <DynamicEmployeeUploadTableForEmployeeObjects
              columns={columns}
              data={props.employeeResults.successfulJoiners}
            />
          )}
          {state.selectedPage === 1 && (
            <DynamicEmployeeUploadTableForEmployeeObjects
              columns={columns}
              data={props.employeeResults.successfulLeavers}
            />
          )}
          {state.selectedPage === 2 && (
            <DynamicEmployeeUploadTableForEmployeeObjects
              columns={columns}
              data={props.employeeResults.successfulUpdated}
            />
          )}
          <div className="mt-4">
            <button
              // disabled={!state.employeeValidationData}
              className="btn btn-primary float-right btn rounded-0"
              onClick={() => history.push('/organisation-hierarchy')}
            >
              Organisation Hierarchy
            </button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
