import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Column } from '../../Employee/ViewEmployees/DynamicTable';
import { listOrganisations } from '../../../graphql/queries';
import { list } from '../../../utils/graphql-utils';
import { CreateEmployeeInput } from '../../../API';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import { Link } from 'react-router-dom';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import DynamicTableLinkable from '../../Employee/ViewEmployees/DynamicTableLinkable';
import { PlusIcon, UserIcon } from '../../../components/icon/Icon.component';
import Loader from '../../../components/loader/Loader';
import { useErrorHandler } from '../../../utils/notification-utils';

interface OrganisationState {
  data?: CreateEmployeeInput[];
  loading: boolean;
}

const ViewOrganisation: React.FC = () => {
  const [state, setState] = useState<OrganisationState>({ data: [], loading: true });
  const handleError = useErrorHandler();
  useEffect(() => {
    list(listOrganisations)
      .then(res => {
        // WHY CASTING AS ANY!!!???
        if (res.data && (res.data as any).listOrganisations) {
          const data: CreateEmployeeInput[] = (res.data as any).listOrganisations.items;
          setState(oldState => ({ ...oldState, data: data, loading: false }));
        }
      })
      .catch(error => handleError(error));
  }, []);

  const columns: Column[] = [
    { key: 'organisationName', label: 'Organisation Name' },
    { key: 'mainContactFirstName', label: 'Main Contact First Name' },
    { key: 'mainContactLastName', label: 'Main Contact Last Name' },
    { key: 'mainContactEmail', label: 'Contact Email' },
    { key: 'mainContactNumber', label: 'Contact Number' },
    { key: 'maxEmployees', label: 'Max Employees' },
  ];

  return (
    <>
      <TopBarComponent title={'Organisations'} subTitle={'View Organisations'}>
        <Link to="/create-organisation">
          <ButtonWithIcons
            title={'Organisations'}
            leftIcon={<PlusIcon />}
            rightIcon={<UserIcon />}
            buttonType={'btn-bd-purple'}
          />
        </Link>
      </TopBarComponent>
      <div className="content">
        {state.loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <Row>
            <Col className="mb-5" md="12">
              <Card>
                <CardBody>
                  <DynamicTableLinkable data={state.data} columns={columns} linkPrefix="create-organisation" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default ViewOrganisation;
