import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  Form,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import React, { useContext } from 'react';
import { Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../components/taskContainer/TaskContainer';
import FormField from '../../../forms/fields/FormField.component';
import { CaseTableData } from '../../../utils/case-utils';
import { Tooltip } from '../../../components/tooltips/Tooltip.component';
import TransgressionInputs from './TransgressionInputs';

const incidentColumns = [
  'Summary of Allegations',
  'Date',
  'Time',
  'Transgression Category*',
  'Transgression*',
  'Frequency*',
  'Potential Sanction*',
  'Process*',
  // 'Action',
];

interface DisciplinaryFormProps {
  cases: CaseTableData[];
  setWarningsCallback: (index: number, message?: string) => void;
  warnings: { index: number; message: string }[];
  organisationId: string;
}

const DisciplinaryActionForm: React.FC<DisciplinaryFormProps> = (props: DisciplinaryFormProps) => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;

  return (
    <>
      <Form>
        <Row>
          <Col>
            <Label className="text-default d-block text-capitalize">Is Disciplinary action required?*</Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex">
              <div className="mr-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="yes"
                      checked={values.isDisciplinaryActionRequired}
                      onChange={(): void => {
                        setFieldValue('isDisciplinaryActionRequired', true);
                      }}
                      // disabled={readOnly}
                    />
                    <span className="form-check-sign">
                      <span className="check text-muted text-uppercase">yes</span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
              <div className="ml-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={!values.isDisciplinaryActionRequired}
                      onChange={(): void => {
                        setFieldValue('isDisciplinaryActionRequired', false);
                      }}
                      disabled={readOnly}
                    />
                    <span className="form-check-sign">
                      <span className="check text-default text-muted text-uppercase">no</span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4 mb-2">
          <Col md={6}>
            <FormGroup>
              <Label for="Motivate Decision" className="text-default text-capitalize">
                Motivate Decision*
              </Label>
              <FormField type={'textarea'} placeholder="Motivate Decision" name="motivationForDisciplinaryAction" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'motivationForDisciplinaryAction'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
        {values.isDisciplinaryActionRequired && (
          <>
            <Row>
              <Col>
                <h6
                  className="text-primary text-capitalize mt-4 font-weight-light"
                  style={{ fontSize: '14px', lineHeight: '17px' }}
                >
                  Incidents*
                </h6>
                <hr style={{ border: '0.06em solid #adb5bd' }} />
              </Col>
            </Row>

            <Row>
              <Col>
                <Table className="table-responsive-lg table-responsive-md" bordered>
                  <thead style={{ border: '1px solid white' }}>
                    <tr>
                      {incidentColumns.map((column: string, index: number) => (
                        <th key={index}>
                          <span className="text-blue text-capitalize font-weight-bold">{column}</span>
                          {column === 'Transgression*' && (
                            <span style={{ width: '100%' }} className="align-self-center pl-2 pointer">
                              <Tooltip
                                id="transgression"
                                title={'Transgression'}
                                text={
                                  'Select from the Disciplinary Code the most appropriate alleged transgression based on your Summary of Allegations. Add separate transgressions, if need be, if more than one offence has allegedly been committed. Avoid splitting of charges whereby you charge the employee with different transgressions based on the same set of facts, rather, just choose the most appropriate transgression from the list provided.'
                                }
                              />
                            </span>
                          )}
                          {column === 'Transgression Category*' && (
                            <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                              <Tooltip
                                title={'Category'}
                                id="transgressionCategory"
                                text={`Select from the Disciplinary Code the most appropriate category of offence based on your Summary of Allegations. A dropdown of alleged transgressions will then appear, from which you can choose the most appropriate one. Check other related categories as well`}
                              />
                            </span>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name={'incidents'}>
                      {(arrayHelpers: FieldArrayRenderProps) => (
                        <>
                          {!values.incidents && arrayHelpers.push({})}
                          {values.incidents &&
                            values.incidents.length &&
                            values.incidents.map((item: Record<string, unknown>, index: number) => (
                              <TransgressionInputs
                                key={index}
                                index={index}
                                cases={props.cases}
                                setWarningsCallback={props.setWarningsCallback}
                                organisationId={props.organisationId}
                                arrayHelpers={arrayHelpers}
                              />
                            ))}
                        </>
                      )}
                    </FieldArray>
                  </tbody>
                </Table>
                {props.warnings.length &&
                  props.warnings.map((item, index) => (
                    <span key={index} className="text-danger pb-1">
                      Warning on {item.message}
                    </span>
                  ))}
              </Col>
            </Row>
          </>
        )}
      </Form>
    </>
  );
};

export default DisciplinaryActionForm;
