import { FormConfig } from './form-config-types';
export const caseOverview: FormConfig = {
  fields: {
    caseType: {
      type: 'string',
      label: 'Case Type',
    },
    caseNumber: {
      type: 'string',
      label: 'Case Number',
    },
    incidents: {
      type: 'table',
      label: 'Incidents',
      tableColumns: ['summaryOfFacts', 'date', 'time', 'location', 'witnesses', 'complainants'],
      ignoredFields: ['value'],
    },
  },
};

export const considerInvestigation: FormConfig = {
  fields: {
    isFurtherInvestigationRequired: {
      type: 'boolean',
      label: 'Further Investigation Required',
    },
    shouldSuspendPendingInvestigation: {
      type: 'boolean',
      label: 'Suspend Employee Pending Investigation',
    },
  },
  nameOnTaskWidget: 'Consider investigation',
};

export const proceedWithInvestigation: FormConfig = {
  fields: {
    appointInvestigator: {
      type: 'boolean',
      label: 'Appoint Investigator',
    },
    investigatorType: {
      type: 'string',
      label: 'Investigator Type',
    },
    investigatorEmailAddress: {
      type: 'string',
      label: 'Investigator Email Address',
    },
    investigatorFirstName: {
      type: 'string',
      label: 'Investigator First Name',
    },
    investigatorLastName: {
      type: 'string',
      label: 'Investigator Last Name',
    },
    investigatorTimeline: {
      type: 'string',
      label: 'Investigator Timeline',
    },
  },
  nameOnTaskWidget: 'Execute investigation process',
};

export const completeInvestigation: FormConfig = {
  fields: {
    investigationReportDocuments: {
      type: 'documents',
      label: 'Investigation Report Documents',
    },
  },
  nameOnTaskWidget: 'Conduct investigation',
};

export const disciplinaryAction: FormConfig = {
  fields: {
    isDisciplinaryActionRequired: {
      type: 'boolean',
      label: 'Is Disciplinary Action Required',
    },
    motivationForDisciplinaryAction: {
      type: 'string',
      label: 'Motivation for Disciplinary Action',
    },
    incidents: {
      type: 'table',
      label: 'Incidents',
      tableColumns: [
        'summaryOfFacts',
        'date',
        'time',
        'transgressionCategory',
        'transgression',
        'frequency',
        'potentialSanction',
        'process',
      ],
    },
  },
  nameOnTaskWidget: 'Execute disciplinary action process',
};

export const initial_hearing_AppointEmployerRepresentative: FormConfig = {
  fields: {
    initial_hearing_suspendPendingHearing: {
      type: 'boolean',
      label: 'Suspend Employee Pending Hearing',
    },
    initial_hearing_employerRepresentativeType: {
      type: 'string',
      label: 'Employer Representative Type',
    },
    initial_hearing_employerRepresentativeEmailAddress: {
      type: 'string',
      label: 'Employer Representative Email Address',
    },
    initial_hearing_employerRepresentativeFirstName: {
      type: 'string',
      label: 'Employer Representative First Name',
    },
    initial_hearing_employerRepresentativeLastName: {
      type: 'string',
      label: 'Employer Representative Last Name',
    },
  },
};

export const recordSuspensionDiscussion: FormConfig = {
  fields: {
    suspensionDiscussionDate: {
      type: 'string',
      label: 'Suspension Discussion Date',
    },
    suspensionDiscussionTime: {
      type: 'string',
      label: 'Suspension Discussion Time',
    },
    suspensionDiscussionSummary: {
      type: 'string',
      label: 'Suspension Discussion Summary',
    },
    suspensionDiscussionAttendees: {
      type: 'table',
      label: 'Suspension Discussion Attendees',
      tableColumns: ['attendeeEmailAddress', 'attendeeName'],
    },
  },
};

export const makeSuspensionDecision: FormConfig = {
  fields: {
    suspensionDecision: {
      type: 'boolean',
      label: 'Suspend Employee?',
    },
    suspensionDecisionReason: {
      type: 'string',
      label: 'Suspension Decision Reason',
    },
    suspensionDecisionEffectiveDate: {
      type: 'string',
      label: 'Suspension Effective Date',
    },
  },
};

export const prepareSuspensionLetter: FormConfig = {
  fields: {
    hasReadSuspensionLetterGuidance: {
      type: 'boolean',
      label: 'Has Read Suspension Letter Guidance',
    },
    lastDocumentId: {
      type: 'string',
      label: 'Document ID',
      hideField: true,
    },
    lastDocumentType: {
      type: 'string',
      label: 'Document Type',
    },
    documentViewConfig: {
      type: 'documentViewer',
      label: 'View Document',
      templateDocumentFields: ['lastDocumentId', 'lastDocumentType'],
    },
  },
  hasDocument: true,
};

export const appointChairperson: FormConfig = {
  fields: {
    initial_hearing_chairpersonType: {
      type: 'string',
      label: 'Chairperson Type',
    },
    initial_hearing_chairpersonFirstName: {
      type: 'string',
      label: 'Chairperson First Name',
    },
    initial_hearing_chairpersonLastName: {
      type: 'string',
      label: 'Chairperson Last Name',
    },
    initial_hearing_chairpersonEmailAddress: {
      type: 'string',
      label: 'Chairperson Email Address',
    },
  },
};

export const selectWitness: FormConfig = {
  fields: {
    initial_hearing_hasWitness: {
      type: 'boolean',
      label: 'Witnesses Present?',
    },
    initial_hearing_hearingWitnesses: {
      type: 'table',
      label: 'Hearing Witnesses',
      tableColumns: ['firstName', 'lastName', 'emailAddress'],
    },
  },
};

export const hearingLogistics: FormConfig = {
  fields: {
    initial_hearing_hearingDate: {
      type: 'string',
      label: 'Hearing Date',
    },
    initial_hearing_hearingTime: {
      type: 'string',
      label: 'HearingTime',
    },
    initial_hearing_hearingLocation: {
      type: 'string',
      label: 'Hearing Location',
    },
    initial_hearing_chairpersonFirstName: {
      type: 'string',
      label: 'Chairperson First Name',
    },
    initial_hearing_chairpersonLastName: {
      type: 'string',
      label: 'Chairperson Last Name',
    },
    initial_hearing_chairpersonEmailAddress: {
      type: 'string',
      label: 'Chairperson Email Address',
    },
    initial_hearing_employerRepresentativeFirstName: {
      type: 'string',
      label: 'Employer Representative First Name',
    },
    initial_hearing_employerRepresentativeLastName: {
      type: 'string',
      label: 'Employer Representative Last Name',
    },
    initial_hearing_employerRepresentativeEmailAddress: {
      type: 'string',
      label: 'Employer Representative Email Address',
    },
    initial_hearing_hearingWitnesses: {
      type: 'table',
      label: 'Hearing Witnesses',
      tableColumns: ['firstName', 'lastName', 'emailAddress'],
    },
  },
};

export const compileNoticeOfHearing: FormConfig = {
  fields: {
    incidents: {
      type: 'table',
      label: 'Incidents',
      tableColumns: ['summaryOfFacts', 'date', 'time', 'transgression'],
    },
    lastDocumentId: {
      type: 'string',
      label: 'Document ID',
      hideField: true,
    },
    lastDocumentType: {
      type: 'string',
      label: 'Document Type',
    },
    documentViewConfig: {
      type: 'documentViewer',
      label: 'View Document',
      templateDocumentFields: ['lastDocumentId', 'lastDocumentType'],
    },
  },
  hasDocument: true,
};

export const employeeRightsAndResponses: FormConfig = {
  fields: {
    initial_hearing_requestedTranslator: {
      type: 'string',
      label: 'Requested Translator',
    },
    initial_hearing_language: {
      type: 'string',
      label: 'Hearing Language',
    },
    initial_hearing_requestedExternalRepresentation: {
      type: 'string',
      label: 'Requested External Representation',
    },
    initial_hearing_reasonForRequestingExternalRepresentation: {
      type: 'string',
      label: 'Reason for Requesting External Representation',
    },
    initial_hearing_EERepresentativePresent: {
      type: 'string',
      label: 'EE representative Present',
    },
    initial_hearing_reasonForEERepresentativePresence: {
      type: 'string',
      label: 'Reason for EE Representative Presence/Absence',
    },
    initial_hearing_disabledAndRequiresAccommodation: {
      type: 'string',
      label: 'Disabled and requires Accommodation',
    },
    initial_hearing_reasonForRequiringAccommodation: {
      type: 'string',
      label: 'Reason for requiring Accommodation',
    },
  },
};

export const minutesAndRecording: FormConfig = {
  fields: {
    initial_hearing_employerRepresentativeFirstName: {
      type: 'string',
      label: 'Employer Representative First Name',
    },
    initial_hearing_employerRepresentativeLastName: {
      type: 'string',
      label: 'Employer Representative Last Name',
    },
    initial_hearing_chairpersonFirstName: {
      type: 'string',
      label: 'Chairperson First Name',
    },
    initial_hearing_chairpersonLastName: {
      type: 'string',
      label: 'Chairperson Last Name',
    },
    initial_hearing_hearingWitnesses: {
      type: 'table',
      label: 'Hearing Witnesses',
      tableColumns: ['firstName', 'lastName', 'emailAddress', 'presentAtHearing'],
    },
    initial_hearing_hearingDate: {
      type: 'string',
      label: 'Hearing Date',
    },
    initial_hearing_hearingTime: {
      type: 'string',
      label: 'Hearing Time',
    },
    initial_hearing_hearingLocation: {
      type: 'string',
      label: 'Hearing Location',
    },
    initial_hearing_minutesOrRecordingsFiles: {
      type: 'documents',
      label: 'Minutes and Recordings',
    },
    initial_hearing_documentaryEvidenceFiles: {
      type: 'documents',
      label: 'Documentary Evidence',
    },
    initial_hearing_isChairpersonPresent: {
      type: 'boolean',
      label: 'Chairperson Present',
    },
    initial_hearing_isEmployeePresent: {
      type: 'boolean',
      label: 'Employee Present',
    },
    initial_hearing_isEmployerRepresentativePresent: {
      type: 'boolean',
      label: 'Employer Representative Present',
    },
    initial_hearing_reasonForDeclining: {
      type: 'string',
      label: 'Reason for Declining',
    },
  },
};

export const chairpersonRuling: FormConfig = {
  fields: {
    initial_hearing_chairpersonsRulingDocuments: {
      type: 'documents',
      label: 'Chairperson Ruling Documents',
    },
    initial_hearing_backgroundLeadingToHearing: {
      type: 'string',
      label: 'Background Leading to Hearing',
    },
    initial_hearing_employeeRightsExercisedInHearing: {
      type: 'string',
      label: 'Employee Rights exercised in Hearing',
    },
    initial_hearing_summaryOfEmployerRepresentativeEvidence: {
      type: 'string',
      label: 'Summary of Employer Representative Evidence',
    },
    initial_hearing_summaryOfEmployeesEvidence: {
      type: 'string',
      label: `Summary of Employee's evidence`,
    },
    initial_hearing_conclusionsReachedOnGuilt: {
      type: 'string',
      label: 'Conclusions Reached on Guilt',
    },
    initial_hearing_conclusionsReachedOnSanction: {
      type: 'string',
      label: 'Conclusions Reached on Sanction',
    },
    initial_hearing_summaryOfAggravatingFactors: {
      type: 'string',
      label: 'Summary of Aggravating Factors',
    },
    initial_hearing_summaryOfLegalPrinciples: {
      type: 'string',
      label: 'Summary of Legal Principles',
    },
    initial_hearing_findingsOnSanction: {
      type: 'string',
      label: 'Findings on Sanction',
    },
    initial_hearing_employeeCommentsAndMitigatingFactors: {
      type: 'string',
      label: 'Employee comments and mitigating factors',
    },
    incidents: {
      type: 'table',
      label: 'Disciplinary Findings on Guilt',
      tableColumns: ['transgression', 'initial_hearing_chairpersonsFindingOnGuilt'],
    },
  },
};

export const confirmSanction: FormConfig = {
  fields: {
    incidents: {
      type: 'table',
      label: 'transgression',
      tableColumns: [
        'summaryOfFacts',
        'date',
        'time',
        'transgression',
        'initial_hearing_chairpersonsFindingOnGuilt',
        'initial_hearing_chairpersonsSanction',
      ],
    },
    overallSanction: {
      type: 'string',
      label: 'Overall Sanction',
    },
  },
};

export const executeOutcome: FormConfig = {
  fields: {
    incidents: {
      type: 'table',
      label: 'Incidents',
      tableColumns: ['transgression', 'correctiveAction'],
    },
    hearingDate: {
      type: 'string',
      label: 'Date of Hearing',
    },
    warningValidityPeriod: {
      type: 'string',
      label: 'Warning Validity Period',
    },
    warningExpiryDate: {
      type: 'string',
      label: 'Warning Expiry Date',
    },
    dismissalDate: {
      type: 'string',
      label: 'Dismissal Date',
    },
    lastRemunerationDate: {
      type: 'string',
      label: 'Last remuneration date',
    },
    contactPersonInCaseOfCCMA: {
      type: 'string',
      label: 'Contact Person in Case of CCMA',
    },
    physicalAddress: {
      type: 'string',
      label: 'Physical Address',
    },
    emailAddress: {
      type: 'string',
      label: 'Email Address',
    },
    physicalExitDate: {
      type: 'string',
      label: 'Physical Exit Date',
    },
    periodOfUnpaidSuspension: {
      type: 'string',
      label: 'Period of unpaid suspension',
    },
    returnToWorkDate: {
      type: 'string',
      label: 'Return to work Date',
    },
    lastDocumentId: {
      type: 'string',
      label: 'Document ID',
      hideField: true,
    },
    lastDocumentType: {
      type: 'string',
      label: 'Document Type',
    },
    documentViewConfig: {
      type: 'documentViewer',
      label: 'View Document',
      templateDocumentFields: ['lastDocumentId', 'lastDocumentType'],
    },
  },
  hasDocument: true,
};

export const concludeHearing: FormConfig = {
  fields: {
    initial_hearing_deliveredSanctionOutcomeLetter: {
      type: 'boolean',
      label: 'Delivered Sanction Outcome Letter',
    },
    initial_hearing_acceptedDemotion: {
      type: 'string',
      label: 'Accepted Demotion',
    },
    initial_hearing_acceptedSuspensionWithoutPay: {
      type: 'string',
      label: 'Accepted Suspension Without Pay',
    },
  },
};

export const concludeHearingAppeal: FormConfig = {
  fields: {
    appeal_hearing_deliveredSanctionOutcomeLetter: {
      type: 'boolean',
      label: 'Delivered Sanction Outcome Letter',
    },
    appeal_hearing_acceptedDemotion: {
      type: 'string',
      label: 'Accepted Demotion',
    },
    appeal_hearing_acceptedSuspensionWithoutPay: {
      type: 'string',
      label: 'Accepted Suspension without pay',
    },
    appeal_hearing_chairpersonsRulingDocuments: {
      type: 'documents',
      label: 'Appeal Hearing Chairperson Ruling Documents',
    },
  },
};

export const startAppeal: FormConfig = {
  fields: {
    appealDeadline: {
      type: 'string',
      label: 'Appeal Deadline',
    },
    appealAllowed: {
      type: 'boolean',
      label: 'Appeal Allowed',
    },
    employeeHasIndicatedAppealIntent: {
      type: 'boolean',
      label: 'Employee has indicated intent to appeal',
    },
    appealReceivedWithinPrescribedTimeFrame: {
      type: 'boolean',
      label: 'Appeal Received within prescribed time frame',
    },
    proceedWithAppeal: {
      type: 'boolean',
      label: 'Proceed with appeal',
    },
    employeeAppealResponseDocuments: {
      type: 'documents',
      label: 'Employee Appeal Response Documents',
    },
    incidents: {
      type: 'table',
      label: 'Appeal Against',
      tableColumns: [
        'transgression',
        'initial_hearing_chairpersonsFinding',
        'didEmployeeAppealRuling',
        'initial_hearing_chairpersonsSanction',
        'didEmployeeAppealSanction',
      ],
    },
  },
};

export const appointEmployerRepresentativeAppeal: FormConfig = {
  fields: {
    appeal_hearing_employerRepresentativeType: {
      type: 'string',
      label: 'Appeal Employer Representative Type',
    },
    appeal_hearing_employerRepresentativeEmailAddress: {
      type: 'string',
      label: 'Appeal Employer Representative Email Address',
    },
    appeal_hearing_employerRepresentativeFirstName: {
      type: 'string',
      label: 'Appeal Employer Representative First Name',
    },
    appeal_hearing_employerRepresentativeLastName: {
      type: 'string',
      label: 'Appeal Employer Representative Last Name',
    },
    appeal_hearing_suspendPendingHearing: {
      type: 'boolean',
      label: 'Appeal Suspend Pending Hearing',
    },
  },
};

export const appointChairpersonAppeal: FormConfig = {
  fields: {
    appeal_hearing_chairpersonType: {
      type: 'string',
      label: 'Appeal Chairperson Type',
    },
    appeal_hearing_chairpersonFirstName: {
      type: 'string',
      label: 'Appeal Chairperson First Name',
    },
    appeal_hearing_chairpersonLastName: {
      type: 'string',
      label: 'Appeal Chairperson Last Name',
    },
    appeal_hearing_chairpersonEmailAddress: {
      type: 'string',
      label: 'Appeal Chairperson Email Address',
    },
  },
};

export const selectWitnessAppeal: FormConfig = {
  fields: {
    appeal_hearing_hasWitness: {
      type: 'boolean',
      label: 'Witnesses Present?',
    },
    appeal_hearing_hearingWitnesses: {
      type: 'table',
      label: 'Appeal Hearing Witnesses',
      tableColumns: ['firstName', 'lastName', 'emailAddress'],
    },
  },
};

export const hearingLogisticsAppeal: FormConfig = {
  fields: {
    appeal_hearing_hearingDate: {
      type: 'string',
      label: 'Appeal Hearing Date',
    },
    appeal_hearing_hearingTime: {
      type: 'string',
      label: 'Appeal Hearing Time',
    },
    appeal_hearing_hearingLocation: {
      type: 'string',
      label: 'Appeal Hearing Location',
    },
    appeal_hearing_ChairpersonFirstName: {
      type: 'string',
      label: 'Appeal Chairperson First Name',
    },
    appeal_hearing_ChairpersonLastName: {
      type: 'string',
      label: 'Appeal Chairperson Last Name',
    },
    appeal_hearing_ChairpersonEmailAddress: {
      type: 'string',
      label: 'Appeal Chairperson Email Address',
    },
    appeal_hearing_employerRepresentativeFirstName: {
      type: 'string',
      label: 'Appeal Employer Representative First Name',
    },
    appeal_hearing_employerRepresentativeLastName: {
      type: 'string',
      label: 'Appeal Employer Representative Last Name',
    },
    appeal_hearing_employerRepresentativeEmailAddress: {
      type: 'string',
      label: 'Appeal Employer Representative Email Address',
    },
    appeal_hearing_hearingWitnesses: {
      type: 'table',
      label: 'Appeal Hearing Witnesses',
      tableColumns: ['firstName', 'lastName', 'emailAddress'],
    },
  },
};

export const compileNoticeOfHearingAppeal: FormConfig = {
  fields: {
    incidents: {
      type: 'table',
      label: 'Incidents',
      tableColumns: ['summaryOfFacts', 'date', 'time', 'transgression'],
    },
  },
};

export const employeeRightsAndResponsesAppeal: FormConfig = {
  fields: {
    appeal_hearing_requestedTranslator: {
      type: 'string',
      label: 'Requested Translator (Appeal)',
    },
    appeal_hearing_language: {
      type: 'string',
      label: 'Hearing Language (Appeal)',
    },
    appeal_hearing_requestedExternalRepresentation: {
      type: 'string',
      label: 'Requested External Representation (Appeal)',
    },
    appeal_hearing_reasonForRequestingExternalRepresentation: {
      type: 'string',
      label: 'Reason for Requesting external Representation (Appeal)',
    },
    appeal_hearing_eERepresentativePresent: {
      type: 'string',
      label: 'EE Representative Present (Appeal)',
    },
    appeal_hearing_reasonForEeRepresentativePresence: {
      type: 'string',
      label: 'Reason for EE Representative (Appeal)',
    },
    appeal_hearing_disabledAndRequiresAccommodation: {
      type: 'string',
      label: 'Disabled and Requires accommodation (Appeal)',
    },
    appeal_hearing_reasonForRequiringAccommodation: {
      type: 'string',
      label: 'Reason for Requiring Accommodation (Appeal)',
    },
  },
};

export const minutesAndRecordingAppeal: FormConfig = {
  fields: {
    appeal_hearing_employerRepresentativeFirstName: {
      type: 'string',
      label: 'Employer Representative First Name (Appeal)',
    },
    appeal_hearing_employerRepresentativeLastName: {
      type: 'string',
      label: 'Employer Representative Last Name (Appeal)',
    },
    appeal_hearing_chairpersonFirstName: {
      type: 'string',
      label: 'Chairperson First Name (Appeal)',
    },
    appeal_hearing_chairpersonLastName: {
      type: 'string',
      label: 'Chairperson Last Name (Appeal)',
    },
    appeal_hearing_hearingWitnesses: {
      type: 'table',
      label: 'Hearing Witnesses (Appeal)',
      tableColumns: ['firstName', 'lastName', 'emailAddress', 'presentAtHearing'],
    },
    appeal_hearing_hearingDate: {
      type: 'string',
      label: 'Hearing Date (Appeal)',
    },
    appeal_hearing_hearingTime: {
      type: 'string',
      label: 'Hearing Time (Appeal)',
    },
    appeal_hearing_hearingLocation: {
      type: 'string',
      label: 'Hearing Location (Appeal)',
    },
    initial_hearing_minutesOrRecordingsFiles: {
      type: 'documents',
      label: 'Minutes and Recordings',
    },
    appeal_hearing_documentaryEvidenceFiles: {
      type: 'documents',
      label: 'Documentary Evidence (Appeal)',
    },
    appeal_hearing_isChairpersonPresent: {
      type: 'boolean',
      label: 'Chairperson Present (Appeal)',
    },
    appeal_hearing_isEmployeePresent: {
      type: 'boolean',
      label: 'Employee Present (Appeal)',
    },
    appeal_hearing_isEmployerRepresentativePresent: {
      type: 'boolean',
      label: 'Employer Representative Present (Appeal)',
    },
    appeal_hearing_reasonForDeclining: {
      type: 'string',
      label: 'Reason for Declining (Appeal)',
    },
  },
};

export const chairpersonsRulingAppeal: FormConfig = {
  fields: {
    appeal_hearing_chairpersonsRulingDocuments: {
      type: 'documents',
      label: 'Chairperson Ruling Documents',
    },
    appeal_hearing_backgroundLeadingToHearing: {
      type: 'string',
      label: 'Background leading to Hearing',
    },
    appeal_hearing_summaryOfAppellantsArguments: {
      type: 'string',
      label: 'Summary of AppellantsArguments',
    },
    appeal_hearing_summaryOfEmployerRepresentativesArguments: {
      type: 'string',
      label: `Summary of Employer Representative's arguments`,
    },
    appeal_hearing_summaryOfLegalPrinciples: {
      type: 'string',
      label: 'Summary of Legal Principles',
    },
    appeal_hearing_conclusionsReached: {
      type: 'string',
      label: 'Conclusions Reached',
    },
    appeal_hearing_newEvidenceConsidered: {
      type: 'string',
      label: 'New Evidence Considered',
    },
    incidents: {
      type: 'table',
      label: '',
      tableColumns: [
        'transgression',
        'appeal_hearing_chairpersonsFindingOnGuilt',
        'appeal_hearing_chairpersonsSanction',
      ],
    },
  },
};

export const confirmSanctionAppeal: FormConfig = {
  fields: {
    overallSanction: {
      type: 'string',
      label: 'Overall Sanction',
    },
    revisedOverallSanction: {
      type: 'string',
      label: 'Revised overall Sanction',
    },
    incidents: {
      type: 'table',
      label: 'Charges',
      tableColumns: [
        'transgression',
        'initial_hearing_chairpersonsFinding',
        'didEmployeeAppealRuling',
        'appeal_hearing_chairpersonsFinding',
        'initial_hearing_chairpersonsSanction',
        'didEmployeeAppealSanction',
        'appeal_hearing_chairpersonsSanction',
        'appeal_hearing_chairpersonConfirmedSanction',
      ],
    },
    lastDocumentId: {
      type: 'string',
      label: 'Document ID',
      hideField: true,
    },
    lastDocumentType: {
      type: 'string',
      label: 'Document Type',
    },
    documentViewConfig: {
      type: 'documentViewer',
      label: 'View Document',
      templateDocumentFields: ['lastDocumentId', 'lastDocumentType'],
    },
  },
  hasDocument: true,
};

export const ccmaDetailsA: FormConfig = {
  fields: {
    disputeReferredToCCMA: {
      type: 'boolean',
      label: 'Dispute referred to CCMA',
    },
    requestedCondonation: {
      type: 'boolean',
      label: 'Has Requested Condonation',
    },
    grantedCondonation: {
      type: 'boolean',
      label: 'Granted Condonation',
    },
    settledAtConciliation: {
      type: 'boolean',
      label: 'Settled at Conciliation',
    },
    lodgedObjection: {
      type: 'boolean',
      label: 'Has lodged Objection',
    },
    disputeReferralFormDocuments: {
      type: 'documents',
      label: 'Dispute Referral form documents',
    },
    certificateOfNoOutcomeDocuments: {
      type: 'documents',
      label: 'Certificate of no outcome documents',
    },
    settlementReached: {
      type: 'string',
      label: 'Settlement Reached',
    },
    furtherDetails: {
      type: 'string',
      label: 'Further details',
    },
    settlementValue:{
      type: 'string',
      label: 'Settlement (Rand value)',
    },
    employerRepresentativesAtConciliation: {
      type: 'table',
      label: 'Employer Representatives at Conciliation',
      tableColumns: ['email', 'employerRepresentativeName'],
    },
    conciliationDate: {
      type: 'string',
      label: 'Conciliation Date',
    },
    natureOfDispute: {
      type: 'string',
      label: 'Nature of Dispute',
    },
  },
};

export const ccmaDetailsB: FormConfig = {
  fields: {
    disputeReferredToCCMA: {
      type: 'boolean',
      label: 'Dispute Referred to CCMA',
    },
    arbitrationRulingInFavourOf: {
      type: 'string',
      label: 'Arbitration Ruling in favour of',
    },
    arbitrationRuling: {
      type: 'string',
      label: 'Arbitration Ruling',
    },
    furtherDetailsArbitration: {
      type: 'string',
      label: 'Further Details on Arbitration',
    },
    arbitrationAward: {
      type: 'string',
      label: 'Arbitration award (Rand value)',
    },
    professionalFees: {
      type: 'string',
      label: 'Professional fees incurred (Rand value)',
    },
    labourCourtRulingValue: {
      type: 'string',
      label: 'Labour Court ruling (Rand value)',
    },
    CCMAArbitrationRulingDocuments: {
      type: 'documents',
      label: 'CCMA Arbitration Ruling Documents',
    },
    externalEmployerRepresentativeNameArbitration: {
      type: 'string',
      label: 'External Employer Representative name at Arbitration',
    },
    arbitrationDate: {
      type: 'string',
      label: 'Arbitration Date',
    },
    extraArbitrationRulingDocuments: {
      type: 'documents',
      label: 'Extra Arbitration Ruling Documents',
    },
    disputeReferredToLabourCourt: {
      type: 'boolean',
      label: 'Dispute has been referred to labour court',
    },
    labourCourtRuling: {
      type: 'string',
      label: 'Labour court ruling',
    },
    labourCourtRulingInFavourOf: {
      type: 'string',
      label: 'Labour court ruling in favour of',
    },
    furtherDetailsLabourCourt: {
      type: 'string',
      label: 'Further Details (Labour court)',
    },
    labourCourtJudgementDocuments: {
      type: 'documents',
      label: 'Labour court judgement documents',
    },
    extraAdjudicationRulingDocuments: {
      type: 'documents',
      label: 'Extra adjudication Ruling Documents',
    },
    internalEmployerRepresentativeAdjudication: {
      type: 'table',
      label: 'Internal Employer Representative at Adjudication',
      tableColumns: ['email', 'representativeName'],
    },
    internalEmployerRepresentativeArbitration: {
      type: 'table',
      label: 'Internal Employer Representative at Arbitration',
      tableColumns: ['email', 'representativeName'],
    },
    externalEmployerRepresentativeNameAdjudication: {
      type: 'string',
      label: 'External Employer Representative at Adjudication',
    },
    adjudicationDate: {
      type: 'string',
      label: 'Adjudication Date',
    },
  },
};

export const conductDisciplinaryDiscussion: FormConfig = {
  fields: {
    disciplinaryDiscussionDate: {
      type: 'string',
      label: 'Disciplinary Discussion date',
    },
    disciplinaryDiscussionTime: {
      type: 'string',
      label: 'Disciplinary Discussion time',
    },
    disciplinaryDiscussionAttendees: {
      type: 'table',
      label: 'Disciplinary Discussion Attendees',
      tableColumns: ['attendeeName', 'attendeeEmailAddress'],
    },
  },
};

export const recordDisciplinaryDiscussion: FormConfig = {
  fields: {
    disciplinaryDiscussionDate: {
      type: 'string',
      label: 'Disciplinary Discussion date',
    },
    disciplinaryDiscussionTime: {
      type: 'string',
      label: 'Disciplinary Discussion time',
    },
    disciplinaryDiscussionAttendees: {
      type: 'table',
      label: 'Disciplinary Discussion Attendees',
      tableColumns: ['attendeeName', 'attendeeEmailAddress'],
    },
    disciplinaryDiscussionLocation: {
      type: 'string',
      label: 'Disciplinary Discussion location',
    },
    disciplinaryDiscussionSummary: {
      type: 'string',
      label: 'Disciplinary Discussion Summary',
    },
    disciplinaryDiscussionDocuments: {
      type: 'documents',
      label: 'Disciplinary Discussion documents',
    },
  },
};

export const confirmDisciplinaryDecision: FormConfig = {
  fields: {
    disciplinaryDiscussionDecision: {
      type: 'string',
      label: 'Disciplinary Discussion decision',
    },
    disciplinaryDecisionReason: {
      type: 'string',
      label: 'Disciplinary decision reason',
    },
  },
};

export const executeDisciplinaryAction: FormConfig = {
  fields: {
    warningValidityPeriod: {
      type: 'string',
      label: 'Warning validity Period (in weeks)',
    },
    warningExpiryDate: {
      type: 'string',
      label: 'Warning expiry date',
    },
    disciplinaryDiscussionTime: {
      type: 'string',
      label: 'Disciplinary Discussion time',
    },
    disciplinaryDiscussionDate: {
      type: 'string',
      label: 'Disciplinary Discussion date',
    },
    incidents: {
      type: 'table',
      label: 'Incidents',
      tableColumns: ['transgression', 'correctiveAction'],
    },
    lastDocumentId: {
      type: 'string',
      label: 'Document ID',
      hideField: true,
    },
    lastDocumentType: {
      type: 'string',
      label: 'Document Type',
    },
    documentViewConfig: {
      type: 'documentViewer',
      label: 'View Document',
      templateDocumentFields: ['lastDocumentId', 'lastDocumentType'],
    },
  },
  hasDocument: true,
};

export const misconductFormConfig: Record<string, FormConfig> = {
  'case-overview': caseOverview,
  'consider-investigation': considerInvestigation,
  'proceed-with-investigation': proceedWithInvestigation,
  'complete-investigation': completeInvestigation,
  'disciplinary-action': disciplinaryAction,
  'record-suspension-discussion': recordSuspensionDiscussion,
  'make-suspension-decision': makeSuspensionDecision,
  'prepare-suspension-letter': prepareSuspensionLetter,

  //initial hearing
  'appoint-employer-representative': initial_hearing_AppointEmployerRepresentative,
  'appoint-chairperson': appointChairperson,
  'select-witness': selectWitness,
  'hearing-logistics': hearingLogistics,
  'compile-notice-of-hearing': compileNoticeOfHearing,
  'employee-rights': employeeRightsAndResponses,
  'minutes-and-recording': minutesAndRecording,
  'chairpersons-ruling': chairpersonRuling,
  'confirm-sanction': confirmSanction,
  'execute-outcome': executeOutcome,
  'conclude-hearing-administration': concludeHearing,
  'appeal-process': startAppeal,

  // disciplinary discussion
  'conduct-disciplinary-discussion': conductDisciplinaryDiscussion,
  'record-disciplinary-discussion': recordDisciplinaryDiscussion,
  'confirm-disciplinary-decision': confirmDisciplinaryDecision,
  'execute-disciplinary-action': executeDisciplinaryAction,

  // appeal hearing
  'appoint-employer-representative-appeal': appointEmployerRepresentativeAppeal,
  'appoint-chairperson-appeal': appointChairpersonAppeal,
  'select-witness-appeal': selectWitnessAppeal,
  'hearing-logistics-appeal': hearingLogisticsAppeal,
  'compile-notice-of-hearing-appeal': compileNoticeOfHearingAppeal,
  'employee-rights-appeal': employeeRightsAndResponsesAppeal,
  'chairpersons-ruling-appeal': chairpersonsRulingAppeal,
  'confirm-sanction-appeal': confirmSanctionAppeal,
  'conclude-hearing-administration-appeal': concludeHearingAppeal,
  'minutes-and-recording-appeal': minutesAndRecordingAppeal,

  // ccma
  'ccma-details-a': ccmaDetailsA,
  'ccma-details-b': ccmaDetailsB,
};
