import React, { ReactElement, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { getFormDataFromCaseData, WorkflowComponentProps } from '../../WorkflowContainer/workflow-utils';
import { CaseAssessmentSchema } from '../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import CaseAssessmentForm from './CaseAssessmentForm';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';

const CaseAssessment: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData } = props.data;
  const fields = {
    remedialActionsInstituted: 'boolean',
    performanceReviewFeedbackGiven: 'boolean',
    performanceReviewFeedbackSummary: 'string',
    haveMetInformally: 'boolean',
    informalMeetingSummary: 'string',
    hasReceivedCoaching: 'boolean',
    coachingSummary: 'string',
    hasReceivedOnTheJobTraining: 'boolean',
    onTheJobTrainingSummary: 'string',
    hasReceivedClassroomBasedSupport: 'boolean',
    classroomBasedSupportSummary: 'string',
    extraInformation: 'boolean',
    extraInformationSummary: 'string',
    caseAssessmentSupportingDocuments: 'array',
  };

  const [data] = useState(() => getFormDataFromCaseData(fields, caseData, caseData.isAppeal));

  const submitValues = async (values: FormikValues) => {
    const formValues = { ...values };
    formValues.caseAssessmentSupportingDocuments = JSON.stringify(formValues.caseAssessmentSupportingDocuments);
    props.data.flowableFunctions.onNext(formValues);
  };
  return (
    <div className="content">
      <h4 className="text-default font-weight-bold">Case Overview</h4>
      <Formik initialValues={data} validationSchema={CaseAssessmentSchema} onSubmit={submitValues}>
        {({ values, errors, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
          <>
            <CaseAssessmentForm caseData={caseData} />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => props.data.flowableFunctions.onSaveAndClose(values)}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default CaseAssessment;
