import React, { useContext, useState } from 'react';
import XLSX from 'xlsx';
import { BulkUploader } from './BulkUploader';
import { ColumnHeading } from './ColumnHeadingEnums';
import { UserContext, UserContextProps, UserDetails } from '../../App';
import { handleTemplate, Row } from './upload-validation-utils';
import { EmployeeUploaderConfirmationScreen } from './EmployeeUploaderConfirmationScreen';
import { useErrorHandler } from '../../utils/notification-utils';
import { AttemptedEmployee } from './upload-validation-utils';
import { EmployeeUploaderCompleteScreen } from './EmployeeUploaderCompleteScreen';
import { importEmployees } from './uploadUploadDatabaseUtils';
import TopBarComponent from '../../components/topBar/TopBar.component';
import Loader from '../../components/loader/Loader';
import { CaseProgressBar } from '../../components/caseProgressBar/caseProgressBar';
import { OrganisationHierarchy } from '../OrganisationHierarchy/OrganisationHierarchy';
import { CreateEmployeeInput, UpdateEmployeeInput } from '../../API';
import AddEmployeeButton from '../../components/AddEmployeeButton/AddEmployeeButton';

//todo add hidden version number to excel file
//todo make column headers independent of column ordering

export interface EmployeeValidationData {
  joiners: AttemptedEmployee[];
  leavers: AttemptedEmployee[];
  updated: AttemptedEmployee[];
  invalidEmployees: AttemptedEmployee[];
}

export interface EmployeeImportResults {
  successfulJoiners: (UpdateEmployeeInput | CreateEmployeeInput)[];
  successfulUpdated: UpdateEmployeeInput[];
  successfulLeavers: UpdateEmployeeInput[];
}

interface SheetJSAppState {
  stepIndex: number;
  employeeValidationData: EmployeeValidationData | null;
  employeeImportResults: EmployeeImportResults | null;
  importComplete: boolean;
  file: any;
  errors: [];
  loading: boolean;
}

export const SheetJSApp: React.FC = () => {
  const [state, setState] = useState<SheetJSAppState>({
    stepIndex: 0,
    employeeValidationData: null,
    employeeImportResults: null,
    file: null,
    errors: [],
    loading: false,
    importComplete: false,
  });

  const handleError = useErrorHandler();

  const currentUser: UserDetails | null | undefined = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const columnHeadings: string[] = Object.keys(ColumnHeading).map(key => ColumnHeading[key]);

  const handleFile = (file: any, columnHeadings: string[]): void => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = async (e: any) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data: Row[] = XLSX.utils.sheet_to_json(ws, {
        header: columnHeadings,
        defval: '',
        blankrows: false,
        raw: true,
      });
      data.shift();

      await handleTemplate(data, currentUser)
        .then((res: EmployeeValidationData) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          res.joiners.forEach(employee => (employee.included = true));
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          res.leavers.forEach(employee => (employee['included'] = true));
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          res.updated.forEach(employee => (employee.included = true));
          setState(oldState => ({ ...oldState, employeeValidationData: res, loading: false }));
        })
        .catch(error => {
          handleError(error);
          setState(oldState => ({ ...oldState, loading: false, errors: error }));
        });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  const handleChange = (e: any): void => {
    setState(oldState => ({ ...oldState, loading: true }));
    const files = e.target.files;
    if (files && files[0]) handleFile(files[0], columnHeadings);
    setState(oldstate => ({ ...oldstate, file: files[0], errors: [], stepIndex: 1 }));
  };

  const onCancel = () => {
    setState(oldstate => ({
      ...oldstate,
      stepIndex: 0,
      file: null,
      errors: [],
      loading: false,
      employeeValidationData: null,
    }));
  };

  const steps = ['Upload File', 'Confirm Import', 'View Results', 'View Organisation Hierarchy'];

  const onClickImport = async (employeeValidationData: EmployeeValidationData) => {
    setState(oldState => ({ ...oldState, loading: true }));
    if (currentUser?.organisationId) {
      await importEmployees(
        currentUser?.organisationId,
        employeeValidationData.joiners.filter((item: AttemptedEmployee) => item.included),
        employeeValidationData.leavers.filter((item: AttemptedEmployee) => item.included),
        employeeValidationData.updated.filter((item: AttemptedEmployee) => item.included),
      )
        .then(res => {
          setState(oldState => ({ ...oldState, loading: false, stepIndex: 2, employeeImportResults: res }));
        })
        .catch(error => {
          setState(oldState => ({ ...oldState, loading: false }));
          handleError(error);
        });
    }
  };

  const renderScreens = (): JSX.Element => {
    if (state.stepIndex === 0) {
      return <BulkUploader handleChange={handleChange} />;
    } else if (state.stepIndex === 1 && state.employeeValidationData) {
      return (
        <EmployeeUploaderConfirmationScreen
          joiners={state.employeeValidationData.joiners}
          leavers={state.employeeValidationData.leavers}
          updated={state.employeeValidationData.updated}
          invalidEmployees={state.employeeValidationData.invalidEmployees}
          fileName={state.file?.name}
          onCancel={() => onCancel()}
          onClickImport={(employeeValidationData: EmployeeValidationData): Promise<any> =>
            onClickImport(employeeValidationData)
          }
        />
      );
    } else if (state.stepIndex === 2 && state.employeeImportResults) {
      return <EmployeeUploaderCompleteScreen employeeResults={state.employeeImportResults} />;
    } else if (state.stepIndex === 3) {
      return <OrganisationHierarchy />;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <TopBarComponent title={'Import Employees'} subTitle={' '}>
        <AddEmployeeButton />
      </TopBarComponent>
      {
        <div className="workflow-content">
          <div>
            <div className="mt-3 ">
              <CaseProgressBar steps={steps} stepIndex={state.stepIndex} />
            </div>
          </div>
        </div>
      }
      <div className="content">
        {state.loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          renderScreens()
        )}
      </div>
    </>
  );
};
