import React from 'react';
import TopBarComponent from '../../components/topBar/TopBar.component';

export const GrievancesInfoScreen: React.FC = () => {
  return (
    <>
      <TopBarComponent title={'Grievances'} subTitle={'View Grievances Module Information'}></TopBarComponent>
      <div className="content"></div>
    </>
  );
};
