import * as Yup from 'yup';

const CompleteInvestigationSchema = Yup.object().shape({
  investigationReportDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string(),
        key: Yup.string(),
        url: Yup.string(),
      }),
    )
    .required('Please upload a file')
    .nullable(),
});

export default CompleteInvestigationSchema;
