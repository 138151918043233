import { styles } from '../../PDF/PDFStyles.style';
import { Image, Page, Text } from '@react-pdf/renderer';
import Table from '../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../PDF/PDFTable/TableRow.component';
import TableCell from '../../PDF/PDFTable/TableCell.component';
import React from 'react';
import { DocumentJSONContent } from '../../../screens/DocumentEditor/DocumentEditor';

export interface ConfirmationOfReceiptPageProps {
  content: DocumentJSONContent;
}

export const ConfirmationOfReceiptPage = (props: ConfirmationOfReceiptPageProps): JSX.Element => {
  const getRecipientSignature = (): string => {
    console.log('sigg: ', props.content.recipientSignature);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return props.content.recipientSignature ? props.content.recipientSignature.value : '';
  };
  const getIssuerSignature = (): string => {
    console.log('sigg: ', props.content.issuerSignature);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return props.content.issuerSignature ? props.content.issuerSignature.value : '';
  };

  return (
    <Page style={styles.body} size="A4" wrap>
      <Text style={styles.title}>Confirmation of receipt:</Text>
      <Table>
        <TableHeader title="Issued by" />
        <TableRow>
          <TableCell>Name: </TableCell>
          <TableCell>{props.content.authorName.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Employee Number: </TableCell>
          <TableCell>{props.content.authorNumber.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Job Title: </TableCell>
          <TableCell>{props.content.authorJobTitle.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Department/Business Unit:</TableCell>
          <TableCell>{props.content.authorDepartment.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Signature:</TableCell>
          <TableCell>
            {props.content.issuerSignature && <Image style={styles.image} src={getIssuerSignature()} />}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Date Signed:</TableCell>
          <TableCell>{props.content.issuerSignedDate?.value}</TableCell>
        </TableRow>
        <TableRow>
          {/*<TableCell checkbox width={'8%'} />*/}
          <TableCell width={'92%'}>I confirm the document was issued to the employee.</TableCell>
        </TableRow>
      </Table>
      <Table>
        <TableHeader title="received by the employee" />
        <TableRow>
          <TableCell>Name: </TableCell>
          <TableCell>{props.content.employeeFirstName.value + ' ' + props.content.employeeLastName.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Employee Number: </TableCell>
          <TableCell>{props.content.employeeNumber.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Job Title: </TableCell>
          <TableCell>{props.content.employeeJobTitle?.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Department/Business Unit:</TableCell>
          <TableCell>{props.content.employeeDepartment.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Signature:</TableCell>
          <TableCell>
            {props.content.recipientSignature && <Image style={styles.image} src={getRecipientSignature()} />}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Date Signed:</TableCell>
          <TableCell>{props.content.recipientSignedDate?.value}</TableCell>
        </TableRow>
        <TableRow>
          {/*<TableCell checkbox width={'8%'} />*/}
          <TableCell width={'100%'}>I herewith confirm receipt.</TableCell>
        </TableRow>
      </Table>
      <Table>
        <TableHeader title="Witness*: only if employee refuses to sign" />
        <TableRow>
          <TableCell>Name: </TableCell>
          <TableCell />
        </TableRow>
        <TableRow>
          <TableCell>Employee Number: </TableCell>
          <TableCell />
        </TableRow>
        <TableRow>
          <TableCell>Job Title: </TableCell>
          <TableCell />
        </TableRow>
        <TableRow>
          <TableCell>Department/Business Unit:</TableCell>
          <TableCell />
        </TableRow>
        <TableRow>
          <TableCell>Signature:</TableCell>
          <TableCell />
        </TableRow>
        <TableRow>
          <TableCell>Date Signed:</TableCell>
          <TableCell />
        </TableRow>
        <TableRow>
          {/*<TableCell checkbox width={'8%'} />*/}
          <TableCell width={'100%'}>
            I confirm the employee refused to acknowledge receipt. A copy was handed to him/her in my presence.
          </TableCell>
        </TableRow>
      </Table>
    </Page>
  );
};
