import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import List from '../../../PDF/List.component';
import ListItem from '../../../PDF/ListItem.component';
import Paragraph from '../../../PDF/Paragraph.component';
import moment from 'moment';
import LetterHead from '../../../PDF/Letterhead.component';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const NoticeOfIncapacityHearingPDF: React.FC<DocumentProps> = props => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Confidential</Text>
        <Table>
          <TableHeader title="attention" />
          <TableRow>
            <TableCell>Name of Employee: </TableCell>
            <TableCell>{props.content.employeeFullName.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Employee Number: </TableCell>
            <TableCell>{props.content.employeeNumber.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Department/Business Unit</TableCell>
            <TableCell>{props.content.employeeDepartment.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{props.content.employeeAddress.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>{props.content.date.value}</TableCell>
          </TableRow>
        </Table>
        <Paragraph> Dear {props.content.employeeFirstName.value}, </Paragraph>
        <Text style={{ ...styles.subject }}>NOTICE OF INCAPACITY POOR PERFORMANCE HEARING</Text>
        <Paragraph>
          <Text>You are herewith informed that an Incapacity Poor Performance Hearing will take place as follows:</Text>
        </Paragraph>

        <Paragraph>
          <Text>
            DATE:{' '}
            {moment.isMoment(props.content.initial_hearing_hearingDate.value)
              ? ' ' + props.content.initial_hearing_hearingDate.value.format('DD-MM-YYYY')
              : props.content.initial_hearing_hearingDate.value}
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            TIME:{' '}
            {moment.isMoment(props.content.initial_hearing_hearingTime.value)
              ? ' ' + props.content.initial_hearing_hearingTime.value.format('DD-MM-YYYY')
              : props.content.initial_hearing_hearingTime.value}
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>PLACE: {props.content.initial_hearing_hearingLocation.value}</Text>
        </Paragraph>
        <Paragraph>
          <Text>
            The purpose of the hearing is to allow you to respond to the allegations of serious and/or continued poor
            performance made against you. At the hearing you will be given a full opportunity to state your case in
            response. Please see the attached Annexure containing details of the alleged sub-standard performance and/or
            shortfalls.
          </Text>
        </Paragraph>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ ...styles.text, ...styles.fonts }}>
            The Incapacity Poor Performance hearing shall be chaired by:
          </Text>
          <Text style={{ ...styles.text, ...styles.bold, marginTop: 2, paddingLeft: 3 }}>
            {props.content.initial_hearing_chairpersonFirstName.value +
              ' ' +
              props.content.initial_hearing_chairpersonLastName.value}
          </Text>
        </View>
        <Text style={{ ...styles.subject }}>EMPLOYEE RIGHTS</Text>
        <Paragraph>
          <Text>You have the following rights:</Text>
        </Paragraph>
        <List>
          <ListItem>You are entitled to lead your case and submit evidence, including documentation.</ListItem>
          <ListItem>
            You have the right to be represented/assisted by a fellow employee at the hearing and/or shop steward of a
            recognised union, if any in the workplace.
          </ListItem>
          <ListItem>
            You may call witnesses, but it is your own responsibility to secure their presence at the hearing. Their
            participation is voluntary. Please inform the employer at least 24 hours in advance of the hearing if you
            have arranged with any of its employees to appear as your witness in order for it to confirm the person’s
            absence from the workplace with his/her line manager.
          </ListItem>
          <ListItem>
            You or your representative, if any, are entitled to cross-examine witnesses called by the employer.
          </ListItem>
          <ListItem>
            You are entitled to confer with your representative, if any, at reasonable times before, during and after
            the hearing.
          </ListItem>
          <ListItem>
            You are entitled to furnish evidence and to argue in mitigation of proposed remedial actions and/or the way
            forward, as appropriate.
          </ListItem>
          <ListItem>
            The hearing will be conducted in English. You have the right to an interpreter. If an interpreter is
            required, this should be requested when the notification is issued, to allow the employer time to arrange
            this. Hearings may not be delayed due to late notification that an interpreter is required.
          </ListItem>
          <ListItem>
            You have the right to attend the hearing. However, should you fail or refuse to attend the hearing and fail
            to timeously provide the employer with an acceptable reason for your failure / refusal, the hearing may be
            held in your absence without any further notice to yourself and action taken accordingly.
          </ListItem>
        </List>
        <Paragraph>
          <Text>{props.content.other.value}</Text>
        </Paragraph>
        <Text style={{ ...styles.subject }}>CONCLUSION</Text>
        <Paragraph>
          <Text>
            Should you fail to understand the contents of this notice, please approach {props.content.authorName.value}{' '}
            to explain.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a
            copy to writer hereof in acknowledgement of receipt hereof, alternatively, acknowledge by way of return
            e-mail.
          </Text>
        </Paragraph>
        <Paragraph>Yours sincerely,</Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
      <Page style={styles.body} size="A4" wrap>
        <View>
          <Text style={{ ...styles.text, ...styles.bold }}>NOTES:</Text>
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ ...styles.text, ...styles.fonts }}>
              <Text>A translator is required / not. If so, state language__________________</Text>
            </Text>
          </View>
        </View>
      </Page>
      <Page style={styles.body} size="A4" wrap>
        <Text style={{ ...styles.subject }}>ANNEXURE – ALLEGED SUB-STANDARD PERFORMANCE AND/OR SHORTFALLS. </Text>
        <Paragraph />
        {Array.isArray(props.content.performanceShortfalls.value) &&
          props.content.performanceShortfalls.value.map((item: string, index: number) => {
            return (
              <>
                <Paragraph>
                  <Text key={index} style={{ ...styles.subject, textTransform: 'capitalize' }}>
                    Performance shortfall {index + 1}:
                  </Text>
                </Paragraph>
                <List>
                  <ListItem>{item}</ListItem>
                </List>
              </>
            );
          })}
      </Page>
    </Document>
  );
};
