import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import List from '../../../PDF/List.component';
import ListItem from '../../../PDF/ListItem.component';
import Paragraph from '../../../PDF/Paragraph.component';
import LetterHead from '../../../PDF/Letterhead.component';
import moment from 'moment';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const SummaryDismissalLetterPDF: React.FC<DocumentProps> = (props): JSX.Element => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Confidential</Text>
        <Table>
          <TableHeader title="attention" />
          <TableRow>
            <TableCell>Name of Employee: </TableCell>
            <TableCell>{props.content.employeeFullName.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Employee Number: </TableCell>
            <TableCell>{props.content.employeeNumber.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Department/Business Unit</TableCell>
            <TableCell>{props.content.employeeDepartment.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{props.content.employeeAddress.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>{props.content.date.value}</TableCell>
          </TableRow>
        </Table>
        <Paragraph>
          <Text> Dear {props.content.employeeFullName.value}, </Text>{' '}
        </Paragraph>
        <Text style={{ ...styles.subject }}>CONFIRMATION OF SUMMARY DISMISSAL DUE TO MISCONDUCT</Text>
        <Paragraph>
          <Text>
            A disciplinary hearing held on{' '}
            {moment.isMoment(props.content.initial_hearing_hearingDate.value)
              ? ' ' + props.content.initial_hearing_hearingDate.value.format('DD-MM-YYYY')
              : props.content.initial_hearing_hearingDate.value}{' '}
            refers.
          </Text>
        </Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>
          You pleaded guilty, alternatively, were found guilty on the following charges:
        </Text>
        <Table>
          {props.content.charges_guilty.value &&
            Array.isArray(props.content.charges_guilty.value) &&
            props.content.charges_guilty.value.map((item: string, index: number) => (
              <TableRow key={index}>
                <TableCell width={'100%'}>{item} </TableCell>
              </TableRow>
            ))}
        </Table>
        <Text style={{ ...styles.text, ...styles.fonts }}>
          Place: {props.content.initial_hearing_hearingLocation.value}
        </Text>
        <Paragraph>
          <Text>
            Dismissal was found to be the applicable sanction in the circumstances by the chairperson of the hearing
            having considered aggravating and mitigating factors, as applicable.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            This serves as confirmation that your services are terminated summarily (i.e. with immediate effect).
          </Text>
        </Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>In light of the above we confirm the following: </Text>
        <List>
          <ListItem>
            Your last day of employment with the company will be:
            {moment.isMoment(props.content.dismissalDate.value)
              ? ' ' + props.content.dismissalDate.value.format('DD-MM-YYYY')
              : props.content.dismissalDate.value}{' '}
            (“termination date”) and no further services will be rendered with immediate effect;
          </ListItem>
          <ListItem>
            Final remuneration is payable up to:
            {moment.isMoment(props.content.finalRemunerationDate.value)
              ? ' ' + props.content.finalRemunerationDate.value.format('DD-MM-YYYY')
              : props.content.finalRemunerationDate.value}{' '}
          </ListItem>
          <ListItem>Any accrued leave as at the termination date will be paid;</ListItem>
          <ListItem>
            Membership of all employer schemes/funds, as applicable, will also terminate on this date as will the
            employer’s obligations in this regard;
          </ListItem>
          <ListItem>A Certificate of Service will be issued to you;</ListItem>
          <ListItem>Appropriate UIF documentation will be completed and issued, as required</ListItem>
          <ListItem>
            You are to return all company property on receipt hereof, should you still have any such in your possession;
          </ListItem>
          <ListItem>Confidentiality undertakings continue to apply.</ListItem>
        </List>
        <Paragraph>
          <Text>{props.content.other.value}</Text>
        </Paragraph>
        <Paragraph>
          <Text>
            Information on appeal procedures, if any are stipulated in the employer’s disciplinary policy and
            procedures, will be provided on receipt hereof.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            You have the right to refer the matter to the Commission for Conciliation, Mediation & Arbitration (CCMA) or
            the relevant Bargaining Council Dispute Forum, as appropriate, should you so wish within 30 days of the
            termination date in which case all documents must be served at the following address:
            {props.content.contactName.value}, {props.content.contactAddress.value}, {props.content.contactEmail.value}
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>Please liaise with {props.content.authorName.value} in case of queries.</Text>
        </Paragraph>
        <Paragraph>
          <Text>
            This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a
            copy to writer hereof in acknowledgement of receipt hereof, alternatively, acknowledge by way of return
            e-mail.
          </Text>
        </Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>Yours Sincerely,</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
    </Document>
  );
};
