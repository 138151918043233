import { DocumentConfig } from './document-config-types';
import {
  AgreedDemotionPDF,
  AppealOutcomeLetterPDF,
  DemotionLetterPDF,
  DismissalWithNoticeLetterPDF,
  ExtensionOfPIPFollowingHearingPDF,
  ExtensionOfPIPPDF,
  FinalWrittenWarningLetterDiscussionPDF,
  FinalWrittenWarningLetterHearingPDF,
  NoticeOfAppealHearingPDF,
  NoticeOfHearingPDF,
  NoticeOfIncapacityHearingPDF,
  PerformanceImprovementPlanPDF,
  PIPConclusionLetterPDF,
  PoorPerformanceAppealFindingsFormPDF,
  PoorPerformanceAppealFormPDF,
  PoorPerformanceAppealOutcomePDF,
  PoorPerformanceDismissalWithNoticePayLetterPDF,
  PoorPerformanceHearingFindingsFormPDF,
  PoorPerformanceNoticeOfAppealHearingPDF,
  PoorPerformanceSummaryOfDismissalLetterPDF,
  SummaryDismissalLetterPDF,
  SuspensionLetterPendingInvestigationPDF,
  SuspensionPendingHearingLetterPDF,
  SuspensionWithoutPayLetterPDF,
  VerbalWarningLetterDiscussionPDF,
  WrittenWarningLetterDiscussionPDF,
  WrittenWarningLetterHearingPDF,
} from '../../components/documents';
import { EmailType } from '../../utils/email-utils';
import { TemplateType } from '../../API';
import { PoorPerformanceDemotionLetterPDF } from '../../components/documents/poorPerformance';

export const documentConfigs: Record<TemplateType, DocumentConfig> = {
  SUSPENSION_PENDING_INVESTIGATION_LETTER: {
    name: 'Suspension Letter',
    component: SuspensionLetterPendingInvestigationPDF,
    emailType: EmailType.ISSUE_DOCUMENT_SUSPENSION_LETTER,
    ltTasksToCompleteOnIssuing: ['draft-new-suspension-letter'],
  },
  SUSPENSION_PENDING_HEARING_LETTER: {
    name: 'Suspension Letter',
    component: SuspensionPendingHearingLetterPDF,
    emailType: EmailType.ISSUE_DOCUMENT_SUSPENSION_LETTER,
    ltTasksToCompleteOnIssuing: [],
  },
  SUSPENSION_WITHOUT_PAY_LETTER: {
    name: 'Suspension Letter',
    component: SuspensionWithoutPayLetterPDF,
    emailType: EmailType.ISSUE_DOCUMENT_HEARING_OUTCOME_MISCONDUCT,
    ltTasksToCompleteOnIssuing: [],
  },
  WRITTEN_WARNING_DISCUSSION: {
    name: 'Written Warning',
    component: WrittenWarningLetterDiscussionPDF,
    emailType: EmailType.ISSUE_DOCUMENT_BY_EMAIL,
    ltTasksToCompleteOnIssuing: ['draft-new-warning'],
  },
  WRITTEN_WARNING_HEARING: {
    name: 'Written Warning',
    component: WrittenWarningLetterHearingPDF,
    emailType: EmailType.ISSUE_DOCUMENT_BY_EMAIL,
    ltTasksToCompleteOnIssuing: [],
  },
  VERBAL_WARNING_DISCUSSION: {
    name: 'Verbal Warning',
    component: VerbalWarningLetterDiscussionPDF,
    emailType: EmailType.ISSUE_DOCUMENT_BY_EMAIL,
    ltTasksToCompleteOnIssuing: ['draft-new-warning'],
  },
  FINAL_WRITTEN_WARNING_DISCUSSION: {
    name: 'Final Written Warning',
    component: FinalWrittenWarningLetterDiscussionPDF,
    emailType: EmailType.ISSUE_DOCUMENT_BY_EMAIL,
    ltTasksToCompleteOnIssuing: ['draft-new-warning'],
  },
  FINAL_WRITTEN_WARNING_HEARING: {
    name: 'Final Written Warning',
    component: FinalWrittenWarningLetterHearingPDF,
    emailType: EmailType.ISSUE_DOCUMENT_HEARING_OUTCOME_MISCONDUCT,
    ltTasksToCompleteOnIssuing: [],
  },

  //****************************************************************************

  NOTICE_OF_HEARING_MISCONDUCT: {
    name: 'Notice Of Hearing',
    component: NoticeOfHearingPDF,
    emailType: EmailType.ISSUE_DOCUMENT_NOTICE_OF_HEARING_MISCONDUCT,
    ltTasksToCompleteOnIssuing: ['draft-new-notice-of-hearing', 'draft-new-notice-of-hearing-er'],
  },
  NOTICE_OF_HEARING_POOR_PERFORMANCE: {
    name: 'Notice of Hearing',
    component: NoticeOfIncapacityHearingPDF,
    emailType: EmailType.ISSUE_DOCUMENT_NOTICE_OF_HEARING_POOR_PERFORMANCE,
    ltTasksToCompleteOnIssuing: ['draft-new-notice-of-hearing', 'draft-new-notice-of-hearing-er'],
  },
  NOTICE_OF_HEARING_MISCONDUCT_APPEAL: {
    name: 'Notice of Appeal Hearing',
    component: NoticeOfAppealHearingPDF,
    emailType: EmailType.ISSUE_DOCUMENT_NOTICE_OF_HEARING_MISCONDUCT_APPEAL,
    ltTasksToCompleteOnIssuing: ['draft-new-notice-of-hearing-appeal', 'draft-new-notice-of-hearing-appeal-er'],
  },
  NOTICE_OF_HEARING_POOR_PERFORMANCE_APPEAL: {
    name: 'Notice of Appeal Hearing',
    component: PoorPerformanceNoticeOfAppealHearingPDF,
    emailType: EmailType.ISSUE_DOCUMENT_NOTICE_OF_HEARING_POOR_PERFORMANCE_APPEAL,
    ltTasksToCompleteOnIssuing: ['draft-new-notice-of-hearing-appeal', 'draft-new-notice-of-hearing-appeal-er'],
  },
  //TODO: think we host a static form for this?
  APPEAL_FORM_POOR_PERFORMANCE: {
    name: 'Appeal Form',
    component: PoorPerformanceAppealFormPDF,
    emailType: EmailType.EMPLOYEE_APPEAL_FORM_POOR_PERFORMANCE,
    ltTasksToCompleteOnIssuing: [],
  },
  HEARING_FINDINGS_FORM_POOR_PERFORMANCE: {
    name: 'Hearing Findings Form',
    component: PoorPerformanceHearingFindingsFormPDF,
    emailType: null,
    ltTasksToCompleteOnIssuing: [],
  },
  HEARING_FINDINGS_FORM_POOR_PERFORMANCE_APPEAL: {
    name: 'Appeal Findings Form',
    component: PoorPerformanceAppealFindingsFormPDF,
    emailType: null,
    ltTasksToCompleteOnIssuing: [],
  },
  //TODO where are the hearing outcome docs for the initial hearing?
  HEARING_OUTCOME_MISCONDUCT_APPEAL: {
    name: 'Appeal Outcome Letter',
    component: AppealOutcomeLetterPDF,
    emailType: EmailType.ISSUE_DOCUMENT_HEARING_OUTCOME_MISCONDUCT_APPEAL,
    ltTasksToCompleteOnIssuing: [],
  },
  HEARING_OUTCOME_POOR_PERFORMANCE_APPEAL: {
    name: 'Appeal Outcome Letter',
    component: PoorPerformanceAppealOutcomePDF,
    emailType: EmailType.ISSUE_DOCUMENT_HEARING_OUTCOME_POOR_PERFORMANCE_APPEAL,
    ltTasksToCompleteOnIssuing: [],
  },
  SUMMARY_DISMISSAL_LETTER_MISCONDUCT: {
    name: 'Summary Dismissal Letter',
    component: SummaryDismissalLetterPDF,
    emailType: EmailType.ISSUE_DOCUMENT_HEARING_OUTCOME_MISCONDUCT,
    ltTasksToCompleteOnIssuing: ['draft-new-outcome', 'draft-new-outcome-appeal'],
  },
  SUMMARY_DISMISSAL_LETTER_POOR_PERFORMANCE: {
    name: 'Summary Dismissal Letter',
    component: PoorPerformanceSummaryOfDismissalLetterPDF,
    emailType: EmailType.ISSUE_DOCUMENT_HEARING_OUTCOME_POOR_PERFORMANCE,
    ltTasksToCompleteOnIssuing: ['draft-new-outcome', 'draft-new-outcome-appeal'],
  },
  DISMISSAL_WITH_NOTICE_LETTER: {
    name: 'Dismissal Letter',
    component: DismissalWithNoticeLetterPDF,
    emailType: EmailType.ISSUE_DOCUMENT_HEARING_OUTCOME_MISCONDUCT,
    ltTasksToCompleteOnIssuing: ['draft-new-outcome', 'draft-new-outcome-appeal'],
  },
  DEMOTION_LETTER_MISCONDUCT: {
    name: 'Demotion Letter',
    component: DemotionLetterPDF,
    emailType: EmailType.ISSUE_DOCUMENT_HEARING_OUTCOME_MISCONDUCT,
    ltTasksToCompleteOnIssuing: ['draft-new-outcome', 'draft-new-outcome-appeal'],
  },
  DEMOTION_LETTER_POOR_PERFORMANCE: {
    name: 'Demotion Letter',
    component: PoorPerformanceDemotionLetterPDF,
    emailType: EmailType.ISSUE_DOCUMENT_HEARING_OUTCOME_POOR_PERFORMANCE,
    ltTasksToCompleteOnIssuing: ['draft-new-outcome', 'draft-new-outcome-appeal'],
  },

  //************************ PIPs ************************

  PERFORMANCE_IMPROVEMENT_PLAN: {
    name: 'Performance Improvement Plan',
    component: PerformanceImprovementPlanPDF,
    emailType: EmailType.ISSUE_DOCUMENT_DRAFT_PIP,
    ltTasksToCompleteOnIssuing: ['draft-new-pip'],
  },
  PERFORMANCE_IMPROVEMENT_PLAN_REVISED: {
    name: 'Performance Improvement Plan',
    component: PerformanceImprovementPlanPDF,
    emailType: EmailType.ISSUE_DOCUMENT_FINAL_PIP,
    ltTasksToCompleteOnIssuing: ['draft-new-pip'],
  },
  EXTENSION_OF_PIP: {
    name: 'Extended PIP',
    component: ExtensionOfPIPPDF,
    emailType: EmailType.ISSUE_DOCUMENT_DRAFT_EXTENDED_PIP,
    ltTasksToCompleteOnIssuing: ['draft-new-pip'],
  },
  EXTENSION_OF_PIP_REVISED: {
    name: 'Extended PIP',
    component: ExtensionOfPIPPDF,
    emailType: EmailType.ISSUE_DOCUMENT_FINAL_EXTENDED_PIP,
    ltTasksToCompleteOnIssuing: ['draft-new-pip'],
  },
  EXTENSION_OF_PIP_FOLLOWING_HEARING: {
    name: 'Final Written Warning with PIP',
    component: ExtensionOfPIPFollowingHearingPDF,
    emailType: EmailType.ISSUE_DOCUMENT_DRAFT_EXTENDED_PIP,
    ltTasksToCompleteOnIssuing: ['draft-new-pip', 'draft-new-outcome', 'draft-new-outcome-appeal'],
  },
  EXTENSION_OF_PIP_FOLLOWING_HEARING_REVISED: {
    name: 'Final Written Warning with PIP',
    component: ExtensionOfPIPFollowingHearingPDF,
    emailType: EmailType.ISSUE_DOCUMENT_FINAL_EXTENDED_PIP,
    ltTasksToCompleteOnIssuing: ['draft-new-pip', 'draft-new-outcome', 'draft-new-outcome-appeal'],
  },

  //******************************************************

  SUCCESSFUL_CONCLUSION_PERFORMANCE_PROCESS_LETTER: {
    name: 'Performance Process Concluded Letter',
    component: PIPConclusionLetterPDF,
    emailType: EmailType.ISSUE_PIP_SUCCESSFULLY_COMPLETED_LETTER,
    ltTasksToCompleteOnIssuing: [],
  },
  AGREED_DEMOTION_LETTER: {
    name: 'Demotion Letter',
    component: AgreedDemotionPDF,
    emailType: EmailType.ISSUE_DOCUMENT_BY_EMAIL,
    ltTasksToCompleteOnIssuing: ['draft-new-agreed-demotion'],
  },
  DISMISSAL_WITH_NOTICE_PAY_LETTER_POOR_PERFORMANCE: {
    name: 'Dismissal Letter',
    component: PoorPerformanceDismissalWithNoticePayLetterPDF,
    emailType: EmailType.ISSUE_DOCUMENT_HEARING_OUTCOME_POOR_PERFORMANCE,
    ltTasksToCompleteOnIssuing: ['draft-new-outcome', 'draft-new-outcome-appeal'],
  },
};
