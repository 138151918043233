import { ErrorMessage, Field, FieldAttributes, FormikValues, useFormikContext } from 'formik';
import React, { useContext } from 'react';
import AsyncSelect from 'react-select/async';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import {
  selectStyles,
  SelectType,
  ValueContainer,
} from '../../../components/reactSelect/ReactSelectComponents.component';
import UploaderContainer from '../../../components/Uploader/UploaderContainer';
import FormField from '../../../forms/fields/FormField.component';
import { ValueType } from 'react-select';
import { UserContext, UserContextProps } from '../../../App';
import { listActiveEmployeesByOrganisationId } from '../../../utils/graphql-utils';
import { WorkflowComponentProps } from '../../WorkflowContainer/workflow-utils';
import { Employee } from '../../../models';
import ApiDataContext from '../../../contexts';

const CCMADetailsAForm: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const { values } = useFormikContext<FormikValues>();
  const { setFieldValue } = useFormikContext();

  const employeeListApiData = useContext(ApiDataContext);

  const handleChange = (value: { value: string; label: string }, fieldName: string) => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data.map((employee: Employee) => {
      const emailAddresses = employee.emails!.map(item => item!.address);
      return {
        label: employee.firstName + ' ' + employee.lastName,
        employerRepresentativeName: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
        email: emailAddresses[0],
      };
    });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  // const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
  //   if (currentUser?.organisationId) {
  //     return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
  //       const preparedData = prepareData(data);
  //       return !inputValue ? preparedData : filterItems(preparedData, inputValue);
  //     });
  //   }
  // };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId && typeof employeeListApiData.initialEmployeesPage === 'undefined') {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        employeeListApiData.employees = !inputValue ? preparedData : filterItems(preparedData, inputValue);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    } else {
      return !inputValue
        ? employeeListApiData.initialEmployeesPage
        : filterItems(employeeListApiData.initialEmployeesPage, inputValue);
    }
  };

  return (
    <Form>
      <Row>
        <Col md={4}>
          <Label className="text-default d-block mt-2">
            Was a dispute referred to the CCMA/Bargaining Council for conciliation?
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.disputeReferredToCCMA}
                    onChange={(): void => {
                      setFieldValue('disputeReferredToCCMA', true);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.disputeReferredToCCMA}
                    onChange={(): void => {
                      setFieldValue('disputeReferredToCCMA', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="natureOfDispute" className="text-default">
              What was the nature of the dispute?*
            </Label>
            <FormField
              name="natureOfDispute"
              placeholder="Select Dispute type"
              type="select"
              selectOptions={[
                'Unfair Dismissal Misconduct',
                'Unfair dismissal incapacity poor performance',
                'Unfair dismissal incapacity ill health/disability ',
                'Unfair dismissal retrenchment',
                'Unfair labour practice',
                'Unfair discrimination',
                'Constructive dismissal',
                'Other',
              ]}
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'natureOfDispute'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      {values.disputeReferredToCCMA && (
        <Row>
          <Col md={4} className="mt-3">
            <FormGroup>
              <Label for="exampleFile" className="text-default ">
                Upload dispute referral form*
              </Label>
              <UploaderContainer
                fieldName={'disputeReferralFormDocuments'}
                path={`cases/${props.data.masterProcessInstanceId}`}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={4}>
          <Label className="text-default d-block mt-2">Was condonation requested?</Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.requestedCondonation === true}
                    onChange={(): void => {
                      setFieldValue('requestedCondonation', true);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.requestedCondonation}
                    onChange={(): void => {
                      setFieldValue('requestedCondonation', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.requestedCondonation === 'NOT APPLICABLE'}
                    onChange={(): void => {
                      setFieldValue('requestedCondonation', 'NOT APPLICABLE');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">not applicable</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Label className="text-default d-block mt-2">Was condonation granted?</Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.grantedCondonation && values.requestedCondonation !== 'NOT APPLICABLE'}
                    onChange={(): void => {
                      setFieldValue('grantedCondonation', true);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.grantedCondonation && values.requestedCondonation !== 'NOT APPLICABLE'}
                    onChange={(): void => {
                      setFieldValue('grantedCondonation', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.requestedCondonation === 'NOT APPLICABLE'}
                    onChange={(): void => {
                      setFieldValue('grantedCondonation', 'NOT APPLICABLE');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">not applicable</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Label className="text-default d-block mt-2">Was an objection lodged against a con-arb process?</Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.lodgedObjection === true}
                    onChange={(): void => {
                      setFieldValue('lodgedObjection', true);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.lodgedObjection}
                    onChange={(): void => {
                      setFieldValue('lodgedObjection', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={values.lodgedObjection === 'NOT APPLICABLE'}
                    onChange={(): void => {
                      setFieldValue('lodgedObjection', 'NOT APPLICABLE');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">not applicable</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Label className="text-default d-block mt-2">Was the matter settled at conciliation?</Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.settledAtConciliation === true}
                    onChange={(): void => {
                      setFieldValue('settledAtConciliation', true);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.settledAtConciliation}
                    onChange={(): void => {
                      setFieldValue('settledAtConciliation', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="mt-3">
          <FormGroup>
            <Label for="exampleFile" className="text-default ">
              Upload Certificate of No Outcome OR Settlement Agreement, as applicable*
            </Label>
            <UploaderContainer
              fieldName={'certificateOfNoOutcomeDocuments'}
              path={`cases/${props.data.masterProcessInstanceId}`}
            />
          </FormGroup>
        </Col>
      </Row>

      {values.settledAtConciliation && (
        <>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="settlementReached" className="text-default ">
                  What was the settlement reached?*
                </Label>
                <FormField
                  name="settlementReached"
                  placeholder="Please select"
                  type="select"
                  selectOptions={['Compensation', 'Reinstatement', 'Re-Employment ', 'Other']}
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'settlementReached'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Label for="further details" className="text-default ">
                  Provide further details*
                </Label>
                <FormField name="furtherDetails" placeholder="Further details" type="text" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'furtherDetails'} />
                </span>
              </FormGroup>
              <Row>
                <Col md={8}>
                  <FormGroup>
                    <Label for="settlement value" className="text-default text-capitalize">
                      Settlement (Rand value)
                    </Label>
                    <FormField name="settlementValue" placeholder="20000" type="text" />
                    <span className="text-danger">
                      <ErrorMessage className="text-danger" name={'settlementValue'} />
                    </span>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="employerRepresentativesAtConciliation" className="text-default">
              Who represented the employer at conciliation?*
            </Label>
            <Field name="employerRepresentativesAtConciliation">
              {({ field }: FieldAttributes<FormikValues>) => (
                <AsyncSelect
                  {...field}
                  placeholder="Select Employee"
                  cacheOptions
                  defaultOptions
                  loadOptions={loadOptions}
                  closeMenuOnSelect={false}
                  isMulti
                  styles={selectStyles}
                  onChange={(value: ValueType<any>) => handleChange(value, 'employerRepresentativesAtConciliation')}
                  components={{ ValueContainer }}
                />
              )}
            </Field>
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'employerRepresentativesAtConciliation'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="conciliationDate" className="text-default">
              Date conciliation took place*
            </Label>
            <FormField name="conciliationDate" placeholder="DD/MM/YYYY" type="date" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'conciliationDate'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default CCMADetailsAForm;
