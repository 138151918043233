import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ErrorMessage, Field, FieldAttributes, FormikProps, FormikValues, useFormikContext } from 'formik';
import FormField from '../../../forms/fields/FormField.component';
import AsyncSelect from 'react-select/async';
import {
  selectStyles,
  SelectType,
  ValueContainer,
} from '../../../components/reactSelect/ReactSelectComponents.component';
import { ValueType } from 'react-select';
import { get, listActiveEmployeesByOrganisationId } from '../../../utils/graphql-utils';
import { getOrganisation } from '../../../graphql/queries';
import { UserContext, UserContextProps } from '../../../App';
import { FormProps } from '../../WorkflowContainer/workflow-utils';
import { EmailType } from '../../../utils/email-utils';
import { Employee, Organisation } from '../../../models';
import { useErrorHandler } from '../../../utils/notification-utils';
import { EmailPreviewModalv3 } from '../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { Tooltip } from '../../../components/tooltips/Tooltip.component';
import { convertToFlowableVariables } from '../../../utils/flowable/flowable-utils';
import ApiDataContext from '../../../contexts';

const NotifyPayrollOfDemotionForm: React.FC<FormProps> = props => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const handleError = useErrorHandler();
  const [, setOrganisation] = useState<Organisation>();
  const employeeListApiData = useContext(ApiDataContext);

  const handleChange = (value: { value: string; label: string }, fieldName: string): void => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data.map((employee: Employee) => {
      const emailAddresses = employee.emails!.map(item => item!.address);
      return {
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
        attendeeId: employee.id,
        attendeeEmailAddress: emailAddresses[0] ? emailAddresses[0] : 'noEmail@labourteq.co.za',
        attendeeName: employee.firstName + ' ' + employee.lastName,
      };
    });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  // const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
  //   if (currentUser?.organisationId) {
  //     return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
  //       const preparedData = prepareData(data);
  //       return !inputValue ? preparedData : filterItems(preparedData, inputValue);
  //     });
  //   }
  // };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId && typeof employeeListApiData?.employees === 'undefined') {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        employeeListApiData.employees = !inputValue ? preparedData : filterItems(preparedData, inputValue);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    } else {
      return !inputValue ? employeeListApiData.employees : filterItems(employeeListApiData.employees, inputValue);
    }
  };

  const getValues = () => {
    return {
      demotionNewJobTitleId: props.data.caseData.demotionNewJobTitleId,
      demotionNewJobLevelId: props.data.caseData.demotionNewJobLevelId,
      demotionNewDepartmentId: props.data.caseData.demotionNewDepartmentId,
      demotionNewRemunerationPackage: props.data.caseData.demotionNewRemunerationPackage,
      demotionNewTerms: props.data.caseData.demotionNewTerms,
      demotionDate: props.data.caseData.demotionDate,
    };
  };

  const loadOrganisation = (): void => {
    get(getOrganisation, props.data.caseData.organisationId)
      .then(res => {
        const org = (res.data as any).getOrganisation;
        setOrganisation(org);
      })
      .catch(error => handleError(error));
  };

  useEffect(() => {
    loadOrganisation();
  }, []);

  return (
    <Form>
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">Did the employee sign the demotion letter in acceptance?</Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.didEmployeeSignedDemotionLetterInAcceptance}
                    onChange={(): void => {
                      setFieldValue('didEmployeeSignedDemotionLetterInAcceptance', true);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.didEmployeeSignedDemotionLetterInAcceptance}
                    onChange={(): void => {
                      setFieldValue('didEmployeeSignedDemotionLetterInAcceptance', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      {values.didEmployeeSignedDemotionLetterInAcceptance && (
        <Row>
          <Col className="mt-3">
            <EmailPreviewModalv3
              buttonText={'Notify Payroll'}
              disabled={!props.data.organisation?.payrollEmail}
              formValues={getValues()}
              emailType={EmailType.PP_NOTIFY_PAYROLL_DEMOTION}
              masterProcessInstanceId={props.data.masterProcessInstanceId}
              processInstanceId={props.data.processInstanceId}
              currentUserId={currentUser?.id}
              getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
            />
            {!props.data.organisation?.payrollEmail && (
              <div className="mt-2">
                <Tooltip
                  id="noWorkEmailAddress"
                  title={`No email address for payroll on the system`}
                  text={`We can only send an email to an employee's designated work email address. You will need to update this employee's information with a work email address.`}
                />
              </div>
            )}
          </Col>
        </Row>
      )}
      {!values.didEmployeeSignedDemotionLetterInAcceptance && (
        <>
          <Row className="my-2">
            <Col md="4">
              <FormGroup>
                <Label for="demotionDiscussionDate" className="text-default text-capitalize">
                  Date of Discussion
                </Label>
                <FormField name="demotionDiscussionDate" placeholder="Date of Discussion" type="date" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'demotionDiscussionDate'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="demotionDiscussionTime" className="text-default text-capitalize">
                  Time of Discussion
                </Label>
                <FormField type={'time'} placeholder="Time of Discussion" name="demotionDiscussionTime" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'demotionDiscussionTime'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="demotionDiscussionLocation" className="text-default text-capitalize">
                  Location
                </Label>
                <FormField name="demotionDiscussionLocation" placeholder="Location of Discussion" type="text" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'demotionDiscussionLocation'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="demotionDiscussionAttendees" className="text-default">
                  Attendees
                </Label>
                <Field name="demotionDiscussionAttendees">
                  {({ field }: FieldAttributes<FormikValues>) => (
                    <AsyncSelect
                      {...field}
                      placeholder="Select Attendees"
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptions}
                      closeMenuOnSelect={false}
                      value={
                        values.demotionDiscussionAttendees &&
                        values.demotionDiscussionAttendees.length &&
                        values.demotionDiscussionAttendees.map((attendee: { [key: string]: string }) => ({
                          label: attendee.attendeeName,
                          value: attendee.attendeeId,
                          attendeeId: attendee.attendeeId,
                          attendeeEmailAddress: attendee.attendeeEmailAddress
                            ? attendee.attendeeEmailAddress
                            : 'No email',
                          attendeeName: attendee.attendeeName,
                        }))
                      }
                      isMulti
                      styles={selectStyles}
                      onChange={(value: ValueType<any>) => handleChange(value, 'demotionDiscussionAttendees')}
                      components={{ ValueContainer }}
                    />
                  )}
                </Field>
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'demotionDiscussionAttendees'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default NotifyPayrollOfDemotionForm;
