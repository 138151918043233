import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Card, Col, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import { AuditLogEventType, CreateDepartmentInput, UpdateDepartmentInput } from '../../../API';
import { UserContext, UserContextProps } from '../../../App';
import AddEmployeeButton from '../../../components/AddEmployeeButton/AddEmployeeButton';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import { CloseIcon, PlusIcon } from '../../../components/icon/Icon.component';
import Loader from '../../../components/loader/Loader';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import { createDepartment, updateDepartment } from '../../../graphql/mutations';
import { listDepartments } from '../../../graphql/queries';
import { Department, Employee } from '../../../models';
import { createLog } from '../../../utils/audit-log-utils';
import { list, listActiveEmployeesByOrganisationId, mutate } from '../../../utils/graphql-utils';
import { useErrorHandler } from '../../../utils/notification-utils';
import DynamicTable, { Column, Data } from '../../Employee/ViewEmployees/DynamicTable';

enum ModalAction {
  EDIT = 'Edit',
  DELETE = 'Delete',
  ADD = 'Add',
}

interface DepartmentModalState {
  open: boolean;
  action: ModalAction | null;
  department: Department | null;
}

const Departments: React.FC = () => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const [departmentModal, setDepartmentModal] = useState<DepartmentModalState>({
    open: false,
    action: null,
    department: null,
  });
  const [departmentName, setDepartmentName] = useState('');
  const [departments] = useState<Department[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(true);

  const handleError = useErrorHandler();

  const handleChange = (event: ChangeEvent<HTMLInputElement>,): void => {
    setDepartmentName(event.target.value);
  };

  const getName = (modalObject: DepartmentModalState, inputObject: CreateDepartmentInput | Partial<UpdateDepartmentInput>,) => {
    if (inputObject.name) {
      return inputObject.name
    } else if (
      modalObject.department?.name
    ) {
      return modalObject.department?.name
    }

  }

  const mutateDepartment = async (department: CreateDepartmentInput | Partial<UpdateDepartmentInput>, modalAction: ModalAction | null,): Promise<any> => {
    const mutation = department.id ? updateDepartment : createDepartment;
    setLoading(true);
    await mutate(mutation, department)
      .then(() => {
        if (currentUser) { createLog(currentUser, AuditLogEventType.DEPARTMENT_MODIFIED, null, null, `${modalAction} ${getName(departmentModal, department)} department`).catch(error => handleError(error)); }
        setLoading(false)
      })
      .catch(error => handleError(error));
  };

  const getData = async (): Promise<void> => {
    if (currentUser?.organisationId) {
      setLoading(true);
      const departmentVariables = {
        filter: {
          organisationID: { eq: currentUser.organisationId },
          deleted: { ne: true },
        },
        limit: 500,
      };
      await Promise.all([
        listActiveEmployeesByOrganisationId(currentUser.organisationId),
        list(listDepartments, departmentVariables),
      ])
        .then((res: any[]) => {
          if (res[1].data && (res[1].data as any).listDepartments) {
            setEmployees(res[0]);
            const departmentsWithEmployeeCounts = (res[1].data as any).listDepartments.items
              .map((department: Department) => {
                const employeeCount = employees.filter(employee => employee.departmentID === department.id).length;
                const item = {
                  id: department.id,
                  deleted: department.deleted,
                  name: department.name,
                  employeeCount: employeeCount,
                };
                return item;
              })
              .sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
                return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
              });
            setTableData(departmentsWithEmployeeCounts);
            setLoading(false);
          } else {
            setLoading(false);
            handleError(new Error('Could not retrieve departments or employees.'));
          }
        })
        .catch((error: Error) => {
          setLoading(false);
          handleError(error);
        });
    } else {
      setLoading(false);
      handleError(new Error('Could not retrieve departments or employees.'));
    }
  };

  const reset = (): void => {
    setDepartmentName('');
    setDepartmentModal({ open: false, action: null, department: null });
    getData();
  };

  const executeModalAction = (
    modalAction: ModalAction | null,
    department: Department | null,
    departmentNameInput: string,
  ): void => {
    if (modalAction === ModalAction.ADD) {
      if (currentUser && departmentNameInput && currentUser.organisationId) {
        const newDepartment: CreateDepartmentInput = {
          organisationID: currentUser.organisationId,
          name: departmentNameInput,
          deleted: false,
        };
        mutateDepartment(newDepartment, modalAction).finally(() => reset());
      }
    } else if (modalAction === ModalAction.EDIT && !!department?.id) {
      const updatedDepartment: UpdateDepartmentInput = { id: department.id, name: departmentNameInput };
      mutateDepartment(updatedDepartment, modalAction).finally(() => reset());
    } else if (modalAction === ModalAction.DELETE && !!department?.id) {
      const deletedDepartment: UpdateDepartmentInput = { id: department.id, deleted: true };
      mutateDepartment(deletedDepartment, modalAction).finally(() => reset());
    } else handleError(new Error('Invalid department or action.'));
  };

  useEffect(() => {
    getData()
      .then(() => setLoading(false))
      .catch(error => handleError(error));
  }, [JSON.stringify(departments), JSON.stringify(employees)]);

  const columns: Column[] = [
    { label: 'Department Name', key: 'name' },
    { label: 'Employee Count', key: 'employeeCount' },
    {
      label: 'actions',
      type: 'action',
      actions: [
        {
          label: 'edit',
          func: (department: Data): void => {
            setDepartmentName(department.name ? department.name : '');
            setDepartmentModal({ open: true, action: ModalAction.EDIT, department: department as Department });
          },
        },
        {
          label: 'delete',
          func: (department: Data): void => {
            setDepartmentName(department.name ? department.name : '');
            setDepartmentModal({ open: true, action: ModalAction.DELETE, department: department as Department });
          },
        },
      ],
    },
  ];

  return (
    <>
      <TopBarComponent title={'Departments'} subTitle={'View Departments'}>
        <AddEmployeeButton />
        <ButtonWithIcons
          title={'Department'}
          handleClick={(): void => setDepartmentModal({ open: true, action: ModalAction.ADD, department: null })}
          leftIcon={<PlusIcon />}
          buttonType={'btn-bd-purple'}
        />
      </TopBarComponent>
      <div className="content">
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <>
            <Modal className="p-0" size="md" isOpen={departmentModal.open} centered>
              <ModalBody className="p-0 rounded-0">
                <div className="d-flex justify-content-between px-3 py-2 document-modal-header">
                  <div className="text-capitalize ml-auto mr-auto font-weight-light">
                    {departmentModal.action} Department
                  </div>
                  <div onClick={reset} className="align-self-center">
                    <CloseIcon fillColour={'white'} />
                  </div>
                </div>
                <div className="px-3 py-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        {departmentModal.action === 'Delete' ? (
                          <div className="d-flex flex-column align-items-center">
                            <span className="text-dark">Are you sure you want to remove this department?</span>
                            <span className="text-danger">
                              {departmentModal.department?.name ? departmentModal.department.name : ''}
                            </span>
                          </div>
                        ) : (
                          <>
                            <Label for="Department name" className="text-default text-capitalize">
                              Department Name
                            </Label>
                            <Input type={'text'} onChange={handleChange} value={departmentName} />
                          </>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex ml-auto mr-auto justify-content-center">
                  <ButtonWithIcons
                    title={`${departmentModal.action === 'Edit' ? 'Confirm' : `${departmentModal.action}`}`}
                    handleClick={(): void =>
                      executeModalAction(departmentModal.action, departmentModal.department, departmentName)
                    }
                  />
                </div>
              </ModalBody>
            </Modal>
            <h4 className="text-default text-capitalize font-weight-bold mt-3 ml-3">Company Information</h4>
            <Row>
              <Col className="mb-5" md="12">
                <Card>
                  <CardBody>
                    <DynamicTable columns={columns} data={tableData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Departments;
