import React from 'react';

import { Nav, NavItem } from 'reactstrap';

export interface TabsProps {
  page: number;
  tabs: Tab[];
  changeTab: (page: number, fn?: any) => void;
  hasValidation?: boolean;
}

export interface Tab {
  tabName: string;
  employeeCount: number;
}
// Todo refactor back into existing tabs component
const Tabs: React.FC<TabsProps> = (props: TabsProps) => {
  const { tabs, page, changeTab } = props;
  const active = tabs[page];

  const navigate = (page: number, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    changeTab(page);
  };

  return (
    <Nav className="nav-pills-info" pills>
      {tabs.map((tab, index) => (
        <span key={index} style={{ cursor: 'pointer' }} onClick={e => navigate(index, e)}>
          <NavItem>
            <button
              className={'btn rounded-0' + ' ' + (active.tabName === tab.tabName ? 'btn-primary' : 'btn-simple')}
              style={active.tabName === tab.tabName ? { border: '2px solid transparent', padding: '10px 22px' } : {}}
              data-toggle="tab"
            >
              {tab.tabName + ' (' + tab.employeeCount + ')'}
            </button>
          </NavItem>
        </span>
      ))}
    </Nav>
  );
};

export default Tabs;
