export enum QueryType {
  EMPLOYEE = 'EMPLOYEE',
  CASE = 'CASE',
  MANAGER = 'MANAGER',
}

export interface QueryTimePeriod {
  // YYYY-MM-DD
  startDate: string;
  // YYYY-MM-DD
  endDate: string;
}

export enum DateIntervalType {
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export interface CaseDataSetQuery {
  label: string;
  queryType: QueryType;
  attributeName: string;
  attributeValue: unknown;
}

export interface ChartsQuery {
  organisationId: string;
  timePeriod: QueryTimePeriod;
  dateIntervalType?: DateIntervalType;
  dataSetQueries: CaseDataSetQuery[];
  groupingDataSetQueries?: CaseDataSetQuery[];
  filterDataSetQuery?: CaseDataSetQuery;
}

export interface CaseDataSetResponse {
  label: string;
  cases: FlowableCase[];
  count: number;
}

export interface FlowableCase {
  start_time: Date | null;
  end_time: Date | null;
  master_process_instance_id: string;
  incidents: string | null;
  is_case_closed: boolean;
  case_type: string | null;
  process_type: string | null;
}

export type DateInterval = {
  /**
   * Label that should be shown on the table.
   */
  label: string;
  /**
   * YYYY-MM-DD - Start date for this interval
   */
  start: string;
  /**
   * YYYY-MM-DD - End date for this interval
   */
  end: string;
};

export type DateIntervalCount = DateInterval & {
  /**
   * The amount of cases that are/were open in this date interval.
   */
  count: number;
  /**
   * The cases for each interval
   */
  cases: FlowableCase[];
};

export interface MultiDataSet {
  data: CaseDataSetResponse[];
  label: string;
}

export interface DataSet {
  data: number[];
  label: string;
  fill: boolean;
  borderWidth: number;
  backgroundColor?: CanvasGradient | string | null;
  borderColor?: CanvasGradient | string | null;
  lineTension: number;
}

export interface GraphData {
  datasets: DataSet[];
  labels: string[];
  heading: string;
}
