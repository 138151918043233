import React, { ReactElement, useEffect, useState } from 'react';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../WorkflowContainer/workflow-utils';
import { Formik, FormikErrors, FormikProps, FormikValues, getIn } from 'formik';
import { PipProgressMeeting } from '../RecordPIPProgressMeeting/RecordPIPProgressMeeting';
import { WorkFlowFooter } from '../../../../components/workflowFooter/WorkFlowFooter';
import CompletePipSessionForm from './CompletePipSessionForm';
import { RecordPIPSessionSchema } from '../../../../forms/ValidationSchema/PoorPerformanceValidationSchema';

const blankPerformanceShortfall = [
  {
    shortfallDescription: '',
    date: '',
    performanceGoal: '',
    qualityStandard: '',
    deadline: '',
    assistanceRequiredFromEmployer: '',
    actionsToBeTakenByEmployee: '',
    pipProgressUpdates: null,
  },
];

const CompletePipSession: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;

  const fields = {
    pipProgressMeetings: 'array',
    performanceShortfalls: 'array',
  };

  const [data, setData] = useState(() => getFormDataFromCaseData(fields, caseData, caseData.isAppeal));

  useEffect(() => {
    if (!data.performanceShortfalls) {
      setData(data => ({ ...data, performanceShortfalls: blankPerformanceShortfall }));
    } else {
      const shortfalls = data.performanceShortfalls.map((shortfall: { [key: string]: any }) => {
        return {
          ...shortfall,
          performanceGoal: shortfall.performanceGoal ? shortfall.performanceGoal : '',
          qualityStandard: shortfall.qualityStandard ? shortfall.qualityStandard : '',
          deadline: shortfall.deadline ? shortfall.deadline : '',
          assistanceRequiredFromEmployer: shortfall.assistanceRequiredFromEmployer
            ? shortfall.assistanceRequiredFromEmployer
            : '',
          actionsToBeTakenByEmployee: shortfall.actionsToBeTakenByEmployee ? shortfall.actionsToBeTakenByEmployee : '',
          pipProgressUpdates: shortfall.pipProgressUpdates ? shortfall.pipProgressUpdates : null,
        };
      });
      setData(data => ({ ...data, performanceShortfalls: shortfalls }));
    }
    if (data.pipProgressMeetings) {
      const pipProgressMeetings: PipProgressMeeting[] = data.pipProgressMeetings.filter(
        (meeting: PipProgressMeeting) => {
          return !meeting.sessionCompleted;
        },
      );
      setData(oldState => ({ ...oldState, session: pipProgressMeetings[0] }));
    }
  }, [JSON.stringify(data)]);

  const setErrors = (errors: FormikErrors<FormikValues>, setFieldTouched: (field: string, touched: boolean) => any) => {
    const errorKeys = Object.keys(errors);
    errorKeys.forEach(field => {
      //if fieldArray
      if (Array.isArray(errors[field])) {
        // @ts-ignore
        errors[field].forEach((item, index) => {
          const keys = Object.keys(item);
          keys.forEach(name => {
            //if inner array
            if (Array.isArray(item[name])) {
              item[name].forEach((obj: { [key: string]: any }, idx: number) => {
                if (obj) {
                  const itemNames = Object.keys(obj);
                  itemNames.forEach(fieldName => {
                    setFieldTouched(`${field}.${index}.${name}.${idx}.${fieldName}`, true);
                  });
                }
              });
            }
          });
        });
      } else if (errors[field] === Object(errors[field])) {
        // if object
        const err = getIn(errors, field);
        const innerObjKeys = Object.keys(err);
        innerObjKeys.forEach(item => {
          setFieldTouched(`${field}.${item}`, true);
        });
      } else {
        setFieldTouched(field, true);
      }
    });
  };

  const submitValues = async (values: FormikValues): Promise<void> => {
    const formValues = { ...values };
    formValues.session.sessionCompleted = true;
    const indexOfCurrentSession = formValues.pipProgressMeetings.findIndex(
      (item: PipProgressMeeting) => item.meetingId === formValues.session.meetingId,
    );
    formValues.pipProgressMeetings[indexOfCurrentSession] = formValues.session;
    formValues.pipProgressMeetings = JSON.stringify(formValues.pipProgressMeetings);
    formValues.performanceShortfalls = JSON.stringify(formValues.performanceShortfalls);
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Record PIP Session</h4>
      <Formik initialValues={data} onSubmit={submitValues} validationSchema={RecordPIPSessionSchema} enableReinitialize>
        {({ values, handleSubmit, errors, setFieldTouched }: FormikProps<FormikValues>): ReactElement => (
          <>
            {values.session && <CompletePipSessionForm processInstanceId={props.data.processInstanceId} />}
            <WorkFlowFooter
              data={props.data}
              onNext={() => {
                setErrors(errors, setFieldTouched);
                if (!Object.keys(errors).length) handleSubmit();
              }}
              onCancel={(): void => flowableFunctions.goBack()}
              onSaveAndClose={() => props.data.flowableFunctions.onSaveAndClose(values)}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default CompletePipSession;
