import React, { useEffect, useState } from 'react';
import DynamicTable, { Column } from '../Employee/ViewEmployees/DynamicTable';
import TopBarComponent from '../../components/topBar/TopBar.component';
import Loader from '../../components/loader/Loader';
import { Card, CardBody, Col, Row } from 'reactstrap';
import moment from 'moment';
import { useParams } from 'react-router';
import { taskConfigs } from '../../configs/task-configs/taskConfigs';
import { useErrorHandler } from '../../utils/notification-utils';
import { toTitleCase } from '../../utils/string-utils';
import { UserRole } from '../../models';
import { get } from '../../utils/api-utils';
import { documentConfigs } from '../../configs/document-configs/document-configs';
import AddEmployeeButton from '../../components/AddEmployeeButton/AddEmployeeButton';

interface AuditTrailTaskFromApi {
  taskId: string;
  taskDefinitionKey: string;
  userFullName: string | null;
  userRoles: UserRole[] | null;
  endTime: Date;
  documentType: string | null;
}

interface AuditTrailTaskFormatted {
  description: string;
  userFullName: string;
  userRolesString: string;
  completedDate: string;
}

const formatAuditTrailTask = (t: AuditTrailTaskFromApi): AuditTrailTaskFormatted => {
  const config = taskConfigs[t.taskDefinitionKey];
  let taskName = config?.name ?? t.taskDefinitionKey;
  /* eslint-disable no-template-curly-in-string */
  const documenTypeLiteral = '${documentType}';
  if (taskName.includes(documenTypeLiteral)) {
    let documentName;
    if (t.documentType) {
      // @ts-ignore
      documentName = documentConfigs[t.documentType]?.name;
    }
    taskName = taskName.replace(documenTypeLiteral, documentName ?? 'Document');
  }

  return {
    description: taskName ?? '',
    userFullName: t.userFullName ?? 'External user',
    completedDate: t.endTime ? moment(t.endTime).format('DD/MM/YYYY HH:mm') : '',
    userRolesString: t.userRoles ? t.userRoles.map(r => toTitleCase(r, '_')).join(', ') : '',
  };
};

export const CaseAuditTrailScreen: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [auditTrailTasks, setAuditTrailTasks] = useState<AuditTrailTaskFormatted[]>([]);
  const { masterProcessInstanceId } = useParams<{ masterProcessInstanceId: string }>();
  const handleError = useErrorHandler();

  const getAuditTrailTasks = async (masterProcessInstanceId: string): Promise<AuditTrailTaskFormatted[]> => {
    const auditTrailTasks = await get<AuditTrailTaskFromApi[]>(`/audit-trail/${masterProcessInstanceId}`);
    const tasks = auditTrailTasks.data.map(formatAuditTrailTask);
    return tasks;
  };

  useEffect(() => {
    if (masterProcessInstanceId) {
      setIsLoading(true);
      getAuditTrailTasks(masterProcessInstanceId)
        .then(res => {
          setAuditTrailTasks(res);
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          handleError(error);
        });
    }
  }, [masterProcessInstanceId, handleError]);

  const columns: Column[] = [
    { key: 'description', label: 'Action' },
    { key: 'userFullName', label: 'Actioned By' },
    { key: 'userRolesString', label: 'Roles' },
    { key: 'completedDate', label: 'Date' },
  ];

  return (
    <>
      <TopBarComponent title={'Audit Trail'} subTitle={' '}>
        <AddEmployeeButton />
      </TopBarComponent>
      <div className="content">
        {isLoading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <Row>
            <Col className="mb-5" md="12">
              <Card>
                <CardBody>
                  <DynamicTable data={auditTrailTasks} columns={columns} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
