import React, { ChangeEvent, useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Alert, Button, CardFooter, FormGroup, Input } from 'reactstrap';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { UserContext, UserContextProps } from '../../../App';

interface ChangePasswordViewProps {
  onComplete: () => void;
  user?: CognitoUser;
}

const INITIAL_CHANGE_DETAILS = { password: '', confirmPassword: '', error: '', loading: false };

const ChangePasswordView: React.FC<ChangePasswordViewProps> = (props: ChangePasswordViewProps) => {
  const userContext = useContext<Partial<UserContextProps>>(UserContext);
  const [state, setState] = useState(INITIAL_CHANGE_DETAILS);
  const { password, confirmPassword, loading, error } = state;
  const { user } = props;

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>): void => {
    const password = event.target.value;
    setState(oldState => ({ ...oldState, password }));
  };
  const handleChangeConfirmPassword = (event: ChangeEvent<HTMLInputElement>): void => {
    const confirmPassword = event.target.value;
    setState(oldState => ({ ...oldState, confirmPassword }));
  };

  const handlePressSubmit = (): void => {
    if (password !== confirmPassword) {
      setState(oldState => ({ ...oldState, error: 'The passwords do not match' }));
    } else if (password.length < 8) {
      setState(oldState => ({ ...oldState, error: 'Password not long enough' }));
    } else if (password === confirmPassword) {
      const failure = (errorResponse: { message: string }): void => {
        let { message } = errorResponse;
        message = message.replace('Password does not conform to policy: ', '');
        setState(oldState => ({ ...oldState, error: message, loading: false }));
      };
      setState(oldState => ({ ...oldState, error: '', loading: true }));
      Auth.completeNewPassword(user, password, {})
        .then((user: CognitoUser) => {
          if (userContext.loginCallBack) userContext.loginCallBack(user);
        })
        .catch(failure);
    }
  };

  const missingFields = !password || !confirmPassword;

  return (
    <>
      <h4 className="text-muted text-lg-center">{'Please change your password'}</h4>
      {!!error && (
        <Alert aria-label="error-message" className=" d-flex justify-content-center" color="danger" fade={false}>
          <span>{error}</span>
        </Alert>
      )}
      <FormGroup className="p-1">
        <Input
          aria-label={'password'}
          placeholder={'New Password'}
          type={'password'}
          name={'password'}
          value={password}
          disabled={loading}
          onChange={handleChangePassword}
        />
      </FormGroup>
      <FormGroup className="p-1">
        <Input
          aria-label={'confirm-password'}
          placeholder={'Confirm Password'}
          type={'password'}
          name={'confirm-password'}
          value={confirmPassword}
          disabled={loading}
          onChange={handleChangeConfirmPassword}
        />
      </FormGroup>
      <CardFooter className="d-flex flex-column align-items-center">
        <Button
          block
          aria-label={'submit'}
          className="btn-round mb-2 text-uppercase font-weight-light"
          color="primary"
          onClick={handlePressSubmit}
          disabled={loading || missingFields}
          size="lg"
        >
          Submit new password
        </Button>
        <div className="p-1">
          <h5 className="text-muted text-lg-center">
            <small>
              Disclaimer: The labourteq software solution does not constitute legal advice.
              <br />
              Labourteq is strictly copyright protected and any unauthorised use/copying/redistribution is prohibited.
              Non-compliance will lead to prosecution
              <br />© Labourteq Solutions (Pty) Ltd 2019 (Registration number 2019/588031/07)
            </small>
          </h5>
        </div>
      </CardFooter>
    </>
  );
};

export default ChangePasswordView;
