import React, { ReactElement } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { Form } from 'reactstrap';
import NotePopup from '../adhocPopover/NotePopup.component';
import { CaseData, getFormDataFromCaseData } from '../../screens/WorkflowContainer/workflow-utils';

interface AdhocContainerProps {
  processInstanceId: string;
  caseData: CaseData;
}

const AdhocContainer: React.FC<AdhocContainerProps> = (props: AdhocContainerProps) => {
  const { caseData, processInstanceId } = props;

  const fields = {
    adhocCaseFiles: 'array',
  };

  const initialValues = getFormDataFromCaseData(fields, caseData);
  return (
    <Formik initialValues={initialValues} onSubmit={() => console.log('')}>
      {({ values, setFieldValue, handleSubmit, errors }: FormikProps<FormikValues>): ReactElement => (
        <Form>
          <NotePopup processInstanceId={processInstanceId} />
        </Form>
      )}
    </Formik>
  );
};

export default AdhocContainer;
