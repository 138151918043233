import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldAttributes,
  Formik,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import { AddIcon, CloseIcon, RemoveIcon } from '../../../../components/icon/Icon.component';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row, Table } from 'reactstrap';
import TableFormField from '../../../../forms/fields/TableFormField';
import FormField, { DATE_FORMAT } from '../../../../forms/fields/FormField.component';
import { Tooltip } from '../../../../components/tooltips/Tooltip.component';
import AsyncSelect from 'react-select/async';
import {
  selectStyles,
  SelectType,
  ValueContainer,
} from '../../../../components/reactSelect/ReactSelectComponents.component';
import Select, { ValueType } from 'react-select';
import { listActiveEmployeesByOrganisationId } from '../../../../utils/graphql-utils';
import { UserContext, UserContextProps } from '../../../../App';
import UploaderContainer from '../../../../components/Uploader/UploaderContainer';
import * as Yup from 'yup';
import moment from 'moment';
import { CaseData, FormProps } from '../../../WorkflowContainer/workflow-utils';
import { EmailRecipient, EmailType } from '../../../../utils/email-utils';
import { Employee } from '../../../../models';
import { TemplateType } from '../../../../API';
import { EmailPreviewModalv3 } from '../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { convertToFlowableVariables } from '../../../../utils/flowable/flowable-utils';
import { toTitleCase } from '../../../../utils/string-utils';
import { notEmpty } from '../../../../utils/typescript-utils';
import ApiDataContext from '../../../../contexts';

export const NewShortfallValidationSchema = Yup.object().shape({
  shortfallDescription: Yup.string().required('Required'),
  date: Yup.string()
    .required('Required')
    .test('date', 'Required', val => moment(val, DATE_FORMAT).isValid())
    .required('Required'),
  documents: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string(),
        key: Yup.string(),
        url: Yup.string(),
      }),
    )
    .nullable(),
  deadline: Yup.string()
    .required('Required')
    .test('date', 'Required', val => moment(val, DATE_FORMAT).isValid())
    .required('Required'),
  performanceGoal: Yup.string().required('Required'),
  qualityStandard: Yup.string().required('Required'),
  assistanceRequiredFromEmployer: Yup.string().required('Required'),
  actionsToBeTakenByEmployee: Yup.string().required('Required'),
});

interface InitiatePIPFormProps extends FormProps {
  employeeId: string;
  employeeFullName: string;
  employeeNumber: string;
  processInstanceId: string;
  caseData: CaseData;
  employeeEmailAddress: string;
  pipDocumentName: string;
}

const InitiatePIPForm: React.FC<InitiatePIPFormProps> = (props: InitiatePIPFormProps) => {
  const { setFieldValue, values }: FormikProps<FormikValues> = useFormikContext();
  const [newShortfallModal, setNewShortfallModal] = useState(false);
  const { processInstanceId } = props;
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const employeeListApiData = useContext(ApiDataContext);

  const handleChange = (value: { value: string; label: string }, fieldName: string) => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data.map((employee: Employee) => {
      const emailAddresses = employee.emails.filter(notEmpty).map(item => item.address);
      return {
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
        attendeeId: employee.id,
        attendeeEmailAddress: emailAddresses[0] ? emailAddresses[0] : 'noEmail@labourteq.co.za',
        attendeeName: employee.firstName + ' ' + employee.lastName,
      };
    });
    return preparedData;
  };

  useEffect(() => {
    if (props.caseData.pipType === 'FINAL_WRITTEN_WARNING_PIP') {
      if (values.warningValidityPeriod) {
        const date = moment()
          .add(values.warningValidityPeriod, 'months')
          .format(DATE_FORMAT);
        setFieldValue('warningExpiryDate', date);
      } else {
        props.templateType === TemplateType.WRITTEN_WARNING_HEARING
          ? setFieldValue('warningValidityPeriod', '6')
          : setFieldValue('warningValidityPeriod', '12');
      }
    }
  }, [values.warningValidityPeriod, values.warningExpiryDate]);

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  // const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
  //   if (currentUser?.organisationId) {
  //     return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
  //       const preparedData = prepareData(data);
  //       return !inputValue ? preparedData : filterItems(preparedData, inputValue);
  //     });
  //   }
  // };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId && typeof employeeListApiData.initialEmployeesPage === 'undefined') {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        employeeListApiData.employees = !inputValue ? preparedData : filterItems(preparedData, inputValue);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    } else {
      return !inputValue
        ? employeeListApiData.initialEmployeesPage
        : filterItems(employeeListApiData.initialEmployeesPage, inputValue);
    }
  };

  const getRecipients = () => {
    const recipients: EmailRecipient[] = [];
    values.pipDiscussionAttendees.forEach((item: any) => {
      if (item.attendeeEmailAddress && item.attendeeName)
        recipients.push({ emailAddress: item.attendeeEmailAddress, name: item.attendeeName });
    });
    return recipients;
  };

  return (
    <>
      <Form>
        <Row className="mb-3">
          <Col>
            <Label className="text-default">Compile invite for discussion</Label>
            <div className="d-flex">
              <div className="mr-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="yes"
                      checked={values.compilePipDiscussionInvite}
                      onChange={(): void => setFieldValue('compilePipDiscussionInvite', true)}
                    />
                    <span className="form-check-sign">
                      <span className="check text-muted text-uppercase">yes</span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
              <div className="ml-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="no"
                      checked={!values.compilePipDiscussionInvite}
                      onChange={(): void => setFieldValue('compilePipDiscussionInvite', false)}
                    />
                    <span className="form-check-sign">
                      <span className="check text-default text-muted text-uppercase">no</span>
                    </span>
                  </Label>
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
        {values.compilePipDiscussionInvite && (
          <>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label for="pipDiscussionDate" className="text-default text-capitalize">
                    Date of Discussion*
                  </Label>
                  <FormField name="pipDiscussionDate" placeholder="Date of Discussion" type="date" />
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'pipDiscussionDate'} />
                  </span>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="pipDiscussionTime" className="text-default text-capitalize">
                    Time of Discussion*
                  </Label>
                  <FormField type={'time'} placeholder="Time of Discussion" name="pipDiscussionTime" />
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'pipDiscussionTime'} />
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="pipDiscussionLocation" className="text-default text-capitalize">
                    Location*
                  </Label>
                  <FormField name="pipDiscussionLocation" placeholder="Location of Discussion" type="text" />
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'pipDiscussionLocation'} />
                  </span>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="pipDiscussionAttendees" className="text-default">
                    Attendees (other than employee)*
                  </Label>
                  <Field name="pipDiscussionAttendees">
                    {({ field }: FieldAttributes<FormikValues>) => (
                      <AsyncSelect
                        {...field}
                        placeholder="Select Attendees"
                        cacheOptions
                        defaultOptions
                        loadOptions={loadOptions}
                        closeMenuOnSelect={false}
                        isClearable={false}
                        value={
                          values.pipDiscussionAttendees &&
                          values.pipDiscussionAttendees.length &&
                          values.pipDiscussionAttendees.map((attendee: { [key: string]: string }) => ({
                            label: attendee.attendeeName,
                            value: attendee.attendeeId,
                            attendeeId: attendee.attendeeId,
                            attendeeEmailAddress: attendee.attendeeEmailAddress
                              ? attendee.attendeeEmailAddress
                              : 'No email',
                            attendeeName: attendee.attendeeName,
                          }))
                        }
                        isMulti
                        styles={selectStyles}
                        onChange={(value: ValueType<any>) => handleChange(value, 'pipDiscussionAttendees')}
                        components={{ ValueContainer }}
                      />
                    )}
                  </Field>
                  <span className="text-danger">
                    <ErrorMessage className="text-danger" name={'pipDiscussionAttendees'} />
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="d-flex">
                  <EmailPreviewModalv3
                    buttonText={'Send Email/Invite to employee'}
                    disabled={
                      !(
                        values.pipDiscussionDate &&
                        values.pipDiscussionTime &&
                        values.pipDiscussionAttendees &&
                        props.data.employeeWorkEmailAddresses.length
                      )
                    }
                    formValues={values}
                    emailType={EmailType.POOR_PERFORMANCE_PIP_DISCUSSION_EMPLOYEE}
                    masterProcessInstanceId={props.data.masterProcessInstanceId}
                    processInstanceId={props.data.processInstanceId}
                    currentUserId={currentUser?.id}
                    getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
                  />
                  {!props.data.employeeWorkEmailAddresses.length && (
                    <div className="mt-2">
                      <Tooltip
                        id="noWorkEmailAddress"
                        title={`No work email address`}
                        text={`We can only send an email to an employee's designated work email address. You will need to update this employee's information with a work email address.`}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col md={4}>
                <EmailPreviewModalv3
                  getRecipients={getRecipients}
                  buttonText={'Send Email/Invite to Attendees'}
                  disabled={!(values.pipDiscussionDate && values.pipDiscussionTime && values.pipDiscussionAttendees)}
                  formValues={values}
                  emailType={EmailType.POOR_PERFORMANCE_PIP_DISCUSSION_ATTENDEES}
                  masterProcessInstanceId={props.data.masterProcessInstanceId}
                  processInstanceId={props.data.processInstanceId}
                  currentUserId={currentUser?.id}
                  getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
                />
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <div>
              <h4 className="text-h4 text-capitalize font-weight-500">
                {'Prepare Draft' + ' ' + toTitleCase(props.pipDocumentName, ' ')}
              </h4>
              <h5 className="text-primary text-capitalize mt-4 font-weight-light">Case Overview: Shortfalls</h5>
              <hr style={{ border: '0.06em solid #adb5bd' }} />
            </div>
          </Col>
        </Row>
        <Table bordered className="table-responsive-lg table-responsive-md">
          <thead>
            <tr>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.83em' }}>
                Performance Shortfall
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                <span className="d-flex">
                  <span>Performance/Competency Goal*</span>
                  <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                    <Tooltip
                      id="performanceGoal"
                      title="Performance/Competency Goal"
                      text={
                        'Set  specific  performance  goals  linked  to  the  employee’s  Key  Performance  Areas  and  Indicators specifying the deliverables or outputs required for the duration of the PIP. Competency goals can also be set in order for the employee to   achieve the knowledge, skills and attitudes required to perform their job responsibilities, driving inputs.'
                      }
                    />
                  </span>
                </span>
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Quality Deliverable/Standard*
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                <span className="d-flex">
                  <span>Deadline/Timeline*</span>
                  <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                    <Tooltip
                      id="deadline"
                      title="Deadline/Timeline "
                      text={
                        'Depending  on  the  nature  of  the  goal  or  deliverable  set,  record  a  deadline  or  time  line  for achievement of each goal or deliverable that is within the PIP duration. Do not leave expectations open ended. The employee must achieve these within the allocated time. What is reasonable, will depend on the facts of each case.'
                      }
                    />
                  </span>
                </span>
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                <span className="d-flex">
                  <span>Action to be taken by Employee*</span>
                  <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                    <Tooltip
                      id="actionToBeTakenByEmployee"
                      title="Action to be taken by employee"
                      text={
                        'Ultimately the employee remains responsible for his/her own performance and the achievement of performance expectations. Record specific commitments and undertakings on the side of the employee on actions to be taken to address their inability to meet performance expectations.'
                      }
                    />
                  </span>
                </span>
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                <span className="d-flex">
                  <span>Assistance Required from Employer*</span>
                  <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                    <Tooltip
                      id="assistanceRequiredFromEmployer"
                      title="Assistance Required from Employer"
                      text={
                        'The  parties  need  to  identify  what  assistance  the  employer  will  provide.  This  can  range  from informal  on-the-job  training,  to  coaching  to  enrolling  the  employee  for  formal  education programmes.  The  assistance  required  must  be  reasonable.  The  more  senior,  highly  paid  and experienced  the  employee  the  less  onerous  will  the  employer’s  duty  be  in  this  regard.  If  the employee indicates no assistance is required, also record this.'
                      }
                    />
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <FieldArray name="performanceShortfalls">
              {(arrayHelpers: FieldArrayRenderProps) => (
                <>
                  {values.performanceShortfalls &&
                    values.performanceShortfalls.length &&
                    values.performanceShortfalls.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className="position-relative" style={{ width: '25%' }}>
                          {item.shortfallDescription}
                        </td>
                        <td className="position-relative" style={{ width: '20%' }}>
                          <TableFormField
                            type={'textarea'}
                            placeholder={'Enter performance goal'}
                            name={`performanceShortfalls.${index}.performanceGoal`}
                            className="square-radius font-weight-light border-0 h-25"
                          />
                          <span className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name={`performanceShortfalls.${index}.performanceGoal`}
                            />
                          </span>
                        </td>
                        <td className="position-relative" style={{ width: '10%' }}>
                          <TableFormField
                            type={'textarea'}
                            placeholder={'Quality deliverable'}
                            name={`performanceShortfalls.${index}.qualityStandard`}
                            className="square-radius font-weight-light border-0 h-25"
                          />
                          <span className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name={`performanceShortfalls.${index}.qualityStandard`}
                            />
                          </span>
                        </td>
                        <td className="position-relative">
                          <TableFormField
                            type={'date'}
                            placeholder={'Deadline'}
                            name={`performanceShortfalls.${index}.deadline`}
                            className="square-radius border-0 h-25"
                          />
                          <span className="text-danger">
                            <ErrorMessage className="text-danger" name={`performanceShortfalls.${index}.deadline`} />
                          </span>
                        </td>
                        <td className="position-relative" style={{ width: '20%' }}>
                          <TableFormField
                            type={'textarea'}
                            placeholder={'Action'}
                            name={`performanceShortfalls.${index}.actionsToBeTakenByEmployee`}
                            className="square-radius font-weight-light border-0 h-25"
                          />
                          <span className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name={`performanceShortfalls.${index}.actionsToBeTakenByEmployee`}
                            />
                          </span>
                        </td>
                        <td className="position-relative" style={{ width: '20%' }}>
                          <TableFormField
                            type={'textarea'}
                            placeholder={'Assistance required'}
                            name={`performanceShortfalls.${index}.assistanceRequiredFromEmployer`}
                            className="square-radius font-weight-light border-0 h-25"
                          />
                          <span className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name={`performanceShortfalls.${index}.assistanceRequiredFromEmployer`}
                            />
                          </span>
                        </td>
                        <td
                          className="position-relative"
                          style={{ maxWidth: '30px', minWidth: '30px', width: 'auto', borderColor: 'white' }}
                        >
                          {index !== 0 && (
                            <span onClick={(): void => arrayHelpers.remove(index)}>
                              <RemoveIcon />
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </FieldArray>
          </tbody>
        </Table>
        <Row className="pb-3">
          <Col md={3} className="d-flex" style={{ cursor: 'pointer' }} onClick={(): void => setNewShortfallModal(true)}>
            <span className="px-1">
              <AddIcon height={'20'} width={'20'} />
            </span>
            <span className="text-default px-1">Add a new Performance Shortfall</span>
          </Col>
        </Row>
        <Row className="d-flex">
          <Col md={4}>
            <FormGroup>
              <Label for="pipStartDate" className="text-default text-capitalize">
                PIP start date*
              </Label>
              <FormField type={'date'} placeholder="Select Date" name="pipStartDate" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'pipStartDate'} />
              </span>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="pipEndDate" className="text-default text-capitalize">
                PIP end date*
              </Label>
              <FormField type={'date'} placeholder="Select Date" name="pipEndDate" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'pipEndDate'} />
              </span>
            </FormGroup>
          </Col>
          <span className="align-self-center pl-2 pointer">
            <Tooltip
              id="pipDuration"
              title="PIP duration "
              text={
                'There  is  no  legal  rule  on  the  duration  of  the  PIP  and  it  is  a  factual  question  of  what  is  fair  and reasonable in the particular circumstances. You need at least one interim review period before a final assessment on the attainment of the PIP goals and deliverables. Do not unduly prolong the PIP and be clear and specific upfront in your expectations.'
              }
            />
          </span>
        </Row>
        {props.caseData.pipType === 'FINAL_WRITTEN_WARNING_PIP' && (
          <Row className="mt-3 mb-3">
            <Col md={4}>
              <FormGroup>
                <Label for="hearingDate" className="text-default text-capitalize">
                  Select Validity Period
                </Label>
                <Select
                  styles={selectStyles}
                  placeholder="Select validity period"
                  value={{
                    label: values.warningValidityPeriod
                      ? values.warningValidityPeriod + ' ' + 'Months'
                      : 'Select validity period',
                    value: values.warningValidityPeriod,
                  }}
                  options={[
                    { label: '3 Months', value: '3' },
                    { label: '6 Months', value: '6' },
                    { label: '1 Year', value: '12' },
                  ]}
                  onChange={(item: ValueType<any>): void => {
                    setFieldValue('warningValidityPeriod', item.value);
                  }}
                  isDisabled={false}
                />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'warningValidityPeriod'} />
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="warningExpiryDate" className="text-default text-capitalize">
                  Warning Valid Until
                </Label>
                <FormField type={'date'} placeholder="Select Time" name="warningExpiryDate" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'warningExpiryDate'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
        )}
      </Form>
      <Modal isOpen={newShortfallModal} size={'lg'} centered>
        <ModalBody>
          <div onClick={(): void => setNewShortfallModal(false)} className="d-flex justify-content-between">
            <div className="text-default h4">New Performance Shortfall</div>
            <div>
              <CloseIcon />
            </div>
          </div>
          <Formik
            initialValues={{
              shortfallDescription: '',
              date: '',
              documents: null,
              progressUpdates: [
                {
                  date: '',
                  update: '',
                },
              ],
              performanceGoal: '',
              qualityStandard: '',
              deadline: '',
              assistanceRequiredFromEmployer: '',
              actionsToBeTakenByEmployee: '',
            }}
            validationSchema={NewShortfallValidationSchema}
            onSubmit={(currentValues: FormikValues): void => {
              setFieldValue('performanceShortfalls', values.performanceShortfalls.concat(currentValues));
              setNewShortfallModal(false);
            }}
          >
            {({ handleSubmit, values }: FormikProps<FormikValues>): ReactElement => (
              <>
                <Form>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="shortfallDescription" className="text-default text-capitalize">
                          Shortfall Description
                        </Label>
                        <FormField type={'textarea'} placeholder="Enter Description" name="shortfallDescription" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'shortfallDescription'} />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="dateOrPeriod" className="text-default text-capitalize">
                          Date / Period
                        </Label>
                        <FormField type={'date'} placeholder="Select Date" name="date" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'date'} />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="dateOrPeriod" className="text-default text-capitalize">
                          Deadline
                        </Label>
                        <FormField type={'date'} placeholder="Select Date" name="deadline" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'deadline'} />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="performanceGoal" className="text-default text-capitalize">
                          Performance Goal
                        </Label>
                        <FormField type={'text'} placeholder="Enter Performance goal" name="performanceGoal" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'performanceGoal'} />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="assistanceRequiredFromEmployer" className="text-default text-capitalize">
                          Assistance Required from employer
                        </Label>
                        <FormField
                          type={'text'}
                          placeholder="Enter Description"
                          name="assistanceRequiredFromEmployer"
                        />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'assistanceRequiredFromEmployer'} />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="actionsToBeTakenByEmployee" className="text-default text-capitalize">
                          Actions to be taken by the employee
                        </Label>
                        <FormField type={'text'} placeholder="Enter Description" name="actionsToBeTakenByEmployee" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'actionsToBeTakenByEmployee'} />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="qualityStandard" className="text-default text-capitalize">
                          Quality/Standard
                        </Label>
                        <FormField type={'text'} placeholder="Enter Description" name="qualityStandard" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'qualityStandard'} />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="text-default text-capitalize">Upload Documents</Label>
                        <UploaderContainer fieldName={'documents'} path={`cases/${processInstanceId}`} isMulti={true} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between">
                    <Button
                      className="btn btn-bd-purple rounded-0 text-uppercase font-weight-normal"
                      onClick={(): void => setNewShortfallModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn btn-bd-purple rounded-0 text-uppercase font-weight-normal"
                      onClick={(): void => handleSubmit()}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InitiatePIPForm;
