import { get, mutate } from './graphql-utils';
import { Department, Organisation } from '../models';
import { UpdateOrganisationInput } from '../API';

export interface LocationType {
  id: string;
  locationName: string;
  deleted: boolean;
}

export interface DepartmentType {
  id: string;
  departmentName: string;
  deleted: boolean;
}

export const getData = async (query: string, organisationId: string): Promise<Organisation> => {
  return new Promise((resolve, reject) => {
    get(query, organisationId)
      .then(res => {
        if (res && (res.data as any).getOrganisation) {
          const data: Organisation = (res.data as any).getOrganisation;
          resolve(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getOrganisationForDisciplinaryMatrix = async (
  query: string,
  organisationId: string,
): Promise<UpdateOrganisationInput> => {
  return new Promise((resolve, reject) => {
    get(query, organisationId)
      .then(res => {
        if (res && (res.data as any).getOrganisation) {
          const data: UpdateOrganisationInput = (res.data as any).getOrganisation;
          resolve(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const updateDepartmentsOrLocations = (
  query: string,
  data: { [key: string]: string | Department[] | Location[] },
): Promise<any> => {
  return new Promise<any>((resolve, reject) => {
    mutate(query, data)
      .then(response => {
        if (response && (response.data as any).updateOrganisation) {
          const data = (response.data as any).updateOrganisation;
          resolve(data);
        }
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};
