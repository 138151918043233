import { get, post } from '../api-utils';
import { FlowableVariable } from './flowable-types';
import { AxiosResponse } from 'axios';
import { ApiData } from '../../screens/WorkflowContainer/WorkflowContainer';

export const completeFlowableTask = (
  taskId: string,
  userId: string | null,
  variables?: FlowableVariable[],
): Promise<AxiosResponse<void>> => {
  const body = { userId: userId, variables: variables };
  console.log('body:', body);
  console.log('path:', `/complete-task/${taskId}`);
  return post<void>(`/complete-task/${taskId}`, { userId: userId, variables: variables });
};

export const getWorkflowContainerData = (
  masterProcessInstanceId: string,
  userId: string,
  subprocessInstanceId?: string,
): Promise<AxiosResponse<ApiData>> => {
  if (subprocessInstanceId) {
    return get<ApiData>(`/subprocess-instance/${masterProcessInstanceId}/${subprocessInstanceId}/${userId}`);
  } else {
    return get<ApiData>(`/task/${masterProcessInstanceId}/${userId}`);
  }
};
