import { AuditLogEventType, CaseRole } from '../models';
import { CreateAuditLogInput } from '../API';
import moment from 'moment';
import { mutate } from './graphql-utils';
import { createAuditLog } from '../graphql/mutations';
import { getHighestUserTypeFromRoles, UserDetails } from '../App';
import { EmailType } from './email-utils';

export const createLog = (
  user: UserDetails | null | undefined,
  type: AuditLogEventType,
  processInstanceId?: string | null,
  documentId?: string | null,
  description?: string | null,
  caseRole?: CaseRole | null,
  caseOrganisationId?: string | null,
  emailType?: EmailType | null,
  relevantEmployeeId?: string | null,
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    let organisationId = null;

    if (caseOrganisationId) {
      organisationId = caseOrganisationId;
    } else if (user) {
      if (user.organisationId) {
        organisationId = user.organisationId;
      }
      // else {
      //   reject(new Error('Missing organisationId'));
      // }
    }

    const auditLog: CreateAuditLogInput = {
      userID: user ? user.id : 'none',
      type: type,
      // @ts-ignore
      userRole: user ? getHighestUserTypeFromRoles(user) : null,
      userRoles: user ? user.userRoles : null,
      caseRoles: [],
      caseRole: caseRole ? (caseRole as CaseRole) : null,
      timeStamp: moment().toISOString(),
      organisationID: organisationId!,
      processInstanceId: processInstanceId,
      description: description,
      auditLogTemplateDocumentVersionId: documentId,
      dummyVar: 1,
      emailType: emailType,
      relevantEmployeeId,
    };

    if (auditLog.auditLogTemplateDocumentVersionId === null) delete auditLog.auditLogTemplateDocumentVersionId;
    mutate(createAuditLog, auditLog)
      .then(() => resolve())
      .catch(error => reject(error));
  });
};
