import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Admin from './layouts/AdminLayout';
import LoginScreen from './screens/Login/LoginScreen';
import { UserContext } from './App';
import routes, { RouteType } from './routes';
import { NoMatch } from './screens/404/NoMatch';
import { UserRole } from './API';
import { ConfirmReceiptView } from './screens/ConfirmReceiptView/ConfirmReceiptView';
import { SignDocumentView } from './screens/ConfirmReceiptView/SignDocumentView';
import { WorkflowContainer } from './screens/WorkflowContainer/WorkflowContainer';
import ViewCasesExternalUser from './screens/Cases/ViewCasesExternalUser';

interface RouterState {
  loading: boolean;
}

const Router: React.FC = () => {
  const [state] = useState<RouterState>({
    loading: false,
  });

  const { loading } = state;

  if (loading) {
    return null;
  }

  const caseUserRoutes = [
    {
      path: '/case/:processInstanceId',
      name: 'Workflow',
      icon: 'tim-icons icon-chart-pie-36',
      component: WorkflowContainer,
      hide: true,
    },
    {
      path: '/cases',
      name: 'Cases',
      icon: 'tim-icons icon-chart-pie-36',
      component: ViewCasesExternalUser,
      hide: false,
    },
  ];

  const getRoutesBySystemUserRoles = (userRoles: UserRole[] | null): RouteType[] => {
    // return routes;
    if (userRoles) {
      return routes.filter(route => {
        return !!route.allowedRoles?.filter(role => userRoles.includes(role)).length;
      });
    }
    return caseUserRoutes;
  };

  // require auth
  return (
    <BrowserRouter>
      <UserContext.Consumer>
        {({ loading: userLoading, currentUser }): JSX.Element =>
          !currentUser ? (
            <Switch>
              {/*<Route path={'/external/:processInstanceId/:assignee'} component={ExternalUserView} />*/}
              <Route path="/confirm-receipt/:PID" component={ConfirmReceiptView} />
              <Route path="/sign-document/:PID" component={SignDocumentView} />
              <Route
                path={'/external/:processInstanceId/:assignee'}
                render={props => <Admin {...props} routes={caseUserRoutes} />}
                exact
              />
              {!userLoading ? <Route path="/*" component={LoginScreen} /> : <></>}
            </Switch>
          ) : (
            <>
              <Switch>
                <Route
                  render={props => <Admin {...props} routes={getRoutesBySystemUserRoles(currentUser.userRoles)} />}
                  exact
                />
                <Route path="/*" component={NoMatch} />
              </Switch>
            </>
          )
        }
      </UserContext.Consumer>
    </BrowserRouter>
  );
};

export { Router };
export { UserContext };
