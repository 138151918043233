import React, { ReactElement, useContext, useState } from 'react';
import { Tooltip } from '../../components/tooltips/Tooltip.component';
import { CardTitle, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { WorkflowComponentProps } from '../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../components/workflowFooter/WorkFlowFooter';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../components/taskContainer/TaskContainer';

const ConsiderInvestigation = (props: WorkflowComponentProps): ReactElement<WorkflowComponentProps> => {
  let cachedData;
  if (
    props.data.caseData.cachedFormData &&
    props.data.caseData.cachedFormData.taskDefinitionKey === 'consider-investigation'
  ) {
    cachedData = props.data.caseData.cachedFormData.data;
  }
  const [isFurtherInvestigationRequired, setIsFurtherInvestigationRequired] = useState(
    cachedData ? cachedData.isFurtherInvestigationRequired : !!props.data.caseData.isFurtherInvestigationRequired,
  );
  const [shouldSuspendPendingInvestigation, setShouldSuspendPendingInvestigation] = useState(
    cachedData ? cachedData.shouldSuspendPendingInvestigation : !!props.data.caseData.shouldSuspendPendingInvestigation,
  );
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;

  const toggleCheckbox = (type: string, value: boolean): void => {
    switch (type) {
      case 'isFurtherInvestigationRequired':
        setIsFurtherInvestigationRequired(value);
        break;
      case 'suspend':
        setShouldSuspendPendingInvestigation(value);
        break;
      default:
        return;
    }
  };
  return (
    <div className={'content'}>
      {/*<div className="position-relative">*/}
      <CardTitle>
        <div className="h4 text-h4 mt-2 font-weight-500">Consider Investigation</div>
      </CardTitle>
      <Row>
        <Col>
          <Label className="text-default d-block">
            Does this incident require further investigation?*
            <span className="ml-3">
              <Tooltip
                id="considerInvestigation"
                title="Does this incident require further investigation?"
                text={` \n\t● An employee lodged a grievance/complaint against another employee(s);\n\t● \n\t ● There  are  a  number  of  potential  witnesses  involved  where  assessing  varying versions of the alleged offence is necessary.\n\t● `}
                jsx={
                  <>
                    There is no hard-and-fast rule to determine when an investigation is required. The question is
                    whether you have sufficient and validated evidence available to proceed to the next step in the
                    disciplinary process. You also need to assess the severity of an alleged offence. An investigation
                    is typically required where:
                    <ul>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        The alleged offence adversely affects another employee, multiple employees, third parties or the
                        employer;
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        An employee lodged a grievance/complaint against another employee(s);
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        There are multiple employees involved in an alleged offence or where the employer suspects that
                        there are additional employees involved, even though that information has not come to light at
                        the outset.
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        There are a number of potential witnesses involved where assessing varying versions of the
                        alleged offence is necessary.
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        The allegations are serious and may lead to serious disciplinary actions.
                      </li>
                    </ul>
                  </>
                }
                footer="See Guidance: Investigations for more information, if required."
              />
            </span>
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={isFurtherInvestigationRequired}
                    onChange={(): void => toggleCheckbox('isFurtherInvestigationRequired', true)}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={!isFurtherInvestigationRequired}
                    onChange={(): void => toggleCheckbox('isFurtherInvestigationRequired', false)}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">no</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="mt-4">
          <Label className="text-default d-block">
            Should the employee be suspended PENDING an investigation?*
            <span className="ml-3">
              <Tooltip
                id="suspendedPendingInvestigation"
                title="Should the employee be suspended pending the investigation?"
                jsx={
                  <>
                    There is no hard-and-fast rule to determine when an employee should be suspended, but this action
                    could constitute an unfair labour practice if not justified. You should apply your mind whether a
                    reasonable, justifiable cause exists to suspect, for example, that:
                    <ul>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        The nature of the alleged offence poses an operational risk or impacts on the trust
                        relationship.
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        The employee may interfere with the investigation or the operations of the employer.
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        The employee may interfere with or retaliate against a complainant.
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        The alleged offence/s may be repeated if the employee continues in his/her role and retains
                        access to the employer’s premises and equipment.
                      </li>
                      <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                        When the health and safety of fellow employees is at risk.
                      </li>
                    </ul>
                    <br />
                    <u>Only</u> if such is reasonably foreseeable, should you suspend the employee pending an
                    investigation or disciplinary hearing. It is reserved for serious cases of alleged misconduct. Such
                    suspension is always with pay. See Guidance: Suspension for more information, if required.
                  </>
                }
              />
            </span>
          </Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={shouldSuspendPendingInvestigation}
                    onChange={(): void => toggleCheckbox('suspend', true)}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">Yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="no"
                    checked={!shouldSuspendPendingInvestigation}
                    onChange={(): void => toggleCheckbox('suspend', false)}
                    disabled={readOnly}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">No</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      <WorkFlowFooter
        data={props.data}
        onNext={() =>
          props.data.flowableFunctions.onNext({
            isFurtherInvestigationRequired: isFurtherInvestigationRequired,
            shouldSuspendPendingInvestigation: shouldSuspendPendingInvestigation,
          })
        }
        onSaveAndClose={(): void => {
          props.data.flowableFunctions.onSaveAndClose({
            isFurtherInvestigationRequired: isFurtherInvestigationRequired,
            shouldSuspendPendingInvestigation: shouldSuspendPendingInvestigation,
          });
        }}
        onCancel={() => console.log('cancel')}
      />
    </div>
  );
};

export default ConsiderInvestigation;
