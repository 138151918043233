import React, { useContext, useState } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { UserContext, UserContextProps } from '../../../App';
import { Card, Col, Row } from 'reactstrap';
import {
  getTimePeriodFromYear,
  OptionType,
  TIME_INTERVAL_OPTIONS,
  TIME_PERIOD_OPTIONS,
  YEAR_OPTIONS,
} from './chart-options/chart-options-utils';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import CardBody from 'reactstrap/lib/CardBody';
import {
  tableFieldSelectStyles,
  ValueContainer,
} from '../../../components/reactSelect/ReactSelectComponents.component';
import Select, { ValueType } from 'react-select';
import { post } from '../../../utils/api-utils';
import { CaseDataSetQuery, ChartsQuery, DateIntervalType, MultiDataSet } from './chart-options/case-reports-types';
import ChartDropdownsSingleSelect from './chart-options/ChartDropdownsSingleSelect';
import ChartDropdownsMultiSelects from './chart-options/ChartDropdownsMultiSelects';
import Loader from '../../../components/loader/Loader';
import { toTitleCase } from '../../../utils/string-utils';
import { LineGraph } from './graphs/LineGraph';
import { BarGraph } from './graphs/BarGraph';
import { DATE_FORMAT_DASHES } from '../../../utils/date-formats';
import { json2csv } from 'json-2-csv';

//@ts-ignore
const moment = extendMoment(Moment);

const SingleVarChart: React.FC = props => {
  const [gradients] = useState(true);
  const [differentColours] = useState(false);
  const [queries, setQueries] = useState<CaseDataSetQuery[]>([]);
  const [queryFilter, setQueryFilter] = useState<CaseDataSetQuery | null>(null);
  const [loadingGraph, setLoadingGraph] = useState<boolean>(false);
  const [currentDataSet, setCurrentDataSet] = useState<MultiDataSet[]>([]);

  // Time-related options:
  const [timePeriodType, setTimePeriodType] = useState<OptionType | null>(TIME_PERIOD_OPTIONS[0]);
  const [yearPeriod, setYearPeriod] = useState<OptionType | null>(YEAR_OPTIONS[1]);
  const [intervalsType, setIntervalsType] = useState<OptionType | null>(TIME_INTERVAL_OPTIONS[0]);
  const [appliedIntervalsType, setAppliedIntervalsType] = useState<OptionType | null>(TIME_INTERVAL_OPTIONS[0]);
  const [startDate, setStartDate] = useState<Date | null>(
    moment()
      .startOf('year')
      .toDate(),
  );
  const [endDate, setEndDate] = useState<Date | null>(moment().toDate());

  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const getChartHeading = () => {
    const timePeriodText =
      timePeriodType?.value === 'year'
        ? yearPeriod?.label
        : moment(startDate).format(DATE_FORMAT_DASHES) + ' - ' + moment(endDate).format(DATE_FORMAT_DASHES);
    // e.g. quarter + ly
    return toTitleCase(intervalsType?.label + ' ' + 'cases' + ' ' + timePeriodText, ' ');
  };

  const getCurrentTimePeriod = (): { startDate: string; endDate: string } => {
    if (timePeriodType?.value === 'year' && !!yearPeriod?.value && typeof yearPeriod.value === 'string') {
      return getTimePeriodFromYear(yearPeriod.value);
    } else {
      return {
        startDate: startDate ? moment(startDate).format(DATE_FORMAT_DASHES) : '2019-01-01',
        endDate: endDate ? moment(endDate).format(DATE_FORMAT_DASHES) : '2099-01-01',
      };
    }
  };



  const onClickUpdate = () => {
    //@ts-ignore
    let json2csvCallback = function (err, csv) {
      if (err) throw err;

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", 'chartdata.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    }

    if (currentUser?.organisationId) {
      const chartsQuery: ChartsQuery = {
        dataSetQueries: queries,
        dateIntervalType: intervalsType?.value as DateIntervalType,
        organisationId: currentUser?.organisationId,
        timePeriod: getCurrentTimePeriod(),
      };
      if (queryFilter) {
        chartsQuery.filterDataSetQuery = queryFilter;
      }
      console.log('chartsQuery: ', chartsQuery);
      setLoadingGraph(true);
      post<MultiDataSet[]>('/case-reporting', chartsQuery)
        .then(res => {
          console.log('res', res);
          if (res.data && res.data.length > 0) {
            //@ts-ignore
            const cases = [];
            res.data.forEach(dataset => {
              console.log(dataset)
              //@ts-ignore
              dataset.data.forEach(el => {
                if (el.cases) {
                  //@ts-ignore
                  el.cases.forEach(object => { cases.push(object) })
                }
              })

            })
            //@ts-ignore
            console.log(cases)
            //@ts-ignore
            if(false){
              json2csv(cases, json2csvCallback);
            }

          }

          setAppliedIntervalsType(intervalsType);
          setCurrentDataSet(res.data);
          setLoadingGraph(false);
        })
        .catch(() => {
          setLoadingGraph(false);
        });
    }
  };

  const renderCharts = (): JSX.Element => {
    if (appliedIntervalsType?.value === DateIntervalType.MONTH) {
      return (
        <div
          style={{
            width: '100%',
            margin: '20px',
            boxSizing: 'border-box',
          }}
        >
          <Card className="shadow mb-5 bg-white" style={{ alignItems: 'center' }}>
            <LineGraph
              multiDataSets={currentDataSet}
              heading={getChartHeading()}
              gradients={gradients}
              colourStart={differentColours ? 60 : 270}
            />
          </Card>
        </div>
      );
    } else if (
      appliedIntervalsType?.value === DateIntervalType.QUARTER ||
      appliedIntervalsType?.value === DateIntervalType.YEAR
    ) {
      return (
        <BarGraph
          multiDataSets={currentDataSet}
          heading={getChartHeading()}
          gradients={gradients}
          colourStart={differentColours ? 60 : 270}
        />
      );
    } else {
      return <div />;
    }
  };

  return (
    <div className="px-3 mt-4">
      <Card className="shadow bg-white" style={{ alignItems: 'left' }}>
        <CardBody>
          <Row className="d-flex justify-content-between align-items-start py-0 px-3" style={{ height: '20px' }}>
            <h4 className="text-default">CASES OVER TIME</h4>
          </Row>
          <hr />
          <Row>
            <Col md={4}>
              <h5 className="text-default">Choose variables for comparison:</h5>
              <ChartDropdownsMultiSelects
                heading="Filter"
                onChange={(queries: CaseDataSetQuery[]) => setQueries(queries)}
              />
              <button className="btn btn-primary w-100" onClick={() => onClickUpdate()} disabled={!queries.length}>
                UPDATE CHART
              </button>
            </Col>
            <Col md={4}>
              <h5 className="text-default">Choose time period:</h5>
              <Select
                placeholder="Select Time Period"
                cacheOptions
                options={TIME_PERIOD_OPTIONS}
                closeMenuOnSelect={true}
                styles={tableFieldSelectStyles}
                value={timePeriodType}
                onChange={(value: ValueType<any>): void => setTimePeriodType(value)}
                components={{ ValueContainer }}
              />

              {timePeriodType?.value === 'custom' && (
                <div>
                  <label style={{ color: '#ababab', marginRight: 10 }}>
                    Select start date:
                    <DatePicker
                      dateFormat={'yyyy-MM-dd'}
                      shouldCloseOnSelect={true}
                      selected={startDate}
                      onChange={(date: Date | null): void => {
                        setStartDate(date);
                      }}
                    />
                  </label>
                  <br />
                  <label style={{ color: '#ababab', marginRight: 10 }}>
                    Select end date:
                    <DatePicker
                      dateFormat={'yyyy-MM-dd'}
                      shouldCloseOnSelect={true}
                      selected={endDate}
                      onChange={(date: Date | null): void => {
                        setEndDate(date);
                      }}
                    />
                  </label>
                </div>
              )}
              {timePeriodType?.value === 'year' && (
                <div>
                  <Select
                    placeholder="Select Year"
                    cacheOptions
                    value={yearPeriod}
                    options={YEAR_OPTIONS}
                    closeMenuOnSelect={true}
                    styles={tableFieldSelectStyles}
                    onChange={(value: ValueType<any>): void => {
                      setYearPeriod(value || []);
                    }}
                    components={{ ValueContainer }}
                  />
                </div>
              )}
              <Select
                placeholder="Choose Time Intervals"
                cacheOptions
                options={TIME_INTERVAL_OPTIONS}
                closeMenuOnSelect={true}
                styles={tableFieldSelectStyles}
                value={intervalsType}
                onChange={(value: ValueType<any>): void => {
                  setIntervalsType(value);
                }}
                components={{ ValueContainer }}
              />
            </Col>
            <Col md={4}>
              <h5 className="text-default">Choose filter:</h5>
              <ChartDropdownsSingleSelect heading="Filter" onChange={filter => setQueryFilter(filter)} />
            </Col>
          </Row>
          {loadingGraph ? (
            <Row className="d-flex flex-row justify-content-center">
              {' '}
              <Loader></Loader>{' '}
            </Row>
          ) : (
            <></>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>{renderCharts()}</div>
        </CardBody>
      </Card>
    </div>
  );
};

export default SingleVarChart;
