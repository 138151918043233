import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Card } from 'reactstrap';
import { generateGraphData } from './graphs.utils';
import { DataSet, GraphData, MultiDataSet } from '../chart-options/case-reports-types';

type LineData = {
  /**
   * Data to show in the table.
   */
  multiDataSets: MultiDataSet[];
  /**
   * Heading of the table
   */
  heading: string;
  /**
   * Should the chart generate gradients for the data visualization.
   */
  gradients?: boolean;
  /**
   * Where to start the colours of the chart? 0 - 360 (Based on colour wheel, HSL colours)
   */
  colourStart?: number;
};

export const BarGraph: React.FC<LineData> = ({ multiDataSets, heading }) => {
  const data: GraphData = useMemo(() => {
    const output = generateGraphData(multiDataSets);
    console.log(123, { output });
    output.datasets?.forEach((item: DataSet) => {
      item.fill = true;
      item.borderWidth = 0;
    });
    console.log('graphdataa: ', output);

    return output;
  }, [multiDataSets]);

  return (
    <div
      style={{
        width: '100%',
        margin: '20px',
        boxSizing: 'border-box',
      }}
    >
      <Card className="shadow mb-5 bg-white" style={{ alignItems: 'center' }}>
        <Bar
          data={data}
          options={{
            title: {
              display: true,
              text: heading,
              fontSize: 16,
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                  },
                  ticks: {
                    min: 0,
                    stepSize: 10,
                  },
                },
              ],
            },
          }}
        />
      </Card>
    </div>
  );
};
