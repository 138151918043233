import React, { ChangeEvent, ReactElement, useContext, useState } from 'react';
import { Field, FieldAttributes, FormikValues, useFormikContext } from 'formik';
import moment from 'moment';
import { Input } from 'reactstrap';
import DateTime from 'react-datetime';
import { CalenderIcon, ClockIcon } from '../../components/icon/Icon.component';
import { startCase } from 'lodash';
import { ReadOnlyContextProps, ReadOnlyContext } from '../../components/taskContainer/TaskContainer';

interface SelectOptionWithLabel {
  value: string;
  label: string;
}

interface FormFieldProps {
  type: string;
  placeholder: string;
  name: string;
  selectOptions?: string[];
  selectOptionsWithLabels?: SelectOptionWithLabel[];
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  style?: object;
}

export const transformSelectValue = (value: string): string => {
  return startCase(value.toLowerCase());
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';

export const autoExpand = (event: ChangeEvent, stateCallback: (value: any) => void): void => {
  const field: any = event.target;
  field.style.height = 'inherit';
  const computed = window.getComputedStyle(field);
  const height =
    parseInt(computed.getPropertyValue('border-top-width'), 10) +
    parseInt(computed.getPropertyValue('padding-top'), 10) +
    field.scrollHeight +
    parseInt(computed.getPropertyValue('padding-bottom'), 10) +
    parseInt(computed.getPropertyValue('border-bottom-width'), 10);
  field.style.height = height + 'px';
  stateCallback(field.style.height);
};

export const setDateTime = (
  value: string | moment.Moment,
  setFieldValue: (name: string, value: any) => void,
  name: string,
  type: string,
): void => {
  let dateTimeValue = value;
  if (value instanceof moment) {
    if (type === 'date') {
      dateTimeValue = moment(value).format(DATE_FORMAT);
    } else if (type === 'time') {
      dateTimeValue = moment(value).format(TIME_FORMAT);
    }
  }
  setFieldValue(name, dateTimeValue);
};

const FormField: React.FC<FormFieldProps> = (props: FormFieldProps) => {
  const { type, placeholder, name, selectOptions, selectOptionsWithLabels, className, disabled = false, style } = props;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly || props.readOnly;
  const { setFieldValue } = useFormikContext();
  const [textAreaHeight, setTextAreaHeight] = useState('1');
  // const disablePastDate = (props: PastDateProps) => {
  //   const { current } = props;
  //   const yesterday = moment().subtract(1, 'day');
  //   return current.isAfter(yesterday);
  // };

  return (
    <>
      {(type === 'text' || type === 'email' || type === 'number' || type === 'password') && (
        <Field name={name} type={type}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <Input
              readOnly={readOnly}
              {...field}
              placeholder={placeholder}
              autoComplete="off"
              type={type}
              className={className}
              disabled={readOnly || disabled}
              style={{ margin: 0, minWidth: '18vw', ...style }}
            />
          )}
        </Field>
      )}

      {type === 'textarea' && (
        <Field name={name} type={type}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <Input
              readOnly={readOnly}
              {...field}
              placeholder={placeholder}
              type={type}
              className={className}
              height={textAreaHeight}
              autoComplete="off"
              style={{ minHeight: '2em', maxHeight: '20vh', width: '100%', margin: 0, ...style }}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                event.preventDefault();
                setFieldValue(name, event.target.value);
                autoExpand(event, setTextAreaHeight);
              }}
              disabled={readOnly || disabled}
            />
          )}
        </Field>
      )}
      {(type === 'date' || type === 'time') && (
        <Field name={name}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <>
              <DateTime
                onChange={(dateTime: string | moment.Moment): void => setDateTime(dateTime, setFieldValue, name, type)}
                dateFormat={type === 'date'}
                timeFormat={type === 'time'}
                // isValidDate={disablePastDate}
                inputProps={{
                  className: `form-control ${className} `,
                  placeholder: placeholder,
                  ...field,
                  disabled: readOnly || disabled,
                  autoComplete: 'off',
                  onKeyDown: e => e.preventDefault(),
                }}
              />
              <label style={{ fontSize: '2em', position: 'absolute', right: '0.6em', top: '1.09em' }}>
                {type === 'time' && <ClockIcon />}
                {type === 'date' && <CalenderIcon />}
              </label>
            </>
          )}
        </Field>
      )}
      {type === 'select' && (
        <Field name={name} placeholder={placeholder}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <Input
              {...field}
              type="select"
              disabled={readOnly || disabled}
              autoComplete="off"
              readOnly={readOnly}
              style={{ margin: 0, minWidth: '18vw', padding: '2px', ...style }}
            >
              <option className="text-muted" value="">
                {placeholder}
              </option>
              {selectOptions &&
                selectOptions.map((value, key) => (
                  <option key={key} value={value.toUpperCase()}>
                    {transformSelectValue(value)}
                  </option>
                ))}
            </Input>
          )}
        </Field>
      )}
      {type === 'selectWithLabels' && (
        <Field name={name} placeholder={placeholder}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <Input
              {...field}
              type="select"
              disabled={readOnly || disabled}
              autoComplete="off"
              readOnly={readOnly}
              style={{ margin: 0, minWidth: '18vw', ...style }}
            >
              <option className="text-muted" label={placeholder} value={''} />
              {selectOptionsWithLabels &&
                selectOptionsWithLabels.map((item: SelectOptionWithLabel, key) => (
                  <option key={key} label={item.label} value={item.value} />
                ))}
            </Input>
          )}
        </Field>
      )}
      {type === 'selectWithLabels2' && (
        <Field name={name} placeholder={placeholder}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <Input
              {...field}
              type="select"
              disabled={disabled}
              autoComplete="off"
              // readOnly={readOnly}
              style={{ margin: 0, minWidth: '18vw', ...style }}
            >
              <option className="text-muted" label={placeholder} value={''} />
              {selectOptionsWithLabels &&
                selectOptionsWithLabels.map((item: SelectOptionWithLabel, key) => (
                  <option key={key} label={item.label} value={item.value} />
                ))}
            </Input>
          )}
        </Field>
      )}
    </>
  );
};

export default FormField;
