import React from 'react';

export const RoleOfChairperson: React.FC = () => {
  return (
    <div>
      <div>
        <h3 className="font-weight-bold">GUIDANCE: ROLE OF THE CHAIRPERSON</h3>
        <p className="font-weight-bold">What is my role as chairperson of an Incapacity hearing?</p>
        <p>
          As chairperson you will be expected to guide all parties during the Incapacity poor performance hearing to
          obtain a fair outcome.
        </p>
        <br />
        <p>
          You will need to decide whether the employee is unable to perform his/her work to the standard required by the
          employer and whether such incapacity arises from a lack of critical KSAs - knowledge, skills, abilities,
          attitudes (generally referred to as competencies). Proof must exist that the employee failed to meet the
          performance standards set. You also need to assess whether the employee was aware of or would reasonably have
          been aware of the standards and was given a fair opportunity to meet these standards.
        </p>
        <br />
        <p>
          You will also be required to advise on a way forward, typically whether it would be fair to dismiss the
          employee or whether other alternatives exist.
        </p>
        <br />
        <p>
          The independence of a chairperson of an incapacity hearing is a critical precondition to the result of the
          hearing being procedurally and substantively fair. The law recognises that you may well be part of the
          management team and can therefore never be absolutely neutral. You may, for example, know the employee's
          personal traits and past performance history. This does not in itself disqualify you from chairing the
          hearing. In essence, the requirement is that the chairperson must approach the case with an open and objective
          mind. As chairperson, you must not have an emotional or vested interest in the case. You may seek outside
          assistance on how to arrive at a balanced decision.
        </p>
        <p>
          Your behaviour and the manner in which you conduct the proceedings of the hearing will ultimately determine
          the fairness or otherwise of the hearing
        </p>
      </div>
    </div>
  );
};

export const RoleOfEmployerRep: React.FC = () => {
  return (
    <div>
      <h3 className="font-weight-bold">GUIDANCE: THE ROLE OF THE EMPLOYER REPRESENTATIVE</h3>
      <p className="font-weight-bold">What is my role as employer representative?</p>
      <p>
        As employer representative, you will be expected to argue the employer’s case at the Incapacity poor performance
        hearing. You need to prepare thoroughly for the hearing, making sure you understand the evidence available. You
        may be the manager who counselled the employee previously and/or engaged in drafting and overseeing a
        Performance Improvement Plan (PIP) for the employee.{' '}
      </p>
      <br />
      <p>You must liaise with employer witnesses beforehand, if applicable, but without influencing their versions.</p>
      <br />
      <p>
        The onus of proof of poor performance is on the employer and you must convince the chairperson that the employee
        is unfit to perform their job.
      </p>
      <br />
      <p>
        You will also be expected to argue an appropriate way forward, with due regard for the employer’s Incapacity
        Poor Performance Policy, past precedents in the workplace, relevant circumstances and the preceding incapacity
        process.
      </p>
      <br />
      <p className="font-weight-bold">How should I prepare for the hearing? </p>
      <p>
        It is vital that you prepare thoroughly. This includes preparing documentation and evidence, as well as any
        witnesses that will be called to testify at the hearing.
      </p>
      <br />
      <p>Make sure that you’re prepared for every relevant issue which must be proven: </p>
      <br />
      <p className="font-weight-bold">Make sure that you’re prepared for every relevant issue which must be proven:</p>
      <ul>
        <li className="list-style">The standard of performance expected and/or deliverables;</li>
        <li className="list-style">Substantive evidence of performance shortfalls;</li>
        <li className="list-style">Specific incidents of poor performance in the period under review; </li>
        <li className="list-style">
          The incapacity process followed so far, including any counselling sessions, PIP and assistance given by the
          employer;{' '}
        </li>
        <li className="list-style">
          Possible defenses and excuses for serious and/or continued poor performance and how to refute those;{' '}
        </li>
        <li className="list-style">
          Alternatives available to deal with the issues arising, if any (for example, the possibility of demoting the
          employee).{' '}
        </li>
      </ul>
      <br />
      <p>
        Prepare an opening statement summarising what you are going to present to the chairperson during the hearing.
      </p>
      <br />
      <p>
        Prepare a paginated bundle of all the documentary evidence (if any) for yourself, and make copies for the
        chairperson, the employee and one to use for witnesses called into the hearing.
      </p>
      <br />
      <p>
        Have a recording device ready on the day of the hearing and ask the chairperson’s permission upfront to record
        the proceedings.
      </p>
    </div>
  );
};

export const EmployeeRights: React.FC = () => {
  return (
    <div>
      <h3 className="font-weight-bold">GUIDANCE: EMPLOYEE RIGHTS DURING APPEAL</h3>
      <p className="font-weight-bold">
        What rights does the alleged offending employee have during the Incapacity poor performance hearing process?
      </p>
      <br />
      <p>The alleged poor performer has the right to: </p>
      <ul>
        <li className="list-style">
          Receive beforehand a formal Notice of an Incapacity Poor Performance Hearing, setting out the alleged
          performance shortfalls raised against him/her;
        </li>
        <li className="list-style">A reasonable timeframe to prepare a response; </li>
        <li className="list-style">
          State his/her case in response to the allegations made by the employer (known as the audi alteram partem
          rule);
        </li>
        <li className="list-style">
          Be represented by a fellow employee or shop steward of a recognised union in the workplace, if a member of
          such a union;
        </li>
        <li className="list-style">
          A translator in his/her language of choice, even if in the normal course of business, he/she communicates in
          the business language (typically English);
        </li>
        <li className="list-style">Call witnesses and present his/her evidence; </li>
        <li className="list-style">
          Cross examination employer witnesses either himself/herself or through a representative;
        </li>
        <li className="list-style">Be informed of the hearing outcomes and reasons for those outcomes;</li>
        <li className="list-style">
          Be informed of the reason for a dismissal and his/her right to refer a dispute to the CCMA, if dissatisfied
          with the outcome.
        </li>
      </ul>
      <br />
      <p className="font-weight-bold">Who may represent an employee in an Incapacity hearing?</p>
      <br />
      <p>
        Employees accused of poor performance have the right to be represented by a fellow employee of their choice. If
        a trade union is recognised in the workplace and shop stewards have been elected, the latter have the right to
        represent their members. It remains the employee’s prerogative to choose an internal representative, or to
        represent himself/herself.
      </p>
      <p>
        Sometimes employees wish for external legal representatives or unrecognised trade unions to assist and represent
        them.
      </p>
      <p>
        You need to check your own Incapacity Poor Performance Policy, if any, and abide by the provisions of the
        Policy. If the Policy is silent on the issue or even where it prohibits external representation or if you have
        no Policy, it is best to allow the alleged offender to motivate the need for an external representative to the
        chairperson of the hearing. The chairperson must make a decision on the request with due regard for procedural
        fairness, meaning that he/she must decide whether and to what extent the employee will be prejudiced, if such
        external representation is not allowed.
      </p>
      <br />
      <p>Factors to be considered holistically by the chairperson include: </p>
      <ul>
        <li className="list-style">
          The seriousness of the alleged poor performance and possible consequences if found incapacitated;
        </li>
        <li className="list-style">
          The profile of the employer representative and the need to “level the playing field”;{' '}
        </li>
        <li className="list-style">
          Whether the matter is a simple factual matter or whether technical issues are at stake (such as reasonable
          accommodation needs of a person with a disability);
        </li>
        <li className="list-style">
          The need to keep an internal process simple and expedient and avoid unnecessary precedents from being created;
        </li>
        <li className="list-style">
          Whether and how either the employer or employee could be prejudiced by the ruling.
        </li>
      </ul>
      <p>
        The mere fact that dismissal may follow, is not in itself sufficient ground to justify external representation.
        Written or oral submissions by both parties should be invited prior to the chairperson coming to a ruling in
        this regard. LabourTeq provides for the recording of these submissions as well as the chairperson’s decision.
      </p>
      <br />
      <p>
        External representation should generally speaking not be allowed into the internal incapacity process. It should
        be the exception rather than the norm, unless your Incapacity Policy expressly allows for it.
      </p>
      <br />
      <p className="font-weight-bold">Does the employee have a right of appeal?</p>
      <p>
        The employee has a right to appeal, <span className="font-weight-bold">only if</span> the employer’s own
        Incapacity Policy provides for an appeal process. If no internal appeal process is available, he/she has the
        right to directly refer the matter to a dispute resolution body (i.e. the CCMA or Bargaining Council, as
        applicable) as an unfair labour practice dispute or unfair dismissal, as applicable in the circumstances.
      </p>
    </div>
  );
};

export const IncapacityHearingSteps: React.FC = () => {
  return (
    <div>
      <h3 className="font-weight-bold">GUIDANCE: INCAPACITY POOR PERFORMANCE HEARING STEPS </h3>
      <p className="font-weight-bold">What is the purpose of an Incapacity poor performance hearing? </p>
      <p>
        The hearing gives the employee a fair and objective opportunity to present his/her case in response to
        allegations of serious or habitual poor performance brought against him/her as a final step in the incapacity
        process. This is in line with legislative requirements. Procedural and substantive fairness principles must be
        complied with to ensure a justifiable outcome.
      </p>
      <br />
      <p className="font-weight-bold">What steps should be followed by the chairperson during the hearing? </p>
      <br />
      <p className="font-weight-bold">OPEN THE HEARING, CONFIRMING EMPLOYEE RIGHTS AND PROCESS: </p>
      <br />
      <p>
        1. Confirm the names of the parties present and the capacity in which they are attending. Confirm whether a
        scribe will be used and/or a recording made.
      </p>
      <p>
        2. Explain the purpose of the hearing namely, first, to establish whether the employee is incapacitated and
        cannot perform their job due to a lack of competency and, second, if so, to decide on an appropriate outcome.
      </p>
      <p>
        3. Confirm that the employee received the Notice of hearing and had sufficient time to prepare. If he/she
        alleges not to have had enough time to prepare, debate the merits of a postponement and make a ruling.
      </p>
      <p>
        4. Confirm the language in which the hearing will be held and if a translator is required. If the employee did
        not request a translator prior to the hearing (as requested in the Notice of Hearing) and requests one in the
        hearing, consider postponing the hearing to arrange one, based on the merits of the situation.
      </p>
      <p>
        5. Confirm whether the employee is represented by a colleague or shop steward or whether he/she will represent
        him/herself. If the employee requests external representation, allow both the employer and employee parties to
        make submissions on the merits of the request and make a ruling.
      </p>
      <p>
        6. Confirm the process to be followed, the right to present evidence, to testify, to call witnesses, and to
        cross-examine the other parties’ witnesses.
      </p>
      <p>
        7. Read/have the allegations read as per the Notice of the Hearing and ask the employee to indicate whether they
        agree with the allegations or not.
      </p>
      <br />
      <p className="font-weight-bold">IF THE EMPLOYEE CONFIRMS HIS/HER INCAPACITY: </p>
      <p>
        8a. Ask the employee to explain what happened and why they think they are performing poorly to the extent that
        they are incapacitated. Ask the employer representative to provide input. Ascertain the extent of the incapacity
        process followed to date and the assistance given to the employee.
      </p>
      <p>
        8b. If satisfied, proceed to discussing the way forward, as below [16 onwards]. If not, rather proceed with the
        evidence phase, as below [9 onwards].
      </p>
      <br />
      <p className="font-weight-bold">
        IF THE EMPLOYEE DISAGREES WITH THE ALLEGATIONS OF POOR PERFORMANCE, PROCEED WITH THE EVIDENCE PHASE:{' '}
      </p>
      <p>9. Allow both parties to make Opening Statements, if they want to.</p>
      <p>10. Ask the employer representative to proceed with his/her evidence.</p>
      <p>
        10a. Allow for employer witnesses to be called, <span className="font-weight-bold">one by one</span> . First the
        employer representative will question his/her own witness/es or will testify himself/herself. Then allow for
        cross-examination by the employee or his/her representative. The employer representative may redress their
        witness thereafter, should they want to.
      </p>
      <p>
        10b. Allow for documentary evidence or other relevant evidence to be submitted into record, if any. As
        chairperson, ask clarifying questions if required.
      </p>
      <p>
        11. After the employer representative has completed leading evidence, ask the employee or his/her representative
        to lead evidence. The employee can first testify on his/her own behalf and state a case in response to the
        charges.
      </p>
      <p>11a. Repeat the evidence leading process as above, as applicable (10a and 10b above).</p>
      <p>12. Allow both parties to make Closing Arguments, if they want to.</p>
      <p>
        13. Assess the evidence to come to a conclusion on whether the employee is guilty of poor performance, whether
        the performance standards were reasonable and known and whether he/she had been granted a reasonable opportunity
        to improve. The hearing may be adjourned for this purpose. The period of adjournment, if any, is at the
        discretion of the chairperson OR as prescribed in the employer’s Incapacity Poor Performance Policy. The
        preceding incapacity process and the fairness of that process must be analysed.{' '}
      </p>
      <p>14. Evaluate the facts and decide on a balance of probability whether the employee is indeed incapacitated.</p>
      <br />
      <p className="font-weight-bold">IF NOT: </p>
      <p>
        15. Communicate the outcome and close the case. Prepare a written ruling summarising the outcomes of the
        hearing.
      </p>
      <br />
      <p className="font-weight-bold">IF INCAPACITATATED, PROCEED TO DISCUSSING THE WAY FORWARD: </p>
      <p>16. Communicate the ruling and ask for submissions on the way forward and possible solutions. </p>
      <p>
        16a. Allow the employer representative to submit aggravating factors and to make a recommendation on the way
        forward, including alternatives considered such as a demotion or transfer.{' '}
      </p>
      <p>
        16b. Allow the employee or his/her representative to comment and/or submit mitigating factors. He/she may make a
        recommendation on the way forward and suggest possible alternatives to dismissal.
      </p>
      <p>16c. Consider past precedents for similar cases, if known.</p>
      <p>
        17. Apply your mind and come to a decision on an appropriate solution and whether dismissal is justified. The
        hearing may be adjourned for this purpose. The period of adjournment, if any, is at the discretion of the
        chairperson OR as prescribed in the employer’s Policy.
      </p>
      <p>18. Communicate the ruling on the way forward and close the case.</p>
      <p>
        19. Inform the employee of his/her right to appeal, only if the employer’s Policy allows for an appeal process.
      </p>
      <p>
        20. In case of dismissal, inform the employee that he/she has the right to refer the matter to the Commission
        for Conciliation, Mediation and Arbitration (CCMA) or applicable bargaining council dispute resolution body,
        should he/she be dissatisfied with the outcome of the hearing.
      </p>
      <p>
        21. Prepare a written ruling summarising the outcomes of the hearing. The employer should then issue the
        relevant documentation, for example a dismissal or final written warning letter or extended Performance
        Improvement Plan (PIP), to give effect to your ruling.
      </p>
      <br />
      <p>Ensure throughout this process that you remain objective and impartial.</p>
    </div>
  );
};

export const IncapacityOutcomes: React.FC = () => {
  return (
    <div>
      <h3 className="font-weight-bold">GUIDANCE: DEALING WITH INCAPACITY POOR PERFORMANCE</h3>
      <p className="font-weight-bold">
        What is the link between performance management and the incapacity poor performance process?
      </p>
      <p>
        When employees receive ‘missed expectations,’ ‘needs improvement’ or ‘poor performer’ ratings in formal
        appraisals/reviews, it is highly recommended that managers begin an incapacity process to address the
        performance shortfalls proactively and appropriately.
      </p>
      <p>
        When poor performance is accepted on an ongoing basis or managed poorly, employees are left to flounder,
        inconsistencies are created in how poor performance is dealt with throughout the organisation, and productivity
        and operations are negatively affected.
      </p>
      <p>
        Ensure that performance ratings accurately reflect performance levels. For example, don’t allocate a ‘meets
        expectations’ rating to a poorly performing employee. This creates confusion and inconsistent messaging and
        makes it difficult to defend undertaking a poor performance process.
      </p>
      <p>
        Managers, however, should not wait for appraisal/review cycle discussions to address concerns. They should and
        can act at any point during the performance cycle when poor performance is observed.
      </p>
      <br />
      <p className="font-weight-bold">How do I decide on an appropriate way forward to deal with poor performance? </p>
      <p>
        Substantive evidence must first exist that the employee is not meeting performance expectations in the
        circumstances. The onus is on the employer to prove that the employee’s performance is lacking.
      </p>
      <p>
        Aggravating and mitigating factors should be considered holistically. Past precedents in the workplace play a
        role in ensuring consistency of treatment across the employee population. Considering the nature and extent of
        previous attempts by the employer to assist the employee is critical. An employer can more confidently embark on
        a formal process when reasonable steps have already been taken to improve the employee’s performance. If nothing
        or very little has been done, typically the employee needs to receive feedback through a counselling process and
        a reasonable opportunity to improve. The employee must in all instances, whether the process is formal or
        informal, be granted a reasonable opportunity to improve.
      </p>
      <p>
        Only once all these factors have been assessed, will one determine appropriate steps, which may include
        counselling, a PIP, or dismissal.
      </p>
      <p>
        Management of poor performance is fundamentally an assistive and corrective process. However, the employer also
        does not need to tolerate performance shortcomings indefinitely. These should be identified at any time in the
        employment cycle and dealt with proactively. Once the process has progressed to an incapacity hearing,
        termination of services may be appropriate.
      </p>
      <br />
      <p className="font-weight-bold">When will dismissal be appropriate? </p>
      <p>
        The chairperson must consider the following questions during the incapacity hearing as per legislative
        requirements. Based on a holistic assessment, he/she must decide whether dismissal is the appropriate outcome:
      </p>
      <ul>
        <li className="list-style">
          Did the employee fail to meet the required performance standard/s for his/her role and level? To what extent?{' '}
        </li>
        <li className="list-style">
          Was the employee aware, or could it be reasonably expected of the employee to be aware of the performance
          standard/s?
        </li>
        <li className="list-style">Was the performance standard reasonable?</li>
        <li className="list-style">
          What were the consequences of the employee’s failure to meet required performance standard/s?
        </li>
        <li className="list-style">
          Was the employee given a reasonable period of time and assistance to meet the required performance standard
          given the circumstances of the particular case?
        </li>
        <li className="list-style">
          Is dismissal the appropriate sanction for not meeting the required performance standard or is there another
          way, short of dismissal, to remedy the poor performance; for example, demotion to a lower level job?{' '}
        </li>
        <li className="list-style">
          Are there any mitigating factors that favour the employee that need to be considered? (see guidance below).{' '}
        </li>
        <li className="list-style">
          Are there any aggravating factors that need to be considered? (see guidance below).{' '}
        </li>
        <li className="list-style">
          Any person making a decision about the fairness of a dismissal of an employee for poor performance during or
          on the expiry of a probationary period, may accept reasons for dismissal that may be less compelling than
          would be required in a dismissal for poor performance exhibited after the completion of the probationary
          period.
        </li>
        <li className="list-style">
          Seniority and experience play a role in that such an employee should be able to self-assess and self-correct
          shortcomings. Failure to do so supports dismissal as the outcome of the incapacity process.
        </li>
      </ul>
      <br />
      <p className="font-weight-bold">
        The employer may present aggravating factors when considering a way forward. What does that mean?
      </p>
      <p>
        Factors that support termination of employment should be brought to the chairperson’s attention. These include
        the following:
      </p>
      <ul>
        <li className="list-style">The nature and seriousness of the poor performance;</li>
        <li className="list-style">The operational impact of the shortfalls;</li>
        <li className="list-style">
          Monetary loss/damages suffered by the employer due to the performance shortfalls;
        </li>
        <li className="list-style">
          Risks arising as a result of the poor performance – financial, social reputation of the employer, health and
          safety and the like;{' '}
        </li>
        <li className="list-style">The employee’s position in the workplace and seniority level;</li>
        <li className="list-style">Whether he/she is still on probation and thus in a trial period;</li>
        <li className="list-style">
          Negative impact on the trust relationship between employer and employee, alternatively, irreparable breakdown
          in the trust relationship;
        </li>
        <li className="list-style">Negative impact on colleagues/teams and/or customers/clients. </li>
      </ul>
      <br />
      <p className="font-weight-bold">
        The employee may argue mitigating factors when discussing a way forward. What does that mean?{' '}
      </p>
      <p>
        Any factors that count in favour of a less harsh approach, should be brought to the chairperson’s attention.
        These include the following:
        <ul>
          <li className="list-style">The employee’s financial or family responsibilities; </li>
          <li className="list-style">Tenure - years of loyal service; </li>
          <li className="list-style">
            Recent promotion or the introduction of new technology and the employee not coping in the new position or
            struggling to adapt to the changes;
          </li>
          <li className="list-style">The employee’s personal circumstances. </li>
        </ul>
      </p>
      <br />
      <p className="font-weight-bold">Should alternatives to dismissal be considered? </p>
      <p>
        Yes, the employer should consider whether viable alternatives to dismissal exist. This includes demoting the
        employee (with a reduction in remuneration) or transferring him/her to another position. This obligation does
        not arise when no suitable position is available at the time and the employer is not obliged to create a
        position for an incompetent employee. In the incapacity hearing the employee should be granted an opportunity to
        suggest solutions other than dismissal for the employer’s consideration.
      </p>
      <br />
      <p className="font-weight-bold">What should I do if the employee refuses to sign any hearing documentation? </p>
      <p>
        You are merely requesting the employee to sign in acknowledgement of receipt. This does not mean that he/she
        necessarily agrees with the outcome of the incapacity hearing. Explain this to the employee. However, it is
        within his/her right to refuse to sign. In this instance hand the hearing outcome to the employee in the
        presence of a witness. The witness then signs the document indicating that it was handed to the employee in the
        witness’s presence. Place the document on the employee’s record. The employee’s signature is not required for
        the hearing outcome to be valid.
      </p>
    </div>
  );
};
