export interface TaskMapping {
  name: string;
  timeFrame?: {
    dueSoon: number;
    overdue: number;
  };
  timeFrameWaitsForDueDate?: boolean;
}

//TODO the task IDs must be changed to be consistent.
// 1.) "-ih-" was supposed to mean "initial hearing" but is used on appeal hearing tasks
// 2.) "-appeal" and "-er" are often switched around.
// These IDs reflect the "update LT Tasks" scripts on flowable and the ids of document tasks like "draft-new-suspension-letter",
// so updating the IDs here will require changes to Flowable too.

export const taskMappings: { [key: string]: TaskMapping } = {
  // MISCONDUCT
  'lt-appoint-investigator': {
    name: 'Appoint Investigator',
    timeFrame: {
      dueSoon: 24,
      overdue: 48,
    },
  },
  'lt-initiate-suspension-process': {
    name: 'Initiate suspension process',
    timeFrame: {
      dueSoon: 12,
      overdue: 24,
    },
  },
  'draft-new-suspension-letter': {
    name: 'Draft and issue suspension letter',
    timeFrame: {
      dueSoon: 24,
      overdue: 48,
    },
  },
  'lt-conduct-disciplinary-discussion': {
    name: 'Conduct and document disciplinary discussion',
    timeFrame: {
      dueSoon: 96,
      overdue: 144,
    },
  },
  'draft-new-warning': {
    name: 'Draft and issue sanction letter',
    timeFrame: {
      dueSoon: 96,
      overdue: 144,
    },
  },
  'lt-proceed-with-investigation': {
    name: 'Proceed with investigation',
    timeFrame: {
      dueSoon: 48,
      overdue: 0,
    },
  },

  // INITIAL HEARING
  'lt-ih-initiate-and-prepare-for-hearing': {
    name: 'Initiate and prepare for ${hearingType} hearing',
    timeFrame: {
      dueSoon: 60,
      overdue: 48,
    },
    timeFrameWaitsForDueDate: true,
  },
  'lt-ih-initiate-and-prepare-for-hearing-er': {
    name: 'Initiate and prepare for ${hearingType} hearing',
    timeFrame: {
      dueSoon: 60,
      overdue: 48,
    },
    timeFrameWaitsForDueDate: true,
  },
  'draft-new-notice-of-hearing': {
    name: 'Draft and issue notice of hearing',
    timeFrame: {
      dueSoon: 60,
      overdue: 48,
    },
    timeFrameWaitsForDueDate: true,
  },
  'draft-new-notice-of-hearing-er': {
    name: 'Draft and issue notice of hearing',
    timeFrame: {
      dueSoon: 60,
      overdue: 48,
    },
    timeFrameWaitsForDueDate: true,
  },
  'lt-ih-manage-hearing': {
    name: 'Manage ${hearingType} hearing',
  },
  'lt-ih-manage-hearing-er': {
    name: 'Manage ${hearingType} hearing',
  },
  'lt-ih-capture-hearing-outcomes': {
    name: 'Capture hearing outcomes',
    timeFrame: {
      dueSoon: 24,
      overdue: 48,
    },
  },
  'draft-new-outcome': {
    name: 'Draft and issue outcome letter',
    timeFrame: {
      dueSoon: 97,
      overdue: 144,
    },
  },

  // CHAIRPERSON
  'lt-ih-conduct-hearing-cp': {
    name: 'Conduct ${hearingType} hearing',
  },
  'lt-ih-submit-ruling-cp': {
    name: 'Submit ruling',
    timeFrame: {
      dueSoon: 97,
      overdue: 144,
    },
  },

  // HC APPROVER
  'lt-ih-conduct-hearing-hc': {
    name: 'Conduct ${hearingType} hearing',
  },
  'lt-ih-submit-ruling-hc': {
    name: 'Submit ruling',
    timeFrame: {
      dueSoon: 97,
      overdue: 144,
    },
  },

  // APPEAL HEARING
  'lt-ih-initiate-and-prepare-for-hearing-appeal': {
    name: 'Initiate and prepare for appeal hearing',
    timeFrame: {
      dueSoon: 60,
      overdue: 48,
    },
    timeFrameWaitsForDueDate: true,
  },
  'lt-ih-initiate-and-prepare-for-hearing-er-appeal': {
    name: 'Initiate and prepare for appeal hearing',
    timeFrame: {
      dueSoon: 60,
      overdue: 48,
    },
    timeFrameWaitsForDueDate: true,
  },
  'draft-new-notice-of-hearing-appeal': {
    name: 'Draft and issue notice of appeal hearing',
    timeFrame: {
      dueSoon: 60,
      overdue: 48,
    },
    timeFrameWaitsForDueDate: true,
  },
  'draft-new-notice-of-hearing-appeal-er': {
    name: 'Draft and issue notice of appeal hearing',
    timeFrame: {
      dueSoon: 60,
      overdue: 48,
    },
    timeFrameWaitsForDueDate: true,
  },
  'lt-ih-manage-hearing-appeal': {
    name: 'Manage appeal hearing',
  },
  'lt-ih-manage-hearing-er-appeal': {
    name: 'Manage appeal hearing',
  },
  'lt-ih-capture-hearing-outcomes-appeal': {
    name: 'Capture appeal hearing outcomes',
    timeFrame: {
      dueSoon: 24,
      overdue: 48,
    },
  },
  'draft-new-outcome-appeal': {
    name: 'Draft and issue outcome letter',
    timeFrame: {
      dueSoon: 97,
      overdue: 144,
    },
  },

  // APPEAL CHAIRPERSON
  'lt-ih-conduct-hearing-cp-appeal': {
    name: 'Conduct appeal hearing',
  },
  'lt-ih-submit-ruling-cp-appeal': {
    name: 'Submit ruling',
    timeFrame: {
      dueSoon: 97,
      overdue: 144,
    },
  },

  // APPEAL HC APPROVER
  'lt-ih-conduct-hearing-hc-appeal': {
    name: 'Conduct appeal hearing',
  },
  'lt-ih-submit-ruling-hc-appeal': {
    name: 'Submit ruling',
    timeFrame: {
      dueSoon: 97,
      overdue: 144,
    },
  },

  // POOR PERFORMANCE
  'lt-conduct-and-document-counselling-discussions': {
    name: 'Conduct and document counselling discussions',
  },
  'lt-conduct-and-document-pip-progress-meetings': {
    name: 'Conduct and document PIP progress meetings',
  },
  'lt-conduct-and-document-extended-pip-progress-meetings': {
    name: 'Conduct and document extended PIP progress meetings',
  },

  'draft-new-pip': {
    name: 'Draft and issue PIP',
  },
  'draft-new-agreed-demotion': {
    name: 'Draft and issue Demotion Letter',
    timeFrame: {
      dueSoon: 97,
      overdue: 144,
    },
  },
};
