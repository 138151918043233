import { Table as BootStrapTable } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import { TemplateType, UpdateEmployeeInput } from '../../API';
import { list } from '../../utils/graphql-utils';
import { UserContext, UserContextProps } from '../../App';
import './emailAuditLog.styles.scss';
import moment from 'moment';
import { EmailType } from '../../utils/email-utils';
import { AuditLog, User } from '../../models';
import { toTitleCase } from '../../utils/string-utils';
import Loader from '../loader/Loader';
import { FlowableHistoricTask } from '../../utils/flowable/flowable-types';
import {
  queryHistoricProcessInstancesByMasterProcessInstanceIdAndEmailType,
  queryHistoricTaskInstances,
  queryHistoricTaskInstancesByLambdaReferenceId,
} from '../../utils/flowable/flowable-utils';
import { listUsersBasic } from '../../graphql-custom/custom-queries';
import { WorkflowComponentProps } from '../../screens/WorkflowContainer/workflow-utils';
import { EmailPreviewModalv3 } from '../EmailPreviewModal/EmailPreviewModalv3';

interface EmailTrackerTableProps extends WorkflowComponentProps {
  templateType: TemplateType;
  showSystemEmailOptions: boolean;
}

interface EmailTask {
  historicTask: FlowableHistoricTask;
  emailType: EmailType | null;
  activeTaskDefinitionKeys: string[];
  processInstanceId: string;
  user?: User;
  isLatestTask: boolean;
}

export interface EmailTrackerTableState {
  employees: UpdateEmployeeInput[];
  employee: UpdateEmployeeInput | null;
  emailTasks: EmailTask[];
  isDownloading: boolean;
  facilitiesEmailAddress: string | null;
  itEmailAddress: string | null;
  auditLogs: AuditLog[];
  isLoading: boolean;
}
interface MailConfig {
  type: EmailType;
  buttonText: string;
  disabled: boolean;
}

export const EmailTrackerTable: React.FC<EmailTrackerTableProps> = (props: EmailTrackerTableProps) => {
  const columnHeadings = ['Date', 'Action', 'Actioned By', ' ', 'Status', ' '];
  const [state, setState] = useState<EmailTrackerTableState>({
    employees: [],
    employee: null,
    emailTasks: [],
    isDownloading: false,
    itEmailAddress: null,
    facilitiesEmailAddress: null,
    auditLogs: [],
    isLoading: false,
  });
  const [emailSentTrackerState, setEmailSentTracker] = useState<boolean>(false);
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const emailTypesByTemplateType: { [key: string]: MailConfig[] } = {
    SUSPENSION_PENDING_INVESTIGATION_LETTER: [
      {
        type: EmailType.NOTIFY_FACILITIES_SUSPENSION_PENDING,
        buttonText: 'Notify Facilities',
        disabled: !props.data.organisation?.facilitiesEmail,
      },
      {
        type: EmailType.NOTIFY_IT_SUSPENSION_PENDING,
        buttonText: 'Notify IT',
        disabled: !props.data.organisation?.itEmail,
      },
    ],
  };

  const renderButtonsForNotifyFacilitiesAndIT = (): JSX.Element => {
    const emailConfigs: MailConfig[] | undefined = emailTypesByTemplateType[props.templateType];
    if (emailConfigs?.length) {
      const buttons = emailConfigs.map((e, index) => (
        <EmailPreviewModalv3
          key={index}
          buttonText={e.buttonText}
          disabled={e.disabled}
          formValues={{}}
          emailType={e.type}
          masterProcessInstanceId={props.data.masterProcessInstanceId}
          processInstanceId={props.data.processInstanceId}
          currentUserId={currentUser?.id}
          callback={() => {
            setEmailSentTracker(!emailSentTrackerState);
          }}
          getFlowableVariables={() => {
            return [];
          }}
        />
      ));

      return (
        <>
          <div className="d-flex justify-content-center align-items-center p-1">{buttons}</div>
        </>
      );
    } else {
      return <div />;
    }
  };

  const getUsers = (): Promise<User[]> => {
    const userVariables = {
      limit: 250,
      filter: {
        organisationId: { eq: props.data.caseData.organisationId },
      },
    };
    return new Promise<User[]>((resolve, reject) => {
      list(listUsersBasic, userVariables)
        .then((res: any) => {
          resolve(res.data.listUsers.items);
        })
        .catch(reject);
    });
  };

  const loadEmailProcesses = async (masterProcessInstanceId: string, emailTypes: EmailType[]): Promise<void> => {
    const processInstancePromises = emailTypes.map(e =>
      queryHistoricProcessInstancesByMasterProcessInstanceIdAndEmailType(masterProcessInstanceId, e),
    );
    const processInstanceArrays = await Promise.all(processInstancePromises);
    const processInstances = processInstanceArrays.reduce(function(a, b) {
      return a.concat(b);
    }, []);

    const promises: Promise<unknown>[] = processInstances.map(p => {
      const lambdaReferenceIdVar = p.variables.find(v => v.name === 'lambdaReferenceId');
      if (lambdaReferenceIdVar?.value && typeof lambdaReferenceIdVar.value === 'string') {
        return Promise.all([
          queryHistoricTaskInstancesByLambdaReferenceId(lambdaReferenceIdVar.value),
          queryHistoricTaskInstances(p.id),
        ]);
      } else {
        throw new Error('process instance with id ' + p.id + 'missing var lambdaReferenceId');
      }
    });
    promises.push(getUsers());
    const res: unknown[] = await Promise.all(promises);
    const users = res.pop() as User[];
    const emailTasks: EmailTask[] = [];
    res.forEach((r: unknown) => {
      const emailSendTasks = (r as unknown[])[0] as FlowableHistoricTask[];
      const otherTasks = (r as unknown[])[1] as FlowableHistoricTask[];

      const completedTasks = otherTasks.filter(t => !!t.endTime);
      const activeTasks = otherTasks.filter(t => !t.endTime);

      completedTasks.concat(emailSendTasks).forEach(t => {
        const matchingProcessInstance = processInstances.find(p => {
          const processLambdaRefVar = p.variables.find(v => v.name === 'lambdaReferenceId');
          const taskLambdaRefVar = t.variables.find(v => v.name === 'lambdaReferenceId');
          return !!(
            processLambdaRefVar?.value &&
            taskLambdaRefVar?.value &&
            processLambdaRefVar.value === taskLambdaRefVar.value
          );
        });

        const emailTypeVar = matchingProcessInstance?.variables.find(v => v.name === 'emailType');
        const emailType: EmailType | null = emailTypeVar ? (emailTypeVar.value as EmailType) : null;

        if (t.endTime) {
          const user: User | undefined = users.find((item: User) => item.id === t.assignee);
          const activeTaskDefinitionKeys = activeTasks.map((item: FlowableHistoricTask) =>
            item.taskDefinitionKey ? item.taskDefinitionKey : '',
          );
          emailTasks.push({
            emailType: emailType ? emailType : null,
            processInstanceId: t.processInstanceId,
            historicTask: t,
            activeTaskDefinitionKeys: activeTaskDefinitionKeys,
            user: user,
            isLatestTask: false,
          });
        }
      });
    });
    const sortedEmailTasks: EmailTask[] = emailTasks.sort(
      (a, b) => moment(a.historicTask.endTime).unix() - moment(b.historicTask.endTime).unix(),
    );

    if (sortedEmailTasks.length) {
      sortedEmailTasks[sortedEmailTasks.length - 1].isLatestTask = true;
    }

    setState((oldState: EmailTrackerTableState) => ({
      ...oldState,
      isLoading: false,
      emailTasks: sortedEmailTasks,
    }));
  };

  useEffect(() => {
    if (currentUser?.organisationId) {
      const emailConfigs: MailConfig[] | undefined = emailTypesByTemplateType[props.templateType];
      if (emailConfigs?.length) {
        const emailTypes = emailConfigs.map(e => e.type);
        loadEmailProcesses(props.data.masterProcessInstanceId, emailTypes);
      }
    }
  }, [emailSentTrackerState]);

  const getTaskDescription = (task: FlowableHistoricTask, emailType: EmailType | null): string => {
    const taskDefinitionKey = task.taskDefinitionKey;
    if (taskDefinitionKey) {
      if (taskDefinitionKey === 'generic-email-task') {
        if (emailType?.includes('FACILITIES')) {
          return 'Email sent to Facilities';
        } else if (emailType?.includes('PAYROLL')) {
          return 'Email sent to Payroll';
        } else if (emailType?.includes('CCMA')) {
          return 'Email sent to CCMA';
        } else if (emailType?.includes('IT')) {
          return 'Email sent to IT';
        } else {
          return '';
        }
      } else if (taskDefinitionKey === 'confirm-receipt') {
        return 'Receipt Confirmed';
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const getStatus = (task: FlowableHistoricTask, emailType: EmailType | null): string => {
    const taskDefinitionKey = task.taskDefinitionKey;
    if (taskDefinitionKey) {
      if (taskDefinitionKey === 'confirm-receipt') {
        if (emailType && emailType.includes('FACILITIES')) {
          return 'Facilities confirmed receipt';
        } else if (emailType && emailType.includes('PAYROLL')) {
          return 'Payroll confirmed receipt';
        } else if (emailType && emailType.includes('CCMA')) {
          return 'CCMA confirmed receipt';
        } else if (emailType && emailType.includes('IT')) {
          return 'IT confirmed receipt';
        } else return '';
        return 'Receipt Confirmed';
      } else if (taskDefinitionKey === 'generic-email-task') {
        return 'Awaiting confirmation of receipt';
      } else return '';
    } else {
      return '';
    }
  };

  const renderRow = (emailTask: EmailTask, key: number): JSX.Element => {
    return (
      <tr key={key}>
        <td key="1" className="system-email-table-row-item">
          {moment(emailTask.historicTask.endTime).format('DD/MM/YYYY')}
        </td>
        <td key="2" className="system-email-table-row-item">
          {getTaskDescription(emailTask.historicTask, emailTask.emailType)}
        </td>
        <td key="3" className="system-email-table-row-item">
          {emailTask.user
            ? toTitleCase(emailTask.user.firstName + ' ' + emailTask.user.lastName, ' ')
            : 'External User'}
        </td>
        <td key="5" className="system-email-table-row-item">
          <div
            className={
              'document-status-circle' +
              ' ' +
              (emailTask.historicTask.taskDefinitionKey === 'confirm-receipt'
                ? 'document-status-circle-approved'
                : 'document-status-circle-requires-revision')
            }
          />
        </td>
        <td key="4">{getStatus(emailTask.historicTask, emailTask.emailType)}</td>
      </tr>
    );
  };

  return state.emailTasks.length ? (
    <>
      <h4 className="text-primary">System Emails</h4>
      {!state.isLoading ? (
        <BootStrapTable>
          <thead>
            <tr>
              {columnHeadings.map((heading, index) => (
                <th className="text-blue font-weight-bold" key={index}>
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{state.emailTasks.map((item: EmailTask, index: number) => renderRow(item, index))}</tbody>
        </BootStrapTable>
      ) : (
        <div className="d-flex justify-content-center align-items-center">{state.isLoading && <Loader />}</div>
      )}
      <>{renderButtonsForNotifyFacilitiesAndIT()}</>
    </>
  ) : (
    <>{renderButtonsForNotifyFacilitiesAndIT()}</>
  );
};
