import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Card, Col, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import { CreateJobGradeInput, ListJobGradesQuery, UpdateJobGradeInput,AuditLogEventType } from '../../../API';
import { UserContext, UserContextProps } from '../../../App';
import AddEmployeeButton from '../../../components/AddEmployeeButton/AddEmployeeButton';
import ButtonWithIcons from '../../../components/buttons/ButtonWIthIcons.component';
import { CloseIcon, PlusIcon } from '../../../components/icon/Icon.component';
import Loader from '../../../components/loader/Loader';
import TopBarComponent from '../../../components/topBar/TopBar.component';
import { createJobGrade, updateJobGrade } from '../../../graphql/mutations';
import { listJobGrades } from '../../../graphql/queries';
import { Employee, JobGrade } from '../../../models';
import { list, listActiveEmployeesByOrganisationId, mutate } from '../../../utils/graphql-utils';
import { useErrorHandler } from '../../../utils/notification-utils';
import DynamicTable, { Column, Data } from '../../Employee/ViewEmployees/DynamicTable';
import { GraphQLResult } from '@aws-amplify/api/lib/types';
import { notEmpty } from '../../../utils/typescript-utils';
import { createLog } from '../../../utils/audit-log-utils';

enum ModalAction {
  EDIT = 'Edit',
  DELETE = 'Delete',
  ADD = 'Add',
}

interface JobGradeModalState {
  open: boolean;
  action: ModalAction | null;
  jobGrade: JobGrade | null;
}

interface EmployeeWithJobGrade {
  id: string;
  deleted?: boolean | null;
  name: string;
  employeeCount: number;
}

const JobGrades: React.FC = () => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const [jobGradeModal, setJobGradeModal] = useState<JobGradeModalState>({
    open: false,
    action: null,
    jobGrade: null,
  });
  const [jobGradeName, setJobGradeName] = useState('');
  const [jobGrades] = useState<JobGrade[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [tableData, setTableData] = useState<EmployeeWithJobGrade[]>([]);
  const [loading, setLoading] = useState(true);

  const handleError = useErrorHandler();

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setJobGradeName(event.target.value);
  };

  const getName = (modalObject: JobGradeModalState, inputObject:  CreateJobGradeInput | Partial<UpdateJobGradeInput>) => {
    if (inputObject.name) {
      return inputObject.name
    } else if (
      modalObject.jobGrade?.name
    ) {
      return modalObject.jobGrade?.name
    }

  }

  const mutateJobGrade = async (jobGrade: CreateJobGradeInput | Partial<UpdateJobGradeInput>,  modalAction: ModalAction | null,): Promise<any> => {
    const mutation = jobGrade.id ? updateJobGrade : createJobGrade;
    setLoading(true);
    await mutate(mutation, jobGrade)
      .then(() =>{ setLoading(false);
        if (currentUser) { createLog(currentUser, AuditLogEventType.JOB_GRADE_MODIFIED, null, null, `${modalAction} ${getName(jobGradeModal, jobGrade)} job grade`).catch(error => handleError(error)); }

      })
      .catch(error => handleError(error));
  };

  const getData = async (): Promise<void> => {
    if (currentUser?.organisationId) {
      setLoading(true);
      const jobGradeVariables = {
        filter: {
          organisationID: { eq: currentUser.organisationId },
          deleted: { ne: true },
        },
        limit: 500,
      };
      await Promise.all<Employee[], GraphQLResult<ListJobGradesQuery>>([
        listActiveEmployeesByOrganisationId(currentUser.organisationId),
        list(listJobGrades, jobGradeVariables),
      ])
        .then(res => {
          const jobGrades = res[1].data?.listJobGrades?.items;
          if (jobGrades) {
            setEmployees(res[0]);
            const jobGradesWithEmployeeCounts: EmployeeWithJobGrade[] = jobGrades.filter(notEmpty).map(jobGrade => {
              const employeeCount = employees.filter(employee => employee.jobGradeID === jobGrade.id).length;
              return {
                id: jobGrade.id,
                deleted: jobGrade.deleted,
                name: jobGrade.name,
                employeeCount: employeeCount,
              };
            });
            setTableData(
              jobGradesWithEmployeeCounts.sort((x, y) => {
                const a = x.name.toUpperCase(),
                  b = y.name.toUpperCase();
                return a === b ? 0 : a > b ? 1 : -1;
              }),
            );
            setLoading(false);
          } else {
            setLoading(false);
            handleError(new Error('Could not retrieve Job Grades or employees.'));
          }
        })
        .catch((error: Error) => {
          setLoading(false);
          handleError(error);
        });
    } else {
      setLoading(false);
      handleError(new Error('Could not retrieve Job Grades or employees.'));
    }
  };

  const reset = (): void => {
    setJobGradeName('');
    setJobGradeModal({ open: false, action: null, jobGrade: null });
    getData();
  };

  const executeModalAction = (
    modalAction: ModalAction | null,
    jobGrade: JobGrade | null,
    jobGradeNameInput: string,
  ): void => {
    if (modalAction === ModalAction.ADD) {
      if (currentUser && jobGradeNameInput && currentUser.organisationId) {
        const newJobGrade: CreateJobGradeInput = {
          organisationID: currentUser.organisationId,
          name: jobGradeNameInput,
          deleted: false,
        };
        mutateJobGrade(newJobGrade, modalAction).finally(() => reset());
      }
    } else if (modalAction === ModalAction.EDIT && !!jobGrade?.id) {
      const updatedJobGrade: UpdateJobGradeInput = { id: jobGrade.id, name: jobGradeNameInput };
      mutateJobGrade(updatedJobGrade, modalAction).finally(() => reset());
    } else if (modalAction === ModalAction.DELETE && !!jobGrade?.id) {
      const deletedJobGrade: UpdateJobGradeInput = { id: jobGrade.id, deleted: true };
      mutateJobGrade(deletedJobGrade, modalAction).finally(() => reset());
    } else handleError(new Error('Invalid jobGrade or action.'));
  };

  useEffect(() => {
    getData()
      .then(() => setLoading(false))
      .catch(error => handleError(error));
  }, [JSON.stringify(jobGrades), JSON.stringify(employees)]);

  const columns: Column[] = [
    { label: 'Job Grade', key: 'name' },
    { label: 'Employee Count', key: 'employeeCount' },
    {
      label: 'actions',
      type: 'action',
      actions: [
        {
          label: 'edit',
          func: (jobGrade: Data): void => {
            setJobGradeName(jobGrade.name ? jobGrade.name : '');
            setJobGradeModal({ open: true, action: ModalAction.EDIT, jobGrade: jobGrade as JobGrade });
          },
        },
        {
          label: 'delete',
          func: (jobGrade: Data): void => {
            setJobGradeName(jobGrade.name ? jobGrade.name : '');
            setJobGradeModal({ open: true, action: ModalAction.DELETE, jobGrade: jobGrade as JobGrade });
          },
        },
      ],
    },
  ];

  return (
    <>
      <TopBarComponent title={'Job Grades'} subTitle={'View Job Grades'}>
      <AddEmployeeButton />
        <ButtonWithIcons
          title={'Job Grade'}
          handleClick={(): void => setJobGradeModal({ open: true, action: ModalAction.ADD, jobGrade: null })}
          leftIcon={<PlusIcon />}
          buttonType={'btn-bd-purple'}
        />
      </TopBarComponent>
      <div className="content">
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <>
            <Modal className="p-0" size="md" isOpen={jobGradeModal.open} centered>
              <ModalBody className="p-0 rounded-0">
                <div className="d-flex justify-content-between px-3 py-2 document-modal-header">
                  <div className="text-capitalize ml-auto mr-auto font-weight-light">
                    {jobGradeModal.action} Job Grade
                  </div>
                  <div onClick={reset} className="align-self-center">
                    <CloseIcon fillColour={'white'} />
                  </div>
                </div>
                <div className="px-3 py-3">
                  <Row>
                    <Col>
                      <FormGroup>
                        {jobGradeModal.action === 'Delete' ? (
                          <div className="d-flex flex-column align-items-center">
                            <span className="text-dark">Are you sure you want to remove this job grade?</span>
                            <span className="text-danger">
                              {jobGradeModal.jobGrade?.name ? jobGradeModal.jobGrade.name : ''}
                            </span>
                          </div>
                        ) : (
                          <>
                            <Label for="JobGrade name" className="text-default text-capitalize">
                              Job Grade Name
                            </Label>
                            <Input type={'text'} onChange={handleChange} value={jobGradeName} />
                          </>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex ml-auto mr-auto justify-content-center">
                  <ButtonWithIcons
                    title={`${jobGradeModal.action === 'Edit' ? 'Confirm' : `${jobGradeModal.action}`}`}
                    handleClick={(): void =>
                      executeModalAction(jobGradeModal.action, jobGradeModal.jobGrade, jobGradeName)
                    }
                  />
                </div>
              </ModalBody>
            </Modal>
            <h4 className="text-default text-capitalize font-weight-bold mt-3 ml-3">Company Information</h4>
            <Row>
              <Col className="mb-5" md="12">
                <Card>
                  <CardBody>
                    <DynamicTable columns={columns} data={tableData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default JobGrades;