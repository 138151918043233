import React from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { FormikValues, useFormikContext } from 'formik';
import UploaderContainer from '../../../../../components/Uploader/UploaderContainer';
import { WorkflowComponentProps } from '../../../../WorkflowContainer/workflow-utils';

export const AppealHearingExternalRepresentationOutcomeForm: React.FC<WorkflowComponentProps> = (
  props: WorkflowComponentProps,
) => {
  const { values } = useFormikContext<FormikValues>();
  const { setFieldValue } = useFormikContext();

  return (
    <Form>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="exampleFile" className="text-default text-capitalize">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Upload Chairperson's Ruling*
            </Label>
            <UploaderContainer
              fieldName={'appeal_hearing_externalRepresentationApplicationRulingDocuments'}
              path={`cases/${props.data.masterProcessInstanceId}`}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <Label className="text-default d-block mt-2">Chairperson's Ruling</Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.appeal_hearing_allowExternalRepresentation}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_allowExternalRepresentation', true);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-capitalize">Allow</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.appeal_hearing_allowExternalRepresentation}
                    onChange={(): void => {
                      setFieldValue('appeal_hearing_allowExternalRepresentation', false);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-capitalize">Disallow</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
