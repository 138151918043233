import { Button, Table as BootStrapTable } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { get, listActiveEmployeesByOrganisationId } from '../../utils/graphql-utils';
import { getOrganisation, getTemplateDocumentVersion } from '../../graphql/queries';
import { DocumentDeliveryMethod } from './IssueLetterModal';
import './DocumentVersionControlTable.styles.scss';
import moment from 'moment';
import {
  queryHistoricProcessInstancesForDocumentFlowsonDocumentCreationTaskKey,
  queryHistoricTaskInstances,
  queryHistoricTaskInstancesByDocumentIdAndProcessInstanceId,
} from '../../utils/flowable/flowable-utils';
import { useErrorHandler } from '../../utils/notification-utils';
import { AuditLog, Employee, TemplateDocumentVersion, User } from '../../models';
import { toTitleCase } from '../../utils/string-utils';
import { getFileURL } from '../../utils/storage-utils';
import { getUserDetails } from '../../graphql-custom/custom-queries';
import Loader from '../loader/Loader';
import { FlowableHistoricTask, FlowableVariable } from '../../utils/flowable/flowable-types';

interface DocumentTableProps {
  processInstanceId: string;
  organisationId: string;
  employeeId: string;
  documentCreationTaskKey: string;
}

interface DocumentEvent {
  event: AuditLog;
  document: TemplateDocumentVersion;
  isLatest: boolean;
  canEdit: boolean;
  documentTaskKeys: (string | null)[];
  deliveryMethod: string | undefined;
}

export interface DocumentTask {
  historicTask: FlowableHistoricTask;
  activeTaskDefinitionKeys: string[];
  doc: TemplateDocumentVersion;
  processInstanceId: string;
  user?: User;
  isLatestTask: boolean;
}

export interface DocumentVersionControlTableState {
  employees: Employee[];
  employee: Employee | null;
  documentEvents: DocumentEvent[];
  documentTasks: DocumentTask[];
  isDownloading: boolean;
  isLoading: boolean;
}

export const DocumentVersionControlTableHistoric: React.FC<DocumentTableProps> = (props: DocumentTableProps) => {
  const columnHeadings = ['Date', 'Version', 'Action', 'Actioned By', 'Status'];
  const [state, setState] = useState<DocumentVersionControlTableState>({
    employees: [],
    employee: null,
    documentEvents: [],
    documentTasks: [],
    isDownloading: false,
    isLoading: false,
  });
  const handleError = useErrorHandler();

  const loadEmployeesAndConfig = (organisationId: string) => {
    Promise.all([listActiveEmployeesByOrganisationId(organisationId), get(getOrganisation, organisationId)])
      .then((res: any[]) => {
        const employees: Employee[] = res[0];
        const organisation = (res[1].data as any)?.getOrganisation;
        const employee = employees.find((item: Employee) => item.id === props.employeeId);
        setState((oldState: DocumentVersionControlTableState) => ({
          ...oldState,
          facilitiesEmailAddress: organisation?.facilitiesEmail,
          itEmailAddress: organisation?.itEmail,
          employees: employees,
          employee: employee ? employee : null,
        }));
      })
      .catch(error => handleError(error));
  };

  const getUserById = async (userId: string) => {
    const userData: any = await get(getUserDetails, userId);
    return userData.data.getUser;
  };

  const loadDocuments2 = async () => {
    if (!(props.processInstanceId && props.documentCreationTaskKey && props.organisationId)) {
      throw new Error('missing data');
    }

    const rawProcessInstances = await queryHistoricProcessInstancesForDocumentFlowsonDocumentCreationTaskKey(
      props.processInstanceId,
      props.documentCreationTaskKey,
    );

    const processInstances = rawProcessInstances.filter(p => p.processDefinitionName?.includes('document-flow'));

    const documentTasksPromises: Promise<unknown[]>[] = processInstances.map(p => {
      const documentIdVar = p.variables.find(v => v.name === 'documentId');
      if (documentIdVar?.value && typeof documentIdVar.value === 'string') {
        return Promise.all([
          get(getTemplateDocumentVersion, documentIdVar.value),
          queryHistoricTaskInstances(p.id),
          queryHistoricTaskInstancesByDocumentIdAndProcessInstanceId(documentIdVar.value, props.processInstanceId),
        ]);
      } else {
        throw new Error('process instance with id ' + p.id + 'missing var documentId');
      }
    });

    const promises: Promise<unknown>[] = documentTasksPromises;
    const res: unknown[] = await Promise.all(promises);

    const documentTasks: DocumentTask[] = [];
    for (const r of res) {
      const doc = (r as unknown[])[0] as { data: { getTemplateDocumentVersion: TemplateDocumentVersion } };
      const tasks = (r as unknown[])[1] as FlowableHistoricTask[];
      const documentCreationTasks = (r as unknown[])[2] as FlowableHistoricTask[];
      const userIds = new Set(
        tasks
          .concat(documentCreationTasks)
          .filter(t => !!t.assignee)
          .map(t => t.assignee),
      ) as Set<string>;

      const userPromises = Array.from(userIds).map(uid => getUserById(uid));
      const usersArrays = await Promise.all(userPromises);
      const usersMap: Record<string, User> = {};
      // @ts-ignore
      usersArrays.flat().forEach(u => (usersMap[u.id] = u));
      console.log('usersArrays.flat(): ', usersArrays.flat());

      documentCreationTasks.forEach(dt => {
        const matchingDocumentProcessInstance = processInstances.find(p => {
          const processDocumentIdVar = p.variables.find(v => v.name === 'documentId');
          const taskDocumentIdVar = dt.variables.find(v => v.name === 'documentId');
          return !!(
            processDocumentIdVar?.value &&
            taskDocumentIdVar?.value &&
            processDocumentIdVar.value === taskDocumentIdVar.value
          );
        });
        if (!matchingDocumentProcessInstance) {
          throw new Error('no matchingDocumentProcessInstance found for document creation task');
        }
        dt.processInstanceId = matchingDocumentProcessInstance.id;
      });

      const completedTasks = tasks.filter(t => !!t.endTime && t.deleteReason === null);
      const activeTasks = tasks.filter(t => !t.endTime);

      completedTasks.concat(documentCreationTasks).forEach(t => {
        if (t.endTime) {
          let user;
          if (t.assignee) {
            user = usersMap[t.assignee];
          }
          const activeTaskDefinitionKeys = activeTasks.map((item: FlowableHistoricTask) =>
            item.taskDefinitionKey ? item.taskDefinitionKey : '',
          );
          documentTasks.push({
            processInstanceId: t.processInstanceId,
            doc: doc.data.getTemplateDocumentVersion,
            historicTask: t,
            activeTaskDefinitionKeys: activeTaskDefinitionKeys,
            user: user,
            isLatestTask: false,
          });
        }
      });
    }

    const sortedDocumentTasks: DocumentTask[] = documentTasks.sort(
      (a, b) => moment(a.historicTask.endTime).unix() - moment(b.historicTask.endTime).unix(),
    );

    if (sortedDocumentTasks.length) {
      sortedDocumentTasks[sortedDocumentTasks.length - 1].isLatestTask = true;
    }

    setState((oldState: DocumentVersionControlTableState) => ({
      ...oldState,
      isLoading: false,
      documentTasks: sortedDocumentTasks,
    }));
  };

  const downloadDocumentV2 = async (bucketPath: string): Promise<void> => {
    await getFileURL(bucketPath)
      .then(async url => {
        window.open(url as string, '_self');
      })
      .catch(error => handleError(error));
  };

  const taskDescriptions: Record<string, string> = {
    'upload-signed-document-from-nominee': 'Document Uploaded',
    'upload-signed-document-from-employee': 'Document Uploaded',
    'approve-document-author': 'Approved',
    'approve-document-approver': 'Approved',
    'request-approval': 'Approval Requested',
    'sign-document': 'Digitally signed by employee',
    'confirm-receipt': 'Receipt confirmed by Nominee',
  };

  const getTaskDescription = (task: FlowableHistoricTask): string => {
    const taskDefinitionKey = task.taskDefinitionKey;
    if (taskDefinitionKey) {
      if (taskDescriptions[taskDefinitionKey]) {
        return taskDescriptions[taskDefinitionKey];
      } else if (task.taskDefinitionKey === 'generic-document-creation-task') {
        return 'New Version';
      } else if (taskDefinitionKey === 'issue-document') {
        const deliveryMethod: FlowableVariable | undefined = task.variables.find(
          (item: FlowableVariable) => item.name === 'documentDeliveryMethod',
        );
        if (deliveryMethod) {
          if (deliveryMethod.value === DocumentDeliveryMethod.DIRECT_EMAIL) {
            return 'Emailed to Employee';
          } else if (deliveryMethod.value === DocumentDeliveryMethod.SIGN_DIGITALLY) {
            return 'Awaiting signature';
          } else if (deliveryMethod.value === DocumentDeliveryMethod.NOMINATE_EMPLOYEE_TO_DELIVER) {
            return 'Emailed to Nominee';
          } else if (deliveryMethod.value === DocumentDeliveryMethod.PRINT_AND_DELIVER) {
            return 'Downloaded';
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else return '';
    } else return '';
  };

  useEffect(() => {
    loadEmployeesAndConfig(props.organisationId);
    loadDocuments2();
  }, []);

  const getStatusFlowable = (task: FlowableHistoricTask): string => {
    if (task.taskDefinitionKey === 'generic-document-creation-task') {
      return 'New version';
    } else if (task.taskDefinitionKey === 'request-approval') {
      return 'Approval requested';
    } else if (
      task.taskDefinitionKey === 'approve-document-approver' ||
      task.taskDefinitionKey === 'approve-document-author'
    ) {
      return 'Approved';
    } else if (task.taskDefinitionKey === 'issue-document') {
      const deliveryMethod: FlowableVariable | undefined = task.variables.find(
        (item: FlowableVariable) => item.name === 'documentDeliveryMethod',
      );
      if (deliveryMethod) {
        if (
          deliveryMethod.value === DocumentDeliveryMethod.DIRECT_EMAIL ||
          deliveryMethod.value === DocumentDeliveryMethod.SIGN_DIGITALLY
        ) {
          return 'Awaiting Digital Signature';
        } else if (deliveryMethod.value === DocumentDeliveryMethod.NOMINATE_EMPLOYEE_TO_DELIVER) {
          return 'Emailed to Nominee for Delivery';
        } else if (deliveryMethod.value === DocumentDeliveryMethod.PRINT_AND_DELIVER) {
          return 'Awaiting Upload';
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else if (
      task.taskDefinitionKey === 'upload-signed-document-from-employee' ||
      task.taskDefinitionKey === 'upload-signed-document-from-nominee'
    ) {
      return 'Uploaded';
    } else if (task.taskDefinitionKey === 'sign-document') {
      return 'Digitally signed';
    } else if (task.taskDefinitionKey === 'confirm-receipt') {
      return 'Received by nominee';
    } else {
      return '';
    }
  };

  const renderRow = (documentTask: DocumentTask, key: number): JSX.Element => {
    return (
      <tr key={key}>
        <td key="1">{moment(documentTask.historicTask.endTime).format('DD/MM/YYYY')}</td>
        <td key="2">{documentTask.doc.version}</td>
        <td key="3">{getTaskDescription(documentTask.historicTask)}</td>
        <td key="4">
          {documentTask.user
            ? toTitleCase(documentTask.user.firstName + ' ' + documentTask.user.lastName, ' ')
            : 'External User'}
        </td>
        <td key="5">{getStatusFlowable(documentTask.historicTask)}</td>
        <td key="6">
          <div
            className={
              'document-status-circle' +
              ' ' +
              (documentTask.historicTask.taskDefinitionKey === 'approve-document-author' ||
              documentTask.historicTask.taskDefinitionKey === 'approve-document-approver' ||
              documentTask.historicTask.taskDefinitionKey === 'sign-document'
                ? 'document-status-circle-approved'
                : 'document-status-circle-requires-revision')
            }
          />
        </td>
        <td key="7" style={{ minWidth: '135px' }}>
          {documentTask.doc.bucketPath && (
            <Button
              className="view-document-button"
              style={{ minWidth: '30px', maxWidth: '30px', borderRadius: '2px' }}
              onClick={(): void => {
                const path = documentTask.doc.uploadedFileBucketPath ?? documentTask.doc.bucketPath;
                if (path) {
                  downloadDocumentV2(path);
                }
              }}
            >
              <i className="fa fa-download"></i>
            </Button>
          )}
        </td>
      </tr>
    );
  };

  return state.documentTasks.length ? (
    <>
      <h4 className="text-primary">Document Version Control</h4>
      {!state.isLoading ? (
        <BootStrapTable>
          <thead>
            <tr>
              {columnHeadings.map((heading, index) => (
                <th className="text-blue font-weight-bold" key={index}>
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{state.documentTasks.map((item: DocumentTask, index: number) => renderRow(item, index))}</tbody>
        </BootStrapTable>
      ) : (
        <div className="d-flex justify-content-center align-items-center">{state.isLoading && <Loader />}</div>
      )}
    </>
  ) : (
    <>
      <span>Error on document load</span>
    </>
  );
};
