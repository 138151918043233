import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik';
import React, { ReactElement } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { WorkFlowFooter } from '../../../../components/workflowFooter/WorkFlowFooter';
import FormField from '../../../../forms/fields/FormField.component';
import { ConfirmDisciplinaryDecisionSchema } from '../../../../forms/ValidationSchema/DiscussionValidationSchema';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../WorkflowContainer/workflow-utils';

const ConfirmDisciplinaryDecision: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { flowableFunctions } = props.data;

  const fields = {
    disciplinaryDiscussionDecision: 'string',
    disciplinaryDecisionReason: 'string',
  };

  const initialValues: FormikValues = getFormDataFromCaseData(fields, props.data.caseData);

  const submitValues = (values: FormikValues): void => {
    const closeCase: boolean = values.disciplinaryDiscussionDecision === 'NO FURTHER ACTION REQUIRED';
    const formValues = { ...values };
    flowableFunctions.onNext(filterFormData(formValues, fields), closeCase);
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Confirm Disciplinary Decision</h4>
      <Formik
        initialValues={initialValues}
        onSubmit={submitValues}
        enableReinitialize
        validationSchema={ConfirmDisciplinaryDecisionSchema}
      >
        {({ values, setFieldValue, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <div style={{ marginBottom: '8em' }}>
              <Row>
                <Col md={5} sm={4} lg={4}>
                  <FormGroup className="rounded-0">
                    <Label for="effectiveSuspensionDate" className="text-default text-capitalize">
                      Select Disciplinary Decision*
                    </Label>
                    <FormField
                      name="disciplinaryDiscussionDecision"
                      placeholder="Select from list"
                      type={'selectWithLabels'}
                      selectOptionsWithLabels={[
                        { label: 'No further action required', value: 'NO_FURTHER_ACTION_REQUIRED' },
                        { label: 'Verbal Warning', value: 'VERBAL_WARNING' },
                        { label: 'Written Warning', value: 'WRITTEN_WARNING' },
                        { label: 'Final Written Warning', value: 'FINAL_WRITTEN_WARNING' },
                      ]}
                    />
                    <span className="text-danger">
                      <ErrorMessage className="text-danger" name={'disciplinaryDiscussionDecision'} />
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <FormGroup>
                    <Label for="disciplinaryDecisionReason" className="text-default text-capitalize">
                      Reason for decision*
                    </Label>
                    <FormField
                      type={'textarea'}
                      placeholder="Please add motivation for action taken"
                      name="disciplinaryDecisionReason"
                    />
                    <span className="text-danger">
                      <ErrorMessage className="text-danger" name={'disciplinaryDecisionReason'} />
                    </span>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <WorkFlowFooter
              finalPage={values.disciplinaryDiscussionDecision === 'NO FURTHER ACTION REQUIRED'}
              data={props.data}
              onNext={handleSubmit}
              onCancel={() => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default ConfirmDisciplinaryDecision;
