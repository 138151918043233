import { Employee } from '../../models';
import { UserDetails } from '../../App';
import { DocumentJSONContent } from './DocumentEditor';
import { CaseData } from '../WorkflowContainer/workflow-utils';
import moment from 'moment';
import { DATE_FORMAT } from '../../forms/fields/FormField.component';
import { toTitleCase } from '../../utils/string-utils';

export const getEmployeeWorkAddress = (employee: Employee): string | null => {
  if (employee.physicalAddresses && employee.physicalAddresses.length) {
    const address = employee.physicalAddresses[0];
    return address?.lineOne + ' \n' + address?.lineTwo + '\n ' + address?.city + '\n' + address?.postalCode;
  } else return null;
};

export const getAuthorWorkAddress = (user: UserDetails): string | null => {
  if (user.physicalAddresses && user.physicalAddresses.length) {
    const address = user.physicalAddresses[0];
    return address?.lineOne + ' \n' + address?.lineTwo + '\n ' + address?.city + '\n' + address?.postalCode;
  } else return null;
};

export const formatAppealFinding = (str: string): string => {
  switch (str) {
    case 'CONFIRM':
      return 'Confirmed';
    case 'OVERTURN':
      return 'Overturned';
    case 'NOT_APPLICABLE':
      return 'Not Applicable';
    default:
      return '';
  }
};

export const getCommonFieldValuesFromCaseData = (
  caseData: CaseData,
  employee: Employee,
  author?: UserDetails,
): DocumentJSONContent => {
  const employeeWorkAddress = getEmployeeWorkAddress(employee);
  const issuerAddress = author && getAuthorWorkAddress(author);
  return {
    date: {
      label: null,
      type: 'text',
      value: moment().format(DATE_FORMAT),
    },
    employeeFullName: {
      label: 'Employee Name',
      type: 'text',
      value: toTitleCase(employee.firstName, ' ') + ' ' + toTitleCase(employee.lastName, ' '),
    },
    employeeFirstName: {
      label: 'Employee First Name',
      type: 'text',
      value: toTitleCase(employee.firstName, ' '),
    },
    employeeLastName: {
      label: 'Employee Last Name',
      type: 'text',
      value: toTitleCase(employee.lastName, ' '),
    },
    employeeJobTitle: {
      label: '',
      type: 'text',
      value: employee.jobTitle ? toTitleCase(employee.jobTitle.name, ' ') : '',
    },
    authorName: {
      label: 'Signatory',
      type: 'text',
      value:
        author && author.firstName && author.lastName
          ? toTitleCase(author.firstName, ' ') + ' ' + toTitleCase(author.lastName, ' ')
          : '',
    },
    authorNumber: {
      label: '',
      type: 'text',
      value: author && author.employeeNumber ? author.employeeNumber : '',
    },
    authorJobTitle: {
      label: `Signatory's Job Title`,
      type: 'text',
      value: author?.jobTitle ? toTitleCase(author.jobTitle.name, ' ') : '',
    },
    authorDepartment: {
      label: '',
      type: 'text',
      value: author?.departmentName ? author.departmentName : '',
    },
    authorAddress: {
      label: '',
      type: 'text',
      value: issuerAddress ? issuerAddress : '',
    },
    authorEmailAddress: {
      label: '',
      type: 'text',
      value: author?.email ? author.email : '',
    },
    employeeNumber: {
      label: null,
      type: 'text',
      value: employee && employee.employeeNumber ? employee.employeeNumber : '',
    },
    employeeAddress: {
      label: null,
      type: 'text',
      value: employeeWorkAddress ? employeeWorkAddress : '',
    },
    employeeDepartment: {
      label: null,
      type: 'text',
      value: employee?.department?.name ? employee.department.name : '',
    },
  };
};
