import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import Paragraph from '../../../PDF/Paragraph.component';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentHeader } from '../../common/Header';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const ExtensionOfPIPFollowingHearingPDF: React.FC<DocumentProps> = props => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        <DocumentHeader content={props.content} logo={props.logo} />
        <Paragraph> Dear {props.content.employeeFullName.value}, </Paragraph>
        <Text style={{ ...styles.subject }}>EXTENSION OF PERFORMANCE IMPROVEMENT PLAN – FINAL REVIEW PERIOD</Text>
        <Paragraph>
          <Text>
            An Incapacity Poor Performance Hearing held {props.content.initial_hearing_hearingDate.value} refers. At
            said hearing the following sub-standard performance/shortfalls were discussed:
          </Text>
        </Paragraph>
        <Table>
          {Array.isArray(props.content.performanceShortfalls.value) &&
            props.content.performanceShortfalls.value.map((item: string, index: number) => (
              <TableRow key={index}>
                <TableCell width={'100%'}>{item} </TableCell>
              </TableRow>
            ))}
        </Table>
        <Paragraph>
          <Text>
            The chairperson of the hearing ruled that your Performance Improvement Plan (“PIP”) be extended and you be
            granted a last opportunity to meet the expected standards. Please find attached a copy of the chairperson’s
            ruling.
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>
            It is imperative that your performance improve and that you meet the agreed expectations as set out below
            within the time frames indicated during this final review period:
          </Text>
        </Paragraph>
        {/*<List>*/}
        {/*  {Array.isArray(objectives.value) &&*/}
        {/*    objectives.value.map((item: string, index: number) => <ListItem key={index}>{item} </ListItem>)}*/}
        {/*</List>*/}
        <Paragraph>
          <Text>
            You are further issued with a final warning valid for {props.content.warningValidityPeriod.value} month(s)
            from date of issuing. Should your performance not improve and remedial steps taken, it may result in the
            termination of your employment.
          </Text>
        </Paragraph>
        {/*<Paragraph>*/}
        {/*  <Text>{props.content.other.value}</Text>*/}
        {/*</Paragraph>*/}
        <Paragraph>
          <Text>Please discuss any concerns you may have with us timeously.</Text>
        </Paragraph>
        <Paragraph>
          <Text>
            This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a
            copy to writer hereof in acknowledgement of receipt hereof, alternatively, acknowledge by way of return
            e-mail.
          </Text>
        </Paragraph>
        <Paragraph>Yours sincerely,</Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
    </Document>
  );
};
