import React, {ChangeEvent, useCallback, useContext, useEffect, useState} from 'react';

import '../adhocPopover/NotePopover.styles.scss';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from 'reactstrap';
import AdhocButton from '../adhocButton/AdhocButton.component';
import { TabAddIcon, TabRemoveIcon, UserInfoIcon } from '../icon/Icon.component';
import { startCase } from 'lodash';
import { emailOrganisationMainContact, getEmployeeDetailsEmailContent } from '../../utils/email-utils';
import { UserContext, UserContextProps, UserDetails } from '../../App';
import { useEmailSentHandler, useErrorHandler } from '../../utils/notification-utils';
import { Employee } from '../../models';
import './UserInfoPopup.style.scss';
import { getEmployeeDetails } from '../../utils/employee-utils';

interface UserInfoPopupProps {
  employeeId: string;
  isSuspended: boolean;
}

const UserInfoPopup: React.FC<UserInfoPopupProps> = (props: UserInfoPopupProps) => {
  const currentUser: UserDetails | null | undefined = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const [modal, setModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [sending, setSending] = useState(false);
  const [openCard, handleOpenCard] = useState({
    toggle1: false,
    toggle2: false,
    toggle3: false,
    toggle4: false,
    toggle5: false,
  });
  const handleError = useErrorHandler();
  const emailSentHandler = useEmailSentHandler();
  const [employee, setEmployee] = useState<Employee | null>(null);
  const loadEmployee = useCallback(async (): Promise<Employee> => {
    return await getEmployeeDetails(props.employeeId);
  }, [props.employeeId]);

  useEffect(() => {
    loadEmployee().then(employee => setEmployee(employee));
  }, [loadEmployee]);

  function toggleCard(id: string): void {
    const card = openCard;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    card[id] = !card[id];
    handleOpenCard(oldState => ({ ...oldState, card }));
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setModalText(value);
  };

  const requestEmployeeDetailsChange = async (): Promise<void> => {
    if (!modalText.length) return;
    setSending(true);
    const emailContent = getEmployeeDetailsEmailContent(employee as Employee, modalText);
    if (currentUser?.organisationId && currentUser.email && employee) {
      await emailOrganisationMainContact(
        currentUser.organisationId,
        currentUser.email,
        `Change request on ${startCase(employee.firstName) + ` ` + startCase(employee.lastName)}'s information`,
        emailContent,
      )
        .then(() => {
          setSending(false);
          setModalText('');
          setModal(false);
          emailSentHandler();
        })
        .catch(error => {
          setSending(false);
          handleError(error);
        });
    }
  };

  const toggleModal = (): void => {
    setModal(!modal);
  };

  return (
    <>
      <AdhocButton id={'userPopover'} className="btn-round btn-icon ">
        <UserInfoIcon isSuspended={props.isSuspended} />
      </AdhocButton>
      <UncontrolledPopover
        placement="left-end"
        className="px-1 shadow-none"
        trigger={modal ? 'click' : 'legacy'}
        hideArrow={true}
        target="userPopover"
        style={{ margin: '0 auto 0 auto' }}
      >
        {employee && (
          <>
            <PopoverHeader className="font-weight-light text-default" style={{ fontSize: '1.25em' }}>
              Employee Information
            </PopoverHeader>
            <PopoverBody className="text-dark px-2">
              <div
                className="d-flex justify-content-between tab-background pointer px-3 py-2"
                onClick={(): void => toggleCard('toggle1')}
              >
                <span className="h-75 font-weight-light text-default">Personal Information</span>
                <span>{openCard.toggle1 ? <TabRemoveIcon /> : <TabAddIcon />}</span>
              </div>
              {openCard.toggle1 && (
                <div className="d-flex flex-column px-3 open-card">
                  <div className="row">
                    <span className="text-muted col-5">Name:</span>
                    <span className="text-default text-capitalize col-7">{employee.firstName}</span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">Last Name:</span>
                    <span className="text-default text-capitalize col-7"> {employee.lastName}</span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">ID Number:</span>
                    <span className="text-default col-7"> {employee.idNumber}</span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">Passport Number:</span>
                    <span className="text-default text-capitalize col-7"> {employee.passportNumber} </span>
                  </div>
                </div>
              )}
            </PopoverBody>
            <PopoverBody className="text-dark">
              <div
                className="d-flex justify-content-between card-header-adhoc tab-background pointer px-3 py-2"
                onClick={() => toggleCard('toggle2')}
              >
                <span className="h-75 font-weight-light text-default">Employment Information</span>
                <span>{openCard.toggle2 ? <TabRemoveIcon /> : <TabAddIcon />}</span>
              </div>
              {openCard.toggle2 && (
                <div className="d-flex flex-column px-3 open-card">
                  <div className="pt-1 row">
                    <span className="text-muted col-5">Employee Number:</span>
                    <span className="text-default text-capitalize col-7">
                      {employee.employeeNumber ? employee.employeeNumber : 'None'}
                    </span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">Department:</span>
                    <span className="text-default text-capitalize col-7">
                      {employee.department ? employee.department.name : 'None'}
                    </span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">Job Title:</span>
                    <span className="text-default text-capitalize col-7">
                      {employee.jobTitle ? employee.jobTitle.name : 'None'}
                    </span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">Job Grade:</span>
                    <span className="text-default text-capitalize col-7">
                      {employee.jobGrade ? employee.jobGrade.name : 'None'}
                    </span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">Job Level:</span>
                    <span className="text-default text-capitalize col-7">
                      {employee.jobLevel ? employee.jobLevel.name : 'None'}
                    </span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">Direct Manager:</span>
                    <span className="text-default text-capitalize col-7">
                      {employee.directManager
                        ? employee.directManager.firstName + ' ' + employee.directManager.lastName
                        : 'None'}
                    </span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">Hire Date:</span>
                    <span className="text-default text-capitalize col-7">
                      {employee.hireDate ? employee.hireDate : 'None'}
                    </span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">Status:</span>
                    <span className="text-default text-capitalize col-7">
                      {employee.status ? employee.status.toLowerCase() : 'None'}
                    </span>
                  </div>
                </div>
              )}
            </PopoverBody>
            <PopoverBody className="text-dark">
              <div
                className="d-flex justify-content-between card-header-adhoc tab-background pointer px-3 py-2"
                onClick={() => toggleCard('toggle3')}
              >
                <span className="h-75 font-weight-light text-default">Employment Equity Demographic</span>
                <span>{openCard.toggle3 ? <TabRemoveIcon /> : <TabAddIcon />}</span>
              </div>
              {openCard.toggle3 && (
                <div className="d-flex flex-column px-3 open-card">
                  <div className="row">
                    <span className="text-muted col-5">Race:</span>
                    <span className="text-default text-capitalize col-7">
                      {employee.race ? startCase(employee.race.toLowerCase()) : 'None'}
                    </span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">Gender:</span>
                    <span className="text-default text-capitalize col-7">
                      {employee.gender ? startCase(employee.gender.toLowerCase()) : 'None'}
                    </span>
                  </div>
                  {employee.hasDisability ? (
                    <>
                      <div className="row">
                        <span className="text-muted col-5">Disability:</span>
                        <span className="text-default text-capitalize col-7">{employee.disability}</span>
                      </div>
                      <div className="row">
                        <span className="text-muted col-5">Disability Description:</span>
                        <span className="text-default text-capitalize col-7">{employee.disabilityDescription}</span>
                      </div>
                    </>
                  ) : (
                    <div className="row">
                      <span className="text-muted col-5">Disability:</span>
                      <span className="text-default text-capitalize col-7">None</span>
                    </div>
                  )}
                  <div className="row">
                    <span className="text-muted col-5">Location:</span>
                    <span className="text-default text-capitalize col-7">
                      {employee.location ? employee.location.name.toLowerCase() : 'None'}
                    </span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">Work Hours:</span>
                    <span className="text-default col-7">
                      {employee.workHours && startCase(employee.workHours.toLowerCase())}
                    </span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">Start Time:</span>
                    <span className="text-default col-7">{employee.startTime}</span>
                  </div>
                  <div className="row">
                    <span className="text-muted col-5">End Time:</span>
                    <span className="text-default col-7">{employee.endTime}</span>
                  </div>
                </div>
              )}
            </PopoverBody>
            <div className="px-3">
              <hr />
              <Button className="btn-simple w-100 font-weight-light text-uppercase mb-2" onClick={toggleModal}>
                Update Employee Information
              </Button>
              <Modal isOpen={modal} toggle={toggleModal} className="top-5" size="md">
                <ModalHeader className="bg-gradient-default d-flex justify-content-center py-3">
                  <span className="font-weight-bold text-white text-uppercase">Update Employee Information</span>
                </ModalHeader>
                <ModalBody className="px-3 py-3">
                  <p className="text-muted">
                    Please note: Only super admin has the authority to change employee information. Please add the info
                    that you need changed in the field below and submit to admin to change.
                  </p>
                  <Input
                    className="px-3 my-3 border-light"
                    type="textarea"
                    placeholder="What info needs to change"
                    onChange={handleChange}
                  />
                  <div className="d-flex justify-content-between">
                    <Button
                      className="btn-simple rounded-0 text-uppercase font-weight-light w-75 mx-1"
                      onClick={toggleModal}
                    >
                      Close
                    </Button>
                    <Button
                      className="btn-default rounded-0 text-uppercase font-weight-light w-75 mx-1"
                      onClick={requestEmployeeDetailsChange}
                      disabled={sending}
                    >
                      {sending ? <i className="spinner-border spinner-border-sm" /> : 'Request Change'}
                    </Button>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </>
        )}
      </UncontrolledPopover>
    </>
  );
};

export default UserInfoPopup;
