import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import { PlusIcon, UserIcon } from '../icon/Icon.component';
import ButtonWithIcons from '../buttons/ButtonWIthIcons.component';
import { UserRole } from '../../models';
import { UserContext } from '../../Router';
import { UserContextProps } from '../../App';


const AddEmployeeButton: React.FC = () => {

    const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
    const allowedRoles = [
        UserRole.SUPER_ADMIN,
        UserRole.COMPANY_ADMIN,
    ]

    //@ts-ignore
    if (currentUser && !allowedRoles.filter(role => currentUser?.userRoles.includes(role)).length) {

        return null;
    }
    return (
        <>
            <Link to="/create-employee">
                <ButtonWithIcons
                    //@ts-ignore
                    disabled={currentUser && !allowedRoles.filter(role => currentUser?.userRoles.includes(role)).length}
                    title={'add employee'}
                    leftIcon={<PlusIcon />}
                    rightIcon={<UserIcon />}
                    buttonType={'btn-bd-purple'}
                />
            </Link>
        </>
    );
};

export default AddEmployeeButton;
