import React, { useContext, useEffect, useState } from 'react';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikValues, useFormikContext } from 'formik';
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { Storage } from 'aws-amplify';
import UploaderContainer from '../../../../../components/Uploader/UploaderContainer';
import { useErrorHandler } from '../../../../../utils/notification-utils';
import { EmailRecipient, EmailType } from '../../../../../utils/email-utils';
import { CaseType } from '../../../../../API';
import { FormProps } from '../../../../WorkflowContainer/workflow-utils';
import TableFormField from '../../../../../forms/fields/TableFormField';
import { Tooltip } from '../../../../../components/tooltips/Tooltip.component';
import { toTitleCase } from '../../../../../utils/string-utils';
import { UserContext, UserContextProps } from '../../../../../App';
import FormField from '../../../../../forms/fields/FormField.component';
import { EmailPreviewModalv3 } from '../../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { BucketFile } from '../../../../../models';
import { convertToFlowableVariables } from '../../../../../utils/flowable/flowable-utils';

const misconductChargesColumns = ['transgression', 'ruling on guilt', 'employee appeal', 'sanction', 'employee appeal'];
const ppChargesColumns = ['shortfall', 'ruling on guilt', 'employee appeal', 'sanction', 'employee appeal'];
const appealFormKey = 'forms/appeal_form.pdf';

const AppealProcessForm: React.FC<FormProps> = props => {
  const { values } = useFormikContext<FormikValues>();
  const { setFieldValue } = useFormikContext();
  const { processInstanceId } = props.data;
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const handleError = useErrorHandler();
  const [, setAppealFormUrl] = useState<string | null>();

  const getDocumentUrl = async (key: string): Promise<Record<string, any> | string> => {
    return new Promise((resolve, reject) => {
      Storage.get(key, { level: 'public' })
        .then(item => {
          if (item) {
            resolve(item);
          } else reject(new Error('could not get key'));
        })
        .catch(error => reject(error));
    });
  };

  const onDownloadAppealForm = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      getDocumentUrl(appealFormKey)
        .then(documentUrl => {
          resolve(documentUrl);
        })
        .catch(error => reject(error));
    });
  };

  const getRecipientsForEmailToRepresentative = (): EmailRecipient[] => {
    return [
      {
        emailAddress: props.data.caseData.initial_hearing_employerRepresentativeEmailAddress,
        name: toTitleCase(props.data.caseData.initial_hearing_employerRepresentativeFirstName, ' '),
      },
    ];
  };

  const getBucketKeyForEmployeesResponse = (): string[] => {
    return Array.isArray(values.employeeAppealResponseDocuments)
      ? values.employeeAppealResponseDocuments.map((i: BucketFile) => i.key)
      : [];
  };

  useEffect(() => {
    getDocumentUrl(appealFormKey).then(documentUrl => {
      setAppealFormUrl(documentUrl as string);
    });
  }, []);

  return (
    <Form>
      <h4 className="text-h4 text-blue text-capitalize font-weight-500 mt-3">
        Appeal Process
        <span className="ml-3">
          <Tooltip
            title={'Appeal'}
            id="appealProcess"
            text={`If an employee appeals the outcome of the initial hearing the original findings will take effect notwithstanding the appeal. If the sanction, for example dismissal, is overturned on appeal the sanction is reversed and the person is reinstated.`}
          />
        </span>
      </h4>
      <Row>
        <Col>
          <Label className="text-default d-block mt-2">Does your code allow for an appeal process?</Label>
          <div className="d-flex">
            <div className="mr-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value="yes"
                    checked={values.appealAllowed}
                    onChange={(): void => {
                      setFieldValue('appealAllowed', true);
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-muted text-uppercase">Yes</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
            <div className="ml-4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={!values.appealAllowed}
                    onChange={(): void => {
                      setFieldValue('appealAllowed', false);
                      setFieldValue('employeeHasIndicatedAppealIntent', false);
                      setFieldValue('employeeAppealResponseDocuments', []);
                      setFieldValue('appealReason', '');
                      setFieldValue('appealReceivedWithinPrescribedTimeFrame', '');
                    }}
                  />
                  <span className="form-check-sign">
                    <span className="check text-default text-muted text-uppercase">No</span>
                  </span>
                </Label>
              </FormGroup>
            </div>
          </div>
        </Col>
      </Row>
      {values.appealAllowed && (
        <>
          <Row>
            <Col>
              <Label className="text-default d-block mt-2">Did the employee indicate their interest to appeal?</Label>
              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="yes"
                        checked={values.employeeHasIndicatedAppealIntent}
                        onChange={(): void => {
                          setFieldValue('employeeHasIndicatedAppealIntent', true);
                        }}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">Yes</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={!values.employeeHasIndicatedAppealIntent}
                        onChange={(): void => {
                          setFieldValue('employeeHasIndicatedAppealIntent', false);
                        }}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-uppercase">No</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {values.employeeHasIndicatedAppealIntent && (
        <>
          <Row className={'mt-3'} md={6}>
            <Col md={3} className="mt-3">
              <FormGroup>
                <Label for="appealDeadline" className="text-default">
                  Appeal Deadline
                </Label>
                <FormField type={'date'} placeholder="Appeal Deadline" name="appealDeadline" />
                <span className="text-danger">
                  <ErrorMessage className="text-danger" name={'appealDeadline'} />
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row className={'mt-3'} md={6}>
            <Col md={3}>
              <Button
                className="rounded-0 shadow text-uppercase font-weight-light"
                onClick={(e: any): void => {
                  onDownloadAppealForm()
                    .then((url: string) => {
                      e.preventDefault();
                      window.open(url, '_blank');
                    })
                    .catch(error => handleError(error));
                }}
              >
                Print and Deliver
              </Button>
            </Col>
            <Col md={2}>
              <div className="mt-3 text-center">
                <span className="text-primary font-weight-bold">OR</span>
              </div>
            </Col>
            <Col md={3}>
              <div className="d-flex">
                <EmailPreviewModalv3
                  buttonText={'Send email to employee'}
                  disabled={!values.appealDeadline || !props.data.employeeWorkEmailAddresses.length}
                  formValues={values}
                  emailType={
                    props.data.caseType === CaseType.POOR_PERFORMANCE
                      ? EmailType.EMPLOYEE_APPEAL_FORM_POOR_PERFORMANCE
                      : EmailType.EMPLOYEE_APPEAL_FORM_MISCONDUCT
                  }
                  masterProcessInstanceId={props.data.masterProcessInstanceId}
                  processInstanceId={props.data.processInstanceId}
                  currentUserId={currentUser?.id}
                  getAttachmentBucketKeys={() => {
                    return [appealFormKey];
                  }}
                  getFlowableVariables={() => {
                    return convertToFlowableVariables(props.getFormValuesForSubmission(values));
                  }}
                />
                {!props.data.employeeWorkEmailAddresses.length && (
                  <div className="mt-2">
                    <Tooltip
                      id="noWorkEmailAddress"
                      title={`No work email address`}
                      text={`We can only send an email to an employee's designated work email address. You will need to update this employee's information with a work email address.`}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mt-3">
              <FormGroup>
                <Label for="exampleFile" className="text-default text-capitalize">
                  Upload completed appeal form*
                </Label>
                <UploaderContainer fieldName={'employeeAppealResponseDocuments'} path={`cases/${processInstanceId}`} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={2} className="no-gutters mb-3">
              <EmailPreviewModalv3
                buttonText={"email employee's response to representative"}
                disabled={!values.employeeAppealResponseDocuments?.length}
                formValues={values}
                emailType={EmailType.NOTIFY_EMPLOYER_REPRESENTATIVE_OF_APPEAL}
                masterProcessInstanceId={props.data.masterProcessInstanceId}
                processInstanceId={props.data.processInstanceId}
                currentUserId={currentUser?.id}
                getRecipients={getRecipientsForEmailToRepresentative}
                getAttachmentBucketKeys={getBucketKeyForEmployeesResponse}
                getFlowableVariables={() => convertToFlowableVariables(props.getFormValuesForSubmission(values))}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className="text-default d-block mt-1">Appeal Against</Label>
              {props.data.caseType === CaseType.MISCONDUCT && (
                <Table className="table-responsive-lg" bordered>
                  <thead>
                    <tr>
                      {misconductChargesColumns.map(column => (
                        <th key={column}>
                          <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name={'incidents'}>
                      {(arrayHelpers: FieldArrayRenderProps) => (
                        <>
                          {!values.incidents && arrayHelpers.push({})}
                          {values.incidents &&
                            values.incidents.length &&
                            values.incidents
                              .filter((item: any) => item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY')
                              .map((item: any, index: number) => (
                                <tr key={index}>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span> {item.transgression}</span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span>{toTitleCase(item.initial_hearing_chairpersonsFindingOnGuilt, '_')}</span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '75px' }}>
                                    <TableFormField
                                      type={'checkbox-yes-and-no'}
                                      placeholder={''}
                                      name={`incidents.${index}.didEmployeeAppealRuling`}
                                      checkedString={item.didEmployeeAppealRuling}
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`incidents.${index}.didEmployeeAppealRuling`}
                                      />
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span>{toTitleCase(item.initial_hearing_chairpersonsSanction, '_')}</span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <TableFormField
                                      type={'checkbox-yes-and-no'}
                                      placeholder={''}
                                      name={`incidents.${index}.didEmployeeAppealSanction`}
                                      checkedString={item.didEmployeeAppealSanction}
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`incidents.${index}.didEmployeeAppealSanction`}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                        </>
                      )}
                    </FieldArray>
                  </tbody>
                </Table>
              )}
              {props.data.caseType === CaseType.POOR_PERFORMANCE && (
                <Table className="table-responsive-lg" bordered>
                  <thead>
                    <tr>
                      {ppChargesColumns.map(column => (
                        <th key={column}>
                          <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name={'performanceShortfalls'}>
                      {(arrayHelpers: FieldArrayRenderProps) => (
                        <>
                          {!values.performanceShortfalls && arrayHelpers.push({})}
                          {values.performanceShortfalls &&
                            values.performanceShortfalls.length &&
                            values.performanceShortfalls
                              .filter((item: any) => item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY')
                              .map((item: any, index: number) => (
                                <tr key={index}>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span> {item.shortfallDescription}</span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span>{toTitleCase(item.initial_hearing_chairpersonsFindingOnGuilt, '_')}</span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '75px' }}>
                                    <TableFormField
                                      type={'checkbox-yes-and-no'}
                                      placeholder={''}
                                      name={`performanceShortfalls.${index}.didEmployeeAppealRuling`}
                                      checkedString={item.didEmployeeAppealRuling}
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`performanceShortfalls.${index}.didEmployeeAppealRuling`}
                                      />
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span>{toTitleCase(item.initial_hearing_chairpersonsSanction, '_')}</span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <TableFormField
                                      type={'checkbox-yes-and-no'}
                                      placeholder={''}
                                      name={`performanceShortfalls.${index}.didEmployeeAppealSanction`}
                                      checkedString={item.didEmployeeAppealSanction}
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`performanceShortfalls.${index}.didEmployeeAppealSanction`}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                        </>
                      )}
                    </FieldArray>
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className="text-default d-block mt-2">Received within prescribed timeframe</Label>
              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="yes"
                        checked={values.appealReceivedWithinPrescribedTimeFrame}
                        onChange={(): void => {
                          setFieldValue('appealReceivedWithinPrescribedTimeFrame', true);
                        }}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">Yes</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={!values.appealReceivedWithinPrescribedTimeFrame}
                        onChange={(): void => {
                          setFieldValue('appealReceivedWithinPrescribedTimeFrame', false);
                        }}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-uppercase">No</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </div>
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'appealReceivedWithinPrescribedTimeFrame'} />
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className="text-default d-block mt-2">Proceed with appeal?</Label>
              <div className="d-flex">
                <div className="mr-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="yes"
                        checked={values.proceedWithAppeal}
                        onChange={(): void => {
                          setFieldValue('proceedWithAppeal', true);
                        }}
                      />
                      <span className="form-check-sign">
                        <span className="check text-muted text-uppercase">Yes</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
                <div className="ml-4">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={!values.proceedWithAppeal}
                        onChange={(): void => {
                          setFieldValue('proceedWithAppeal', false);
                        }}
                      />
                      <span className="form-check-sign">
                        <span className="check text-default text-muted text-uppercase">No</span>
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default AppealProcessForm;
