import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useContext } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../components/taskContainer/TaskContainer';
import { Tooltip } from '../../components/tooltips/Tooltip.component';
import { WorkFlowFooter } from '../../components/workflowFooter/WorkFlowFooter';
import FormField from '../../forms/fields/FormField.component';
import { SuspensionDecisionSchema } from '../../forms/ValidationSchema/GeneralCaseSchema';
import { getFormDataFromCaseData, WorkflowComponentProps } from '../WorkflowContainer/workflow-utils';

const SuspensionDecision: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData } = props.data;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
  const fields = {
    suspensionDecisionReason: 'string',
    suspensionDecisionEffectiveDate: 'string',
    suspensionDecision: 'boolean',
  };
  const initialValues: FormikValues = getFormDataFromCaseData(fields, caseData, caseData.isAppeal);

  const submitValues = async (values: FormikValues): Promise<void> => {
    const formValues = { ...values };
    props.data.flowableFunctions.onNext(formValues);
    // props.data.flowableFunctions.updateEmployeeSuspensionDate(formValues.suspensionDecisionEffectiveDate);
  };

  return (
    <div className="content">
      <>
        <h4 className="text-h4 text-capitalize font-weight-500">Make Suspension Decision</h4>
        <Formik initialValues={initialValues} validationSchema={SuspensionDecisionSchema} onSubmit={submitValues}>
          {({ values, setFieldValue, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
            <>
              <Row>
                <Col>
                  <Label className="text-default d-block">Should the employee be suspended?</Label>
                  <div className="d-flex">
                    <div className="mr-4">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            value="yes"
                            checked={values.suspensionDecision}
                            onChange={(): void => setFieldValue('suspensionDecision', true)}
                            disabled={readOnly}
                          />
                          <span className="form-check-sign">
                            <span className="check text-muted text-uppercase">yes</span>
                          </span>
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="ml-4">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            value="no"
                            checked={!values.suspensionDecision}
                            onChange={(): void => {
                              setFieldValue('suspensionDecision', false);
                              setFieldValue('suspensionDecisionEffectiveDate', '');
                              setFieldValue('suspensionDecisionReason', '');
                            }}
                            disabled={readOnly}
                          />
                          <span className="form-check-sign">
                            <span className="check text-default text-muted text-uppercase">no</span>
                          </span>
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <FormGroup>
                    <Label for="suspensionDecisionReason" className="text-default">
                      Reason for Suspension Decision*
                    </Label>
                    <FormField
                      type={'textarea'}
                      placeholder="Please Supply a Reason for Suspension"
                      name="suspensionDecisionReason"
                      style={{ minWidth: '65vw' }}
                    />
                    <span className="text-danger">
                      <ErrorMessage className="text-danger" name={'suspensionDecisionReason'} />
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              {values.suspensionDecision && (
                <>
                  <div className="d-flex flex-column justify-content-between mt-3">
                    <Row>
                      <Col md={5} sm={4} lg={4}>
                        <FormGroup className="rounded-0">
                          <Label for="suspensionDecisionEffectiveDate" className="text-default text-capitalize">
                            Confirm effective date of suspension*
                          </Label>
                          <FormField
                            name="suspensionDecisionEffectiveDate"
                            placeholder="Effective Suspension Date"
                            type="date"
                          />
                          <span className="text-danger">
                            <ErrorMessage className="text-danger" name={'suspensionDecisionEffectiveDate'} />
                          </span>
                        </FormGroup>
                      </Col>
                      <div className="align-self-center">
                        <div className=" pl-2 pt-3 pointer">
                          <span>
                            <Tooltip
                              id="suspensionDate"
                              title={`Confirm effective date of suspension (Calendar) `}
                              text={`A   suspension   normally   is   effective   immediately   to   avoid   the   foreseen   risks   from materialising. Although  there  is  no  maximum  period  prescribed  in  law,  the  suspension period  itself  should  not  be  prolonged  unnecessarily  to  avoid  a  potential  unfair  labour practice dispute from arising.`}
                            />
                          </span>
                        </div>
                      </div>
                    </Row>
                  </div>
                </>
              )}
              <WorkFlowFooter
                data={props.data}
                onNext={handleSubmit}
                onSaveAndClose={(): void => {
                  props.data.flowableFunctions.onSaveAndClose(values);
                }}
                onCancel={() => console.log('cancel')}
              />
            </>
          )}
        </Formik>
      </>
    </div>
  );
};

export default SuspensionDecision;
