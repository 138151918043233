import { ErrorMessage, Field, FieldAttributes, FormikProps, FormikValues, useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { CaseType } from '../../API';
import FormField, { transformSelectValue } from '../fields/FormField.component';
import UploaderContainer from '../../components/Uploader/UploaderContainer';

const selectOptions = [
  { label: '1st', value: 'firstOffence' },
  { label: '2nd', value: 'secondOffence' },
  { label: '3rd', value: 'thirdOffence' },
  { label: '4th', value: 'forthOffence' },
  { label: '5th', value: 'fifthOffence' },
  { label: '6th', value: 'sixthOffence' },
];
const PastCaseForm: React.FC = () => {
  const { values }: FormikProps<FormikValues> = useFormikContext();

  return (
    <div className="d-flex flex-column justify-content-between">
      <h4 className="text-default mt-2 font-weight-bold">Past Case</h4>
      <Row>
        <Col md={4}>
          <FormGroup className="rounded-0">
            <Label for="caseNumber" className="text-default">
              Case Number
            </Label>
            <FormField name="caseNumber" placeholder="Please enter Case Number" type="text" />
          </FormGroup>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={'caseNumber'} />
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="caseType" className="text-default">
              Case type*
            </Label>
            <FormField
              name={'caseType'}
              placeholder={'Select Case Type'}
              type={'select'}
              selectOptions={Object.keys(CaseType)}
            />
          </FormGroup>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={'caseType'} />
          </span>
        </Col>
      </Row>
      <Row>
        {values.caseType === CaseType.MISCONDUCT && (
          <Col md={4}>
            <FormGroup className="rounded-0">
              <Label for="transgression" className="text-default">
                Transgression
              </Label>
              <FormField name="transgression" placeholder="Enter Transgression" type="text" />
            </FormGroup>
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'transgression'} />
            </span>
          </Col>
        )}
        {values.caseType === CaseType.POOR_PERFORMANCE && (
          <Col md={4}>
            <FormGroup className="rounded-0">
              <Label for="shortfallDescription" className="text-default">
                Shortfall Description
              </Label>
              <FormField name="shortfallDescription" placeholder="Enter Description" type="text" />
            </FormGroup>
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'shortfallDescription'} />
            </span>
          </Col>
        )}
        <Col md={4}>
          <FormGroup className="rounded-0">
            <Label for="frequency" className="text-default">
              Offence
            </Label>
            <Field name="frequency" placeholder="Select Frequency">
              {({ field }: FieldAttributes<FormikValues>): ReactElement => (
                <Input {...field} type="select">
                  <option className="text-muted" value="">
                    Select Frequency
                  </option>
                  {selectOptions &&
                    selectOptions.length &&
                    selectOptions.map((value, key) => (
                      <option key={key} value={value.value.toUpperCase()}>
                        {transformSelectValue(value.label)}
                      </option>
                    ))}
                </Input>
              )}
            </Field>
          </FormGroup>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={'frequency'} />
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup className="rounded-0">
            <Label for="sanction" className="text-default">
              Sanction
            </Label>
            <FormField name="sanction" placeholder="Enter Sanction" type="text" />
          </FormGroup>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={'sanction'} />
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormGroup className="rounded-0">
            <Label for="Expiry Date" className="text-default">
              Expiry Date
            </Label>
            <FormField name="expiryDate" placeholder="Expiry Date" type="date" />
          </FormGroup>
          <span className="text-danger">
            <ErrorMessage className="text-danger" name={'expiryDate'} />
          </span>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="mt-3">
          <FormGroup>
            <Label for="caseFiles" className="text-default text-capitalize">
              Upload Case files, if any
            </Label>
            <UploaderContainer fieldName={'caseFiles'} withAutoUpload={false} isMulti={true} />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default PastCaseForm;
