export enum ColumnHeading {
  employeeNumber = 'Employee Number*',
  firstName = 'First Name*',
  lastName = 'Last Name*',
  idNumber = 'ID Number*',
  passportNumber = 'Passport Number*',
  mobilePhone = 'Mobile Phone*',
  workPhone = 'Work Phone*',
  homePhone = 'Home Phone*',
  residentialAddressLineOne = 'Address Line 1 (Residential Address)*',
  residentialAddressLineTwo = 'Address Line 2 (Residential Address)*',
  residentialAddressCity = 'City (Residential Address)*',
  residentialAddressProvince = 'Province (Residential Address)*',
  residentialAddressPostalCode = 'Postal Code (Residential Address)*',
  workAddressLineOne = 'Address Line 1 (Work Address)*',
  workAddressLineTwo = 'Address Line 2 (Work Address)*',
  workAddressCity = 'City (Work Address)*',
  workAddressProvince = 'Province (Work Address)*',
  workAddressPostalCode = 'Postal Code (Work Address)*',
  workEmail = 'Work Email*',
  personalEmail = 'Personal Email*',
  departmentName = 'Function/Department*',
  locationName = 'Location*',
  directManager = 'Employee Number of Direct Manager*',
  allocatedManager = 'Employee Number of Allocated HR Manager*',
  raceType = 'Race*',
  genderType = 'Gender*',
  disability = 'Disability*',
  disabilityType = 'Disability Type*',
  placeOfWorkType = 'Place of Work*',
  hireDate = 'Hire Date (DD/MM/YYYY)*',
  workHoursType = 'Work Hours',
  statusType = 'Status*',
  jobTitle = 'Job Title*',
  jobGrade = 'Job Grade*',
  jobLevel = 'Job Level*',
  approvedChairPerson = 'Approved Chairperson (Y/N)*',
  approvedHcApprover = 'Approved HC Approver (Y/N)*',
}
