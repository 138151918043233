import React, { ChangeEvent, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Alert, Button, CardFooter, FormGroup, Input } from 'reactstrap';

interface ForgotPasswordViewProps {
  handleMoveForward: (username: string) => void;
  handleCancelForgotPassword: () => void;
}

const INITIAL_FORGOT_DETAILS = {
  step: 1,
  username: '',
  code: '',
  password: '',
  confirmPassword: '',
  error: '',
  loading: false,
};

const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = (props: ForgotPasswordViewProps) => {
  const [state, setState] = useState(INITIAL_FORGOT_DETAILS);
  const { username, loading, error } = state;
  const { handleMoveForward, handleCancelForgotPassword } = props;

  const handleChangeForgotUsername = (event: ChangeEvent<HTMLInputElement>): void => {
    const username = event.target.value;
    setState(oldState => ({ ...oldState, username }));
  };

  const handlePressSubmit = (): void => {
    if (username) {
      const success = (): void => handleMoveForward(username);
      const failure = (err: { message: string }): void =>
        setState(oldState => ({ ...oldState, error: err.message, loading: false }));
      // success();
      // setState(oldState => ({ ...oldState, error: '', loading: true }));
      Auth.forgotPassword(username)
        .then(success)
        .catch(failure);
    }
  };
  return (
    <>
      {!!error && (
        <Alert aria-label="error-message" className=" d-flex justify-content-center" color="danger" fade={false}>
          <span>{error}</span>
        </Alert>
      )}
      <h4 className="text-muted text-lg-center">{'Please enter your username'}</h4>
      <FormGroup className="p-1">
        <Input
          aria-label={'username'}
          placeholder="Username"
          type="text"
          value={username}
          onChange={handleChangeForgotUsername}
        />
      </FormGroup>
      <CardFooter className="d-flex flex-column align-items-center">
        <Button
          block
          className="btn-round mb-2 text-uppercase font-weight-light"
          color="primary"
          onClick={handlePressSubmit}
          size="lg"
          disabled={loading || !username}
          aria-label={'submit'}
        >
          Send Reset Code
        </Button>
        <Button
          block
          className="btn-round mb-2 text-uppercase font-weight-light"
          color="primary"
          onClick={handleCancelForgotPassword}
          size="lg"
          aria-label={'cancel'}
        >
          Cancel
        </Button>

        <div className="p-1">
          <h5 className="text-muted text-lg-center">
            <small>
              Disclaimer: The labourteq software solution does not constitute legal advice.
              <br />
              Labourteq is strictly copyright protected and any unauthorised use/copying/redistribution is prohibited.
              Non-compliance will lead to prosecution
              <br />
              © Labourteq Solutions (Pty) Ltd 2019 (Registration number 2019/588031/07)
            </small>
          </h5>
        </div>
      </CardFooter>
    </>
  );
};

export default ForgotPasswordView;
