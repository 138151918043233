export const AppealHearingMisconductGuidancePageTabs = [
  'Role of Chairperson',
  'Employee Rights',
  'Evidence',
  'Sanctions',
];

export const AppealHearingGuidancePageIncapacityTabs = [
  'Role of Chairperson',
  'Employee Rights',
  'Incapacity Outcomes',
];
