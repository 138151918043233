import { FlowableHistoricTask, FlowableVariable } from '../../utils/flowable/flowable-types';

export const findFlowableVariablesFromTask = (
  task: FlowableHistoricTask,
  variableNames: string[],
): Record<string, FlowableVariable | undefined> => {
  const arr: [string, FlowableVariable | undefined][] = variableNames.map((name: string) => {
    const variable: FlowableVariable | undefined = task.variables?.find((item: FlowableVariable) => item.name === name);
    return [name, variable];
  });

  return Object.fromEntries(arr);
};
