import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { CaseClosedIcon } from '../../components/icon/Icon.component';
import { useHistory } from 'react-router-dom';

export interface ConfirmCloseCaseProps {
  onNext: () => void;
  closeModal: () => void;
  employeeId: string;
  isOpen: boolean;
}

export const ConfirmCloseCaseModal: React.FC<ConfirmCloseCaseProps> = (props: ConfirmCloseCaseProps) => {
  const [state, setState] = useState<number>(1);
  const history = useHistory();
  const onCloseModal = () => {
    setState(1);
    props.closeModal();
  };
  return (
    <Modal className="p-0" size="md" isOpen={props.isOpen} centered>
      <ModalBody className="p-0 rounded-0">
        <div className="d-flex justify-content-between px-3 py-2 guidance-modal-header">
          <div className="text-capitalize ml-auto mr-auto font-weight-bold">
            {state === 2 ? 'Choose Next Location' : 'Confirm Close Case'}
          </div>
        </div>
        <div className="d-flex align-items-center flex-column px-3 py-3 mb-3">
          <div className="text-muted mb-2">
            {state === 2 ? 'Please choose where you want to go' : 'Are you sure you want to close the case?'}
          </div>
          <div className="pl-2">
            <CaseClosedIcon />
          </div>
        </div>
        <div className="d-flex justify-content-between my-2">
          {state === 2 ? (
            <>
              <Button
                className="btn-simple text-uppercase rounded-0 ml-2 font-weight-light"
                title={'Case History'}
                onClick={(): void => {
                  props.onNext();
                  history.push(`/create-employee/${props.employeeId}/history`);
                }}
              >
                Case History
              </Button>
              <Button
                className="btn-default text-uppercase rounded-0 mr-2 font-weight-light"
                title={'CCMA'}
                onClick={(): void => {
                  props.closeModal();
                  props.onNext();
                }}
              >
                CCMA
              </Button>
            </>
          ) : (
            <>
              <Button
                className="btn-simple text-uppercase rounded-0 ml-2 font-weight-light"
                title={'Cancel'}
                onClick={onCloseModal}
              >
                Cancel
              </Button>
              <Button
                className="btn-default text-uppercase rounded-0 mr-2 font-weight-light"
                title={'Confirm'}
                onClick={(): void => {
                  setState(2);
                }}
              >
                Confirm
              </Button>
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
