import { UserDetails } from '../App';
import { AuditLog, AuditLogEventType, UserRole } from '../models';
import { getAllDescendantIds } from './employee-utils';

export const allowUserToPerformOperation = (
  user: UserDetails | null | undefined,
  allowedRoles: UserRole[],
): boolean => {
  if (user && user.userRoles) return !!user.userRoles.filter(role => allowedRoles.includes(role)).length;
  return false;
};

export const filterLogsByRole = async (auditLogs: AuditLog[], currentUser: UserDetails): Promise<AuditLog[]> => {
  const systemUsageAuditLogTypes = [
    AuditLogEventType.USER_CREATED.toString(),
    AuditLogEventType.USER_EDITED.toString(),
    AuditLogEventType.EMPLOYEE_CREATED.toString(),
    AuditLogEventType.BULK_EMPLOYEE_UPLOAD.toString(),
  ];
  if (
    !allowUserToPerformOperation(currentUser, [
      UserRole.COMPANY_ADMIN,
      UserRole.HR_MANAGER,
      UserRole.LINE_MANAGER,
      UserRole.CASE_USER,
    ])
  ) {
    return auditLogs.filter(log => systemUsageAuditLogTypes.includes(log.type));
  } else if (allowUserToPerformOperation(currentUser, [UserRole.COMPANY_ADMIN])) {
    return auditLogs;
  } else {
    const logs = [];
    for (let i = 0; i < auditLogs.length; i++) {
      const descendants = await getAllDescendantIds(currentUser.id, currentUser.organisationId!);
      if (descendants.includes(auditLogs[i].relevantEmployeeId!)) {
        if (!systemUsageAuditLogTypes.includes(auditLogs[i].type)) logs.push(auditLogs[i]);
      }
    }
    return logs;
  }
};
