import React, { ReactElement, useState } from 'react';
import GuidanceModal from '../../../components/guidanceModals/GuidanceModal.component';
import { Formik, FormikProps, FormikValues } from 'formik';
import { PreparePerformanceCounsellingSchema } from '../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import PreparePerformanceCounsellingForm from './PreparePerformanceCounsellingForm';
import {
  WorkflowComponentProps,
  getFormDataFromCaseData,
  filterFormData,
} from '../../WorkflowContainer/workflow-utils';
import { CounsellingSession } from '../RecordCounselling/RecordCounselling';
import { v4 as uuidV4 } from 'uuid';

const PreparePerformanceCounselling: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { caseData, flowableFunctions } = props.data;

  const fields = {
    compileInvite: 'boolean',
    performanceCounsellingSessions: 'array',
  };

  const initialSession: CounsellingSession = {
    sessionId: uuidV4(),
    counsellingDate: '',
    counsellingTime: '',
    counsellingAttendees: null,
    counsellingLocation: '',
    counsellingSummary: '',
    counsellingDocuments: null,
    sessionCompleted: false,
  };

  const initialState = getFormDataFromCaseData(
      fields,
      caseData,
      caseData.isAppeal
  );

  if (!initialState.performanceCounsellingSessions) initialState.performanceCounsellingSessions = [];

  initialState.counsellingSession = initialState.performanceCounsellingSessions[0] || initialSession;

  const [state] = React.useState(initialState);

  const getFormValuesForSubmission = (values: FormikValues): Record<string, any> => {
    const formValues = { ...values };
    if (!formValues.counsellingSession.sessionCompleted) {
      formValues.performanceCounsellingSessions = JSON.stringify([values.counsellingSession]);
    } else {
      formValues.performanceCounsellingSessions = JSON.stringify(values.performanceCounsellingSessions);
    }
    delete formValues.counsellingSession;
    return filterFormData(formValues, fields);
  };

  const submitValues = (values: FormikValues): void => {
    flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Prepare for Performance Counselling Discussion</h4>
      <GuidanceModal
        buttonTitle={'Performance Counselling discussion'}
        title={'Guidance: Performance Counselling discussion'}
        isOpen={modalIsOpen}
        setModal={setModalIsOpen}
      >
        <p>
          <p>
            <div className="font-weight-bold font-italic text-dark">
              What is the purpose of performance counselling?
            </div>
          </p>
          <span className="text-muted">
            Counselling discussions are intended to address minor performance concerns and to give the employee the
            opportunity to correct performance shortfalls going forward. In the counselling session, the employee should
            be given an opportunity to respond to perceived performance shortfalls, indicate what assistance is required
            from the employer and what remedial steps he/she will implement.
          </span>
        </p>
        <p>
          <span className="text-muted">
            Counselling is corrective in nature. Most performance problems can be resolved through effective
            communication between employers/managers and employees.
          </span>
        </p>
        <p>
          <span className="text-muted">Specifically, counselling should achieve the following objectives:</span>
          <ul className="px-3">
            <li className="text-muted">
              • Alert the employee to his/her performance shortfalls relative to job requirements/expectations;
            </li>
            <li className="text-muted">• Obtain the employee’s input on the reasons for their shortfalls;</li>
            <li className="text-muted">• Discuss and confirm performance standards, expectations and goals;</li>
            <li className="text-muted">• Set expectations for reviews to monitor performance going forward;</li>
            <li className="text-muted">
              • Agree on what the employee must do to bring his/her performance up to an acceptable level;
            </li>
            <li className="text-muted">• Agree on the assistance the employee requires from the employer/manager.</li>
          </ul>
        </p>
        <p>
          <span className="text-muted">
            Counselling discussions are typically conducted by the employee’s direct manager with or without HR present.
            Constructive counselling given early and regularly can lead to performance improvement and eliminate the
            need for more formal action.
          </span>
          <p>
            <span className="text-muted">
              All actions must be documented. Should the employee’s performance not improve, documented actions will
              support the decision to proceed with progressive, more serious steps.
            </span>
          </p>
          <p>
            <span className="text-muted">
              You should not wait for performance appraisal/review cycles to inform the employee of his/her performance
              shortfalls.
            </span>
          </p>
          <br />
          <p>
            <div className="font-weight-bold font-italic text-dark">
              How can I try and avoid performance problems before they occur?{' '}
            </div>
          </p>
          <ul className="px-3">
            <li className="text-muted">
              • Communicate clear performance standards, goals and expectations upfront so that employees know what is
              expected of them;
            </li>
            <li className="text-muted">
              • Provide regular and frequent performance feedback, whether positive or developmental in nature;
            </li>
            <li className="text-muted">
              • Frequent feedback reduces the likelihood that serious shortfalls will arise or become entrenched;
            </li>
            <li className="text-muted">
              • Frequent feedback reduces the likelihood that an employee is surprised when it becomes necessary to
              institute formal steps to resolve his/her poor performance;
            </li>
            <li className="text-muted">
              • Acknowledge and recognise good and superior performance, formally and informally;
            </li>
            <li className="text-muted">
              • Make full use of probationary periods for new employees and pay attention to performance from the start
              to detect shortfalls early;
            </li>
            <li className="text-muted">• Address shortfalls proactively when they arise, rather than wait;</li>
            <li className="text-muted">
              • Be honest yet constructive in formal performance evaluation discussions and apply your mind to assigning
              accurate performance ratings as they may later become important evidence in a poor performance incapacity
              process . For example, don’t assign a ‘meets expectations’ rating simply to avoid conflict, when this is
              not reflective of actual performance.
            </li>
          </ul>
        </p>
        <br />
        <p>
          <p>
            <div className="font-weight-bold font-italic text-dark">Why should I address poor performance?</div>
          </p>
          <span className={'text-muted'}>
            Dealing with poor performance can be a challenge for managers as well as time consuming. It is, however, a
            core management responsibility and failure to address poor performance when it arises can have serious
            operational, monetary and reputational consequences. It also sends the wrong message to the employee
            concerned and other employees, namely that poor performance is accepted.
          </span>
        </p>
        <p>
          <span className={'text-muted'}>
            Without proactive management intervention, poor performance typically gets worse over time and rarely
            self-corrects. It is best that managers act proactively when it arises thereby avoiding habitual or serious
            shortfalls from becoming entrenched. Building a productive and fair work environment begins by setting clear
            performance expectations and addressing any perceived failures to meet those expectations in a fair and
            reasonable manner.
          </span>
        </p>
        <br />
        <p>
          <p>
            <div className="font-weight-bold font-italic text-dark">
              What is the difference between poor performance and misconduct?
            </div>
          </p>
          <span className={'text-muted'}>
            It is important that you make sure that you are faced with true incapacity poor performance rather than
            misconduct.
          </span>
        </p>
        <p>
          <span className={'text-muted'}>
            Misconduct is when it is alleged that the employee contravened a workplace rule or committed a material
            breach of his/her employment contract. He/she is at fault. Examples are negligence, poor time management,
            failure to follow instructions or standing operating procedures, or intentionally misleading a manager as to
            tasks done.{' '}
          </span>
        </p>
        <p>
          <span className={'text-muted'}>
            Poor performance on the other hand is when the employee is incapable of doing his/her job because of a lack
            of skill, knowledge, ability, attitude or efficiency which is necessary to meet the performance standards
            for the role. He/she is willing but unable to meet the standards and expectations. Sometimes performance and
            misconduct is interrelated and then you need to evaluate whether the problem experienced is primarily
            performance based.
          </span>
        </p>
        <br />
        <p>
          <p>
            <div className="font-weight-bold font-italic text-dark">
              What role does an Employee Assistance Programme (EAP) play?
            </div>
          </p>
          <span className={'text-muted'}>
            At times, you may need the support of the Employee Assistance Programme (EAP), if available in your
            workplace, to assist the employee to deal with a range of issues, including personal circumstances or mental
            health conditions. Make sure that you understand the available services and how to refer an employee. The
            EAP can help to address personal circumstances impacting on the employee’s work. Ultimately, however, the
            employee remains responsible for utilising the EAP and meeting the employer’s performance standards and
            expectations.
          </span>
        </p>
        <br />
        <p>
          <p>
            <div className="font-weight-bold font-italic text-dark">
              What process should be followed when embarking on performance counselling?
            </div>
          </p>
          <ul className="px-3">
            <li className="text-muted">
              • Read your company’s Incapacity Poor Performance Policy or the Policy provided by LabourTeq;
            </li>
            <li className="text-muted">
              • Request to meet with the employee in a meeting room or suitable space, preferably one as private as
              possible so that the discussion is not overheard by other employees;
            </li>
            <li className="text-muted">• Allow adequate time for your feedback as well as the employee’s responses;</li>
            <li className="text-muted">
              • Prepare adequately for the counselling discussion(s) - know the specifics of the alleged shortfalls,
              examples of incidents and how you want the behaviour to be remedied in future;
            </li>
            <li className="text-muted">
              • Clearly state the performance standards / expectations required by the job, and obtain confirmation that
              the employee understands these expectations;
            </li>
            <li className="text-muted">
              • If the expectations are unclear, you may need to re-communicate and/or document them;
            </li>
            <li className="text-muted">
              • Indicate areas of concerns citing specific examples of performance shortfalls;
            </li>
            <li className="text-muted">• Focus on the poor performance, not personality or other extraneous issues;</li>
            <li className="text-muted">
              • Obtain confirmation from the employee that he/she understands and give him/her an opportunity to
              respond;
            </li>
            <li className="text-muted">
              • Listen to factors the employee may raise impacting on his/her performance and respond appropriately;
            </li>
            <li className="text-muted">• Ascertain what actions are required from you to assist;</li>
            <li className="text-muted">• Remain calm and professional;</li>
            <li className="text-muted">• Seek cooperation and resolution, rather than confrontation;</li>
            <li className="text-muted">
              • End on a positive note, assuring the employee of your support and your mutual goal to improve his/her
              performance;
            </li>
            <li className="text-muted">
              • LabourTeq allows you to record all counselling sessions held to ensure proper documentation of all
              discussions;
            </li>
            <li className="text-muted">
              • Provide an appropriate opportunity and time period for the employee to improve and continue to monitor
              progress;
            </li>
            <li className="text-muted">• Follow up. If the employee improves, acknowledge this;</li>
            <li className="text-muted">• If he/she is still struggling, counsel again;</li>
            <li className="text-muted">
              • However, you do not need to accept continued or habitual poor performance for an undue period, and at a
              point in time you may need to embark upon a formal process which may include drafting a Performance
              Improvement Plan or even initiating an Incapacity poor performance hearing.
            </li>
            <li className="text-muted">
              • Bear in mind that the counselling discussions are confidential and should not be discussed with other
              employees.
            </li>
            <li className="text-muted">• Escalate the matter to HR or Executive management, as required.</li>
          </ul>
        </p>
        <br />
        <p>
          <p>
            <div className="font-weight-bold font-italic text-dark">Why should I document the discussions held?</div>
          </p>
          <span className={'text-muted'}>
            The procedural success or failure of your process is dependent on all meetings, evaluations and
            communications <span className="font-weight-bold font-italic">being documented.</span> Failure to document
            will weaken your incapacity procedure and any defence you might put forward, should the employee decide to
            ultimately challenge a perceived unfair dismissal at the CCMA/Bargaining Council forum. LabourTeq provides
            you with the necessary tool to conveniently and speedily record all performance counselling discussions and
            any subsequent actions taken to address an employee’s poor performance.
          </span>
        </p>
        <br />
        <p>
          <p>
            <div className="font-weight-bold font-italic text-dark">
              What if the informal process does not render the desired results within a reasonable period?
            </div>
          </p>
          <span className={'text-muted'}>
            As a manager, you have a responsibility to begin a formal poor performance process if the employee’s
            performance does not improve with counselling within a reasonable time period. It will depend on what is
            reasonable and fair in the circumstances taking into account factors such as the seriousness and extent of
            the shortfalls, the impact or potential impact of the shortfalls, the seniority and/or level of experience
            of the employee, and how he/she is reacting and taking accountability for improving his/her performance. You
            should not unduly delay progressing the situation and allow poor performance to continue unabated.
          </span>
        </p>
        <br />
        <p>
          <p>
            <div className="font-weight-bold font-italic text-dark">
              What should I do if an employee on probation fails to meet performance standards
            </div>
          </p>
          <span className={'text-muted'}>
            The purpose of a probation period is for the parties to decide whether there is a proper person-job fit.
            Performance shortfalls should be detected early and communicated timeously to the employee on probation. An
            employee’s performance during probation usually serves as a good indication of how he/she will perform in
            the role going forward. Similar counselling and assistance should be offered to the probationary employee as
            with permanent employees, however, you can more easily come to a decision to dismiss as probation is a trial
            period. The lapse of a probation period without proper assessment may result in future performance problems.
            Rather take decisive action before the end of the probation period if the employee is not meeting reasonable
            performance standards. Escalate the matter to an incapacity poor performance hearing before terminating the
            probationary employee and give him/her a chance to state their case.
          </span>
        </p>
        <br />
        <p>
          <p>
            <div className="font-weight-bold font-italic text-dark">
              What about dealing with allegations of poor performance against senior employees
            </div>
          </p>
          <span className={'text-muted'}>
            In certain circumstances, where the risk or impact of the poor performance may have serious implications for
            the employer or where senior employees are found not to be performing, the incapacity process may then be
            expedited. Senior, highly experienced employees are expected to have the ability to self- evaluate and
            self-correct if there are any indications of poor performance on their part. The duty on the employer to
            counsel, correct and/or assist is less onerous and you can more speedily escalate the performance
            improvement process to a hearing and come to a decision to dismiss.
          </span>
        </p>
        <br />
        <p>
          <p>
            <div className="font-weight-bold font-italic text-dark">
              What do I do when an employee discloses a disability and requests reasonable accommodation measures in the
              counselling process?
            </div>
          </p>
          <span className={'text-muted'}>
            During the counselling process, an employee may disclose a previously undisclosed disability, such as a
            mental health condition. As a result, he/she may request some type of accommodation to be put in place.{' '}
          </span>
        </p>
        <p>
          <span className={'text-muted'}>
            In order to be protected, the employee must qualify as a person with a disability as defined in the
            Employment Equity Act, 1998. Check with HR as to whether or not the employee has disclosed his/her status
            previously. If not, you may request medical documentation that supports a condition that substantially
            limits him/her in the workplace.{' '}
          </span>
        </p>
        <p>
          <span className={'text-muted'}>
            Once the employee’s disability status is confirmed, you will need to consider what the employee is
            requesting by way of accommodation and assess whether or not such a request is reasonable in the
            circumstances. He/she may, for example, request some technological assistance to do the work, or flexible
            work arrangements, or even a period of leave.
          </span>
        </p>
        <p>
          <span className={'text-muted'}>
            <strong>
              <i>Always</i>
            </strong>{' '}
            involve your HR or Executive manager in these discussions once the initial disclosure is made and obtain
            professional legal advice, if required.
          </span>
        </p>
        <p>
          <span className={'text-muted'}>
            Failure to provide reasonable accommodations is a form of actionable unfair discrimination. A reasonable
            accommodation is designed to address the limitations so that the employee has as much of a chance to achieve
            acceptable performance levels as a non-disabled person. It may not cause the employer unjustifiable hardship
            to provide reasonable accommodations. Each case is unique.
          </span>
        </p>
        <p>
          <span className={'text-muted'}>
            Keep in mind that a request of this nature does not preclude you from proceeding with performance-based
            actions as long as you duly consider the requests and put appropriate remedial actions in place.
          </span>
        </p>
        <br />
        <p>
          <p>
            <div className="font-weight-bold font-italic text-dark">
              What should I do about an employee who just won’t talk to me?
            </div>
          </p>
          <span className={'text-muted'}>
            The employee remains responsible for his/her performance and for improving it. An employee who fails to
            participate in the counselling process will need to accept the consequences of their unwillingness to
            engage. Ensure that you communicate performance standards and expectations, the consequences if the
            expectations are not met, as well as the potential consequences of his/her unwillingness to engage in the
            process. Be sure to document on LabourTeq all your efforts to communicate, counsel the employee and escalate
            the matter to HR or Executive management timeously if need be. Continue your process in a fair manner.
          </span>
        </p>
      </GuidanceModal>
      <br />
      <Formik
        initialValues={state}
        enableReinitialize
        validationSchema={PreparePerformanceCounsellingSchema}
        onSubmit={submitValues}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <PreparePerformanceCounsellingForm
              data={props.data}
              getFormValuesForSubmission={getFormValuesForSubmission}
            />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default PreparePerformanceCounselling;
