import * as Yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '../fields/FormField.component';
import { CaseType } from '../../API';

export const AddCaseSchema = Yup.object().shape({
  caseNumber: Yup.string().required('Required field'),
  caseType: Yup.string().required('Required field'),
  incidents: Yup.array()
    .of(
      Yup.object().shape({
        summaryOfFacts: Yup.string().test('testSummaryOfFacts', 'Required field', function(value): boolean {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { from } = this;
          return from[1].value.caseType !== CaseType.MISCONDUCT || value;
        }),
        date: Yup.string().test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid() || !val),
        time: Yup.string().test('time', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid() || !val),
        location: Yup.string(),
        complainants: Yup.array().nullable(),
        witnesses: Yup.array().nullable(),
      }),
    )
    .nullable(),
  jobDescriptionOrKPIDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string(),
        key: Yup.string(),
        url: Yup.string(),
      }),
    )
    .nullable(),
  performanceRatingHistory: Yup.string().nullable(),
  jobTitle: Yup.string()
    .nullable()
    .when('caseType', { is: CaseType.POOR_PERFORMANCE, then: Yup.string().required('Required') }),
  performanceShortfalls: Yup.array()
    .of(
      Yup.object().shape({
        shortfallDescription: Yup.string().test('testSummaryOfFacts', 'Required', function(value): boolean {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { from } = this;
          return from[1].value.caseType !== CaseType.POOR_PERFORMANCE || value;
        }),
      }),
    )
    .nullable(),
});

export const RecordSuspensionDiscussionSchema = Yup.object().shape({
  suspensionDiscussionDate: Yup.string().test(
    'date',
    'Date is not valid',
    value => moment(value, DATE_FORMAT).isValid() || !value,
  ),
  suspensionDiscussionTime: Yup.string().test(
    'time',
    'Time is not valid',
    value => moment(value, TIME_FORMAT).isValid() || !value,
  ),
  suspensionDiscussionSummary: Yup.string(),
  suspensionDiscussionAttendees: Yup.array().nullable(),
});

export const SuspensionDecisionSchema = Yup.object().shape({
  suspensionDecision: Yup.boolean().required('Required field'),
  suspensionDecisionReason: Yup.string().required('Required field'),
  suspensionDecisionEffectiveDate: Yup.string().when('suspensionDecision', {
    is: true,
    then: Yup.string()
      .required('Required field')
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid()),
  }),
});

export const DisciplinaryActionSchema = Yup.object().shape({
  isDisciplinaryActionRequired: Yup.boolean().required(),
  motivationForDisciplinaryAction: Yup.string().required('Required field'),
  incidents: Yup.array()
    .of(
      Yup.object().shape({
        summaryOfFacts: Yup.string().test('testSummaryOfFacts', 'Required', function(value): boolean {
          // test allows access to context of parent schema. from[1] gets the value of a sibling (eg isDisciplinaryActionRequired) of the grandparent of this function
          // @ts-ignore
          const { from } = this;
          return !from[1].value.isDisciplinaryActionRequired || value;
        }),
        date: Yup.string().test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid() || !val),
        time: Yup.string().test('time', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid() || !val),
        transgression: Yup.string().test('testTransgression', 'Required', function(value): boolean {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { from } = this;
          return !from[1].value.isDisciplinaryActionRequired || value;
        }),
        transgressionCategory: Yup.string().test('testTransgressionCategory', 'Required', function(value): boolean {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { from } = this;
          return !from[1].value.isDisciplinaryActionRequired || value;
        }),
        frequency: Yup.string().test('testFrequency', 'Required', function(value): boolean {
          // @ts-ignore
          const { from } = this;
          return !from[1].value.isDisciplinaryActionRequired || value;
        }),
        potentialSanction: Yup.string().test('testPotentialSanction', 'Required', function(value): boolean {
          // @ts-ignore
          const { from } = this;
          return !from[1].value.isDisciplinaryActionRequired || value;
        }),
        process: Yup.string().test('testProcess', 'Required', function(value): boolean {
          // @ts-ignore
          const { from } = this;
          return !from[1].value.isDisciplinaryActionRequired || value;
        }),
      }),
    )
    .nullable()
    .when('isDisciplinaryActionRequired', { is: true, then: Yup.array().required('Enter Incidents') }),
});

export const ProceedWithInvestigationSchema = Yup.object().shape({
  appointInvestigator: Yup.boolean().required(),
  investigatorType: Yup.string()
    .nullable()
    .when('appointInvestigator', {
      is: true,
      then: Yup.string().required('Required Field'),
    }),
  investigatorFirstName: Yup.string()
    .nullable()
    .when('appointInvestigator', {
      is: true,
      then: Yup.string().required('Required Field'),
    }),
  investigatorLastName: Yup.string()
    .nullable()
    .when('appointInvestigator', {
      is: true,
      then: Yup.string().required('Required Field'),
    }),
  investigatorEmailAddress: Yup.string()
    .nullable()
    .when('appointInvestigator', {
      is: true,
      then: Yup.string()
        .email('Email is not valid')
        .required('Required Field'),
    }),
  representativeId: Yup.string().nullable(),
  investigatorTimeline: Yup.string()
    .nullable()
    .when('appointInvestigator', {
      is: true,
      then: Yup.string()
        .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
        .required('Date is  required'),
    }),
});

export const CCMADetailsASchema = Yup.object().shape({
  disputeReferredToCCMA: Yup.boolean().required('Required'),

  grantedCondonation: Yup.boolean().required('Required'),
  settledAtConciliation: Yup.boolean().required('Required'),
  lodgedObjection: Yup.boolean().required('Required'),
  disputeReferralFormDocuments: Yup.array()
    .of(
      Yup.object().shape({
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .nullable()
    .when('disputeReferredToCCMA', {
      is: true,
      then: Yup.array().required('Please upload file'),
    }),
  certificateOfNoOutcomeDocuments: Yup.array()
    .of(
      Yup.object().shape({
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .nullable()
    .required('Please upload file'),
  settlementReached: Yup.string()
    .nullable()
    .when('settledAtConciliation', {
      is: true,
      then: Yup.string().required('Required Field'),
    }),
  furtherDetails: Yup.string()
    .nullable()
    .when('settledAtConciliation', {
      is: true,
      then: Yup.string().required('Required Field'),
    }),
  employerRepresentativesAtConciliation: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required('Required'),
        value: Yup.string().required('Required'),
        email: Yup.string().email('Email is not valid'),
      }),
    )
    .nullable()
    .when('settledAtConciliation', {
      is: true,
      then: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string().required('Required'),
            value: Yup.string().required('Required'),
            email: Yup.string().email('Email is not valid'),
          }),
        )
        .nullable()
        .required('Required'),
    }),

  conciliationDate: Yup.string()
    .nullable()
    .when('settledAtConciliation', {
      is: true,
      then: Yup.string()
        .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
        .required('Required'),
    }),
  natureOfDispute: Yup.string().required('Required'),
});

export const CCMADetailsBSchema = Yup.object().shape({
  disputeReferredToCCMA: Yup.boolean().required('Required'),
  arbitrationRulingInFavourOf: Yup.string().when('disputeReferredToCCMA', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  arbitrationRuling: Yup.string().when('disputeReferredToCCMA', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  furtherDetailsArbitration: Yup.string().when('disputeReferredToCCMA', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  CCMAArbitrationRulingDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .nullable()
    .when('disputeReferredToCCMA', {
      is: true,
      then: Yup.array().required('Please upload file'),
    }),
  internalEmployerRepresentativeArbitration: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
        email: Yup.string().email(),
      }),
    )
    .nullable(),
  externalEmployerRepresentativeNameArbitration: Yup.string().when('disputeReferredToCCMA', {
    is: true,
    then: Yup.string().when('internalEmployerRepresentativeArbitration', {
      is: internalEmployerRepresentativeArbitration =>
        !internalEmployerRepresentativeArbitration || !internalEmployerRepresentativeArbitration.length,
      then: Yup.string().required('Required. Enter representative name or select an internal representative'),
    }),
  }),
  arbitrationDate: Yup.string().when('disputeReferredToCCMA', {
    is: true,
    then: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
  }),
  extraArbitrationRulingDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .nullable(),
  disputeReferredToLabourCourt: Yup.boolean().required('Required'),
  labourCourtRulingInFavourOf: Yup.string().when('disputeReferredToLabourCourt', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  labourCourtRuling: Yup.string().when('disputeReferredToLabourCourt', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  furtherDetailsLabourCourt: Yup.string().when('disputeReferredToLabourCourt', {
    is: true,
    then: Yup.string().required('Required'),
  }),
  labourCourtJudgementDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .nullable()
    .when('disputeReferredToLabourCourt', {
      is: true,
      then: Yup.array().required('Please upload file'),
    }),
  extraAdjudicationRulingDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .nullable(),
  internalEmployerRepresentativeAdjudication: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
        email: Yup.string().email('Email is not valid'),
      }),
    )
    .nullable(),
  externalEmployerRepresentativeNameAdjudication: Yup.string().when('disputeReferredToLabourCourt', {
    is: true,
    then: Yup.string().when('internalEmployerRepresentativeAdjudication', {
      is: internalEmployerRepresentativeAdjudication =>
        !internalEmployerRepresentativeAdjudication || !internalEmployerRepresentativeAdjudication.length,
      then: Yup.string().required('Required. Enter representative name or select an internal representative'),
    }),
  }),
  adjudicationDate: Yup.string().when('disputeReferredToLabourCourt', {
    is: true,
    then: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
  }),
});
