import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import ButtonWithIcons from '../buttons/ButtonWIthIcons.component';
import React, { FormEvent, useState } from 'react';
import { CaseClosedIcon } from '../icon/Icon.component';
import { ConfirmCloseCaseModal } from '../../screens/MisconductScreens/ConfirmCloseCaseModal.component';
import { checkforCompletedTaskOnProcessInstance } from '../../utils/flowable/flowable-utils';
import { WorkflowComponentProps } from '../../screens/WorkflowContainer/workflow-utils';
import { PleaseWaitModal } from '../../screens/MisconductScreens/PleaseWaitModal.component';

export interface WorkFlowFooterProps extends WorkflowComponentProps {
  finalPage?: boolean;
  disabled?: boolean;
  backModalText?: string;
  onNext: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  onSaveAndClose: () => void;
  onCancel: () => void;
  hideSaveAndCloseButton?: boolean;
}

export const WorkFlowFooter: React.FC<WorkFlowFooterProps> = props => {
  const [closeCaseModalIsOpen, setCloseCaseModalIsOpen] = useState(false);
  const [pleaseWaitModalIsOpen, setPleaseWaitModalIsOpen] = useState(false);
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);

  const checkCanProceed = async (): Promise<boolean> => {
    if (props.data.taskConfig && props.data.taskConfig.tasksToCheckBeforeProceeding?.length) {
      return await checkforCompletedTaskOnProcessInstance(
        props.data.processInstanceId,
        props.data.taskConfig.tasksToCheckBeforeProceeding,
      );
    }
    return true;
  };

  const handleOnNext = async (): Promise<void> => {
    const canProceed = await checkCanProceed();
    if (canProceed && props.onNext) {
      props.onNext();
    } else {
      setPleaseWaitModalIsOpen(true);
    }
  };

  return (
    <>
      <div className="mt-2 w-auto">
        <hr style={{ border: '0.06em solid #adb5bd' }} />
        <Row className="d-flex justify-content-between">
          <Col className="align-items-start">
            {/*<ButtonWithIcons title="Back" handleClick={props.onBack} disabled={props.disableBackButton} />*/}
            <ButtonWithIcons
              title={props.data.showFirstTaskWarning ? 'Cancel' : 'Back'}
              handleClick={() => {
                if (props.data.showFirstTaskWarning) {
                  setCancelModalIsOpen(true);
                } else {
                  props.data.flowableFunctions.goBack();
                }
              }}
              disabled={props.data.taskConfig?.disableBackButton || props.data.disableFooter}
            />
            {/*<ButtonWithIcons title="Cancel" disabled={true} buttonType="btn-simple" handleClick={props.onCancel} />*/}
          </Col>
          <Col className="text-right">
            {!props.hideSaveAndCloseButton && (
              <ButtonWithIcons
                title="Save & Close"
                buttonType="btn-simple"
                handleClick={props.onSaveAndClose}
                disabled={props.data.disableFooter}
              />
            )}
            {props.finalPage && !props.data.caseData.isCaseClosed ? (
              <ButtonWithIcons
                title={'Close Case'}
                handleClick={(): void => {
                  setCloseCaseModalIsOpen(true);
                }}
                disabled={props.disabled || props.data?.disableFooter}
              />
            ) : (
              <ButtonWithIcons
                title={'Submit and Proceed'}
                handleClick={handleOnNext}
                disabled={props.disabled || props.data.disableFooter}
              />
            )}
          </Col>
        </Row>
      </div>
      {props.data.employee?.id && (
        <ConfirmCloseCaseModal
          closeModal={() => setCloseCaseModalIsOpen(false)}
          employeeId={props.data.employee?.id}
          //@ts-ignore
          onNext={callback => props.onNext(callback)}
          isOpen={closeCaseModalIsOpen}
        />
      )}
      <PleaseWaitModal closeModal={() => setPleaseWaitModalIsOpen(false)} isOpen={pleaseWaitModalIsOpen} />
      <Modal className="p-0" size="md" isOpen={cancelModalIsOpen} centered>
        <ModalBody className="p-0 rounded-0">
          <div className="d-flex justify-content-between px-3 py-2 guidance-modal-header">
            <div className="text-capitalize ml-auto mr-auto font-weight-bold">Cancel Task</div>
          </div>
          <div className="d-flex align-items-center flex-column px-3 py-3 mb-3">
            <div className="text-muted mb-2">
              Are you sure you want to cancel this task? You will lose your progress.
            </div>
            <div className="pl-2">
              <CaseClosedIcon />
            </div>
          </div>
          <div className="d-flex justify-content-between my-2">
            <Button
              className="btn-simple text-uppercase rounded-0 ml-2 font-weight-light"
              title={'Cancel'}
              onClick={(): void => {
                setCancelModalIsOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-default text-uppercase rounded-0 mr-2 font-weight-light"
              title={'Confirm'}
              onClick={(): void => {
                props.data.flowableFunctions.goBack();
                setCancelModalIsOpen(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
