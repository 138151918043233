import {
  appeal_hearing_pp_chairpersonsRuling,
  appeal_hearing_pp_compileNoticeOfHearing,
  appeal_hearing_pp_confirmSanction,
  initial_hearing_pp_chairpersonsRuling,
  initial_hearing_pp_compileNoticeOfHearing,
  initial_hearing_pp_confirmSanction,
  pp_executeOutcomeWrittenWarning,
  pp_startAppeal,
} from './poor-performance-fields';
import { FieldsAndValidationSchema } from '../../screens/MisconductScreens/HearingScreens/hearing-utils';
import {
  appeal_hearing_AppointChairperson,
  appeal_hearing_AppointEmployerRepresentative,
  appeal_hearing_EmployeeRightsAndResponses,
  appeal_hearing_HearingLogistics,
  appeal_hearing_MinutesAndRecording,
  appeal_hearing_SelectWitness,
  initial_hearing_AppointChairperson,
  initial_hearing_AppointEmployerRepresentative,
  initial_hearing_EmployeeRightsAndResponses,
  initial_hearing_HearingLogistics,
  initial_hearing_MinutesAndRecording,
  initial_hearing_SelectWitness,
} from './common-fields';

export const poorPerformanceFieldsMap: { [key: string]: FieldsAndValidationSchema } = {
  'appoint-employer-representative': initial_hearing_AppointEmployerRepresentative,
  'appoint-chairperson': initial_hearing_AppointChairperson,
  'select-witness': initial_hearing_SelectWitness,
  'hearing-logistics': initial_hearing_HearingLogistics,
  'compile-notice-of-hearing': initial_hearing_pp_compileNoticeOfHearing,
  'employee-rights': initial_hearing_EmployeeRightsAndResponses,
  'minutes-and-recording': initial_hearing_MinutesAndRecording,
  'chairpersons-ruling': initial_hearing_pp_chairpersonsRuling,
  'confirm-sanction': initial_hearing_pp_confirmSanction,
  'execute-outcome-written-warning': pp_executeOutcomeWrittenWarning,
  'appeal-process': pp_startAppeal,

  // appeal
  'appoint-employer-representative-appeal': appeal_hearing_AppointEmployerRepresentative,
  'appoint-chairperson-appeal': appeal_hearing_AppointChairperson,
  'select-witness-appeal': appeal_hearing_SelectWitness,
  'hearing-logistics-appeal': appeal_hearing_HearingLogistics,
  'compile-notice-of-hearing-appeal': appeal_hearing_pp_compileNoticeOfHearing,
  'employee-rights-appeal': appeal_hearing_EmployeeRightsAndResponses,
  'minutes-and-recording-appeal': appeal_hearing_MinutesAndRecording,
  'chairpersons-ruling-appeal': appeal_hearing_pp_chairpersonsRuling,
  'confirm-sanction-appeal': appeal_hearing_pp_confirmSanction,
};
