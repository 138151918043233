import React, { useContext } from 'react';
import { Form, Row, Col, FormGroup, Label } from 'reactstrap';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import Tabs from '../../../../../components/HearingAndAppealTabs/HearingAndAppealTabs.component';
import { HEARING_TABS } from '../../../../../utils/case-utils';
import FormField from '../../../../../forms/fields/FormField.component';
import { EmailRecipient, EmailType } from '../../../../../utils/email-utils';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../../../../components/taskContainer/TaskContainer';
import { Tooltip } from '../../../../../components/tooltips/Tooltip.component';
import { UserContext, UserContextProps } from '../../../../../App';
import { FormProps, Witness } from '../../../../WorkflowContainer/workflow-utils';
import { toTitleCase } from '../../../../../utils/string-utils';
import { CaseType } from '../../../../../API';
import { EmailPreviewModalv3 } from '../../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { convertToFlowableVariables } from '../../../../../utils/flowable/flowable-utils';

const AppealHearingLogisticsForm: React.FC<FormProps> = props => {
  const { values }: FormikProps<FormikValues> = useFormikContext();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;

  const getRecipients = (): EmailRecipient[] => {
    const recipients: EmailRecipient[] = [];
    if (values.appeal_hearing_chairpersonEmailAddress && values.appeal_hearing_chairpersonFirstName) {
      recipients.push({
        emailAddress: values.appeal_hearing_chairpersonEmailAddress,
        name: toTitleCase(values.appeal_hearing_chairpersonFirstName, ' '),
      });
    }
    if (
      values.appeal_hearing_employerRepresentativeEmailAddress &&
      values.appeal_hearing_employerRepresentativeFirstName
    ) {
      recipients.push({
        emailAddress: values.appeal_hearing_employerRepresentativeEmailAddress,
        name: toTitleCase(values.appeal_hearing_employerRepresentativeFirstName, ' '),
      });
    }
    if (values.appeal_hearing_hearingWitnesses)
      values.appeal_hearing_hearingWitnesses.forEach((witness: Witness) => {
        if (witness.emailAddress) {
          recipients.push({ emailAddress: witness.emailAddress, name: toTitleCase(witness.firstName, ' ') });
        }
      });
    return recipients;
  };

  return (
    <Form>
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Appeal Hearing preparations</h4>
      <Row>
        <Col md={12}>
          <Tabs page={3} tabs={HEARING_TABS} />
        </Col>
      </Row>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <h5 className="text-h5 text-blue text-capitalize font-weight-500 mt-3">Set Up Appeal Hearing Logistics</h5>
        <span className="m-3">
          <Tooltip
            title={'Appeal'}
            id="hearing"
            text={
              props.data.caseType === 'POOR_PERFORMANCE'
                ? 'It is important to hold the hearing as soon as possible after it has become clear that the employee  is  incapacitated  to  perform  his/her  job.  Failure  to  do  so  could  result  in procedural unfairness arising, unless there is a reasonable ground for the delay. Legally, at least 2 working days’ notice of the hearing should be given to the employee in order to allow him/her to prepare his/her case. A longer period may be required if reasonable in the circumstances of a specific case.'
                : 'It is important to hold the hearing as soon as possible after the event that has given rise to  the  necessity  for  the  hearing  or  after  it  has  come  to  the  attention  of  the  employer. Failure to do so could result in procedural unfairness arising, unless there is a reasonable ground for the delay. Legally, at least 2 working days’ notice of the hearing should be given to the alleged offender in order to allow him/her to prepare his/her case. A longer period may be required if reasonable in the circumstances of a specific case.'
            }
          />
        </span>
      </span>
      <Row className="mt-4 mb-4">
        <Col md={3}>
          <FormGroup>
            <Label for="Date of Hearing" className="text-default text-capitalize">
              Date of Hearing*
            </Label>
            <FormField type={'date'} placeholder="Date of Hearing" name="appeal_hearing_hearingDate" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'appeal_hearing_hearingDate'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="Time of hearing" className="text-default text-capitalize">
              Time of hearing*
            </Label>
            <FormField type={'time'} placeholder="Time of hearing" name="appeal_hearing_hearingTime" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'appeal_hearing_hearingTime'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="Hearing Location" className="text-default text-capitalize">
              Location*
            </Label>
            <FormField
              type={'text'}
              className="text-capitalize"
              placeholder="Hearing Location"
              name="appeal_hearing_hearingLocation"
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'appeal_hearing_hearingLocation'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-4 mb-4">
        <Col md={3}>
          <FormGroup>
            <Label for="Representative First Name" className="text-default text-capitalize">
              Representative First Name
            </Label>
            <FormField
              type={'text'}
              className="text-capitalize"
              placeholder="Representative First Name"
              name="appeal_hearing_employerRepresentativeFirstName"
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'appeal_hearing_employerRepresentativeFirstName'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="Representative Last Name" className="text-default text-capitalize">
              Representative Last Name
            </Label>
            <FormField
              type={'text'}
              className="text-capitalize"
              placeholder="Representative Last Name"
              name="appeal_hearing_employerRepresentativeLastName"
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'appeal_hearing_employerRepresentativeLastName'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="Representative Email Address" className="text-default text-capitalize">
              Representative Email Address
            </Label>
            <FormField
              type={'text'}
              className="text-lowercase"
              placeholder="Representative Email Address"
              name="appeal_hearing_employerRepresentativeEmailAddress"
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'appeal_hearing_employerRepresentativeEmailAddress'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-4 mb-4">
        <Col md={3}>
          <FormGroup>
            <Label for="Chairperson First Name" className="text-default text-capitalize">
              Chairperson First Name
            </Label>
            <FormField
              type={'text'}
              className="text-capitalize"
              placeholder="Chairperson First Name"
              name="appeal_hearing_chairpersonFirstName"
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'appeal_hearing_chairpersonFirstName'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="Chairperson Last Name" className="text-default text-capitalize">
              Chairperson Last Name
            </Label>
            <FormField
              type={'text'}
              className="text-capitalize"
              placeholder="Chairperson Last Name"
              name="appeal_hearing_chairpersonLastName"
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'appeal_hearing_chairpersonLastName'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="Chairperson Email Address" className="text-default text-capitalize">
              Chairperson Email Address
            </Label>
            <FormField
              type={'text'}
              className="text-lowercase"
              placeholder="Chairperson Email Address"
              name="appeal_hearing_chairpersonEmailAddress"
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'appeal_hearing_chairpersonEmailAddress'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
      <FieldArray name="hearingWitnesses">
        {(arrayHelpers: FieldArrayRenderProps) => (
          <>
            {values.appeal_hearing_hearingWitnesses &&
              values.appeal_hearing_hearingWitnesses.length &&
              values.appeal_hearing_hearingWitnesses.map((item: Witness, index: number) => {
                return (
                  <>
                    <Row className="mt-4 mb-4">
                      <Col md={3}>
                        <FormGroup>
                          <Label for="Witness First Name" className="text-default text-capitalize">
                            Witness First Name
                          </Label>
                          <FormField
                            type={'text'}
                            placeholder="First Name"
                            name={`appeal_hearing_hearingWitnesses.${index}.firstName`}
                            className="text-capitalize"
                          />
                          <span className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name={`appeal_hearing_hearingWitnesses.${index}.firstName`}
                            />
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="Witness Last Name" className="text-default text-capitalize">
                            Witness First Name
                          </Label>
                          <FormField
                            type={'text'}
                            placeholder="Last Name"
                            name={`appeal_hearing_hearingWitnesses.${index}.lastName`}
                            className="text-capitalize"
                          />
                          <span className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name={`appeal_hearing_hearingWitnesses.${index}.lastName`}
                            />
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="Witness Email Address" className="text-default text-capitalize">
                            Witness Email Address
                          </Label>
                          <FormField
                            type={'text'}
                            placeholder="Witness Email Address"
                            name={`appeal_hearing_hearingWitnesses.${index}.emailAddress`}
                            className="text-lowercase"
                          />
                          <span className="text-danger">
                            <ErrorMessage
                              className="text-danger"
                              name={`appeal_hearing_hearingWitnesses.${index}.emailAddress`}
                            />
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                );
              })}
          </>
        )}
      </FieldArray>
      <Row>
        <Col>
          <EmailPreviewModalv3
            emailType={
              props.data.caseType === CaseType.POOR_PERFORMANCE
                ? EmailType.INCAPACITY_APPEAL_HEARING_ATTENDEES
                : EmailType.APPEAL_HEARING_ATTENDEES
            }
            processInstanceId={props.data.processInstanceId}
            masterProcessInstanceId={props.data.masterProcessInstanceId}
            currentUserId={currentUser?.id}
            getRecipients={getRecipients}
            buttonText={'Send Calendar Invite'}
            formValues={values}
            disabled={
              readOnly ||
              (values.appeal_hearing_hearingDate &&
                values.appeal_hearing_hearingTime &&
                values.appeal_hearing_hearingLocation) === ''
            }
            getFlowableVariables={() => {
              return convertToFlowableVariables(props.getFormValuesForSubmission(values));
            }}
          />
        </Col>
      </Row>
      {/*<EmailPreviewModal*/}
      {/*  sendStatus={sendingStatus}*/}
      {/*  subject="Details of Hearing"*/}
      {/*  sendFunction={(): void => {*/}
      {/*    const emails = getEmailAddressesOfAttendees();*/}
      {/*    if (emails.length) sendEmails(emails).catch(error => handleError(error));*/}
      {/*  }}*/}
      {/*  modal={modal}*/}
      {/*  toggleModal={toggleModal}*/}
      {/*  toAddress={getEmailAddressesOfAttendees()}*/}
      {/*  fromAdress={currentUser?.email}*/}
      {/*  previewContent={previewContent}*/}
      {/*/>*/}
    </Form>
  );
};

export default AppealHearingLogisticsForm;
