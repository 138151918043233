import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { get, mutate } from '../../utils/graphql-utils';
import { getEmployee, getTemplateDocumentVersion } from '../../graphql/queries';
import { Formik, FormikProps, FormikValues } from 'formik';
import DocumentFields from '../../forms/documentFields/DocumentFields';
import { FieldData } from '../../forms/fields/FieldTypeDefinitions';
import { CaseType, CreateTemplateDocumentVersionInput, LogoPosition, TemplateType } from '../../API';
import { createTemplateDocumentVersion } from '../../graphql/mutations';
import { UserContext, UserContextProps } from '../../App';
import { Document, Page, pdf, PDFViewer, Text } from '@react-pdf/renderer';
import { Storage } from 'aws-amplify';
import { generateNewDocument, getInputFields } from './document-utils';
import { CaseData } from '../WorkflowContainer/workflow-utils';
import { useErrorHandler } from '../../utils/notification-utils';
import Loader from '../../components/loader/Loader';
import { styles } from '../../components/PDF/PDFStyles.style';
import { Employee, TemplateDocumentVersion } from '../../models';
import { cleanFileName } from '../../utils/storage-utils';
import { documentConfigs } from '../../configs/document-configs/document-configs';
import { queryDocumentCreationTasks } from '../../utils/flowable/flowable-utils';

export interface DocumentEditorProps {
  documentId: string | null;
  templateType: TemplateType | null;
  templateLogo?: {
    imageUrl: string;
    position: LogoPosition;
  };
  isDocumentReadOnly: boolean;
  employee: Employee;
  caseData: CaseData;
  masterProcessInstanceId: string;
  completeTask: (variables?: any) => void;
  onCreateNewDocument: (documentId: string, templateType: TemplateType) => Promise<void>;
  clearDocument: () => void;
  showSignedCopy: boolean;
  caseType: CaseType;
}

interface DocumentEditorState {
  documentWithContent: DocumentWithContent | null;
  isSaving: boolean;
  isLoading: boolean;
}

export interface DocumentWithContent {
  document: TemplateDocumentVersion;
  jsonContent: DocumentJSONContent | null;
}

export interface DocumentJSONContent {
  [key: string]: FieldData;
}

const DocumentEditor: React.FC<DocumentEditorProps> = (props: DocumentEditorProps) => {
  const handleError = useErrorHandler();
  const [state, setState] = useState<DocumentEditorState>({
    documentWithContent: null,
    isSaving: false,
    isLoading: false,
  });
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const loadDocument = (id: string): Promise<TemplateDocumentVersion> => {
    return new Promise((resolve, reject) => {
      get(getTemplateDocumentVersion, id)
        .then(res => {
          if (res.data && (res.data as any).getTemplateDocumentVersion) {
            resolve((res.data as any).getTemplateDocumentVersion);
          } else reject(new Error('No data on graphql response'));
        })
        .catch(error => reject(error));
    });
  };

  const loadEmployee = (id: string): Promise<Employee> => {
    return new Promise((resolve, reject) => {
      get(getEmployee, id)
        .then(res => {
          if (res.data && (res.data as any).getEmployee) {
            resolve((res.data as any).getEmployee);
          } else reject(new Error('No data on graphql response'));
        })
        .catch(error => reject(error));
    });
  };

  const updatePDF = (values: DocumentJSONContent): void => {
    setState(oldState => {
      if (oldState.documentWithContent) {
        return {
          ...oldState,
          documentWithContent: {
            document: { ...oldState.documentWithContent?.document, stringifiedContent: JSON.stringify(values) },
            jsonContent: values,
          },
        };
      }
      return { ...oldState, documentContent: oldState.documentWithContent };
    });
  };

  const getDocumentJSXForPDFViewer = (content: DocumentJSONContent, templateType: TemplateType): JSX.Element => {
    if (content) {
      const component = documentConfigs[templateType]?.component({ content: content, logo: props.templateLogo });
      if (component) {
        return component;
      } else {
        return (
          <Document>
            <Page style={styles.body} size="A4" wrap>
              <Text style={styles.title}>Error: Could not find document template</Text>
            </Page>
          </Document>
        );
      }
    } else {
      return <div>Could not load document</div>;
    }
  };

  const savePdfToBucket = async (documentWithContent: DocumentWithContent): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      if (
        documentWithContent?.jsonContent &&
        documentWithContent.document.templateType &&
        documentWithContent.document.version
      ) {
        const masterProcessInstanceId = documentWithContent.document.processInstanceId;
        const fileName =
          documentWithContent.document.templateType.toString() + '_v' + documentWithContent.document.version.toString();
        const pdfDocumentJSX = getDocumentJSXForPDFViewer(
          documentWithContent.jsonContent,
          documentWithContent.document.templateType as TemplateType,
        );
        if (pdfDocumentJSX) {
          pdf(pdfDocumentJSX)
            .toBlob()
            .then(async blob => {
              const cleanedFileName = cleanFileName(fileName);
              const file = new File([blob], cleanedFileName);
              await Storage.put(`cases/${masterProcessInstanceId}/${cleanedFileName}.pdf`, file, {
                level: 'public',
              })
                .then((response: Record<string, any>) => {
                  resolve(response.key);
                })
                .catch(error => reject(error));
            });
        } else reject(new Error('Could not generate pdf view'));
      } else {
        reject(new Error('No document present.'));
      }
    });
  };

  const getVersionNumberForNewDocumentFromFlowable = async (
    masterProcessInstanceId: string,
    templateType: TemplateType | keyof typeof TemplateType,
  ): Promise<number> => {
    const documentCreationTasks = await queryDocumentCreationTasks(masterProcessInstanceId, templateType);

    return documentCreationTasks.length ? documentCreationTasks.length + 1 : 1;
  };

  const goBack = () => {
    props.clearDocument();
  };

  const onSave = async (jsonContent: DocumentJSONContent): Promise<void> => {
    setState(oldState => ({ ...oldState, isSaving: true }));
    const documentWithJSONContent = state.documentWithContent;

    const isUnsavedChanges = true;
    if (documentWithJSONContent) {
      if (isUnsavedChanges) {
        const pid = documentWithJSONContent?.document.processInstanceId;
        const templateType = documentWithJSONContent?.document.templateType;
        let version: number | null = null;
        if (pid && templateType) {
          await getVersionNumberForNewDocumentFromFlowable(pid, templateType)
            .then((res: number) => (version = res))
            .catch(error => handleError(error));
        }
        if (version) {
          const docWithContent: DocumentWithContent = {
            document: {
              ...documentWithJSONContent.document,
              version: version,
              stringifiedContent: JSON.stringify(jsonContent),
              signedCopy: undefined,
            },
            jsonContent: documentWithJSONContent.jsonContent,
          };
          savePdfToBucket(docWithContent)
            .then(res => {
              const doc: CreateTemplateDocumentVersionInput = {
                ...docWithContent.document,
                id: undefined,
                parentID: docWithContent.document.id,
                bucketPath: res,
                // @ts-ignore
                templateType: TemplateType[docWithContent.document.templateType],
              };

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              delete doc.createdAt;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              delete doc.updatedAt;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              delete doc.auditLogs;
              mutate(createTemplateDocumentVersion, doc)
                .then(async res => {
                  if (res.data && (res.data as any).createTemplateDocumentVersion) {
                    const newDocument = (res.data as any).createTemplateDocumentVersion;
                    setState(oldState => ({
                      ...oldState,
                      // isSaving: false,
                      documentWithContent: newDocument,
                    }));
                    props
                      .onCreateNewDocument(newDocument.id, newDocument.templateType)
                      .then(() => goBack())
                      .catch(error => {
                        handleError(error);
                        setState(oldState => ({ ...oldState, isSaving: false }));
                      });
                  }
                })
                .catch(error => {
                  handleError(error);
                  setState(oldState => ({ ...oldState, isSaving: false }));
                  return;
                });
            })
            .catch(error => {
              setState(oldState => ({ ...oldState, isSaving: false }));
              handleError(error);
            });
        } else {
          const error = new Error('Could not generate new version number');
          handleError(error);
          setState(oldState => ({ ...oldState, isSaving: false }));
        }
      }
    } else {
      const error = new Error('No document');
      handleError(error);
      setState(oldState => ({ ...oldState, isSaving: false }));
    }
  };

  useEffect(() => {
    // edit existing document
    if (props.documentId) {
      setState(oldState => ({ ...oldState, isLoading: true }));
      loadDocument(props.documentId)
        .then((res: TemplateDocumentVersion) =>
          setState(oldState => ({
            ...oldState,
            isLoading: false,
            documentWithContent: {
              document: res,
              jsonContent: res.stringifiedContent ? JSON.parse(res.stringifiedContent) : null,
            },
          })),
        )
        .catch(error => {
          setState(oldState => ({ ...oldState, isLoading: false }));
          handleError(error);
        });
      // create new document
    } else if (props.templateType) {
      setState(oldState => ({ ...oldState, isLoading: true }));
      loadEmployee(props.employee?.id)
        .then(employee => {
          if (props.templateType && props.masterProcessInstanceId && currentUser) {
            const inputFields: Record<string, unknown> | null = getInputFields(
              props.templateType,
              props.caseData,
              employee,
              currentUser,
              props.caseType,
            );
            if (inputFields && props.caseData.organisationId) {
              const newDocument: DocumentWithContent = generateNewDocument(
                props.templateType,
                props.masterProcessInstanceId,
                inputFields,
                props.caseData.organisationId,
              );
              setState(oldState => ({ ...oldState, documentWithContent: newDocument, isLoading: false }));
            } else {
              const error = new Error('could not generate inputFields for ' + props.templateType);
              handleError(error);
            }
          } else {
            const error = new Error('No current user or missing template type or PI type.');
            handleError(error);
            setState(oldState => ({ ...oldState, isSaving: false }));
          }
        })
        .catch(error => {
          setState(oldState => ({ ...oldState, isLoading: false }));
          handleError(error);
        });
    } else {
      const error = new Error('Could not resolve document id from url parameters.');
      handleError(error);
    }
  }, []);

  const getDocumentTitleFromTemplateType = (
    templateType: TemplateType | keyof typeof TemplateType | undefined | null,
  ): string => {
    if (!templateType) return 'Document';
    const title = documentConfigs[templateType].name;
    return title || 'Prepare Document';
  };

  const getDoc = () => {
    console.log({ state });
    if (props.showSignedCopy && state.documentWithContent?.document.signedCopy) {
      return getDocumentJSXForPDFViewer(
        JSON.parse(state.documentWithContent.document.signedCopy),
        state.documentWithContent.document.templateType as TemplateType,
      );
    } else if (state.documentWithContent?.jsonContent) {
      return getDocumentJSXForPDFViewer(
        state.documentWithContent.jsonContent,
        state.documentWithContent.document.templateType as TemplateType,
      );
    }
  };

  return (
    <>
      <div className="content mt-4">
        {!(state.documentWithContent && state.documentWithContent.jsonContent) ? (
          <div className="d-flex justify-content-center mt-5">
            <Loader />
          </div>
        ) : (
          <div>
            <Formik
              initialValues={{ ...state.documentWithContent.jsonContent }}
              onSubmit={(values: DocumentJSONContent): void => {
                updatePDF(values);
              }}
            >
              {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
                <DocumentFields
                  title={getDocumentTitleFromTemplateType(state.documentWithContent?.document?.templateType)}
                  saveDocument={onSave}
                  goBack={goBack}
                  isSaving={state.isSaving}
                  isDocumentReadOnly={props.isDocumentReadOnly}
                />
              )}
            </Formik>
            {state.documentWithContent && <PDFViewer style={{ width: '100%', height: '100vh' }}>{getDoc()}</PDFViewer>}
          </div>
        )}
      </div>
    </>
  );
};
export default DocumentEditor;
