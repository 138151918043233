import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import PrepareEmployeeDiscussionForm from './PrepareEmployeeDiscussionForm';

import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../components/workflowFooter/WorkFlowFooter';
import { EmailAddressType, UpdateEmployeeInput } from '../../../../API';
import { get } from '../../../../utils/graphql-utils';
import { getEmployee } from '../../../../graphql/queries';
import GuidanceModal from '../../../../components/guidanceModals/GuidanceModal.component';
import { PrepareEmployeeDiscussionSchema } from '../../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import { toTitleCase } from '../../../../utils/string-utils';

const PrepareEmployeeDiscussion: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { flowableFunctions, processInstanceId, caseData } = props.data;
  const [employeeEmail, setEmployeeEmail] = useState('');
  const [employeeFullName, setEmployeeFullName] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const fields = {
    compileInvite: 'boolean',
    employeeDiscussionDate: 'string',
    employeeDiscussionTime: 'string',
    employeeDiscussionAttendees: 'array',
    employeeDiscussionLocation: 'string',
  };

  const [data] = useState(() => getFormDataFromCaseData(fields, caseData, caseData.isAppeal));

  const getFormValuesForSubmission = (values: FormikValues): Record<string, any> => {
    const formValues = { ...values };
    if (formValues.employeeDiscussionAttendees) {
      formValues.employeeDiscussionAttendees = values.employeeDiscussionAttendees.map(
        (attendee: { [key: string]: string }) => {
          return {
            attendeeName: attendee.attendeeName,
            attendeeEmailAddress: attendee.attendeeEmailAddress
              ? attendee.attendeeEmailAddress
              : 'noEmail@labourteq.co.za',
            attendeeId: attendee.attendeeId,
          };
        },
      );
    }
    formValues.employeeDiscussionAttendees = JSON.stringify(formValues.employeeDiscussionAttendees);
    formValues.employeeDiscussionDocuments = JSON.stringify(formValues.employeeDiscussionDocuments);
    return filterFormData(formValues, fields);
  };

  const submitValues = (values: FormikValues): void => {
    flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  const getEmployeeEmail = (employee: UpdateEmployeeInput): string | null => {
    let emailAddress: string | undefined | null = null;
    if (employee?.emails) {
      employee.emails.forEach(item => {
        if (item?.emailAddressType === EmailAddressType.WORK) {
          emailAddress = item.address;
        }
      });
    }
    return emailAddress;
  };

  useEffect(() => {
    get(getEmployee, props.data.caseData.employeeId).then(res => {
      if (res.data && (res.data as any).getEmployee) {
        const employee: UpdateEmployeeInput = (res.data as any).getEmployee;
        const email = getEmployeeEmail(employee);
        setEmployeeFullName(toTitleCase(employee.firstName! + ' ' + employee.lastName!, ' '));
        if (email) setEmployeeEmail(email);
      }
    });
  }, []);

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Prepare for Employee Discussion</h4>
      <GuidanceModal
        buttonTitle="Employee Discussion"
        title="Guidance: Employee Discussion"
        isOpen={modalIsOpen}
        setModal={setModalIsOpen}
      >
        <p>
          <div className="font-weight-bold font-italic text-dark">What is the purpose of an employee discussion?</div>

          <p>
            <span className="text-muted">
              It is legally incumbent on an employer to share information regarding the perceived performance shortfalls
              with the employee. The discussion should describe specific instances of performance shortfalls, and give
              the employee the opportunity to provide input as to the <span className="font-weight-bold">reasons</span>{' '}
              for the poor performance before deciding on an appropriate process. Failure to conduct this step places
              you at risk of conducting a procedurally unfair process.
            </span>
          </p>
          <p>
            <span className="text-muted">
              The discussion is typically conducted by the employee’s direct manager, who may sometimes elect to have HR
              present.
            </span>
          </p>
          <p>
            <span className="text-muted">
              The purpose of this meeting is to assist you in deciding on the appropriate way forward by answering the
              following question in conjunction with the employee:{' '}
              <span className="font-italic">
                Is this an employee who ‘won’t do’ OR ‘can’t do’ what the job requires of him/her?
              </span>
            </span>
          </p>
          <p>
            <span className="text-muted">
              Make sure that you ask the employee what he/she thinks may be causing the poor performance. Indicate that
              you will communicate a way forward after assessing his/her responses and all relevant issues.
            </span>
          </p>
          <p>
            <span className="text-muted">
              Bear in mind that often the initial employee discussion also ends up serving the purpose of a performance
              counselling session. This is perfectly acceptable, as long as you have clearly communicated shortfalls,
              given the employee an opportunity to provide their input, and set clear expectations for performance going
              forward.
            </span>
          </p>
          <br />
          <p>
            <div className="font-weight-bold font-italic text-dark">
              What process should be followed in the employee discussion?
            </div>

            <span className="text-muted">The following serves as guideline: </span>
            <ul className="px-3">
              <li className="text-muted">
                • Request to meet with the employee in a meeting room or suitable space, preferably one as private as
                possible so that the discussion is not overheard by other employees;
              </li>
              <li className="text-muted">
                • Allow adequate time for your feedback as well as the employee’s responses;
              </li>
              <li className="text-muted">
                • Prepare adequately for the discussion - know the specifics of the alleged shortfalls, examples of
                incidents and how you want the behaviour to be remedied in future;
              </li>
              <li className="text-muted">
                • Clearly state the performance standards / expectations required by the job, and seek confirmation that
                the employee understands these expectations;
              </li>
              <li className="text-muted">
                • If the expectations are unclear, you may need to re-communicate and/or document them;
              </li>
              <li className="text-muted">
                • Indicate areas of concerns citing specific examples of performance shortfalls;
              </li>
              <li className="text-muted">
                • Focus on the poor performance, not personalities or other extraneous issues;
              </li>
              <li className="text-muted">
                • Ask him/her what he/she thinks the reasons are for the shortfalls and contributing factors;
              </li>
              <li className="text-muted">• Give the employee an opportunity to respond;</li>
              <li className="text-muted">
                • Listen to factors the employee may raise impacting on his/her performance and respond appropriately;
              </li>
              <li className="text-muted">• Ascertain what actions are required from you to assist;</li>
              <li className="text-muted">• Remain calm and professional;</li>
              <li className="text-muted">• Seek cooperation and resolution, rather than confrontation;</li>
              <li className="text-muted">
                • Apply your mind to the factors and/or reasons put forward by the employee and whether that confirms
                your assessment that the employee is unable to perform his/her job;
              </li>
              <li className="text-muted">
                • Indicate that you will consider the submissions made in the discussion and advise on a way forward;
              </li>
              <li className="text-muted">• LabourTeq allows you to record the discussion held;</li>
              <li className="text-muted">
                • Bear in mind that the session is confidential and should not be discussed with other employees;
              </li>
              <li className="text-muted">• Escalate the matter to HR or Executive management, as required.</li>
            </ul>
          </p>
          <br />
          <p>
            <div className="font-weight-bold font-italic text-dark">
              What decision must be taken following this discussion?
            </div>

            <span className="text-muted">
              Based on the discussion held, analyse which one of the following is the cause of, or contributing
              factor/s, for the failure of the employee to perform to the required performance standard(s):{' '}
            </span>
            <ul className="px-3">
              <li className="text-muted">• An intentional or negligent failure on the side of the employee;</li>
              <li className="text-muted">• A lack of knowledge, skills, abilities, attitudes (competencies); and/or</li>
              <li className="text-muted">
                • An inherent material problem with the job design, work methods and procedures, insufficient technology
                or defective machinery; and/or
              </li>
              <li className="text-muted">• External market/economic factors; and/or</li>
              <li className="text-muted">
                • Other factors, such as personal circumstances, ill health concerns or disability.
              </li>
            </ul>
          </p>
          <p>
            <span className="text-muted">
              If the underlying reason relates to misconduct (i.e. the employee is at fault), the disciplinary procedure
              should be instituted.
            </span>
          </p>
          <p>
            <span className="text-muted">
              If the underlying reason relates to ‘an inability’ or shortcomings on the side of the employee (i.e.
              competency shortcomings) the incapacity poor performance process should be initiated. You need to decide
              if performance counselling suffices, or whether a formal Performance Improvement Plan (PIP) needs to be
              drafted. The seriousness and nature of the performance shortfalls will determine this. In some instances,
              an incapacity poor performance hearing may even be required if the shortfalls are particularly serious
              and/or have had a serious negative impact and/or in the case of senior resources who should self-assess
              their shortfalls.
            </span>
          </p>
          <p>
            <span className="text-muted">
              If the underlying reason is of a medical nature, an incapacity process due to ill-health must be pursued.
              In case of a person with a disability, implementation of proper reasonable accommodation measures may be
              required.
            </span>
          </p>
          <p>
            <span className="text-muted">
              In some instances, the employer may need to assess its operational needs and economic factors impacting on
              its business, necessitating a restructuring or redesign (which could potentially lead to retrenchments).
            </span>
          </p>
          <p>
            <span className="text-muted">
              No further action should be taken against the employee based on incapacity if the issue is beyond their
              control. However, failure to previously report, for example, defective equipment may be ground for
              disciplinary action. Remedial actions must be instituted by management to address factors identified such
              as lack of resources, equipment or flawed work methods.
            </span>
          </p>
        </p>
      </GuidanceModal>
      <br />
      <Formik
        initialValues={data}
        enableReinitialize
        validationSchema={PrepareEmployeeDiscussionSchema}
        onSubmit={submitValues}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <PrepareEmployeeDiscussionForm
              data={props.data}
              employeeEmail={employeeEmail}
              employeeFullName={employeeFullName}
              processInstanceId={processInstanceId}
              employeeNumber={props.data.employeeNumber}
              caseData={props.data.caseData}
              employeeDiscussionDate={values ? values.employeeDiscussionDate : ''}
              employeeDiscussionTime={values ? values.employeeDiscussionTime : ''}
              getFormValuesForSubmission={getFormValuesForSubmission}
            />
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default PrepareEmployeeDiscussion;
