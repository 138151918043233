import React, { useContext, useState } from 'react';
import { Card, CardText, CardTitle, Tooltip as ToolTipComponent } from 'reactstrap';
import { InfoIcon } from '../icon/Icon.component';
import { UserContext, UserContextProps } from '../../App';
import { FontPreference } from '../../API';

interface TooltipProps {
  title?: string;
  text?: string;
  footer?: string;
  id: string;
  jsx?: JSX.Element;
  style?: object;
  className?: string;
}

export const Tooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <span id={props.id} className={props.className} style={props.style}>
        <InfoIcon />
      </span>
      <ToolTipComponent
        id="tooltip"
        placement="right"
        isOpen={isOpen}
        target={props.id}
        toggle={toggle}
        style={{
          width: 'auto',
          minWidth: window.screen.width / 2.2,
          border: '0.06em solid rgb(173, 181, 189)',
          borderRadius: '5px',
          margin: 0,
          padding: 0,
          opacity: 1.25,
          zIndex: 1000,
          maxHeight: '100vh',
          height: 'auto',
          fontFamily:
            currentUser?.fontPreference === FontPreference.DYSLEXIC
              ? 'OpenDyslexic, OpenDyslexic Mono Regular, OpenDyslexic Alta Regular, OpenDyslexic Bold, serif'
              : '',
        }}
      >
        <Card
          className="text-justify h-100 m-0 p-0 shadow-lg"
          style={{ lineHeight: '2.0', opacity: 1, background: '#fff', zIndex: 1000 }}
        >
          {props.title && (
            <CardTitle
              className="d-flex p-1 navbar text-center text-uppercase"
              style={{ color: '#ffffff', justifyContent: 'center' }}
            >
              {props.title}
            </CardTitle>
          )}
          <CardText
            className="text-left px-4 mb-3"
            style={{
              whiteSpace: 'pre-wrap',
              alignContent: 'flex-start',
              opacity: 1,
              overflow: 'auto',
              marginBottom: 15,
            }}
          >
            {props.jsx ? props.jsx : props.text}
          </CardText>
          {props.footer && <div className="text-left px-4">{props.footer}</div>}
        </Card>
      </ToolTipComponent>
    </>
  );
};
