import React from 'react';

export const NoMatch: React.FC = props => {
  return (
    <div className="content">
      <br />
      <br />
      <br />
      <div className="text-primary text-center">404 - Page Not Found</div>
    </div>
  );
};
