import React, { useContext, useEffect } from 'react';
import { ErrorMessage, Field, FieldArray, FieldAttributes, FieldProps, FormikValues, useFormikContext, } from 'formik';
import { Col, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import Select, { ValueType } from 'react-select';
import AsyncSelect from 'react-select/async';
import { CaseIncident, CasePerformanceShortfall, FormProps, } from '../../../../WorkflowContainer/workflow-utils';
import {
    selectStyles,
    SingleValue,
    tableFieldSelectStyles,
    ValueContainer,
} from '../../../../../components/reactSelect/ReactSelectComponents.component';
import FormField from '../../../../../forms/fields/FormField.component';
import { toTitleCase } from '../../../../../utils/string-utils';
import {
    chairpersonsFindingsOptions,
    pp_sanctionOptionsFirstTime,
    pp_sanctionOptionsSecondTime,
} from './sanction-options';
import { EmailPreviewModalv3 } from '../../../../../components/EmailPreviewModal/EmailPreviewModalv3';
import { UserContext, UserContextProps } from '../../../../../App';
import { ReadOnlyContext, ReadOnlyContextProps, } from '../../../../../components/taskContainer/TaskContainer';
import { EmailParamsV2, EmailRecipient, EmailType, } from '../../../../../utils/email-utils';
import { AxiosResponse } from 'axios';
import { appointHcApprover } from '../../../../../utils/user-utils';
import { loadOptionsHCApprover } from '../../../../../utils/employee-user-selector';
import { getAWSCache } from '../../../../../utils/storage-utils';
import TableFormFieldWithReadOnlyOption from '../../../../../forms/fields/TableFormFieldWithReadOnlyOption';
import TableFormField from '../../../../../forms/fields/TableFormField';

const shortfallColumns = [
    'Performance Shortfalls',
    'Date',
    'Ruling on Guilt',
    'Sanction',
];

const hcApproverColumns = [
    'Performance Shortfalls',
    'Chairperson Ruling on Guilt',
    'HC Approver Ruling on Guilt',
    'HC Motivation for Deviation (Guilt)',
    'Chairperson Sanction',
    'HC Approver Sanction',
    'HC Motivation for Deviation (Sanction)',
];

const InitialHearingConfirmOverallSanctionFormPerformance: React.FC<FormProps> = props => {
    const { setFieldValue } = useFormikContext();
    const { values } = useFormikContext<FormikValues>();
    const { caseData, employeeFullName } = props.data;
    const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
    const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;
    const [emailSent, setEmailSent] = React.useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignores
    const isUserAdmin =
        ['SUPER_ADMIN', 'COMPANY_ADMIN', 'HR_MANAGER', 'LINE_MANAGER', 'LINE_EXECUTIVE'].some(role =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignores
            currentUser?.userRoles?.includes(role),
        ) && props.data?.taskConfig?.name === 'Confirm sanction';

    let showHCApp = isUserAdmin && props.data?.organisation?.useHCApprover;
    if (isUserAdmin && !props.data?.organisation?.useHCApprover) {
        showHCApp = false;
    }
    if (!isUserAdmin) {
        showHCApp = true;
    }

    const handleChange = (item: {
        value: string;
        label: string;
        employeeId: string;
        userId: string;
        emailAddress: string;
        firstName: string;
        lastName: string;
    }): void => {
        let performanceShortfalls = values.performanceShortfalls || [];
        console.log(values);
        if (!performanceShortfalls[0]) {
            performanceShortfalls[0] = {};
        } else {
            performanceShortfalls = [...performanceShortfalls];
            performanceShortfalls[0] = { ...performanceShortfalls[0] };
        }

        performanceShortfalls[0].selectedHCEmailApprover = {
            label: item?.label,
            value: item?.value,
        };

        // const firstItem = performanceShortfalls[0];

        // setFieldValue('initial_hearing_hearingDate', firstItem.date || '');
        setFieldValue('performanceShortfalls', performanceShortfalls);
        setFieldValue('hc_approverFirstName', item.firstName);
        setFieldValue('hc_approverLastName', item.lastName);
        setFieldValue('hc_approverEmailAddress', item.emailAddress);
        setFieldValue('hc_approverUserId', item.userId);
        setFieldValue('hc_approverEmployeeId', item.employeeId);
        setFieldValue('hc_approverId', item.value);
        setFieldValue('addresseeFirstName', item.firstName);
        setFieldValue('employeeFullName', employeeFullName);
        setFieldValue(
            'contactName',
            `${ toTitleCase(currentUser?.firstName || '') } ${ toTitleCase(currentUser?.lastName || '') }`,
        );
        setFieldValue('initial_hearing_chairpersonFirstName', item.firstName);
        setFieldValue('initial_hearing_chairpersonLastName', item.lastName);
        setFieldValue('initial_hearing_chairpersonEmailAddress', item.emailAddress);
        setFieldValue('initial_hearing_chairpersonUserId', item.userId);
        setFieldValue('initial_hearing_chairpersonEmployeeId', item.employeeId);
        setFieldValue('initial_hearing_chairpersonId', item.value);
        setFieldValue('HCApproverID', item.userId);
    };

    const formatOption = (str: string): { label: string; value: string } => ({
        label: toTitleCase(str, '_'),
        value: str,
    });

    const getPPSanctionOptions = (): { label: string; value: string }[] => {
        const options =
            caseData.hasCompletedExtendedPIP || caseData.hasCompletedFinalWrittenWarningPIP
                ? pp_sanctionOptionsSecondTime
                : pp_sanctionOptionsFirstTime;
        return options.map(formatOption);
    };

    const canSelectOverallSanction = (): boolean => {
        return values.performanceShortfalls.some(
            (i: CaseIncident) => i.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
        );
    };

    const canSelectOverallSanctionHC = (): boolean => {
        return values.performanceShortfalls.some(
            (i: CaseIncident) => i.initial_hearing_hcApproversFindingOnGuilt === 'GUILTY',
        );
    };

    const getOptionsForOverallSanction = (): { label: string; value: string }[] => {
        if (canSelectOverallSanction()) {
            return getPPSanctionOptions().filter((s) => s.value !== 'NOT_APPLICABLE');
        } else {
            return [formatOption('NOT_APPLICABLE')];
        }
    };

    const getOptionsForOverallSanctionHC = (): { label: string; value: string }[] => {
        if (canSelectOverallSanctionHC()) {
            return getPPSanctionOptions().filter((s) => s.value !== 'NOT_APPLICABLE');
        } else {
            return [formatOption('NOT_APPLICABLE')];
        }
    };

    const getRecipients = (): EmailRecipient[] => {
        return [
            {
                emailAddress: values.hc_approverEmailAddress,
                name: `${ toTitleCase(values.hc_approverFirstName || '') } ${ toTitleCase(
                    values.hc_approverLastName || '',
                ) }`,
            },
        ];
    };

    const saveCacheData = async (vals: any) => {
        const data = {
            action: 'hcApproverSet',
            currentTaskId: `${ caseData.masterProcessInstanceId }-step9`,
            data: {
                performanceShortfalls: vals?.performanceShortfalls,
                overallSanctionOld: vals?.overallSanctionOld,
            },
        };
        await getAWSCache(data);
    };

    const sendEmail = (): Promise<AxiosResponse> => {
        const valuesWithoutPerformanceShortfalls = { ...values };
        delete valuesWithoutPerformanceShortfalls.performanceShortfalls;

        if (!caseData?.organisationId) {
            throw new Error('No current user with organisation id');
        }
        if (!currentUser?.id) {
            throw new Error('No current user id');
        }

        const params: EmailParamsV2 = {
            attachmentBucketKeys: [],
            currentUserId: currentUser.id,
            emailType: EmailType.HC_APPROVER,
            formValues: values,
            masterProcessInstanceId: props.data.masterProcessInstanceId,
            processInstanceId: props.data.processInstanceId,
            recipients: getRecipients(),
        };
        setEmailSent(!emailSent);

        saveCacheData(values);

        return appointHcApprover(
            params,
            props.getFormValuesForSubmission(valuesWithoutPerformanceShortfalls),
            values.initial_hearing_chairpersonEmailAddress,
            values.initial_hearing_chairpersonFirstName,
            values.initial_hearing_chairpersonLastName,
            values.initial_hearing_chairpersonEmployeeId,
            values.initial_hearing_chairpersonType === 'internal' ? caseData.organisationId : undefined,
        );
    };

    useEffect(() => {
        if (!values.initial_hearing_chairpersonType) setFieldValue('initial_hearing_chairpersonType', 'internal');
    }, [values.initial_hearing_chairpersonType]);

    useEffect(() => {
        const fetchData = async () => {
            const data = {
                action: 'hcApproverGet',
                currentTaskId: `${caseData.masterProcessInstanceId}-step9`,
            };
            const getCache = await getAWSCache(data);

            if (getCache?.body?.performanceShortfalls?.length > 0) {
                const updatedShortfalls = values.performanceShortfalls.map((shortfall: any, index: React.ReactText) => ({
                    ...shortfall,
                    ...(getCache.body.performanceShortfalls[index] || {}),
                }));

                setFieldValue('performanceShortfalls', updatedShortfalls);
                setFieldValue('overallSanctionOld', getCache?.body?.overallSanctionOld);
            } else {
                setFieldValue('overallSanctionOld', values.overallSanction);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        values.performanceShortfalls.forEach((item: { initial_hearing_chairpersonsSanction: string, initial_hearing_chairpersonsFindingOnGuilt: string; }, index: any) => {
            if (item.initial_hearing_chairpersonsFindingOnGuilt === 'NOT_GUILTY') {
                setFieldValue(
                    `performanceShortfalls.${index}.initial_hearing_chairpersonsSanction`,
                    'NOT_APPLICABLE'
                );
            }
            if (item.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY') {
                if (item.initial_hearing_chairpersonsSanction === 'NOT_APPLICABLE') {
                    setFieldValue(
                        `performanceShortfalls.${index}.initial_hearing_chairpersonsSanction`,
                        ''
                    );
                }
            }
        });
    }, [JSON.stringify(values.performanceShortfalls)]);

    useEffect(() => {
        if (
            !values.performanceShortfalls.some(
                (shortfall: CasePerformanceShortfall) => shortfall.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
            )
        ) {
            setFieldValue('overallSanction', 'NOT_APPLICABLE');
        }
    }, [JSON.stringify(values.performanceShortfalls)]);

    return (
        <Form>
            <>
                {/* Chairperson's Findings */ }
                <Row>
                    <Col>
                        <div>
                            <Row>
                                <Col>
                                    <Table className="table-responsive-lg" bordered>
                                        <thead>
                                        <tr>
                                            { shortfallColumns.map((column) => (
                                                <th key={ column }>
                                                        <span className="text-blue font-weight-500 text-capitalize">
                                                            { column }
                                                        </span>
                                                </th>
                                            )) }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <FieldArray name="performanceShortfalls">
                                            { () => (
                                                <>
                                                    { !values.performanceShortfalls &&
                                                        setFieldValue('performanceShortfalls', [{}]) }
                                                    { values.performanceShortfalls &&
                                                        values.performanceShortfalls.length &&
                                                        values.performanceShortfalls.map(
                                                            (item: CasePerformanceShortfall, index: number) => (
                                                                <tr key={ index }>
                                                                    <td className="position-relative"
                                                                        style={ { minWidth: '150px' } }>
                                                                        <span> { item.shortfallDescription }</span>
                                                                    </td>
                                                                    <td className="position-relative"
                                                                        style={ { minWidth: '100px' } }>
                                                                        <span> { item.date }</span>
                                                                    </td>
                                                                    <td className="position-relative"
                                                                        style={ { minWidth: '150px' } }>
                                                                        <TableFormField
                                                                            type={ 'select' }
                                                                            placeholder={ 'Select Ruling' }
                                                                            name={ `performanceShortfalls.${ index }.initial_hearing_chairpersonsFindingOnGuilt` }
                                                                            selectOptions={ chairpersonsFindingsOptions.map(formatOption) }
                                                                        />
                                                                        <span className="text-danger">
                                                                                <ErrorMessage
                                                                                    className="text-danger"
                                                                                    name={ `performanceShortfalls.${ index }.initial_hearing_chairpersonsFindingOnGuilt` }
                                                                                />
                                                                            </span>
                                                                    </td>
                                                                    <td className="position-relative"
                                                                        style={ { minWidth: '150px' } }>
                                                                        { !!item.initial_hearing_chairpersonsFindingOnGuilt?.length && (
                                                                            <TableFormField
                                                                                disabled={ item.initial_hearing_chairpersonsFindingOnGuilt === 'NOT_GUILTY' }
                                                                                type={ 'select' }
                                                                                placeholder={ 'Select Sanction' }
                                                                                name={ `performanceShortfalls.${ index }.initial_hearing_chairpersonsSanction` }
                                                                                selectOptions={ getPPSanctionOptions() }
                                                                            />
                                                                        ) }
                                                                        <span className="text-danger">
                                                                                <ErrorMessage
                                                                                    className="text-danger"
                                                                                    name={ `performanceShortfalls.${ index }.initial_hearing_chairpersonsSanction` }
                                                                                />
                                                                            </span>
                                                                    </td>
                                                                </tr>
                                                            ),
                                                        ) }
                                                </>
                                            ) }
                                        </FieldArray>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={ 4 }>
                        <FormGroup>
                            <Label for="Overall Sanction" className="text-default text-capitalize">
                                Confirm Overall Sanction
                            </Label>
                            <FormField
                                disabled={ !canSelectOverallSanction() }
                                type={ 'selectWithLabels' }
                                placeholder={ 'Select Overall Sanction' }
                                name={ props.data?.organisation?.useHCApprover ? 'overallSanctionOld' : 'overallSanction' }
                                selectOptionsWithLabels={ getOptionsForOverallSanction() }
                            />
                            <span className="text-danger">
                                <ErrorMessage className="text-danger"
                                              name={ props.data?.organisation?.useHCApprover ? 'overallSanctionOld' : 'overallSanction' }/>
                            </span>
                        </FormGroup>
                    </Col>
                </Row>
                { showHCApp && (
                    <>
                        { isUserAdmin && (
                            <Row>
                                <Col>
                                    <h5 className="text-h5 text-blue font-weight-500 mt-3">
                                        Send Chairperson Recommendation for Review
                                    </h5>
                                    <p style={ { color: 'red' } }>
                                        The Chairperson recommendation can only be sent to an HC approver who was NOT
                                        involved in the hearing preparation.{ ' ' }
                                        <u>Please check your policy provisions to identify approvers</u>.
                                    </p>
                                </Col>
                            </Row>
                        ) }

                        { values.initial_hearing_chairpersonType === 'internal' && isUserAdmin && (
                            <Row className="mt-4 mb-4">
                                <Col md={ 3 }>
                                    <FormGroup>
                                        <Label for="employee" className="text-default text-capitalize">
                                            Select HC Approver
                                        </Label>
                                        <Field name={ 'initial_hearing_chairpersonId' }>
                                            { ({ field, form }: FieldProps) => (
                                                <AsyncSelect
                                                    placeholder="Select Employee"
                                                    cacheOptions
                                                    defaultOptions
                                                    value={
                                                        values.performanceShortfalls &&
                                                        values.performanceShortfalls[0] &&
                                                        values.performanceShortfalls[0].selectedHCEmailApprover
                                                            ? values.performanceShortfalls[0].selectedHCEmailApprover
                                                            : values.initial_hearing_chairpersonFirstName &&
                                                            values.initial_hearing_chairpersonLastName
                                                                ? {
                                                                    label: `${ values.initial_hearing_chairpersonFirstName } ${ values.initial_hearing_chairpersonLastName }`,
                                                                    value: values.initial_hearing_chairpersonId,
                                                                }
                                                                : null
                                                    }
                                                    loadOptions={ (inputValue: string | null) => {
                                                        return loadOptionsHCApprover(
                                                            inputValue,
                                                            props.data.caseData.organisationId,
                                                            props.data.caseData.employeeId,
                                                        );
                                                    } }
                                                    closeMenuOnSelect={ true }
                                                    styles={ selectStyles }
                                                    onChange={ (value: ValueType<any>): void => {
                                                        handleChange(value as any);
                                                        form.setFieldValue(field.name, (value as any).value);
                                                    } }
                                                    components={ { ValueContainer } }
                                                    name={ field.name }
                                                />
                                            ) }
                                        </Field>
                                        <span className="text-danger">
                                            <ErrorMessage
                                                className="text-danger"
                                                name={ 'initial_hearing_chairpersonId' }
                                            />
                                        </span>
                                    </FormGroup>
                                </Col>
                            </Row>
                        ) }
                        { isUserAdmin && (
                            <Row>
                                <Col>
                                    <EmailPreviewModalv3
                                        buttonText={ 'Email HC Approver' }
                                        getRecipients={ getRecipients }
                                        disabled={
                                            !values.performanceShortfalls?.every(
                                                (obj: {
                                                    initial_hearing_chairpersonsFindingOnGuilt: string;
                                                    initial_hearing_chairpersonsSanction: string;
                                                }) =>
                                                    obj.initial_hearing_chairpersonsFindingOnGuilt &&
                                                    (obj.initial_hearing_chairpersonsSanction ||
                                                        obj.initial_hearing_chairpersonsSanction === 'NOT_APPLICABLE')
                                            ) ||
                                            !values.initial_hearing_chairpersonFirstName ||
                                            !values.initial_hearing_chairpersonLastName ||
                                            !values.initial_hearing_chairpersonEmailAddress ||
                                            emailSent ||
                                            readOnly
                                        }
                                        formValues={ values }
                                        emailType={ EmailType.HC_APPROVER }
                                        masterProcessInstanceId={ props.data.masterProcessInstanceId }
                                        processInstanceId={ props.data.processInstanceId }
                                        currentUserId={ currentUser?.id }
                                        sendFunction={ sendEmail }
                                        getFlowableVariables={ () => [] }
                                        getAttachmentBucketKeys={ () => [] }
                                    />
                                </Col>
                            </Row>
                        ) }

                        <h5 className="text-h5 text-blue font-weight-500 mt-3">HC Approver Ruling</h5>
                        <Row>
                            <Col>
                                <Table className="table-responsive-lg" bordered>
                                    <thead>
                                    <tr>
                                        { hcApproverColumns.map(column => (
                                            <th key={ column }>
                                                <span
                                                    className="text-blue font-weight-500 text-capitalize">{ column }</span>
                                            </th>
                                        )) }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <FieldArray name="performanceShortfalls">
                                        { arrayHelpers => (
                                            <>
                                                { !values.performanceShortfalls && arrayHelpers.push({}) }
                                                { values.performanceShortfalls &&
                                                    values.performanceShortfalls.length &&
                                                    values.performanceShortfalls.map((item: CasePerformanceShortfall, index: number) => (
                                                            <tr key={ index }>
                                                                <td className="position-relative"
                                                                    style={ { minWidth: '150px' } }>
                                                                  <span>
                                                                    <TableFormFieldWithReadOnlyOption
                                                                        isUserAdmin={ true }
                                                                        type={ 'textarea' }
                                                                        placeholder={ 'Shortfall Description' }
                                                                        name={ `performanceShortfalls.${ index }.shortfallDescription` }
                                                                        className="square-radius border-0 h-25 pt-4"
                                                                    />
                                                                    <span className="text-danger">
                                                                      <ErrorMessage
                                                                          name={ `performanceShortfalls.${ index }.shortfallDescription` }/>
                                                                    </span>
                                                                  </span>
                                                                </td>
                                                                <td className="position-relative"
                                                                    style={ { minWidth: '100px' } }>
                                                                  <span>
                                                                    <TableFormField
                                                                        disabled={ isUserAdmin }
                                                                        type={ 'select' }
                                                                        placeholder={ 'Ruling on Guilt' }
                                                                        name={ `performanceShortfalls.${ index }.initial_hearing_chairpersonsFindingOnGuilt` }
                                                                        selectOptions={ chairpersonsFindingsOptions.map(formatOption) }
                                                                    />
                                                                    <span className="text-danger">
                                                                      <ErrorMessage
                                                                          name={ `performanceShortfalls.${ index }.initial_hearing_chairpersonsFindingOnGuilt` }/>
                                                                    </span>
                                                                  </span>
                                                                </td>

                                                                <td className="position-relative"
                                                                    style={ { minWidth: '150px' } }>
                                                                    {
                                                                        <Field
                                                                            name={ `performanceShortfalls.${ index }.initial_hearing_hcApproversFindingOnGuilt` }>
                                                                            { ({ field }: FieldAttributes<FormikValues>) => (
                                                                                <Select
                                                                                    { ...field }
                                                                                    placeholder="Select Ruling"
                                                                                    cacheOptions
                                                                                    options={ [
                                                                                        {
                                                                                            label: 'Guilty',
                                                                                            value: 'GUILTY'
                                                                                        },
                                                                                        {
                                                                                            label: 'Not guilty',
                                                                                            value: 'NOT_GUILTY'
                                                                                        },
                                                                                    ] }
                                                                                    closeMenuOnSelect={ true }
                                                                                    styles={ tableFieldSelectStyles }
                                                                                    onChange={ (value: ValueType<any>): void => {
                                                                                        setFieldValue(
                                                                                            `performanceShortfalls.${ index }.initial_hearing_hcApproversFindingOnGuilt`,
                                                                                            value.value,
                                                                                        );
                                                                                    } }
                                                                                    value={ {
                                                                                        label: item.initial_hearing_hcApproversFindingOnGuilt,
                                                                                        value: item.initial_hearing_hcApproversFindingOnGuilt,
                                                                                    } }
                                                                                    components={ { SingleValue } }
                                                                                    isDisabled={ isUserAdmin }
                                                                                />
                                                                            ) }
                                                                        </Field>
                                                                    }

                                                                    <span className="text-danger">
                                                                        <ErrorMessage
                                                                            className="text-danger"
                                                                            name={ `performanceShortfalls.${ index }.initial_hearing_hcApproversFindingOnGuilt` }
                                                                        />
                                                                      </span>
                                                                </td>

                                                                <td className="position-relative"
                                                                    style={ { minWidth: '150px' } }>
                                                                    { values.performanceShortfalls[index].initial_hearing_chairpersonsFindingOnGuilt !==
                                                                        values.performanceShortfalls[index].initial_hearing_hcApproversFindingOnGuilt && (
                                                                            <span>
                                                                          <TableFormFieldWithReadOnlyOption
                                                                              isUserAdmin={ isUserAdmin }
                                                                              type={ 'textarea' }
                                                                              placeholder={ 'Reason for deviation from ruling' }
                                                                              name={ `performanceShortfalls.${ index }.reason_hc_deviation_motivation_ruling` }
                                                                              className="square-radius border-0 h-25 pt-4"
                                                                          />

                                                                          <span className="text-danger">
                                                                            <ErrorMessage
                                                                                className="text-danger"
                                                                                name={ `values.performanceShortfalls.${ index }.reason_hc_deviation_motivation_ruling` }
                                                                            />
                                                                          </span>
                                                                        </span>
                                                                        ) }
                                                                </td>

                                                                <td className="position-relative"
                                                                    style={ { minWidth: '100px' } }>
                                                                    { !!item.initial_hearing_chairpersonsFindingOnGuilt?.length && (
                                                                        <TableFormField
                                                                            disabled={
                                                                                isUserAdmin ||
                                                                                item.initial_hearing_chairpersonsFindingOnGuilt === 'NOT_GUILTY'
                                                                            }
                                                                            type={ 'select' }
                                                                            placeholder={ 'Select Sanction' }
                                                                            name={ `performanceShortfalls.${ index }.initial_hearing_chairpersonsSanction` }
                                                                            selectOptions={ getPPSanctionOptions() }
                                                                        />
                                                                    ) }
                                                                    <span className="text-danger">
                                                                <ErrorMessage
                                                                    className="text-danger"
                                                                    name={ `performanceShortfalls.${ index }.initial_hearing_chairpersonsSanction` }
                                                                />
                                                              </span>
                                                                </td>

                                                                <td className="position-relative"
                                                                    style={ { minWidth: '100px' } }>
                                                                    <TableFormFieldWithReadOnlyOption
                                                                        isUserAdmin={ isUserAdmin }
                                                                        disabled={ item.initial_hearing_hcApproversFindingOnGuilt === 'NOT_GUILTY' }
                                                                        type={ 'select' }
                                                                        placeholder={
                                                                            item.initial_hearing_hcApproversFindingOnGuilt === 'NOT_GUILTY'
                                                                                ? 'Not Applicable'
                                                                                : 'Select Sanction'
                                                                        }
                                                                        name={ `performanceShortfalls.${ index }.initial_hearing_hcApproversSanction` }
                                                                        selectOptions={ getPPSanctionOptions() }
                                                                    />
                                                                    <span className="text-danger">
                                                                    <ErrorMessage
                                                                        className="text-danger"
                                                                        name={ `performanceShortfalls.${ index }.initial_hearing_hcApproversSanction` }
                                                                    />
                                                                  </span>
                                                                </td>

                                                                <td className="position-relative"
                                                                    style={ { minWidth: '100px' } }>
                                                                    { values.performanceShortfalls[index].initial_hearing_chairpersonsSanction !==
                                                                        values.performanceShortfalls[index].initial_hearing_hcApproversSanction && (
                                                                            <span>
                                                                          <TableFormFieldWithReadOnlyOption
                                                                              type={ 'textarea' }
                                                                              placeholder={ 'Reason for deviation from sanction' }
                                                                              name={ `performanceShortfalls.${ index }.reason_hc_deviation_motivation_sanction` }
                                                                              className="square-radius border-0 h-25 pt-4"
                                                                              isUserAdmin={ isUserAdmin }
                                                                          />
                                                                      <span className="text-danger">
                                                                        <ErrorMessage
                                                                            className="text-danger"
                                                                            name={ `values.performanceShortfalls.${ index }.reason_hc_deviation_motivation_sanction` }
                                                                        />
                                                                      </span>
                                                                    </span>
                                                                        ) }
                                                                </td>
                                                            </tr>
                                                        )
                                                    ) }
                                            </>
                                        ) }
                                    </FieldArray>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={ 4 }>
                                <FormGroup>
                                    <Label for="Overall Sanction" className="text-default text-capitalize">
                                        Confirm Overall Sanction HC
                                    </Label>
                                    <FormField
                                        disabled={ isUserAdmin }
                                        type={ 'selectWithLabels2' }
                                        placeholder={ 'Select Overall Sanction' }
                                        name={ 'overallSanction' }
                                        selectOptionsWithLabels={ getOptionsForOverallSanctionHC() }
                                    />
                                    <span className="text-danger">
                                                <ErrorMessage className="text-danger" name={ 'overallSanction' }/>
                                            </span>
                                </FormGroup>
                            </Col>
                        </Row>
                    </>
                ) }
            </>
        </Form>
    );
};

export default InitialHearingConfirmOverallSanctionFormPerformance;
