import React, { CSSProperties } from 'react';
import { components } from 'react-select';
import { startCase } from 'lodash';
import { toTitleCase } from '../../utils/string-utils';

export interface SelectType {
  [key: string]: string | string[] | null | undefined | boolean;
}

export const selectStyles = {
  control: (styles: CSSProperties): CSSProperties => ({
    ...styles,
    height: 53,
    backgroundColor: 'transparent',
    border: '1px solid rgba(29, 37, 59, 0.5)',
    borderColor: 'rgba(29, 37, 59, 0.5)',
    borderWidth: '0.05em',
    overflow: 'auto',
    minWidth: '18vw',
    borderRadius: '5px',
  }),
  dropdownIndicator: (styles: CSSProperties): CSSProperties => ({ ...styles, color: 'black' }),
  indicatorSeparator: (styles: CSSProperties): CSSProperties => ({ ...styles, display: 'none' }),
  multiValueLabel: (styles: CSSProperties): CSSProperties => ({ ...styles, color: 'black', background: 'bottom' }),
  menuList: (styles: CSSProperties): CSSProperties => ({ ...styles, color: 'black' }),
  valueContainer: (styles: CSSProperties): CSSProperties => ({ ...styles, textTransform: 'capitalize' }),
  option: (styles: CSSProperties): CSSProperties => ({ ...styles, textTransform: 'capitalize' }),
};

export const tableFieldSelectStyles = {
  control: (styles: CSSProperties): CSSProperties => ({
    ...styles,
    borderRadius: '8px',
    height: 'auto',
    backgroundColor: 'transparent',
    overflow: 'auto',
    width: '100%',
    margin: '5px 0',
  }),
  dropdownIndicator: (styles: CSSProperties): CSSProperties => ({ ...styles, color: 'black', padding: 0 }),
  indicatorSeparator: (styles: CSSProperties): CSSProperties => ({ ...styles, display: 'none' }),
  multiValueLabel: (styles: CSSProperties): CSSProperties => ({
    ...styles,
    color: 'black',
    background: 'bottom',
    whiteSpace: 'pre-wrap',
  }),
  menuList: (styles: CSSProperties): CSSProperties => ({ ...styles, color: 'black' }),
  valueContainer: (styles: CSSProperties): CSSProperties => ({
    ...styles,
    textTransform: 'capitalize',
  }),
  placeholder: (styles: CSSProperties): CSSProperties => ({
    ...styles,
    fontSize: '0.8em',
  }),
  clearIndicator: (styles: CSSProperties): CSSProperties => ({ ...styles, padding: 0, display: 'none' }),
  option: (styles: CSSProperties): CSSProperties => ({ ...styles, color: 'black', textTransform: 'capitalize' }),
};

export const ValueContainer = ({ children, getValue, ...props }: any) => {
  const length = getValue().length;
  if (props.selectProps.menuIsOpen) {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  } else {
    if (length < 1) {
      return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    } else if (length > 2) {
      return (
        <components.ValueContainer {...props}>
          <span>{children[0][0]}</span>
          <span>{children[0][1]}</span>
          <span className="text-muted small ml-1">and {`${length - 2}`} other(s)</span>
        </components.ValueContainer>
      );
    } else {
      return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    }
  }
};

export const SingleValue = ({ children, getValue, ...props }: any) => {
  const length = getValue().length;
  const label = getValue()[0] ? getValue()[0].label : '';
  const value = startCase(label);
  if (props.selectProps.menuIsOpen) {
    return (
      <components.ValueContainer {...props} className="text-dark text-capitalize">
        {toTitleCase(value, ' ')}
      </components.ValueContainer>
    );
  } else {
    if (length < 1) {
      return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    } else if (length > 2) {
      return (
        <components.ValueContainer {...props}>
          <span>{children[0][0]}</span>
          <span>{children[0][1]}</span>
          <span className="text-muted small ml-1">and {`${length - 2}`} other(s)</span>
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props} className="text-dark text-capitalize">
          {toTitleCase(value, ' ')}
        </components.ValueContainer>
      );
    }
  }
};
