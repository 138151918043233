import React from 'react';
import { View } from '@react-pdf/renderer';

interface TableProps {
  wrap?: boolean;
  children?: any;
}

const Table: React.FC<TableProps> = (props: TableProps) => {
  const { children } = props;
  return (
    <View style={{ marginBottom: 15 }} wrap={false}>
      {children}
    </View>
  );
};

export default Table;
