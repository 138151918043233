import React, { useEffect, useState } from 'react';
import { useErrorHandler } from '../../utils/notification-utils';
import { useParams } from 'react-router';
import Loader from '../../components/loader/Loader';
import { queryTasksByProcessInstanceId } from '../../utils/flowable/flowable-utils';
import { FlowableTask, FlowableVariable } from '../../utils/flowable/flowable-types';
import { completeFlowableTask } from '../../utils/flowable/flowable-api-utils';

interface ConfirmReceiptViewState {
  isLoading: boolean;
  isReceiptConfirmationComplete: boolean;
  isNoTaskFound: boolean;
}

export const ConfirmReceiptView: React.FC = () => {
  const handleError = useErrorHandler();
  const [state, setState] = useState<ConfirmReceiptViewState>({
    isLoading: true,
    isReceiptConfirmationComplete: false,
    isNoTaskFound: false,
  });

  const { PID }: { PID: string } = useParams<{
    PID: string;
  }>();

  const getTasks = async (PID: string): Promise<FlowableTask[]> => {
    console.log('getting tasks...');
    const tasks = await queryTasksByProcessInstanceId(PID);
    return tasks.filter(t => t.taskDefinitionKey?.includes('confirm-receipt'));
  };

  const handleTasks = async (tasks: FlowableTask[]): Promise<void> => {
    if (tasks.length) {
      const task: FlowableTask = tasks[0];
      // Todo: make this update local task vars first, like in the WorkFlowContainer.
      const vars: FlowableVariable[] = [
        { name: 'path', value: 'next' },
        {
          name: 'lastTaskDefinitionKey',
          value: task.taskDefinitionKey,
        },
      ];
      completeFlowableTask(task.id, null, vars)
        .then(() => {
          setState(oldState => ({
            ...oldState,
            isLoading: false,
            isReceiptConfirmationComplete: true,
            isNoTaskFound: false,
          }));
        })
        .catch(error => handleError(error));
    } else {
      setState(oldState => ({
        ...oldState,
        isLoading: false,
        isReceiptConfirmationComplete: false,
        isNoTaskFound: true,
      }));
    }
  };

  useEffect(() => {
    if (PID) {
      setState(oldState => ({
        ...oldState,
        isLoading: true,
      }));
      getTasks(PID)
        .then(res => {
          handleTasks(res);
        })
        .catch(error => {
          handleError(error);
          setState(oldState => ({ ...oldState, isLoading: false }));
        });
    } else {
      setState(oldState => ({ ...oldState, isLoading: false }));
      handleError(new Error('No key'));
    }
  }, []);

  const renderContent = (): JSX.Element => {
    if (state.isLoading) {
      return <Loader />;
    } else if (state.isReceiptConfirmationComplete) {
      return <div>Receipt Confirmed!</div>;
    } else if (state.isNoTaskFound) {
      return <div>Sorry, that link is no longer valid.</div>;
    } else return <div></div>;
  };

  return (
    <>
      <div className="content mt-5">
        <br />
        <br />
        <br />
        <br />
        <div className="d-flex justify-content-center mt-5 text-primary">{renderContent()}</div>
      </div>
    </>
  );
};
