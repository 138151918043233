import React from 'react';
import TopBarComponent from '../../components/topBar/TopBar.component';
import StaticIndicator from '../../components/staticIndicators/StaticIndicator';
import MultiVarChart from './charts/MultiVarChart';

export const AnalyticsScreen: React.FC = () => {
  return (
    <>
      <TopBarComponent title={'Analytics'} subTitle={'View Analytics'} hideSearch={true}></TopBarComponent>
      <div className="content">
        <br />
        <StaticIndicator showBottomMain />
        <StaticIndicator showSub />
        <MultiVarChart />
      </div>
    </>
  );
};
