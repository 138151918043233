import { styles } from '../../PDF/PDFStyles.style';
import { Text } from '@react-pdf/renderer';
import Table from '../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../PDF/PDFTable/TableRow.component';
import TableCell from '../../PDF/PDFTable/TableCell.component';
import React from 'react';
import { DocumentJSONContent } from '../../../screens/DocumentEditor/DocumentEditor';
import LetterHead from '../../PDF/Letterhead.component';
import { LogoPosition } from '../../../API';

export interface DocumentHeaderProps {
  content: DocumentJSONContent;
  logo?: { imageUrl: string; position: LogoPosition };
}

export const DocumentHeader = (props: DocumentHeaderProps): JSX.Element => {
  return (
    <>
      {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
      <Text style={styles.title}>Confidential</Text>
      <Table>
        <TableHeader title="attention" />
        <TableRow>
          <TableCell>Name of Employee: </TableCell>
          <TableCell>{props.content.employeeFullName.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Employee Number: </TableCell>
          <TableCell>{props.content.employeeNumber.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Department/Business Unit</TableCell>
          <TableCell>{props.content.employeeDepartment.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Address</TableCell>
          <TableCell>{props.content.employeeAddress.value}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>{props.content.date.value}</TableCell>
        </TableRow>
      </Table>
    </>
  );
};
