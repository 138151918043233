import React, { useState } from 'react';
import { Column } from '../EmployeeUploader/DynamicEmployeeUploadTable';
import Tabs, { Tab } from './EmployeeUploaderTabs.component';
import './employee-uploader.styles.scss';
import { Card, CardBody } from 'reactstrap';
import { AttemptedEmployee } from './upload-validation-utils';
import DynamicEmployeeUploadTableForInvalidEntries from './DynamicEmployeeUploadTableForInvalidEntries';
import { EmployeeValidationData } from './EmployeeUploader';

interface EmployeeUploaderConfirmationState {
  joiners: AttemptedEmployee[];
  leavers: AttemptedEmployee[];
  updated: AttemptedEmployee[];
  invalidEmployees: AttemptedEmployee[];
  selectedPage: number;
  loading: boolean;
}
interface EmployeeUploaderConfirmationProps {
  joiners: AttemptedEmployee[];
  leavers: AttemptedEmployee[];
  updated: AttemptedEmployee[];
  invalidEmployees: AttemptedEmployee[];
  fileName: string;
  onCancel: () => void;
  onClickImport: (employeeValidationData: EmployeeValidationData) => Promise<any>;
}

export const EmployeeUploaderConfirmationScreen: React.FC<EmployeeUploaderConfirmationProps> = (
  props: EmployeeUploaderConfirmationProps,
) => {
  const [state, setState] = useState<EmployeeUploaderConfirmationState>({
    joiners: props.joiners,
    leavers: props.leavers,
    updated: props.updated,
    invalidEmployees: props.invalidEmployees,
    selectedPage: 0,
    loading: false,
  });

  const columns: Column[] = [
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'idNumber', label: 'ID Number' },
    { key: 'employeeNumber', label: 'Employee Number' },
  ];

  const columnsForInvalidEmployees: Column[] = [
    { key: 'employeeNumber', label: 'Employee Number' },
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    { key: 'validationErrors', label: 'Validation Errors' },
  ];

  const getTabs = (): Tab[] => {
    return [
      { tabName: 'Joiners', employeeCount: state.joiners.length },
      { tabName: 'Leavers', employeeCount: state.leavers.length },
      { tabName: 'Updated', employeeCount: state.updated.length },
      { tabName: 'Invalid Entries', employeeCount: state.invalidEmployees.length },
    ];
  };

  const onToggleJoinerIncluded = (checked: boolean, rowIndex: number): void => {
    setState((prevState: EmployeeUploaderConfirmationState) => {
      const updatedJoiners = [...prevState.joiners];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updatedJoiners[rowIndex].included = checked;
      return { ...prevState, joiners: updatedJoiners };
    });
  };

  const toggleLeaverIncluded = (checked: boolean, rowIndex: number): void => {
    setState((prevState: EmployeeUploaderConfirmationState) => {
      const updatedLeavers = [...prevState.leavers];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updatedLeavers[rowIndex].included = checked;
      return { ...prevState, leavers: updatedLeavers };
    });
  };

  const toggleUpdatedIncluded = (checked: boolean, rowIndex: number): void => {
    setState((prevState: EmployeeUploaderConfirmationState) => {
      const updatedEmployees = [...prevState.updated];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updatedEmployees[rowIndex].included = checked;
      return { ...prevState, updated: updatedEmployees };
    });
  };

  return (
    <div className="content">
      <div className="import-employee-header mb-4 mt-4 p-3">
        <h3 className="text-secondary mb-3">Import Employees</h3>
        <p className="text-secondary">
          The following items have been identified as changes to your employees. Please deselect those that are
          inapplicable.
        </p>
      </div>
      <Tabs
        tabs={getTabs()}
        page={state.selectedPage}
        changeTab={(pageTo: number): void => setState(oldState => ({ ...oldState, selectedPage: pageTo }))}
      />
      <Card>
        <CardBody>
          {state.selectedPage === 0 && (
            <DynamicEmployeeUploadTableForInvalidEntries
              columns={columns}
              data={state.joiners}
              toggle={onToggleJoinerIncluded}
            />
          )}
          {state.selectedPage === 1 && (
            <DynamicEmployeeUploadTableForInvalidEntries
              columns={columns}
              data={state.leavers}
              toggle={toggleLeaverIncluded}
            />
          )}
          {state.selectedPage === 2 && (
            <DynamicEmployeeUploadTableForInvalidEntries
              columns={columns}
              data={state.updated}
              toggle={toggleUpdatedIncluded}
            />
          )}
          {state.selectedPage === 3 && (
            <DynamicEmployeeUploadTableForInvalidEntries
              columns={columnsForInvalidEmployees}
              data={state.invalidEmployees}
            />
          )}
          <div className="mt-4">
            <button className="btn btn-primary btn rounded-0" onClick={props.onCancel}>
              Cancel
            </button>
            <button
              // disabled={!state.employeeValidationData}
              className="btn btn-secondary float-right btn rounded-0"
              disabled={!(state.joiners.length || state.leavers.length || state.updated.length)}
              onClick={() =>
                props.onClickImport({
                  joiners: state.joiners,
                  leavers: state.leavers,
                  updated: state.updated,
                  invalidEmployees: [],
                })
              }
            >
              Import
            </button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
