import React, { useContext, useState } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { EmailParamsV2, EmailRecipient, EmailType } from '../../utils/email-utils';
import { CloseIcon } from '../icon/Icon.component';
import './document-modal.styles.scss';
import { useErrorHandler } from '../../utils/notification-utils';
import { UserContext, UserContextProps } from '../../App';
import { Employee, TemplateDocumentVersion } from '../../models';
import { toTitleCase } from '../../utils/string-utils';
import AsyncSelect from 'react-select/async';
import { selectStyles, SelectType, ValueContainer } from '../reactSelect/ReactSelectComponents.component';
import { ValueType } from 'react-select';
import { getUserForCase } from '../../utils/user-utils';
import { loadOptionsForEmployeeAndUserSelect } from '../../utils/select-utils';
import { EmailPreviewModalv3 } from '../EmailPreviewModal/EmailPreviewModalv3';
import { DocumentTask } from './DocumentVersionControlTable';
import { documentConfigs } from '../../configs/document-configs/document-configs';

interface Approver {
  emailAddress: string;
  userId?: string;
  employeeId?: string;
  firstName: string;
  lastName: string;
}

interface EmailDraftModalProps {
  documentTask: DocumentTask;
  employee: Employee | null;
  employees: Employee[];
  requestApproval: (
    document: TemplateDocumentVersion,
    emailParams: EmailParamsV2,
    approverUserId: string,
    documentProcessInstanceId: string,
  ) => Promise<void>;
  closeModal: () => void;
  processInstanceId: string;
  masterProcessInstanceId: string;
  documentProcessInstanceId: string;
}

interface EmailDraftModalState {
  primaryRecipientEmail: string;
  secondaryRecipientEmail: string;
  motivationText: string;
  internalEmailAddresses: string[];
  approvalRequired: boolean;
  selectedApprover: Approver | null;
}

export const EmailDraftModal: React.FC<EmailDraftModalProps> = (props: EmailDraftModalProps) => {
  const [state, setState] = useState<EmailDraftModalState>({
    primaryRecipientEmail: '',
    secondaryRecipientEmail: '',
    motivationText: '',
    internalEmailAddresses: [],
    approvalRequired: false,
    selectedApprover: null,
  });

  const attachmentName = props.documentTask.doc.templateType
    ? toTitleCase(props.documentTask.doc.templateType, ' ') + '_v' + props.documentTask.doc.version?.toString() + '.pdf'
    : '';

  const [showPreview] = useState<boolean>(false);

  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const handleError = useErrorHandler();

  const getRecipients = (): EmailRecipient[] => {
    if (state.selectedApprover) {
      return [
        {
          emailAddress: state.selectedApprover.emailAddress,
          name: toTitleCase(state.selectedApprover.firstName, ' '),
        },
      ];
    } else {
      throw new Error('No approver selected.');
    }
  };

  const getEmailParams = (): EmailParamsV2 => {
    // @ts-ignore
    const config = documentConfigs[props.documentTask.doc.templateType];
    const documentDescription = config?.name ?? '';
    if (!currentUser) {
      throw new Error('No current user.');
    }
    return {
      attachmentBucketKeys: [],
      currentUserId: currentUser.id,
      emailType: EmailType.APPROVE_DOCUMENT_INTERNAL,
      formValues: { documentDescription: documentDescription },
      masterProcessInstanceId: props.masterProcessInstanceId,
      processInstanceId: props.documentProcessInstanceId,
      recipients: getRecipients(),
    };
  };

  const sendMail = async (): Promise<void> => {
    if (!state.selectedApprover) {
      throw new Error('No approver selected');
    }
    const approver = state.selectedApprover;
    return new Promise<void>((resolve, reject) => {
      if (approver.userId) {
        const emailParams = getEmailParams();
        props
          .requestApproval(props.documentTask.doc, emailParams, approver.userId, props.documentProcessInstanceId)
          .then(() => {
            props.closeModal();
            resolve();
          })
          .catch(error => handleError(error));
      } else if (approver.employeeId) {
        getUserForCase(approver.emailAddress, approver.firstName, approver.lastName, approver.employeeId)
          .then(async userAndPassword => {
            const emailParams = getEmailParams();
            if (userAndPassword) {
              if (userAndPassword.temporaryPassword) {
                emailParams.newUserDetails = {
                  username: userAndPassword.user.emailAddress,
                  temporaryPassword: userAndPassword.temporaryPassword,
                };
              } else {
                emailParams.newUserDetails = undefined;
              }
              props
                .requestApproval(
                  props.documentTask.doc,
                  emailParams,
                  userAndPassword.user.id,
                  props.documentProcessInstanceId,
                )
                .then(() => {
                  props.closeModal();
                  resolve();
                })
                .catch(error => reject(error));
            } else reject(new Error('could not generate temporary password for user'));
          })
          .catch(error => reject(error));
      }
    });
  };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId) {
      return loadOptionsForEmployeeAndUserSelect(currentUser.organisationId, inputValue, props.employee?.id);
    }
  };

  const handleChange = (value: any) => {
    console.log('approver: ', value);
    const approver: Approver = {
      firstName: value.firstName,
      lastName: value.lastName,
      emailAddress: value.emailAddress,
      employeeId: value.employeeId,
      userId: value.userId,
    };
    setState(oldState => ({ ...oldState, selectedApprover: approver }));
  };

  return (
    <>
      <Modal className="p-0" size="lg" isOpen={!showPreview} centered>
        <ModalBody className="document-modal-body p-0 rounded-0">
          <div className="document-modal-header d-flex justify-content-between px-3 py-2 bg-default">
            <div className="text-capitalize ml-auto mr-auto font-weight-bold">
              {'Email Draft ' + toTitleCase(props.documentTask.doc.templateType, '_')}
            </div>
            <div className="align-self-center" onClick={(): void => props.closeModal()}>
              <CloseIcon fillColour={'white'} />
            </div>
          </div>
          <div className="modal-file-attachment">
            <i className="fas fa-paperclip pr-2" />
            {attachmentName}
          </div>
          <div className="px-3 py-3 text-default">
            <div>
              <p className="ml-4 mt-3">Select an employee to approve the document</p>
              <div>
                <AsyncSelect
                  placeholder="Select Employee"
                  cacheOptions
                  defaultOptions
                  loadOptions={loadOptions}
                  closeMenuOnSelect={true}
                  components={{ ValueContainer }}
                  onChange={(value: ValueType<any>) => {
                    handleChange(value);
                  }}
                  styles={selectStyles}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            className="modal-close-button text-primary"
            style={{ height: '48px', width: '148px' }}
            onClick={(): void => props.closeModal()}
          >
            CLOSE
          </button>
          <EmailPreviewModalv3
            buttonText={'Preview'}
            disabled={!state.selectedApprover}
            formValues={{
              // @ts-ignore
              documentDescription: documentConfigs[props.documentTask.doc.templateType]?.name ?? '',
            }}
            emailType={EmailType.APPROVE_DOCUMENT_INTERNAL}
            masterProcessInstanceId={props.masterProcessInstanceId}
            processInstanceId={props.documentProcessInstanceId}
            currentUserId={currentUser?.id}
            getRecipients={getRecipients}
            sendFunction={sendMail}
            getFlowableVariables={() => {
              return [];
            }}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};
