import React, { useEffect } from 'react';
import { Col, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import TableFormField from '../../../../forms/fields/TableFormField';
import FormField, { DATE_FORMAT } from '../../../../forms/fields/FormField.component';
import Select, { ValueType } from 'react-select';
import { selectStyles } from '../../../../components/reactSelect/ReactSelectComponents.component';
import moment from 'moment';
import { TemplateType } from '../../../../API';

interface ExecuteDisciplinaryActionFormProps {
  templateType: TemplateType;
}
const incidentCols = ['date of discussion', 'time', 'transgression', 'corrective action required*'];
const ExecuteDisciplinaryActionForm: React.FC<ExecuteDisciplinaryActionFormProps> = (
  props: ExecuteDisciplinaryActionFormProps,
) => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();

  useEffect(() => {
    if (values.warningValidityPeriod) {
      const date = moment()
        .add(values.warningValidityPeriod, 'months')
        .format(DATE_FORMAT);
      setFieldValue('warningExpiryDate', date);
    } else {
      switch (props.templateType) {
        case TemplateType.VERBAL_WARNING_DISCUSSION:
          setFieldValue('warningValidityPeriod', '3');
          break;
        case TemplateType.WRITTEN_WARNING_DISCUSSION:
          setFieldValue('warningValidityPeriod', '6');
          break;
        case TemplateType.FINAL_WRITTEN_WARNING_DISCUSSION:
          setFieldValue('warningValidityPeriod', '12');
          break;
      }
    }
  }, [values.warningValidityPeriod, values.warningExpiryDate]);

  return (
    <Form>
      <Row>
        <Col>
          <div>
            {/*<h6 className="text-primary text-capitalize mt-4 font-weight-normal">Compile Written Warning</h6>*/}
            <hr style={{ border: '0.06em solid #adb5bd' }} />
            <Row>
              <Col>
                <Table className="table-responsive-lg" bordered>
                  <thead>
                    <tr>
                      {incidentCols.map(column => (
                        <th key={column}>
                          <span className="text-blue font-weight-bold text-capitalize">{column}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name={'incidents'}>
                      {(arrayHelpers: FieldArrayRenderProps) => (
                        <>
                          {!values.incidents && arrayHelpers.push({})}
                          {values.incidents &&
                            values.incidents.length &&
                            values.incidents.map((item: any, index: number) => (
                              <tr key={index}>
                                <td className="position-relative" style={{ maxWidth: '130px', minWidth: '130px' }}>
                                  <TableFormField
                                    type={'date'}
                                    placeholder={'Date'}
                                    name={'disciplinaryDiscussionDate'}
                                    className="square-radius border-0 h-25"
                                  />
                                  <span className="text-danger">
                                    <ErrorMessage className="text-danger" name={'disciplinaryDiscussionDate'} />
                                  </span>
                                </td>
                                <td className="position-relative" style={{ maxWidth: '130px', minWidth: '130px' }}>
                                  <TableFormField
                                    type={'time'}
                                    placeholder={'Time'}
                                    name={'disciplinaryDiscussionTime'}
                                    className="square-radius border-0"
                                  />
                                  <span className="text-danger">
                                    <ErrorMessage className="text-danger" name={'disciplinaryDiscussionTime'} />
                                  </span>
                                </td>
                                <td className="position-relative" style={{ minWidth: '150px' }}>
                                  <span>{item.transgression}</span>
                                </td>
                                <td style={{ minWidth: '100px' }}>
                                  <TableFormField
                                    type={'text'}
                                    placeholder={'Corrective action required'}
                                    name={`incidents.${index}.correctiveAction`}
                                    className="square-radius border-0 h-25"
                                  />
                                  <span className="text-danger">
                                    <ErrorMessage
                                      className="text-danger"
                                      name={`incidents.${index}.correctiveAction`}
                                    />
                                    {!values.incidents[index].correctiveAction && <div>&nbsp; required field </div>}
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </>
                      )}
                    </FieldArray>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={4}>
          <FormGroup>
            <Label for="discussion Date" className="text-default text-capitalize">
              Select Validity Period
            </Label>
            <Select
              // @ts-ignore
              styles={selectStyles}
              placeholder="Select validity period"
              value={{
                label: values.warningValidityPeriod
                  ? values.warningValidityPeriod + ' ' + 'Months'
                  : 'Select validity period',
                value: values.warningValidityPeriod,
              }}
              options={[
                { label: '3 Months', value: '3' },
                { label: '6 Months', value: '6' },
                { label: '1 Year', value: '12' },
              ]}
              onChange={(item: ValueType<any>): void => setFieldValue('warningValidityPeriod', item.value)}
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'warningValidityPeriod'} />
            </span>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="warningExpiryDate" className="text-default text-capitalize">
              Warning Valid Until
            </Label>
            <FormField type={'date'} placeholder="Select Time" name="warningExpiryDate" />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'warningExpiryDate'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default ExecuteDisciplinaryActionForm;
