//Validation schema with Yup
//first wizard page
import * as Yup from 'yup';

const OrganisationSchema = Yup.object().shape({
  organisationName: Yup.string().required('Required field'),
  type: Yup.string().required('Required field'),
  maxEmployees: Yup.number()
    .required('Required field')
    .positive('Must be greater than 0')
    .nullable(),
  termsAndConditions: Yup.mixed()
    .required('Required field')
    .nullable(),
  mainContactFirstName: Yup.string()
    .required('Required field')
    .nullable(),
  mainContactLastName: Yup.string()
    .required('Required field')
    .nullable(),
  mainContactEmail: Yup.string()
    .email()
    .required('Required field'),
  mainContactNumber: Yup.string()
    .min(10)
    .max(10)
    .required('Required field'),
  disciplinaryCode: Yup.mixed(),
});

export default OrganisationSchema;
