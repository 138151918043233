import React, { ReactElement } from 'react';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../WorkflowContainer/workflow-utils';
import { Formik, FormikProps, FormikValues } from 'formik';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import PrepareAgreedDemotionForm from './PrepareAgreedDemotionForm';
import { PrepareAgreedDemotionSchema } from '../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import { TemplateType } from '../../../API';
import { DocumentVersionControlTable } from '../../../components/documentVersionControlTable/DocumentVersionControlTable';

const PrepareAgreedDemotion: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const fields = {
    demotionNewJobTitle: 'string',
    demotionNewJobLevel: 'string',
    demotionNewDepartment: 'string',
    demotionNewRemunerationPackage: 'string',
    demotionNewTerms: 'string',
    demotionDate: 'string',
  };

  const initialValues: FormikValues = getFormDataFromCaseData(fields, caseData, caseData.isAppeal);

  const submitValues = async (values: FormikValues): Promise<void> => {
    const formValues = { ...values };
    props.data.flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500">Agreed Demotion</h4>
      <Formik
        initialValues={initialValues}
        onSubmit={submitValues}
        validationSchema={PrepareAgreedDemotionSchema}
        enableReinitialize
      >
        {({ values, errors, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
          <>
            <PrepareAgreedDemotionForm data={props.data.caseData} />
            <h4 className="text-h4 text-capitalize font-weight-500">Compile Letter</h4>
            <button
              className="btn prepare-letter-button mb-3"
              onClick={(): void => {
                props.data.flowableFunctions.onOpenNewDocument(values, fields, TemplateType.AGREED_DEMOTION_LETTER);
              }}
            >
              PREPARE AGREED DEMOTION LETTER
            </button>
            {props.data.processInstanceId ? (
              <DocumentVersionControlTable
                data={props.data}
                processInstanceId={props.data.processInstanceId}
                templateType={TemplateType.AGREED_DEMOTION_LETTER}
                completeTask={props.data.flowableFunctions.completeTask}
              />
            ) : null}
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => flowableFunctions.onSaveAndClose(values)}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default PrepareAgreedDemotion;
