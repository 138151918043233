import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from '../PDFStyles.style';

interface PDFTableCellComponentProps {
  checkbox?: boolean;
  width?: string;
}
const TableCell: React.FC<PDFTableCellComponentProps> = ({ children, checkbox, width = '50%' }) => {
  return (
    <View style={{ width }}>
      <Text style={{ ...styles.tableCell, ...styles.fonts }}>{children}</Text>
      {checkbox && <View style={styles.checkbox} />}
    </View>
  );
};

export default TableCell;
