import React from 'react';
import { Col, Form, Row, Table } from 'reactstrap';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import { startCase } from 'lodash';
import { CaseIncident, CasePerformanceShortfall } from '../../../../../WorkflowContainer/workflow-utils';
import TableFormField from '../../../../../../forms/fields/TableFormField';
import { CaseType } from '../../../../../../API';

const dismissalWithNoticeCols = [
  'Date of Hearing*',
  'Transgressions found guilty of per hearing notice*',
  'Date of Dismissal*',
  'Last Day of Remuneration*',
  'Last Working Day*',
  'CCMA Referral Contact Name',
  'CCMA Referral Contact Address',
  'CCMA Referral Contact Email',
];

const shortfallColumns = [
  'Date of Hearing*',
  'Performance shortfalls found guilty of per hearing notice*',
  'Date of Dismissal*',
  'Last Day of Remuneration*',
  'Last Working Day*',
  'CCMA Referral Contact Name',
  'CCMA Referral Contact Address',
  'CCMA Referral Contact Email',
];

export const InitialHearingExecuteOutcomeDismissalWithNoticeForm: React.FC<{ caseType: CaseType }> = props => {
  const { values }: FormikProps<FormikValues> = useFormikContext();

  return (
    <Form>
      <Row>
        <Col>
          <div>
            <h5 className="text-primary text-capitalize font-weight-normal mt-3">
              Compile Dismissal With Notice Letter
            </h5>
            <hr style={{ border: '0.06em solid #adb5bd' }} />
            {props.caseType === CaseType.MISCONDUCT && (
              <Row>
                <Col>
                  <Table className="table-responsive-lg" bordered>
                    <thead>
                      <tr>
                        {dismissalWithNoticeCols.map(column => (
                          <th key={column}>
                            <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name={'incidents'}>
                        {(arrayHelpers: FieldArrayRenderProps) => (
                          <>
                            {!values.incidents && arrayHelpers.push({})}
                            {values.incidents &&
                              values.incidents.length &&
                              values.incidents
                                .filter((incidents: CaseIncident) =>
                                  incidents.initial_hearing_hcApproversFindingOnGuilt
                                    ? incidents.initial_hearing_hcApproversFindingOnGuilt === 'GUILTY'
                                    : incidents.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
                                )
                                .map((item: any, index: number) => (
                                  <tr key={index}>
                                    <td style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'Date'}
                                        name={`initial_hearing_hearingDate`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'initial_hearing_hearingDate'} />
                                      </span>
                                    </td>
                                    <td className="position-relative text-capitalize" style={{ minWidth: '100px' }}>
                                      <span>{startCase(item.transgression.toLowerCase())}</span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'date'}
                                        name={`dismissalDate`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'dismissalDate'} />
                                        {!values.incidents.dismissalDate && <div>&nbsp; required field </div>}
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'date'}
                                        name={`lastRemunerationDate`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'lastRemunerationDate'} />
                                        {!values.incidents.lastRemunerationDate && <div>&nbsp; required field </div>}
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'date'}
                                        name={`lastWorkingDay`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'lastWorkingDay'} />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '60px' }}>
                                      <TableFormField
                                        type={'text'}
                                        placeholder={'Contact Person'}
                                        className="square-radius border-0 h-25"
                                        name={`contactPersonInCaseOfCCMA`}
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'contactPersonInCaseOfCCMA'} />
                                      </span>
                                    </td>
                                    <td style={{ minWidth: '120px' }}>
                                      <TableFormField
                                        type={'text'}
                                        placeholder={'Physical Address'}
                                        name={`physicalAddress`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'physicalAddress'} />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '120px' }}>
                                      <TableFormField
                                        type={'text'}
                                        placeholder={'Email Address'}
                                        name={`emailAddress`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'emailAddress'} />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                          </>
                        )}
                      </FieldArray>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
            {props.caseType === CaseType.POOR_PERFORMANCE && (
              <Row>
                <Col>
                  <Table className="table-responsive-lg" bordered>
                    <thead>
                      <tr>
                        {shortfallColumns.map(column => (
                          <th key={column}>
                            <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      <FieldArray name={'performanceShortfalls'}>
                        {(arrayHelpers: FieldArrayRenderProps) => (
                          <>
                            {!values.performanceShortfalls && arrayHelpers.push({})}
                            {values.performanceShortfalls &&
                              values.performanceShortfalls.length &&
                              values.performanceShortfalls
                                  .filter((shortfall: CasePerformanceShortfall) => {
                                    if (shortfall.initial_hearing_hcApproversFindingOnGuilt) {
                                      return shortfall.initial_hearing_hcApproversFindingOnGuilt === 'GUILTY';
                                    } else {
                                      return shortfall.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY' ||
                                          shortfall.initial_hearing_chairpersonsFindingOnGuilt === 'NOT GUILTY';
                                    }
                                  })
                                  .map((item: CasePerformanceShortfall, index: number) => (
                                  <tr key={index}>
                                    <td style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'Date'}
                                        name={`initial_hearing_hearingDate`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'initial_hearing_hearingDate'} />
                                      </span>
                                    </td>
                                    <td className="position-relative text-capitalize" style={{ minWidth: '100px' }}>
                                      <span>{startCase(item.shortfallDescription.toLowerCase())}</span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'date'}
                                        name={`dismissalDate`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'dismissalDate'} />
                                        {!values.performanceShortfalls.dismissalDate && <div>&nbsp; required field </div>}
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'date'}
                                        name={`lastRemunerationDate`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'lastRemunerationDate'} />
                                        {!values.performanceShortfalls.lastRemunerationDate && <div>&nbsp; required field </div>}
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '60px', maxWidth: 'auto' }}>
                                      <TableFormField
                                        type={'date'}
                                        placeholder={'date'}
                                        name={`lastWorkingDay`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'lastWorkingDay'} />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '60px' }}>
                                      <TableFormField
                                        type={'text'}
                                        placeholder={'Contact Person'}
                                        className="square-radius border-0 h-25"
                                        name={`contactPersonInCaseOfCCMA`}
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'contactPersonInCaseOfCCMA'} />
                                      </span>
                                    </td>
                                    <td style={{ minWidth: '120px' }}>
                                      <TableFormField
                                        type={'text'}
                                        placeholder={'Physical Address'}
                                        name={`physicalAddress`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'physicalAddress'} />
                                      </span>
                                    </td>
                                    <td className="position-relative" style={{ minWidth: '120px' }}>
                                      <TableFormField
                                        type={'text'}
                                        placeholder={'Email Address'}
                                        name={`emailAddress`}
                                        className="square-radius border-0 h-25"
                                      />
                                      <span className="text-danger">
                                        <ErrorMessage className="text-danger" name={'emailAddress'} />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                          </>
                        )}
                      </FieldArray>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
};
