import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ButtonWithIcons, { default as CustomButton } from '../buttons/ButtonWIthIcons.component';
import './sign-document-modal.style.scss';
import { useErrorHandler } from '../../utils/notification-utils';
import { Document, Page, PDFViewer, Text } from '@react-pdf/renderer';
import Loader from '../loader/Loader';
import { LogoPosition, Organisation, TemplateDocumentVersion } from '../../models';
import { get, mutate } from '../../utils/graphql-utils';
import { FieldData } from '../../forms/fields/FieldTypeDefinitions';

import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';
import './signature-styles.css';
import { updateTemplateDocumentVersion } from '../../graphql/mutations';
import { CloseIcon } from '../icon/Icon.component';
import { styles } from '../PDF/PDFStyles.style';
import { getOrganisationSettings } from '../../graphql-custom/custom-queries';
import { bucketPath } from '../../utils/storage-utils';
import { documentConfigs } from '../../configs/document-configs/document-configs';

interface SignDocumentModalProps {
  document: TemplateDocumentVersion;
  onSign: () => void;
}

interface DocumentWithContent {
  document: TemplateDocumentVersion;
  jsonContent: DocumentJSONContent | null;
}

interface Logo {
  imageUrl: string;
  position: LogoPosition;
}

interface DocumentJSONContent {
  [key: string]: FieldData;
}

const SignDocumentModal: React.FC<SignDocumentModalProps> = (props: SignDocumentModalProps) => {
  const [logo, setLogo] = useState<Logo>({
    imageUrl: '',
    position: LogoPosition.LEFT,
  });
  const [documentData, setDocumentData] = useState<DocumentWithContent | null>(null);
  const [, setIsSigningDoc] = useState(false);
  const [hasSigned, setHasSigned] = useState<boolean>(false);
  const signature = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleError = useErrorHandler();

  const toggleModal = (): void => {
    if (!isModalOpen) {
      setIsLoading(true);
      setIsModalOpen(true);
      setTimeout(() => {
        setDocumentData({
          document: props.document,
          jsonContent: props.document.stringifiedContent ? JSON.parse(props.document.stringifiedContent) : null,
        });
        setIsLoading(false);
      }, 2000);
    } else {
      setIsModalOpen(false);
      setDocumentData(null);
    }
  };

  const loadOrganisation = (id: string): Promise<Organisation> => {
    return new Promise((resolve, reject) => {
      get(getOrganisationSettings, id)
        .then(res => {
          const org: Organisation = (res.data as any)?.getOrganisation;
          if (org) {
            resolve(org);
          } else reject(new Error('No data on graphql response'));
        })
        .catch(error => reject(error));
    });
  };

  useEffect(() => {
    loadOrganisation(props.document.organisationID).then(async res => {
      if (res?.logo && res.logoPosition) {
        const url = bucketPath + '/public/' + res.logo.key;
        let position;
        switch (res.logoPosition) {
          case 'CENTER':
            position = LogoPosition.CENTER;
            break;
          case 'LEFT':
            position = LogoPosition.LEFT;
            break;
          case 'RIGHT':
            position = LogoPosition.RIGHT;
            break;
          default:
            position = LogoPosition.CENTER;
            break;
        }
        setLogo({ imageUrl: url, position });
      }
    });
  }, []);

  const getDocumentJSXForPDFViewer = (document: DocumentWithContent): JSX.Element => {
    const templateType = document.document.templateType;
    let content;
    if (document.document.signedCopy) {
      content = JSON.parse(document.document.signedCopy);
    } else if (document.jsonContent) {
      content = documentData?.jsonContent;
    } else {
      return <></>;
    }

    if (content) {
      // @ts-ignore
      const component = documentConfigs[templateType]?.component({ content: content, logo: logo });
      if (component) {
        return component;
      } else {
        return (
          <Document>
            <Page style={styles.body} size="A4" wrap>
              <Text style={styles.title}>Error: Could not find document template</Text>
            </Page>
          </Document>
        );
      }
    } else {
      return <div>Could not load document</div>;
    }
  };

  const clear = () => {
    //@ts-ignore
    signature.current.clear();
    setHasSigned(false);
  };

  const submit = () => {
    if (documentData && signature && signature.current) {
      //@ts-ignore
      const sig = signature.current.getTrimmedCanvas().toDataURL('image/png');
      if (
        sig ===
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII='
      ) {
        alert('No signature detected');
      } else {
        const signedDoc = {
          document: documentData.document,
          jsonContent: {
            ...documentData.jsonContent,
            issuerSignature: {
              label: '',
              type: 'text',
              value: sig,
            },
            issuerSignedDate: {
              label: '',
              type: 'text',
              value: moment().format('MMMM Do YYYY, h:mm:ss a'),
            },
          },
        };
        setDocumentData(signedDoc);
        setIsSigningDoc(true);
        setHasSigned(true);
      }
    }
  };

  const save = async (documentData: DocumentWithContent): Promise<void> => {
    setIsSaving(true);
    mutate(updateTemplateDocumentVersion, {
      id: documentData.document.id,
      signedCopy: JSON.stringify(documentData.jsonContent),
    })
      .then(() => {
        setIsSaving(false);
        // setHasSigned(true);
        props.onSign();
        toggleModal();
      })
      .catch(error => {
        setIsSaving(false);
        handleError(error);
      });
  };

  const renderSignDocument = (documentData: DocumentWithContent): JSX.Element => {
    return (
      <>
        <div className="container" style={{ maxWidth: '1400px', paddingTop: '25px' }}>
          <div>
            {isLoading ? (
              <div className="d-flex justify-content-center mt-5">
                <Loader />
              </div>
            ) : (
              <div className="p-0 m-0" style={{ height: '100%' }}>
                <PDFViewer style={{ width: '100%', height: '70vh' }}>
                  {getDocumentJSXForPDFViewer(documentData)}
                </PDFViewer>
              </div>
            )}
          </div>
          <div className="sigContainer">
            <>
              <h4>Sign here</h4>
              <SignatureCanvas ref={signature} penColor="black" canvasProps={{ className: 'sigPad' }} />
              <ButtonWithIcons title={'Clear'} buttonType={'btn-bd-purple'} handleClick={clear} />
              <ButtonWithIcons title={'Add signature'} buttonType={'btn-bd-purple'} handleClick={submit} />
            </>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Button onClick={() => toggleModal()} className="btn btn-bd-purple rounded-0 text-uppercase font-weight-normal">
        Sign Document
      </Button>
      <Modal size="lg" isOpen={isModalOpen} toggle={toggleModal} backdrop="static" fade={false} centered>
        <ModalBody className="p-0 rounded-0">
          <div className="document-modal-header d-flex justify-content-between px-3 py-2 bg-default">
            <div className="text-capitalize ml-auto mr-auto font-weight-bold"> {'Sign Document'}</div>
            <div className="align-self-center" onClick={(): void => toggleModal()}>
              <CloseIcon fillColour={'white'} />
            </div>
          </div>
          {documentData ? (
            renderSignDocument(documentData)
          ) : (
            <div className="d-flex justify-content-center mt-5">
              <Loader />
            </div>
          )}
          <br />
        </ModalBody>
        <ModalFooter
          className="d-flex justify-content-end py-2 m-0"
          style={{ borderTop: '0.1em solid rgb(206, 206, 206)' }}
        >
          <CustomButton
            title={isSaving ? 'Saving...' : 'Submit'}
            buttonType="btn-bd-purple py-2 px-1 mx-0"
            handleClick={async (): Promise<void> => await save(documentData!)}
            style={{ height: '50px' }}
            disabled={!documentData || isSaving || !hasSigned}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SignDocumentModal;
