import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import List from '../../../PDF/List.component';
import ListItem from '../../../PDF/ListItem.component';
import Paragraph from '../../../PDF/Paragraph.component';
import { DocumentHeader } from '../../common/Header';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const ExtensionOfPIPPDF: React.FC<DocumentProps> = props => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        <DocumentHeader content={props.content} logo={props.logo} />
        <Paragraph> Dear {props.content.employeeFullName.value}, </Paragraph>
        <Text style={{ ...styles.subject }}>Extension Of PIP</Text>
        <Paragraph>
          A Counselling discussion held {props.content.performanceDiscussionDate.value} with you refers regarding
          performance concerns raised by the employer. At said discussion the following concerns were discussed:
        </Paragraph>
        <List>
          {Array.isArray(props.content.shortfallDescriptions.value) &&
            props.content.shortfallDescriptions.value.map((item: string, index: number) => (
              <ListItem key={index}>{item} </ListItem>
            ))}
        </List>
        <Paragraph>
          It is imperative that your performance improve and that you meet the agreed expectations as set out below
          within the time frames indicated:
        </Paragraph>
      </Page>
      <Page style={styles.body} orientation={'landscape'} size="A4" wrap>
        <Table>
          <TableRow>
            <TableCell width="17%">Performance shortfall(s) </TableCell>
            <TableCell width="18%">Performance/ Competency Goal </TableCell>
            <TableCell width="17%">Quality deliverable/ standard required</TableCell>
            <TableCell width="16%">Deadline/ time frame </TableCell>
            <TableCell width="16%">Actions to be taken by Employee </TableCell>
            <TableCell width="16%">Assistance required from Employer </TableCell>
          </TableRow>
          {Array.isArray(props.content.performanceShortfalls.value) &&
            props.content.performanceShortfalls.value.map((val: string | string[], index: number) => (
              <TableRow key={index}>
                <TableCell width="17%">{val}</TableCell>
                <TableCell width="18%">
                  {Array.isArray(props.content.performanceGoal.value)
                    ? props.content.performanceGoal.value[index]
                    : 'N/A'}
                </TableCell>
                <TableCell width="17%">
                  {Array.isArray(props.content.qualityStandard.value)
                    ? props.content.qualityStandard.value[index]
                    : 'N/A'}
                </TableCell>
                <TableCell width="16%">
                  {Array.isArray(props.content.deadline.value) ? props.content.deadline.value[index] : 'N/A'}
                </TableCell>
                <TableCell width="16%">
                  {Array.isArray(props.content.actionsToBeTakenByEmployee.value)
                    ? props.content.actionsToBeTakenByEmployee.value[index]
                    : 'N/A'}
                </TableCell>
                <TableCell width="16%">
                  {Array.isArray(props.content.assistanceRequiredFromEmployer.value)
                    ? props.content.assistanceRequiredFromEmployer.value[index]
                    : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
        </Table>
      </Page>
      <Page style={styles.body} size="A4" wrap>
        <Paragraph>
          You are further issued with a final warning that should your performance not improve and remedial steps taken,
          the employer will progress the Incapacity Poor Performance process to a hearing, which may result in the
          termination of your employment.
        </Paragraph>
        <Paragraph>
          <Text>Please discuss any concerns you may have with us timeously.</Text>
          <Text>
            This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a
            copy to writer hereof in acknowledgement of receipt hereof, alternatively, acknowledge by way of return
            e-mail.
          </Text>
        </Paragraph>
        <Paragraph>Yours sincerely,</Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
    </Document>
  );
};
