import React, { useState } from 'react';

interface TaskContainerProps {
  isTaskReadOnly: boolean;
}

export interface ReadOnlyContextProps {
  isTaskReadOnly: boolean;
  enableFieldsCallback?: () => void;
}

export const ReadOnlyContext = React.createContext<ReadOnlyContextProps>({ isTaskReadOnly: false });

const TaskContainer: React.FC<TaskContainerProps> = ({ children, isTaskReadOnly }) => {
  const [readOnlyLocal, setReadOnlyLocal] = useState(isTaskReadOnly);

  const enableFields = (): void => {
    setReadOnlyLocal(false);
  };

  const contextValue: ReadOnlyContextProps = {
    isTaskReadOnly: readOnlyLocal,
    enableFieldsCallback: enableFields,
  };

  return <ReadOnlyContext.Provider value={contextValue}>{children}</ReadOnlyContext.Provider>;
};

export default TaskContainer;
