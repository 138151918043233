import * as Yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT } from '../../../forms/fields/FormField.component';

const GeneralCompanyInformationSchema = Yup.object().shape({
  organisationName: Yup.string()
    .required('Required field')
    .nullable(),
  registrationNumber: Yup.string()
    .required('Required field')
    .nullable(),
  vatNumber: Yup.string()
    .required('Required field')
    .nullable(),
  webUrl: Yup.string().nullable(),
  logo: Yup.object()
    .nullable()
    .shape({
      fileName: Yup.string(),
      url: Yup.string(),
      key: Yup.string(),
    })
    .nullable(),
  logoPosition: Yup.string().nullable(),
  mainContactNumber: Yup.string()
    .required('Required field')
    .nullable(),
  mainContactEmail: Yup.string()
    .email('Email is not valid')
    .required('Required field')
    .nullable(),
  // mainContactFirstName: Yup.string()
  //   .required('Required field')
  //   .nullable(),
  // mainContactLastName: Yup.string()
  //   .required('Required field')
  //   .nullable(),
  facilitiesEmail: Yup.string()
    .email('Email is not valid')
    .required('Required field')
    .nullable(),
  payrollEmail: Yup.string()
    .email('Email is not valid')
    .required('Required field')
    .nullable(),
  ccmaContactName: Yup.string()
    .required('Required field')
    .nullable(),
  ccmaContactEmail: Yup.string()
    .email('Email is not valid')
    .required('Required field')
    .nullable(),
  ccmaContactPhysicalAddress: Yup.string()
    .required('Required field')
    .nullable(),
  customReportingPeriodStartDate: Yup.string()
    .nullable()
    .when('useCustomDatesForReportingPeriod', {
      is: true,
      then: Yup.string(), //.required('Provide start date.'),
    })
    .test('date', 'Date is not valid', val => !val || moment(val, DATE_FORMAT).isValid()),
  customReportingPeriodEndDate: Yup.string()
    .nullable()
    .when('useCustomDatesForReportingPeriod', {
      is: true,
      then: Yup.string(), //.required('Provide end date.'),
    })
    .test('date', 'Date is not valid', val => !val || moment(val, DATE_FORMAT).isValid()),
});

export default GeneralCompanyInformationSchema;
