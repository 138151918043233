import React, { ReactElement, useContext } from 'react';
import { ErrorMessage, Field, FieldAttributes, FormikProps, FormikValues, useFormikContext } from 'formik';
import moment from 'moment';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { CalenderIcon, ClockIcon } from '../../components/icon/Icon.component';
import { TypedDateTime } from './TableFormField';
import { ReadOnlyContext, ReadOnlyContextProps } from '../../components/taskContainer/TaskContainer';
import { setDateTime } from './FormField.component';

interface TableDateTimeFieldWithControlsProps {
  name: string;
  type: string;
  placeholder: string;
  index: number;
  disabled?: boolean;
  className?: string;
}

const TableDateTimeFieldWithControls: React.FC<TableDateTimeFieldWithControlsProps> = (
  props: TableDateTimeFieldWithControlsProps,
) => {
  const { setFieldValue }: FormikProps<FormikValues> = useFormikContext();
  const { name, type, placeholder, index, disabled = false, className } = props;
  const readOnly = useContext<ReadOnlyContextProps>(ReadOnlyContext).isTaskReadOnly;

  return (
    <>
      {(type === 'date' || type === 'time') && (
        <Field name={name}>
          {({ field }: FieldAttributes<FormikValues>): ReactElement => (
            <>
              <TypedDateTime
                onChange={(dateTime: string | moment.Moment): void => setDateTime(dateTime, setFieldValue, name, type)}
                dateFormat={type === 'date'}
                timeFormat={type === 'time'}
                inputProps={{
                  onKeyDown: e => e.preventDefault(),
                }}
                closeOnTab
                //@ts-ignore
                renderInput={(
                  props: any,
                  openCalender: () => void,
                  closeCalender: () => void,
                  closeOnTab: boolean,
                ): JSX.Element => {
                  return (
                    <div>
                      <InputGroup className="border-white mb-0">
                        <Input
                          {...props}
                          {...field}
                          type={'textarea'}
                          placeholder={placeholder}
                          className={`h-25 border-bottom-0 border-right-0 border-left-0 ${
                            index === 0 ? 'border-top-0' : 'border-top-1'
                          } rounded-0 pl-0 pr-0 ${className}`}
                          style={{ fontSize: '0.9em', display: 'block' }}
                          disabled={readOnly || disabled}
                          autoComplete="off"
                        />
                        <InputGroupAddon addonType="append" className={`border-0 p-0 m-0 ${className} `}>
                          <InputGroup className="text-dark border-0 pt-3">
                            {type === 'time' && <ClockIcon width="20" height="20" />}
                            {type === 'date' && <CalenderIcon width="20" height="20" />}
                          </InputGroup>
                        </InputGroupAddon>
                      </InputGroup>
                      <span onClick={openCalender} style={{ display: 'none' }} />
                      <span onClick={closeCalender} style={{ display: 'none' }} />
                    </div>
                  );
                }}
              />
            </>
          )}
        </Field>
      )}
      <span className="text-danger">
        <ErrorMessage className="text-danger" name={name} />
      </span>
    </>
  );
};

export default TableDateTimeFieldWithControls;
