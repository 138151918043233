import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row, Table, Form, Modal, ModalBody, Button } from 'reactstrap';
import FormField from '../../../../forms/fields/FormField.component';
import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldAttributes,
  Formik,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import AsyncSelect from 'react-select/async';
import {
  selectStyles,
  SelectType,
  ValueContainer,
} from '../../../../components/reactSelect/ReactSelectComponents.component';
import { ValueType } from 'react-select';
import { UserContext, UserContextProps } from '../../../../App';
import { listActiveEmployeesByOrganisationId } from '../../../../utils/graphql-utils';
import { Tooltip } from '../../../../components/tooltips/Tooltip.component';
import TableFormField from '../../../../forms/fields/TableFormField';
import { AddIcon, CloseIcon, RemoveIcon } from '../../../../components/icon/Icon.component';
import UploaderContainer from '../../../../components/Uploader/UploaderContainer';
import ButtonWithIcons from '../../../../components/buttons/ButtonWIthIcons.component';
import TableFormFieldWithControls from '../../../../forms/fields/TableFormFieldWithControls';
import TableDateTimeFieldWithControls from '../../../../forms/fields/TableDateTimeFieldWithControls';
import { NewShortfallValidationSchema } from '../InitiatePIP/InitiatePIPForm';
import PerformanceCounsellingHistoryAccordion, {
  SessionHistory,
} from '../../../../components/PeformanceCounsellingHistory/PerformanceCounsellingHistoryAccordion';
import { PipProgressMeeting } from '../RecordPIPProgressMeeting/RecordPIPProgressMeeting';
import { Employee } from '../../../../models';
import { CasePerformanceShortfall, PipProgressUpdate } from '../../../WorkflowContainer/workflow-utils';
import { notEmpty } from '../../../../utils/typescript-utils';
import ApiDataContext from '../../../../contexts';

interface CompletePipSessionFormProps {
  processInstanceId: string;
}

interface CompletePipSessionFormState {
  newShortfallModalIsOpen: boolean;
  pipProgressMeetingsModalIsOpen: boolean;
  pipProgressMeetingSessions: SessionHistory[];
}

const initialState: CompletePipSessionFormState = {
  newShortfallModalIsOpen: false,
  pipProgressMeetingsModalIsOpen: false,
  pipProgressMeetingSessions: [],
};

const CompletePipSessionForm: React.FC<CompletePipSessionFormProps> = (props: CompletePipSessionFormProps) => {
  const { processInstanceId } = props;
  const { setFieldValue, values }: FormikProps<FormikValues> = useFormikContext();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const [state, setState] = useState<CompletePipSessionFormState>(initialState);

  const employeeListApiData = useContext(ApiDataContext);

  const handleChange = (value: { value: string; label: string }, fieldName: string) => {
    setFieldValue(fieldName, value);
  };

  const prepareData = (data: Employee[]): SelectType[] => {
    const preparedData = data.map((employee: Employee) => {
      const emailAddresses = employee.emails.filter(notEmpty).map(item => item.address);
      return {
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.id,
        attendeeId: employee.id,
        attendeeEmailAddress: emailAddresses[0] ? emailAddresses[0] : 'noEmail@labourteq.co.za',
        attendeeName: employee.firstName + ' ' + employee.lastName,
      };
    });
    return preparedData;
  };

  const filterItems = (data: SelectType[], inputValue: string | null): SelectType[] => {
    const filteredData = data.filter(option => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return option!.label.toLowerCase().includes(inputValue.toLowerCase());
    });
    return filteredData;
  };

  // const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
  //   if (currentUser?.organisationId) {
  //     return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
  //       const preparedData = prepareData(data);
  //       return !inputValue ? preparedData : filterItems(preparedData, inputValue);
  //     });
  //   }
  // };

  const loadOptions = async (inputValue: string | null): Promise<SelectType[] | undefined> => {
    if (currentUser?.organisationId && typeof employeeListApiData.initialEmployeesPage === 'undefined') {
      return await listActiveEmployeesByOrganisationId(currentUser.organisationId).then(data => {
        const preparedData = prepareData(data);
        employeeListApiData.employees = !inputValue ? preparedData : filterItems(preparedData, inputValue);
        return !inputValue ? preparedData : filterItems(preparedData, inputValue);
      });
    } else {
      return !inputValue
        ? employeeListApiData.initialEmployeesPage
        : filterItems(employeeListApiData.initialEmployeesPage, inputValue);
    }
  };

  useEffect(() => {
    if (values.pipProgressMeetings && values.pipProgressMeetings.length) {
      const sessions: SessionHistory[] = values.pipProgressMeetings.map((session: PipProgressMeeting) => {
        const item: SessionHistory = {
          sessionId: session.meetingId,
          date: session.pipProgressMeetingDate,
          time: session.pipProgressMeetingTime,
          location: session.pipProgressMeetingLocation,
          attendees: session.pipProgressMeetingAttendees,
          sessionCompleted: session.sessionCompleted,
        };

        return item;
      });

      setState(oldState => ({
        ...oldState,
        pipProgressMeetingSessions: sessions,
      }));
    }
  }, [JSON.stringify(values.pipProgressMeetings)]);

  useEffect(() => {
    const cleanObject = {
      reviewPeriod: values.session.pipProgressMeetingDate,
      progressStatus: '',
      progressRemarks: '',
      progressMeetingId: values.session.meetingId,
    };

    values.performanceShortfalls.forEach((sf: CasePerformanceShortfall) => {
      sf.pipProgressUpdates = sf.pipProgressUpdates.filter(progressUpdate => !!progressUpdate.progressMeetingId);
      if (
        !sf.pipProgressUpdates.filter(progressUpdate => progressUpdate.progressMeetingId === values.session.meetingId)
          .length
      ) {
        sf.pipProgressUpdates.push(cleanObject);
      }
    });
  }, [state.pipProgressMeetingSessions]);

  useEffect(() => {
    if (values.session && values.performanceShortfalls.length) {
      values.performanceShortfalls.forEach((shortfall: CasePerformanceShortfall, index: number) => {
        if (
          shortfall.pipProgressUpdates &&
          shortfall.pipProgressUpdates.length &&
          state.pipProgressMeetingSessions.length
        ) {
          if (shortfall.pipProgressUpdates.length) {
            shortfall.pipProgressUpdates.forEach((update, i) => {
              if (!update.reviewPeriod || i === shortfall.pipProgressUpdates.length - 1) {
                setFieldValue(
                  `performanceShortfalls.${index}.pipProgressUpdates.${i}.reviewPeriod`,
                  values.session.pipProgressMeetingDate,
                );
              }
            });
          }
        }
      });
    }
  }, [
    values.session.pipProgressMeetingDate,
    JSON.stringify(values.performanceShortfalls),
    state.pipProgressMeetingSessions,
  ]);

  return (
    <>
      <Form>
        <Row>
          <Col md="4">
            <FormGroup>
              <Label for="pipProgressMeetingDate" className="text-default text-capitalize">
                Date of Discussion
              </Label>
              <FormField name="session.pipProgressMeetingDate" placeholder="Date of Discussion" type="date" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'session.pipProgressMeetingDate'} />
              </span>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="session.pipProgressMeetingTime" className="text-default text-capitalize">
                Time of Discussion
              </Label>
              <FormField type={'time'} placeholder="Time of Discussion" name="session.pipProgressMeetingTime" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'session.pipProgressMeetingTime'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="session.pipProgressMeetingLocation" className="text-default text-capitalize">
                Location
              </Label>
              <FormField name="session.pipProgressMeetingLocation" placeholder="Location of Discussion" type="text" />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'session.pipProgressMeetingLocation'} />
              </span>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="session.pipProgressMeetingAttendees" className="text-default">
                Attendees
              </Label>
              <Field name="session.pipProgressMeetingAttendees">
                {({ field }: FieldAttributes<FormikValues>) => (
                  <AsyncSelect
                    {...field}
                    placeholder="Select Attendees"
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptions}
                    closeMenuOnSelect={false}
                    isClearable={false}
                    value={
                      values.pipProgressMeetingAttendees &&
                      values.pipProgressMeetingAttendees.length &&
                      values.pipProgressMeetingAttendees.map((attendee: { [key: string]: string }) => ({
                        label: attendee.attendeeName,
                        value: attendee.attendeeId,
                        attendeeId: attendee.attendeeId,
                        attendeeEmailAddress: attendee.attendeeEmailAddress
                          ? attendee.attendeeEmailAddress
                          : 'No email',
                        attendeeName: attendee.attendeeName,
                      }))
                    }
                    isMulti
                    styles={selectStyles}
                    onChange={(value: ValueType<any>) => handleChange(value, 'session.pipProgressMeetingAttendees')}
                    components={{ ValueContainer }}
                  />
                )}
              </Field>
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'session.pipProgressMeetingAttendees'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <ButtonWithIcons
              title={'View PIP Progress meeting Sessions'}
              buttonType={'btn-bd-purple'}
              handleClick={(): void => setState(oldState => ({ ...oldState, pipProgressMeetingsModalIsOpen: true }))}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <h5 className="text-primary text-capitalize mt-4 font-weight-light">Progress Report</h5>
              <hr style={{ border: '0.06em solid #adb5bd' }} />
            </div>
          </Col>
        </Row>
        <Table bordered className="table-responsive-sm">
          <thead>
            <tr>
              <th className="text-default" style={{ fontSize: '0.8em' }}>
                Performance Shortfall
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Performance / Competency Goal
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Quality Deliverable / Standard
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Deadline / Timeline
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Assistance Required from Employer
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Action to be taken by Employee
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Review Period
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Progress Status
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                <span className="d-flex">
                  <span>Progress Remarks</span>
                  <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                    <Tooltip
                      id="completePIPProgressRemarks"
                      title="Progress Remarks"
                      jsx={
                        <>
                          The employee’s progress against each of the identified performance shortfalls must be
                          monitored, assessed, documented and communicated to the employee. Progress remarks should
                          reflect factual, objective evidence as to whether the employee is now meeting the necessary
                          standards and expectations or not. Support your assessment with specific examples of where the
                          employee has met performance goals or not. Be brief, but specific. Decide on a commensurate
                          progress status per performance goal:
                          <ul>
                            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                              Achieved means that the particular performance shortfall has been successfully and
                              consistently addressed. The improved performance now needs to be sustained.
                            </li>
                            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                              Trending up means that, on balance, the employee is showing good progress and objectively
                              improving, but needs to still close a few gaps or perform at the necessary level
                              consistently, in order to fully address performance goals by the end of the PIP period.
                            </li>
                            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                              Trending down means that, on balance, the employee is not showing sufficient progress,
                              that gaps are still marked, or that performance is highly inconsistent, despite remedial
                              actions in place. Successful completion of the PIP is still possible, but only if the
                              employee drives a quick, consistent and sustained turnaround in performance.
                            </li>
                            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                              Missed means that the employee has made no or little progress and unless a dramatic
                              turnaround happens, is unlikely to complete the PIP successfully.
                            </li>
                          </ul>
                          At the end of the PIP period, assign an overall status and identify further steps, if needed.
                        </>
                      }
                    />
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <FieldArray name="performanceShortfalls">
              {(arrayHelpers: FieldArrayRenderProps) => (
                <>
                  {values.performanceShortfalls &&
                    values.performanceShortfalls.length &&
                    values.performanceShortfalls.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className="position-relative" style={{ width: '10%' }}>
                          <span>{item.shortfallDescription}</span>
                        </td>
                        <td className="position-relative" style={{ width: '10%' }}>
                          <span>{item.performanceGoal}</span>
                        </td>
                        <td className="position-relative" style={{ width: '7.5%' }}>
                          <span>{item.qualityStandard}</span>
                        </td>
                        <td className="position-relative" style={{ width: '7.5%' }}>
                          <span>{item.deadline}</span>
                        </td>
                        <td className="position-relative" style={{ width: '5%' }}>
                          <span>{item.assistanceRequiredFromEmployer}</span>
                        </td>
                        <td className="position-relative" style={{ width: '5%' }}>
                          <span>{item.actionsToBeTakenByEmployee}</span>
                        </td>
                        <td className="position-relative" style={{ width: '8%' }}>
                          <FieldArray name={`performanceShortfalls.${index}.pipProgressUpdates`}>
                            {(arrayHelpers: FieldArrayRenderProps) => (
                              <>
                                {values.performanceShortfalls[index].pipProgressUpdates &&
                                  values.performanceShortfalls[index].pipProgressUpdates.length &&
                                  values.performanceShortfalls[index].pipProgressUpdates.map(
                                    (item: PipProgressUpdate, idx: number) => (
                                      <span key={idx}>
                                        <TableDateTimeFieldWithControls
                                          type={'date'}
                                          placeholder={'Date'}
                                          name={`performanceShortfalls.${index}.pipProgressUpdates.${idx}.reviewPeriod`}
                                          index={idx}
                                          disabled={
                                            idx !== values.performanceShortfalls[index].pipProgressUpdates.length - 1
                                          }
                                        />
                                      </span>
                                    ),
                                  )}
                              </>
                            )}
                          </FieldArray>
                        </td>
                        <td className="position-relative" style={{ width: '8%' }}>
                          <FieldArray name={`performanceShortfalls.${index}.pipProgressUpdates`}>
                            {(arrayHelpers: FieldArrayRenderProps) => (
                              <>
                                {values.performanceShortfalls[index].pipProgressUpdates &&
                                  values.performanceShortfalls[index].pipProgressUpdates.length &&
                                  values.performanceShortfalls[index].pipProgressUpdates.map(
                                    (item: PipProgressUpdate, idx: number) => (
                                      <>
                                        <span key={idx}>
                                          <TableFormField
                                            type={'select'}
                                            placeholder={'Progress Status'}
                                            disabled={
                                              idx !== values.performanceShortfalls[index].pipProgressUpdates.length - 1
                                            }
                                            className={`h-25 rounded-0 border-bottom-0 border-left-0 border-right-0 ${
                                              idx === 0 ? 'border-top-0' : 'border-top-1'
                                            } pr-0 pl-0 py-4`}
                                            name={`performanceShortfalls.${index}.pipProgressUpdates.${idx}.progressStatus`}
                                            selectOptions={[
                                              { label: 'MISSED', value: 'missed' },
                                              { label: 'TRENDING DOWN', value: 'trending_down' },
                                              { label: 'TRENDING UP', value: 'trending_up' },
                                              { label: 'ACHIEVED', value: 'achieved' },
                                              { label: 'N/A', value: 'na' },
                                            ]}
                                          />
                                        </span>
                                        <span className="text-danger">
                                          <ErrorMessage
                                            className="text-danger"
                                            name={`performanceShortfalls.${index}.pipProgressUpdates.${idx}.progressStatus`}
                                          />
                                        </span>
                                      </>
                                    ),
                                  )}
                              </>
                            )}
                          </FieldArray>
                        </td>
                        <td className="position-relative" style={{ width: '15%' }}>
                          <FieldArray name={`performanceShortfalls.${index}.pipProgressUpdates`}>
                            {(helpers: FieldArrayRenderProps) => {
                              return (
                                <>
                                  {values.performanceShortfalls[index].pipProgressUpdates &&
                                    values.performanceShortfalls[index].pipProgressUpdates.length &&
                                    values.performanceShortfalls[index].pipProgressUpdates.map(
                                      (item: PipProgressUpdate, idx: number) => (
                                        <span key={idx}>
                                          <TableFormFieldWithControls
                                            name={`performanceShortfalls.${index}.pipProgressUpdates.${idx}.progressRemarks`}
                                            type={'textarea'}
                                            placeholder={'Progress Remarks'}
                                            className={''}
                                            disabled={
                                              idx !== values.performanceShortfalls[index].pipProgressUpdates.length - 1
                                            }
                                            defaultValues={{
                                              reviewPeriod: '',
                                              progressRemarks: '',
                                            }}
                                            index={idx}
                                            arrayHelpers={helpers}
                                          />
                                        </span>
                                      ),
                                    )}
                                </>
                              );
                            }}
                          </FieldArray>
                        </td>
                        <td
                          className="position-relative"
                          style={{ maxWidth: '1%', minWidth: '1%', width: '1%', borderColor: 'white' }}
                        >
                          {index !== 0 && (
                            <span className="pointer" onClick={(): void => arrayHelpers.remove(index)}>
                              <RemoveIcon />
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </FieldArray>
          </tbody>
        </Table>
        <Row className="my-2">
          <Col
            md={3}
            className="d-flex"
            style={{ cursor: 'pointer' }}
            onClick={(): void => setState(oldState => ({ ...oldState, newShortfallModalIsOpen: true }))}
          >
            <span className="px-1">
              <AddIcon height={'20'} width={'20'} />
            </span>
            <span className="text-default px-1">Add a new Performance Shortfall</span>
          </Col>
        </Row>
      </Form>
      <Modal isOpen={state.newShortfallModalIsOpen} size={'lg'} centered>
        <ModalBody>
          <div
            onClick={(): void => setState(oldState => ({ ...oldState, newShortfallModalIsOpen: false }))}
            className="d-flex justify-content-between"
          >
            <div className="text-default h4">New Performance Shortfall</div>
            <div>
              <CloseIcon />
            </div>
          </div>
          <Formik
            initialValues={{
              shortfallDescription: '',
              date: '',
              documents: null,
              progressUpdates: [
                {
                  date: '',
                  update: '',
                },
              ],
              performanceGoal: '',
              qualityStandard: '',
              deadline: '',
              assistanceRequiredFromEmployer: '',
              actionsToBeTakenByEmployee: '',
              pipProgressUpdates: [
                {
                  progressRemarks: '',
                  reviewPeriod: '',
                  progressStatus: '',
                  progressMeetingId: values.session.meetingId,
                },
              ],
            }}
            validationSchema={NewShortfallValidationSchema}
            onSubmit={(currentValues: FormikValues): void => {
              setFieldValue('performanceShortfalls', values.performanceShortfalls.concat(currentValues));
              setState(oldState => ({ ...oldState, newShortfallModalIsOpen: false }));
            }}
          >
            {({ handleSubmit, values }: FormikProps<FormikValues>): ReactElement => (
              <>
                <Form>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="shortfallDescription" className="text-default text-capitalize">
                          Shortfall Description
                        </Label>
                        <FormField type={'textarea'} placeholder="Enter Description" name="shortfallDescription" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'shortfallDescription'} />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="dateOrPeriod" className="text-default text-capitalize">
                          Date / Period
                        </Label>
                        <FormField type={'date'} placeholder="Select Date" name="date" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'date'} />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="dateOrPeriod" className="text-default text-capitalize">
                          Deadline
                        </Label>
                        <FormField type={'date'} placeholder="Select Date" name="deadline" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'deadline'} />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="performanceGoal" className="text-default text-capitalize">
                          Performance Goal
                        </Label>
                        <FormField type={'text'} placeholder="Enter Performance goal" name="performanceGoal" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'performanceGoal'} />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="assistanceRequiredFromEmployer" className="text-default text-capitalize">
                          Assistance Required from employer
                        </Label>
                        <FormField
                          type={'text'}
                          placeholder="Enter Description"
                          name="assistanceRequiredFromEmployer"
                        />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'assistanceRequiredFromEmployer'} />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="actionsToBeTakenByEmployee" className="text-default text-capitalize">
                          Actions to be taken by the employee
                        </Label>
                        <FormField type={'text'} placeholder="Enter Description" name="actionsToBeTakenByEmployee" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'actionsToBeTakenByEmployee'} />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="qualityStandard" className="text-default text-capitalize">
                          Quality/Standard
                        </Label>
                        <FormField type={'text'} placeholder="Enter Description" name="qualityStandard" />
                        <span className="text-danger">
                          <ErrorMessage className="text-danger" name={'qualityStandard'} />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="text-default text-capitalize">Upload Documents</Label>
                        <UploaderContainer fieldName={'documents'} path={`cases/${processInstanceId}`} isMulti={true} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between">
                    <Button
                      className="btn btn-bd-purple rounded-0 text-uppercase font-weight-normal"
                      onClick={(): void => setState(oldState => ({ ...oldState, newShortfallModalIsOpen: false }))}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn btn-bd-purple rounded-0 text-uppercase font-weight-normal"
                      onClick={(): void => handleSubmit()}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <Modal isOpen={state.pipProgressMeetingsModalIsOpen} size={'lg'} centered>
        <ModalBody>
          <div
            onClick={(): void =>
              setState(oldState => ({
                ...oldState,
                pipProgressMeetingsModalIsOpen: false,
              }))
            }
            className="d-flex justify-content-between"
          >
            <div className="text-default h4">PIP Progress meeting Sessions</div>
            <div>
              <CloseIcon height={'15'} width={'15'} fillColour="#8461C9" />
            </div>
          </div>
          {state.pipProgressMeetingSessions &&
            state.pipProgressMeetingSessions.map((session: SessionHistory, index: number) => (
              <PerformanceCounsellingHistoryAccordion key={index} index={index} data={session} />
            ))}
        </ModalBody>
      </Modal>
    </>
  );
};

export default CompletePipSessionForm;
