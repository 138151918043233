import React, { useContext, useState } from 'react';
import CaseTable from '../../components/tables/CaseTable.component';
import { default as Button } from '../../components/buttons/ButtonWIthIcons.component';
import { PlusIcon } from '../../components/icon/Icon.component';
import { useHistory } from 'react-router';
import { UserContext, UserContextProps, UserDetails } from '../../App';
import Loader from '../../components/loader/Loader';
import { useErrorHandler } from '../../utils/notification-utils';
import { FlowableFunctions } from '../../screens/WorkflowContainer/workflow-utils';
import { AuditLogEventType, CreateEmployeeInput } from '../../API';
import { Employee } from '../../models';
import { createLog } from '../../utils/audit-log-utils';
import { useCaseDataForHistoryTable } from '../../utils/case-utils';
import { post } from '../../utils/api-utils';

interface HistoryProps {
  employee: Employee | CreateEmployeeInput;
  flowableFunctions?: FlowableFunctions;
}
export const startCase = async (
  employee: Employee | CreateEmployeeInput,
  currentUser: UserDetails,
): Promise<{ id: string }> => {
  const res = await post<{ id: string }>('/start-new-case', {
    employeeId: employee.id,
    caseOwnerUserId: currentUser.id,
    organisationId: currentUser.organisationId,
  });
  return res.data;
};

export const History: React.FC<HistoryProps> = (props: HistoryProps) => {
  const handleError = useErrorHandler();
  const history = useHistory();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const [newCaseLoading, setNewCaseLoading] = useState(false);

  const { data, loading } = useCaseDataForHistoryTable({
    employeeId: props.employee.id!,
    organisationId: currentUser!.organisationId!,
  });

  const loc = {
    pathname: `/create-employee/${props.employee.id}/past-case`,
  };

  return (
    <div className="content">
      <div className="d-flex flex-column justify-content-between mt-3 mb-4">
        <div className="d-flex align-self-center">{loading && <Loader />}</div>
        {!loading && (
          <>
            <span className="text-default mb-3"> Current Issues </span>
            <CaseTable
              caseData={data.systemCases}
              auditTrailPath={'/audit-trail/case/'}
              caseNumberPath={'/case/'}
              showActionButtons={true}
            />
            {!props.flowableFunctions && (
              <div className="pt-2">
                {currentUser && (
                  <Button
                    title={'start new case'}
                    buttonType={'btn-simple'}
                    leftIcon={newCaseLoading ? <Loader /> : <PlusIcon fill="#8461C9" />}
                    disabled={!(props.employee.id && currentUser) || newCaseLoading}
                    handleClick={async (): Promise<void> => {
                      setNewCaseLoading(true);
                      startCase(props.employee, currentUser)
                        .then(res => {
                          history.push(`/case/${res.id}`);
                          setNewCaseLoading(false);
                          if (currentUser) {
                            createLog(currentUser, AuditLogEventType.CASE_STARTED, res.id).catch(error =>
                              handleError(error),
                            );
                          }
                        })
                        .catch((error: any) => handleError(error));
                    }}
                  />
                )}
              </div>
            )}
            <span className="text-default mb-2 mt-4"> Past Issues </span>
            <CaseTable
              caseData={data.pastCases}
              auditTrailPath={'/audit-trail/case/'}
              caseNumberPath={`${loc.pathname}/`}
              showActionButtons={false}
            />
            {!props.flowableFunctions && (
              <div className="pt-2">
                <Button
                  title={'add past case'}
                  buttonType={'btn-simple'}
                  leftIcon={<PlusIcon fill="#8461C9" />}
                  handleClick={(): void => history.push(loc)}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
