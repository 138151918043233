import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Modal, ModalBody, Table, FormGroup, Label } from 'reactstrap';
import { FormikProps, FormikValues, useFormikContext, FieldArray, FieldArrayRenderProps, ErrorMessage } from 'formik';
import { CloseIcon, RemoveIcon } from '../../../../components/icon/Icon.component';
import ButtonWithIcons from '../../../../components/buttons/ButtonWIthIcons.component';
import { PipProgressMeeting } from './RecordPIPProgressMeeting';
import PerformanceCounsellingHistoryAccordion, {
  SessionHistory,
} from '../../../../components/PeformanceCounsellingHistory/PerformanceCounsellingHistoryAccordion';
import TableFormFieldWithControls from '../../../../forms/fields/TableFormFieldWithControls';
import TableFormField from '../../../../forms/fields/TableFormField';
import TableDateTimeFieldWithControls from '../../../../forms/fields/TableDateTimeFieldWithControls';
import { Tooltip } from '../../../../components/tooltips/Tooltip.component';
import FormField from '../../../../forms/fields/FormField.component';
import { v4 as uuidV4 } from 'uuid';
import InputRow from '../../../../components/inputRow/InputRow.component';
import { CasePerformanceShortfall, PipProgressUpdate } from '../../../WorkflowContainer/workflow-utils';

interface RecordPIPProgressMeetingFormProps {
  processInstanceId: string;
  pipType: string;
}

interface RecordPIPProgressMeetingFormState {
  newShortfallModalIsOpen: boolean;
  pipProgressMeetingsModalIsOpen: boolean;
  pipProgressMeetingSessions: SessionHistory[];
  allSessionsHaveBeenCompleted: boolean;
}

const initialState: RecordPIPProgressMeetingFormState = {
  newShortfallModalIsOpen: false,
  pipProgressMeetingsModalIsOpen: false,
  pipProgressMeetingSessions: [],
  allSessionsHaveBeenCompleted: false,
};

const RecordPIPProgressMeetingForm: React.FC<RecordPIPProgressMeetingFormProps> = (
  props: RecordPIPProgressMeetingFormProps,
) => {
  const { values, handleSubmit }: FormikProps<FormikValues> = useFormikContext();
  const [state, setState] = useState<RecordPIPProgressMeetingFormState>(initialState);

  useEffect(() => {
    if (values.pipProgressMeetings && values.pipProgressMeetings.length) {
      const sessions: SessionHistory[] = values.pipProgressMeetings.map((session: PipProgressMeeting) => {
        const item: SessionHistory = {
          sessionId: session.meetingId,
          date: session.pipProgressMeetingDate,
          time: session.pipProgressMeetingTime,
          location: session.pipProgressMeetingLocation,
          attendees: session.pipProgressMeetingAttendees,
          sessionCompleted: session.sessionCompleted,
        };

        return item;
      });

      const pendingSessions = sessions.filter(session => !session.sessionCompleted).length;
      setState(oldState => ({
        ...oldState,
        pipProgressMeetingSessions: sessions,
        allSessionsHaveBeenCompleted: !pendingSessions,
      }));
    }
  }, [JSON.stringify(values.pipProgressMeetings)]);

  return (
    <>
      <Form>
        <Row className="my-2">
          <Col md={4} sm={4} lg={4}>
            <FormGroup>
              <Label for="pipStartDate" className="text-default text-capitalize">
                {props.pipType === 'EXTENDED_PIP' ? 'Extended PIP start date' : 'PIP start date'}
              </Label>
              <FormField type={'date'} placeholder="Select Date" name="pipStartDate" disabled={true} />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'pipStartDate'} />
              </span>
            </FormGroup>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <FormGroup>
              <Label for="pipEndDate" className="text-default text-capitalize">
                {props.pipType === 'EXTENDED_PIP' ? 'Extended PIP end date' : 'PIP end date'}
              </Label>
              <FormField type={'date'} placeholder="Select Date" name="pipEndDate" disabled={true} />
              <span className="text-danger">
                <ErrorMessage className="text-danger" name={'pipEndDate'} />
              </span>
            </FormGroup>
          </Col>
        </Row>
        <FieldArray name="pipProgressMeetings">
          {(arrayHelpers: FieldArrayRenderProps) => (
            <>
              {!values.pipProgressMeetings &&
                arrayHelpers.push({
                  meetingId: uuidV4(),
                  pipProgressMeetingDate: '',
                  pipProgressMeetingTime: '',
                  pipProgressMeetingLocation: '',
                  pipProgressMeetingAttendees: '',
                  sessionCompleted: false,
                })}
              {values.pipProgressMeetings &&
                values.pipProgressMeetings.length &&
                values.pipProgressMeetings.map((item: PipProgressMeeting, index: number) => (
                  <InputRow
                    key={index}
                    index={index}
                    addCallback={arrayHelpers.push}
                    removeCallback={arrayHelpers.remove}
                    defaultValues={{
                      meetingId: uuidV4(),
                      pipProgressMeetingDate: '',
                      pipProgressMeetingTime: '',
                      pipProgressMeetingLocation: '',
                      pipProgressMeetingAttendees: '',
                      sessionCompleted: false,
                    }}
                    disabled={(item.sessionCompleted && index !== 0) || values.sessionsCompleted}
                  >
                    <>
                      <Col md={4}>
                        <FormGroup>
                          <Label className="text-default">Scheduled Progress Meeting Date*</Label>
                          <FormField
                            name={`pipProgressMeetings.${index}.pipProgressMeetingDate`}
                            placeholder={'Meeting Date'}
                            type={'date'}
                            disabled={item.sessionCompleted || values.sessionsCompleted}
                          />
                          <span className="text-danger">
                            <ErrorMessage name={`pipProgressMeetings.${index}.pipProgressMeetingDate`} />
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label className="text-default">Scheduled Progress Meeting Time*</Label>
                          <FormField
                            name={`pipProgressMeetings.${index}.pipProgressMeetingTime`}
                            placeholder={'Meeting Time'}
                            type={'time'}
                            disabled={item.sessionCompleted || values.sessionsCompleted}
                          />
                          <span className="text-danger">
                            <ErrorMessage name={`pipProgressMeetings.${index}.pipProgressMeetingTime`} />
                          </span>
                        </FormGroup>
                      </Col>
                      {item.sessionCompleted && <i className="tim-icons icon-check-2 text-success mt-md-5" />}
                    </>
                  </InputRow>
                ))}
            </>
          )}
        </FieldArray>
        <Row>
          <Col md={4}>
            <ButtonWithIcons
              title={'Start Next Session'}
              buttonType={'btn-bd-purple'}
              disabled={
                !values.pipProgressMeetings.filter((session: PipProgressMeeting) => !session.sessionCompleted).length
              }
              handleClick={(): void => handleSubmit()}
            />
          </Col>
          <Col md={4}>
            <ButtonWithIcons
              title={
                props.pipType === 'EXTENDED_PIP'
                  ? 'View Extended PIP Progress meeting Sessions'
                  : 'View PIP Progress meeting Sessions'
              }
              buttonType={'btn-bd-purple'}
              handleClick={(): void => setState(oldState => ({ ...oldState, pipProgressMeetingsModalIsOpen: true }))}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <h5 className="text-primary text-capitalize mt-4 font-weight-light">Progress Report</h5>
              <hr style={{ border: '0.06em solid #adb5bd' }} />
            </div>
          </Col>
        </Row>
        <Table bordered className="table-responsive-sm">
          <thead>
            <tr>
              <th className="text-default" style={{ fontSize: '0.8em' }}>
                Performance Shortfall
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Performance / Competency Goal
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Quality Deliverable / Standard
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Deadline / Timeline
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Assistance Required from Employer
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Action to be taken by Employee
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Review Period
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                Progress Status
              </th>
              <th className="text-default font-weight-bold" style={{ fontSize: '0.8em' }}>
                <span className="d-flex">
                  <span>Progress Remarks</span>
                  <span style={{ width: '60%' }} className="align-self-center pl-2 pointer">
                    <Tooltip
                      id="progressRemarks"
                      title="Progress Remarks"
                      jsx={
                        <>
                          The employee’s progress against each of the identified performance shortfalls must be
                          monitored, assessed, documented and communicated to the employee. Progress remarks should
                          reflect factual, objective evidence as to whether the employee is now meeting the necessary
                          standards and expectations or not. Support your assessment with specific examples of where the
                          employee has met performance goals or not. Be brief, but specific. Decide on a commensurate
                          progress status per performance goal:
                          <ul>
                            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                              Achieved means that the particular performance shortfall has been successfully and
                              consistently addressed. The improved performance now needs to be sustained.
                            </li>
                            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                              Trending up means that, on balance, the employee is showing good progress and objectively
                              improving, but needs to still close a few gaps or perform at the necessary level
                              consistently, in order to fully address performance goals by the end of the PIP period.
                            </li>
                            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                              Trending down means that, on balance, the employee is not showing sufficient progress,
                              that gaps are still marked, or that performance is highly inconsistent, despite remedial
                              actions in place. Successful completion of the PIP is still possible, but only if the
                              employee drives a quick, consistent and sustained turnaround in performance.
                            </li>
                            <li style={{ listStyle: 'initial', marginLeft: '30px' }}>
                              Missed means that the employee has made no or little progress and unless a dramatic
                              turnaround happens, is unlikely to complete the PIP successfully.
                            </li>
                          </ul>
                          At the end of the PIP period, assign an overall status and identify further steps, if needed.
                        </>
                      }
                    />
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <FieldArray name="performanceShortfalls">
              {(arrayHelpers: FieldArrayRenderProps) => (
                <>
                  {values.performanceShortfalls &&
                    values.performanceShortfalls.length &&
                    values.performanceShortfalls.map((item: CasePerformanceShortfall, index: number) => (
                      <tr key={index}>
                        <td className="position-relative" style={{ width: '10%' }}>
                          <span>{item.shortfallDescription}</span>
                        </td>
                        <td className="position-relative" style={{ width: '10%' }}>
                          <span>{item.performanceGoal}</span>
                        </td>
                        <td className="position-relative" style={{ width: '7.5%' }}>
                          <span>{item.qualityStandard}</span>
                        </td>
                        <td className="position-relative" style={{ width: '7.5%' }}>
                          <span>{item.deadline}</span>
                        </td>
                        <td className="position-relative" style={{ width: '5%' }}>
                          <span>{item.assistanceRequiredFromEmployer}</span>
                        </td>
                        <td className="position-relative" style={{ width: '5%' }}>
                          <span>{item.actionsToBeTakenByEmployee}</span>
                        </td>
                        <td className="position-relative" style={{ width: '10%' }}>
                          <FieldArray name={`performanceShortfalls.${index}.pipProgressUpdates`}>
                            {(arrayHelpers: FieldArrayRenderProps) => (
                              <>
                                {values.performanceShortfalls[index].pipProgressUpdates &&
                                  values.performanceShortfalls[index].pipProgressUpdates.length &&
                                  values.performanceShortfalls[index].pipProgressUpdates.map(
                                    (item: PipProgressUpdate, idx: number) => (
                                      <span key={idx}>
                                        <TableDateTimeFieldWithControls
                                          type={'date'}
                                          placeholder={'Date'}
                                          name={`performanceShortfalls.${index}.pipProgressUpdates.${idx}.reviewPeriod`}
                                          index={idx}
                                          disabled={true}
                                        />
                                      </span>
                                    ),
                                  )}
                              </>
                            )}
                          </FieldArray>
                        </td>
                        <td className="position-relative" style={{ width: '15%' }}>
                          <FieldArray name={`performanceShortfalls.${index}.pipProgressUpdates`}>
                            {(arrayHelpers: FieldArrayRenderProps) => (
                              <>
                                {values.performanceShortfalls[index].pipProgressUpdates &&
                                  values.performanceShortfalls[index].pipProgressUpdates.length &&
                                  values.performanceShortfalls[index].pipProgressUpdates.map(
                                    (item: PipProgressUpdate, idx: number) => (
                                      <span key={idx}>
                                        <TableFormField
                                          type={'select'}
                                          placeholder={'Progress Status'}
                                          className={`h-25 rounded-0 border-bottom-0 border-left-0 border-right-0 ${
                                            idx === 0 ? 'border-top-0' : 'border-top-1'
                                          } pr-0 pl-0 py-4`}
                                          name={`performanceShortfalls.${index}.pipProgressUpdates.${idx}.progressStatus`}
                                          selectOptions={[
                                            { label: 'missed', value: 'missed' },
                                            { label: 'trending down', value: 'trending_down' },
                                            { label: 'trending up', value: 'trending_up' },
                                            { label: 'achieved', value: 'achieved' },
                                          ]}
                                          disabled={true}
                                        />
                                      </span>
                                    ),
                                  )}
                              </>
                            )}
                          </FieldArray>
                        </td>
                        <td className="position-relative" style={{ width: '15%' }}>
                          <FieldArray name={`performanceShortfalls.${index}.pipProgressUpdates`}>
                            {(helpers: FieldArrayRenderProps) => (
                              <>
                                {!values.performanceShortfalls[index].pipProgressUpdates &&
                                  helpers.push({ progressRemarks: '', reviewPeriod: '', progressStatus: '' })}
                                {values.performanceShortfalls[index].pipProgressUpdates &&
                                  values.performanceShortfalls[index].pipProgressUpdates.length &&
                                  values.performanceShortfalls[index].pipProgressUpdates.map(
                                    (item: PipProgressUpdate, idx: number) => (
                                      <span key={idx}>
                                        <TableFormFieldWithControls
                                          name={`performanceShortfalls.${index}.pipProgressUpdates.${idx}.progressRemarks`}
                                          type={'textarea'}
                                          placeholder={'Progress Remarks'}
                                          className={''}
                                          disabled={true}
                                          defaultValues={{
                                            reviewPeriod: '',
                                            progressRemarks: '',
                                          }}
                                          index={idx}
                                          arrayHelpers={helpers}
                                        />
                                      </span>
                                    ),
                                  )}
                              </>
                            )}
                          </FieldArray>
                        </td>
                        <td
                          className="position-relative"
                          style={{ maxWidth: '1%', minWidth: '1%', width: '1%', borderColor: 'white' }}
                        >
                          {index !== 0 && (
                            <span onClick={(): void => arrayHelpers.remove(index)}>
                              <RemoveIcon />
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </FieldArray>
          </tbody>
        </Table>
      </Form>
      <Modal isOpen={state.pipProgressMeetingsModalIsOpen} size={'lg'} centered>
        <ModalBody>
          <div
            onClick={(): void =>
              setState(oldState => ({
                ...oldState,
                pipProgressMeetingsModalIsOpen: false,
              }))
            }
            className="d-flex justify-content-between"
          >
            <div className="text-default h4">PIP Progress meeting Sessions</div>
            <div>
              <CloseIcon height={'15'} width={'15'} fillColour="#8461C9" />
            </div>
          </div>
          {state.pipProgressMeetingSessions &&
            state.pipProgressMeetingSessions.map((session: SessionHistory, index: number) => (
              <PerformanceCounsellingHistoryAccordion key={index} index={index} data={session} />
            ))}
        </ModalBody>
      </Modal>
    </>
  );
};

export default RecordPIPProgressMeetingForm;
