import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { ScheduleAdditionalCounsellingSessionSchema } from '../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import ScheduleAdditionalCounsellingSessionForm from './ScheduleAdditionalCounsellingSessionForm';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../WorkflowContainer/workflow-utils';
import { CounsellingSession } from '../RecordCounselling/RecordCounselling';
import { v4 as uuidV4 } from 'uuid';

const ScheduleAdditionalCounsellingSession: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;

  const fields = {
    additionalSessions: 'boolean',
    compileInvite: 'boolean',
    counsellingHavePerformanceConcernsBeenAddressed: 'boolean',
    reasonForFurtherActionAfterCounselling: 'string',
    furtherActionAfterCounselling: 'string',
    performanceCounsellingSessions: 'array',
  };

  const [state, setState] = useState(getFormDataFromCaseData(fields, caseData, caseData.isAppeal));

  useEffect(() => {
    // todo: create ts type for counselling session when variables are finalised
    const completedSessions = state.performanceCounsellingSessions.filter((session: any) => session.sessionCompleted);
    const onGoingSessions = state.performanceCounsellingSessions.filter((session: any) => !session.sessionCompleted);
    const newSession: CounsellingSession = {
      sessionId: uuidV4(),
      counsellingDate: '',
      counsellingTime: '',
      counsellingAttendees: null,
      counsellingLocation: '',
      sessionCompleted: false,
    };
    setState(oldState => ({
      ...oldState,
      performanceCounsellingSessions: completedSessions,
      counsellingSession: onGoingSessions.length ? onGoingSessions[onGoingSessions.length - 1] : newSession,
    }));
  }, []);

  const getFormValuesForSubmission = (values: FormikValues): Record<string, any> => {
    const formValues = { ...values };

    if (formValues.additionalSessions) {
      formValues.performanceCounsellingSessions = [...values.performanceCounsellingSessions, values.counsellingSession];
    }

    formValues.performanceCounsellingSessions = JSON.stringify(formValues.performanceCounsellingSessions);
    delete formValues.counsellingSession;
    return filterFormData(formValues, fields);
  };

  const submitValues = async (values: FormikValues): Promise<void> => {
    flowableFunctions.onNext(getFormValuesForSubmission(values));
  };

  return (
    <div>
      <h4 className="text-default text-capitalize font-weight-bold">
        Conduct Additional Performance Counselling if required
      </h4>
      <Formik
        enableReinitialize
        initialValues={state}
        validationSchema={ScheduleAdditionalCounsellingSessionSchema}
        onSubmit={submitValues}
      >
        {({ values, errors, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
          <>
            <ScheduleAdditionalCounsellingSessionForm
              data={props.data}
              getFormValuesForSubmission={getFormValuesForSubmission}
            />
            <WorkFlowFooter
              data={props.data}
              onCancel={(): void => console.log('cancel')}
              onNext={handleSubmit}
              onSaveAndClose={() => props.data.flowableFunctions.onSaveAndClose(values)}
              finalPage={values.counsellingHavePerformanceConcernsBeenAddressed}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default ScheduleAdditionalCounsellingSession;
