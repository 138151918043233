import { startCase } from 'lodash';
import React, { Component } from 'react';
import { listActiveEmployeesByOrganisationId } from '../../utils/graphql-utils';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    let message = this.props.message;

    //missing error message
    if (error.message.includes('multiple roots')) {
      message = `${this.props.message}, multiple employees are designated to the top of the reporting line`;
    }

    //missing error message
    if (error.message.includes('missing')) {
      listActiveEmployeesByOrganisationId(this.props.currentUser.organisationId)
        .then(res => {
          const wrongEmployee = res.filter(el => el.directManagerID === error.message.split(' ')[1]);
          if (wrongEmployee.length) {
            message = `${this.props.message}, Please assign a line manager to  employee ${startCase(
              wrongEmployee[0].firstName,
            )} ${startCase(wrongEmployee[0].lastName)}`;
            this.setState({
              hasError: error,
              errorInfo: errorInfo,
              message,
            });
          }
          {
            this.setState({
              hasError: error,
              errorInfo: errorInfo,
              message,
            });
          }
        })
        .catch(err => console.log(err));
    } else {
      this.setState({
        hasError: error,
        errorInfo: errorInfo,
        message,
      });
    }
  }
  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '50px 0px 0px 260px', textAlign: 'center' }}>
          <i style={{ color: '#8965e0' }} className="fas fa-exclamation-triangle fa-3x"></i>
          <h2 style={{ color: '#8965e0' }}>{this.state.message}</h2>
        </div>
      );
    }
    return this.props.children;
  }
}
