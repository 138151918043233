import React from 'react';
import TopBarComponent from '../../components/topBar/TopBar.component';

export const MisconductInfoScreen: React.FC = () => {
  return (
    <>
      <TopBarComponent
        title={'Misconduct'}
        subTitle={'View Misconduct Module Information'}
        hideSearch={true}
      ></TopBarComponent>
      <div className="content">
        <div className="text-muted px-4 pt-4" style={{ fontSize: '16px' }}>
          The schematics below provides a roadmap of the process you will follow in LabourTeq as you begin, execute and
          close Misconduct cases. Intuitive flows and resources will guide you through each step of a case.
          <br />
          The appeal schematic and process in LabourTeq is relevant only if your companys Disciplinary Code and Policy
          allows for an appeal process.
          <br />
          <div>
            <img src={require('../../assets/img/Misconduct.png')} alt={'Misconduct Flow'} />
          </div>
          <div>
            <img src={require('../../assets/img/Appeal.png')} alt={'Appeal Flow'} />
          </div>
        </div>
      </div>
    </>
  );
};
