import { Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { BucketFile } from '../../models';

interface FileDisplayProps {
  file: BucketFile | File;
  index?: number;
  removeDocumentCallback?: (documentKey: string) => void;
}

export const isBucketFile = (file: BucketFile | File): boolean => {
  return !!(file as BucketFile).key && !!(file as BucketFile).url && !!(file as BucketFile).fileName;
};

const FileDisplay: React.FC<FileDisplayProps> = (props: FileDisplayProps) => {
  const [documentUrl, setDocumentUrl] = useState();
  const { file } = props;

  const getDocumentUrl = async (key: string): Promise<Record<string, any> | string> => {
    return new Promise((resolve, reject) => {
      Storage.get(key, { level: 'public' })
        .then(item => {
          if (item) {
            resolve(item);
          } else reject(new Error('could not get key'));
        })
        .catch(error => reject(error));
    });
  };

  const getFileName = (): string => {
    if (isBucketFile(file)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return file.fileName;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return file.name;
    }
  };

  const getRemoveKey = (): string => {
    if (isBucketFile(file)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return file.key;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return file.name;
    }
  };

  useEffect(() => {
    if (isBucketFile(file)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getDocumentUrl(file.key).then(documentUrl => setDocumentUrl(documentUrl));
    }
  }, []);

  return (
    <span className="text-default ml-3 my-2 w-100" style={{ width: 'fit-content' }}>
      <span className="d-flex justify-content-between w-auto">
        <div className="d-flex flex-column">
          <a className="text-success col-sm-md mx-2">
            <i className="fa fa-file fa-3x" />
          </a>
        </div>
        <div className="d-flex">
          <a href={documentUrl} className="text-success col-sm-md mr-1">
            <span
              className="col-sm btn-default mx-1 pb-2 pt-1 border-3"
              style={{ borderRadius: '10%', maxWidth: '0.5%', backgroundColor: '#50E3C2' }}
            >
              <i className="fa fa-download" />
            </span>
          </a>
          <span
            className="text-danger col-sm-md ml-1"
            style={{ cursor: 'pointer' }}
            onClick={(): void => {
              if (props.removeDocumentCallback) props.removeDocumentCallback(getRemoveKey());
            }}
          >
            <span className="col-sm btn-danger mx-1 pb-2 pt-1" style={{ borderRadius: '10%', maxWidth: '0.5%' }}>
              <i className="tim-icons icon-simple-remove" />
            </span>
          </span>
        </div>
      </span>
      <p
        className="text-success mr-4 mw-100"
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {getFileName()}
      </p>
    </span>
  );
};

export default FileDisplay;
