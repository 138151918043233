import React, { ReactElement } from 'react';
import { Col, Row } from 'reactstrap';
import { Formik, FormikProps, FormikValues } from 'formik';
import { filterFormData, getFormDataFromCaseData } from '../../../../../WorkflowContainer/workflow-utils';
import { HEARING_TABS } from '../../../../../../utils/case-utils';
import Tabs from '../../../../../../components/tabs/Tabs.component';
import { DocumentVersionControlTable } from '../../../../../../components/documentVersionControlTable/DocumentVersionControlTable';
import { WorkFlowFooter } from '../../../../../../components/workflowFooter/WorkFlowFooter';
import { CaseType } from '../../../../../../API';
import { initial_hearing_misconduct_executeOutcomeSuspensionWithoutPay } from './config';
import { InitialHearingExecuteOutcomeSuspensionWithoutPayForm } from './InitialHearingExecuteOutcomeSuspensionWithoutPayForm';
import { ExecuteOutcomeProps } from '../InitialHearingExecuteOutcome';

export const InitialHearingExecuteOutcomeSuspensionWithoutPay: React.FC<ExecuteOutcomeProps> = props => {
  const { caseData, flowableFunctions } = props.data;
  const { fields, validationSchema, templateType } = initial_hearing_misconduct_executeOutcomeSuspensionWithoutPay;
  if (!templateType) {
    throw new Error('Missing templateType');
  }
  const initialValues = getFormDataFromCaseData(fields, caseData);

  const submitValues = (values: FormikValues): void => {
    const formValues = { ...values };
    if (props.data.caseType === CaseType.MISCONDUCT) {
      formValues.incidents = JSON.stringify(values.incidents);
      formValues.periodOfUnpaidSuspension = JSON.stringify(values.periodOfUnpaidSuspension);
    } else if (props.data.caseType === CaseType.POOR_PERFORMANCE) {
      formValues.performanceShortfalls = JSON.stringify(values.performanceShortfalls);
    }
    flowableFunctions.onNext(filterFormData(formValues, fields));
  };

  return (
    <div className="content">
      <h4 className="text-h4 text-capitalize font-weight-500 mt-3">Conclude Hearing</h4>
      <Row>
        <Col md={12}>
          <Tabs page={9} tabs={HEARING_TABS} />
        </Col>
      </Row>
      <h5 className="text-h5 text-blue text-capitalize font-weight-500 mt-3"> Execute Sanction outcomes</h5>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={submitValues}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit }: FormikProps<FormikValues>): ReactElement => (
          <>
            <InitialHearingExecuteOutcomeSuspensionWithoutPayForm caseType={CaseType.MISCONDUCT} />
            <button
              className="btn prepare-letter-button mb-3"
              onClick={(): void => {
                props.data.flowableFunctions.onOpenNewDocument(values, fields, templateType);
              }}
            >
              PREPARE SUSPENSION WITHOUT PAY LETTER
            </button>
            {props.data.processInstanceId ? (
              <DocumentVersionControlTable
                data={props.data}
                processInstanceId={props.data.processInstanceId}
                templateType={templateType}
                completeTask={props.data.flowableFunctions.completeTask}
                additionalAttachmentFormKeys={props.additionalBucketKeys}
              />
            ) : null}
            <WorkFlowFooter
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => {
                props.data.flowableFunctions.onSaveAndClose(values);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
