import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { Auth } from 'aws-amplify/lib';
import awsConfig from '../aws-exports';
import { CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';

const getCognitoProvider = (): Promise<CognitoIdentityServiceProvider> => {
  return new Promise((resolve, error) => {
    Auth.currentCredentials()
      .then(credentials => {
        const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider({
          region: 'eu-central-1',
          credentials: Auth.essentialCredentials(credentials),
        });
        resolve(cognitoIdentityServiceProvider);
      })
      .catch(e => error(e));
  });
};

export const getCognitoUserAttributes = (
  attributeNames: string[],
  attributes: CognitoUserAttribute[] | undefined,
): { [key: string]: CognitoUserAttribute | undefined } => {
  const attributesArray = attributeNames.map(attributeName => {
    if (attributes) {
      const variable = attributes.find(attribute => attribute.getName() === attributeName);
      return [attributeName, variable];
    } else return ['default', undefined];
  });

  return Object.fromEntries(attributesArray);
};

export const createCognitoUser = (
  emailAddress: string,
  temporaryPassword: string,
  resendInvite?: boolean,
): Promise<CognitoIdentityServiceProvider.AdminCreateUserResponse> => {
  return new Promise((resolve, reject) => {
    getCognitoProvider()
      .then(service => {
        const params = {
          UserPoolId: awsConfig.aws_user_pools_id,
          Username: emailAddress,
          TemporaryPassword: temporaryPassword,
          UserAttributes: [
            {
              Name: 'email',
              Value: emailAddress,
            },
            {
              Name: 'email_verified',
              Value: 'True',
            },
            // Hack to circumvent aws cognito password from sending on resend 
            {
              Name: 'phone_number',
              Value: '+27645230147',
            },
            {
              Name: 'phone_number_verified',
              Value: 'True',
            }
          ],
          DesiredDeliveryMediums: resendInvite ? ['SMS'] : ['EMAIL'],
          MessageAction: resendInvite ? 'RESEND' : 'SUPPRESS',
        } as CognitoIdentityServiceProvider.AdminCreateUserRequest;
        service.adminCreateUser(params, (error, data) => {
          data ? resolve(data) : reject(error.message);
        });
      })
      .catch(e => console.error(e));
  });
};

export const getCognitoUser = (
  username: string,
): Promise<CognitoIdentityServiceProvider.Types.AdminGetUserResponse> => {
  return new Promise((resolve, reject) => {
    getCognitoProvider().then(service => {
      const params: CognitoIdentityServiceProvider.AdminGetUserRequest = {
        UserPoolId: awsConfig.aws_user_pools_id,
        Username: username,
      };
      service.adminGetUser(params, (error, data) => {
        data ? resolve(data) : reject(error.message);
      });
    });
  });
};

export const enableCognitoUser = (
  username: string,
): Promise<CognitoIdentityServiceProvider.Types.AdminEnableUserResponse> => {
  return new Promise((resolve, reject) => {
    getCognitoProvider().then(service => {
      const params: CognitoIdentityServiceProvider.AdminEnableUserRequest = {
        UserPoolId: awsConfig.aws_user_pools_id,
        Username: username,
      };
      service.adminEnableUser(params, (error, data) => {
        data ? resolve(data) : reject(error.message);
      });
    });
  });
};

export const deleteCognitoUser = (username: string): Promise<{ data: Record<string, unknown>; message: 'deleted' }> => {
  return new Promise((resolve, reject) => {
    getCognitoProvider().then(service => {
      const params: CognitoIdentityServiceProvider.AdminDeleteUserRequest = {
        UserPoolId: awsConfig.aws_user_pools_id,
        Username: username,
      };
      service.adminDeleteUser(params, (error, data) => {
        data ? resolve({ data: data, message: 'deleted' }) : reject(error.message);
      });
    });
  });
};

export const disableCognitoUser = (
  username: string,
): Promise<{ data: CognitoIdentityServiceProvider.Types.AdminDisableUserResponse; message: 'disabled' }> => {
  return new Promise((resolve, reject) => {
    getCognitoProvider().then(service => {
      const params: CognitoIdentityServiceProvider.AdminDisableUserRequest = {
        UserPoolId: awsConfig.aws_user_pools_id,
        Username: username,
      };
      service.adminDisableUser(params, (error, data) => {
        data ? resolve({ data, message: 'disabled' }) : reject(error.message);
      });
    });
  });
};

export const verifyUser = (
  user: CognitoUser,
): Promise<CognitoIdentityServiceProvider.Types.AdminUpdateUserAttributesResponse> => {
  return new Promise((resolve, reject) => {
    user.getUserAttributes(async (error, attributes) => {
      if (attributes) {
        const { email_verified, email } = getCognitoUserAttributes(['email_verified', 'email'], attributes);
        const emailVerifiedValue = !!(email_verified && email_verified.getValue() === 'true');
        if (email && !emailVerifiedValue) {
          getCognitoProvider().then(service => {
            const params: CognitoIdentityServiceProvider.AdminUpdateUserAttributesRequest = {
              UserPoolId: awsConfig.aws_user_pools_id,
              Username: user.getUsername(),
              UserAttributes: [
                {
                  Name: 'email_verified',
                  Value: 'true',
                },
              ],
            };
            service.adminUpdateUserAttributes(params, (error, data) => {
              data ? resolve(data) : reject(error.message);
            });
          });
        }
      }
    });
  });
};

export const updateEmail = (
  userName: string,
  newEmailAddress: string,
): Promise<CognitoIdentityServiceProvider.Types.AdminUpdateUserAttributesResponse> => {
  return new Promise((resolve, reject) => {
    getCognitoProvider().then(service => {
      const params: CognitoIdentityServiceProvider.AdminUpdateUserAttributesRequest = {
        UserPoolId: awsConfig.aws_user_pools_id,
        Username: userName,
        UserAttributes: [
          {
            Name: 'email',
            Value: newEmailAddress,
          },
        ],
      };
      service.adminUpdateUserAttributes(params, (error, data) => {
        data ? resolve(data) : reject(error.message);
      });
    });
  });
};

export const setCognitoUserPassword = (
  username: string,
): Promise<CognitoIdentityServiceProvider.AdminSetUserPasswordResponse> => {
  return new Promise((resolve, reject) => {
    getCognitoProvider().then(service => {
      const adminSetPasswordParams: CognitoIdentityServiceProvider.AdminSetUserPasswordRequest = {
        UserPoolId: awsConfig.aws_user_pools_id,
        Username: username,
        Password: 'Password$123#',
        Permanent: true,
      };
      service.adminSetUserPassword(adminSetPasswordParams, (error, data) => {
        data ? resolve(data) : reject(error.message);
      });
    });
  });
};

export const resetCognitoUserPassword = async (
  username: string,
): Promise<{ data: Record<string, unknown>; message: 'password reset' }> => {
  const user = await getCognitoUser(username);
  return new Promise((resolve, reject) => {
    getCognitoProvider().then(async service => {
      const password = Math.random().toString(36).slice(-8);
      const params: CognitoIdentityServiceProvider.AdminSetUserPasswordRequest = {
        UserPoolId: awsConfig.aws_user_pools_id,
        Username: user.Username,
        Password: password,
        Permanent: false
      };

      if (user.UserStatus === 'FORCE_CHANGE_PASSWORD') {
        setCognitoUserPassword(user.Username)
          .then(() => {
            service.adminSetUserPassword(params, (error, data) => {
              console.log('224',password)
              data ? resolve({ data: { password }, message: 'password reset' }) : reject(error.message);
            });
          })
          .catch(error => {
            reject(error.message);
          });
      } else {
        service.adminSetUserPassword(params, (error, data) => {
          console.log('233',password)
          data ? resolve({ data: { password }, message: 'password reset' }) : reject(error.message);
        });
      }
    });
  });
};

// export const updateOrganisationId = (userName: string, newOrganisationId: string): Promise<{}> => {
//   return new Promise((resolve, reject) => {
//     getCognitoProvider().then(service => {
//       const params: CognitoIdentityServiceProvider.AdminUpdateUserAttributesRequest = {
//         UserPoolId: awsConfig.aws_user_pools_id,
//         Username: userName,
//         UserAttributes: [
//           {
//             Name: 'custom:organisationId',
//             Value: newOrganisationId,
//           },
//         ],
//       };
//       service.adminUpdateUserAttributes(params, (error, data) => {
//         data ? resolve(data) : reject(error.message);
//       });
//     });
//   });
// };
