import React, { ChangeEvent, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Alert, Button, CardFooter, FormGroup, Input } from 'reactstrap';

interface ForgotPasswordSubmissionViewProps {
  handleCancelForgotPassword: () => void;
  onComplete: () => void;
  username: string;
}

const INITIAL_FORGOT_DETAILS = { step: 1, code: '', password: '', confirmPassword: '', error: '', loading: false };

const ForgotPasswordSubmissionView: React.FC<ForgotPasswordSubmissionViewProps> = (
  props: ForgotPasswordSubmissionViewProps,
) => {
  const [state, setState] = useState(INITIAL_FORGOT_DETAILS);
  const { code, password, confirmPassword, loading, error } = state;
  const { handleCancelForgotPassword, onComplete, username } = props;

  const handleChangeForgotCode = (event: ChangeEvent<HTMLInputElement>): void => {
    const temp = event.target.value;
    setState(oldState => ({ ...oldState, code: temp }));
  };
  const handleChangeForgotPassword = (event: ChangeEvent<HTMLInputElement>): void => {
    const password = event.target.value;
    setState(oldState => ({ ...oldState, password }));
  };
  const handleChangeForgotConfirmPassword = (event: ChangeEvent<HTMLInputElement>): void => {
    const confirmPassword = event.target.value;
    setState(oldState => ({ ...oldState, confirmPassword }));
  };

  const handlePressSubmit = (): void => {
    if (password !== confirmPassword) {
      setState(oldState => ({ ...oldState, error: 'The passwords do not match' }));
    } else if (password.length < 8) {
      setState(oldState => ({ ...oldState, error: 'Password not long enough' }));
    } else if (password === confirmPassword) {
      const success = (): void => {
        onComplete();
      };
      const failure = (errorResponse: { message: string }): void => {
        let { message } = errorResponse;
        message = message.replace('Password does not conform to policy: ', '');
        setState(oldState => ({ ...oldState, error: message, loading: false }));
      };
      setState(oldState => ({ ...oldState, error: '', loading: true }));
      Auth.forgotPasswordSubmit(username, code, password)
        .then(success)
        .catch(failure);
    }
  };

  const missingFields = !code || !password || !confirmPassword;

  return (
    <>
      <Alert className=" d-flex justify-content-center" color="success">
        {`We've sent a code to ${username}`}
      </Alert>
      {!!error && (
        <Alert aria-label="error-message" className=" d-flex justify-content-center" color="danger" fade={false}>
          <span>{error}</span>
        </Alert>
      )}
      <FormGroup className="p-1">
        <Input
          aria-label={'code'}
          placeholder={'Code'}
          type={'number'}
          name={'code'}
          value={code}
          disabled={loading}
          onChange={handleChangeForgotCode}
        />
      </FormGroup>
      <FormGroup className="p-1">
        <Input
          aria-label={'password'}
          placeholder={'New Password'}
          type={'password'}
          name={'password'}
          value={password}
          disabled={loading}
          onChange={handleChangeForgotPassword}
        />
      </FormGroup>
      <FormGroup className="p-1">
        <Input
          aria-label={'confirm-password'}
          placeholder={'Confirm Password'}
          type={'password'}
          name={'confirm-password'}
          value={confirmPassword}
          disabled={loading}
          onChange={handleChangeForgotConfirmPassword}
        />
      </FormGroup>
      <CardFooter className="d-flex flex-column align-items-center">
        <Button
          block
          aria-label={'submit'}
          className="btn-round mb-2 text-uppercase font-weight-light"
          color="primary"
          onClick={handlePressSubmit}
          disabled={loading || missingFields}
          size="lg"
        >
          Submit new password
        </Button>
        <Button
          block
          aria-label={'cancel'}
          className="btn-round mb-2 text-uppercase font-weight-light"
          color="primary"
          onClick={handleCancelForgotPassword}
          size="lg"
        >
          Cancel
        </Button>
        <div className="p-1">
          <h5 className="text-muted text-lg-center">
            <small>
              The system is copyright protected and any unauthorised use/copying/redistribution is prohibited.
              Non-compliance will lead to prosecution
            </small>
          </h5>
        </div>
      </CardFooter>
    </>
  );
};

export default ForgotPasswordSubmissionView;
