import { ColumnHeading } from './ColumnHeadingEnums';
import {
  CreateEmployeeInputUploader,
  DisabilityType,
  EmailAddressInput,
  EmailAddressType,
  GenderType,
  PhoneNumberInput,
  PhoneNumberType,
  PhysicalAddressInput,
  PlaceOfWorkType,
  Province,
  RaceType,
  StatusType,
  UpdateEmployeeInputUploader,
  WorkHoursType,
} from '../../API';
import { list } from '../../utils/graphql-utils';
import { listDepartments, listJobGrades, listJobLevels, listJobTitles, listLocations } from '../../graphql/queries';
import { UserDetails } from '../../App';
import { EmployeeValidationData } from './EmployeeUploader';
import { EmployeeUploadValidationSchema } from './EmployeeUploadValidationSchema';
import { Department, JobGrade, JobLevel, JobTitle, Location, PhysicalAddressType } from '../../models';
import { diff } from 'deep-diff';
import { listEmployeesForComparison } from '../../graphql-custom/custom-queries';

export interface Row {
  [key: string]: string | null;
}

export type AttemptedEmployee = {
  employeeData: Partial<CreateEmployeeInputUploader | UpdateEmployeeInputUploader>;
  directManagerEmployeeNumber: string | null;
  allocatedManagerEmployeeNumber: string | null;
  validationErrors: string[];
  // "isRejoining" means the employee was marked as deleted and is now being re-added.
  isRejoining?: boolean;
  included?: boolean;
  uploadError?: string;
  uploadOperation?: string;
};

const validateEmployeeWithYup = async (employee: AttemptedEmployee): Promise<AttemptedEmployee> => {
  return new Promise(resolve => {
    EmployeeUploadValidationSchema.validate(employee.employeeData, { abortEarly: false })
      .then(() => resolve(employee))
      .catch(error => {
        employee.validationErrors = employee.validationErrors.concat(error.inner.map((item: any) => item.message));
        resolve(employee);
      });
  });
};

const getEmployeeDataFromRow = (
  row: Row,
  departments: Department[],
  locations: Location[],
  jobTitles: JobTitle[],
  jobGrades: JobGrade[],
  jobLevels: JobLevel[],
  organisationId: string,
  employeesFromDatabase: UpdateEmployeeInputUploader[],
): AttemptedEmployee => {
  Object.keys(row).forEach(key => {
    if (row[key] !== '') row[key] = row[key]!.toString();
    else row[key] = null;
  });
  const validationErrors: string[] = [];
  console.log("employeesFromDatabase", employeesFromDatabase)
  let department: Department | undefined | null = null;
  let location: Department | undefined | null = null;
  let jobTitle: JobTitle | undefined | null = null;
  let jobGrade: JobGrade | undefined | null = null;
  let jobLevel: JobLevel | undefined | null = null;

  department = departments.find(
    (item: Department) => item.name?.toLowerCase() === row[ColumnHeading.departmentName]?.toString().toLowerCase(),
  );
  jobTitle = jobTitles.find(
    (item: JobTitle) => item.name?.toLowerCase() === row[ColumnHeading.jobTitle]?.toString().toLowerCase(),
  );
  jobGrade = jobGrades.find(
    (item: JobGrade) => item.name?.toLowerCase() === row[ColumnHeading.jobGrade]?.toString().toLowerCase(),
  );

  jobLevel = jobLevels.find(
    (item: JobLevel) => item.name?.toLowerCase() === row[ColumnHeading.jobLevel]?.toString().toLowerCase(),
  );

  location = locations.find(
    (item: Location) => item.name?.toLowerCase() === row[ColumnHeading.locationName]?.toString().toLowerCase(),
  );

  let directManagerID = 'none';
  const dmEmployeeNumber = row[ColumnHeading.directManager];
  if (dmEmployeeNumber === 'TOP OF ORG') {
    directManagerID = 'topOfReportingLine';
  } else if (dmEmployeeNumber) {
    const dm = employeesFromDatabase.find((item: UpdateEmployeeInputUploader) => {
      return item.employeeNumber === dmEmployeeNumber;
    });
    if (dm) {
      directManagerID = dm.id;
    }
  }

  let allocatedManagerID = 'none';
  const amEmployeeNumber = row[ColumnHeading.allocatedManager];
  if (amEmployeeNumber) {
    const am = employeesFromDatabase.find((item: UpdateEmployeeInputUploader) => {
      return item.employeeNumber === amEmployeeNumber;
    });
    if (am) {
      allocatedManagerID = am.id;
    }
  }

  if (!row[ColumnHeading.departmentName]) {
    validationErrors.push('Missing department name');
  } else if (!department) {
    validationErrors.push('No such department on system: ' + row[ColumnHeading.departmentName]);
  }

  if (!row[ColumnHeading.locationName]) {
    validationErrors.push('Missing location name');
  } else if (!location) {
    validationErrors.push('No such location on system: ' + row[ColumnHeading.locationName]);
  }

  const phoneNumbers: PhoneNumberInput[] = [];
  const mobilePhone = row[ColumnHeading.mobilePhone];
  const workPhone = row[ColumnHeading.workPhone];
  const homePhone = row[ColumnHeading.homePhone];

  if (mobilePhone)
    phoneNumbers.push({
      number: mobilePhone
        .toString()
        .split(' ')
        .join(''),
      phoneNumberType: PhoneNumberType.MOBILE,
    });

  if (workPhone)
    phoneNumbers.push({
      number: workPhone
        .toString()
        .split(' ')
        .join(''),
      phoneNumberType: PhoneNumberType.WORK,
    });
  if (homePhone)
    phoneNumbers.push({
      number: homePhone
        .toString()
        .split(' ')
        .join(''),
      phoneNumberType: PhoneNumberType.HOME,
    });

  let workAddressProvince: string | undefined;
  if (row[ColumnHeading.workAddressProvince]) {
    workAddressProvince = row[ColumnHeading.workAddressProvince]
      ?.toString()
      .trim()
      .toUpperCase()
      .split(' ')
      .join('_');
  }

  let residentialAddressProvince: string | undefined;
  if (row[ColumnHeading.residentialAddressProvince]) {
    residentialAddressProvince = row[ColumnHeading.residentialAddressProvince]
      ?.toString()
      .trim()
      .toUpperCase()
      .split(' ')
      .join('_');
  }

  const physicalAddresses: PhysicalAddressInput[] = [];

  const workAddress: PhysicalAddressInput = {
    physicalAddressType: PhysicalAddressType.WORK,
    lineOne: row[ColumnHeading.workAddressLineOne],
    lineTwo: row[ColumnHeading.workAddressLineTwo],
    city: row[ColumnHeading.workAddressCity],
    province: workAddressProvince as Province,
    postalCode: row[ColumnHeading.workAddressPostalCode],
  };

  const residentialAddress: PhysicalAddressInput = {
    physicalAddressType: PhysicalAddressType.RESIDENCE,
    lineOne: row[ColumnHeading.residentialAddressLineOne],
    lineTwo: row[ColumnHeading.residentialAddressLineTwo],
    city: row[ColumnHeading.residentialAddressCity],
    province: residentialAddressProvince as Province,
    postalCode: row[ColumnHeading.residentialAddressPostalCode],
  };

  let idNumber = row[ColumnHeading.idNumber] ? row[ColumnHeading.idNumber] : null;
  if (idNumber) idNumber = idNumber.split(' ').join('');

  let hireDate = row[ColumnHeading.hireDate] ? row[ColumnHeading.hireDate] : null;
  if (hireDate) hireDate = hireDate.split(' ').join('');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (Object.keys(workAddress).some((key: string) => key !== 'physicalAddressType' && !!workAddress[key])) {
    physicalAddresses.push(workAddress);
  } else validationErrors.push('Missing work address');

  if (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.keys(residentialAddress).some((key: string) => key !== 'physicalAddressType' && !!residentialAddress[key])
  ) {
    physicalAddresses.push(residentialAddress);
  } else validationErrors.push('Missing residential address');

  const emails: EmailAddressInput[] = [];
  const workMail = row[ColumnHeading.workEmail];
  const personalEmail = row[ColumnHeading.personalEmail];

  if (workMail)
    emails.push({
      emailAddressType: EmailAddressType.WORK,
      address: workMail,
    });

  if (personalEmail)
    emails.push({
      emailAddressType: EmailAddressType.PERSONAL,
      address: personalEmail,
    });

  let raceType: string | null = row[ColumnHeading.raceType] ? row[ColumnHeading.raceType] : null;
  if (raceType) {
    raceType = raceType
      ?.toString()
      .trim()
      .toUpperCase()
      .split(' ')
      .join('_');
  }
  let genderType: string | null = row[ColumnHeading.genderType] ? row[ColumnHeading.genderType] : null;
  if (genderType) {
    genderType = genderType
      ?.toString()
      .trim()
      .toUpperCase()
      .split(' ')
      .join('_');
  }

  let placeOfWorkType: string | null = row[ColumnHeading.placeOfWorkType] ? row[ColumnHeading.placeOfWorkType] : null;
  if (placeOfWorkType) {
    placeOfWorkType = placeOfWorkType
      ?.toString()
      .trim()
      .toUpperCase()
      .split(' ')
      .join('_');
  }

  let disabilityType: string | null = row[ColumnHeading.disabilityType] ? row[ColumnHeading.disabilityType] : null;
  if (disabilityType) {
    disabilityType = disabilityType
      ?.toString()
      .trim()
      .toUpperCase()
      .split(' ')
      .join('_');
  }

  let approvedChairPerson: boolean | null = row[ColumnHeading.approvedChairPerson] === 'Y' ? true : row[ColumnHeading.approvedChairPerson] === 'N' ? false : null;
  console.log('approvedChairPerson', approvedChairPerson);
  let approvedHcApprover: boolean | null = row[ColumnHeading.approvedHcApprover] === 'Y' ? true : row[ColumnHeading.approvedHcApprover] === 'N' ? false : null;
  console.log('approvedHcApprover', approvedHcApprover);
  let statusType: string | null = row[ColumnHeading.statusType] ? row[ColumnHeading.statusType] : null;
  if (statusType) {
    statusType = statusType
      ?.toString()
      .trim()
      .toUpperCase()
      .split(' ')
      .join('_');
  }

  let workHoursType: string | null = row[ColumnHeading.workHoursType] ? row[ColumnHeading.workHoursType] : null;
  if (workHoursType) {
    workHoursType = workHoursType
      ?.toString()
      .trim()
      .toUpperCase()
      .split(' ')
      .join('_');
  }

  const employee: Partial<UpdateEmployeeInputUploader> = {
    organisationId: organisationId,
    employeeNumber: row[ColumnHeading.employeeNumber] ? row[ColumnHeading.employeeNumber] : undefined,
    idNumber: idNumber,
    passportNumber: row[ColumnHeading.passportNumber],
    firstName: row[ColumnHeading.firstName],
    lastName: row[ColumnHeading.lastName],
    departmentID: department ? department.id : 'none',
    locationID: location ? location.id : 'none',
    disabilityDescription: disabilityType as DisabilityType,
    disability: disabilityType as DisabilityType,
    jobTitleID: jobTitle ? jobTitle.id : 'none',
    jobGradeID: jobGrade ? jobGrade.id : 'none',
    jobLevelID: jobLevel ? jobLevel.id : 'none',
    directManagerID: directManagerID,
    allocatedManagerID: allocatedManagerID,
    hireDate: hireDate,
    race: raceType as RaceType,
    phoneNumbers: phoneNumbers,
    physicalAddresses: physicalAddresses,
    emails: emails,
    placeOfWork: placeOfWorkType as PlaceOfWorkType,
    workHours: workHoursType as WorkHoursType,
    // startTime: data.startTime,
    // endTime: data.endTime,
    status: statusType as StatusType,
    gender: genderType as GenderType,
    deleted: false,
    approvedChairPerson: approvedChairPerson,
    approvedHcApprover: approvedHcApprover,
  };



  return {
    employeeData: employee,
    validationErrors: validationErrors,
    directManagerEmployeeNumber: row[ColumnHeading.directManager],
    allocatedManagerEmployeeNumber: row[ColumnHeading.allocatedManager],
  };
};

const validateTemplateData = async (
  rows: Row[],
  departments: Department[],
  locations: Location[],
  jobTitles: JobTitle[],
  jobGrades: JobGrade[],
  jobLevels: JobLevel[],
  organisationId: string,
  employeesFromDatabase: UpdateEmployeeInputUploader[],
): Promise<AttemptedEmployee[]> => {
  const validatedEmployees: AttemptedEmployee[] = [];

  for (let i = 0; i < rows.length; i++) {
    const unvalidatedEmployee: AttemptedEmployee = getEmployeeDataFromRow(
      rows[i],
      departments,
      locations,
      jobTitles,
      jobGrades,
      jobLevels,
      organisationId,
      employeesFromDatabase,
    );
    console.log("database employee: ", employeesFromDatabase)
    const validatedEmployee: AttemptedEmployee = await validateEmployeeWithYup(unvalidatedEmployee);
    validatedEmployees.push(validatedEmployee);
  }

  for (let i = 0; i < validatedEmployees.length; i++) {
    const employee = validatedEmployees[i];
    if (employee.directManagerEmployeeNumber) {
      const manager =
        employee.directManagerEmployeeNumber === 'TOP OF ORG'
          ? {
              directManagerID: 'topOfReportingLine',
              directManager: null,
              directManagerName: null,
            }
          : validatedEmployees.find(
              (item: AttemptedEmployee) => item.employeeData.employeeNumber === employee.directManagerEmployeeNumber,
            );
      if (!manager) {
        const dbmanager = employeesFromDatabase.find(
          (item: UpdateEmployeeInputUploader) => item.employeeNumber === employee.directManagerEmployeeNumber,
        );
        if (!dbmanager) {
          employee.validationErrors.push("No match found for Direct Manager's employee number");
        }
      }
    }

    if (employee.allocatedManagerEmployeeNumber) {
      const manager = validatedEmployees.find(
        (item: AttemptedEmployee) => item.employeeData.employeeNumber === employee.allocatedManagerEmployeeNumber,
      );
      if (!manager) {
        const dbmanager = employeesFromDatabase.find(
          (item: UpdateEmployeeInputUploader) => item.employeeNumber === employee.allocatedManagerEmployeeNumber,
        );
        if (!dbmanager) {
          employee.validationErrors.push("No match found for Allocated Manager's employee number");
        }
      }
    }

    const matchingEmployeeNumbers = validatedEmployees.filter(
      validatedEmployee => validatedEmployee.employeeData.employeeNumber === employee.employeeData.employeeNumber,
    );
    if (matchingEmployeeNumbers.length > 1) {
      employee.validationErrors.push('Duplicate employee number detected');
    }
  }
  return validatedEmployees;
};

export const getEmployeeValidationData = async (
  organisationId: string,
  rows: Row[],
  employeesFromDatabase: UpdateEmployeeInputUploader[],
  departments: Department[],
  locations: Location[],
  jobTitles: JobTitle[],
  jobGrades: JobGrade[],
  jobLevels: JobLevel[],
): Promise<EmployeeValidationData> => {
  const employeesFromTemplate: AttemptedEmployee[] = await validateTemplateData(
    rows,
    departments,
    locations,
    jobTitles,
    jobGrades,
    jobLevels,
    organisationId,
    employeesFromDatabase,
  );

  const validEmployeesFromTemplate: AttemptedEmployee[] = [];
  const joiners: AttemptedEmployee[] = [];
  const leavers: AttemptedEmployee[] = [];
  const updated: AttemptedEmployee[] = [];
  const invalidEmployees: AttemptedEmployee[] = [];
  console.log("joiners", joiners)
  console.log("leavers", leavers)
  console.log("updated", updated)
  for (const dbEmployee1 of employeesFromDatabase) {
    for (const dbEmployee2 of employeesFromDatabase) {
      if (dbEmployee1.employeeNumber === dbEmployee2.employeeNumber && dbEmployee1.id !== dbEmployee2.id) {
        throw new Error(
          ' Existing employee' +
            dbEmployee1.firstName +
            ' ' +
            dbEmployee1.lastName +
            ' has the same employee number as existing employee ' +
            dbEmployee2.firstName +
            ' ' +
            dbEmployee2.lastName,
        );
      }
    }
  }

  employeesFromTemplate.forEach((templateEmployee: AttemptedEmployee) => {
    if (templateEmployee.validationErrors.length) {
      invalidEmployees.push(templateEmployee);
    } else {
      // const typedEmployee: CreateEmployeeInput = makeEmployee(templateEmployee, organisation.departments);
      if (templateEmployee.directManagerEmployeeNumber === 'TOP OF ORG') {
        templateEmployee.directManagerEmployeeNumber = 'topOfReportingLine';
      }
      validEmployeesFromTemplate.push(templateEmployee);
    }
  });

  // const isEmployeeUpdated = (diffs: Diff<UpdateEmployeeInput, Partial<CreateEmployeeInput | UpdateEmployeeInput>>[]): boolean => {
  //   for (let i = 0; i < diffs.length; i++) {
  //     if (diffs[i].kind === "E" && diffs[i].lhs
  //   }
  // };

  for (const templateEmployee of validEmployeesFromTemplate) {
    const matchingDatabaseEmployee = employeesFromDatabase.find(
      e => e.employeeNumber === templateEmployee.employeeData.employeeNumber,
    );
    if (matchingDatabaseEmployee) {
      templateEmployee.employeeData.id = matchingDatabaseEmployee.id;
      templateEmployee.employeeData.organisationId = matchingDatabaseEmployee.organisationId;

      matchingDatabaseEmployee.approvedChairPerson = templateEmployee.employeeData.approvedChairPerson;
      matchingDatabaseEmployee.approvedHcApprover = templateEmployee.employeeData.approvedHcApprover;
      matchingDatabaseEmployee.directManagerID = templateEmployee.employeeData.directManagerID;
      matchingDatabaseEmployee.allocatedManagerID = templateEmployee.employeeData.allocatedManagerID;

      if (matchingDatabaseEmployee.deleted) {
        templateEmployee.isRejoining = true;
        joiners.push(templateEmployee);
      } else {
        const employeeDiffs = diff(matchingDatabaseEmployee, templateEmployee.employeeData);
        if (employeeDiffs?.length) {
          console.log('matchingDatabaseEmployee', matchingDatabaseEmployee);
          console.log('templateEmployee.employeeData', templateEmployee.employeeData);
          updated.push(templateEmployee);
        }
      }
    } else {
      joiners.push(templateEmployee);
    }
  }

  employeesFromDatabase.forEach(databaseEmployee => {
    if (
      !databaseEmployee.deleted &&
      !employeesFromTemplate.some(
        (templateEmployee: AttemptedEmployee) =>
          templateEmployee.employeeData.employeeNumber === databaseEmployee.employeeNumber,
      )
    )
      leavers.push({
        employeeData: databaseEmployee,
        included: true,
        validationErrors: [],
        directManagerEmployeeNumber: null,
        allocatedManagerEmployeeNumber: null,
      });
  });
  return { joiners: joiners, leavers: leavers, updated: updated, invalidEmployees: invalidEmployees };
};

// const fetchEmployees = async (organisationId: string): Promise<UpdateEmployeeInputUploader[]> => {
//   return new Promise((resolve, reject) => {
//     const variables = { filter: { organisationId: { eq: organisationId } }, limit: 501 };
//     list(listEmployeesForComparison, variables)
//       .then(res => {
//         console.log('employees res', res.data);

//         if (res.data && (res.data as any).listEmployees.items) {
//           resolve((res.data as any).listEmployees.items);
//         }
//       })
//       .catch(error => reject(error));
//   });
// };

const fetchEmployees = async (organisationId: string, nextToken?: string): Promise<UpdateEmployeeInputUploader[]> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const variables = {
      filter: { organisationId: { eq: organisationId } },
      limit: 501,
      nextToken: nextToken,
    };
    try {
      const res = await list(listEmployeesForComparison, variables);
      if (res.data && (res.data as any).listEmployees.items) {
        const items = (res.data as any).listEmployees.items;
        const newNextToken = (res.data as any).listEmployees.nextToken;

        if (newNextToken) {
          const moreItems = await fetchEmployees(organisationId, newNextToken);
          resolve([...items, ...moreItems]);
        } else {
          resolve(items);
        }
      } else {
        resolve([]);
      }
    } catch (error) {
      reject(error);
    }
  });
};

const fetchDepartments = async (organisationId: string): Promise<Department[]> => {
  return new Promise((resolve, reject) => {
    const variables = {
      filter: {
        organisationID: { eq: organisationId },
        deleted: { ne: true },
      },
      limit: 500,
    };
    list(listDepartments, variables)
      .then(res => {
        if (res.data && (res.data as any).listDepartments.items) {
          resolve((res.data as any).listDepartments.items);
        }
      })
      .catch(error => reject(error));
  });
};

const fetchJobTitles = async (organisationId: string): Promise<JobTitle[]> => {
  return new Promise((resolve, reject) => {
    const variables = {
      filter: {
        organisationID: { eq: organisationId },
        deleted: { ne: true },
      },
      limit: 500,
    };
    list(listJobTitles, variables)
      .then(res => {
        if (res.data && (res.data as any).listJobTitles.items) {
          resolve((res.data as any).listJobTitles.items);
        }
      })
      .catch(error => reject(error));
  });
};

const fetchJobGrades = async (organisationId: string): Promise<JobGrade[]> => {
  return new Promise((resolve, reject) => {
    const variables = {
      filter: {
        organisationID: { eq: organisationId },
        deleted: { ne: true },
      },
      limit: 500,
    };
    list(listJobGrades, variables)
      .then(res => {
        if (res.data && (res.data as any).listJobGrades.items) {
          resolve((res.data as any).listJobGrades.items);
        }
      })
      .catch(error => reject(error));
  });
};

const fetchJobLevels = async (organisationId: string): Promise<JobLevel[]> => {
  return new Promise((resolve, reject) => {
    const variables = {
      filter: {
        organisationID: { eq: organisationId },
        deleted: { ne: true },
      },
      limit: 500,
    };
    list(listJobLevels, variables)
      .then(res => {
        if (res.data && (res.data as any).listJobLevels.items) {
          resolve((res.data as any).listJobLevels.items);
        }
      })
      .catch(error => reject(error));
  });
};

const fetchLocations = async (organisationId: string): Promise<Location[]> => {
  return new Promise((resolve, reject) => {
    const variables = {
      filter: {
        organisationID: { eq: organisationId },
        deleted: { ne: true },
      },
      limit: 500,
    };
    list(listLocations, variables)
      .then(res => {
        if (res.data && (res.data as any).listLocations.items) {
          resolve((res.data as any).listLocations.items);
        }
      })
      .catch(error => reject(error));
  });
};

export const handleTemplate = async (
  data: Row[],
  currentUser: UserDetails | null | undefined,
): Promise<EmployeeValidationData> => {
  return new Promise((resolve, reject) => {
    if (currentUser && currentUser.organisationId) {
      Promise.all([
        fetchEmployees(currentUser.organisationId),
        fetchDepartments(currentUser.organisationId),
        fetchLocations(currentUser.organisationId),
        fetchJobTitles(currentUser.organisationId),
        fetchJobGrades(currentUser.organisationId),
        fetchJobLevels(currentUser.organisationId),
      ])
        .then(async res => {
          if (currentUser.organisationId) {
            const employeeValidationData: EmployeeValidationData = await getEmployeeValidationData(
              currentUser.organisationId,
              data,
              res[0],
              res[1],
              res[2],
              res[3],
              res[4],
              res[5],
            );
            resolve(employeeValidationData);
          }
        })
        .catch((error: Error) => reject(error));
    } else {
      reject(new Error('No current user'));
    }
  });
};