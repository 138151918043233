import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { RecordCounsellingSchema } from '../../../forms/ValidationSchema/PoorPerformanceValidationSchema';
import { WorkFlowFooter } from '../../../components/workflowFooter/WorkFlowFooter';
import {
  filterFormData,
  getFormDataFromCaseData,
  WorkflowComponentProps,
} from '../../WorkflowContainer/workflow-utils';
import RecordCounselingForm from '../RecordCounselling/RecordCounsellingForm';
import { CounsellingSession } from '../RecordCounselling/RecordCounselling';

const RecordAdditionalCounsellingSession: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;
  const fields = {
    performanceShortfalls: 'array',
    performanceCounsellingSessions: 'array',
  };

  const [state, setState] = useState(getFormDataFromCaseData(fields, caseData));
  useEffect(() => {
    const completedSessions = state.performanceCounsellingSessions.filter(
      (session: CounsellingSession) => session.sessionCompleted,
    );
    const onGoingSessions = state.performanceCounsellingSessions.filter(
      (session: CounsellingSession) => !session.sessionCompleted,
    );

    // get the last session if there are any completed sessions
    if (onGoingSessions.length) {
      setState(oldState => ({
        ...oldState,
        // performanceCounsellingSessions: onGoingSessions,
        counsellingSession: onGoingSessions[0],
      }));
    } else {
      setState(oldState => ({
        ...oldState,
        counsellingSession: completedSessions[completedSessions.length - 1],
      }));
    }
  }, []);

  const submitValues = async (formValues: FormikValues): Promise<void> => {
    const values = { ...formValues };
    values.counsellingSession.sessionCompleted = true;
    const sessions = values.performanceCounsellingSessions.filter(
      (s: CounsellingSession) => s.sessionId !== values.counsellingSession.sessionId,
    );
    values.performanceCounsellingSessions = JSON.stringify([...sessions, values.counsellingSession]);
    values.performanceShortfalls = JSON.stringify(formValues.performanceShortfalls);
    flowableFunctions.onNext(filterFormData(values, fields));
  };
  return (
    <div className="content">
      <h4 className="text-default text-capitalize font-weight-bold">Record Additional Performance Counselling</h4>
      <Formik
        enableReinitialize
        initialValues={state}
        validationSchema={RecordCounsellingSchema}
        onSubmit={submitValues}
      >
        {({ values, errors, handleSubmit, setFieldValue }: FormikProps<FormikValues>): ReactElement => (
          <>
            {values.counsellingSession && (
              <RecordCounselingForm
                employeeEmail={props.data.employeeWorkEmailAddresses[0]}
                processInstanceId={props.data.processInstanceId}
                employeeFullName={props.data.employeeFullName}
              />
            )}
            <WorkFlowFooter
              hideSaveAndCloseButton={true}
              data={props.data}
              onNext={handleSubmit}
              onCancel={(): void => console.log('cancel')}
              onSaveAndClose={(): void => flowableFunctions.onSaveAndClose(values)}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default RecordAdditionalCounsellingSession;
