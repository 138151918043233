export const updateDisciplinaryMatrixMutation = /* GraphQL */ `
  mutation UpdateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      id
      disciplinaryCode {
        transgressionCategory
        transgression
        sanction {
          firstOffence
          secondOffence
          thirdOffence
          fourthOffence
          fifthOffence
          sixthOffence
        }
        default
        active
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateEmployeeSuspension = `
  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      id
      suspensionDate
    }
  }
 `;

export const updateUserFontPreference = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      fontPreference
    }
  }
`;

export const updateUserOrganisationId = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      organisationId
    }
  }
`;

export const updateDocumentFile = /* GraphQL */ `
  mutation UpdateTemplateDocumentVersion($input: UpdateTemplateDocumentVersionInput!) {
    updateTemplateDocumentVersion(input: $input) {
      id
      uploadedFileBucketPath
    }
  }
`;

export const updateUserMutation = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      active
    }
  }
`;

export const updateEmployeeSimple = /* GraphQL */ `
  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      id
      organisationId
      employeeNumber
      idNumber
      passportNumber
      firstName
      lastName
      departmentID
      jobTitleID
      jobGradeID
      jobLevelID
      directManagerID
      allocatedManagerID
      hireDate
      race
      phoneNumbers {
        number
        phoneNumberType
      }
      physicalAddresses {
        physicalAddressType
        lineOne
        lineTwo
        city
        province
        postalCode
      }
      emails {
        emailAddressType
        address
      }
      hasDisability
      locationID
      placeOfWork
      workHours
      startTime
      endTime
      status
      disability
      disabilityDescription
      gender
      suspensionDate
      deleted
    }
  }
`;
