import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Paragraph from '../../../PDF/Paragraph.component';
import { DocumentHeader } from '../../common/Header';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const PIPConclusionLetterPDF: React.FC<DocumentProps> = (props): JSX.Element => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        <DocumentHeader content={props.content} logo={props.logo} />
        <Paragraph> Dear {props.content.employeeFullName.value}, </Paragraph>
        <Text style={{ ...styles.subject }}>SUCCESSFUL CONCLUSION OF PERFORMANCE IMPROVEMENT PROCESS </Text>
        <Paragraph />
        <Paragraph>
          A meeting held {/*date.value*/} with you refers where we discussed progress made with your Performance
          Improvement Plan (PIP). We are happy to confirm that you have made sufficient progress and that you have met
          the performance deliverables set out in your PIP to our satisfaction.
        </Paragraph>
        <Paragraph>
          In light thereof, we herewith conclude the performance counseling process and trust that you will continue
          performing at the required level going forward. Normal performance evaluation and management processes will
          measure your future performance.
        </Paragraph>
        <Paragraph>
          <Text>{props.content.other.value}</Text>
        </Paragraph>
        <Paragraph>
          This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a copy
          to writer hereof in acknowledgement of receipt hereof, alternatively, acknowledge by way of return e-mail.
        </Paragraph>
        <Paragraph>Yours sincerely,</Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
    </Document>
  );
};
