import * as Yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '../fields/FormField.component';

export const initial_hearing_appointEmployerRepresentativeSchema = Yup.object().shape({
  initial_hearing_suspendPendingHearing: Yup.boolean().required(),
  initial_hearing_employerRepresentativeType: Yup.string().required(),
  initial_hearing_employerRepresentativeFirstName: Yup.string().required("Please enter representative's first name"),
  initial_hearing_employerRepresentativeLastName: Yup.string().required("Please enter representative's last name"),
  // initial_hearing_employerRepresentativeUserId: Yup.string().required('TEST MESSAGE'),

  initial_hearing_employerRepresentativeEmailAddress: Yup.string()
    .email('Email is not valid')
    .required("Please enter representative's email address"),
  // initial_hearing_employerRepresentativeId: Yup.string().when('initial_hearing_employerRepresentativeType', {
  //   is: 'internal',
  //   then: Yup.string().required('Required'),
  // }),
});

export const initial_hearing_appointChairpersonSchema = Yup.object().shape({
  initial_hearing_chairpersonType: Yup.string().required(),
  initial_hearing_chairpersonFirstName: Yup.string().required('Required'),
  initial_hearing_chairpersonLastName: Yup.string().required('Required'),
  initial_hearing_chairpersonEmailAddress: Yup.string()
    .email('Email is not valid')
    .required('Required'),
  // initial_hearing_chairpersonId: Yup.string().when('initial_hearing_chairpersonType', {
  //   is: 'internal',
  //   then: Yup.string().required('Required'),
  // }),
});

export const SelectWitnessSchema = Yup.object().shape({
  initial_hearing_hasWitness: Yup.boolean(),
  initial_hearing_internalWitnesses: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        emailAddress: Yup.string(),
        employeeId: Yup.string(),
      }),
    )
    .nullable(),
  initial_hearing_externalWitnesses: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        emailAddress: Yup.string().email('Email is not valid'),
      }),
    )
    .nullable()
    .when(['initial_hearing_hasWitness', 'initial_hearing_internalWitnesses'], {
      is: (hasWitness, initial_hearing_internalWitnesses) =>
        hasWitness && (!initial_hearing_internalWitnesses || !initial_hearing_internalWitnesses.length),
      then: Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string().required('Required'),
          lastName: Yup.string().required('Required'),
          emailAddress: Yup.string()
            .email('Email is not valid')
            .required('Required'),
        }),
      ),
    }),
});

export const initial_hearing_hearingLogisticsSchema = Yup.object().shape({
  initial_hearing_hearingDate: Yup.string()
    .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    .required('Please select date'),
  initial_hearing_hearingTime: Yup.string()
    .test('time', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid())
    .required('Please select time'),
  initial_hearing_hearingLocation: Yup.string().required('Please enter location'),
  initial_hearing_employerRepresentativeFirstName: Yup.string().required('Please enter representatives first name'),
  initial_hearing_employerRepresentativeLastName: Yup.string().required('Please enter representatives last name'),
  initial_hearing_employerRepresentativeEmailAddress: Yup.string()
    .email('Email is not valid')
    .required('Please enter email'),
  initial_hearing_chairpersonFirstName: Yup.string().required('Please enter chairpersons first name'),
  initial_hearing_chairpersonLastName: Yup.string().required('Please enter chairpersons last name'),
  initial_hearing_chairpersonEmailAddress: Yup.string()
    .email('Email is not valid')
    .required('Please enter email'),
  initial_hearing_hearingWitnesses: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().required("Please enter witness's first name"),
        lastName: Yup.string().required("Please enter witness's last name"),
        emailAddress: Yup.string()
          .email('Email is not valid')
          .required('Please enter email address'),
      }),
    )
    .nullable(),
});

export const initial_hearing_misconductCompileNoticeOfHearingSchema = Yup.object().shape({
  incidents: Yup.array().of(
    Yup.object().shape({
      summaryOfFacts: Yup.string().required('Please enter Summary of Allegations'),
      date: Yup.string().test('date', 'Date is not valid', value => moment(value, DATE_FORMAT).isValid() || !value),
      time: Yup.string().test('time', 'Time is not valid', value => moment(value, TIME_FORMAT).isValid() || !value),
      transgression: Yup.string().required('Required'),
    }),
  ),
});

export const initial_hearing_poor_performance_compileNoticeOfHearingSchema = Yup.object().shape({
  performanceShortfalls: Yup.array()
    .of(
      Yup.object().shape({
        shortfallDescription: Yup.string().required('Required'),
      }),
    )
    .required()
    .nullable(),
});

export const initial_hearing_poorPerformanceCompileNoticeOfHearingSchema = Yup.object().shape({
  performanceShortfalls: Yup.array().of(
    Yup.object().shape({
      shortfallDescription: Yup.string().required('Required'),
      date: Yup.string().test('date', 'Date is not valid', value => moment(value, DATE_FORMAT).isValid() || !value),
    }),
  ),
});

export const appeal_hearing_poorPerformanceCompileNoticeOfHearingSchema = Yup.object().shape({
  performanceShortfalls: Yup.array().of(
    Yup.object().shape({
      shortfallDescription: Yup.string().required('Required'),
      date: Yup.string().test('date', 'Date is not valid', value => moment(value, DATE_FORMAT).isValid() || !value),
    }),
  ),
});

export const initial_hearing_recordEmployeeRightsAndResponsesSchema = Yup.object().shape({
  initial_hearing_requestedTranslator: Yup.string().required('Required'),
  initial_hearing_language: Yup.string().when('initial_hearing_requestedTranslator', {
    is: 'YES',
    then: Yup.string().required('Please select a language'),
  }),
  initial_hearing_requestedExternalRepresentation: Yup.string()
    .required('Required')
    .nullable(),
  initial_hearing_reasonForRequestingExternalRepresentation: Yup.string().when(
    'initial_hearing_requestedExternalRepresentation',
    {
      is: 'YES',
      then: Yup.string().required('Required'),
    },
  ),
  initial_hearing_eERepresentativePresent: Yup.string().required('Required'),
  initial_hearing_reasonForEeRepresentativePresence: Yup.string().when('initial_hearing_eERepresentativePresent', {
    is: 'YES',
    then: Yup.string().required('Required'),
  }),
  initial_hearing_disabledAndRequiresAccommodation: Yup.string().required('Required'),
  initial_hearing_reasonForRequiringAccommodation: Yup.string().when(
    'initial_hearing_disabledAndRequiresAccommodation',
    {
      is: 'YES',
      then: Yup.string().required('Required'),
    },
  ),
});

export const initial_hearing_externalRepresentationOutcomeSchema = Yup.object().shape({
  initial_hearing_externalRepresentationApplicationRulingDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .required('Please upload a file')
    .nullable()
    .when('isUpload', {
      is: true,
      then: Yup.array().required('Please upload a file'),
    }),
});

export const initial_hearing_minutesAndRecordingSchema = Yup.object().shape({
    initial_hearing_hearingDate: Yup.string()
        .required('Please select date')
        .test('date', 'Date is not valid', (val) => moment(val, DATE_FORMAT).isValid()),
    initial_hearing_hearingTime: Yup.string()
        .required('Please select time')
        .test('time', 'Time is not valid', (val) => moment(val, TIME_FORMAT).isValid()),
    initial_hearing_hearingLocation: Yup.string().required('Please enter location'),
    initial_hearing_reasonForDeclining: Yup.string(),
    initial_hearing_documentaryEvidenceFiles: Yup.array()
        .of(
            Yup.object().shape({
                fileName: Yup.string().required('Required'),
                url: Yup.string().required('Required'),
                key: Yup.string().required('Required'),
            })
        )
        .nullable(),
    initial_hearing_minutesOrRecordingsFiles: Yup.array()
        .of(
            Yup.object().shape({
                fileName: Yup.string().required('Required'),
                url: Yup.string().required('Required'),
                key: Yup.string().required('Required'),
            })
        )
        .nullable()
        .when('initial_hearing_documentaryEvidenceFiles', {
            is: (documentaryEvidenceFiles) => !documentaryEvidenceFiles || documentaryEvidenceFiles.length === 0,
            then: Yup.array()
                .of(
                    Yup.object().shape({
                        fileName: Yup.string().required('Required'),
                        url: Yup.string().required('Required'),
                        key: Yup.string().required('Required'),
                    })
                )
                .required('Please upload at least one of the required documents.')
                .min(1, 'Please upload at least one of the required documents.'),
        }),
});

export const initial_hearing_misconductChairpersonsRulingSchema = Yup.object().shape({
  isUpload: Yup.boolean().required(),
  initial_hearing_chairpersonsRulingDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    ).typeError('*Please submit here'),
  initial_hearing_backgroundLeadingToHearing: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_employeeRightsExercisedInHearing: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_summaryOfEmployerRepresentativeEvidence: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_summaryOfEmployeesEvidence: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_summaryOfAggravatingFactors: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_conclusionsReachedOnGuilt: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_conclusionsReachedOnSanction: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_summaryOfLegalPrinciples: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_findingsOnSanction: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_employeeCommentsAndMitigatingFactors: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
});

export const initial_hearing_poorPerformanceChairpersonsRulingSchema = Yup.object().shape({
  isUpload: Yup.boolean().required(),
  initial_hearing_chairpersonsRulingDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .nullable()
    .when('isUpload', {
      is: true,
      then: Yup.array().required('Please upload a file'),
    }),
  initial_hearing_backgroundLeadingToHearing: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_employeeRightsExercisedInHearing: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_summaryOfEmployerRepresentativeEvidence: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_summaryOfEmployeesEvidence: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_summaryOfAggravatingFactors: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_conclusionsReachedOnWayForward: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_summaryOfLegalPrinciples: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_conclusionsReachedOnSubStandardPerformance: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_findingsOnWayForward: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_employeeCommentsAndMitigatingFactors: Yup.string().when('isUpload', {
    is: false,
    then: Yup.string().required('Required'),
  }),
  initial_hearing_reasonForSelectingOther: Yup.string().when('initial_hearing_findingsOnWayForward ', {
    is: 'OTHER',
    then: Yup.string().required('Required'),
  }),
});

export const initial_hearing_confirmOverallSanctionSchema = Yup.object().shape({
  overallSanction: Yup.string().required('Please select overall sanction'),
  incidents: Yup.array().of(
    Yup.object().shape({
      summaryOfFacts: Yup.string().required('Please enter Summary of Allegations'),
      date: Yup.string().test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid() || !val),
      time: Yup.string().test('time', 'Time is not valid', val => moment(val, TIME_FORMAT).isValid() || !val),
      transgression: Yup.string().required('Please select transgression'),
      initial_hearing_chairpersonsFindingOnGuilt: Yup.string()
        .required('Please select ruling')
        .nullable(),
      initial_hearing_chairpersonsSanction: Yup.string()
        .when('initial_hearing_chairpersonsFindingOnGuilt', {
          is: 'GUILTY',
          then: Yup.string().required('Please select Sanction'),
        })
        .nullable(),
    }),
  ),
});

export const startAppealSchema = Yup.object().shape({
  appealAllowed: Yup.boolean(),
  employeeHasIndicatedAppealIntent: Yup.boolean().when('appealAllowed', {
    is: true,
    then: Yup.boolean().required('Required'),
  }),
  appealReceivedWithinPrescribedTimeFrame: Yup.boolean().when(['appealAllowed', 'employeeHasIndicatedAppealIntent'], {
    is: true,
    then: Yup.boolean().required('Required'),
  }),
  employeeAppealResponseDocuments: Yup.array()
    .of(
      Yup.object().shape({
        fileName: Yup.string().required('Required'),
        url: Yup.string().required('Required'),
        key: Yup.string().required('Required'),
      }),
    )
    .nullable()
    .when(['appealAllowed', 'employeeHasIndicatedAppealIntent'], {
      is: true,
      then: Yup.array().required('Please upload a document'),
    }),
});

export const initial_hearing_concludeHearingAdministrationSchema = Yup.object().shape({
  initial_hearing_deliveredSanctionOutcomeLetter: Yup.string().required('Required'),
  // initial_hearing_acceptedDemotion: Yup.string().required('Required'),
  // initial_hearing_acceptedSuspensionWithoutPay: Yup.string().required('Required'),
});
