import React, { useEffect } from 'react';
import { Col, Form, FormGroup, Label, Row, Table } from 'reactstrap';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import TableFormField from '../../../../../forms/fields/TableFormField';
import FormField from '../../../../../forms/fields/FormField.component';
import { toTitleCase } from '../../../../../utils/string-utils';
import { CasePerformanceShortfall, WorkflowComponentProps } from '../../../../WorkflowContainer/workflow-utils';

const cols = [
  'performance shortfall',
  'ruling on guilt',
  'did employee appeal ruling?',
  'appeal chairperson ruling',
  'sanction',
  'did employee appeal sanction?',
  'appeal chairperson sanction',
  'confirm revised sanction',
];

const formatOption = (str: string) => {
  return { value: str, label: toTitleCase(str, '_') };
};

const generalSanctionOptions = ['CONFIRM', 'OVERTURN', 'NOT_APPLICABLE'];

const sanctionOptionsHasCompletedPIPs = [
  'SUMMARY_DISMISSAL',
  'DISMISSAL_WITH_NOTICE_PAY',
  'DEMOTION',
  'NOT_APPLICABLE',
];

const sanctionOptionsDefault = [
  'FINAL_WRITTEN_WARNING_WITH_PIP',
  'SUMMARY_DISMISSAL',
  'DISMISSAL_WITH_NOTICE_PAY',
  'DEMOTION',
  'NOT_APPLICABLE',
];

export const AppealHearingPoorPerformanceConfirmOverallSanctionForm: React.FC<WorkflowComponentProps> = (
  props: WorkflowComponentProps,
) => {
  const { values, setFieldValue }: FormikProps<FormikValues> = useFormikContext();

  const getPPSanctionOptions = (): string[] => {
    if (props.data.caseData.hasCompletedExtendedPIP || props.data.caseData.hasCompletedFinalWrittenWarningPIP) {
      return sanctionOptionsHasCompletedPIPs;
    } else {
      return sanctionOptionsDefault;
    }
  };

  const getRulingOptions = (index: number): { label: string; value: string }[] => {
    if (values.performanceShortfalls[index].didEmployeeAppealRuling === 'YES') {
      return generalSanctionOptions.filter(s => s !== 'NOT_APPLICABLE').map(formatOption);
    } else {
      return generalSanctionOptions.map(formatOption);
    }
  };

  const getGeneralSanctionOptions = (index: number): { label: string; value: string }[] => {
    if (values.performanceShortfalls[index].didEmployeeAppealSanction === 'YES') {
      if (values.performanceShortfalls[index].appeal_hearing_chairpersonsFinding === 'OVERTURN') {
        return [formatOption('NOT_APPLICABLE')];
      } else {
        return generalSanctionOptions.filter(s => s !== 'NOT_APPLICABLE').map(formatOption);
      }
    } else {
      return generalSanctionOptions.map(formatOption);
    }
  };

  const getSpecificSanctionOptions = (index: number): { label: string; value: string }[] => {
    // if (values.performanceShortfalls[index].appeal_hearing_chairpersonsSanction === 'OVERTURN') {
    //   return specificSanctionOptions.filter(s => s !== 'NOT_APPLICABLE').map(formatOption);
    // } else {
    return getPPSanctionOptions().map(formatOption);
    // }
  };

  const canSelectRevisedOverallSanction = (): boolean => {
    return values.performanceShortfalls.some(
      (i: CasePerformanceShortfall) => i.appeal_hearing_chairpersonConfirmedSanction !== 'NOT_APPLICABLE',
    );
  };

  const getOptionsForRevisedSanction = () => {
    if (canSelectRevisedOverallSanction()) {
      return getPPSanctionOptions()
        .filter(s => s !== 'NOT_APPLICABLE')
        .map(formatOption);
    } else return [formatOption('NOT_APPLICABLE')];
  };

  const canShowRevisedSanctionColumn = (index: number): boolean => {
    if (!values.performanceShortfalls[index].appeal_hearing_chairpersonsFinding?.length) {
      return false;
    }
    return !!values.performanceShortfalls[index].appeal_hearing_chairpersonsSanction?.length;
    // return (
    //   (values.performanceShortfalls[index].appeal_hearing_chairpersonsSanction === 'CONFIRM' ||
    //     values.performanceShortfalls[index].appeal_hearing_chairpersonsSanction === 'OVERTURN') &&
    //   (values.performanceShortfalls[index].appeal_hearing_chairpersonsFinding === 'CONFIRM' ||
    //     values.performanceShortfalls[index].appeal_hearing_chairpersonsFinding === 'OVERTURN')
    // );
  };

  useEffect(() => {
    if (!values.revisedOverallSanction) {
      setFieldValue('revisedOverallSanction', values.overallSanction);
    }
    if (values.performanceShortfalls) {
      values.performanceShortfalls.forEach((shortfall: CasePerformanceShortfall) => {
        if (shortfall.didEmployeeAppealRuling === 'NO' && shortfall.didEmployeeAppealSanction === 'NO') {
          console.log('1inc: ', shortfall.didEmployeeAppealRuling, shortfall.didEmployeeAppealSanction);
          shortfall.appeal_hearing_chairpersonsFinding = 'NOT_APPLICABLE';
          shortfall.appeal_hearing_chairpersonsSanction = 'NOT_APPLICABLE';
          shortfall.appeal_hearing_chairpersonConfirmedSanction = 'NOT_APPLICABLE';
        } else if (shortfall.didEmployeeAppealRuling === 'YES' && shortfall.didEmployeeAppealSanction === 'YES') {
          console.log('2inc: ', shortfall.didEmployeeAppealRuling, shortfall.didEmployeeAppealSanction);
          if (shortfall.appeal_hearing_chairpersonsFinding === 'OVERTURN') {
            shortfall.appeal_hearing_chairpersonsSanction = 'NOT_APPLICABLE';
            shortfall.appeal_hearing_chairpersonConfirmedSanction = 'NOT_APPLICABLE';
          } else if (shortfall.appeal_hearing_chairpersonsFinding === 'CONFIRM') {
            if (shortfall.appeal_hearing_chairpersonsSanction === 'CONFIRM') {
              shortfall.appeal_hearing_chairpersonConfirmedSanction = shortfall.initial_hearing_chairpersonsSanction;
            } else if (shortfall.appeal_hearing_chairpersonsSanction === '') {
              shortfall.appeal_hearing_chairpersonConfirmedSanction = '';
            }
          }
        } else if (shortfall.didEmployeeAppealRuling === 'NO' && shortfall.didEmployeeAppealSanction === 'YES') {
          console.log('3inc: ', shortfall.didEmployeeAppealRuling, shortfall.didEmployeeAppealSanction);
          shortfall.appeal_hearing_chairpersonsFinding = 'NOT_APPLICABLE';
          if (shortfall.appeal_hearing_chairpersonsSanction === 'CONFIRM') {
            shortfall.appeal_hearing_chairpersonConfirmedSanction = shortfall.initial_hearing_chairpersonsSanction;
          }
        } else if (shortfall.didEmployeeAppealRuling === 'YES' && shortfall.didEmployeeAppealSanction === 'NO') {
          console.log('4inc: ', shortfall.didEmployeeAppealRuling, shortfall.didEmployeeAppealSanction);
          shortfall.appeal_hearing_chairpersonsSanction = 'NOT_APPLICABLE';
          if (shortfall.appeal_hearing_chairpersonsFinding === '') {
            shortfall.appeal_hearing_chairpersonConfirmedSanction = '';
          }
          if (shortfall.appeal_hearing_chairpersonsFinding === 'OVERTURN') {
            shortfall.appeal_hearing_chairpersonConfirmedSanction = 'NOT_APPLICABLE';
          } else if (shortfall.appeal_hearing_chairpersonsFinding === 'CONFIRM') {
            shortfall.appeal_hearing_chairpersonConfirmedSanction = shortfall.initial_hearing_chairpersonsSanction;
          }
        }
      });
      if (canSelectRevisedOverallSanction() && values.revisedOverallSanction === 'NOT_APPLICABLE') {
        setFieldValue('revisedOverallSanction', '');
      } else {
        setFieldValue('revisedOverallSanction', 'NOT_APPLICABLE');
      }
    }
  }, [JSON.stringify(values.performanceShortfalls)]);

  return (
    <Form>
      <Row>
        <Col>
          <div>
            <h5 className="text-primary text-capitalize font-weight-normal mt-3">Charges</h5>
            <hr style={{ border: '0.06em solid #adb5bd' }} />
            <Row style={{ overflowX: 'auto' }}>
              <Col>
                <Table className="table-responsive-lg" bordered>
                  <thead>
                    <tr>
                      {cols.map(column => (
                        <th key={column}>
                          <span className="text-blue font-weight-500 text-capitalize">{column}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name={'performanceShortfalls'}>
                      {(arrayHelpers: FieldArrayRenderProps) => (
                        <>
                          {!values.performanceShortfalls && arrayHelpers.push({})}
                          {values.performanceShortfalls &&
                            values.performanceShortfalls.length &&
                            values.performanceShortfalls
                              .filter(
                                (performanceShortfalls: CasePerformanceShortfall) =>
                                  performanceShortfalls.initial_hearing_chairpersonsFindingOnGuilt === 'GUILTY',
                              )
                              .map((item: CasePerformanceShortfall, index: number) => (
                                <tr key={index}>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span> {item.shortfallDescription}</span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span>
                                      {item.initial_hearing_chairpersonsFindingOnGuilt
                                        ? toTitleCase(item.initial_hearing_chairpersonsFindingOnGuilt, '_')
                                        : ''}
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '75px' }}>
                                    <TableFormField
                                      disabled={true}
                                      type={'checkbox-yes-and-no'}
                                      name={`performanceShortfalls.${index}.didEmployeeAppealRuling`}
                                      checkedString={item.didEmployeeAppealRuling}
                                    />
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <TableFormField
                                      type={'select'}
                                      placeholder={'Select Ruling'}
                                      name={`performanceShortfalls.${index}.appeal_hearing_chairpersonsFinding`}
                                      selectOptions={getRulingOptions(index)}
                                      disabled={item.didEmployeeAppealRuling === 'NO'}
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`performanceShortfalls.${index}.appeal_hearing_chairpersonsFinding`}
                                      />
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <span>
                                      {item.initial_hearing_chairpersonsSanction
                                        ? toTitleCase(item.initial_hearing_chairpersonsSanction, '_')
                                        : ''}
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '75px' }}>
                                    <TableFormField
                                      disabled={true}
                                      type={'checkbox-yes-and-no'}
                                      name={`performanceShortfalls.${index}.didEmployeeAppealSanction`}
                                      checkedString={item.didEmployeeAppealSanction}
                                    />
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    {(!!item.appeal_hearing_chairpersonsFinding?.length ||
                                      item.appeal_hearing_chairpersonsSanction === 'NOT_APPLICABLE') && (
                                      <>
                                        <TableFormField
                                          type={'select'}
                                          placeholder={'Select Ruling'}
                                          name={`performanceShortfalls.${index}.appeal_hearing_chairpersonsSanction`}
                                          selectOptions={getGeneralSanctionOptions(index)}
                                          disabled={
                                            item.didEmployeeAppealSanction === 'NO' ||
                                            item.appeal_hearing_chairpersonsFinding === 'OVERTURN'
                                          }
                                        />
                                        <span className="text-danger">
                                          <ErrorMessage
                                            className="text-danger"
                                            name={`performanceShortfalls.${index}.appeal_hearing_chairpersonsSanction`}
                                          />
                                        </span>
                                      </>
                                    )}
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    {canShowRevisedSanctionColumn(index) && (
                                      <>
                                        <TableFormField
                                          type={'select'}
                                          placeholder={'Select Sanction'}
                                          name={`performanceShortfalls.${index}.appeal_hearing_chairpersonConfirmedSanction`}
                                          selectOptions={getSpecificSanctionOptions(index)}
                                          disabled={item.appeal_hearing_chairpersonsSanction !== 'OVERTURN'}
                                        />
                                        <span className="text-danger">
                                          <ErrorMessage
                                            className="text-danger"
                                            name={`performanceShortfalls.${index}.appeal_hearing_chairpersonConfirmedSanction`}
                                          />
                                        </span>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                        </>
                      )}
                    </FieldArray>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={4}>
          <FormGroup>
            <Label for="Overall Sanction" className="text-default text-capitalize">
              Confirm Revised Overall sanction
            </Label>
            <FormField
              type={'selectWithLabels'}
              placeholder={'Select Revised Overall Sanction'}
              name={'revisedOverallSanction'}
              selectOptionsWithLabels={getOptionsForRevisedSanction()}
              disabled={!canSelectRevisedOverallSanction()}
            />
            <span className="text-danger">
              <ErrorMessage className="text-danger" name={'revisedOverallSanction'} />
            </span>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
