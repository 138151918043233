import React from 'react';
import { WorkflowComponentProps } from '../../../../WorkflowContainer/workflow-utils';
import { ExecuteOutcomeProps } from '../../InitialHearingScreens/ExecuteOutcome/InitialHearingExecuteOutcome';
import { componentsAndFormsBySanction } from '../../InitialHearingScreens/ExecuteOutcome/execute-outcome-form-configs';

export const AppealHearingExecuteOutcome: React.FC<WorkflowComponentProps> = props => {
  const sanction = props.data.caseData.revisedOverallSanction;

  const getRelevantChairpersonsRuling = (): string[] => {
    const docs = props.data.caseData.initial_hearing_chairpersonsRulingDocuments;
    if (!docs) {
      throw new Error('No chairpersons ruling');
    }
    return docs.map(d => d.key);
  };

  const getSanctionComponent = (sanction: string): JSX.Element | null => {
    if (!props.data.caseType) {
      throw new Error('Missing caseType');
    }
    const caseType = props.data.caseType;
    const config = componentsAndFormsBySanction[caseType][sanction];
    const component: React.FC<ExecuteOutcomeProps> = config.component;
    if (component) {
      return component({
        data: props.data,
        sanction: sanction,
        additionalBucketKeys: getRelevantChairpersonsRuling(),
        formConfig: config.formConfig,
      });
    } else {
      throw new Error('No matching component for sanction: ' + sanction);
    }
  };
  return getSanctionComponent(sanction);
};
