import React, { useState } from 'react';
import TopBarComponent from '../../../../../components/topBar/TopBar.component';
import Tabs from '../../../../../components/tabs/Tabs.component';
import { EmployeeRights, Evidence, RoleOfChairperson, Sanctions } from './AppealHearingTabData';
import { CaseRole } from '../../../../../models';
import { WorkflowComponentProps } from '../../../../WorkflowContainer/workflow-utils';
import { WorkFlowFooter } from '../../../../../components/workflowFooter/WorkFlowFooter';
import { AppealHearingMisconductGuidancePageTabs } from './AppealHearingGuidanceTabs';

export const AppealHearingGuidancePage: React.FC<WorkflowComponentProps> = props => {
  const { flowableFunctions } = props.data;
  const taskConfig = props.data.taskConfig;
  let initialTab = 3;
  if (taskConfig && taskConfig.caseRole === CaseRole.CHAIRPERSON) {
    initialTab = 0;
  } else if (taskConfig && taskConfig.caseRole === CaseRole.EMPLOYER_REPRESENTATIVE) {
    initialTab = 1;
  }
  const [currentTab, setCurrentTab] = useState(initialTab);

  const changeTab = (tab: number): void => {
    setCurrentTab(tab);
  };

  return (
    <div className="content">
      <TopBarComponent title={'Resources'} hideSearch color={'white'} style={{ height: '60px' }} />
      <hr />
      <div className="px-3">
        <Tabs
          tabs={AppealHearingMisconductGuidancePageTabs}
          page={currentTab}
          changeTab={changeTab}
          style={{ margin: '0.1vw', borderRadius: '5px' }}
        />
        <div className="w-75 d-flex justify-content-center text-justify py-4">
          {currentTab === 0 && <RoleOfChairperson />}
          {currentTab === 1 && <EmployeeRights />}
          {currentTab === 2 && <Evidence />}
          {currentTab === 3 && <Sanctions />}
        </div>
        <WorkFlowFooter
          onNext={() => flowableFunctions.onNext()}
          onCancel={(): void => console.log('cancel')}
          onSaveAndClose={() => props.data.flowableFunctions.onSaveAndClose()}
          data={props.data}
        />
      </div>
    </div>
  );
};
