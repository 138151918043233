//Validation schema with Yup
//first wizard page
import moment from 'moment';
import * as Yup from 'yup';
import { DATE_FORMAT } from '../../forms/fields/FormField.component';
import {
  DisabilityType,
  GenderType,
  Province,
  RaceType,
  StatusType,
  WorkHoursType,
  ApprovedChairPersonType,
  ApprovedHcApproverType,
} from '../../models';
import { PlaceOfWorkType } from '../../API';
import { toTitleCase } from '../../utils/string-utils';

const getDisabilityTypes = (): (null | string)[] => {
  const list: (null | string)[] = Object.keys(DisabilityType);
  list.push(null);
  return list;
};

const getApprovedChairPersonTypes = (): (null | string)[] => {
  const list: (null | string)[] = Object.keys(ApprovedChairPersonType);
  list.push(null);
  return list;
};

const getApprovedHcApproverTypes = (): (null | string)[] => {
  const list: (null | string)[] = Object.keys(ApprovedHcApproverType);
  list.push(null);
  return list;
};

export const EmployeeUploadValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Missing first name')
    .nullable()
    .min(2, 'First name must have 2 or more characters'),
  lastName: Yup.string()
    .required('Missing last name')
    .nullable()
    .min(2, 'Last Name must have 2 or more characters'),
  idNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(13, 'ID must be exactly 13 digits')
    .max(13, 'ID must be exactly 13 digits')
    .nullable(),
  passportNumber: Yup.string()
    .nullable()
    .when('idNumber', {
      is: undefined,
      then: Yup.string().required('Passport number and ID cannot both be empty'),
    }),
  phoneNumbers: Yup.array().of(
    Yup.object().shape({
      number: Yup.string()
        .required('Missing phone number')
        .nullable()
        .matches(/^[0-9]+$/, 'Phone number contains invalid characters')
        .min(10, 'Phone number must be exactly 10 digits')
        .max(10, 'Phone number must be exactly 10 digits'),
      phoneNumberType: Yup.string().required('Missing phone number type'),
    }),
  ),
  emails: Yup.array().of(
    Yup.object().shape({
      address: Yup.string()
        .email('This is not a valid email')
        .nullable(),
      emailAddressType: Yup.string()
        .nullable()
        .when('address', {
          is: value => {
            return !!value;
          },
          then: Yup.string().required('Required Field'),
        }),
    }),
  ),

  physicalAddresses: Yup.array().of(
    Yup.object().shape({
      physicalAddressType: Yup.string().required('Missing physical address type'),
      lineOne: Yup.string()
        .required('Missing physical address line one')
        .nullable()
        .min(2, 'Address line must have 2 or more characters'),
      lineTwo: Yup.string()
        .required('Missing physical address line two')
        .nullable()
        .min(2, 'Address line must have 2 or more characters'),
      city: Yup.string().required('Missing city'),
      province: Yup.string()
        .oneOf(
          Object.keys(Province),
          'Province must be one of the following: ' +
            Object.keys(Province)
              .map(item => toTitleCase(item, '_'))
              .join(', '),
        )
        .required('Missing province')
        .nullable(),
      postalCode: Yup.string()
        .required('Missing postal code')
        .nullable()
        .matches(/^[0-9]+$/, 'Postal code must contain only digits')
        .min(4, 'Postal code must be exactly 4 digits')
        .max(4, 'Postal code must be exactly 4 digits'),
    }),
  ),
  employeeNumber: Yup.string()
    .nullable()
    .required('Missing employee number'),
  jobLevel: Yup.string().nullable(),
  // .required('Missing job level'),
  jobGrade: Yup.string().nullable(),
  // .required('Missing job grade'),
  jobTitle: Yup.string().nullable(),
  // .required('Missing job title'),
  placeOfWork: Yup.string()
    .oneOf(
      Object.keys(PlaceOfWorkType),
      'Place Of Work must be one of the following: ' +
        Object.keys(PlaceOfWorkType)
          .map(item => toTitleCase(item, '_'))
          .join(', '),
    )
    .nullable()
    .required('Missing place of work'),
  status: Yup.mixed()
    .oneOf(
      Object.keys(StatusType),
      'Status must be one of the following: ' +
        Object.keys(StatusType)
          .map(item => toTitleCase(item, '_'))
          .join(', '),
    )
    .required('Missing status')
    .nullable(),
  race: Yup.mixed()
    .oneOf(
      Object.keys(RaceType).filter(el => el !== 'BLACK'),
      'Race must be one of the following: ' +
        Object.keys(RaceType)
          .filter(el => el !== 'BLACK')
          .map(item => toTitleCase(item, '_'))
          .join(', '),
    )
    .required('Missing race')
    .nullable(),
  gender: Yup.string()
    .oneOf(
      Object.keys(GenderType),
      'Gender must be one of the following: ' +
        Object.keys(GenderType)
          .map(item => toTitleCase(item, '_'))
          .join(', '),
    )
    .required('Missing gender')
    .nullable(),
  hireDate: Yup.string()
    .required('Missing hire date')
    .test('date', 'Invalid hire date - Please use DD/MM/YYYY', val => moment(val, DATE_FORMAT).isValid())
    .nullable(),
  directManager: Yup.string().nullable(),
  workHours: Yup.string()
    .oneOf(
      Object.keys(WorkHoursType),
      'Work hours must be one of the following: ' +
        Object.keys(WorkHoursType)
          .map(item => toTitleCase(item, '_'))
          .join(', '),
    )
    .required('Missing work hours')
    .nullable(),
  disability: Yup.mixed()
    .nullable()
    .oneOf(
      getDisabilityTypes(),
      'Disability type must be one of the following: ' +
        Object.keys(DisabilityType)
          .map(item => toTitleCase(item, '_'))
          .join(', '),
    ),
  approvedChairPerson: Yup.mixed()
    .required('Missing Approved Chairperson - Must be Y or N')
    .nullable(),
  approvedHcApprover: Yup.mixed()
    .required('Missing Approved HC Approver- Must be Y or N')
    .nullable(),
});
