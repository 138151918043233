import React from 'react';
import { Col, FormGroup, Label, Row, Form } from 'reactstrap';
import UploaderContainer from '../../../components/Uploader/UploaderContainer';
import { WorkflowComponentProps } from '../../WorkflowContainer/workflow-utils';

const CompleteInvestigationForm: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  return (
    <Form>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="investigation report" className="text-default text-capitalize">
              Upload Investigation Report*
            </Label>
            <UploaderContainer
              fieldName={'investigationReportDocuments'}
              path={`cases/${props.data.masterProcessInstanceId}`}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default CompleteInvestigationForm;
