import React from 'react';
import { Col, Form, Row, Table } from 'reactstrap';
import { ErrorMessage, FieldArray, FieldArrayRenderProps, FormikProps, FormikValues, useFormikContext } from 'formik';
import TableFormField from '../../../../../forms/fields/TableFormField';
import { Tooltip } from '../../../../../components/tooltips/Tooltip.component';
import { CasePerformanceShortfall, WorkflowComponentProps } from '../../../../WorkflowContainer/workflow-utils';
import { CaseType } from '../../../../../API';

const incidentCols = ['Summary of Allegations', 'date', 'time', 'transgression'];
const shortfallCols = ['performance shortfalls', 'date'];

const InitialHearingCompileNoticeOfHearingForm: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { values }: FormikProps<FormikValues> = useFormikContext();
  return (
    <Form>
      {props.data.caseType === CaseType.MISCONDUCT && (
        <Row>
          <Col>
            <div>
              <h6
                className="text-primary text-capitalize mt-4 font-weight-light"
                style={{ fontSize: '14px', lineHeight: '17px' }}
              >
                Incidents*
                <Tooltip
                  className="ml-2"
                  title="hearing logistics"
                  id="noticeOfHearing"
                  text="Charges will automatically pull through into the Notice of Disciplinary Hearing as per the information captured earlier in the employee case. You must apply your mind and edit these charges if required, prior to issuing the employee with the Notice."
                />
              </h6>
              <hr style={{ border: '0.06em solid #adb5bd' }} />
              <Row>
                <Col>
                  <Table className="table-responsive-lg" bordered>
                    <thead>
                      <tr>
                        {incidentCols.map(column => (
                          <th key={column}>
                            <span className="text-default">{column}</span>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name={'incidents'}>
                        {(arrayHelpers: FieldArrayRenderProps) => (
                          <>
                            {!values.incidents && arrayHelpers.push({})}
                            {values.incidents &&
                              values.incidents.length &&
                              values.incidents.map((item: any, index: number) => (
                                <tr key={index}>
                                  <td style={{ width: 'auto', minWidth: '250px' }}>
                                    <TableFormField
                                      type={'text'}
                                      placeholder={'Summary of Facts'}
                                      name={`incidents.${index}.summaryOfFacts`}
                                      className="square-radius border-0 h-25"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage
                                        className="text-danger"
                                        name={`incidents.${index}.summaryOfFacts`}
                                      />
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ maxWidth: '75px', minWidth: '75px' }}>
                                    <TableFormField
                                      type={'date'}
                                      placeholder={'Date'}
                                      name={`incidents.${index}.date`}
                                      className="square-radius border-0 h-25"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage className="text-danger" name={`incidents.${index}.date`} />
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ maxWidth: '75px', minWidth: '75px' }}>
                                    <TableFormField
                                      type={'time'}
                                      placeholder={'Time'}
                                      name={`incidents.${index}.time`}
                                      className="square-radius border-0"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage className="text-danger" name={`incidents.${index}.time`} />
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '120px' }}>
                                    <span> {item.transgression}</span>
                                  </td>
                                </tr>
                              ))}
                          </>
                        )}
                      </FieldArray>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
      {props.data.caseType === CaseType.POOR_PERFORMANCE && (
          <Row className="mt-4">
            <Col>
              <Table className="table-responsive-lg" bordered>
                <thead>
                <tr>
                  {shortfallCols.map((column) => (
                      <th key={column}>
                        <span className="text-default">{column}</span>
                      </th>
                  ))}
                </tr>
                </thead>
                <tbody>
                <FieldArray name={'performanceShortfalls'}>
                  {(arrayHelpers: FieldArrayRenderProps) => (
                      <>
                        {values.performanceShortfalls &&
                            values.performanceShortfalls.length > 0 &&
                            values.performanceShortfalls.map((item: CasePerformanceShortfall, index: number) => (
                                <tr key={index}>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <TableFormField
                                        type="text"
                                        placeholder="Performance Shortfall"
                                        name={`performanceShortfalls.${index}.shortfallDescription`}
                                        className="square-radius border-0 h-25"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage name={`performanceShortfalls.${index}.shortfallDescription`} />
                                    </span>
                                  </td>
                                  <td className="position-relative" style={{ minWidth: '150px' }}>
                                    <TableFormField
                                        type="date"
                                        placeholder="Date"
                                        name={`performanceShortfalls.${index}.date`}
                                        className="square-radius border-0 h-25"
                                    />
                                    <span className="text-danger">
                                      <ErrorMessage name={`performanceShortfalls.${index}.date`} />
                                    </span>
                                  </td>
                                </tr>
                            ))}
                      </>
                  )}
                </FieldArray>
                </tbody>
              </Table>
            </Col>
          </Row>
      )}
    </Form>
  );
};

export default InitialHearingCompileNoticeOfHearingForm;
