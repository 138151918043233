import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import List from '../../../PDF/List.component';
import ListItem from '../../../PDF/ListItem.component';
import Paragraph from '../../../PDF/Paragraph.component';
import LetterHead from '../../../PDF/Letterhead.component';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const PoorPerformanceDemotionLetterPDF: React.FC<DocumentProps> = props => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        {!!props.logo && <LetterHead imageUrl={props.logo.imageUrl} logoPosition={props.logo.position} />}
        <Text style={styles.title}>Confidential</Text>
        <Table>
          <TableHeader title="attention" />
          <TableRow>
            <TableCell>Name of Employee: </TableCell>
            <TableCell>{props.content.employeeFullName.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Employee Number: </TableCell>
            <TableCell>{props.content.employeeNumber.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Department/Business Unit</TableCell>
            <TableCell>{props.content.employeeDepartment.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{props.content.employeeAddress.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>{props.content.date.value}</TableCell>
          </TableRow>
        </Table>
        <Paragraph> Dear {props.content.employeeFullName.value}, </Paragraph>
        <Text style={{ ...styles.subject }}>
          DISMISSAL DUE TO INCAPACITY POOR PERFORMANCE, ALTERNATIVELY, DEMOTION{' '}
        </Text>
        <Paragraph>
          An Incapacity Poor Performance Hearing held on {props.content.initial_hearing_hearingDate.value} refers.
        </Paragraph>
        <Paragraph>
          You were found guilty, alternatively pleaded guilty, on the following incidents of poor performance and/or
          failure to meet deliverables:
        </Paragraph>
        <Table>
          {Array.isArray(props.content.performanceShortfalls_guilty.value) &&
            props.content.performanceShortfalls_guilty.value.length &&
            props.content.performanceShortfalls_guilty.value.map((value: string, index: number) => (
              <TableRow key={index}>
                <TableCell width={'100%'}>{value} </TableCell>
              </TableRow>
            ))}
        </Table>
        <Paragraph>
          Having considered aggravating and mitigating factors, as applicable, the chairperson found dismissal to be a
          suitable sanction. In the circumstances, however, demotion is viewed as a possible alternative. Please find
          attached a copy of the chairperson’s ruling.
        </Paragraph>
        <Paragraph>You are to indicate whether the latter alternative is acceptable to you.</Paragraph>
        <Paragraph>Should your services be terminated the following terms apply:</Paragraph>
        <List>
          <ListItem>
            Your last day of employment with the company will be{' '}
            <Text style={styles.bold}>{props.content.dismissalDate.value} (“termination date”);</Text>
          </ListItem>
          <ListItem>You will not be required to render any further services with immediate effect;</ListItem>
          <ListItem>Final remuneration is payable up to {props.content.finalRemunerationDate.value};</ListItem>
          <ListItem>Any accrued leave as at the termination date will be paid;</ListItem>
          <ListItem>
            Membership of all employer schemes/funds, as applicable, will also terminate on this date as will the
            employer’s obligations in this regard;
          </ListItem>
          <ListItem>A Certificate of Service will be issued to you; </ListItem>
          <ListItem>Appropriate UIF documentation will be completed and issued, as required; </ListItem>
          <ListItem>
            You are to return all company property on receipt hereof, should you still have any such in your possession;{' '}
          </ListItem>
          <ListItem>Confidentiality undertakings continue to apply. </ListItem>
        </List>
        <Paragraph>Alternatively, should you opt for demotion the following terms apply:</Paragraph>
        <Table>
          <TableHeader title={'New job Details'} />
          <TableRow>
            <TableCell>New job title: </TableCell>
            <TableCell>{props.content.demotionNewJobTitle.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>New job level/grade: </TableCell>
            <TableCell>{props.content.demotionNewJobLevel.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Department </TableCell>
            <TableCell>{props.content.demotionNewDepartment.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>New remuneration package per annum </TableCell>
            <TableCell>{props.content.demotionNewRemunerationPackage.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Any other changed terms and conditions related to the new level/grade</TableCell>
            <TableCell>{props.content.demotionNewTerms.value}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Effective date of demotion </TableCell>
            <TableCell>{props.content.demotionDate.value}</TableCell>
          </TableRow>
        </Table>
        <Paragraph>
          Details of the role and performance expectations will be discussed with you by your reporting manager. Going
          forward, your performance will then be monitored against the standards and expectations set for your new level
          and feedback given to ensure acceptable future performance.
        </Paragraph>
        <Paragraph>
          <Text>{props.content.other.value}</Text>
        </Paragraph>
        <Paragraph>Please indicate your preferred choice below.</Paragraph>
        <Paragraph>
          In case of dismissal, you have the right to refer the matter to the CCMA / Bargaining Council Dispute Forum &
          Arbitration or the appropriate Bargaining Council Dispute Forum, as appropriate, should you so wish in which
          case all documents must be served at the following address:
        </Paragraph>
        <Text style={styles.bold}>Contact Name: {props.content.contactName.value}</Text>
        <Text style={styles.bold}>Physical Address: {props.content.contactAddress.value}</Text>
        <Text style={styles.bold}>Email Address: {props.content.contactEmail.value}</Text>
        <Paragraph>Please liaise with {props.content.authorName.value} in case of queries.</Paragraph>
        <Paragraph>
          This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a copy
          to writer hereof in acknowledgement of receipt hereof, alternatively, acknowledge by way of return e-mail.
        </Paragraph>
        <Paragraph>Yours sincerely,</Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
    </Document>
  );
};
