import { Form, Formik, FormikErrors, FormikProps, FormikValues, getIn } from 'formik';
import React, { ReactElement, useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import { WorkFlowFooter } from '../../components/workflowFooter/WorkFlowFooter';
import NewCaseForm from '../../forms/addCase/NewCaseForm';
import { AddCaseSchema } from '../../forms/ValidationSchema/GeneralCaseSchema';
import { getFormDataFromCaseData, WorkflowComponentProps } from '../WorkflowContainer/workflow-utils';
import './IncidentFormValues';
import { CaseType } from '../../API';

const AddCaseScreen: React.FC<WorkflowComponentProps> = (props: WorkflowComponentProps) => {
  const { caseData, flowableFunctions } = props.data;

  const fields = {
    caseNumber: 'string',
    caseType: 'string',
    incidents: 'array',
    performanceShortfalls: 'array',
    jobTitle: 'string',
    jobDescriptionOrKPIDocuments: 'array',
    performanceRatingHistory: 'string',
  };
  const [data, setData] = useState(() => getFormDataFromCaseData(fields, caseData, caseData.isAppeal));

  const submitValues = async (values: FormikValues): Promise<void> => {
    const formValues = { ...values };
    if (formValues.caseType === CaseType.MISCONDUCT) formValues.performanceShortfalls = null;
    else if (formValues.caseType === CaseType.POOR_PERFORMANCE) formValues.incidents = null;
    formValues.incidents = JSON.stringify(formValues.incidents);
    formValues.performanceShortfalls = JSON.stringify(formValues.performanceShortfalls);
    formValues.jobDescriptionOrKPIDocuments = JSON.stringify(formValues.jobDescriptionOrKPIDocuments);
    flowableFunctions.onNext(formValues);
  };

  const setErrors = (
    errors: FormikErrors<FormikValues>,
    setFieldTouched: (field: string, touched: boolean) => void,
  ): void => {
    const errorKeys = Object.keys(errors);
    errorKeys.forEach(field => {
      //if fieldArray
      if (Array.isArray(errors[field])) {
        const err = getIn(errors, field);
        const innerArr = Object.keys(err);
        innerArr.forEach((item, index) => {
          let fieldNames;
          if (err[item]) {
            fieldNames = Object.keys(err[item]);
            fieldNames.forEach(name => setFieldTouched(`${field}.${index}.${name}`, true));
          }
        });
      } else {
        setFieldTouched(field, true);
      }
    });
  };

  useEffect(() => {
    if (!data.performanceShortfalls) {
      const obj = [
        {
          shortfallDescription: '',
          date: '',
          documents: [
            {
              key: '',
              fileName: '',
              url: '',
            },
          ],
        },
      ];
      setData(data => ({ ...data, performanceShortfalls: obj }));
    }
  }, [data.performanceShortfalls]);
  return (
    <div className="content">
      <>
        <Formik
          initialValues={data}
          validationSchema={AddCaseSchema}
          enableReinitialize
          onSubmit={(values: FormikValues): Promise<void> => submitValues(values)}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            errors,
            setFieldTouched,
          }: FormikProps<FormikValues>): ReactElement => (
            <div className="position-relative">
              <Row>
                <Form className="col-md-12">
                  <NewCaseForm caseData={caseData} />
                </Form>
              </Row>
              <WorkFlowFooter
                data={props.data}
                onNext={(): void => {
                  setErrors(errors, setFieldTouched);
                  if (!Object.keys(errors).length) handleSubmit();
                }}
                onSaveAndClose={(): void => props.data.flowableFunctions.onSaveAndClose(values)}
                onCancel={(): void => console.log('cancel')}
                hideSaveAndCloseButton={props.data.caseType === 'NONE'}
              />
            </div>
          )}
        </Formik>
      </>
    </div>
  );
};

export default AddCaseScreen;
