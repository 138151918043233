import * as Yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../../../forms/fields/FormField.component';
import { TemplateType } from '../../../../../../API';
import { FormConfigV2 } from '../../../../../../configs/form-configs/form-config-types';

export const initial_hearing_misconduct_executeOutcomeSuspensionWithoutPay: FormConfigV2 = {
  templateType: TemplateType.SUSPENSION_WITHOUT_PAY_LETTER,
  fields: {
    incidents: 'array',
    initial_hearing_hearingDate: 'string',
    // periodOfUnpaidSuspension: 'string',
    // returnToWorkDate: 'string',
    dismissalDate: 'string',
    suspensionStartDate: 'string',
    suspensionEndDate: 'string',
  },
  caseHistoryFields: {
    incidents: {
      type: 'table',
      label: 'Incidents',
      tableColumns: ['transgression'],
    },
    initial_hearing_hearingDate: {
      type: 'string',
      label: 'Date of Hearing',
    },
    dismissalDate: {
      type: 'string',
      label: 'Dismissal Date',
    },
    suspensionStartDate: {
      type: 'string',
      label: 'Suspension Start Date',
    },
    suspensionEndDate: {
      type: 'string',
      label: 'Suspension End Date',
    },
  },
  validationSchema: Yup.object().shape({
    initial_hearing_hearingDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
    dismissalDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
    suspensionStartDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
    suspensionEndDate: Yup.string()
      .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
      .required('Please select date'),
    // periodOfUnpaidSuspension: Yup.number().required('Please enter a number'),
    // returnToWorkDate: Yup.string()
    //   .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
    //   .required('Please select date'),
    incidents: Yup.array()
      .of(
        Yup.object().shape({
          transgression: Yup.string().required('Please transgression'),
          correctiveAction: Yup.string().when('initial_hearing_chairpersonsFindingOnGuilt', {
            is: 'GUILTY',
            then: Yup.string().required('Please enter corrective action'),
            otherwise: Yup.string().nullable(),
          }),
        }),
      )
      .required()
      .nullable(),
  }),
};

// TODO APPARENTLY THERE SHOULD *NOT* BE A SUSPENSION WITHOUT PAY FOR POOR PERFORMANCE?

// export const initial_hearing_pp_executeOutcomeSuspensionWithoutPay = {
//     templateType: ,
//     fields: {
//         performanceShortfalls: 'array',
//         initial_hearing_hearingDate: 'string',
//         periodOfUnpaidSuspension: 'string',
//         returnToWorkDate: 'string',
//     },
//     validationSchema: Yup.object().shape({
//         initial_hearing_hearingDate: Yup.string()
//             .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
//             .required('Please select date'),
//         periodOfUnpaidSuspension: Yup.number().required('Please enter a number'),
//         returnToWorkDate: Yup.string()
//             .test('date', 'Date is not valid', val => moment(val, DATE_FORMAT).isValid())
//             .required('Please select date'),
//         performanceShortfalls: Yup.array()
//             .of(
//                 Yup.object().shape({
//                     shortfallDescription: Yup.string().required('Required'),
//                     correctiveAction: Yup.string().required('Please enter corrective action'),
//                 }),
//             )
//             .required()
//             .nullable(),
//     }),
// };
