const getContentHtml = require('./getContentHtml')


module.exports = (emailParams, siteUrl) => {

    const requiredFields = [
        'to',
        'from',
        'addresseeName',
        'contactName',
        'temporaryPassword'
    ];

    for (let i = 0; i < requiredFields.length; i ++) {
        const reqField = requiredFields[i];
        if (!emailParams[reqField]) {
            console.log('error, emailParams missing field', emailParams[reqField]);
        }
    }

    const bodyHtml =
        (`
        <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-start-index="4787" data-end-index="4925" data-muid="eBRaDmnRhcZU8CphJrrjCM" data-mc-module-version="2019-10-22">
        <tbody><tr data-start-index="4932" data-end-index="4936">
        <td style="padding:18px 40px 0px 40px; background-color:#ffffff; line-height:24px;" height="100%" valign="top" bgcolor="#ffffff" data-start-index="4945" data-end-index="5088"><div>
        <div style="font-family: inherit; text-align: inherit">
        Dear <span style="text-transform: capitalize">${emailParams.addresseeName},</span>
        <br>
        <br>
        Welcome to LabourTeq, the company's cloud-based employee relations management system. The system provides intuitive
        workflows as well as resources to guide you step by step when dealing with misconduct and incapacity poor performance
        cases in the workplace. A user account has been created for you.     
        <br>
        <br>
        Please use your e-mail address as Username. Your temporary password is [password]. Please click <u>here</u> to log in. 
        Once you access the system, you will be requested to choose your own password.
        <br>
        <br>
        When setting your password, make sure it is not your name, surname or some other easily identifiable personal 
        information that can give others easy access. Your password should be at least 8 characters in length, contain a  
        numeral and a special character to mitigate security risks.
        <br>
        <br>
        Please maintain the security of the system link sent, do not share your password with anyone and take the necessary
        care when using LabourTeq to protect and maintain confidential employee information.
        <br>
        <br>
        LabourTeq is copyright protected. Please follow the terms and conditions governing your use, available on the system.
        <br>
        <br>
        </div>
            <div style="font-family: inherit; text-align: center"><br></div>
                <div style="font-family: inherit; text-align: center"><br></div><div></div></div></td>
        </tr>
        </tbody>
        </table>
`);

//TODO button to confirm action
    const options = {
    bodyHtml: bodyHtml,
        logoUrl: emailParams.organisationLogoUrl,
    preheaderText: 'Account Created',
    headingText: 'Account Created',
    buttonHtml: ''
};

    const content = getContentHtml(options);
    return content;
};
